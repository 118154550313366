import { IPreviewConversationFavourite, } from 'atlas-shared';
import { IConversationFavouriteStore, ConversationFavouriteActions, TConversationFavouriteActionTypes, } from '@Store';

export const ConversationFavouriteReducer = (state: IConversationFavouriteStore = {
  loaded: false,
  loading: false,
  conversation_favourites: [],
  dict: {},
}, action: TConversationFavouriteActionTypes): IConversationFavouriteStore => {
  switch (action.type) {
  case ConversationFavouriteActions.LOADING:
    return { ...state, loading: true, };
  case ConversationFavouriteActions.SET_STATE:
    const dict: IConversationFavouriteStore['dict'] = {};

    action.payload.forEach((conversation_favourite: IPreviewConversationFavourite) => dict[conversation_favourite.id] = conversation_favourite);

    return { ...state, loaded: true, loading: false, conversation_favourites: action.payload, dict, };
  case ConversationFavouriteActions.ADD_STATE:
    return { ...state, conversation_favourites: [...state.conversation_favourites, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case ConversationFavouriteActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, conversation_favourites: state.conversation_favourites.filter((item: IPreviewConversationFavourite) => item.id !== action.payload), dict, };
  }
  case ConversationFavouriteActions.UPDATE_STATE:
    const conversation_favourites = [...state.conversation_favourites, ];
    const index = state.conversation_favourites.findIndex((conversation_favourite: IPreviewConversationFavourite) => conversation_favourite.id === action.payload.id);

    if (!~index)
      conversation_favourites.push(action.payload);
    else
      conversation_favourites[index] = action.payload;

    return { ...state, conversation_favourites, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
