import {
  IPreviewConversation,
  IPreviewConversationWatcher,
  IPreviewVoiceRecording,
  IPreviewVoiceVoicemailMessage,
  ISharedContact,
  ISharedConversation,
  ISharedDraft,
  ISharedFullConversation,
  ISharedFullConversationReducer,
  ISharedInternalNote,
  ISharedMailMessage,
  Nullable
  , IPreviewVoiceCallBridge, } from 'atlas-shared';
import {
  TConversationActionTypes as TConversationActionTypesBase,
} from './abstract/conversation.types.abstract';

export enum ConversationActions {
  SET_STATE = 'CONVERSATION_SET_STATE',
  ADD_STATE = 'CONVERSATION_ADD_STATE',
  ADDS_STATE = 'CONVERSATION_ADDS_STATE',
  ADD_FULL_STATE = 'CONVERSATION_ADD_FULL_STATE',
  ADD_FULL_STATE_LOADING = 'CONVERSATION_ADD_FULL_STATE_LOADING',
  REMOVE_FULL_STATE_LOADING = 'CONVERSATION_REMOVE_FULL_STATE_LOADING',
  REMOVE_STATE = 'CONVERSATION_REMOVE_STATE',
  REMOVE_FULL_STATE = 'CONVERSATION_REMOVE_FULL_STATE',
  GET_STATE = 'CONVERSATION_GET_STATE',
  UPDATE_STATE = 'CONVERSATION_UPDATE_STATE',
  UPDATE_FULL_STATE = 'CONVERSATION_UPDATE_FULL_STATE',
  LOADING = 'CONVERSATION_LOADING',
  ADD_CONVERSATION_MESSAGE = 'ADD_CONVERSATION_MESSAGE',
  ADD_CONVERSATION_VOICE_CALL_BRIDGE = 'ADD_CONVERSATION_VOICE_CALL_BRIDGE',
  UPDATE_CONVERSATION_MESSAGE = 'UPDATE_CONVERSATION_MESSAGE',
  UPDATE_CONVERSATION_VOICE_CALL_BRIDGE = 'UPDATE_CONVERSATION_VOICE_CALL_BRIDGE',
  UPDATE_CONVERSATION_CONTACT = 'UPDATE_CONVERSATION_CONTACT',
  SET_CONVERSATION_CONTACT = 'SET_CONVERSATION_CONTACT',
  ADD_CONVERSATION_DRAFT = 'ADD_CONVERSATION_DRAFT',
  UPDATE_CONVERSATION_DRAFT = 'UPDATE_CONVERSATION_DRAFT',
  REMOVE_CONVERSATION_DRAFT = 'REMOVE_CONVERSATION_DRAFT',
  ADD_CONVERSATION_CONVERSATION_WATCHER = 'ADD_CONVERSATION_CONVERSATION_WATCHER',
  REMOVE_CONVERSATION_CONVERSATION_WATCHER = 'REMOVE_CONVERSATION_CONVERSATION_WATCHER',
  SET_CURRENT_DASHBOARD_CONVERSATION_ID = 'SET_CURRENT_DASHBOARD_CONVERSATION_ID',
  ADD_CONVERSATION_VOICE_RECORDING = 'ADD_CONVERSATION_VOICE_RECORDING',
  UPDATE_CONVERSATION_VOICE_RECORDING = 'UPDATE_CONVERSATION_VOICE_RECORDING',
  ADD_CONVERSATION_VOICE_VOICEMAIL_MESSAGE = 'ADD_CONVERSATION_VOICE_VOICEMAIL_MESSAGE',
  UPDATE_CONVERSATION_VOICE_VOICEMAIL_MESSAGE = 'UPDATE_CONVERSATION_VOICE_VOICEMAIL_MESSAGE',
  SET_CONVERSATION_JOURNEY = 'SET_CONVERSATION_JOURNEY',
}

export interface ICurrentConversationDashboardConversationId {
  type: ConversationActions.SET_CURRENT_DASHBOARD_CONVERSATION_ID;
  payload: ISharedFullConversation['id'];
}

export interface IConversationUpdateFullAction {
  type: ConversationActions.UPDATE_FULL_STATE;
  payload: ISharedFullConversation;
}

export interface IConversationAddFullAction {
  type: ConversationActions.ADD_FULL_STATE;
  payload: ISharedFullConversation;
}

export interface IConversationRemoveFullAction {
  type: ConversationActions.REMOVE_FULL_STATE;
  payload?: ISharedFullConversation['id'];
}

export interface IConversationAddDraft {
  type: ConversationActions.ADD_CONVERSATION_DRAFT;
  payload: ISharedDraft;
}

export interface IConversationUpdateDraft {
  type: ConversationActions.UPDATE_CONVERSATION_DRAFT;
  payload: ISharedDraft;
}

export interface IConversationRemoveDraft {
  type: ConversationActions.REMOVE_CONVERSATION_DRAFT;
  payload: ISharedDraft['id'];
}

export interface IConversationAddConversationWatcher {
  type: ConversationActions.ADD_CONVERSATION_CONVERSATION_WATCHER;
  payload: IPreviewConversationWatcher;
}

export interface IConversationRemoveConversationWatcher {
  type: ConversationActions.REMOVE_CONVERSATION_CONVERSATION_WATCHER;
  payload: IPreviewConversationWatcher;
}

export interface IConversationAddMessage {
  type: ConversationActions.ADD_CONVERSATION_MESSAGE;
  payload: ISharedMailMessage | ISharedInternalNote;
}

export interface IConversationUpdateMessage {
  type: ConversationActions.UPDATE_CONVERSATION_MESSAGE;
  payload: ISharedMailMessage | ISharedInternalNote;
}

export interface IConversationUpdateContact {
  type: ConversationActions.UPDATE_CONVERSATION_CONTACT;
  payload: ISharedContact;
}

export interface IConversationAddsAction {
  type: ConversationActions.ADDS_STATE;
  payload: Array<IPreviewConversation>;
}

export interface IConversationSetContact {
  type: ConversationActions.SET_CONVERSATION_CONTACT;
  payload: {
    conversation_id: IPreviewConversation['id'];
    contact: ISharedContact;
  };
}

export interface IConversationSetJourney {
  type: ConversationActions.SET_CONVERSATION_JOURNEY;
  payload: {
    conversation_id: IPreviewConversation['id'];
    journey: ISharedFullConversation['journey'];
  };
}

export interface IConversationAddVoiceRecording {
  type: ConversationActions.ADD_CONVERSATION_VOICE_RECORDING;
  payload: IPreviewVoiceRecording;
}

export interface IConversationUpdateVoiceRecording {
  type: ConversationActions.UPDATE_CONVERSATION_VOICE_RECORDING;
  payload: IPreviewVoiceRecording;
}

export interface IConversationAddVoiceVoicemailMessage {
  type: ConversationActions.ADD_CONVERSATION_VOICE_VOICEMAIL_MESSAGE;
  payload: IPreviewVoiceVoicemailMessage;
}

export interface IConversationUpdateVoiceVoicemailMessage {
  type: ConversationActions.UPDATE_CONVERSATION_VOICE_VOICEMAIL_MESSAGE;
  payload: IPreviewVoiceVoicemailMessage;
}

export interface IConversationAddVoiceCallBridge {
  type: ConversationActions.ADD_CONVERSATION_VOICE_CALL_BRIDGE;
  payload: IPreviewVoiceCallBridge;
}

export interface IConversationUpdateVoiceCallBridge {
  type: ConversationActions.UPDATE_CONVERSATION_VOICE_CALL_BRIDGE;
  payload: IPreviewVoiceCallBridge;
}

export interface IConversationStore {
  loaded: boolean;
  loading: boolean;
  conversations: Array<IPreviewConversation>;
  dict: Record<IPreviewConversation['id'], IPreviewConversation>;
  full_conversations_dict: Record<ISharedFullConversation['id'], ISharedFullConversationReducer>;
  full_conversations_loading: Array<ISharedFullConversation['id']>;
  draft_dict: Record<ISharedDraft['id'], {conversation_id: ISharedConversation['id']}>;
  current_dashboard_conversation_id: Nullable<ISharedFullConversation['id']>;
}

export interface IFullConversationAddStateLoading {
  type: ConversationActions.ADD_FULL_STATE_LOADING;
  payload: ISharedFullConversation['id'];
}

export interface IFullConversationRemoveStateLoading {
  type: ConversationActions.REMOVE_FULL_STATE_LOADING;
  payload: ISharedFullConversation['id'];
}

export type TConversationActionTypes =
  TConversationActionTypesBase
  | IConversationAddsAction
  | IConversationUpdateFullAction
  | IConversationAddFullAction
  | IConversationRemoveFullAction
  | IConversationAddDraft
  | IConversationUpdateDraft
  | IConversationAddMessage
  | IConversationUpdateMessage
  | IConversationRemoveDraft
  | IConversationUpdateContact
  | IConversationSetContact
  | IFullConversationAddStateLoading
  | IFullConversationRemoveStateLoading
  | ICurrentConversationDashboardConversationId
  | IConversationAddConversationWatcher
  | IConversationRemoveConversationWatcher
  | IConversationAddVoiceRecording
  | IConversationUpdateVoiceRecording
  | IConversationAddVoiceVoicemailMessage
  | IConversationUpdateVoiceVoicemailMessage
  | IConversationAddVoiceCallBridge
  | IConversationUpdateVoiceCallBridge
  | IConversationSetJourney
  ;

export type {
  IConversationLoading,
  IConversationSetAction,
  IConversationUpdateAction,
  IConversationAddAction,
  IConversationRemoveAction,
} from './abstract/conversation.types.abstract';
