import { RestRequest, } from '@Utils';
import { IPreviewOrganization, ISharedOrganization, ISharedCreateOrganization, ISharedPatchOrganization, } from 'atlas-shared';

export const fetchOrganizations = async (): Promise<Array<IPreviewOrganization>> => {
  return RestRequest.get<Array<IPreviewOrganization>>('organization', { socket_id: global.socketId, });
};

export const fetchOrganization = async (id: IPreviewOrganization['id']): Promise<ISharedOrganization> => {
  return RestRequest.get<ISharedOrganization>(`organization/${id}`, { socket_id: global.socketId, });
};

export const createOrganization = (payload: ISharedCreateOrganization) => {
  return RestRequest.post<any, ISharedOrganization>('organization', payload, { socket_id: global.socketId, });
};

export const patchOrganization = (id: IPreviewOrganization['id'], payload: ISharedPatchOrganization) => {
  return RestRequest.patch<any, ISharedOrganization>(`organization/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteOrganization = (id: IPreviewOrganization['id']) => {
  return RestRequest.delete(`organization/${id}`, { socket_id: global.socketId, });
};

export const getOrganizationUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('organization/update/schema');
};

export const getOrganizationCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('organization/create/schema');
};

export const getOrganizationPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('organization/patch/schema');
};
