import { IPreviewVoiceRecording, ISharedVoiceRecordingTranscript, } from 'atlas-shared';
import { download, RestRequest, stream, } from '@Utils';

export const downloadVoiceRecordingFilename = (organization_id: IPreviewVoiceRecording['organization_id'], id: IPreviewVoiceRecording['id'], filename: string, onError?: (error) => void) => {
  download(`voice_recording/${organization_id}/${id}/filename`, filename, onError);
};

export const streamVoiceRecordingFilename = (organization_id: IPreviewVoiceRecording['organization_id'], id: IPreviewVoiceRecording['id'], onError?: (error) => void): Promise<string> => {
  return stream(`voice_recording/${organization_id}/${id}/filename`, onError);
};

export const generateVoiceRecordingTranscript = (organization_id: IPreviewVoiceRecording['organization_id'], id: IPreviewVoiceRecording['id']) => {
  return RestRequest.post<any, ISharedVoiceRecordingTranscript>(`voice_recording/${organization_id}/${id}/transcript`, {});
};

export const getVoiceRecordingTranscript = (organization_id: IPreviewVoiceRecording['organization_id'], id: IPreviewVoiceRecording['id']) => {
  return RestRequest.get<ISharedVoiceRecordingTranscript>(`voice_recording/${organization_id}/${id}/transcript`);
};
