import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateTimeInterval, ISharedUpdateTimeInterval, ISharedTimeInterval, IPreviewOrganization, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface ITimeIntervalFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const TimeIntervalFormOptions = (time_interval: ISharedCreateTimeInterval | ISharedTimeInterval, props: ITimeIntervalFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.TimeInterval, {})), 'organization_id', false), 'organization'),
  
});

export const TimeIntervalComponents: IForm['components'] = {};
export const TimeIntervalAdditionalParams: (auth: IAuth, props: ITimeIntervalFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ITimeIntervalFormOptionsProps) => ({
});

export const TimeIntervalEditForm = (time_interval: ISharedTimeInterval, props: ITimeIntervalFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `time_interval_edit_${time_interval.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === time_interval.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'interval', },
    { ref: 'open', },
  ],
});

export const TimeIntervalAddForm = (time_interval: ISharedCreateTimeInterval, props: ITimeIntervalFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'time_interval_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'interval', },
    { ref: 'open', },
  ],
});

export const TimeIntervalAddInitialValues: ISharedCreateTimeInterval = {} as ISharedCreateTimeInterval;

export const TimeIntervalEditInitialValues = (time_interval: ISharedTimeInterval): ISharedUpdateTimeInterval => ({
  title: time_interval.title,
  interval: time_interval.interval,
  open: time_interval.open,
});

export const onTimeIntervalFormSaved = (t: TFunction, action: 'edit' | 'add', time_interval: ISharedTimeInterval, navigate: NavigateFunction): void => {
  navigate(onFormSaved('time_intervals'));
};
