import React from 'react';
import './header.create.conversation.popover.scss';
import { MailIcon, } from '@Assets/icons';
import { ChannelEnum, } from 'atlas-shared';
import { useNavigate, } from 'react-router-dom';
import { IconText, } from '@Components';
import { TFunction, } from 'i18next';

interface IProps {
  t: TFunction;
  channels: Array<ChannelEnum>;
}

export const HeaderCreateConversationPopover = React.memo(({ channels, t, }: IProps) => {

  const navigate = useNavigate();
  // const startConversation = useStartConversation();

  return <div id='header-create-conversation-list'>
    <ul>
      {
        channels.map(channel => <li key={channel} onClick={_ => navigate(`/create/conversation?channel=${channel}`)}>
          <IconText icon={MailIcon} text={t(`CHANNEL_${channel}`)} />
        </li>)
      }
      {/*<li><IconText icon={PhoneIcon} text={t('CALL')} onClick={e => startConversation(ChannelEnum.Voice)} /></li>
          <li><IconText icon={MailIcon} text={t('EMAIL')} onClick={e => history.push(`/create/conversation?channel=${ChannelEnum.Mail}`)} /></li>*/}
    </ul>
  </div>;
});
