import React, { useState, useEffect, useMemo, useRef, useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import './editor-froala.scss';
import '../editor/editor.scss';
import 'froala-editor/js/plugins.pkgd.min.js';
import { Popover, Upload, } from 'antd';
import { IPreviewCannedAnswer, Undefinable, IPreviewDraft, } from 'atlas-shared';
import { TEditorHashOptions, TEditorAtOptions, } from '@Hooks';
import Tribute from 'tributejs';
import 'tributejs/dist/tribute.css';
import { Icon, } from '..';
import { DownloadIcon, TrashIcon, } from '@Assets';
import { HttpRequestHeader, } from 'antd/es/upload/interface';
import { UploadFile, } from 'antd/lib/upload/interface';
import { htmlElementOnDropFiles, RestRequest, } from '@Utils';
import { actionDocsPreview, useAppDispatch, } from '@Store';
import { copyCannedAnswerAttachments, } from '@Api';
import FroalaEditor from 'froala-editor';
import Editor from 'react-froala-wysiwyg';
import { IFormElementHtml, } from 'atlas-form';

export interface IQuillFileUpload {
  attachments: Array<{filename: string}>;
  action: string;
  headers: HttpRequestHeader;
  onDelete: (uid: number) => void;
  onDownload: (uid: number, name: string) => void;
  onDownloadUri: (index: number) => string;
  onChange?: (file: UploadFile<any>, fileList: Array<UploadFile<any>>) => void;
  dragAndDrop?: boolean;
  draft_id?: IPreviewDraft['id'];
  static?: true;
}

interface IFroalaProps {
  defaultValue?: string;
  onChangeTrigger?: (value: string) => void;
  onBlur?: (value: string) => void;
  placeholder?: string;
  hashOptions?: TEditorHashOptions;
  atOptions?: TEditorAtOptions;
  className?: string;
  canned_answers?: Array<IPreviewCannedAnswer>;
  style?: React.CSSProperties;
  file_upload?: IQuillFileUpload;
  disabled?: boolean;
  editorToggle: any;
  tableImport?: IFormElementHtml['tableImport'];
}

const generateId = () => 'editor-froala-' + (Math.random() + '').replace('.', '-');

export const EditorFroala = React.memo((props: IFroalaProps) => {

  const dispatch = useAppDispatch();
  const { t, } = useTranslation();
  const [value, setValue, ] = useState<string>(props?.defaultValue || '');
  const [id, setId, ] = useState(generateId());
  const [key, setKey, ] = useState(generateId());
  const ref = useRef<any>(null);
  const [defaultValue, setDefaultValue, ] = useState(props.defaultValue || '<p></p>');
  const [cannedAnswerSearch, setCannedAnswerSearch, ] = useState<Undefinable<string>>();
  const canned_answers = useMemo(() => !cannedAnswerSearch || !props.canned_answers?.length ? props.canned_answers || [] : props.canned_answers.filter(ca => ca.title.toLowerCase().includes(cannedAnswerSearch)), [props.canned_answers, cannedAnswerSearch, ]);
  const canned_answer_ref = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [loaded, setLoaded, ] = useState(false);
  const [default_file_list, set_default_file_list, ] = useState<IQuillFileUpload['attachments']>();
  const [uploader_key, set_uploader_key, ] = useState<string>(generateId());
  const [tributeAt, tributeHash, tributeStar, ] = useMemo(() => {
    return [
      !!props.atOptions?.length && new Tribute({
        trigger: '@',
        values: props.atOptions,
        selectTemplate: function(item) {
          return `<span contenteditable="false" class="mention fr-deletable fr-tribute" data-denotation-char="@" data-id="${item.original.id}">@${item.original.value}</span>`;
        },
        menuItemTemplate: function(item) {
          return `<span class="option">${item.original.value}</span>`;
        },
      }),
      !!props.hashOptions?.length && new Tribute({
        trigger: '#',
        values: props.hashOptions,
        selectTemplate: function(item) {
          return `<span contenteditable="false" class="mention fr-deletable fr-tribute" class="mention" data-denotation-char="#" data-id="${item.original.id}">#${item.original.value}</span>`;
        },
        menuItemTemplate: function(item) {
          return `<span class="option">${item.original.value}</span>`;
        },
      }),
      !!props.canned_answers?.length && new Tribute({
        trigger: '*',
        values: props.canned_answers,
        selectTemplate: function(item) {
          return item.original.body;
        },
        menuItemTemplate: function(item) {
          return `<span class="option">${item.original.title}</span>`;
        },
      }),
    ];
  }, [props.atOptions, props.hashOptions, props.canned_answers, ]);
  const canned_answer_ref_height = useMemo(() => {
    let px = cannedAnswerSearch && canned_answer_ref.current?.clientHeight;

    if (px && px > 200)
      px = 200;

    return px || 'auto';
  }, [cannedAnswerSearch, ]);
  const [dragOver, setDragOver, ] = useState<boolean>(false);
  const hasOndrop = props.file_upload?.dragAndDrop && props.file_upload?.action;
  const onDrop = useMemo(() => {
    if (props.file_upload?.dragAndDrop && props.file_upload?.action)
      return htmlElementOnDropFiles(props.file_upload.action, 'attachment', () => {
        setDragOver(false);
      }, () => {
        // @ts-ignore
        props.file_upload?.onChange({ status: 'done', });
      });

    return null;
  }, [props.file_upload, ]);
  const onDragOver = useCallback((event) => {

    if (!props.file_upload?.dragAndDrop)
      return;

    if (event.target?.className?.toString().includes('ant-upload-button')) {
      if (dragOver)
        setDragOver(false);

      return;
    }

    if (!dragOver)
      setDragOver(true);

    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'move';
  }, [dragOver, ]);
  const onDragLeave = useCallback(() => setDragOver(false), []);

  useEffect(() => {
    if (props.file_upload?.static && default_file_list)
      return;

    const _ = props.file_upload?.attachments || [];

    if (JSON.stringify(_) !== JSON.stringify(default_file_list || []))
      set_default_file_list(_);
  }, [props.file_upload, ]);

  useEffect(() => {
    set_uploader_key(generateId());
  }, [default_file_list, ]);

  useEffect(() => {
    props.onChangeTrigger?.(value);
  }, [value, ]);

  useEffect(() => {
    props.tableImport?.forEach(({ key, title, callback, }) => {
      const ImportIconKey = `CustomImportButton_${key}_Icon`;
      const ImportButtonKey = `CustomImportButton_${key}_Button`;

      FroalaEditor.DefineIcon(ImportIconKey, { NAME: key, SVG_KEY: 'insertAll', });
      FroalaEditor.RegisterCommand(ImportButtonKey, {
        title,
        icon: ImportIconKey,
        undo: true,
        focus: true,
        refreshAfterCallback: true,
        callback: function() {
          callback(this);
        },
      });
    });

    setLoaded(true);
  }, [props.tableImport, ]);

  if ((!onDrop && hasOndrop) || !loaded)
    return <></>;

  return (
    <span
      onBlur={() => {
        props.onBlur?.(value);
      }}
      onDrop={onDrop || undefined}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDragEnd={onDragLeave}
    >
      {props.editorToggle}
      <div className='ql-wrapper froala-wrapper'>
        {dragOver && <div className='drop-info'>{t('DROP_TO_ATTACH_FILE')}</div>}
        <Editor
          ref={ref}
          model={value}
          onModelChange={setValue}
          config={{
            key: (process.env.REACT_APP_FROALA_LICENSE || '').trim(),
            attribution: false,
            toolbarButtons: {
              'moreText': {
                'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting', ],
              },
              'moreParagraph': {
                'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote', ],
              },
              'moreRich': {
                'buttons': ['insertLink', 'insertImage', 'insertTable', 'emoticons', 'specialCharacters', 'insertHR', ],
              },
              'moreMisc': {
                'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help', ],
              },
              'moreImport': {
                'buttons': ['CustomImportButton_form_submission_Button', 'CustomImportButton_conversation_form_Button', ],
              },
            },
            quickInsertButtons: ['image', 'table', 'ol', 'ul', ],
            imageDefaultWidth: 'auto',
            imageInsertButtons: ['imageUpload', 'imageByURL', ],
            events: {
              initialized: function() {

                const editor: any = this;

                if (tributeHash)
                  tributeHash.attach(editor.el);

                if (tributeAt)
                  tributeAt.attach(editor.el);

                if (tributeStar)
                  tributeStar.attach(editor.el);

                editor.events.on('keydown', function(e) {
                  // @ts-ignore
                  return (e.which === 13 && (tributeAt?.isActive || tributeHash?.isActive || tributeStar?.isActive)) ? false : e;
                }, true);
              },
              'file.beforeUpload': function(files) {

                const editor: any = this;

                editor.popups.hideAll();

                return false;
              },
              'image.beforeUpload': function(files) {
                const editor: any = this;

                if (files.length) {
                  // Create a File Reader.
                  const reader = new FileReader();

                  // Set the reader to insert images when they are loaded.
                  reader.onload = function(e) {
                    const result = e.target!.result;

                    editor.image.insert(result, false);
                  };
                  // Read image as base64.
                  reader.readAsDataURL(files[0]);
                }

                editor.popups.hideAll();

                return false;
              },
            },
          }}
          //toolbarButtons={'undo redo | headers bold italic underline strikethrough hr | link image numlist bullist alignment direction removeformat table code'}
        />
        <div className='editor-additional'>
          {!!props.canned_answers?.length && <div className='canned-answers' ref={canned_answer_ref}>
            <Popover
              overlayClassName='conversation-message-reply-canned-answers-popover'
              content={<div className='canned-answers' ref={canned_answer_ref} style={{ height: canned_answer_ref_height, }}>
                {((props.canned_answers?.length || 0) > 1 || cannedAnswerSearch) && <div className='search'>
                  <input placeholder={`${t('SEARCH')}...`} onChange={e => setCannedAnswerSearch((e.target?.value || '').toLocaleLowerCase())} />
                </div>}
                <ul>
                  {canned_answers.map(canned_answer => <li
                    key={canned_answer.id}
                    onClick={_ => {
                      if (props.file_upload?.draft_id && canned_answer.attachments?.length)
                        copyCannedAnswerAttachments(props.file_upload?.draft_id, canned_answer.id);

                      ref.current.editor.html.insert((canned_answer.body || ''), false);
                    }}
                    title={canned_answer.title}
                  >
                    <span className='text'>{canned_answer.title}</span>
                    <span className='select-btn'>{t('SELECT')}</span>
                  </li>)}
                </ul>
              </div>}
            >
              <label>{t('CANNED_ANSWERS')}</label>
            </Popover>
            <div className='border'></div>
          </div>}
          {
            props.file_upload && <div className='attachments'>
              <label>
                {t('ATTACHMENTS')}
                {(default_file_list?.length || 0) > 0 && <span style={{ marginLeft: 5, opacity: .5, }}>{t('N_FILES', {
                  replace: { files: default_file_list?.length || 0, },
                })}</span>}
              </label>
              <Upload
                name='attachment'
                key={uploader_key}
                action={props.file_upload.action}
                headers={props.file_upload.headers}
                defaultFileList={(default_file_list || []).map((attachment, i) => ({
                  uid: i + '',
                  name: attachment.filename,
                  status: 'done',
                }))}
                showUploadList={{
                  removeIcon: <Icon icon={TrashIcon} />,
                  downloadIcon: <Icon icon={DownloadIcon} />,
                  showDownloadIcon: true,
                }}
                onRemove={file => props.file_upload?.onDelete(+file.uid)}
                onDownload={file => props.file_upload?.onDownload(+file.uid, file.name)}
                onChange={({ file, fileList, }) => {
                  props.file_upload?.onChange?.(file, fileList);
                  if (props.file_upload?.static)
                    set_default_file_list(fileList
                      .filter(({ status, }) => status === 'done')
                      .map(({ name, }) => ({ filename: name, }))
                    );
                }}
                customRequest={(e) => {
                  if (!e)
                    return;

                  RestRequest.upload(e.action, e.file, 'attachment')
                    .then(e.onSuccess)
                    .catch(e.onError);
                  //e?.onSuccess?.(() => {});
                }}
                onPreview={file => {
                  if (props.file_upload?.onDownloadUri && default_file_list?.length)
                    dispatch(actionDocsPreview({
                      index: +file.uid,
                      docs: default_file_list.map((attachment, i) => ({ uri: props.file_upload!.onDownloadUri(i), filename: attachment.filename, })),
                    }));
                }}
                disabled={props.disabled}
              >
                <button className='ant-btn ant-btn-default ant-upload-button' onDrop={(event) => {
                  alert('Dropped file on upload button will not be attached!');
                  event.stopPropagation();
                  event.preventDefault();
                }}>{t('UPLOAD')}</button>
              </Upload>
              <div className='border'></div>
            </div>
          }
        </div>

      </div>
    </span>
  );
});

export default Editor;
