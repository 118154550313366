import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchGdprs, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useGdprs = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const gdprs = useSelector((store: IReducerStore) => store.gdprs);

  useEffect(() => {
    if (!initiated && autoload && !gdprs.loaded && !gdprs.loading) {
      initiated = true;
      dispatch(actionFetchGdprs());
    }
  }, [autoload, gdprs.loaded, gdprs.loading, dispatch, ]);

  return gdprs;
};
