import { FormLayoutTypes, IForm, IFormOptions, } from 'atlas-form';
import {
  AdminprofilePermissionIdEnum,
  EnumValues,
  IPreviewAdminprofile,
  IPreviewAgentprofile,
  IPreviewCannedAnswer,
  IPreviewDisposition,
  IPreviewField,
  IPreviewForm,
  IPreviewList,
  IPreviewMailAccount,
  IPreviewJourney,
  IPreviewQueue,
  IPreviewOperationalHours,
  IPreviewOrganization,
  IPreviewPause,
  IPreviewSignature,
  IPreviewSlaPolicy,
  IPreviewTag,
  IPreviewTeam,
  IPreviewTimeInterval,
  IPreviewUser,
  ISharedAdminprofile,
  ISharedCreateAdminprofile,
  IPreviewDestinationRestrictor,
  IPreviewVoiceVoicemail,
  IPreviewVoiceShortcut,
  PermissionEnum,
  IPreviewInvoice,
  IPreviewSound,
  IPreviewClient,
  IPreviewVoiceRoute,
} from 'atlas-shared/dist';
import { TFunction, } from 'i18next';
import { filterRelated, } from '@Utils';
import {
  IAdminprofileFormOptionsProps,
  AdminprofileAdditionalParams as AdminprofileAdditionalParamsBase,
  AdminprofileFormOptions as AdminprofileFormOptionsBase,
} from './abstract/adminprofile.form.abstract';
import { FormComponentCrud, } from './components/crud';
import { IAuth, } from '@Store';

export * from './abstract/adminprofile.form.abstract';

const PERMISSIONS_WITH_NO_CONDITIONS = [
  PermissionEnum.VoiceRecording,
  PermissionEnum.VoiceVoicemailMessage,
  PermissionEnum.StatsWidget,
  PermissionEnum.StatsReport,
  PermissionEnum.PhonenumberPurchase,
  PermissionEnum.Contact,
  PermissionEnum.ContactCustomEvent,
];

export const AdminprofileAdditionalParams: (auth: IAuth, props: IAdminprofileFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IAdminprofileFormOptionsProps) => ({
  ...AdminprofileAdditionalParamsBase(auth, props),
  permissions: {
    shouldUpdate: (prev, next) => prev.functionParams[0]?.organization_id !== next.functionParams[0].organization_id,
  },
  permissions_id: { hideLabel: true, row_style: { width: 150, }, },
  permissions_crud: {
    hideLabel: true,
    shouldUpdate: (prev, next) => prev.functionParams[1]?.id !== next.functionParams[1].id || prev.functionParams[1]?.allow !== next.functionParams[1].allow || !!prev.functionParams[1]?.conditions !== !!next.functionParams[1].conditions,
  },
  // permissions_allow: (adminprofile, permission) => {
  //   return {
  //     hidden: PERMISSIONS_WITH_NO_CONDITIONS.includes(permission.id)
  //   };
  // },
  permissions_conditions: (adminprofile, permission) => ({
    ui_layout: FormLayoutTypes.INLINE,
    label: 'FILTER',
    noPadding: true,
    shouldUpdate: (prev, next) => prev.functionParams[0]?.organization_id !== next.functionParams[0].organization_id || prev.functionParams[1]?.id !== next.functionParams[1].id,
    hidden: PERMISSIONS_WITH_NO_CONDITIONS.includes(permission.id),
  }),
  permissions_conditions_id: {
    hideLabel: true,
    row_style: { marginRight: 0, },
    element_style: { width: 240, },
  },
  permissions_conditions_user_id: { hidden: true, },
});

export const AdminprofileComponents: IForm['components'] = {
  permissions_crud: FormComponentCrud,
};

export const AdminprofileAddInitialValues: ISharedCreateAdminprofile = {
  permissions: EnumValues(AdminprofilePermissionIdEnum).map(table => ({
    id: table,
    crud: 15,
    allow: true,
    conditions: null,
  })),
} as any;

export const AdminprofileFormOptions = (adminprofile: ISharedCreateAdminprofile | ISharedAdminprofile, props: IAdminprofileFormOptionsProps, t: TFunction): IFormOptions => ({
  ...(AdminprofileFormOptionsBase(adminprofile, props, t)),
  permissions_conditions_id: (base, parent, _self, parent_parent) => {
    const table = parent_parent?.id;

    if (table)
      return {
        [AdminprofilePermissionIdEnum.Organization]: (it: ISharedAdminprofile) => filterRelated<IPreviewOrganization>(it.organization_id || adminprofile.organization_id, 'organization', 'adminprofile', props.organizations, true),
        [AdminprofilePermissionIdEnum.Pause]: (it: ISharedAdminprofile) => filterRelated<IPreviewPause>(it.organization_id || adminprofile.organization_id, 'pause', 'adminprofile', props.pauses, true, undefined, true),
        [AdminprofilePermissionIdEnum.User]: (it: ISharedAdminprofile) => filterRelated<IPreviewUser>(it.organization_id || adminprofile.organization_id, 'user_reverse', 'adminprofile', props.users, true),
        [AdminprofilePermissionIdEnum.Adminprofile]: (it: ISharedAdminprofile) => filterRelated<IPreviewAdminprofile>(it.organization_id || adminprofile.organization_id, 'adminprofile', 'adminprofile', props.adminprofiles, true, undefined, true),
        [AdminprofilePermissionIdEnum.Agentprofile]: (it: ISharedAdminprofile) => filterRelated<IPreviewAgentprofile>(it.organization_id || adminprofile.organization_id, 'agentprofile', 'adminprofile', props.agentprofiles, true, undefined, true),
        [AdminprofilePermissionIdEnum.CannedAnswer]: (it: ISharedAdminprofile) => filterRelated<IPreviewCannedAnswer>(it.organization_id || adminprofile.organization_id, 'canned_answer', 'adminprofile', props.canned_answers, true, undefined, true),
        [AdminprofilePermissionIdEnum.Disposition]: (it: ISharedAdminprofile) => filterRelated<IPreviewDisposition>(it.organization_id || adminprofile.organization_id, 'conversation.disposition', 'adminprofile', props.dispositions, true, undefined, true),
        [AdminprofilePermissionIdEnum.Form]: (it: ISharedAdminprofile) => filterRelated<IPreviewForm>(it.organization_id || adminprofile.organization_id, 'form', 'adminprofile', props.forms, true, undefined, true),
        [AdminprofilePermissionIdEnum.Field]: (it: ISharedAdminprofile) => filterRelated<IPreviewField>(it.organization_id || adminprofile.organization_id, 'field', 'adminprofile', props.fields, true, undefined, true),
        [AdminprofilePermissionIdEnum.Journey]: (it: ISharedAdminprofile) => filterRelated<IPreviewJourney>(it.organization_id || adminprofile.organization_id, 'journey', 'adminprofile', props.journeies, true, undefined, true),
        [AdminprofilePermissionIdEnum.List]: (it: ISharedAdminprofile) => filterRelated<IPreviewList>(it.organization_id || adminprofile.organization_id, 'list', 'adminprofile', props.lists, true, undefined, true),
        [AdminprofilePermissionIdEnum.MailAccount]: (it: ISharedAdminprofile) => filterRelated<IPreviewMailAccount>(it.organization_id || adminprofile.organization_id, 'mail_account', 'adminprofile', props.mail_accounts, true),
        [AdminprofilePermissionIdEnum.Queue]: (it: ISharedAdminprofile) => filterRelated<IPreviewQueue>(it.organization_id || adminprofile.organization_id, 'queue', 'adminprofile', props.queues, true, undefined, true),
        [AdminprofilePermissionIdEnum.OperationalHours]: (it: ISharedAdminprofile) => filterRelated<IPreviewOperationalHours>(it.organization_id || adminprofile.organization_id, 'operational_hours', 'adminprofile', props.operational_hourses, true, undefined, true),
        [AdminprofilePermissionIdEnum.Signature]: (it: ISharedAdminprofile) => filterRelated<IPreviewSignature>(it.organization_id || adminprofile.organization_id, 'signature', 'adminprofile', props.signatures, true, undefined, true),
        [AdminprofilePermissionIdEnum.SlaPolicy]: (it: ISharedAdminprofile) => filterRelated<IPreviewSlaPolicy>(it.organization_id || adminprofile.organization_id, 'sla_policy', 'adminprofile', props.sla_policies, true, undefined, true),
        [AdminprofilePermissionIdEnum.Tag]: (it: ISharedAdminprofile) => filterRelated<IPreviewTag>(it.organization_id || adminprofile.organization_id, 'tag', 'adminprofile', props.tags, true, undefined, true),
        [AdminprofilePermissionIdEnum.Team]: (it: ISharedAdminprofile) => filterRelated<IPreviewTeam>(it.organization_id || adminprofile.organization_id, 'team', 'adminprofile', props.teams, true, undefined, true),
        [AdminprofilePermissionIdEnum.TimeInterval]: (it: ISharedAdminprofile) => filterRelated<IPreviewTimeInterval>(it.organization_id || adminprofile.organization_id, 'time_interval', 'adminprofile', props.time_intervals, true, undefined, true),
        [AdminprofilePermissionIdEnum.DestinationRestrictor]: (it: ISharedAdminprofile) => filterRelated<IPreviewDestinationRestrictor>(it.organization_id || adminprofile.organization_id, 'destionation_rectrictor', 'adminprofile', props.destination_restrictors, true, undefined, true),
        [AdminprofilePermissionIdEnum.VoiceVoicemail]: (it: ISharedAdminprofile) => filterRelated<IPreviewVoiceVoicemail>(it.organization_id || adminprofile.organization_id, 'voice_voicemail', 'adminprofile', props.voice_voicemails, true, undefined, true),
        [AdminprofilePermissionIdEnum.VoiceShortcut]: (it: ISharedAdminprofile) => filterRelated<IPreviewVoiceShortcut>(it.organization_id || adminprofile.organization_id, 'voice_shortcut', 'adminprofile', props.voice_shortcuts, true, undefined, true),
        [AdminprofilePermissionIdEnum.Invoice]: (it: ISharedAdminprofile) => filterRelated<IPreviewInvoice>(it.organization_id || adminprofile.organization_id, 'invoice', 'adminprofile', props.invoices, true, undefined, true),
        [AdminprofilePermissionIdEnum.Sound]: (it: ISharedAdminprofile) => filterRelated<IPreviewSound>(it.organization_id || adminprofile.organization_id, 'sound', 'adminprofile', props.sounds, true, undefined, true),
        [AdminprofilePermissionIdEnum.Client]: (it: ISharedAdminprofile) => filterRelated<IPreviewClient>(it.organization_id || adminprofile.organization_id, 'client', 'adminprofile', props.clients, true, undefined, true),
        [AdminprofilePermissionIdEnum.Skill]: (it: ISharedAdminprofile) => filterRelated<IPreviewClient>(it.organization_id || adminprofile.organization_id, 'skill', 'adminprofile', props.skills, true, undefined, true),
        [AdminprofilePermissionIdEnum.VoiceRoute]: (it: ISharedAdminprofile) => filterRelated<IPreviewVoiceRoute>(it.organization_id || adminprofile.organization_id, 'voice_route', 'adminprofile', props.voice_routes, true, undefined, true),
      }?.[table]?.(base) || [];
  },
});
