import React, { useEffect, useState, } from 'react';
import '../admin.list.scss';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { ColumnsType, SorterResult, } from 'antd/lib/table/interface';
import { IPreviewVoiceTrunk, PermissionEnum, CrudEnum, TVoiceTrunkSortableListCol, } from 'atlas-shared';
import { Table, } from 'antd';
import { AdminListPageHeader, ListPageEmpty, } from '@Components';
import { IWithListProps, IWithListSorterProps, withList, } from '@Hocs/with.list';
import { useAbilities, useAuth, useVoiceTrunks, useOrganizations, } from '@Hooks';
import { VoiceTrunkListColumns, } from '@Utils';
import { IAuth, } from '@Store';

const VoiceTrunkList = ({ filters, filtersCount, hasFilters, sorter, pagination, setQueryParams, resetQueryParams, }: IWithListProps<IPreviewVoiceTrunk, TVoiceTrunkSortableListCol>) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth: IAuth = useAuth();
  const voice_trunks = useVoiceTrunks();
  const abilities = useAbilities();
  const [loading, setLoading, ] = useState<boolean>(true);
  const [rows, setRows, ] = useState<Array<any>>([]);
  const organizations = useOrganizations();
  
  const [showDeleted, setShowDeleted, ] = useState(false);
  const [deletedCount, setDeletedCount, ] = useState<number>(0);
  
  useEffect(() => {
    if (!voice_trunks.loaded)
      return;
      
    setLoading(false);
    setRows((!showDeleted ? voice_trunks.voice_trunks.filter(p => !p.deleted_at) : voice_trunks.voice_trunks || []).map(row => ({ ...row, key: row.id, })));
    setDeletedCount(voice_trunks.voice_trunks.filter(voice_trunk => !!voice_trunk.deleted_at).length);
  }, [voice_trunks, showDeleted, ]);

  const columns: ColumnsType<IPreviewVoiceTrunk> = React.useMemo(() => {
    return VoiceTrunkListColumns(t, navigate, showDeleted, organizations, abilities, auth, [], filters, sorter);
  }, [t, navigate, showDeleted, organizations, abilities, auth, filters, sorter, ]);

  return <div className='admin-list-view'>
    <AdminListPageHeader 
      t={t}
      title={t('VOICE_TRUNKS')}
      filtersCount={filtersCount}
      reset={() => resetQueryParams()}
      deleted={[showDeleted, setShowDeleted, ]} deletedCount={deletedCount}
      addBtn={abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.VoiceTrunk) ? { callback: () => navigate('/admin/voice_trunk/add'), } : undefined} 
    />
    <div className='table-wrp'>
      <Table
        key={loading ? 1 : 0}
        columns={columns}
        dataSource={rows}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
        onChange={(pagination, filters, _sorter) => {
          const sorter: Array<SorterResult<any>> = Array.isArray(_sorter) ? _sorter : _sorter?.columnKey ? [_sorter, ] : [];

          setQueryParams({
            pagination: { current: pagination.current as number, pageSize: pagination.pageSize as number, },
            filters: Object.fromEntries(Object.entries(filters).filter(([key, value, ]) => value !== null)),
            sorter: sorter
              .filter(s => !!s.order)
              .map(s => ({ column: s.columnKey as TVoiceTrunkSortableListCol, order: s.order as IWithListSorterProps<IPreviewVoiceTrunk, TVoiceTrunkSortableListCol>['order'], })),
          });
        }}
        locale={{
          emptyText: <ListPageEmpty t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => resetQueryParams()} />,
        }}
      />
    </div>
  </div>;
};

export const VoiceTrunkListView = React.memo(withList<IPreviewVoiceTrunk, TVoiceTrunkSortableListCol>(VoiceTrunkList, {
  filters: {},
  sorter: [{ column: 'created_at', order: 'descend', }, ],
  pagination: { current: 1, pageSize: 10, },
}));
