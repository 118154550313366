import { useEffect, useState, } from 'react';

let initiated = false;

export const useVisibilityChange = () => {
  const [isVisible, setIsVisible, ] = useState(!document.hidden);

  useEffect(() => {
    if (!initiated) {
      initiated = true;
      addEventListener('visibilitychange', (event) => {
        setIsVisible(!document.hidden);
      });
    }
  }, []);

  return isVisible;
};
