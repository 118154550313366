import { IPreviewVoiceAsterisk, } from 'atlas-shared';
import { IVoiceAsteriskStore, VoiceAsteriskActions, TVoiceAsteriskActionTypes, } from '@Store';

export const VoiceAsteriskReducer = (state: IVoiceAsteriskStore = {
  loaded: false,
  loading: false,
  voice_asterisks: [],
  dict: {},
}, action: TVoiceAsteriskActionTypes): IVoiceAsteriskStore => {
  switch (action.type) {
  case VoiceAsteriskActions.LOADING:
    return { ...state, loading: true, };
  case VoiceAsteriskActions.SET_STATE:
    const dict: IVoiceAsteriskStore['dict'] = {};

    action.payload.forEach((voice_asterisk: IPreviewVoiceAsterisk) => dict[voice_asterisk.id] = voice_asterisk);

    return { ...state, loaded: true, loading: false, voice_asterisks: action.payload, dict, };
  case VoiceAsteriskActions.ADD_STATE:
    return { ...state, voice_asterisks: [...state.voice_asterisks, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case VoiceAsteriskActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_asterisks: state.voice_asterisks.filter((item: IPreviewVoiceAsterisk) => item.id !== action.payload), dict, };
  }
  case VoiceAsteriskActions.UPDATE_STATE:
    const voice_asterisks = [...state.voice_asterisks, ];
    const index = state.voice_asterisks.findIndex((voice_asterisk: IPreviewVoiceAsterisk) => voice_asterisk.id === action.payload.id);

    if (!~index)
      voice_asterisks.push(action.payload);
    else
      voice_asterisks[index] = action.payload;

    return { ...state, voice_asterisks, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
