import { Icon, } from '@Components';
import { TrashIcon, } from '@Assets/icons';
import { AlertError, Confirm, } from '@Utils';
import { Tooltip, } from 'antd';
import React from 'react';
import { TFunction, } from 'i18next';

export const listDeleteButton = <T extends {id: number}>(t: TFunction, row: T, deleteFunction: Function): React.ReactElement => {
  if ((row as any).removable === false)
    return <></>;

  return <Tooltip overlayClassName={'table-tooltip-custom'} arrowPointAtCenter title={t('DELETE')}
    placement="bottom">
    <div className={'table-icon-wrp'}>
      <Icon icon={TrashIcon} iconProps={{ size: 14, }} onClick={() => {
        Confirm(t, {
          content: t('CONFIRM_DELETION'),
          onOk: (modal) => {
            deleteFunction(row.id)
              .then(_ => modal.destroy())
              .catch(err => {
                AlertError(t, {
                  content: t(err),
                });
              });
          },
        });
      }} />
    </div>
  </Tooltip>;
};
