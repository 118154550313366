import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, IUserStore, } from '@Store';
import { IPreviewStatsReport, TStatsReportSortableListCol, } from 'atlas-shared';
import React from 'react';
import { ColumnsType, } from 'antd/lib/table/interface';
import { StatsReportListColumns as StatsReportListColumnsBase, } from './abstract/stats_report.list.column.abstract';
import { IWithListProps, } from '@Hocs/with.list';

export const StatsReportListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, users: IUserStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewStatsReport) => React.ReactElement> = [], filters: IWithListProps<IPreviewStatsReport, TStatsReportSortableListCol>['filters'], sorter: IWithListProps<IPreviewStatsReport, TStatsReportSortableListCol>['sorter']): ColumnsType<IPreviewStatsReport> => {
  const columns = StatsReportListColumnsBase(t, navigate, showDeleted, organizations, users, abilities, auth, buttons, filters, sorter);

  columns[2].title = t('OWNER');
  
  return columns;
};
