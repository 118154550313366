import { IPreviewVoiceShortcut, } from 'atlas-shared';

export enum VoiceShortcutActions {
  SET_STATE = 'VOICE_SHORTCUT_SET_STATE',
  ADD_STATE = 'VOICE_SHORTCUT_ADD_STATE',
  REMOVE_STATE = 'VOICE_SHORTCUT_REMOVE_STATE',
  GET_STATE = 'VOICE_SHORTCUT_GET_STATE',
  UPDATE_STATE = 'VOICE_SHORTCUT_UPDATE_STATE',
  LOADING = 'VOICE_SHORTCUT_LOADING',
}

export interface IVoiceShortcutLoading {
  type: VoiceShortcutActions.LOADING;
}

export interface IVoiceShortcutSetAction {
  type: VoiceShortcutActions.SET_STATE;
  payload: Array<IPreviewVoiceShortcut>;
}

export interface IVoiceShortcutUpdateAction {
  type: VoiceShortcutActions.UPDATE_STATE;
  payload: IPreviewVoiceShortcut;
}

export interface IVoiceShortcutAddAction {
  type: VoiceShortcutActions.ADD_STATE;
  payload: IPreviewVoiceShortcut;
}

export interface IVoiceShortcutRemoveAction {
  type: VoiceShortcutActions.REMOVE_STATE;
  payload: IPreviewVoiceShortcut['id'];
}

export interface IVoiceShortcutStore {
  loaded: boolean;
  loading: boolean;
  voice_shortcuts: Array<IPreviewVoiceShortcut>;
  dict: Record<IPreviewVoiceShortcut['id'], IPreviewVoiceShortcut>;
}

export type TVoiceShortcutActionTypes = IVoiceShortcutSetAction | IVoiceShortcutUpdateAction | IVoiceShortcutAddAction | IVoiceShortcutRemoveAction | IVoiceShortcutLoading;
