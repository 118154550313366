import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchStatsWidgets, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useStatsWidgets = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const stats_widgets = useSelector((store: IReducerStore) => store.stats_widgets);

  useEffect(() => {
    if (!initiated && autoload && !stats_widgets.loaded && !stats_widgets.loading) {
      initiated = true;
      dispatch(actionFetchStatsWidgets());
    }
  }, [autoload, stats_widgets.loaded, stats_widgets.loading, dispatch, ]);

  return stats_widgets;
};
