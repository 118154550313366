import React, { useMemo, } from 'react';
import './widget-type.table.scss';
import './widget-type.grid.scss';
import { getStatsWidgetMetricData, getStatsWidgetTableMetricBoxStyles, getStatsWidgetTableMetricStyles, } from '@Utils';
import { StatsWidgetMetricData, } from '../../stats_widget.metric.data';
import { IWithStatsWidgetProps, withStatsWidget, } from '@Hocs/with.stats_widget';

export const _WidgetTypeGrid = ({ data: _data, metrics, stats_widget, t: _t, metric_data_dicts, organization, }: IWithStatsWidgetProps) => {

  const isSummaryOrSplit = _data?.g && ['summary', 'split', ].includes(_data?.g);
  const data = isSummaryOrSplit ? _data.r?.[0]?.c : _data;
  const summary: any = isSummaryOrSplit ? _data.r[0] : false;

  const [group_y, group_x, ] = stats_widget.settings.group_by;
  const { x, y, r, } = useMemo(() => {
    const x: Array<string> = [];
    const y: Array<string> = [];
    const r: Record<string, {d: number; t?: number; tc?: number; tp?: [string, string]}> = {};

    if (data)
      data.r.forEach((row) => {
        if (!x.includes(row.d[1]))
          x.push(row.d[1]);

        if (!y.includes(row.d[0]))
          y.push(row.d[0]);

        r[`${row.d[1]}-${row.d[0]}`] = {
          d: row.d[2],
          t: row?.t?.[0],
          tc: row?.tc?.[0],
          tp: row?.tp,
        };
      });
    
    return { x, y, r, };
  }, [data, ]);

  const { summary_x, summary_y, } = useMemo(() => {
    const empty_x = new Array(x.length).fill(0);
    const summary_x = { d: [...empty_x, ], t: [...empty_x, ], tc: [...empty_x, ], };
    const empty_y = new Array(y.length).fill(0);
    const summary_y = { d: [...empty_y, ], t: [...empty_y, ], tc: [...empty_y, ], };

    if (stats_widget.settings.summary) {
      y.forEach((_y, yi) => {
        x.forEach((_x, xi) => {
          const _r = r[`${_x}-${_y}`];

          if (_r?.d) {
            summary_x.d[xi] += +_r.d;
            summary_x.t[xi] += +(_r.t || 0);
            summary_x.tc[xi] += +(_r.tc || 0);
            summary_y.d[yi] += +_r.d;
            summary_y.t[xi] += +(_r.t || 0);
            summary_y.tc[xi] += +(_r.tc || 0);
          }
        });
      });
    }

    return { summary_x, summary_y, };
  }, [stats_widget, x, y, r, ]);

  if (!data)
    return <>no data</>;

  const y_styles = getStatsWidgetTableMetricStyles(group_y, 0, 0, undefined);
  const y_box_styles = getStatsWidgetTableMetricBoxStyles(group_y, 0, 0, undefined);
  const x_styles = getStatsWidgetTableMetricStyles(group_x, 0, 0, undefined);
  const x_box_styles = getStatsWidgetTableMetricBoxStyles(group_x, 0, 0, undefined);

  return <div className='widget-type-grid'>
    <table cellPadding={0} cellSpacing={0}>
      <thead>
        <tr>
          <th></th>
          {x.map(v => {
            const txt = getStatsWidgetMetricData(_t, group_x, v, metric_data_dicts, organization);

            return <th style={x_styles} title={txt}>
              <span>
                <div>
                  <div className='text'>
                    <div className='value' style={x_box_styles}>{txt}</div>
                  </div>
                </div>
              </span>
            </th>;
          })}
          {summary && <th className='total-title'>{_t('TOTAL')}</th>}
        </tr>
      </thead>
      <tbody>
        {y.map((_y, yi) => {
          return <tr>
            <td style={y_styles}>
              <div className='metric'>
                <div>
                  <div className='value' style={y_box_styles}>{getStatsWidgetMetricData(_t, group_y, _y, metric_data_dicts, organization)}</div>
                </div>
              </div>
            </td>
            {x.map(_x => {
              const { d, t, tc, tp, } = r[`${_x}-${_y}`] || {};

              return <td>
                <StatsWidgetMetricData
                  t={_t}
                  stats_widget={stats_widget}
                  metric_settings={stats_widget.settings.metrics[0]}
                  metric={metrics[0]}
                  value={d || 0}
                  trend_value={t}
                  trend_period={tp}
                  trend_comparison={tc}
                  metric_data_dicts={metric_data_dicts}
                  organization={organization}
                />
              </td>;
            })}
            {summary && <td className='y-summary'>
              <StatsWidgetMetricData
                t={_t}
                stats_widget={stats_widget}
                metric_settings={stats_widget.settings.metrics[0]}
                metric={metrics[0]}
                value={summary_y.d[yi] || 0}
                trend_value={summary_y.t[yi]}
                trend_period={summary.tp}
                trend_comparison={summary_y.tc[yi]}
                metric_data_dicts={metric_data_dicts}
                organization={organization}
              />
            </td>}
          </tr>;
          
        })}
      </tbody>
      <tfoot>
        {summary && <tr className='total'>
          <td className='total-title'>{_t('TOTAL')}</td>
          {summary_x.d.map((_, i) => {
            const metric_index = stats_widget.settings.split_by.length + i;
            const [metric, ] = stats_widget.settings.metrics;

            return <td>
              <StatsWidgetMetricData
                t={_t}
                stats_widget={stats_widget}
                metric_settings={metric}
                metric={metrics[0]}
                value={summary_x.d[metric_index]}
                trend_value={summary_x.t?.[i]}
                trend_comparison={summary_x.tc?.[i]}
                trend_period={summary.tp}
                metric_data_dicts={metric_data_dicts}
                organization={organization}
              />
            </td>;
          })}
          <td></td>
        </tr>}
      </tfoot>
    </table>
  </div>;
};

export const WidgetTypeGrid = React.memo(withStatsWidget(_WidgetTypeGrid));
