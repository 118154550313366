import moment from 'moment';

export const formatDate = (time: string, format: string = 'HH:mm') => {
  try {
    return moment(time).format(format);
  } catch {
    return time;
  }
};

export const nameSplitter = (name?: string): string => {
  if (!name) return '';

  let nameSplit = name.split(' ');
  let nameTmp = '';

  if (nameSplit.length)
    nameTmp = nameSplit[0].split('')[0];

  if (nameSplit.length && nameSplit[1] && nameSplit[1].length)
    return nameTmp + nameSplit[1].split('')[0];

  return nameTmp;
};

export const moveArrayIndex = (arr: any[], fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];

  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

export const replaceIndexes = (arr: any[], from: number, to: number) => {
  const fromVal = arr[from];
  const toVal = arr[to];

  if (!fromVal || !toVal) {
    return;
  }

  arr[from] = toVal;
  arr[to] = fromVal;
};
