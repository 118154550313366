import { InvoiceActions, } from '@Store';
import { IPreviewInvoice, } from 'atlas-shared';
import { fetchInvoices, } from '@Api';

export const actionFetchInvoices = () => {
  return async (dispatch) => {
    dispatch(actionLoadingInvoices());
    const invoices = await fetchInvoices();

    dispatch(actionSetInvoices(invoices));
    return invoices;
  };
};

export const actionLoadingInvoices = () => ({
  type: InvoiceActions.LOADING,
  payload: [],
});

export const actionSetInvoices = (payload: Array<IPreviewInvoice>) => ({
  type: InvoiceActions.SET_STATE,
  payload: payload,
});

export const actionAddInvoice = (payload: IPreviewInvoice) => ({
  type: InvoiceActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateInvoice = (payload: IPreviewInvoice) => ({
  type: InvoiceActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveInvoice = (id: IPreviewInvoice['id']) => ({
  type: InvoiceActions.REMOVE_STATE,
  payload: id,
});
