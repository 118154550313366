import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchVoiceRoutes, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useVoiceRoutes = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const voice_routes = useSelector((store: IReducerStore) => store.voice_routes);

  useEffect(() => {
    if (!initiated && autoload && !voice_routes.loaded && !voice_routes.loading) {
      initiated = true;
      dispatch(actionFetchVoiceRoutes());
    }
  }, [autoload, voice_routes.loaded, voice_routes.loading, dispatch, ]);

  return voice_routes;
};
