import { VoiceShortcutActions, } from '@Store';
import { IPreviewVoiceShortcut, } from 'atlas-shared';
import { fetchVoiceShortcuts, } from '@Api';

export const actionFetchVoiceShortcuts = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceShortcuts());
    const voice_shortcuts = await fetchVoiceShortcuts();

    dispatch(actionSetVoiceShortcuts(voice_shortcuts));
    return voice_shortcuts;
  };
};

export const actionLoadingVoiceShortcuts = () => ({
  type: VoiceShortcutActions.LOADING,
  payload: [],
});

export const actionSetVoiceShortcuts = (payload: Array<IPreviewVoiceShortcut>) => ({
  type: VoiceShortcutActions.SET_STATE,
  payload: payload,
});

export const actionAddVoiceShortcut = (payload: IPreviewVoiceShortcut) => ({
  type: VoiceShortcutActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateVoiceShortcut = (payload: IPreviewVoiceShortcut) => ({
  type: VoiceShortcutActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveVoiceShortcut = (id: IPreviewVoiceShortcut['id']) => ({
  type: VoiceShortcutActions.REMOVE_STATE,
  payload: id,
});
