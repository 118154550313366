import { FormWebsiteActions, } from '@Store';
import { IPreviewFormWebsite, } from 'atlas-shared';
import { fetchFormWebsites, } from '@Api';

export const actionFetchFormWebsites = () => {
  return async (dispatch) => {
    dispatch(actionLoadingFormWebsites());
    const form_websites = await fetchFormWebsites();

    dispatch(actionSetFormWebsites(form_websites));
    return form_websites;
  };
};

export const actionLoadingFormWebsites = () => ({
  type: FormWebsiteActions.LOADING,
  payload: [],
});

export const actionSetFormWebsites = (payload: Array<IPreviewFormWebsite>) => ({
  type: FormWebsiteActions.SET_STATE,
  payload: payload,
});

export const actionAddFormWebsite = (payload: IPreviewFormWebsite) => ({
  type: FormWebsiteActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateFormWebsite = (payload: IPreviewFormWebsite) => ({
  type: FormWebsiteActions.UPDATE_STATE,
  payload: payload,
});
