import { RestRequest, } from '@Utils';
import { IPreviewRecentlySearched, ISharedRecentlySearched, ISharedCreateRecentlySearched, ISharedPatchRecentlySearched, } from 'atlas-shared';

export const fetchRecentlySearcheds = async (): Promise<Array<IPreviewRecentlySearched>> => {
  return RestRequest.get<Array<IPreviewRecentlySearched>>('recently_searched', { socket_id: global.socketId, });
};

export const fetchRecentlySearched = async (id: IPreviewRecentlySearched['id']): Promise<ISharedRecentlySearched> => {
  return RestRequest.get<ISharedRecentlySearched>(`recently_searched/${id}`, { socket_id: global.socketId, });
};

export const createRecentlySearched = (payload: ISharedCreateRecentlySearched) => {
  return RestRequest.post<any, ISharedRecentlySearched>('recently_searched', payload, { socket_id: global.socketId, });
};

export const patchRecentlySearched = (id: IPreviewRecentlySearched['id'], payload: ISharedPatchRecentlySearched) => {
  return RestRequest.patch<any, ISharedRecentlySearched>(`recently_searched/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteRecentlySearched = (id: IPreviewRecentlySearched['id']) => {
  return RestRequest.delete(`recently_searched/${id}`, { socket_id: global.socketId, });
};

export const getRecentlySearchedUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('recently_searched/update/schema');
};

export const getRecentlySearchedCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('recently_searched/create/schema');
};

export const getRecentlySearchedPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('recently_searched/patch/schema');
};
