import { RestRequest, } from '@Utils/rest';
import { ISharedAgentprofile, ISharedRecentlySearched, ISharedUser, Nullable, } from 'atlas-shared';

export * from './abstract/recently_searched.api.abstract';

export const fetchRecentlySearcheds = async (agentprofile_id?: Nullable<ISharedAgentprofile['id']>): Promise<Array<ISharedRecentlySearched>> => {
  return RestRequest.get<Array<ISharedRecentlySearched>>('recently_searched', { socket_id: global.socketId, agentprofile_id: agentprofile_id || undefined, });
};

export const clearRecentlySearched = (user_id: ISharedUser['id'], agentprofile_id: ISharedAgentprofile['id']) => {
  return RestRequest.delete(`recently_searched/clear/${agentprofile_id}/${user_id}`);
};
