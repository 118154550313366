import React, { Suspense, useEffect, useState, } from 'react';
import '../admin.list.scss';
import { useTranslation, } from 'react-i18next';
import { useNavigate, useParams, } from 'react-router-dom';
import { ColumnsType, } from 'antd/lib/table/interface';
import { IAdminprofileUser, IPreviewOrganization, IPreviewUser, IPreviewAdminprofile, } from 'atlas-shared';
import { Table, } from 'antd';
import { Spinner, ListPageEmpty, } from '@Components';
import {
  useAdminprofiles, useOrganizations,
  useUsers,
} from '@Hooks';
import { fetchAdminprofileUsers, } from '@Api';

import { getFullName, } from '@Utils';
import { GetColumnSearchEqualProps, GetColumnSearchProps, } from '@Utils/list/components';

interface IAdminprofileUserRow {
  user_id: IPreviewUser['id'];
  user: string;
  organization_id: IPreviewOrganization['id'];
  organization: IPreviewOrganization['title'];
}

const AdminprofileUsersList = () => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const users = useUsers();
  const adminprofiles = useAdminprofiles();
  const organizations = useOrganizations();
  const [adminprofile, setAdminprofile, ] = useState<IPreviewAdminprofile>();
  const [rows, setRows, ] = useState<Array<IAdminprofileUserRow>>([]);
  const { id, } = useParams<'id'>();

  const [hasFilters, setHasFilters, ] = useState(false);
  const [filters, setFilters, ] = useState<Record<string, any>>({});

  useEffect(() => {
    setHasFilters(Object.keys(filters).length > 0);
  }, [filters, ]);
  
  useEffect(() => {
    if (id && adminprofiles.loaded)
      setAdminprofile(adminprofiles.dict[id]);
  }, [adminprofiles, ]);

  useEffect(() => {
    if (organizations.loaded && users.loaded)
      fetchAdminprofileUsers(+(id as string)).then(adminprofile_users => setRows(adminprofile_users.map(({ user_id, organization_id, }) => {
        return {
          user_id,
          user: users.dict?.[user_id] ? getFullName(users.dict?.[user_id]) : 'USER NOT FOUND',
          organization_id: organization_id,
          organization: organizations.dict?.[organization_id]?.title || 'ORGANIZATION NOT FOUND',
        };
      })));
  }, [users, organizations, ]);

  const columns: ColumnsType<IAdminprofileUserRow> = React.useMemo(() => {
    return [
      {
        title: t('USER_ID'),
        dataIndex: 'user_id',
        key: 'user_id',
        render: (user_id: IAdminprofileUser['user_id'], row: IAdminprofileUserRow) => <a onClick={() => navigate(`/admin/user/${user_id}/edit`)}>{row.user_id}</a>,
        sorter: (a: IAdminprofileUserRow, b: IAdminprofileUserRow) => a.user_id - b.user_id,
        sortDirections: ['descend', 'ascend', ],
        filteredValue: filters.user_id || null,
        ...GetColumnSearchEqualProps(t, 'user_id', t('ID')),
      },
      {
        title: t('AGENT'),
        dataIndex: 'user_id',
        key: 'user_id',
        render: (user_id: IAdminprofileUser['user_id'], row: IAdminprofileUserRow) => <a onClick={() => navigate(`/admin/user/${user_id}/edit`)}>{row.user}</a>,
        sorter: (a: IAdminprofileUserRow, b: IAdminprofileUserRow) => a.user.localeCompare(b.user),
        sortDirections: ['descend', 'ascend', ],
      },
      {
        title: t('ORGANIZATION'),
        dataIndex: 'organization_id',
        key: 'organization_id',
        render: (organization_id: IAdminprofileUser['organization_id'], row: IAdminprofileUserRow) => <a onClick={() => navigate(`/admin/organization/${organization_id}/edit`)}>{row.organization}</a>,
        sorter: (a: IAdminprofileUserRow, b: IAdminprofileUserRow) => a.organization.localeCompare(b.organization),
        sortDirections: ['descend', 'ascend', ],
        filteredValue: filters.organization_id || null,
        ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      },
    ];
  }, [t, navigate, filters, ]);

  if (!adminprofile || !organizations.loaded || !users.loaded || !adminprofiles.loaded)
    return <></>;

  return (
    <Suspense fallback={<Spinner />}>
      <div className='admin-list-view'>
        <div className='admin-list-page-header'>
          <h1>
            {t('ADMINPROFILE_USERS')}: <span>{adminprofile.title}</span>
          </h1>
        </div>
        <div className='table-wrp'>
          <Table
            columns={columns}
            dataSource={rows}
            onChange={(pagination, filters, sorter) => {
              setFilters(filters || {});
            }}
            locale={{
              emptyText: <ListPageEmpty title={'NO_USERS_WERE_FOUND'} t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => setFilters({})} />,
            }}
          />
        </div>
      </div>
    </Suspense>
  );
};

export const AdminprofileUsersListView = React.memo(AdminprofileUsersList);
