import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { ISharedUpdateGdpr, ISharedGdpr, IPreviewOrganization, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { entitiesToOptions, onFormSaved, } from '@Utils';

export interface IGdprFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const GdprFormOptions = (gdpr: ISharedGdpr, props: IGdprFormOptionsProps, t: TFunction): IFormOptions => ({
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  
});

export const GdprComponents: IForm['components'] = {};
export const GdprAdditionalParams: (auth: IAuth, props: IGdprFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IGdprFormOptionsProps) => ({
});

export const GdprEditForm = (gdpr: ISharedGdpr, props: IGdprFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `gdpr_edit_${gdpr.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === gdpr.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'contact', },
    { ref: 'agent', },
    { ref: 'password', },
    { ref: 'ip_whitelist', },
    { ref: 'connectel_info_share', },
  ],
});

export const GdprEditInitialValues = (gdpr: ISharedGdpr): ISharedUpdateGdpr => ({
  contact: gdpr.contact,
  agent: gdpr.agent,
  password: gdpr.password,
  ip_whitelist: gdpr.ip_whitelist,
  connectel_info_share: gdpr.connectel_info_share,
});

export const onGdprFormSaved = (t: TFunction, action: 'edit' | 'add', gdpr: ISharedGdpr, navigate: NavigateFunction): void => {
  navigate(onFormSaved('gdprs'));
};
