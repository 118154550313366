import React, { useState, } from 'react';
import { Popover, } from 'antd';
import { ISharedFullConversation, } from 'atlas-shared';
import './conversation.disposition.popover.scss';
import { IWithFullConversationProps, } from '@Hocs/with.full.conversation';
import { useTranslation, } from 'react-i18next';
import { ConversationDispositionForm, } from './conversation.disposition.form';

interface IProps {
  conversation: ISharedFullConversation;
  children?: any;
  patch_conversation: IWithFullConversationProps['patch_conversation'];
}

export const ConversationDispositionPopover = React.memo(({ conversation, children, patch_conversation, }: IProps) => {
  const { t, } = useTranslation();
  const [visible, setVisible, ] = useState<boolean>(false);

  return (
    <Popover
      className={'disposal-popover-container'}
      placement='bottomLeft'
      content={
        <div className={'disposal-popover-content'}>
          <div className={'popover-header'}>
            <span className='popover-title' style={{ opacity: .5, textTransform: 'uppercase', }}>{t('DISPOSITION')}</span>
            <span className='popover-title'>{t('SELECT_DISPOSITION')}</span>
          </div>
          <div className={'popover-content'}>
            <ConversationDispositionForm key={conversation.id} patch_conversation={patch_conversation} conversation={conversation} onClose={() => {
              setVisible(false);
            }}/>
          </div>
        </div>
      }
      trigger='click'
      open={visible}
      onOpenChange={visible => setVisible(visible)}
    >
      {children}
    </Popover>
  );
});
