import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchAgentprofiles, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useAgentprofiles = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const agentprofiles = useSelector((store: IReducerStore) => store.agentprofiles);

  useEffect(() => {
    if (!initiated && autoload && !agentprofiles.loaded && !agentprofiles.loading) {
      initiated = true;
      dispatch(actionFetchAgentprofiles());
    }
  }, [autoload, agentprofiles.loaded, agentprofiles.loading, dispatch, ]);

  return agentprofiles;
};
