import { IPreviewAgentprofile, } from 'atlas-shared';
import { IAgentprofileStore, AgentprofileActions, TAgentprofileActionTypes, } from '@Store';

export const AgentprofileReducer = (state: IAgentprofileStore = {
  loaded: false,
  loading: false,
  agentprofiles: [],
  dict: {},
}, action: TAgentprofileActionTypes): IAgentprofileStore => {
  switch (action.type) {
  case AgentprofileActions.LOADING:
    return { ...state, loading: true, };
  case AgentprofileActions.SET_STATE:
    const dict: IAgentprofileStore['dict'] = {};

    action.payload.forEach((agentprofile: IPreviewAgentprofile) => dict[agentprofile.id] = agentprofile);

    return { ...state, loaded: true, loading: false, agentprofiles: action.payload, dict, };
  case AgentprofileActions.ADD_STATE:
    return { ...state, agentprofiles: [...state.agentprofiles, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case AgentprofileActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, agentprofiles: state.agentprofiles.filter((item: IPreviewAgentprofile) => item.id !== action.payload), dict, };
  }
  case AgentprofileActions.UPDATE_STATE:
    const agentprofiles = [...state.agentprofiles, ];
    const index = state.agentprofiles.findIndex((agentprofile: IPreviewAgentprofile) => agentprofile.id === action.payload.id);

    if (!~index)
      agentprofiles.push(action.payload);
    else
      agentprofiles[index] = action.payload;

    return { ...state, agentprofiles, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
