import { useEffect, useState, } from 'react';
import {
  ISharedConversation,
  ISharedConversationWatcher,
  ISharedFullConversation,
  Undefinable,
} from 'atlas-shared';
import { useConversations, } from './use.conversations';
import { createConversationWatcher, fetchFullConversation, } from '@Api';
import { IReducerStore, } from '@Store';

export const useFullConversation = (organization_id: ISharedConversation['organization_id'], id: Undefinable<ISharedConversation['id']>, source: ISharedConversationWatcher['source'], auth: IReducerStore['auth'], isMock: boolean = false, skipWatcher: boolean = false) => {
  const conversations = useConversations(false);
  const [conversation, setConversation, ] = useState<Undefinable<ISharedFullConversation>>();
  const dictConversation = id && conversations.full_conversations_dict[id];

  useEffect(() => {
    if (id && !conversations.full_conversations_loading.includes(id) && !dictConversation)
      fetchFullConversation(organization_id, id, source, global.socketId);
  }, [id, source, dictConversation, auth.user.id, conversations.full_conversations_loading, ]);
  
  useEffect(() => {
    if (!skipWatcher && !isMock && id)
      createConversationWatcher({
        source,
        user_id: auth.user.id,
        session_id: global.socketId,
        conversation_id: id,
      }).catch(e => {});
  }, [id, ]);

  useEffect(() => {
    setConversation(dictConversation || undefined);
  }, [id, dictConversation, ]);

  return conversation;
};
