import { RestRequest, } from '@Utils';
import { IPreviewDisposition, ISharedDisposition, ISharedCreateDisposition, ISharedPatchDisposition, } from 'atlas-shared';

export const fetchDispositions = async (): Promise<Array<IPreviewDisposition>> => {
  return RestRequest.get<Array<IPreviewDisposition>>('disposition', { socket_id: global.socketId, });
};

export const fetchDisposition = async (id: IPreviewDisposition['id']): Promise<ISharedDisposition> => {
  return RestRequest.get<ISharedDisposition>(`disposition/${id}`, { socket_id: global.socketId, });
};

export const createDisposition = (payload: ISharedCreateDisposition) => {
  return RestRequest.post<any, ISharedDisposition>('disposition', payload, { socket_id: global.socketId, });
};

export const patchDisposition = (id: IPreviewDisposition['id'], payload: ISharedPatchDisposition) => {
  return RestRequest.patch<any, ISharedDisposition>(`disposition/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteDisposition = (id: IPreviewDisposition['id']) => {
  return RestRequest.delete(`disposition/${id}`, { socket_id: global.socketId, });
};

export const getDispositionUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('disposition/update/schema');
};

export const getDispositionCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('disposition/create/schema');
};

export const getDispositionPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('disposition/patch/schema');
};
