import React from 'react';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { CrudEnum, IPreviewVoiceRecordingTranscriptSetting, PermissionEnum, TVoiceRecordingTranscriptSettingSortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, timeSince, } from '@Utils';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { ColumnsType, } from 'antd/lib/table/interface';
import { listAuditButton, listEditButton, GetColumnSearchProps, GetColumnSearchEqualProps, } from '../components';
import { IWithListProps, } from '@Hocs/with.list';

export const VoiceRecordingTranscriptSettingListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceRecordingTranscriptSetting) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceRecordingTranscriptSetting, TVoiceRecordingTranscriptSettingSortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceRecordingTranscriptSetting, TVoiceRecordingTranscriptSettingSortableListCol>['sorter']): ColumnsType<IPreviewVoiceRecordingTranscriptSetting> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewVoiceRecordingTranscriptSetting, b: IPreviewVoiceRecordingTranscriptSetting) => a.id - b.id,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewVoiceRecordingTranscriptSetting['organization_id'], voice_recording_transcript_setting: IPreviewVoiceRecordingTranscriptSetting) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id, }, }),
      sorter: (a: IPreviewVoiceRecordingTranscriptSetting, b: IPreviewVoiceRecordingTranscriptSetting) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewVoiceRecordingTranscriptSetting['created_at'], voice_recording_transcript_setting: IPreviewVoiceRecordingTranscriptSetting) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewVoiceRecordingTranscriptSetting, b: IPreviewVoiceRecordingTranscriptSetting) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewVoiceRecordingTranscriptSetting) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'voice_recording_transcript_setting', row)}
          {abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.VoiceRecordingTranscriptSetting, row) && listEditButton(t, navigate, 'voice_recording_transcript_setting', row)}
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<IPreviewVoiceRecordingTranscriptSetting>;

};
