import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import React from 'react';
import { IWithListProps, } from '@Hocs/with.list';
import { ColumnsType, } from 'antd/lib/table/interface';
import { IPreviewRelease, TReleaseSortableListCol, } from 'atlas-shared/dist';

export * from './abstract/release.list.column.abstract';
import { ReleaseListColumns as ReleaseListColumnsBase, } from './abstract/release.list.column.abstract';
import { ellipsis, } from '@Utils/string';

export const ReleaseListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewRelease) => React.ReactElement> = [], filters: IWithListProps<IPreviewRelease, TReleaseSortableListCol>['filters'], sorter: IWithListProps<IPreviewRelease, TReleaseSortableListCol>['sorter']): ColumnsType<IPreviewRelease> => {
  const base = ReleaseListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, buttons, filters, sorter);
  
  const output_index = base.findIndex(({ key, }) => key === 'output');
  const error_index = base.findIndex(({ key, }) => key === 'error');
  
  const _ellipsis = (str: string, release: IPreviewRelease) => str?.length ? <a style={{ fontSize: 11, }} title={t('CLICK_TO_PRINT_TO_CONSOLE')} onClick={() => console.log(str)}>{ellipsis(str, 30)}</a> : str;
  
  base[output_index].render = _ellipsis;
  base[error_index].render = _ellipsis;

  return base;
};
