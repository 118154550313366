import { RestRequest, } from '@Utils';
import { IPreviewTab, ISharedTab, ISharedCreateTab, ISharedPatchTab, } from 'atlas-shared';

export const fetchTabs = async (): Promise<Array<IPreviewTab>> => {
  return RestRequest.get<Array<IPreviewTab>>('tab', { socket_id: global.socketId, });
};

export const fetchTab = async (id: IPreviewTab['id']): Promise<ISharedTab> => {
  return RestRequest.get<ISharedTab>(`tab/${id}`, { socket_id: global.socketId, });
};

export const createTab = (payload: ISharedCreateTab) => {
  return RestRequest.post<any, ISharedTab>('tab', payload, { socket_id: global.socketId, });
};

export const patchTab = (id: IPreviewTab['id'], payload: ISharedPatchTab) => {
  return RestRequest.patch<any, ISharedTab>(`tab/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteTab = (id: IPreviewTab['id']) => {
  return RestRequest.delete(`tab/${id}`, { socket_id: global.socketId, });
};

export const getTabUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('tab/update/schema');
};

export const getTabCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('tab/create/schema');
};

export const getTabPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('tab/patch/schema');
};
