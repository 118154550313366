import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedUpdatePointsSystem, ISharedPointsSystem, IPreviewOrganization, IPreviewQueue, IPreviewSkill, IPreviewClient, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IPointsSystemFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  queues: Array<IPreviewQueue>;
  skills: Array<IPreviewSkill>;
  clients: Array<IPreviewClient>;
}

export const PointsSystemFormOptions = (points_system: ISharedPointsSystem, props: IPointsSystemFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.PointsSystem, {})), 'organization_id', false), 'organization'),
  mail_message_sent_queue_id: (it: ISharedPointsSystem) => filterRelated<IPreviewQueue>(it.organization_id || points_system.organization_id, 'queue', 'points_system', props.queues, true),
  mail_message_sent_conversation_skill_id: (it: ISharedPointsSystem) => filterRelated<IPreviewSkill>(it.organization_id || points_system.organization_id, 'skill', 'points_system', props.skills, true),
  mail_message_sent_client_id: (it: ISharedPointsSystem) => filterRelated<IPreviewClient>(it.organization_id || points_system.organization_id, 'client', 'points_system', props.clients, true),
  inbound_voice_call_queue_id: (it: ISharedPointsSystem) => filterRelated<IPreviewQueue>(it.organization_id || points_system.organization_id, 'queue', 'points_system', props.queues, true),
  inbound_voice_call_conversation_skill_id: (it: ISharedPointsSystem) => filterRelated<IPreviewSkill>(it.organization_id || points_system.organization_id, 'skill', 'points_system', props.skills, true),
  inbound_voice_call_client_id: (it: ISharedPointsSystem) => filterRelated<IPreviewClient>(it.organization_id || points_system.organization_id, 'client', 'points_system', props.clients, true),
  outbound_voice_call_queue_id: (it: ISharedPointsSystem) => filterRelated<IPreviewQueue>(it.organization_id || points_system.organization_id, 'queue', 'points_system', props.queues, true),
  outbound_voice_call_conversation_skill_id: (it: ISharedPointsSystem) => filterRelated<IPreviewSkill>(it.organization_id || points_system.organization_id, 'skill', 'points_system', props.skills, true),
  outbound_voice_call_client_id: (it: ISharedPointsSystem) => filterRelated<IPreviewClient>(it.organization_id || points_system.organization_id, 'client', 'points_system', props.clients, true),
  internal_voice_call_queue_id: (it: ISharedPointsSystem) => filterRelated<IPreviewQueue>(it.organization_id || points_system.organization_id, 'queue', 'points_system', props.queues, true),
  internal_voice_call_conversation_skill_id: (it: ISharedPointsSystem) => filterRelated<IPreviewSkill>(it.organization_id || points_system.organization_id, 'skill', 'points_system', props.skills, true),
  internal_voice_call_client_id: (it: ISharedPointsSystem) => filterRelated<IPreviewClient>(it.organization_id || points_system.organization_id, 'client', 'points_system', props.clients, true),
  outbound_conversation_created_queue_id: (it: ISharedPointsSystem) => filterRelated<IPreviewQueue>(it.organization_id || points_system.organization_id, 'queue', 'points_system', props.queues, true),
  outbound_conversation_created_conversation_skill_id: (it: ISharedPointsSystem) => filterRelated<IPreviewSkill>(it.organization_id || points_system.organization_id, 'skill', 'points_system', props.skills, true),
  outbound_conversation_created_client_id: (it: ISharedPointsSystem) => filterRelated<IPreviewClient>(it.organization_id || points_system.organization_id, 'client', 'points_system', props.clients, true),
  outbound_conversation_resolved_queue_id: (it: ISharedPointsSystem) => filterRelated<IPreviewQueue>(it.organization_id || points_system.organization_id, 'queue', 'points_system', props.queues, true),
  outbound_conversation_resolved_conversation_skill_id: (it: ISharedPointsSystem) => filterRelated<IPreviewSkill>(it.organization_id || points_system.organization_id, 'skill', 'points_system', props.skills, true),
  outbound_conversation_resolved_client_id: (it: ISharedPointsSystem) => filterRelated<IPreviewClient>(it.organization_id || points_system.organization_id, 'client', 'points_system', props.clients, true),
  inbound_conversation_resolved_queue_id: (it: ISharedPointsSystem) => filterRelated<IPreviewQueue>(it.organization_id || points_system.organization_id, 'queue', 'points_system', props.queues, true),
  inbound_conversation_resolved_conversation_skill_id: (it: ISharedPointsSystem) => filterRelated<IPreviewSkill>(it.organization_id || points_system.organization_id, 'skill', 'points_system', props.skills, true),
  inbound_conversation_resolved_client_id: (it: ISharedPointsSystem) => filterRelated<IPreviewClient>(it.organization_id || points_system.organization_id, 'client', 'points_system', props.clients, true),
  outbound_conversation_closed_queue_id: (it: ISharedPointsSystem) => filterRelated<IPreviewQueue>(it.organization_id || points_system.organization_id, 'queue', 'points_system', props.queues, true),
  outbound_conversation_closed_conversation_skill_id: (it: ISharedPointsSystem) => filterRelated<IPreviewSkill>(it.organization_id || points_system.organization_id, 'skill', 'points_system', props.skills, true),
  outbound_conversation_closed_client_id: (it: ISharedPointsSystem) => filterRelated<IPreviewClient>(it.organization_id || points_system.organization_id, 'client', 'points_system', props.clients, true),
  inbound_conversation_closed_queue_id: (it: ISharedPointsSystem) => filterRelated<IPreviewQueue>(it.organization_id || points_system.organization_id, 'queue', 'points_system', props.queues, true),
  inbound_conversation_closed_conversation_skill_id: (it: ISharedPointsSystem) => filterRelated<IPreviewSkill>(it.organization_id || points_system.organization_id, 'skill', 'points_system', props.skills, true),
  inbound_conversation_closed_client_id: (it: ISharedPointsSystem) => filterRelated<IPreviewClient>(it.organization_id || points_system.organization_id, 'client', 'points_system', props.clients, true),
  
});

export const PointsSystemComponents: IForm['components'] = {};
export const PointsSystemAdditionalParams: (auth: IAuth, props: IPointsSystemFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPointsSystemFormOptionsProps) => ({
});

export const PointsSystemEditForm = (points_system: ISharedPointsSystem, props: IPointsSystemFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `points_system_edit_${points_system.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === points_system.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'mail_message_sent', },
    { ref: 'inbound_voice_call', },
    { ref: 'outbound_voice_call', },
    { ref: 'internal_voice_call', },
    { ref: 'outbound_conversation_created', },
    { ref: 'outbound_conversation_resolved', },
    { ref: 'inbound_conversation_resolved', },
    { ref: 'outbound_conversation_closed', },
    { ref: 'inbound_conversation_closed', },
  ],
});

export const PointsSystemEditInitialValues = (points_system: ISharedPointsSystem): ISharedUpdatePointsSystem => ({
  mail_message_sent: points_system.mail_message_sent,
  inbound_voice_call: points_system.inbound_voice_call,
  outbound_voice_call: points_system.outbound_voice_call,
  internal_voice_call: points_system.internal_voice_call,
  outbound_conversation_created: points_system.outbound_conversation_created,
  outbound_conversation_resolved: points_system.outbound_conversation_resolved,
  inbound_conversation_resolved: points_system.inbound_conversation_resolved,
  outbound_conversation_closed: points_system.outbound_conversation_closed,
  inbound_conversation_closed: points_system.inbound_conversation_closed,
});

export const onPointsSystemFormSaved = (t: TFunction, action: 'edit' | 'add', points_system: ISharedPointsSystem, navigate: NavigateFunction): void => {
  navigate(onFormSaved('points_systems'));
};
