import { IPreviewPause, } from 'atlas-shared';

export enum PauseActions {
  SET_STATE = 'PAUSE_SET_STATE',
  ADD_STATE = 'PAUSE_ADD_STATE',
  REMOVE_STATE = 'PAUSE_REMOVE_STATE',
  GET_STATE = 'PAUSE_GET_STATE',
  UPDATE_STATE = 'PAUSE_UPDATE_STATE',
  LOADING = 'PAUSE_LOADING',
}

export interface IPauseLoading {
  type: PauseActions.LOADING;
}

export interface IPauseSetAction {
  type: PauseActions.SET_STATE;
  payload: Array<IPreviewPause>;
}

export interface IPauseUpdateAction {
  type: PauseActions.UPDATE_STATE;
  payload: IPreviewPause;
}

export interface IPauseAddAction {
  type: PauseActions.ADD_STATE;
  payload: IPreviewPause;
}

export interface IPauseRemoveAction {
  type: PauseActions.REMOVE_STATE;
  payload: IPreviewPause['id'];
}

export interface IPauseStore {
  loaded: boolean;
  loading: boolean;
  pauses: Array<IPreviewPause>;
  dict: Record<IPreviewPause['id'], IPreviewPause>;
}

export type TPauseActionTypes = IPauseSetAction | IPauseUpdateAction | IPauseAddAction | IPauseRemoveAction | IPauseLoading;
