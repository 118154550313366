import { journeyAction, } from './base';
import { JourneyActionEditConversation as JourneyActionEditConversationBase, } from 'atlas-shared';

export const JourneyActionEditConversation = journeyAction({
  ...JourneyActionEditConversationBase,
  additionalParams: {
    properties_set: { label: '', addText: 'ADD_PROPERTY', },
    // MOVED TO additionalParams in journey.view
    //properties_set_value: (a, b) => ({ ui_type: b.property === 'disposition_note' ? FormElementTypes.TEXT : b.property === 'disposition_id' ? FormElementTypes.TREE : FormElementTypes.SELECT })
  },
  icon: 'Edit',
});
