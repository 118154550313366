import { RestRequest, } from '@Utils';
import { IPreviewVoiceVoicemail, ISharedVoiceVoicemail, ISharedCreateVoiceVoicemail, ISharedPatchVoiceVoicemail, } from 'atlas-shared';

export const fetchVoiceVoicemails = async (): Promise<Array<IPreviewVoiceVoicemail>> => {
  return RestRequest.get<Array<IPreviewVoiceVoicemail>>('voice_voicemail', { socket_id: global.socketId, });
};

export const fetchVoiceVoicemail = async (id: IPreviewVoiceVoicemail['id']): Promise<ISharedVoiceVoicemail> => {
  return RestRequest.get<ISharedVoiceVoicemail>(`voice_voicemail/${id}`, { socket_id: global.socketId, });
};

export const createVoiceVoicemail = (payload: ISharedCreateVoiceVoicemail) => {
  return RestRequest.post<any, ISharedVoiceVoicemail>('voice_voicemail', payload, { socket_id: global.socketId, });
};

export const patchVoiceVoicemail = (id: IPreviewVoiceVoicemail['id'], payload: ISharedPatchVoiceVoicemail) => {
  return RestRequest.patch<any, ISharedVoiceVoicemail>(`voice_voicemail/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteVoiceVoicemail = (id: IPreviewVoiceVoicemail['id']) => {
  return RestRequest.delete(`voice_voicemail/${id}`, { socket_id: global.socketId, });
};

export const getVoiceVoicemailUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_voicemail/update/schema');
};

export const getVoiceVoicemailCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_voicemail/create/schema');
};

export const getVoiceVoicemailPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_voicemail/patch/schema');
};
