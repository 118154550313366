import React, { Dispatch, SetStateAction, useEffect, useMemo, useState, } from 'react';
import './conversation.contact.scss';
import {
  ActivityIcon,
  EditIcon,
  MailIcon,
  MergeIcon,
  PhoneCallIcon,
  ShuffleIcon,
  UserAnonymousIcon,
  UserIcon,
} from '@Assets/icons';
import { Col, Tooltip, } from 'antd';
import Text from 'antd/es/typography/Text';
import { useTranslation, } from 'react-i18next';
import { canEmail, hasVoiceLicense, ISharedFullConversation, CrudEnum, PermissionEnum, IPreviewAgentprofile, Nullable, } from 'atlas-shared';
import { AlertError, Confirm, getContactFullName, TranslateAndDisplayPhoneNumber, TypeModal, } from '@Utils';
import { useNavigate, } from 'react-router-dom';
import { useAgentprofiles, useAuth, useVoiceAsterisks, } from '@Hooks';
import { actionSetGlobalCall, IAbilities, useAppDispatch, } from '@Store';
import { ConversationContactPopover, } from './conversation.contact.popover';
import { ConversationContactMerge, } from './conversation.contact.merge';
import { Icon, IconText, } from '@Components';
import { anonymizeContact, } from '@Api';

interface IProps {
  conversation: ISharedFullConversation;
  hasContactForm: boolean;
  editContactVisible: boolean;
  setEditContactVisible: Dispatch<SetStateAction<boolean>>;
  editable?: boolean;
  leftIcon?: boolean;
  bottomIcons?: boolean;
  className?: string;
  abilities: IAbilities;
  contactTimeline?: {
    get: boolean;
    set: (value: boolean) => void;
  };
}

export const ConversationContact = React.memo(({
  conversation,
  editContactVisible,
  setEditContactVisible,
  leftIcon,
  bottomIcons,
  className,
  editable,
  hasContactForm,
  contactTimeline,
  abilities,
}: IProps) => {

  const { t, } = useTranslation();
  const navigate = useNavigate();
  const agentprofiles = useAgentprofiles();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const voice_asterisks = useVoiceAsterisks();
  const anonymized = !!conversation.contact?.anonymized_at;
  const [agentprofile, setAgentprofile, ] = useState<Nullable<IPreviewAgentprofile>>(null);
  const [canAnonymize, setCanAnonymize, ] = useState<boolean>(false);
  const phones = useMemo(() => {

    if (!conversation.contact?.phones)
      return <></>;

    const phones = (conversation.contact?.phones || []).map((phone, index) => <TranslateAndDisplayPhoneNumber t={t} phone_number={phone} key={index} />) || [];

    return phones && <Tooltip placement='bottom' title={phones}>
      <small className={'phones'}>
        {phones.slice(0, 1)}
        {conversation.contact.phones.length > 2 && <span className='hidden-count'>+{conversation.contact.phones.length - 1}</span>}
      </small>
    </Tooltip>;
  }, [conversation.contact?.phones, ]);

  const emails = useMemo(() => {

    if (!conversation.contact?.emails)
      return <></>;

    const emails = (conversation.contact?.emails || []).map((email, index) => <span key={index}>{email}</span>) || [];

    return emails.length > 0 && <Tooltip placement='bottom' title={emails} overlayClassName='emails-tooltip'>
      <small className={'emails'}>
        {emails.slice(0, 2)}
        {emails.length > 2 && <span className='hidden-count'>+{emails.length - 2}</span>}
      </small>
    </Tooltip>;
  }, [conversation.contact?.emails, ]);

  useEffect(() => {
    setAgentprofile(auth.user_status.agentprofile_id && agentprofiles.dict?.[auth.user_status.agentprofile_id] || null);
  }, [agentprofiles, ]);

  useEffect(() => {
    setCanAnonymize(abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.GdprAnonymize) || !!agentprofile?.settings?.anonymize_contacts);
  }, [abilities, agentprofile, ]);

  return (
    <div className={'conversation-contact-view ' + className}>
      <small className={'title'}>
        {t('CONTACT').toUpperCase()}
      </small>
      {!conversation.contact && <div>
        <ConversationContactPopover conversation={conversation}>
          <IconText icon={UserIcon} text={t('SELECT_OR_CREATE_CONTACT')} className='select-or-create-contact' />
        </ConversationContactPopover>
      </div>}
      {conversation.contact && <div className={'row'}>
        {
          leftIcon && <Icon icon={UserIcon} className={'icon1'} iconProps={{ size: 14, }}/>
        }
        <Col>
          <div style={{ minHeight: bottomIcons ? 47 : 0, }}>
            <div className={'row'}>
              <Text className={'conversation-contact-view-title'}>{conversation.contact.anonymized_at ? <span style={{ opacity: .25, }}>{t('ANONYMIZED')}</span> : getContactFullName(conversation.contact, t)}</Text>
              {contactTimeline && <div className='conversation-contact-toggle-timeline-button'>
                <Icon
                  icon={ActivityIcon}
                  className={`toggle-timeline${contactTimeline.get ? ' active' : ''}`}
                  onClick={_ => contactTimeline.set(!contactTimeline.get)}
                  tooltip={{
                    title: t('JOURNEY_TIMELINE'),
                  }}
                />
              </div>}
              {canAnonymize && <div className='conversation-contact-anonymize'>
                <Icon
                  icon={UserAnonymousIcon}
                  onClick={() => Confirm(t, {
                    title: t('CONFIRM_ANONYMIZE'),
                    content: t('CONFIRM_ANONYMIZE_DESCRIPTION'),
                    onOk: (modal: TypeModal) => {
                      anonymizeContact(conversation.contact.organization_id, conversation.contact.id)
                        .then(() => modal.destroy())
                        .catch(error => AlertError(t, {
                          title: t('ANONYMIZATION_ERROR'),
                          content: t(error.toString()),
                        }));
                    },
                  })}
                  tooltip={{
                    title: t('ANONYMIZE_CONTACT_AND_CONVERSATIONS'),
                  }}
                />
              </div>}
              {bottomIcons && hasContactForm &&
              <div className='conversation-contact-view-edit-button'>{editable !== false &&
              <Icon
                icon={EditIcon}
                iconProps={{ size: 14, }}
                onClick={() => setEditContactVisible(!editContactVisible)}
                tooltip={{ title: t('EDIT_CONTACT'), }}
              />}</div>}
            </div>
            <div className={'column'}>
              {emails}
              {phones}
            </div>
          </div>

          {
            !anonymized && bottomIcons &&
            <div className={'row action-buttons'}>
              <ConversationContactPopover conversation={conversation}>
                <Icon icon={ShuffleIcon} tooltip={{ title: t('CHANGE_CONTACT'), }} className={'icon-view'}
                  iconProps={{ size: 14, }}/>
              </ConversationContactPopover>
              <ConversationContactMerge conversation={conversation}>
                <Icon tooltip={{ title: t('MERGE'), }} icon={MergeIcon} className='icon-view' iconProps={{ size: 14, }}/>
              </ConversationContactMerge>
              {/*<Icon icon={ChatIcon} className={'icon-view'} iconProps={{ size: 14 }} />*/}
              {<Icon icon={MailIcon} tooltip={{ title: t('START_NEW_MAIL_CONVERSATION'), }} className={'icon-view'}
                iconProps={{ size: 14, style: { opacity: canEmail(auth.user) ? 1 : .5, }, }} onClick={() => {
                  if (canEmail(auth.user))
                    navigate(`/create/conversation?channel=M&contact_id=${conversation.contact.id}&queue_id=${conversation.queue_id}&organization_id=${conversation.organization_id}`);
                  else
                    AlertError(t, {
                      content: t('NO_EMAIL_ACCESS'),
                    });
                }}/>}
              {!!conversation.contact?.phones?.length && hasVoiceLicense(auth.user) && auth.user.main && <Icon
                tooltip={{ title: t('START_NEW_VOICE_CONVERSATION'), }}
                icon={PhoneCallIcon}
                className={'icon-view'}
                iconProps={{ size: 14, }}
                onClick={() => {
                  dispatch(actionSetGlobalCall({
                    numbers: conversation.contact.phones,
                    requires_route: true,
                    confirm: true,
                    voice_asterisk_id: -1,
                  }));
                }}/>}
            </div>
          }
        </Col>
      </div>}
    </div>
  );
});
