import { IPreviewAudit, } from 'atlas-shared/dist';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { AUDIT_MAP as AUDIT_MAP_BASE, } from './abstract';
import { IAuditMap, } from './interface';

export * from './utils';

const AUDIT_MAP: IAuditMap = {
  ...AUDIT_MAP_BASE,
};

export const translateAudit = (t: TFunction, navigate: NavigateFunction, item: IPreviewAudit) => {
  return Object.keys(item.changed_props).map(key => {
    const value = item.changed_props[key];
    const props = AUDIT_MAP[item.table]?.[key]?.(item) || {};
    const EMPTY = `(${t('EMPTY')})`;
    let {
      title = key.toUpperCase(),
      before = props.render?.(value.b, t, true) || item.changed_props[key].b || EMPTY,
      after = props.render?.(value.a, t, false) || item.changed_props[key].a || EMPTY,
      no_tooltip = false,
      link,
    } = {
      ...props,
      before: props.before?.(value.b, t),
      after: props.after?.(value.a, t),
    };
    
    if (Array.isArray(before)) {
      if (!before.length)
        before = EMPTY;
      else
        before = `[${before.join(', ')}]`;
    }

    if (Array.isArray(after)) {
      if (!after.length)
        after = EMPTY;
      else
        after = `[${after.join(', ')}]`;
        
    }

    return <div className={`tag-audit-property${link ? ' clickable' : ''}`} title={no_tooltip ? '' : `${before} -> ${after}`} onClick={() => link && navigate(link)}>{t(title)}</div>;
  });
};
