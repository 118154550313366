import { IAuth, } from '@Store';
import { FormElementTypes, FormLayoutTypes, IForm, } from 'atlas-form';
import {
  IPhonenumberMonthlyCostFormOptionsProps,
  PhonenumberMonthlyCostAdditionalParams as PhonenumberMonthlyCostAdditionalParamsBase,
} from './abstract/phonenumber_monthly_cost.form.abstract';
import { CountryFlagTitle, } from '@Utils/phone';

export * from './abstract/phonenumber_monthly_cost.form.abstract';

export const PhonenumberMonthlyCostAdditionalParams: (auth: IAuth, props: IPhonenumberMonthlyCostFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPhonenumberMonthlyCostFormOptionsProps) => ({
  ...PhonenumberMonthlyCostAdditionalParamsBase(auth, props),
  phonenumber_monthly_cost_list: {
    ui_type: FormElementTypes.SECTION,
    child_params: {
      ui_type: FormElementTypes.SECTION,
      ui_layout: FormLayoutTypes.INLINE,
      labelRenderer: label => <CountryFlagTitle t={props.t} country={label as string} width={21} />,
    },
  },
});
