import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import React from 'react';
import { IWithListProps, } from '@Hocs/with.list';
import { ColumnsType, } from 'antd/lib/table/interface';
import { VoiceAsteriskListColumns as VoiceAsteriskListColumnsBase, } from './abstract/voice_asterisk.list.column.abstract';
import { IPreviewVoiceAsterisk, TVoiceAsteriskSortableListCol, } from 'atlas-shared/dist';
import { Icon, } from '@Components';
import { ClockIcon, ListIcon, RefreshIcon, } from '@Assets';
import { resyncVoiceAsterisk, } from '@Api';

export const VoiceAsteriskListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceAsterisk) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceAsterisk, TVoiceAsteriskSortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceAsterisk, TVoiceAsteriskSortableListCol>['sorter']): ColumnsType<IPreviewVoiceAsterisk> => {
  return VoiceAsteriskListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, [
    (row: IPreviewVoiceAsterisk) => <Icon icon={RefreshIcon} tooltip={{ title: t('RESYNC'), }} iconProps={{ size: 14, }} onClick={() => { resyncVoiceAsterisk(row.id); }} />,
    (row: IPreviewVoiceAsterisk) => <Icon icon={ClockIcon} tooltip={{ title: t('OPERATIONAL_HOURS'), }} iconProps={{ size: 14, }} onClick={() => { navigate(`/admin/voice_asterisk_operational_hourses/search?ff={"voice_asterisk_id":["${row.id}"]}`); }} />,
    (row: IPreviewVoiceAsterisk) => <Icon icon={ListIcon} tooltip={{ title: t('VOICE_ASTERISK_TOGGLE_HISTORY'), }} iconProps={{ size: 14, }} onClick={() => { navigate(`/admin/voice_asterisk_toggle_histories/search?ff={"voice_asterisk_id":["${row.id}"]}`); }} />,
    ...buttons,
  ], filters, sorter);

};

export * from './abstract/voice_asterisk.list.column.abstract';
