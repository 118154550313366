import * as React from 'react';
import { useParams, } from 'react-router-dom';
import { StatsReportsReportLayout, } from '@Layouts/stats/stats_report.report.layout';
import { StatsReportsDashboardLayout, } from '@Layouts/stats/stats_report.dashboard.layout';
import { useAuth, } from '@Hooks';

const StatsReportPage: React.FC = () => {
  const { report_id, type, } = useParams<'report_id' | 'type'>();
  const is_dashboard = type === 'dashboard';
  const auth = useAuth();

  if (!auth.user[is_dashboard ? 'dashboard_license' : 'reporting_license'])
    return <div>Access restricted!</div>;

  if (!report_id)
    return <div>Missing report_id</div>;
  
  return is_dashboard
    ? <StatsReportsDashboardLayout report_id={+report_id} />
    : <StatsReportsReportLayout report_id={+report_id} />;

};

export default StatsReportPage;
