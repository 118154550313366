import { ISharedNotification, Undefinable, } from 'atlas-shared';

const broadcast = new BroadcastChannel('sw-update-channel');

export const BrowserNotification = (
  id: ISharedNotification['id'] | string,
  title: string,
  body: Undefinable<string>,
  buttons: Array<any>,
  icon: Undefinable<string> = '/icons/info.png'
) => {
  Notification.requestPermission((result) => {
    if (result !== 'granted')
      return;

    navigator.serviceWorker.ready.then((registration) => {

      registration.showNotification(title, {
        icon,
        requireInteraction: true,
        body,
        data: {
          id,
        },
        actions: buttons.map(({ action, title, onClick, }) => {
          return {
            action,
            title,
          };
        }),
      });

      const listener = event => {
        
        const clickedButton = buttons.find(button => button.action === event.data.notification.action);

        clickedButton?.onClick?.();
      };

      broadcast.addEventListener('message', listener);

      //broadcast.removeEventListener('message', listener);

      // if (timeout)
      //   setTimeout(() => {
      //     RemoveBrowserNotification(id);
      //   }, timeout);

    }).catch(err => {
    });
  });

};

export const RemoveBrowserNotification = (id: number | string) => {
  navigator.serviceWorker.ready.then((registration) => {
    registration.getNotifications().then(notifications => {
      const notification = notifications.find(n => n.data.id === id);

      if (notification)
        notification.close();
    });
  });
};

if ('serviceWorker' in navigator) {
  // The current browser supports service workers.

  // navigator.serviceWorker.controlled iff there is currently a service worker handling
  // requests on this page for the current navigation.

  // Override the default scope of '/' with './', so that the registration applies
  // to the current directory and everything underneath it.
  navigator.serviceWorker.getRegistrations().then(async (registrations) => {
    //returns installed service workers
    if (registrations.length) {
      for (let registration of registrations) {
        await registration.unregister();
      }
    }

    navigator.serviceWorker
      .register('/service-worker.js', { scope: './', })
      .then((registration) => {
        // At this point, registration has taken place.
        // The service worker will not handle requests until this page and any
        // other instances of this page (in other tabs, etc.) have been
        // closed/reloaded.

        let serviceWorker;

        if (registration.installing) {
          serviceWorker = registration.installing;
        } else if (registration.waiting) {
          serviceWorker = registration.waiting;
        } else if (registration.active) {
          serviceWorker = registration.active;
        }

        if (serviceWorker) {
          serviceWorker.addEventListener('statechange', function(e) {
          });
        }
      })
      .catch(function(error) {
        // Something went wrong during registration. The service-worker.js file
        // might be unavailable or contain a syntax error.
      });
  });

} else {
  // The current browser doesn't support service workers.
}
