import { TFunction, } from 'i18next';
import {
  AnsweredAbandonedWithinArray,
  findMetricByAlias,
  findMetricByAliasOrId,
  GroupMetrics,
  IMetric,
  IPreviewDisposition,
  IPreviewOrganization,
  IPreviewUser,
  ISharedCreateStatsAlarm,
  ISharedStatsAlarm,
  ISharedStatsAlarmSettingsMetricProperty,
  MessageAnsweredAbandonedWithinArray,
  StatsAlarmPeriodEnum,
  ThreadAnsweredAbandonedWithinArray,
  isSuperAdmin,
  CrudEnum,
  PermissionEnum,
  OrganizationDashboardReportingTypeEnum,
  ISharedStatsWidget, IPreviewPause,
} from 'atlas-shared/dist';
import {
  metric_options,
  metricToOption, PAUSE_FILTER_METRICS,
  seconds_threshold_options,
  seconds_threshold_settings,
  stats_channels_options,
  stats_direction_options,
  stats_message_channels_options,
  stats_message_direction_options,
} from '@Utils/stats';
import { IForm, IFormElementTypeahead, IFormOptions, } from 'atlas-form';
import { IStatsAlarmFormOptionsProps,
  StatsAlarmFormOptions as StatsAlarmFormOptionsBase,
  StatsAlarmAdditionalParams as StatsAlarmAdditionalParamsBase,
  StatsAlarmAddInitialValues as StatsAlarmAddInitialValuesBase,
} from './abstract/stats_alarm.form.abstract';

export * from './abstract/stats_alarm.form.abstract';

import { IAuth, } from '@Store';
import { filterRelated, } from '@Utils/relation';
import { entitiesToOptions, } from '@Utils/options';
import { nestedArray, } from '@Utils/array';

export const StatsAlarmFormOptions = (stats_alarm: ISharedCreateStatsAlarm | ISharedStatsAlarm, props: IStatsAlarmFormOptionsProps, t: TFunction): IFormOptions => {

  const disposition_options = (txt: string) => (it: ISharedStatsAlarm) => ([{ key: 0, title: t(txt), }, ...filterRelated<IPreviewDisposition>(it.organization_id || stats_alarm.organization_id, 'disposition', 'stats_widget', props.dispositions, true), ]);
  const sub_disposition_options = (txt: string) => (it: ISharedStatsAlarm) => ([{ key: 0, title: t(txt), }, ...filterRelated<IPreviewDisposition>(it.organization_id || stats_alarm.organization_id, 'disposition', 'stats_widget', props.dispositions, true, disposition => !!(disposition.parents || []).length), ]);
  const pause_options = (it: ISharedStatsAlarm) => filterRelated<IPreviewPause>(it.organization_id || stats_alarm.organization_id, 'pause', 'stats_alarm', props.pauses, true);

  return {
    ...StatsAlarmFormOptionsBase(stats_alarm, props, t),
    organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => (organization.reduced_dashboard_license_type !== OrganizationDashboardReportingTypeEnum.Entry || organization.reduced_reporting_license_type !== OrganizationDashboardReportingTypeEnum.Entry) && props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.StatsAlarm, {})), 'organization_id', false), 'organization'),
    settings_filter_organization_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewOrganization>(it.organization_id || stats_alarm.organization_id, 'organization', 'stats_alarm', props.organizations, true),
    settings_filter_disposition_ids: disposition_options('NO_DISPOSITION'),
    settings_filter_sub_disposition_ids: disposition_options('NO_SUB_DISPOSITION'),
    settings_filter_thrd_disposition_ids: sub_disposition_options('NO_THRD_DISPOSITION'),
    settings_metrics_metric_alias: (it: ISharedStatsAlarm) => metric_options(t, props.stats_custom_metrics, it),
    settings_group_by: (it: ISharedStatsAlarm) => (it?.settings?.period === StatsAlarmPeriodEnum.LIVE_TODAY ? GroupMetrics : GroupMetrics.filter(m => !m.live)).map(m => metricToOption(m, t)),
    settings_metrics_metric_filter_pause_ids: pause_options,
    settings_filter_pause_ids: pause_options,
    settings_metrics_metric_channels: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_channels_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.channels ? stats_channels_options(t) : stats_channels_options(t).filter(o => metric.channels?.includes(o.key as any));
    },
    settings_metrics_metric_direction: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_direction_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.directions ? stats_direction_options(t) : stats_direction_options(t).filter(o => metric.directions?.includes(o.key as any));
    },
    settings_metrics_metric_thread_channels: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_channels_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.thread_channels ? stats_channels_options(t) : stats_channels_options(t).filter(o => metric.thread_channels?.includes(o.key as any));
    },
    settings_metrics_metric_message_channels: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_channels_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.message_channels ? stats_message_channels_options(t) : stats_message_channels_options(t).filter(o => metric.message_channels?.includes(o.key as any));
    },
    settings_metrics_metric_thread_direction: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_direction_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.thread_directions ? stats_message_direction_options(t) : stats_direction_options(t).filter(o => metric.thread_directions?.includes(o.key as any));
    },
    settings_metrics_metric_message_direction: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_direction_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.message_directions ? stats_message_direction_options(t) : stats_message_direction_options(t).filter(o => metric.message_directions?.includes(o.key as any));
    },
    settings_metrics_metric_seconds_threshold: seconds_threshold_options(t, AnsweredAbandonedWithinArray),
    settings_metrics_metric_thread_seconds_threshold: seconds_threshold_options(t, ThreadAnsweredAbandonedWithinArray),
    settings_metrics_metric_message_seconds_threshold: seconds_threshold_options(t, MessageAnsweredAbandonedWithinArray),
    settings_notification_user_ids: (it: ISharedStatsAlarm) => (it.organization_id || stats_alarm.organization_id) === 1 ? entitiesToOptions(props.users.filter(user => isSuperAdmin(user)), 'user') : filterRelated<IPreviewUser>(it.organization_id || stats_alarm.organization_id, 'user_reverse', 'stats_alarm', props.users, true),
  };
};

export const StatsAlarmAdditionalParams: (auth: IAuth, props: IStatsAlarmFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IStatsAlarmFormOptionsProps) => ({
  ...StatsAlarmAdditionalParamsBase(auth, props),
  settings_filter: (it: ISharedStatsAlarm) => ({ hidden: !it.organization_id, }),
  settings_filter_conversation_user_ids: { advanced: true, },
  settings_filter_thread_user_ids: { advanced: true, },
  settings_filter_message_user_ids: { advanced: true, },
  settings_filter_conversation_queue_ids: { advanced: true, },
  settings_filter_thread_queue_ids: { advanced: true, },
  settings_filter_message_queue_ids: { advanced: true, },
  settings_filter_disposition_ids: { advanced: true, },
  settings_filter_sub_disposition_ids: { advanced: true, },
  settings_filter_thrd_disposition_ids: { advanced: true, },
  settings_filter_team_ids: { advanced: true, },
  settings_filter_thread_team_ids: { advanced: true, },
  settings_filter_message_team_ids: { advanced: true, },
  settings_filter_mail_account_ids: { advanced: true, },
  settings_filter_voice_route_ids: { advanced: true, },
  min_interval: { postFixText: '/min', help: 'STATS_ALARM_MIN_INTERVAL_HELP', },
  settings_metrics_comparison: { hideLabel: true, },
  settings_metrics_value: { hideLabel: true, },
  settings_metrics_value_arr: { hideLabel: true, mode: 'tags', },
  settings_metrics_metric: { hideLabel: true, },
  settings_metrics_metric_alias: (it: ISharedStatsAlarm) => ({
    hideLabel: true,
    onAdd: (selected: any, item: IFormElementTypeahead, value: Array<any>, stats_widget) => {
      console.log('onAdd', selected, item, value);
    },
  }),
  settings_metrics_metric_title: { advanced: true, },
  settings_metrics_metric_channels: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
    const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

    return { hidden: !metric || (metric?.channels && metric.channels.length <= 1), };
  },
  settings_metrics_metric_direction: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
    const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

    return { label: 'CONVERSATION_DIRECTION', hidden: !metric || (metric?.directions && metric.directions?.length <= 1), };
  },
  settings_metrics_metric_message_channels: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
    const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

    return { hidden: !metric || (metric?.message_channels && metric.message_channels.length <= 1), };
  },
  settings_metrics_metric_thread_direction: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
    const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

    return { hidden: !metric || (metric?.thread_directions && metric.thread_directions?.length <= 1), };
  },
  settings_metrics_metric_thread_channels: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
    const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

    return { hidden: !metric || (metric?.thread_channels && metric.thread_channels.length <= 1), };
  },
  settings_metrics_metric_message_direction: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => {
    const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

    return { hidden: !metric || (metric?.message_directions && metric.message_directions?.length <= 1), };
  },
  settings_metrics_metric_filter: { advanced: true, },
  settings_metrics_metric_seconds_threshold: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => seconds_threshold_settings('seconds_threshold', it, settings),
  settings_metrics_metric_thread_seconds_threshold: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => seconds_threshold_settings('thread_seconds_threshold', it, settings),
  settings_metrics_metric_message_seconds_threshold: (it: ISharedStatsAlarm, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => seconds_threshold_settings('message_seconds_threshold', it, settings),
  settings_metrics_metric_filter_pause_ids: (it: ISharedStatsWidget, _, __, settings: ISharedStatsAlarmSettingsMetricProperty['metric']) => ({ hidden: !settings.alias || !PAUSE_FILTER_METRICS.includes(settings.alias), }),
  // settings_group_by: {
  //   ui_type: FormElementTypes.TYPEAHEAD,
  //   mode: 'multiple',
  //   tagRender: (item: IPreviewStatsAlarmSettingsGroupByProperty, options: Array<IOption>, t:TFunction) => {
  //     return getStatsWidgetMetricTitle(t, item, props.stats_custom_metrics).elm;
  //   },
  //   tag_style: {
  //     maxWidth: 160
  //   }
  // }
});

export const StatsAlarmAddInitialValues: ISharedCreateStatsAlarm = {
  ...StatsAlarmAddInitialValuesBase,
  // settings: {
  //   metrics: [{
  //     metric: {}
  //   }]
  // },
} as ISharedCreateStatsAlarm;
