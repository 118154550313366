import { ConversationView, } from '@Components';
import React from 'react';
import { ISharedConversation, } from 'atlas-shared';

interface IProps {
  id: ISharedConversation['id'];
  organization_id: ISharedConversation['organization_id'];
}

export const SearchLayoutRight = ({ id, organization_id, }: IProps) => {
  return (
    <div className={'inbox-right'}>
      <ConversationView conversation_id={id} organization_id={organization_id} />
    </div>
  );
};
