import { RestRequest, } from '@Utils';
import { IPreviewAiAssistant, ISharedAiAssistant, ISharedCreateAiAssistant, ISharedPatchAiAssistant, } from 'atlas-shared';

export const fetchAiAssistants = async (): Promise<Array<IPreviewAiAssistant>> => {
  return RestRequest.get<Array<IPreviewAiAssistant>>('ai_assistant', { socket_id: global.socketId, });
};

export const fetchAiAssistant = async (id: IPreviewAiAssistant['id']): Promise<ISharedAiAssistant> => {
  return RestRequest.get<ISharedAiAssistant>(`ai_assistant/${id}`, { socket_id: global.socketId, });
};

export const createAiAssistant = (payload: ISharedCreateAiAssistant) => {
  return RestRequest.post<any, ISharedAiAssistant>('ai_assistant', payload, { socket_id: global.socketId, });
};

export const patchAiAssistant = (id: IPreviewAiAssistant['id'], payload: ISharedPatchAiAssistant) => {
  return RestRequest.patch<any, ISharedAiAssistant>(`ai_assistant/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteAiAssistant = (id: IPreviewAiAssistant['id']) => {
  return RestRequest.delete(`ai_assistant/${id}`, { socket_id: global.socketId, });
};

export const getAiAssistantUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('ai_assistant/update/schema');
};

export const getAiAssistantCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('ai_assistant/create/schema');
};

export const getAiAssistantPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('ai_assistant/patch/schema');
};
