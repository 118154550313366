import { VoiceRecordingTranscriptSettingActions, } from '@Store';
import { IPreviewVoiceRecordingTranscriptSetting, } from 'atlas-shared';
import { fetchVoiceRecordingTranscriptSettings, } from '@Api';

export const actionFetchVoiceRecordingTranscriptSettings = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceRecordingTranscriptSettings());
    const voice_recording_transcript_settings = await fetchVoiceRecordingTranscriptSettings();

    dispatch(actionSetVoiceRecordingTranscriptSettings(voice_recording_transcript_settings));
    return voice_recording_transcript_settings;
  };
};

export const actionLoadingVoiceRecordingTranscriptSettings = () => ({
  type: VoiceRecordingTranscriptSettingActions.LOADING,
  payload: [],
});

export const actionSetVoiceRecordingTranscriptSettings = (payload: Array<IPreviewVoiceRecordingTranscriptSetting>) => ({
  type: VoiceRecordingTranscriptSettingActions.SET_STATE,
  payload: payload,
});

export const actionAddVoiceRecordingTranscriptSetting = (payload: IPreviewVoiceRecordingTranscriptSetting) => ({
  type: VoiceRecordingTranscriptSettingActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateVoiceRecordingTranscriptSetting = (payload: IPreviewVoiceRecordingTranscriptSetting) => ({
  type: VoiceRecordingTranscriptSettingActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveVoiceRecordingTranscriptSetting = (id: IPreviewVoiceRecordingTranscriptSetting['id']) => ({
  type: VoiceRecordingTranscriptSettingActions.REMOVE_STATE,
  payload: id,
});
