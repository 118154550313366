import { journeyAction, } from './base';

import { JourneyActionWhen as JourneyActionWhenBase, } from 'atlas-shared';
import { FormLayoutTypes, } from 'atlas-form';

export const JourneyActionWhen = journeyAction({
  ...JourneyActionWhenBase,
  additionalParams: {
    properties_condition: {
      hideLabel: true,
      noPadding: true,
    },
    properties_contact_condition: {
      hideLabel: true,
      noPadding: true,
    },
    properties_since: {
      hideLabel: true,
      noPadding: true,
    },
    properties_since_source: {
      noPadding: true,
    },
    properties_since_since: {
      ui_layout: FormLayoutTypes.INLINE,
      label: 'TIME_PAST',
    },
  },
  icon: 'When',
});
