import i18n from 'i18next';
import { initReactI18next, } from 'react-i18next';
import { en, } from './resources';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    react: {
      // https://react.i18next.com/latest/trans-component#trans-props
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i', 'a', ],
    },

    interpolation: {
      escapeValue: false,
    },
  });
