import { RestRequest, } from '@Utils';
import { IPreviewVoiceInternalCall, ISharedVoiceInternalCall, } from 'atlas-shared';

export const fetchVoiceInternalCalls = async (): Promise<Array<IPreviewVoiceInternalCall>> => {
  return RestRequest.get<Array<IPreviewVoiceInternalCall>>('voice_internal_call', { socket_id: global.socketId, });
};

export const fetchVoiceInternalCall = async (id: IPreviewVoiceInternalCall['id']): Promise<ISharedVoiceInternalCall> => {
  return RestRequest.get<ISharedVoiceInternalCall>(`voice_internal_call/${id}`, { socket_id: global.socketId, });
};
