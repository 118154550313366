import React, { MouseEvent, useState, } from 'react';
import { Tooltip, TooltipProps, } from 'antd';
import { TooltipPlacement, } from 'antd/lib/tooltip';

import { LoadingOutlined, } from '@ant-design/icons';
import { useTranslation, } from 'react-i18next';

export interface IIconProps {
  icon: any;
  iconProps?: {
    size?: number;
    width?: number;
    height?: number;
    padding?: number;
    className?: string;
    style?: React.CSSProperties;
    stroke?: number;
  };
  onClick?: (e?: MouseEvent<HTMLElement>) => any;
  tooltip?: {
    placement?: TooltipPlacement;
    title: string | React.ReactNode;
    style?: React.CSSProperties;
    overlayClassName?: TooltipProps['overlayClassName'];
    color?: TooltipProps['color'];
    overlayInnerStyle?: TooltipProps['overlayInnerStyle'];
  };
  className?: string;
  circle?: boolean;
  wrapperStyle?: React.CSSProperties;
  title?: string;
  loading?: boolean;
}

export const Icon = React.memo((props: IIconProps) => {

  const { iconProps: _iconProps, icon, onClick, className, title, tooltip, } = props;
  const [loading, setLoading, ] = useState<boolean>(false);
  const iconProps = _iconProps || { style: {}, };
  const isLoading = props.loading || loading;
  const { t, } = useTranslation();

  const elm = (
    <span title={title} onClick={(e) => {
      if (!props.onClick || isLoading)
        return;

      setLoading(true);
      const response = props.onClick?.(e);

      if (response?.finally)
        response.finally(() => setLoading(false));
      else
        setLoading(false);
    }} className={['icon-wrapper', className, onClick && 'clickable', props.circle && 'circle', isLoading && 'loading', ].filter(Boolean).join(' ')} style={props.wrapperStyle}>
      {isLoading && <LoadingOutlined style={{ fontSize: iconProps.size || 14, }} spin />}
      {React.createElement(icon, { ...iconProps, className: iconProps.className || 'icon', style:{ opacity: isLoading ? .25 : 1, ...iconProps.style, }, t, })}
    </span>
  );

  if (tooltip)
    return <Tooltip {...{ ...tooltip, placement: tooltip.placement || 'top', }}>{elm}</Tooltip>;

  return elm;
});
