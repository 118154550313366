export const preventDefault = (callback?: Function) => {
  return (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    return callback?.();
  };
};

export const setPreventDefault = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};
