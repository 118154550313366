import { IPreviewDestinationRestrictor, } from 'atlas-shared';
import { IDestinationRestrictorStore, DestinationRestrictorActions, TDestinationRestrictorActionTypes, } from '@Store';

export const DestinationRestrictorReducer = (state: IDestinationRestrictorStore = {
  loaded: false,
  loading: false,
  destination_restrictors: [],
  dict: {},
}, action: TDestinationRestrictorActionTypes): IDestinationRestrictorStore => {
  switch (action.type) {
  case DestinationRestrictorActions.LOADING:
    return { ...state, loading: true, };
  case DestinationRestrictorActions.SET_STATE:
    const dict: IDestinationRestrictorStore['dict'] = {};

    action.payload.forEach((destination_restrictor: IPreviewDestinationRestrictor) => dict[destination_restrictor.id] = destination_restrictor);

    return { ...state, loaded: true, loading: false, destination_restrictors: action.payload, dict, };
  case DestinationRestrictorActions.ADD_STATE:
    return { ...state, destination_restrictors: [...state.destination_restrictors, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case DestinationRestrictorActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, destination_restrictors: state.destination_restrictors.filter((item: IPreviewDestinationRestrictor) => item.id !== action.payload), dict, };
  }
  case DestinationRestrictorActions.UPDATE_STATE:
    const destination_restrictors = [...state.destination_restrictors, ];
    const index = state.destination_restrictors.findIndex((destination_restrictor: IPreviewDestinationRestrictor) => destination_restrictor.id === action.payload.id);

    if (!~index)
      destination_restrictors.push(action.payload);
    else
      destination_restrictors[index] = action.payload;

    return { ...state, destination_restrictors, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
