import { RestRequest, } from '@Utils';
import { IPreviewTariff, ISharedTariff, ISharedCreateTariff, ISharedPatchTariff, } from 'atlas-shared';

export const fetchTariffs = async (): Promise<Array<IPreviewTariff>> => {
  return RestRequest.get<Array<IPreviewTariff>>('tariff', { socket_id: global.socketId, });
};

export const fetchTariff = async (id: IPreviewTariff['id']): Promise<ISharedTariff> => {
  return RestRequest.get<ISharedTariff>(`tariff/${id}`, { socket_id: global.socketId, });
};

export const createTariff = (payload: ISharedCreateTariff) => {
  return RestRequest.post<any, ISharedTariff>('tariff', payload, { socket_id: global.socketId, });
};

export const patchTariff = (id: IPreviewTariff['id'], payload: ISharedPatchTariff) => {
  return RestRequest.patch<any, ISharedTariff>(`tariff/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteTariff = (id: IPreviewTariff['id']) => {
  return RestRequest.delete(`tariff/${id}`, { socket_id: global.socketId, });
};

export const getTariffUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('tariff/update/schema');
};

export const getTariffCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('tariff/create/schema');
};

export const getTariffPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('tariff/patch/schema');
};
