import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchClients, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useClients = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const clients = useSelector((store: IReducerStore) => store.clients);

  useEffect(() => {
    if (!initiated && autoload && !clients.loaded && !clients.loading) {
      initiated = true;
      dispatch(actionFetchClients());
    }
  }, [autoload, clients.loaded, clients.loading, dispatch, ]);

  return clients;
};
