import { RestRequest, } from '@Utils';
import { IPreviewAdminprofile, ISharedAdminprofile, ISharedCreateAdminprofile, ISharedPatchAdminprofile, } from 'atlas-shared';

export const fetchAdminprofiles = async (): Promise<Array<IPreviewAdminprofile>> => {
  return RestRequest.get<Array<IPreviewAdminprofile>>('adminprofile', { socket_id: global.socketId, });
};

export const fetchAdminprofile = async (id: IPreviewAdminprofile['id']): Promise<ISharedAdminprofile> => {
  return RestRequest.get<ISharedAdminprofile>(`adminprofile/${id}`, { socket_id: global.socketId, });
};

export const createAdminprofile = (payload: ISharedCreateAdminprofile) => {
  return RestRequest.post<any, ISharedAdminprofile>('adminprofile', payload, { socket_id: global.socketId, });
};

export const patchAdminprofile = (id: IPreviewAdminprofile['id'], payload: ISharedPatchAdminprofile) => {
  return RestRequest.patch<any, ISharedAdminprofile>(`adminprofile/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteAdminprofile = (id: IPreviewAdminprofile['id']) => {
  return RestRequest.delete(`adminprofile/${id}`, { socket_id: global.socketId, });
};

export const getAdminprofileUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('adminprofile/update/schema');
};

export const getAdminprofileCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('adminprofile/create/schema');
};

export const getAdminprofilePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('adminprofile/patch/schema');
};
