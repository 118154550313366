import React, { useCallback, useEffect, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions, } from 'atlas-form';
import {
  InvoicePostEditForm,
  InvoicePostEditInitialValues,
  InvoicePostComponents,
  InvoicePostAdditionalParams,
  InvoicePostFormOptions,
  onInvoicePostFormSaved,
  IInvoicePostFormOptionsProps,
  AlertError,
} from '@Utils';
import { ISharedInvoicePost, ISharedPatchInvoicePost, PermissionEnum, CrudEnum, } from 'atlas-shared';
import { fetchInvoicePost, patchInvoicePost, getInvoicePostUpdateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withEdit, IWithEditProps, } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useInvoices, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithEditProps<ISharedInvoicePost> {}

const InvoicePostFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const invoices = useInvoices();

  useEffect(() => {
    fetchInvoicePost(id).then(invoice_post => setValue(invoice_post));
  }, [id, setValue, ]);

  const param_props: IInvoicePostFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    invoices: invoices.invoices,
  };
  const formLayout: IForm | undefined = useMemo(() => value && InvoicePostEditForm(value, param_props, t), [value, organizations, invoices, t, abilities, ]);
  const options: IFormOptions | undefined = useMemo(() => value && InvoicePostFormOptions(value, param_props, t), [value, organizations, invoices, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchInvoicePost, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchInvoicePost) => value && save(() => patchInvoicePost(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onInvoicePostFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [value, save, navigate, t, ]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !invoices.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.InvoicePost, value)}
    >
      <AtlasForm<ISharedPatchInvoicePost>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={InvoicePostComponents}
        additionalParams={InvoicePostAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={InvoicePostEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('INVOICE_POST')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const InvoicePostFormEditView = React.memo(withEdit(InvoicePostFormEdit, getInvoicePostUpdateSchema));
