import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  StatsAlarmAddForm,
  StatsAlarmAddInitialValues,
  StatsAlarmComponents,
  StatsAlarmAdditionalParams,
  StatsAlarmFormOptions,
  onStatsAlarmFormSaved,
  IStatsAlarmFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateStatsAlarm, PermissionEnum, } from 'atlas-shared';
import { createStatsAlarm, fetchStatsAlarm, getStatsAlarmCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useStatsCustomMetrics, useUsers, useQueues, usePauses, useDispositions, useTeams, useMailAccounts, useVoiceRoutes, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateStatsAlarm> {}

const StatsAlarmFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = true, }: IProps) => {
  const organizations = useOrganizations();
  const stats_custom_metrics = useStatsCustomMetrics();
  const users = useUsers();
  const queues = useQueues();
  const pauses = usePauses();
  const dispositions = useDispositions();
  const teams = useTeams();
  const mail_accounts = useMailAccounts();
  const voice_routes = useVoiceRoutes();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateStatsAlarm>>();

  const param_props: IStatsAlarmFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    stats_custom_metrics: stats_custom_metrics.stats_custom_metrics,
    users: users.users,
    queues: queues.queues,
    pauses: pauses.pauses,
    dispositions: dispositions.dispositions,
    teams: teams.teams,
    mail_accounts: mail_accounts.mail_accounts,
    voice_routes: voice_routes.voice_routes,
  };
  const formLayout = useMemo(() => StatsAlarmAddForm(values, param_props, t), [values, organizations, stats_custom_metrics, users, queues, pauses, dispositions, teams, mail_accounts, voice_routes, t, abilities, ]);
  const options: IFormOptions = useMemo(() => StatsAlarmFormOptions(values || StatsAlarmAddInitialValues, param_props, t), [values, organizations, stats_custom_metrics, users, queues, pauses, dispositions, teams, mail_accounts, voice_routes, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateStatsAlarm, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateStatsAlarm) => save(() => createStatsAlarm(values).then(
    res => onSaved ? onSaved(res, navigate) : onStatsAlarmFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchStatsAlarm(+id)
        .then(({
          organization_id,
          title,
          auto_text,
          text,
          min_interval,
          settings,
        }) => {
          setCloned({
            organization_id,
            title,
            auto_text,
            text,
            min_interval,
            settings,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !stats_custom_metrics.loaded || !users.loaded || !queues.loaded || !pauses.loaded || !dispositions.loaded || !teams.loaded || !mail_accounts.loaded || !voice_routes.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.StatsAlarm, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={StatsAlarmComponents}
        additionalParams={StatsAlarmAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...StatsAlarmAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('STATS_ALARM')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const StatsAlarmFormAddView = React.memo(withAdd(StatsAlarmFormAdd, getStatsAlarmCreateSchema, StatsAlarmAddInitialValues));
