import { IPreviewGdpr, } from 'atlas-shared';

export enum GdprActions {
  SET_STATE = 'GDPR_SET_STATE',
  ADD_STATE = 'GDPR_ADD_STATE',
  REMOVE_STATE = 'GDPR_REMOVE_STATE',
  GET_STATE = 'GDPR_GET_STATE',
  UPDATE_STATE = 'GDPR_UPDATE_STATE',
  LOADING = 'GDPR_LOADING',
}

export interface IGdprLoading {
  type: GdprActions.LOADING;
}

export interface IGdprSetAction {
  type: GdprActions.SET_STATE;
  payload: Array<IPreviewGdpr>;
}

export interface IGdprUpdateAction {
  type: GdprActions.UPDATE_STATE;
  payload: IPreviewGdpr;
}

export interface IGdprAddAction {
  type: GdprActions.ADD_STATE;
  payload: IPreviewGdpr;
}

export interface IGdprRemoveAction {
  type: GdprActions.REMOVE_STATE;
  payload: IPreviewGdpr['id'];
}

export interface IGdprStore {
  loaded: boolean;
  loading: boolean;
  gdprs: Array<IPreviewGdpr>;
  dict: Record<IPreviewGdpr['id'], IPreviewGdpr>;
}

export type TGdprActionTypes = IGdprSetAction | IGdprUpdateAction | IGdprAddAction | IGdprRemoveAction | IGdprLoading;
