import { IPreviewTariffParent, } from 'atlas-shared';

export enum TariffParentActions {
  SET_STATE = 'TARIFF_PARENT_SET_STATE',
  ADD_STATE = 'TARIFF_PARENT_ADD_STATE',
  REMOVE_STATE = 'TARIFF_PARENT_REMOVE_STATE',
  GET_STATE = 'TARIFF_PARENT_GET_STATE',
  UPDATE_STATE = 'TARIFF_PARENT_UPDATE_STATE',
  LOADING = 'TARIFF_PARENT_LOADING',
}

export interface ITariffParentLoading {
  type: TariffParentActions.LOADING;
}

export interface ITariffParentSetAction {
  type: TariffParentActions.SET_STATE;
  payload: Array<IPreviewTariffParent>;
}

export interface ITariffParentUpdateAction {
  type: TariffParentActions.UPDATE_STATE;
  payload: IPreviewTariffParent;
}

export interface ITariffParentAddAction {
  type: TariffParentActions.ADD_STATE;
  payload: IPreviewTariffParent;
}

export interface ITariffParentRemoveAction {
  type: TariffParentActions.REMOVE_STATE;
  payload: IPreviewTariffParent['id'];
}

export interface ITariffParentStore {
  loaded: boolean;
  loading: boolean;
  tariff_parents: Array<IPreviewTariffParent>;
  dict: Record<IPreviewTariffParent['id'], IPreviewTariffParent>;
}

export type TTariffParentActionTypes = ITariffParentSetAction | ITariffParentUpdateAction | ITariffParentAddAction | ITariffParentRemoveAction | ITariffParentLoading;
