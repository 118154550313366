import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchMailAccounts, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useMailAccounts = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const mail_accounts = useSelector((store: IReducerStore) => store.mail_accounts);

  useEffect(() => {
    if (!initiated && autoload && !mail_accounts.loaded && !mail_accounts.loading) {
      initiated = true;
      dispatch(actionFetchMailAccounts());
    }
  }, [autoload, mail_accounts.loaded, mail_accounts.loading, dispatch, ]);

  return mail_accounts;
};
