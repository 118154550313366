import { SignatureActions, } from '@Store';
import { IPreviewSignature, } from 'atlas-shared';
import { fetchSignatures, } from '@Api';

export const actionFetchSignatures = () => {
  return async (dispatch) => {
    dispatch(actionLoadingSignatures());
    const signatures = await fetchSignatures();

    dispatch(actionSetSignatures(signatures));
    return signatures;
  };
};

export const actionLoadingSignatures = () => ({
  type: SignatureActions.LOADING,
  payload: [],
});

export const actionSetSignatures = (payload: Array<IPreviewSignature>) => ({
  type: SignatureActions.SET_STATE,
  payload: payload,
});

export const actionAddSignature = (payload: IPreviewSignature) => ({
  type: SignatureActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateSignature = (payload: IPreviewSignature) => ({
  type: SignatureActions.UPDATE_STATE,
  payload: payload,
});
