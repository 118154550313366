import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchTimeIntervals, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useTimeIntervals = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const time_intervals = useSelector((store: IReducerStore) => store.time_intervals);

  useEffect(() => {
    if (!initiated && autoload && !time_intervals.loaded && !time_intervals.loading) {
      initiated = true;
      dispatch(actionFetchTimeIntervals());
    }
  }, [autoload, time_intervals.loaded, time_intervals.loading, dispatch, ]);

  return time_intervals;
};
