import React from 'react';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { CrudEnum, IPreviewVoiceAsteriskOperationalHours, PermissionEnum, TVoiceAsteriskOperationalHoursSortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnStringSorter, timeSince, } from '@Utils';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { ColumnsType, } from 'antd/lib/table/interface';
import { listAuditButton, GetColumnSearchLikeProps, GetColumnSearchEqualProps, } from '../components';
import { IWithListProps, } from '@Hocs/with.list';

export const VoiceAsteriskOperationalHoursListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceAsteriskOperationalHours) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceAsteriskOperationalHours, TVoiceAsteriskOperationalHoursSortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceAsteriskOperationalHours, TVoiceAsteriskOperationalHoursSortableListCol>['sorter']): ColumnsType<IPreviewVoiceAsteriskOperationalHours> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewVoiceAsteriskOperationalHours, b: IPreviewVoiceAsteriskOperationalHours) => a.id - b.id,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    {
      title: t('DATE'),
      dataIndex: 'date',
      key: 'date',
      sorter: (a: IPreviewVoiceAsteriskOperationalHours, b: IPreviewVoiceAsteriskOperationalHours) => tableColumnStringSorter(a.date, b.date),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'date')?.order || null,
      filteredValue: filters.date || null,
      ...GetColumnSearchLikeProps(t, 'date', t('DATE')),
      className: 'table-cell-date',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewVoiceAsteriskOperationalHours['created_at'], voice_asterisk_operational_hours: IPreviewVoiceAsteriskOperationalHours) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewVoiceAsteriskOperationalHours, b: IPreviewVoiceAsteriskOperationalHours) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewVoiceAsteriskOperationalHours) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(null, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'voice_asterisk_operational_hours', row)}
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<IPreviewVoiceAsteriskOperationalHours>;

};
