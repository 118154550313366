import { IPreviewVoiceRoute, } from 'atlas-shared';
import { IVoiceRouteStore, VoiceRouteActions, TVoiceRouteActionTypes, } from '@Store';

export const VoiceRouteReducer = (state: IVoiceRouteStore = {
  loaded: false,
  loading: false,
  voice_routes: [],
  dict: {},
}, action: TVoiceRouteActionTypes): IVoiceRouteStore => {
  switch (action.type) {
  case VoiceRouteActions.LOADING:
    return { ...state, loading: true, };
  case VoiceRouteActions.SET_STATE:
    const dict: IVoiceRouteStore['dict'] = {};

    action.payload.forEach((voice_route: IPreviewVoiceRoute) => dict[voice_route.id] = voice_route);

    return { ...state, loaded: true, loading: false, voice_routes: action.payload, dict, };
  case VoiceRouteActions.ADD_STATE:
    return { ...state, voice_routes: [...state.voice_routes, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case VoiceRouteActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_routes: state.voice_routes.filter((item: IPreviewVoiceRoute) => item.id !== action.payload), dict, };
  }
  case VoiceRouteActions.UPDATE_STATE:
    const voice_routes = [...state.voice_routes, ];
    const index = state.voice_routes.findIndex((voice_route: IPreviewVoiceRoute) => voice_route.id === action.payload.id);

    if (!~index)
      voice_routes.push(action.payload);
    else
      voice_routes[index] = action.payload;

    return { ...state, voice_routes, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
