import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateAgentprofile, ISharedUpdateAgentprofile, ISharedAgentprofile, IPreviewOrganization, IPreviewPause, IPreviewQueue, IPreviewTeam, IPreviewVoiceShortcut, EnumOptions, PermissionSelfEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IAgentprofileFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  pauses: Array<IPreviewPause>;
  queues: Array<IPreviewQueue>;
  teams: Array<IPreviewTeam>;
  voice_shortcuts: Array<IPreviewVoiceShortcut>;
}

export const AgentprofileFormOptions = (agentprofile: ISharedCreateAgentprofile | ISharedAgentprofile, props: IAgentprofileFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Agentprofile, {})), 'organization_id', false), 'organization'),
  __pauses: (it: ISharedAgentprofile) => filterRelated<IPreviewPause>(it.organization_id || agentprofile.organization_id, 'pause', 'agentprofile', props.pauses, true, it => !it.global),
  __queues: (it: ISharedAgentprofile) => filterRelated<IPreviewQueue>(it.organization_id || agentprofile.organization_id, 'queue', 'agentprofile', props.queues, true),
  global_search_queues: (it: ISharedAgentprofile) => filterRelated<IPreviewQueue>(it.organization_id || agentprofile.organization_id, 'queue', 'agentprofile', props.queues, true),
  __teams: (it: ISharedAgentprofile) => filterRelated<IPreviewTeam>(it.organization_id || agentprofile.organization_id, 'team', 'agentprofile', props.teams, true),
  __voice_shortcuts: (it: ISharedAgentprofile) => filterRelated<IPreviewVoiceShortcut>(it.organization_id || agentprofile.organization_id, 'voice_shortcut', 'agentprofile', props.voice_shortcuts, true),
  settings_autopause: (it: ISharedAgentprofile) => filterRelated<IPreviewPause>(it.organization_id || agentprofile.organization_id, 'pause', 'agentprofile', props.pauses, true, it => !it.global),
  settings_play_recording: EnumOptions('PERMISSION_SELF', PermissionSelfEnum, t),
  settings_download_recording: EnumOptions('PERMISSION_SELF', PermissionSelfEnum, t),
  settings_view_transcript: EnumOptions('PERMISSION_SELF', PermissionSelfEnum, t),
});

export const AgentprofileComponents: IForm['components'] = {};
export const AgentprofileAdditionalParams: (auth: IAuth, props: IAgentprofileFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IAgentprofileFormOptionsProps) => ({
  __pauses: {
    onModalAdd: {
      key: 'queue-__pauses-add',
      path: '@Components/page-views/pause',
      component: 'PauseFormAddView',
      item_text: 'PAUSE',
    },
  }, 
  __teams: {
    onModalAdd: {
      key: 'queue-__teams-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM',
    },
  }, 
  __voice_shortcuts: {
    onModalAdd: {
      key: 'queue-__voice_shortcuts-add',
      path: '@Components/page-views/voice_shortcut',
      component: 'VoiceShortcutFormAddView',
      item_text: 'VOICE_SHORTCUT',
    },
  }, 
  settings_autopause: {
    onModalAdd: {
      key: 'queue-settings_autopause-add',
      path: '@Components/page-views/pause',
      component: 'PauseFormAddView',
      item_text: 'PAUSE',
    },
  },
});

export const AgentprofileEditForm = (agentprofile: ISharedAgentprofile, props: IAgentprofileFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `agentprofile_edit_${agentprofile.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === agentprofile.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: '__pauses', },
    { ref: '__queues', },
    { ref: 'global_search_queues', },
    { ref: '__teams', },
    { ref: '__voice_shortcuts', },
    { ref: 'settings', },
  ],
});

export const AgentprofileAddForm = (agentprofile: ISharedCreateAgentprofile, props: IAgentprofileFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'agentprofile_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: '__pauses', },
    { ref: '__queues', },
    { ref: 'global_search_queues', },
    { ref: '__teams', },
    { ref: '__voice_shortcuts', },
    { ref: 'settings', },
  ],
});

export const AgentprofileAddInitialValues: ISharedCreateAgentprofile = {} as ISharedCreateAgentprofile;

export const AgentprofileEditInitialValues = (agentprofile: ISharedAgentprofile): ISharedUpdateAgentprofile => ({
  title: agentprofile.title,
  __pauses: agentprofile.__pauses,
  __queues: agentprofile.__queues,
  global_search_queues: agentprofile.global_search_queues,
  __teams: agentprofile.__teams,
  __voice_shortcuts: agentprofile.__voice_shortcuts,
  settings: agentprofile.settings,
});

export const onAgentprofileFormSaved = (t: TFunction, action: 'edit' | 'add', agentprofile: ISharedAgentprofile, navigate: NavigateFunction): void => {
  navigate(onFormSaved('agentprofiles'));
};
