import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchPhonenumberSetupCosts, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const usePhonenumberSetupCosts = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const phonenumber_setup_costs = useSelector((store: IReducerStore) => store.phonenumber_setup_costs);

  useEffect(() => {
    if (!initiated && autoload && !phonenumber_setup_costs.loaded && !phonenumber_setup_costs.loading) {
      initiated = true;
      dispatch(actionFetchPhonenumberSetupCosts());
    }
  }, [autoload, phonenumber_setup_costs.loaded, phonenumber_setup_costs.loading, dispatch, ]);

  return phonenumber_setup_costs;
};
