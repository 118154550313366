import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateJourneyDraft, ISharedUpdateJourneyDraft, ISharedJourneyDraft, IPreviewOrganization, IPreviewJourney, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IJourneyDraftFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  journeies: Array<IPreviewJourney>;
}

export const JourneyDraftFormOptions = (journey_draft: ISharedCreateJourneyDraft | ISharedJourneyDraft, props: IJourneyDraftFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Journey, {})), 'organization_id', false), 'organization'),
  journey_id: (it: ISharedJourneyDraft) => filterRelated<IPreviewJourney>(it.organization_id || journey_draft.organization_id, 'journey', 'journey_draft', props.journeies, true),
  
});

export const JourneyDraftComponents: IForm['components'] = {};
export const JourneyDraftAdditionalParams: (auth: IAuth, props: IJourneyDraftFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IJourneyDraftFormOptionsProps) => ({
});

export const JourneyDraftEditForm = (journey_draft: ISharedJourneyDraft, props: IJourneyDraftFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `journey_draft_edit_${journey_draft.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === journey_draft.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'payload', },
  ],
});

export const JourneyDraftAddForm = (journey_draft: ISharedCreateJourneyDraft, props: IJourneyDraftFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'journey_draft_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'journey_id', },
    { ref: 'title', },
  ],
});

export const JourneyDraftAddInitialValues: ISharedCreateJourneyDraft = {} as ISharedCreateJourneyDraft;

export const JourneyDraftEditInitialValues = (journey_draft: ISharedJourneyDraft): ISharedUpdateJourneyDraft => ({
  title: journey_draft.title,
  payload: journey_draft.payload,
});

export const onJourneyDraftFormSaved = (t: TFunction, action: 'edit' | 'add', journey_draft: ISharedJourneyDraft, navigate: NavigateFunction): void => {
  navigate(onFormSaved('journey_drafts'));
};
