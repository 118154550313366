import { IPreviewVoiceTrunk, } from 'atlas-shared';

export enum VoiceTrunkActions {
  SET_STATE = 'VOICE_TRUNK_SET_STATE',
  ADD_STATE = 'VOICE_TRUNK_ADD_STATE',
  REMOVE_STATE = 'VOICE_TRUNK_REMOVE_STATE',
  GET_STATE = 'VOICE_TRUNK_GET_STATE',
  UPDATE_STATE = 'VOICE_TRUNK_UPDATE_STATE',
  LOADING = 'VOICE_TRUNK_LOADING',
}

export interface IVoiceTrunkLoading {
  type: VoiceTrunkActions.LOADING;
}

export interface IVoiceTrunkSetAction {
  type: VoiceTrunkActions.SET_STATE;
  payload: Array<IPreviewVoiceTrunk>;
}

export interface IVoiceTrunkUpdateAction {
  type: VoiceTrunkActions.UPDATE_STATE;
  payload: IPreviewVoiceTrunk;
}

export interface IVoiceTrunkAddAction {
  type: VoiceTrunkActions.ADD_STATE;
  payload: IPreviewVoiceTrunk;
}

export interface IVoiceTrunkRemoveAction {
  type: VoiceTrunkActions.REMOVE_STATE;
  payload: IPreviewVoiceTrunk['id'];
}

export interface IVoiceTrunkStore {
  loaded: boolean;
  loading: boolean;
  voice_trunks: Array<IPreviewVoiceTrunk>;
  dict: Record<IPreviewVoiceTrunk['id'], IPreviewVoiceTrunk>;
}

export type TVoiceTrunkActionTypes = IVoiceTrunkSetAction | IVoiceTrunkUpdateAction | IVoiceTrunkAddAction | IVoiceTrunkRemoveAction | IVoiceTrunkLoading;
