import { useTranslation, } from 'react-i18next';
import { useAuth, } from '@Hooks';
import { useNavigate, } from 'react-router-dom';
import './header.search.results.scss';
import { clearRecentlySearched, deleteRecentlySearched, } from '@Api';
import { preventDefault, sortByProperty, } from '@Utils';
import { actionSetGlobalSearch, IRecentlySearchedStore, useAppDispatch, } from '@Store';
import React from 'react';
import { IPreviewRecentlySearched, } from 'atlas-shared';
import { XIcon, } from '@Assets';
import { Icon, } from '@Components';

interface IProps {
  setSelectedSearchType: any;
  setInputFocus: Function;
  searches: IRecentlySearchedStore;
}

export const HeaderSearchRecentlySearch = React.memo(({ searches, setInputFocus, setSelectedSearchType, }: IProps) => {
  const { t, } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const navigate = useNavigate();
  const onDelete = (search: IPreviewRecentlySearched) => deleteRecentlySearched(search.id);
  const onClear = () => clearRecentlySearched(auth.user.id, auth.user_status.agentprofile_id || 0);

  if (!searches.recently_searcheds.length)
    return <></>;

  return (
    <>
      <div className={'search-section-header'} >
        <div className={'search-section-title'} >
          {t('RECENTLY_SEARCHED')}
        </div>
        <div className={'s-clear-btn'} onClick={onClear}>
          {t('CLEAR')}
        </div>
      </div>
      <ul className={'recent-searched-list'}>
        {
          sortByProperty(searches.recently_searcheds, 'id').slice(0, 5).map((search) => {
            return (
              <li onClick={() => {
                setSelectedSearchType(search.section);
                dispatch(actionSetGlobalSearch(search.search));
                navigate(`/dashboard/search?keyword=${search.search}`);
              }}>
                <div className={'result-text'}>
                  <div className='result-row'>
                    {search.search}
                  </div>
                </div>
                <Icon className={'closeable-icon'} icon={XIcon} iconProps={{ size: 14, }} onClick={preventDefault(() => {
                  onDelete(search);
                  setInputFocus();
                })} />
              </li>
            );
          })
        }
      </ul>
    </>
  );
});
