import { useLayoutEffect, useState, } from 'react';

export const useWindowSize = (): [number, number] => {
  const [size, setSize, ] = useState<[number, number]>([window.innerWidth, window.innerHeight, ]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight, ]);
    };

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};
