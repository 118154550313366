import { TFunction, } from 'i18next';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { IPreviewOperationalHours, ISharedOperationalHours, TimezoneMapOptions, TOperationalHoursSortableListCol, CrudEnum, PermissionEnum, } from 'atlas-shared';
import React from 'react';
import { ColumnsType, } from 'antd/lib/table/interface';
import { OperationalHoursListColumns as OperationalHoursListColumnsBase, } from './abstract/operational_hours.list.column.abstract';
import { NavigateFunction, } from 'react-router';
import { IWithListProps, } from '@Hocs/with.list';
import { ClockIcon, } from '@Assets';
import { Icon, } from '@Components';
import { patchOperationalHours, } from '@Api';

export * from './abstract/operational_hours.list.column.abstract';

export const OperationalHoursListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewOperationalHours) => React.ReactElement> = [], filters: IWithListProps<IPreviewOperationalHours, TOperationalHoursSortableListCol>['filters'], sorter: IWithListProps<IPreviewOperationalHours, TOperationalHoursSortableListCol>['sorter']): ColumnsType<IPreviewOperationalHours> => {
  let cols = OperationalHoursListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, [
    (row: IPreviewOperationalHours) => auth.is_superadmin && abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.OperationalHours, row) ? <Icon tooltip={{ title: t('TOGGLE_ASTERISK_HOURS'), }} icon={ClockIcon} iconProps={{ size: 14, }} onClick={_ => patchOperationalHours(row.id, { asterisk_hours: !row.asterisk_hours, }) } /> : <></>,
    ...buttons,
  ], filters, sorter).map(col => {

    if (col.key === 'timezone') {
      col.render = (timezone: ISharedOperationalHours['timezone']) => TimezoneMapOptions.find(tz => tz.key === timezone)?.title || '';
      delete col.filterDropdown;
      delete col.filterIcon;
      delete col.onFilter;
    }

    return col;
  });

  if (!auth.is_superadmin)
    cols = cols.filter(col => col.key !== 'asterisk_hours');

  return cols;
};
