import { NavigateFunction, } from 'react-router';
import {
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { ISharedCreateVoiceAsterisk, ISharedUpdateVoiceAsterisk, ISharedVoiceAsterisk, IPreviewVoiceAsteriskDowngrade, IPreviewOrganization, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { entitiesToOptions, onFormSaved, } from '@Utils';

export interface IVoiceAsteriskFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  voice_asterisk_downgrades: Array<IPreviewVoiceAsteriskDowngrade>;
  organizations: Array<IPreviewOrganization>;
}

export const VoiceAsteriskFormOptions = (voice_asterisk: ISharedCreateVoiceAsterisk | ISharedVoiceAsterisk, props: IVoiceAsteriskFormOptionsProps, t: TFunction): IFormOptions => ({
  voice_asterisk_downgrade_id: entitiesToOptions<IPreviewVoiceAsteriskDowngrade>(props.voice_asterisk_downgrades, 'voice_asterisk_downgrade'),
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  
});

export const VoiceAsteriskComponents: IForm['components'] = {};
export const VoiceAsteriskAdditionalParams: (auth: IAuth, props: IVoiceAsteriskFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IVoiceAsteriskFormOptionsProps) => ({
});

export const VoiceAsteriskEditForm = (voice_asterisk: ISharedVoiceAsterisk, props: IVoiceAsteriskFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `voice_asterisk_edit_${voice_asterisk.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'host', },
    { ref: 'internal_host', },
    { ref: 'port', },
    { ref: 'tls', },
    { ref: 'from_domain', },
    { ref: 'iso2', },
    { ref: 'rest', },
    { ref: 'voice_asterisk_downgrade_id', },
    { ref: 'description', },
  ],
});

export const VoiceAsteriskAddForm = (voice_asterisk: ISharedCreateVoiceAsterisk, props: IVoiceAsteriskFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'voice_asterisk_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'host', },
    { ref: 'internal_host', },
    { ref: 'port', },
    { ref: 'tls', },
    { ref: 'from_domain', },
    { ref: 'iso2', },
    { ref: 'rest', },
    { ref: 'voice_asterisk_downgrade_id', },
    { ref: 'description', },
  ],
});

export const VoiceAsteriskAddInitialValues: ISharedCreateVoiceAsterisk = {} as ISharedCreateVoiceAsterisk;

export const VoiceAsteriskEditInitialValues = (voice_asterisk: ISharedVoiceAsterisk): ISharedUpdateVoiceAsterisk => ({
  title: voice_asterisk.title,
  host: voice_asterisk.host,
  internal_host: voice_asterisk.internal_host,
  port: voice_asterisk.port,
  tls: voice_asterisk.tls,
  from_domain: voice_asterisk.from_domain,
  iso2: voice_asterisk.iso2,
  rest: voice_asterisk.rest,
  voice_asterisk_downgrade_id: voice_asterisk.voice_asterisk_downgrade_id,
  description: voice_asterisk.description,
});

export const onVoiceAsteriskFormSaved = (t: TFunction, action: 'edit' | 'add', voice_asterisk: ISharedVoiceAsterisk, navigate: NavigateFunction): void => {
  navigate(onFormSaved('voice_asterisks'));
};
