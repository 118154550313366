import { IPreviewJourneyTrigger, } from 'atlas-shared';

export enum JourneyTriggerActions {
  SET_STATE = 'JOURNEY_TRIGGER_SET_STATE',
  ADD_STATE = 'JOURNEY_TRIGGER_ADD_STATE',
  REMOVE_STATE = 'JOURNEY_TRIGGER_REMOVE_STATE',
  GET_STATE = 'JOURNEY_TRIGGER_GET_STATE',
  UPDATE_STATE = 'JOURNEY_TRIGGER_UPDATE_STATE',
  LOADING = 'JOURNEY_TRIGGER_LOADING',
}

export interface IJourneyTriggerLoading {
  type: JourneyTriggerActions.LOADING;
}

export interface IJourneyTriggerSetAction {
  type: JourneyTriggerActions.SET_STATE;
  payload: Array<IPreviewJourneyTrigger>;
}

export interface IJourneyTriggerUpdateAction {
  type: JourneyTriggerActions.UPDATE_STATE;
  payload: IPreviewJourneyTrigger;
}

export interface IJourneyTriggerAddAction {
  type: JourneyTriggerActions.ADD_STATE;
  payload: IPreviewJourneyTrigger;
}

export interface IJourneyTriggerRemoveAction {
  type: JourneyTriggerActions.REMOVE_STATE;
  payload: IPreviewJourneyTrigger['id'];
}

export interface IJourneyTriggerStore {
  loaded: boolean;
  loading: boolean;
  journey_triggers: Array<IPreviewJourneyTrigger>;
  dict: Record<IPreviewJourneyTrigger['id'], IPreviewJourneyTrigger>;
}

export type TJourneyTriggerActionTypes = IJourneyTriggerSetAction | IJourneyTriggerUpdateAction | IJourneyTriggerAddAction | IJourneyTriggerRemoveAction | IJourneyTriggerLoading;
