import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchPointsSystems, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const usePointsSystems = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const points_systems = useSelector((store: IReducerStore) => store.points_systems);

  useEffect(() => {
    if (!initiated && autoload && !points_systems.loaded && !points_systems.loading) {
      initiated = true;
      dispatch(actionFetchPointsSystems());
    }
  }, [autoload, points_systems.loaded, points_systems.loading, dispatch, ]);

  return points_systems;
};
