import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchListFiles, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useListFiles = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const list_files = useSelector((store: IReducerStore) => store.list_files);

  useEffect(() => {
    if (!initiated && autoload && !list_files.loaded && !list_files.loading) {
      initiated = true;
      dispatch(actionFetchListFiles());
    }
  }, [autoload, list_files.loaded, list_files.loading, dispatch, ]);

  return list_files;
};
