import React, { Dispatch, SetStateAction, useMemo, } from 'react';
import { Tag, Tooltip, } from 'antd';
import './conversation.form.preview.scss';
import { ConversationStatusEnum, IPreviewForm, ISharedFullConversation, } from 'atlas-shared';
import { useFields, } from '@Hooks';
import { useTranslation, } from 'react-i18next';
import { SettingsIcon, } from '@Assets/icons';
import { Icon, AppSpinner, } from '@Components';

interface IProps {
  conversation: ISharedFullConversation;
  form?: IPreviewForm;
  editConversationVisible?: boolean;
  setEditConversationVisible?: Dispatch<SetStateAction<boolean>>;
}

export const ConversationFormPreview = React.memo(({ conversation, form, editConversationVisible, setEditConversationVisible, }: IProps) => {
  const fields = useFields();
  const { t, } = useTranslation();

  const _fields = useMemo(() => {
    const limit = 7;
    const _fields = (fields.loaded && form?.__fields || []).map(field_id => {
      const field = fields.dict[field_id];
      const value = conversation.data[field.alias];
      const required = !!field.settings[conversation.status === ConversationStatusEnum.Closed ? 'required_on_conversation_close' : 'required_on_save'];
      const close_required = !!field.settings.required_on_conversation_close;

      let translated_value: string = value;

      if (value === true)
        translated_value = t('YES');

      else if (value === false)
        translated_value = t('NO');
      else if (Array.isArray(translated_value))
        translated_value = translated_value.join(', ');

      let elm = <Tag key={field_id} className={value === undefined ? required ? 'tag-error' : close_required ? 'tag-warning' : '' : ''}>
        <span className='tag-title'>{field.display_title || field.title}</span>
        {translated_value ? <span className='value'><span>{translated_value}</span></span> : required ? <span className='error'>{t('REQUIRED')}</span> : ''}
      </Tag>;

      if (required || close_required)
        elm = <Tooltip key={field_id} placement="top" title={t(close_required ? 'CONVERSATION_FORM_DATA_CLOSE_REQUIRED_TOOLTIP' : 'CONVERSATION_FORM_DATA_CLOSE_TOOLTIP')}>
          {elm}
        </Tooltip>;

      return {
        elm,
        value: !!translated_value,
        required: required || close_required,
      };
    });

    _fields.sort((a, b) => a.required ? -2 : a.value ? -1 : 1);

    if (_fields.length >= limit + 1)
      return [..._fields.slice(0, limit), { elm: <span key={'count'} className='count'>+{_fields.length - limit}</span>, }, ];

    return _fields;
  }, [form?.__fields, fields, conversation.data, ]);

  if (!form)
    return <></>;

  if (!fields.loaded)
    return <AppSpinner />;

  return <div className='conversation-form-preview'>
    <div className='tag-list'>
      <div className='tag-list-inner'>
        {_fields.map(({ elm, }) => elm)}
      </div>

      <Icon
        className='edit'
        icon={SettingsIcon}
        onClick={() => {
          setEditConversationVisible?.(!editConversationVisible);
        }}
      />
    </div>
  </div>;
});
