import { IPreviewAiAssistant, } from 'atlas-shared';

export enum AiAssistantActions {
  SET_STATE = 'AI_ASSISTANT_SET_STATE',
  ADD_STATE = 'AI_ASSISTANT_ADD_STATE',
  REMOVE_STATE = 'AI_ASSISTANT_REMOVE_STATE',
  GET_STATE = 'AI_ASSISTANT_GET_STATE',
  UPDATE_STATE = 'AI_ASSISTANT_UPDATE_STATE',
  LOADING = 'AI_ASSISTANT_LOADING',
}

export interface IAiAssistantLoading {
  type: AiAssistantActions.LOADING;
}

export interface IAiAssistantSetAction {
  type: AiAssistantActions.SET_STATE;
  payload: Array<IPreviewAiAssistant>;
}

export interface IAiAssistantUpdateAction {
  type: AiAssistantActions.UPDATE_STATE;
  payload: IPreviewAiAssistant;
}

export interface IAiAssistantAddAction {
  type: AiAssistantActions.ADD_STATE;
  payload: IPreviewAiAssistant;
}

export interface IAiAssistantRemoveAction {
  type: AiAssistantActions.REMOVE_STATE;
  payload: IPreviewAiAssistant['id'];
}

export interface IAiAssistantStore {
  loaded: boolean;
  loading: boolean;
  ai_assistants: Array<IPreviewAiAssistant>;
  dict: Record<IPreviewAiAssistant['id'], IPreviewAiAssistant>;
}

export type TAiAssistantActionTypes = IAiAssistantSetAction | IAiAssistantUpdateAction | IAiAssistantAddAction | IAiAssistantRemoveAction | IAiAssistantLoading;
