import { FormLayoutTypes, IForm, } from 'atlas-form';
import {
  ISlaPolicyFormOptionsProps,
  SlaPolicyAdditionalParams as SlaPolicyAdditionalParamsBase,
} from './abstract/sla_policy.form.abstract';
import {
  ConversationPriorityEnum, EnumValues,
  ISharedCreateSlaPolicy, SlaTimeUnitEnum,
} from 'atlas-shared';
import { IAuth, } from '@Store';

export * from './abstract/sla_policy.form.abstract';

export const SlaPolicyAdditionalParams: (auth: IAuth, props: ISlaPolicyFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ISlaPolicyFormOptionsProps) => {
  const additionalParams: IForm['additionalParams'] = {
    ...SlaPolicyAdditionalParamsBase(auth, props),
    policy: { labelWidth: '80px', },
  };

  EnumValues(ConversationPriorityEnum).forEach(p => {
    additionalParams[`policy_${p}`] = { ...additionalParams[`policy_${p}`], label: `PRIORITY_${p}`, labelWidth: '45px', };
    ['first_response', 'every_response', 'resolution_time', ].forEach(k => {
      additionalParams[`policy_${p}_${k}`] = { ...additionalParams[`policy_${p}_${k}`], ui_layout: FormLayoutTypes.INLINE, labelWidth: '105px', };
    });
    additionalParams[`policy_${p}_operational_hours_id`] = {
      ...additionalParams[`policy_${p}_operational_hours_id`],
      labelWidth: '105px',
      help: props.t('LEAVE_EMPTY_FOR_ALL_DAY_EVERYDAY'),
    };
  });

  return additionalParams;
};

export const SlaPolicyAddInitialValues: ISharedCreateSlaPolicy = {
  policy: {
    U: { first_response: { time: 4, unit: SlaTimeUnitEnum.Hours, }, every_response: { time: 3, unit: SlaTimeUnitEnum.Hours, }, resolution_time: { time: 8, unit: SlaTimeUnitEnum.Hours, }, },
    H: { first_response: { time: 8, unit: SlaTimeUnitEnum.Hours, }, every_response: { time: 3, unit: SlaTimeUnitEnum.Hours, }, resolution_time: { time: 16, unit: SlaTimeUnitEnum.Hours, }, },
    M: { first_response: { time: 24, unit: SlaTimeUnitEnum.Hours, }, every_response: { time: 3, unit: SlaTimeUnitEnum.Hours, }, resolution_time: { time: 48, unit: SlaTimeUnitEnum.Hours, }, },
    L: { first_response: { time: 56, unit: SlaTimeUnitEnum.Hours, }, every_response: { time: 3, unit: SlaTimeUnitEnum.Hours, }, resolution_time: { time: 112, unit: SlaTimeUnitEnum.Hours, }, },
  },
} as ISharedCreateSlaPolicy;
