import React, { useEffect, useMemo, useState, } from 'react';
import './header.user.presence.scss';
import { ChevronDownIcon, } from '@Assets';
import { Icon, Avatar, } from '@Components';
import { Popover, } from 'antd';
import { useAuth, useUsers, } from '@Hooks';
import { IPreviewUser, RoleEnum, hasRole, } from 'atlas-shared';
import { HeaderUserPresenceList, } from './header.user.presence.list';

export const HeaderUserPresence = React.memo(() => {
  const users = useUsers();
  const [filteredUsers, setFilteredUsers, ] = useState<Array<IPreviewUser>>([]);
  const auth = useAuth();
  const isSuperadmin = useMemo(() => hasRole(auth.user, RoleEnum.Superadmin), [auth, ]);

  useEffect(() => {
    setFilteredUsers((isSuperadmin ? users.users : users.users.filter(user => !hasRole(user, RoleEnum.Superadmin))).filter(user => !user.deleted_at && user.active && user.id !== auth.user.id).sort((a, b) => (Math.random() > .5) ? 1 : -1).slice(0, 3));
  }, [users.users, auth.user.id, isSuperadmin, ]);

  return filteredUsers.length ? <div className={'header-user-presence'} >
    <Popover
      className={'user-menu-popup'}
      placement='bottomRight'
      trigger='click'
      content={<HeaderUserPresenceList />}
      destroyTooltipOnHide={true}
      zIndex={1}
    >
      <div className={'preview-users'} >
        <ul>
          {filteredUsers.map(user => <li key={`user_${user.id}`}>
            <div>
              <Avatar user={user} size={32} />
            </div>
          </li>)}
        </ul>
        <div className={'chevron'} >
          <Icon icon={ChevronDownIcon} />
        </div>
      </div>
    </Popover>
  </div> : <></>;
});
