import { IPreviewRecentlySearched, } from 'atlas-shared';
import { IRecentlySearchedStore, RecentlySearchedActions, TRecentlySearchedActionTypes, } from '@Store';

export const RecentlySearchedReducer = (state: IRecentlySearchedStore = {
  loaded: false,
  loading: false,
  recently_searcheds: [],
  dict: {},
}, action: TRecentlySearchedActionTypes): IRecentlySearchedStore => {
  switch (action.type) {
  case RecentlySearchedActions.LOADING:
    return { ...state, loading: true, };
  case RecentlySearchedActions.SET_STATE:
    const dict: IRecentlySearchedStore['dict'] = {};

    action.payload.forEach((recently_searched: IPreviewRecentlySearched) => dict[recently_searched.id] = recently_searched);

    return { ...state, loaded: true, loading: false, recently_searcheds: action.payload, dict, };
  case RecentlySearchedActions.ADD_STATE:
    return { ...state, recently_searcheds: [...state.recently_searcheds, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case RecentlySearchedActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, recently_searcheds: state.recently_searcheds.filter((item: IPreviewRecentlySearched) => item.id !== action.payload), dict, };
  }
  case RecentlySearchedActions.UPDATE_STATE:
    const recently_searcheds = [...state.recently_searcheds, ];
    const index = state.recently_searcheds.findIndex((recently_searched: IPreviewRecentlySearched) => recently_searched.id === action.payload.id);

    if (!~index)
      recently_searcheds.push(action.payload);
    else
      recently_searcheds[index] = action.payload;

    return { ...state, recently_searcheds, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
