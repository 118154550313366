import { GlobalActionTypes, IGlobalPopupModal, IGlobalStore, } from '@Store';
import {
  IPreviewConversation,
  Nullable,
} from 'atlas-shared';

export const actionSetTheme = (theme: string) => ({
  type: GlobalActionTypes.SET_THEME,
  payload: theme,
});

export const actionSetFocusedConversation = (focused_conversation: Nullable<IPreviewConversation['id']>) => ({
  type: GlobalActionTypes.SET_FOCUSED_CONVERSATION,
  payload: focused_conversation,
});

export const actionSetHandlingConversation = (handling_conversation: Nullable<IPreviewConversation['id']>) => ({
  type: GlobalActionTypes.SET_HANDLING_CONVERSATION,
  payload: handling_conversation,
});

export const actionSetHideStats = (hide: boolean) => ({
  type: GlobalActionTypes.HIDE_STATS,
  payload: hide,
});

export const actionSetHideDashboardLeft = (hide: boolean) => ({
  type: GlobalActionTypes.HIDE_DASHBOARD_LEFT,
  payload: hide,
});

export const actionSetLoading = (loading: boolean) => {
  if (loading) {
    return {
      action: GlobalActionTypes.ADD_LOADER,
    };
  }

  return {
    action: GlobalActionTypes.REDUCE_LOADER,
  };
};

export const actionSetGlobalState = (payload: {
  name: string;
  id: string | number;
  values: any;
}) => ({
  type: GlobalActionTypes.ADD_STATE,
  payload: payload,
});

export const actionRemoveGlobalState = (name: string) => ({
  type: GlobalActionTypes.REMOVE_STATE,
  payload: {
    name,
  },
});

export const actionSetGlobalSearch = (search: IGlobalStore['globalSearch']) => ({
  type: GlobalActionTypes.SET_GLOBAL_SEARCH,
  payload: search,
});

export const actionSetGlobalCall = (call: IGlobalStore['call']) => ({
  type: GlobalActionTypes.SET_GLOBAL_CALL,
  payload: call,
});

export const actionSetGlobalCalls = (calls: IGlobalStore['calls']) => ({
  type: GlobalActionTypes.SET_GLOBAL_CALLS,
  payload: calls,
});

export const actionSetResetConversationList = () => ({
  type: GlobalActionTypes.SET_RESET_CONVERSATION_LIST,
  payload: Math.random(),
});

export const actionSetGlobalVoiceQueueRouteOptions = (voice_queue_route_options: IGlobalStore['voice_queue_route_options']) => ({
  type: GlobalActionTypes.SET_GLOBAL_VOICE_QUEUE_ROUTE_OPTIONS,
  payload: voice_queue_route_options,
});

export const actionSetGlobalVoiceQueueRouteSelected = (voice_queue_route_selected: IGlobalStore['voice_queue_route_selected']) => ({
  type: GlobalActionTypes.SET_GLOBAL_VOICE_QUEUE_ROUTE_SELECTED,
  payload: voice_queue_route_selected,
});

export const actionSetGlobalVoiceMicrophone = (voice_mic: IGlobalStore['voice_mic']) => ({
  type: GlobalActionTypes.SET_GLOBAL_VOICE_MIC,
  payload: voice_mic,
});

export const actionSetGlobalVoiceVolume = (voice_volume: IGlobalStore['voice_volume']) => ({
  type: GlobalActionTypes.SET_GLOBAL_VOICE_VOLUME,
  payload: voice_volume,
});

export const actionSetResetPassword = (user_id: IGlobalStore['reset_password']) => ({
  type: GlobalActionTypes.RESET_PASSWORD,
  payload: user_id,
});

export const actionDisplayAudioSettings = (display_audio_settings: IGlobalStore['display_audio_settings']) => ({
  type: GlobalActionTypes.DISPLAY_AUDIO_SETTINGS,
  payload: display_audio_settings,
});

export const actionSetDevices = (devices: IGlobalStore['devices']) => ({
  type: GlobalActionTypes.SET_DEVICES,
  payload: devices,
});

export const actionSetDevicesAvailable = (devices_available: IGlobalStore['devices_available']) => ({
  type: GlobalActionTypes.SET_DEVICES_AVAILABLE,
  payload: devices_available,
});

export const actionSetInactive = (inactive: IGlobalStore['inactive']) => ({
  type: GlobalActionTypes.SET_GLOBAL_INACTIVE,
  payload: inactive,
});

export const actionSetSocketDisconnected = (socket_disconnected: IGlobalStore['socket_disconnected']) => ({
  type: GlobalActionTypes.SET_SOCKET_DISCONNECTED,
  payload: socket_disconnected,
});

export const actionSetIsFullscreen = (is_fullscreen: IGlobalStore['is_fullscreen']) => ({
  type: GlobalActionTypes.SET_GLOBAL_FULLSCREEN,
  payload: is_fullscreen,
});

export const actionAddPopupModal = (popup_modal: IGlobalPopupModal) => ({
  type: GlobalActionTypes.ADD_GLOBAL_POPUP_MODAL,
  payload: popup_modal,
});

export const actionRemovePopupModal = (popup_modal_key: IGlobalPopupModal['key']) => ({
  type: GlobalActionTypes.REMOVE_GLOBAL_POPUP_MODAL,
  payload: popup_modal_key,
});

export const actionDisplayChangelog = (display: boolean) => ({
  type: GlobalActionTypes.DISPLAY_CHANGELOG,
  payload: display,
});

export const actionAiResponding = (ai_responding: boolean) => ({
  type: GlobalActionTypes.AI_RESPONDING,
  payload: ai_responding,
});

export const actionDocsPreview = (docs_preview: IGlobalStore['docs_preview']) => ({
  type: GlobalActionTypes.DOCS_PREVIEW,
  payload: docs_preview,
});

export const actionSwitchingProfile = (switching_profile: IGlobalStore['switching_profile']) => ({
  type: GlobalActionTypes.SWITCHING_PROFILE,
  payload: switching_profile,
});

export const actionKeyPressed = (key_pressed: IGlobalStore['key_pressed']) => ({
  type: GlobalActionTypes.KEY_PRESSED,
  payload: key_pressed,
});
