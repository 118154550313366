import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, } from '@Store';
import {
  CrudEnum,
  IPreviewOrganization,
  PermissionEnum,
  SuperadminProfilePermissionIdEnum,
  TOrganizationSortableListCol,
} from 'atlas-shared';
import React from 'react';
import { ColumnsType, } from 'antd/lib/table/interface';
import { OrganizationListColumns as OrganizationListColumnsBase, } from './abstract/organization.list.column.abstract';
import { Icon, } from '@Components';
import { ListIcon, } from '@Assets';
import { IWithListProps, } from '@Hocs/with.list';

export const OrganizationListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewOrganization) => React.ReactElement> = [], filters: Record<string, any>, sorter: IWithListProps<IPreviewOrganization, TOrganizationSortableListCol>['sorter']): ColumnsType<IPreviewOrganization> => {
  return OrganizationListColumnsBase(
    t,
    navigate,
    showDeleted,
    abilities,
    auth,
    [
      (row: IPreviewOrganization) => row.id === row.invoice_organization_id && abilities.can(null, CrudEnum.Read, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={ListIcon} tooltip={{ title: t('INVOICES'), }} iconProps={{ size: 14, }} onClick={() => { navigate(`/admin/invoices/?f={"organization_id":[[${row.id}]]}`); }} /> : <></>,
      ...buttons,
    ],
    filters,
    sorter
  );

};

export * from './abstract/organization.list.column.abstract';
