import { TFunction, } from 'i18next';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { EnumTranslateTitle, IPreviewMailAccount, ISharedMailAccount, MailAccountStateColor, TMailAccountSortableListCol, } from 'atlas-shared';
import React, { useEffect, useState, } from 'react';
import { ColumnsType, } from 'antd/lib/table/interface';
import { MailAccountListColumns as MailAccountListColumnsBase, } from './abstract/mail_account.list.column.abstract';
import { Icon, } from '@Components';
import { LockIcon, RefreshIcon, } from '@Assets/icons';
import { reconnectMailAccountImap, reconnectMailAccountSmtp, } from '@Api';
import { Tooltip, } from 'antd';
import { timeSince, } from '@Utils';
import { NavigateFunction, } from 'react-router';
import { IWithListProps, } from '@Hocs/with.list';

export * from './abstract/mail_account.list.column.abstract';

const WithState = React.memo((props: {mail_account: IPreviewMailAccount; imapSmtp: 'imap' | 'smtp'; t: TFunction}) => {
  const { mail_account, imapSmtp, t, } = props;
  const state = mail_account[`${imapSmtp}_state`];
  const reconnection = imapSmtp === 'imap' ? reconnectMailAccountImap : reconnectMailAccountSmtp;
  const [title, setTitle, ] = useState<React.ReactElement>();
  const retries = mail_account[`${imapSmtp}_retries`];
  
  useEffect(() => {
    setTitle(<span>
      {t('CONNECTION_AT')}: {mail_account[`${imapSmtp}_connect_at`] && timeSince(t, new Date(mail_account[`${imapSmtp}_connect_at`] as string), true)}<br />
      {retries > 0 ? <>{t('RETRIES')}: {retries}<br /></> : ''}
      {mail_account[`${imapSmtp}_error`] ? <><br />{mail_account[`${imapSmtp}_error`]}</> : ''}
    </span>);
  }, [mail_account, retries, t, imapSmtp, ]);

  return <Tooltip key={`mail_account_${mail_account.id}_${imapSmtp}_error`} placement="top" title={title}>
    <div style={{ display: 'flex', }}>
      <span style={{ color: MailAccountStateColor(state), marginRight: 7, }}>{EnumTranslateTitle(`MAIL_ACCOUNT_${imapSmtp.toUpperCase()}_STATE`, state, t)}</span>
      <Icon icon={RefreshIcon} tooltip={{ title: t('RECONNECT'), }} onClick={() => reconnection(mail_account.id)} />
    </div>
  </Tooltip>;
});

export const MailAccountListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewMailAccount) => React.ReactElement> = [], filters: IWithListProps<IPreviewMailAccount, TMailAccountSortableListCol>['filters'], sorter: IWithListProps<IPreviewMailAccount, TMailAccountSortableListCol>['sorter']): ColumnsType<IPreviewMailAccount> => {
  const columns = MailAccountListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, [
    (mail_account: IPreviewMailAccount) => mail_account.oauth2 ? <Icon icon={LockIcon} tooltip={{ title: t('REAUTHENTICATE_ACCOUNT'), }} iconProps={{ size: 14, }} onClick={() => {
      window.location.href = `${process.env.REACT_APP_BASE_URL || 'http://localhost:8000'}/mail_account/${mail_account.id}/oauth2/redirect`;
    }} /> : <></>,
    ...buttons,
  ], filters, sorter);
  const imap_state_column = columns.find(c => c.key === 'imap_state');

  if (imap_state_column)
    imap_state_column.render = (imap_state: ISharedMailAccount['imap_state'], mail_account: IPreviewMailAccount) => <WithState mail_account={mail_account} imapSmtp={'imap'} t={t} />;

  const smtp_state_column = columns.find(c => c.key === 'smtp_state');

  if (smtp_state_column)
    smtp_state_column.render = (smtp_state: ISharedMailAccount['smtp_state'], mail_account: IPreviewMailAccount) => <WithState mail_account={mail_account} imapSmtp={'smtp'} t={t} />;
  
  return columns;
};
