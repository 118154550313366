import { RestRequest, } from '@Utils';
import { IPreviewContactCustomEvent, ISharedContactCustomEvent, ISharedCreateContactCustomEvent, ISharedPatchContactCustomEvent, } from 'atlas-shared';

export const fetchContactCustomEvent = async (organization_id: IPreviewContactCustomEvent['organization_id'], id: IPreviewContactCustomEvent['id']): Promise<ISharedContactCustomEvent> => {
  return RestRequest.get<ISharedContactCustomEvent>(`contact_custom_event/p/${organization_id}/${id}`, { socket_id: global.socketId, });
};

export const createContactCustomEvent = (payload: ISharedCreateContactCustomEvent) => {
  return RestRequest.post<any, ISharedContactCustomEvent>('contact_custom_event', payload, { socket_id: global.socketId, });
};

export const patchContactCustomEvent = (organization_id: IPreviewContactCustomEvent['organization_id'], id: IPreviewContactCustomEvent['id'], payload: ISharedPatchContactCustomEvent) => {
  return RestRequest.patch<any, ISharedContactCustomEvent>(`contact_custom_event/p/${organization_id}/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteContactCustomEvent = (organization_id: IPreviewContactCustomEvent['organization_id'], id: IPreviewContactCustomEvent['id']) => {
  return RestRequest.delete(`contact_custom_event/p/${organization_id}/${id}`, { socket_id: global.socketId, });
};

export const getContactCustomEventUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('contact_custom_event/update/schema');
};

export const getContactCustomEventCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('contact_custom_event/create/schema');
};

export const getContactCustomEventPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('contact_custom_event/patch/schema');
};
