import { IPreviewApiKey, } from 'atlas-shared';
import { IApiKeyStore, ApiKeyActions, TApiKeyActionTypes, } from '@Store';

export const ApiKeyReducer = (state: IApiKeyStore = {
  loaded: false,
  loading: false,
  api_keys: [],
  dict: {},
}, action: TApiKeyActionTypes): IApiKeyStore => {
  switch (action.type) {
  case ApiKeyActions.LOADING:
    return { ...state, loading: true, };
  case ApiKeyActions.SET_STATE:
    const dict: IApiKeyStore['dict'] = {};

    action.payload.forEach((api_key: IPreviewApiKey) => dict[api_key.id] = api_key);

    return { ...state, loaded: true, loading: false, api_keys: action.payload, dict, };
  case ApiKeyActions.ADD_STATE:
    return { ...state, api_keys: [...state.api_keys, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case ApiKeyActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, api_keys: state.api_keys.filter((item: IPreviewApiKey) => item.id !== action.payload), dict, };
  }
  case ApiKeyActions.UPDATE_STATE:
    const api_keys = [...state.api_keys, ];
    const index = state.api_keys.findIndex((api_key: IPreviewApiKey) => api_key.id === action.payload.id);

    if (!~index)
      api_keys.push(action.payload);
    else
      api_keys[index] = action.payload;

    return { ...state, api_keys, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
