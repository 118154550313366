import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateSlaPolicy, ISharedUpdateSlaPolicy, ISharedSlaPolicy, IPreviewOrganization, IPreviewOperationalHours, EnumOptions, SlaTimeUnitEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface ISlaPolicyFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  operational_hourses: Array<IPreviewOperationalHours>;
}

export const SlaPolicyFormOptions = (sla_policy: ISharedCreateSlaPolicy | ISharedSlaPolicy, props: ISlaPolicyFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.SlaPolicy, {})), 'organization_id', false), 'organization'),
  policy_U_operational_hours_id: (it: ISharedSlaPolicy) => filterRelated<IPreviewOperationalHours>(it.organization_id || sla_policy.organization_id, 'operational_hours', 'sla_policy', props.operational_hourses, true),
  policy_H_operational_hours_id: (it: ISharedSlaPolicy) => filterRelated<IPreviewOperationalHours>(it.organization_id || sla_policy.organization_id, 'operational_hours', 'sla_policy', props.operational_hourses, true),
  policy_M_operational_hours_id: (it: ISharedSlaPolicy) => filterRelated<IPreviewOperationalHours>(it.organization_id || sla_policy.organization_id, 'operational_hours', 'sla_policy', props.operational_hourses, true),
  policy_L_operational_hours_id: (it: ISharedSlaPolicy) => filterRelated<IPreviewOperationalHours>(it.organization_id || sla_policy.organization_id, 'operational_hours', 'sla_policy', props.operational_hourses, true),
  policy_U_first_response_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_U_every_response_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_U_resolution_time_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_H_first_response_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_H_every_response_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_H_resolution_time_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_M_first_response_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_M_every_response_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_M_resolution_time_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_L_first_response_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_L_every_response_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
  policy_L_resolution_time_unit: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
});

export const SlaPolicyComponents: IForm['components'] = {};
export const SlaPolicyAdditionalParams: (auth: IAuth, props: ISlaPolicyFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ISlaPolicyFormOptionsProps) => ({
  policy_U_operational_hours_id: {
    onModalAdd: {
      key: 'queue-policy_U_operational_hours_id-add',
      path: '@Components/page-views/operational_hours',
      component: 'OperationalHoursFormAddView',
      item_text: 'OPERATIONAL_HOURS',
    },
  }, 
  policy_H_operational_hours_id: {
    onModalAdd: {
      key: 'queue-policy_H_operational_hours_id-add',
      path: '@Components/page-views/operational_hours',
      component: 'OperationalHoursFormAddView',
      item_text: 'OPERATIONAL_HOURS',
    },
  }, 
  policy_M_operational_hours_id: {
    onModalAdd: {
      key: 'queue-policy_M_operational_hours_id-add',
      path: '@Components/page-views/operational_hours',
      component: 'OperationalHoursFormAddView',
      item_text: 'OPERATIONAL_HOURS',
    },
  }, 
  policy_L_operational_hours_id: {
    onModalAdd: {
      key: 'queue-policy_L_operational_hours_id-add',
      path: '@Components/page-views/operational_hours',
      component: 'OperationalHoursFormAddView',
      item_text: 'OPERATIONAL_HOURS',
    },
  },
});

export const SlaPolicyEditForm = (sla_policy: ISharedSlaPolicy, props: ISlaPolicyFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `sla_policy_edit_${sla_policy.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === sla_policy.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'policy', },
  ],
});

export const SlaPolicyAddForm = (sla_policy: ISharedCreateSlaPolicy, props: ISlaPolicyFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'sla_policy_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'policy', },
  ],
});

export const SlaPolicyAddInitialValues: ISharedCreateSlaPolicy = {} as ISharedCreateSlaPolicy;

export const SlaPolicyEditInitialValues = (sla_policy: ISharedSlaPolicy): ISharedUpdateSlaPolicy => ({
  title: sla_policy.title,
  policy: sla_policy.policy,
});

export const onSlaPolicyFormSaved = (t: TFunction, action: 'edit' | 'add', sla_policy: ISharedSlaPolicy, navigate: NavigateFunction): void => {
  navigate(onFormSaved('sla_policies'));
};
