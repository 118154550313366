import { NavigateFunction, } from 'react-router';
import {
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { ISharedCreatePhonenumberSetupCostParent, ISharedUpdatePhonenumberSetupCostParent, ISharedPhonenumberSetupCostParent, IPreviewOrganization, EnumOptions, InvoiceCurrencyEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { entitiesToOptions, onFormSaved, } from '@Utils';

export interface IPhonenumberSetupCostParentFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const PhonenumberSetupCostParentFormOptions = (phonenumber_setup_cost_parent: ISharedCreatePhonenumberSetupCostParent | ISharedPhonenumberSetupCostParent, props: IPhonenumberSetupCostParentFormOptionsProps, t: TFunction): IFormOptions => ({
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  currency: EnumOptions('INVOICE_CURRENCY', InvoiceCurrencyEnum, t),
});

export const PhonenumberSetupCostParentComponents: IForm['components'] = {};
export const PhonenumberSetupCostParentAdditionalParams: (auth: IAuth, props: IPhonenumberSetupCostParentFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPhonenumberSetupCostParentFormOptionsProps) => ({
});

export const PhonenumberSetupCostParentEditForm = (phonenumber_setup_cost_parent: ISharedPhonenumberSetupCostParent, props: IPhonenumberSetupCostParentFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `phonenumber_setup_cost_parent_edit_${phonenumber_setup_cost_parent.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'currency', },
    { ref: 'phonenumber_setup_cost_list', },
  ],
});

export const PhonenumberSetupCostParentAddForm = (phonenumber_setup_cost_parent: ISharedCreatePhonenumberSetupCostParent, props: IPhonenumberSetupCostParentFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'phonenumber_setup_cost_parent_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'currency', },
    { ref: 'phonenumber_setup_cost_list', },
  ],
});

export const PhonenumberSetupCostParentAddInitialValues: ISharedCreatePhonenumberSetupCostParent = {} as ISharedCreatePhonenumberSetupCostParent;

export const PhonenumberSetupCostParentEditInitialValues = (phonenumber_setup_cost_parent: ISharedPhonenumberSetupCostParent): ISharedUpdatePhonenumberSetupCostParent => ({
  title: phonenumber_setup_cost_parent.title,
  currency: phonenumber_setup_cost_parent.currency,
  phonenumber_setup_cost_list: phonenumber_setup_cost_parent.phonenumber_setup_cost_list,
});

export const onPhonenumberSetupCostParentFormSaved = (t: TFunction, action: 'edit' | 'add', phonenumber_setup_cost_parent: ISharedPhonenumberSetupCostParent, navigate: NavigateFunction): void => {
  navigate(onFormSaved('phonenumber_setup_cost_parents'));
};
