import { IPreviewForm, } from 'atlas-shared';

export enum FormActions {
  SET_STATE = 'FORM_SET_STATE',
  ADD_STATE = 'FORM_ADD_STATE',
  REMOVE_STATE = 'FORM_REMOVE_STATE',
  GET_STATE = 'FORM_GET_STATE',
  UPDATE_STATE = 'FORM_UPDATE_STATE',
  LOADING = 'FORM_LOADING',
}

export interface IFormLoading {
  type: FormActions.LOADING;
}

export interface IFormSetAction {
  type: FormActions.SET_STATE;
  payload: Array<IPreviewForm>;
}

export interface IFormUpdateAction {
  type: FormActions.UPDATE_STATE;
  payload: IPreviewForm;
}

export interface IFormAddAction {
  type: FormActions.ADD_STATE;
  payload: IPreviewForm;
}

export interface IFormRemoveAction {
  type: FormActions.REMOVE_STATE;
  payload: IPreviewForm['id'];
}

export interface IFormStore {
  loaded: boolean;
  loading: boolean;
  forms: Array<IPreviewForm>;
  dict: Record<IPreviewForm['id'], IPreviewForm>;
}

export type TFormActionTypes = IFormSetAction | IFormUpdateAction | IFormAddAction | IFormRemoveAction | IFormLoading;
