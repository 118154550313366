import React, { useCallback, useMemo, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  SoundAddForm,
  SoundAddInitialValues,
  SoundComponents,
  SoundAdditionalParams,
  SoundFormOptions,
  onSoundFormSaved,
  ISoundFormOptionsProps,
  AlertError,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateSound, PermissionEnum, } from 'atlas-shared';
import { createSound, getSoundCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateSound> {}

const SoundFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const param_props: ISoundFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
  };
  const formLayout = useMemo(() => SoundAddForm(values, param_props, t), [values, organizations, t, abilities, ]);
  const options: IFormOptions = useMemo(() => SoundFormOptions(values || SoundAddInitialValues, param_props, t), [values, organizations, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateSound, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateSound) => save(() => createSound(values).then(
    res => onSaved ? onSaved(res, navigate) : onSoundFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.Sound, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={SoundComponents}
        additionalParams={SoundAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...SoundAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('SOUND')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const SoundFormAddView = React.memo(withAdd(SoundFormAdd, getSoundCreateSchema, SoundAddInitialValues));
