import { TFunction, } from 'i18next';
import { IForm, IFormOptions, } from '@Modules/atlas-form-core';
import { IVoiceRouteFormOptionsProps, } from '@Utils/forms/abstract/voice_route.form.abstract';

export * from './abstract/voice_route.form.abstract';
import { VoiceRouteAdditionalParams as VoiceRouteAdditionalParamsBase, VoiceRouteFormOptions as VoiceRouteFormOptionsBase, } from './abstract/voice_route.form.abstract';

import {
  ChannelEnum,
  DirectionEnum,
  IPreviewJourney,
  ISharedCreateVoiceRoute,
  ISharedVoiceRoute,
} from 'atlas-shared/dist';
import { filterRelated, } from '@Utils/relation';
import { IAuth, } from '@Store';

export const VoiceRouteFormOptions = (voice_route: ISharedCreateVoiceRoute | ISharedVoiceRoute, props: IVoiceRouteFormOptionsProps, t: TFunction): IFormOptions => ({
  ...VoiceRouteFormOptionsBase(voice_route, props, t),
  inbound_journey_id: (it: ISharedVoiceRoute) => filterRelated<IPreviewJourney>(it.organization_id || voice_route.organization_id, 'journey', 'voice_route', props.journeies, true, it => it.channels.some(({ c, d, }) => c === ChannelEnum.Voice && d === DirectionEnum.InBound)),
  outbound_journey_id: (it: ISharedVoiceRoute) => filterRelated<IPreviewJourney>(it.organization_id || voice_route.organization_id, 'journey', 'voice_route', props.journeies, true, it => it.channels.some(({ c, d, }) => c === ChannelEnum.Voice && d === DirectionEnum.Outbound)),
});

export const VoiceRouteAdditionalParams: (auth: IAuth, props: IVoiceRouteFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IVoiceRouteFormOptionsProps) => ({
  ...VoiceRouteAdditionalParamsBase(auth, props),
  number: { hidden: !auth.is_superadmin, },
  provider: { hidden: !auth.is_superadmin, },
});
