import { VoiceRouteActions, } from '@Store';
import { IPreviewVoiceRoute, } from 'atlas-shared';
import { fetchVoiceRoutes, } from '@Api';

export const actionFetchVoiceRoutes = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceRoutes());
    const voice_routes = await fetchVoiceRoutes();

    dispatch(actionSetVoiceRoutes(voice_routes));
    return voice_routes;
  };
};

export const actionLoadingVoiceRoutes = () => ({
  type: VoiceRouteActions.LOADING,
  payload: [],
});

export const actionSetVoiceRoutes = (payload: Array<IPreviewVoiceRoute>) => ({
  type: VoiceRouteActions.SET_STATE,
  payload: payload,
});

export const actionAddVoiceRoute = (payload: IPreviewVoiceRoute) => ({
  type: VoiceRouteActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateVoiceRoute = (payload: IPreviewVoiceRoute) => ({
  type: VoiceRouteActions.UPDATE_STATE,
  payload: payload,
});
