import React from 'react';
import { Input, } from 'antd';
import debounce from 'lodash/debounce';
import { IFormElement, IFormElementText, } from '../atlas-form-core';

interface IProps {
  className?: string;
  default_value?: string;
  placeholder?: string;
  rows?: IFormElementText['rows'];
  onChange: (value: any) => void;
  style?: IFormElement['element_style'];
  disabled?: boolean;
}

function FormText(props: IProps) {

  const {
    placeholder,
    default_value,
    onChange,
    style,
    disabled,
    className,
    rows = 2,
  } = props;

  const debouncedChange = debounce((value) => {
    onChange(value);
  }, 250);

  return <Input.TextArea
    placeholder={placeholder}
    className={className}
    defaultValue={default_value}
    onBlur={e => onChange(e.target.value)}
    onKeyUp={e => debouncedChange((e.target as any).value)}
    style={style}
    autoSize={{ minRows: rows, maxRows: 6, }}
    disabled={disabled}
  />;

}

export default React.memo(FormText);
