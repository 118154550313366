import { IPreviewApiKey, } from 'atlas-shared';

export enum ApiKeyActions {
  SET_STATE = 'API_KEY_SET_STATE',
  ADD_STATE = 'API_KEY_ADD_STATE',
  REMOVE_STATE = 'API_KEY_REMOVE_STATE',
  GET_STATE = 'API_KEY_GET_STATE',
  UPDATE_STATE = 'API_KEY_UPDATE_STATE',
  LOADING = 'API_KEY_LOADING',
}

export interface IApiKeyLoading {
  type: ApiKeyActions.LOADING;
}

export interface IApiKeySetAction {
  type: ApiKeyActions.SET_STATE;
  payload: Array<IPreviewApiKey>;
}

export interface IApiKeyUpdateAction {
  type: ApiKeyActions.UPDATE_STATE;
  payload: IPreviewApiKey;
}

export interface IApiKeyAddAction {
  type: ApiKeyActions.ADD_STATE;
  payload: IPreviewApiKey;
}

export interface IApiKeyRemoveAction {
  type: ApiKeyActions.REMOVE_STATE;
  payload: IPreviewApiKey['id'];
}

export interface IApiKeyStore {
  loaded: boolean;
  loading: boolean;
  api_keys: Array<IPreviewApiKey>;
  dict: Record<IPreviewApiKey['id'], IPreviewApiKey>;
}

export type TApiKeyActionTypes = IApiKeySetAction | IApiKeyUpdateAction | IApiKeyAddAction | IApiKeyRemoveAction | IApiKeyLoading;
