import { IPreviewVoiceVoicemail, } from 'atlas-shared';

export enum VoiceVoicemailActions {
  SET_STATE = 'VOICE_VOICEMAIL_SET_STATE',
  ADD_STATE = 'VOICE_VOICEMAIL_ADD_STATE',
  REMOVE_STATE = 'VOICE_VOICEMAIL_REMOVE_STATE',
  GET_STATE = 'VOICE_VOICEMAIL_GET_STATE',
  UPDATE_STATE = 'VOICE_VOICEMAIL_UPDATE_STATE',
  LOADING = 'VOICE_VOICEMAIL_LOADING',
}

export interface IVoiceVoicemailLoading {
  type: VoiceVoicemailActions.LOADING;
}

export interface IVoiceVoicemailSetAction {
  type: VoiceVoicemailActions.SET_STATE;
  payload: Array<IPreviewVoiceVoicemail>;
}

export interface IVoiceVoicemailUpdateAction {
  type: VoiceVoicemailActions.UPDATE_STATE;
  payload: IPreviewVoiceVoicemail;
}

export interface IVoiceVoicemailAddAction {
  type: VoiceVoicemailActions.ADD_STATE;
  payload: IPreviewVoiceVoicemail;
}

export interface IVoiceVoicemailRemoveAction {
  type: VoiceVoicemailActions.REMOVE_STATE;
  payload: IPreviewVoiceVoicemail['id'];
}

export interface IVoiceVoicemailStore {
  loaded: boolean;
  loading: boolean;
  voice_voicemails: Array<IPreviewVoiceVoicemail>;
  dict: Record<IPreviewVoiceVoicemail['id'], IPreviewVoiceVoicemail>;
}

export type TVoiceVoicemailActionTypes = IVoiceVoicemailSetAction | IVoiceVoicemailUpdateAction | IVoiceVoicemailAddAction | IVoiceVoicemailRemoveAction | IVoiceVoicemailLoading;
