import { IPreviewStatsAlarm, } from 'atlas-shared';

export enum StatsAlarmActions {
  SET_STATE = 'STATS_ALARM_SET_STATE',
  ADD_STATE = 'STATS_ALARM_ADD_STATE',
  REMOVE_STATE = 'STATS_ALARM_REMOVE_STATE',
  GET_STATE = 'STATS_ALARM_GET_STATE',
  UPDATE_STATE = 'STATS_ALARM_UPDATE_STATE',
  LOADING = 'STATS_ALARM_LOADING',
}

export interface IStatsAlarmLoading {
  type: StatsAlarmActions.LOADING;
}

export interface IStatsAlarmSetAction {
  type: StatsAlarmActions.SET_STATE;
  payload: Array<IPreviewStatsAlarm>;
}

export interface IStatsAlarmUpdateAction {
  type: StatsAlarmActions.UPDATE_STATE;
  payload: IPreviewStatsAlarm;
}

export interface IStatsAlarmAddAction {
  type: StatsAlarmActions.ADD_STATE;
  payload: IPreviewStatsAlarm;
}

export interface IStatsAlarmRemoveAction {
  type: StatsAlarmActions.REMOVE_STATE;
  payload: IPreviewStatsAlarm['id'];
}

export interface IStatsAlarmStore {
  loaded: boolean;
  loading: boolean;
  stats_alarms: Array<IPreviewStatsAlarm>;
  dict: Record<IPreviewStatsAlarm['id'], IPreviewStatsAlarm>;
}

export type TStatsAlarmActionTypes = IStatsAlarmSetAction | IStatsAlarmUpdateAction | IStatsAlarmAddAction | IStatsAlarmRemoveAction | IStatsAlarmLoading;
