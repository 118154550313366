import { useSelector, } from 'react-redux';
import { useAuth, } from './use.auth';
import { actionFetchRecentlyViewedConversations, IReducerStore, useAppDispatch, } from '@Store';
import { useEffect, useState, } from 'react';
import { IPreviewUserStatus, ISharedAgentprofile, } from 'atlas-shared';

export const useRecentlyViewedConversations = (agentprofile_id?: ISharedAgentprofile['id'], autoload = true) => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const recently_viewed_conversations = useSelector((store: IReducerStore) => store.recently_viewed_conversations);
  const [agentprofileId, setAgentprofileId, ] = useState<IPreviewUserStatus['agentprofile_id']>(auth.user_status.agentprofile_id);

  useEffect(() => {
    autoload && !recently_viewed_conversations.loaded && !recently_viewed_conversations.loading && dispatch(actionFetchRecentlyViewedConversations(agentprofile_id));
  }, [autoload, recently_viewed_conversations.loaded, recently_viewed_conversations.loading, dispatch, agentprofile_id, ]);

  useEffect(() => {
    if (autoload && recently_viewed_conversations.loaded && !recently_viewed_conversations.loading && auth.user_status.agentprofile_id !== agentprofileId) {
      dispatch(actionFetchRecentlyViewedConversations(auth.user_status.agentprofile_id));
      setAgentprofileId(auth.user_status.agentprofile_id);
    }
  }, [autoload, auth.user_status.agentprofile_id, agentprofileId, recently_viewed_conversations.loaded, recently_viewed_conversations.loading, dispatch, ]);

  return recently_viewed_conversations;
};
