import React, { useCallback, useEffect, useState, } from 'react';
import './changelog.scss';
import { IAuth, actionDisplayChangelog, useAppDispatch, } from '@Store';
import { useDisplayChangelog, } from '@Hooks';
import { useTranslation, } from 'react-i18next';
import { Sider, } from '@Components/misc';
import { CHANGELOG_HISTORY, IChangelogHistory, } from './changelog.history';
import { hasRole, } from 'atlas-shared';
import { ChangelogHistoryVersion, } from './changelog.history.version';

interface IProps {
  auth: IAuth;
}

export const Changelog = React.memo(({ auth, }: IProps) => {

  const { t, } = useTranslation();

  const displayed = useDisplayChangelog();
  const dispatch = useAppDispatch();
  const [history, setHistory, ] = useState<Array<IChangelogHistory>>([]);

  useEffect(() => {
    if (auth?.user)
      setHistory([
        ...CHANGELOG_HISTORY.map(version => {
          return {
            ...version,
            history: version.history.filter(h => !h.roles || h.roles.some(role => hasRole(auth.user, role))),
          };
        }).filter(version => version.history.length), ]);
  }, [auth, ]);

  const toggle = useCallback(() => {
    dispatch(actionDisplayChangelog(!displayed));
  }, [displayed, dispatch, ]);

  return <Sider onClose={() => { toggle(); }} classNames={['changelog', ]}>
    <h2>{t('CHANGELOG')}</h2>
    <ul className='versions'>
      {history.map((history, index) => <ChangelogHistoryVersion expanded={index < 7} t={t} history={history} />)}
    </ul>
  </Sider>;
});
