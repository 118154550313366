import { RestRequest, } from '@Utils';
import { IPreviewVoiceAsteriskDowngrade, ISharedVoiceAsteriskDowngrade, ISharedCreateVoiceAsteriskDowngrade, ISharedPatchVoiceAsteriskDowngrade, } from 'atlas-shared';

export const fetchVoiceAsteriskDowngrades = async (): Promise<Array<IPreviewVoiceAsteriskDowngrade>> => {
  return RestRequest.get<Array<IPreviewVoiceAsteriskDowngrade>>('voice_asterisk_downgrade', { socket_id: global.socketId, });
};

export const fetchVoiceAsteriskDowngrade = async (id: IPreviewVoiceAsteriskDowngrade['id']): Promise<ISharedVoiceAsteriskDowngrade> => {
  return RestRequest.get<ISharedVoiceAsteriskDowngrade>(`voice_asterisk_downgrade/${id}`, { socket_id: global.socketId, });
};

export const createVoiceAsteriskDowngrade = (payload: ISharedCreateVoiceAsteriskDowngrade) => {
  return RestRequest.post<any, ISharedVoiceAsteriskDowngrade>('voice_asterisk_downgrade', payload, { socket_id: global.socketId, });
};

export const patchVoiceAsteriskDowngrade = (id: IPreviewVoiceAsteriskDowngrade['id'], payload: ISharedPatchVoiceAsteriskDowngrade) => {
  return RestRequest.patch<any, ISharedVoiceAsteriskDowngrade>(`voice_asterisk_downgrade/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteVoiceAsteriskDowngrade = (id: IPreviewVoiceAsteriskDowngrade['id']) => {
  return RestRequest.delete(`voice_asterisk_downgrade/${id}`, { socket_id: global.socketId, });
};

export const getVoiceAsteriskDowngradeUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_asterisk_downgrade/update/schema');
};

export const getVoiceAsteriskDowngradeCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_asterisk_downgrade/create/schema');
};

export const getVoiceAsteriskDowngradePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_asterisk_downgrade/patch/schema');
};
