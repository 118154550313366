import { VoiceTrunkActions, } from '@Store';
import { IPreviewVoiceTrunk, } from 'atlas-shared';
import { fetchVoiceTrunks, } from '@Api';

export const actionFetchVoiceTrunks = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceTrunks());
    const voice_trunks = await fetchVoiceTrunks();

    dispatch(actionSetVoiceTrunks(voice_trunks));
    return voice_trunks;
  };
};

export const actionLoadingVoiceTrunks = () => ({
  type: VoiceTrunkActions.LOADING,
  payload: [],
});

export const actionSetVoiceTrunks = (payload: Array<IPreviewVoiceTrunk>) => ({
  type: VoiceTrunkActions.SET_STATE,
  payload: payload,
});

export const actionAddVoiceTrunk = (payload: IPreviewVoiceTrunk) => ({
  type: VoiceTrunkActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateVoiceTrunk = (payload: IPreviewVoiceTrunk) => ({
  type: VoiceTrunkActions.UPDATE_STATE,
  payload: payload,
});
