import { Button, Modal, } from 'antd';
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import './audio.settings.modal.scss';
import { actionDisplayAudioSettings, actionSetDevices, IGlobalStore, useAppDispatch, } from '@Store';
import { useAuth, useDevices, useDisplayAudioSettings, } from '@Hooks';
import { FormSelect, } from 'atlas-form';
import { useTranslation, } from 'react-i18next';
import { EchoTest, } from './echo.test';
import { TwinningSettingsForm, } from '@Components/header/header.call.bar/twinning.settings.form';
import { AudioPlayer, } from '@Utils';

interface IProps {
  speakerDevices: Array<MediaDeviceInfo>;
  microphoneDevices: Array<MediaDeviceInfo>;
}

export const AudioSettingsModal = ({ speakerDevices, microphoneDevices, }: IProps) => {
  const dispatch = useAppDispatch();
  const visible = useDisplayAudioSettings();
  // @ts-ignore
  const hasSpeakerSupport = useMemo(() => !!document.createElement('audio').setSinkId);
  const [echoTest, setEchoTest, ] = useState<boolean>(false);
  const [echoTestPassed, setEchoTestPassed, ] = useState<boolean>(false);
  const { t, } = useTranslation();
  const auth = useAuth();
  const [externalVoiceRingtone, setExternalVoiceRingtone, ] = useState<string>();
  const [internalVoiceRingtone, setInternalVoiceRingtone, ] = useState<string>();
  const [notificationRingtone, setNotificationRingtone, ] = useState<string>();

  const readLocalStorageVoiceRingtone = useCallback(() => {
    setExternalVoiceRingtone(localStorage.getItem('atlas-voice-ringtone') || 'ringing1.ogg');
    setInternalVoiceRingtone(localStorage.getItem('atlas-internal-voice-ringtone') || 'ringing5.ogg');
  }, []);

  const readLocalStorageNotificationRingtone = useCallback(() => {
    setNotificationRingtone(localStorage.getItem('atlas-notification-ringtone') || 'notification1.wav');
  }, []);

  const devices = useDevices();

  const setDevices = useCallback((partial: Partial<IGlobalStore['devices']>) => {
    // @ts-ignore
    dispatch(actionSetDevices({
      ...partial,
    }));
  }, []);

  useEffect(() => {
    setDevices(JSON.parse(localStorage.getItem('devices') || '{}'));
  }, []);

  useEffect(() => {
    readLocalStorageVoiceRingtone();
    readLocalStorageNotificationRingtone();
  }, []);

  return visible ? <Modal
    open={visible}
    width={580}
    onCancel={() => {
      dispatch(actionDisplayAudioSettings(false));
      setEchoTest(false);
    }}
    className={'audio-settings-modal'}
  >
    <h2>{t('AUDIO_SETTINGS')}</h2>

    <div className='settings-table'>
      <div>
        <div className='label'>{t('ECHO_TEST')}</div>
        <div className='select'>
          <Button type={echoTest ? 'dashed' : 'primary'} size='small' className='echo-test-btn' onClick={() => {
            setEchoTest(!echoTest);
            setEchoTestPassed(false);
          }}>{t(echoTest ? 'STOP_TEST' : 'START_TEST')}</Button>
          {echoTest && <EchoTest onDone={(passed) => {
            setEchoTest(false);
            setEchoTestPassed(passed);
          }} />}
          {echoTestPassed && <div className='echo-test-passed'>{t('ECHO_TEST_PASSED')}</div>}
        </div>
      </div>
      {hasSpeakerSupport && <>
        <div>
          <div className='label'>{t('RINGTONE_SPEAKER')}</div>
          <div className='select'>
            <FormSelect
              value={devices.ringtone && speakerDevices.some(device => device.deviceId === devices.ringtone) ? devices.ringtone : null}
              options={speakerDevices.map(device => ({ key: device.deviceId, title: device.label, }))}
              onChange={selected => setDevices({ ringtone: selected, })}
              placeholder={t('SELECT_RINGTONE_SPEAKER')}
            />
          </div>
        </div>
        <div>
          <div className='label'>{t('VOICE_SPEAKER')}</div>
          <div className='select'>
            <FormSelect
              value={devices.microphone && speakerDevices.some(device => device.deviceId === devices.speaker) ? devices.speaker : null}
              options={speakerDevices.map(device => ({ key: device.deviceId, title: device.label, }))}
              onChange={selected => setDevices({ speaker: selected, })}
              placeholder={t('SELECT_VOICE_SPEAKER')}
            />
          </div>
        </div>
        <div>
          <div className='label'>{t('EXTERNAL_VOICE_RINGTONE')}</div>
          <div className='select'>
            <FormSelect
              value={externalVoiceRingtone}
              options={new Array(7).fill({}).map((_, i) => ({ key: `ringing${i + 1}.ogg`, title: t(`Ringtone ${i + 1}`), }))}
              onChange={selected => {
                localStorage.setItem('atlas-voice-ringtone', selected);
                readLocalStorageVoiceRingtone();
              }}
              placeholder={t('SELECT_VOICE_RINGTONE')}
            />
            <AudioPlayer t={t} src={`/ringtones/${externalVoiceRingtone}`} size='s' autoPlay={false} />
          </div>
        </div>
        <div>
          <div className='label'>{t('INTERNAL_VOICE_RINGTONE')}</div>
          <div className='select'>
            <FormSelect
              value={internalVoiceRingtone}
              options={new Array(7).fill({}).map((_, i) => ({ key: `ringing${i + 1}.ogg`, title: t(`Ringtone ${i + 1}`), }))}
              onChange={selected => {
                localStorage.setItem('atlas-internal-voice-ringtone', selected);
                readLocalStorageVoiceRingtone();
              }}
              placeholder={t('SELECT_VOICE_RINGTONE')}
            />
            <AudioPlayer t={t} src={`/ringtones/${internalVoiceRingtone}`} size='s' autoPlay={false} />
          </div>
        </div>
        <div>
          <div className='label'>{t('NOTIFICATION_RINGTONE')}</div>
          <div className='select'>
            <FormSelect
              value={localStorage.getItem('atlas-notification-ringtone') || 'notification1.wav'}
              options={[{ key: 'notification1.wav', title: 'Notification 1', }, ]}
              onChange={selected => {
                localStorage.setItem('atlas-notification-ringtone', selected);
                readLocalStorageNotificationRingtone();
              }}
              placeholder={t('SELECT_NOTIFICATION_RINGTONE')}
            />
            <AudioPlayer t={t} src={`/ringtones/${notificationRingtone}`} size='s' autoPlay={false} />
          </div>
        </div>
      </>}
      <div>
        <div className='label'>{t('SELECT_MICROPHONE')}</div>
        <div className='select'>
          <FormSelect
            value={devices.microphone && microphoneDevices.some(device => device.deviceId === devices.microphone) ? devices.microphone : null}
            options={microphoneDevices.map(device => ({ key: device.deviceId, title: device.label, }))}
            onChange={selected => setDevices({ microphone: selected, })}
            placeholder={t('SELECT_MICROPHONE')}
          />
        </div>
      </div>
      <span className='hr'></span>
    </div>

    <TwinningSettingsForm auth={auth} t={t} />

  </Modal> : <></>;
};
