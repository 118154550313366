import React from 'react';
import { deleteVoiceAsterisk, } from '@Api';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { CrudEnum, IPreviewVoiceAsterisk, PermissionEnum, EnumTranslateTitle, TVoiceAsteriskSortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnBooleanRender, tableColumnStringSorter, timeSince, } from '@Utils';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { ColumnsType, } from 'antd/lib/table/interface';
import { listAuditButton, listCloneButton, listDeleteButton, listEditButton, GetColumnSearchLikeProps, GetColumnSearchEqualProps, } from '../components';
import { IWithListProps, } from '@Hocs/with.list';

export const VoiceAsteriskListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceAsterisk) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceAsterisk, TVoiceAsteriskSortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceAsterisk, TVoiceAsteriskSortableListCol>['sorter']): ColumnsType<IPreviewVoiceAsterisk> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewVoiceAsterisk, b: IPreviewVoiceAsterisk) => a.id - b.id,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewVoiceAsterisk, b: IPreviewVoiceAsterisk) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      filteredValue: filters.title || null,
      ...GetColumnSearchLikeProps(t, 'title', t('TITLE')),
      className: 'table-cell-title',
    },
    {
      title: t('STATUS'),
      dataIndex: 'status',
      key: 'status',
      render: (status: IPreviewVoiceAsterisk['status'], voice_asterisk: IPreviewVoiceAsterisk) => EnumTranslateTitle('ASTERISK_STATUS', status, t),
      className: 'table-cell-status',
    },
    {
      title: t('__IS_DOWNGRADED'),
      dataIndex: '__is_downgraded',
      key: '__is_downgraded',
      render: (__is_downgraded: IPreviewVoiceAsterisk['__is_downgraded'], voice_asterisk: IPreviewVoiceAsterisk) => tableColumnBooleanRender(t, __is_downgraded),
      sorter: (a: IPreviewVoiceAsterisk, b: IPreviewVoiceAsterisk) => (a.__is_downgraded ? 1 : 0) > (b.__is_downgraded ? 1 : 0) ? 1 : -1,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === '__is_downgraded')?.order || null,
      className: 'table-cell-__is_downgraded',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewVoiceAsterisk['created_at'], voice_asterisk: IPreviewVoiceAsterisk) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewVoiceAsterisk, b: IPreviewVoiceAsterisk) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    showDeleted && { 
      title: t('DELETED'), 
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      className: 'table-cell-deleted_at',
      render: (deleted_at: IPreviewVoiceAsterisk['deleted_at']) => deleted_at ? timeSince(t, new Date(deleted_at), true) : '',
      sorter: (a: IPreviewVoiceAsterisk, b: IPreviewVoiceAsterisk) => tableColumnDatetimeSorter(a.deleted_at, b.deleted_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'deleted_at')?.order || null,
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewVoiceAsterisk) => (
        <div className={'table-operation-wrp'}>
          {!row.deleted_at && buttons.map(button => button(row))}
          {abilities.can(null, CrudEnum.Insert, PermissionEnum.VoiceAsterisk, row) && listCloneButton(t, navigate, 'voice_asterisk', row)}
          {abilities.can(null, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'voice_asterisk', row)}
          {!row.deleted_at && abilities.can(null, CrudEnum.Update, PermissionEnum.VoiceAsterisk, row) && listEditButton(t, navigate, 'voice_asterisk', row)}
          {!row.deleted_at && abilities.can(null, CrudEnum.Delete, PermissionEnum.VoiceAsterisk, row) && listDeleteButton(t, row, deleteVoiceAsterisk)}
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<IPreviewVoiceAsterisk>;

};
