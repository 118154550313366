import { IPreviewMailAccount, } from 'atlas-shared';
import { IMailAccountStore, MailAccountActions, TMailAccountActionTypes, } from '@Store';

export const MailAccountReducer = (state: IMailAccountStore = {
  loaded: false,
  loading: false,
  mail_accounts: [],
  dict: {},
}, action: TMailAccountActionTypes): IMailAccountStore => {
  switch (action.type) {
  case MailAccountActions.LOADING:
    return { ...state, loading: true, };
  case MailAccountActions.SET_STATE:
    const dict: IMailAccountStore['dict'] = {};

    action.payload.forEach((mail_account: IPreviewMailAccount) => dict[mail_account.id] = mail_account);

    return { ...state, loaded: true, loading: false, mail_accounts: action.payload, dict, };
  case MailAccountActions.ADD_STATE:
    return { ...state, mail_accounts: [...state.mail_accounts, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case MailAccountActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, mail_accounts: state.mail_accounts.filter((item: IPreviewMailAccount) => item.id !== action.payload), dict, };
  }
  case MailAccountActions.UPDATE_STATE:
    const mail_accounts = [...state.mail_accounts, ];
    const index = state.mail_accounts.findIndex((mail_account: IPreviewMailAccount) => mail_account.id === action.payload.id);

    if (!~index)
      mail_accounts.push(action.payload);
    else
      mail_accounts[index] = action.payload;

    return { ...state, mail_accounts, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
