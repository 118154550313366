import React from 'react';
import { getContactFullName, preventDefault, SearchSections, translatePhoneNumber, } from '@Utils';
import { Avatar, IconText, } from '@Components';
import { NavigateFunction, } from 'react-router';
import { IElasticPreviewContact, IElasticSearchResultItem, } from 'atlas-shared';
import { MailIcon, PhoneIcon, } from '@Assets';
import { TFunction, } from 'i18next';

interface IProps {
  t: TFunction;
  item: IElasticSearchResultItem<IElasticPreviewContact>;
  navigate: NavigateFunction;
}

export const HeaderSearchResultsContact = React.memo(({ item, t, navigate, }: IProps) => {
  const { item: contact, } = item;

  return <li key={contact.id} onClick={preventDefault(() => {
    navigate(`/dashboard/search?type=${SearchSections[0]}&c=${contact.id}`);
  })}>
    <div className={'channel-wrp'} >
      <Avatar size={28} />
    </div>
    <div className={'result-text'}>
      <div className='result-row'>
        <strong>{getContactFullName(contact, t)}</strong>
      </div>
      <div className={'result-row'}>
        <div style={{ display: 'flex', opacity: .5, }}>
          <span className='emails'>{contact.emails.length > 0 && <IconText icon={MailIcon} text={contact.emails.join(', ')} style={{ marginRight: 5, }} />}</span>
          <span className='phones'>{contact.phones.length > 0 && <IconText icon={PhoneIcon} text={contact.phones.map(phone => translatePhoneNumber(phone.split(' ')[0])?.formatInternational()).join(', ')} />}</span>
        </div>
        <strong>#{contact.id}</strong>
      </div>
    </div>
  </li>;
});
