import React from 'react';
import { ISvgIconProps, } from './interface';
import { SvgIcon, } from './SvgIcon';

export const AscIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <polyline points="19 12 12 19 5 12"></polyline>
  </SvgIcon>;
};
