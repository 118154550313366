import { journeyAction, } from './base';

import { JourneyActionVoiceQueue as JourneyActionVoiceQueueBase, } from 'atlas-shared/dist';

export const JourneyActionVoiceQueue = journeyAction({
  ...JourneyActionVoiceQueueBase,
  icon: 'Queue',
  additionalParams: {
    properties_timeout: {
      postFixText: 'SECONDS',
    },
  },
});
