import { RestRequest, } from '@Utils';
import { IPreviewPhonenumberMonthlyCostParent, ISharedPhonenumberMonthlyCostParent, ISharedCreatePhonenumberMonthlyCostParent, ISharedPatchPhonenumberMonthlyCostParent, } from 'atlas-shared';

export const fetchPhonenumberMonthlyCostParents = async (): Promise<Array<IPreviewPhonenumberMonthlyCostParent>> => {
  return RestRequest.get<Array<IPreviewPhonenumberMonthlyCostParent>>('phonenumber_monthly_cost_parent', { socket_id: global.socketId, });
};

export const fetchPhonenumberMonthlyCostParent = async (id: IPreviewPhonenumberMonthlyCostParent['id']): Promise<ISharedPhonenumberMonthlyCostParent> => {
  return RestRequest.get<ISharedPhonenumberMonthlyCostParent>(`phonenumber_monthly_cost_parent/${id}`, { socket_id: global.socketId, });
};

export const createPhonenumberMonthlyCostParent = (payload: ISharedCreatePhonenumberMonthlyCostParent) => {
  return RestRequest.post<any, ISharedPhonenumberMonthlyCostParent>('phonenumber_monthly_cost_parent', payload, { socket_id: global.socketId, });
};

export const patchPhonenumberMonthlyCostParent = (id: IPreviewPhonenumberMonthlyCostParent['id'], payload: ISharedPatchPhonenumberMonthlyCostParent) => {
  return RestRequest.patch<any, ISharedPhonenumberMonthlyCostParent>(`phonenumber_monthly_cost_parent/${id}`, payload, { socket_id: global.socketId, });
};

export const deletePhonenumberMonthlyCostParent = (id: IPreviewPhonenumberMonthlyCostParent['id']) => {
  return RestRequest.delete(`phonenumber_monthly_cost_parent/${id}`, { socket_id: global.socketId, });
};

export const getPhonenumberMonthlyCostParentUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_monthly_cost_parent/update/schema');
};

export const getPhonenumberMonthlyCostParentCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_monthly_cost_parent/create/schema');
};

export const getPhonenumberMonthlyCostParentPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_monthly_cost_parent/patch/schema');
};
