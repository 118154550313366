import { TFunction, } from 'i18next';
import {
  ConversationPriorityEnum,
  ConversationPriorityOptions,
  ConversationStatusOptions,
  IPreviewConversation, 
  ISharedPatchConversation,
  ConversationStatusEnum,
} from 'atlas-shared';
import React, { Dispatch, SetStateAction, useCallback, useState, } from 'react';
import { FormSelect, } from 'atlas-form';
import { Button, } from '@Components';
import { patchConversation, } from '@Api';
import { AlertError, InfoModal, } from '@Utils';
import { actionSetResetConversationList, useAppDispatch, } from '@Store';

interface IProps {
  t: TFunction; 
  shortened: boolean; 
  selected: Array<IPreviewConversation>;
  setSelected: Dispatch<SetStateAction<Array<IPreviewConversation>>>;
}

export const ConversationListSelectedBar = ({ t, shortened, selected, setSelected, }: IProps) => {
  const [status, setStatus, ] = useState<ConversationStatusEnum>();
  const [priority, setPriority, ] = useState<ConversationPriorityEnum>();
  const [updating, setUpdating, ] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const apply = useCallback((reset: boolean = false) => {
    setUpdating(true);
    const body: ISharedPatchConversation = {};

    if (status)
      body.status = status;

    if (priority)
      body.priority = priority;

    Promise.allSettled(selected.map(({ id, organization_id, }) => patchConversation(organization_id, id, body))).then((results) => {

      if (reset) {
        dispatch(actionSetResetConversationList());
        setSelected([]);
      }

      setUpdating(false);
      const errors: Array<{id: number; error: string}> = [];

      results.forEach((result, i) => {
        if (result.status === 'rejected')
          errors.push({ id: selected[i].id, error: result.reason.replace('ValidationError: ', ''), });
      });

      if (errors.length)
        AlertError(t, {
          title: t('ITEMS_FAILED_TO_UPDATE', { count: errors.length, }),
          content: <>
            {errors.map(error => <div>#{error.id}: {error.error}</div>)}
          </>,
        });
      else
        InfoModal(t, {
          content: t('CHANGES_APPLIED_TO_ITEMS', { count: selected.length, }),
        });
    });
  }, [selected, priority, updating, status, ]);

  return selected.length > 0 ? <div className={`inbox-selected${shortened ? ' shortened' : ''}`}>
    <div className='title'>{t('WITH_SELECTED_DO')}</div>
    <div className='options'>
      <div className='option'>
        <div className='option-title'>{t('STATUS')}: </div>
        <div className='option-action'><FormSelect options={ConversationStatusOptions(t)} onChange={(status) => setStatus(status)} allowClear={true} /></div>
      </div>
      <div className='option'>
        <div className='option-title'>{t('PRIORITY')}: </div>
        <div className='option-action'><FormSelect options={ConversationPriorityOptions(t)} onChange={(priority) => setPriority(priority)} allowClear={true} /></div>
      </div>
    </div>
    {(status || priority) && <div className='buttons'>
      <Button type='default' disabled={updating} onClick={() => apply()}>{t('APPLY_CHANGES_TO_ITEMS', { count: selected.length, })}</Button>
      <Button type='primary' disabled={updating} onClick={() => apply(true)}>{t('APPLY_AND_RESET')}</Button>
    </div>}
  </div> : <></>;
};
