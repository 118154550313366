import { queryParam, } from '@Utils/url';

export const onFormSaved = (entity: string): string => {
  const params: Array<{key: string; value: string}> = [];

  ['p', 's', 'f', 'ff', ].forEach(key => {
    const value = queryParam(key);

    if (value)
      params.push({ key, value, });
  });

  return `/admin/${entity}${params.length ? `${params.length ? `?${params.map(({ key, value, }) => `${key}=${value}`).join('&')}` : ''}` : ''}`;
};
