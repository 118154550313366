import { journeyAction, } from './base';

import { JourneyActionOpenBrowserTab as JourneyActionOpenBrowserTabBase, } from 'atlas-shared';
import { FormElementTypes, } from 'atlas-form';

export const JourneyActionOpenBrowserTab = journeyAction({
  ...JourneyActionOpenBrowserTabBase,
  icon: 'Browser',
  additionalParams: {
    properties_url: { ui_type: FormElementTypes.INPUT, },
  },
});
