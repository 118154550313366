import { StatsReportPreviewWidgetActions, } from '@Store';
import { IPreviewStatsReportPreviewWidget, } from 'atlas-shared';
import { fetchStatsReportPreviewWidgets, } from '@Api';

export const actionFetchStatsReportPreviewWidgets = () => {
  return async (dispatch) => {
    dispatch(actionLoadingStatsReportPreviewWidgets());
    const stats_report_preview_widgets = await fetchStatsReportPreviewWidgets();

    dispatch(actionSetStatsReportPreviewWidgets(stats_report_preview_widgets));
    return stats_report_preview_widgets;
  };
};

export const actionLoadingStatsReportPreviewWidgets = () => ({
  type: StatsReportPreviewWidgetActions.LOADING,
  payload: [],
});

export const actionSetStatsReportPreviewWidgets = (payload: Array<IPreviewStatsReportPreviewWidget>) => ({
  type: StatsReportPreviewWidgetActions.SET_STATE,
  payload: payload,
});

export const actionAddStatsReportPreviewWidget = (payload: IPreviewStatsReportPreviewWidget) => ({
  type: StatsReportPreviewWidgetActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateStatsReportPreviewWidget = (payload: IPreviewStatsReportPreviewWidget) => ({
  type: StatsReportPreviewWidgetActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveStatsReportPreviewWidget = (id: IPreviewStatsReportPreviewWidget['id']) => ({
  type: StatsReportPreviewWidgetActions.REMOVE_STATE,
  payload: id,
});
