import { RestRequest, } from '@Utils/rest';
import { IPreviewNotificationUser, ISharedAgentprofile, ISharedNotificationUser, Nullable, } from 'atlas-shared';
import { NotificationActionEnum, } from 'atlas-shared/src/utilities/enums/index';

export * from './abstract/notification_user.api.abstract';

export const notificationUserAction = (organization_id: ISharedNotificationUser['organization_id'], id: ISharedNotificationUser['id'], payload: { action: NotificationActionEnum }) => {
  return RestRequest.put<any, ISharedNotificationUser>(`notification_user/p/${organization_id}/${id}/action`, payload);
};

export const fetchNotificationUsers = (agentprofile_id?: Nullable<ISharedAgentprofile['id']>): Promise<Array<IPreviewNotificationUser>> => {
  return RestRequest.get<Array<IPreviewNotificationUser>>('notification_user', { socket_id: global.socketId, agentprofile_id: agentprofile_id || undefined, });
};
