import React, { useEffect, useState, } from 'react';
import { Nullable, } from 'atlas-shared';
import moment, { Moment, } from 'moment';

export interface ITimerProps {
  base: Nullable<string | number>;
  format?: string;
  default_value?: string;
  ignoreZeros?: boolean;
  color?: string | false;
}

const toHHMMSS = (sec_num: number, ignoreZeros: boolean = false, color: string = 'var(--COLOR-000533)') => {

  let hours = Math.floor(sec_num / 3600);

  sec_num -= hours * 3600;
  let minutes = Math.floor(sec_num / 60);

  sec_num -= minutes * 60;
  let seconds = sec_num;

  const hours_str = ignoreZeros && !hours ? '' : `${hours < 10 ? '0' : ''}${hours}:`;
  const minutes_str = `${minutes < 10 ? '0' : ''}${minutes}:`;
  const seconds_str = `${seconds < 10 ? '0' : ''}${seconds}`;

  return `${hours_str}${minutes_str}${seconds_str}`;
};

export const Timer = ({ base, default_value = '-', ignoreZeros = false, color = 'var(--COLOR-000533)', }: ITimerProps) => {

  const [groundZero, setGroundZero, ] = useState<Nullable<Moment>>(null);
  const [diff, setDiff, ] = useState<number>(0);

  useEffect(() => {
    setGroundZero(base ? moment(base) : null);
  }, [base, ]);

  useEffect(() => {

    let interval: any;

    if (groundZero)
      interval = setInterval(() => {
        setDiff(moment().unix() - groundZero.unix());
      }, 1000);
    
    return () => clearInterval(interval);

  }, [groundZero, ]);

  return <span className={'text'} style={{ color: color || undefined, opacity: 0.9, }}>{!groundZero ? default_value : toHHMMSS(diff, ignoreZeros)}</span>;
};
