import { RestRequest, } from '@Utils';
import { IPreviewVoiceShortcut, ISharedVoiceShortcut, ISharedCreateVoiceShortcut, ISharedPatchVoiceShortcut, } from 'atlas-shared';

export const fetchVoiceShortcuts = async (): Promise<Array<IPreviewVoiceShortcut>> => {
  return RestRequest.get<Array<IPreviewVoiceShortcut>>('voice_shortcut', { socket_id: global.socketId, });
};

export const fetchVoiceShortcut = async (id: IPreviewVoiceShortcut['id']): Promise<ISharedVoiceShortcut> => {
  return RestRequest.get<ISharedVoiceShortcut>(`voice_shortcut/${id}`, { socket_id: global.socketId, });
};

export const createVoiceShortcut = (payload: ISharedCreateVoiceShortcut) => {
  return RestRequest.post<any, ISharedVoiceShortcut>('voice_shortcut', payload, { socket_id: global.socketId, });
};

export const patchVoiceShortcut = (id: IPreviewVoiceShortcut['id'], payload: ISharedPatchVoiceShortcut) => {
  return RestRequest.patch<any, ISharedVoiceShortcut>(`voice_shortcut/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteVoiceShortcut = (id: IPreviewVoiceShortcut['id']) => {
  return RestRequest.delete(`voice_shortcut/${id}`, { socket_id: global.socketId, });
};

export const getVoiceShortcutUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_shortcut/update/schema');
};

export const getVoiceShortcutCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_shortcut/create/schema');
};

export const getVoiceShortcutPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_shortcut/patch/schema');
};
