import { NavigateFunction, } from 'react-router';
import {
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { ISharedCreateSuperadminProfile, ISharedUpdateSuperadminProfile, ISharedSuperadminProfile, IPreviewOrganization, EnumOptions, SuperadminProfilePermissionIdEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { entitiesToOptions, onFormSaved, } from '@Utils';

export interface ISuperadminProfileFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const SuperadminProfileFormOptions = (superadmin_profile: ISharedCreateSuperadminProfile | ISharedSuperadminProfile, props: ISuperadminProfileFormOptionsProps, t: TFunction): IFormOptions => ({
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  permissions_id: EnumOptions('SUPERADMIN_PROFILE_PERMISSION_ID', SuperadminProfilePermissionIdEnum, t),
});

export const SuperadminProfileComponents: IForm['components'] = {};
export const SuperadminProfileAdditionalParams: (auth: IAuth, props: ISuperadminProfileFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ISuperadminProfileFormOptionsProps) => ({
});

export const SuperadminProfileEditForm = (superadmin_profile: ISharedSuperadminProfile, props: ISuperadminProfileFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `superadmin_profile_edit_${superadmin_profile.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'permissions', },
  ],
});

export const SuperadminProfileAddForm = (superadmin_profile: ISharedCreateSuperadminProfile, props: ISuperadminProfileFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'superadmin_profile_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'permissions', },
  ],
});

export const SuperadminProfileAddInitialValues: ISharedCreateSuperadminProfile = {} as ISharedCreateSuperadminProfile;

export const SuperadminProfileEditInitialValues = (superadmin_profile: ISharedSuperadminProfile): ISharedUpdateSuperadminProfile => ({
  title: superadmin_profile.title,
  permissions: superadmin_profile.permissions,
});

export const onSuperadminProfileFormSaved = (t: TFunction, action: 'edit' | 'add', superadmin_profile: ISharedSuperadminProfile, navigate: NavigateFunction): void => {
  navigate(onFormSaved('superadmin_profiles'));
};
