import { SipUser, ISipDelegate, ISipOptions, } from 'SIP';
import { IPreviewAgentprofile, IPreviewVoiceAsterisk, ISharedUser, Undefinable, Nullable, Sleep, } from 'atlas-shared';
import { Invitation, RegistererState, Session, URI, } from 'sip.js';
import { actionAddSipUser, actionRemoveSipUser, actionUpdateSipUser, } from '@Store';

export default class SipUserWrapper {

  private sipUser: SipUser;

  private readonly agentDelegate: ISipDelegate;

  private readonly agentOption: ISipOptions;

  private audioElement: HTMLAudioElement;

  constructor(
    private user: ISharedUser,
    public readonly agentprofile_id: Undefinable<IPreviewAgentprofile['id']>,
    private dispatch,
    public readonly voice_asterisk: IPreviewVoiceAsterisk,
    public microphone: Nullable<string>
  ) {
    this.agentDelegate = {
      onCallReceived: (invitation) => {
        this.setSession(invitation);
      },
      onCallAnswered: (session) => {
        this.setSession(session);
      },
      onCallHangup: (session) => {
        this.setSession(session);
      },
      onCallCreated: (session: Session | Invitation) => {
        this.setSession(session);
      },
      onCallHold: (session: Session, held: boolean) => {
        this.setSession(session);
      },
      onRegisterStateChange: (state: RegistererState | undefined) => {
        dispatch(actionUpdateSipUser({
          voice_asterisk: this.voice_asterisk,
          state,
        }));
      },
    };

    const audioElement = document.getElementById('remoteAudio');

    if (!(audioElement instanceof HTMLAudioElement))
      throw new Error(`Element "${audioElement}" not found or not an audio element.`);

    this.audioElement = audioElement;

    const __voice_asterisk = voice_asterisk.__is_downgraded && voice_asterisk.voice_asterisk_downgrade ? voice_asterisk.voice_asterisk_downgrade : voice_asterisk;
    const sip_username = `${this.user.id}-${this.agentprofile_id || 0}`;
    const url = `ws${__voice_asterisk.tls ? 's' : ''}://${__voice_asterisk.host}:${__voice_asterisk.port}/ws`;

    this.agentOption = {
      delegate: this.agentDelegate,
      media: {
        remote: {
          audio: audioElement,
        },
        constraints: {
          // @ts-ignore
          audio: this.microphone ? {
            deviceId: this.microphone,
            noiseSuppression: { exact: false, },
            autoGainControl: { exact: false, },
            echoCancellation: { exact: false, },
          } : true,
        },
      },
      userAgentOptions: {
        logLevel: 'warn',
        displayName: 'Atlas SIP.js',
        authorizationUsername: sip_username,
        authorizationHa1: this.user.md5_secret as string,
        uri: new URI('sip', sip_username, __voice_asterisk.host),
        sessionDescriptionHandlerFactoryOptions: {
          iceGatheringTimeout: 750,
          peerConnectionConfiguration: {
            iceServers: [
              { 'urls': ['stun:stun-eu.connectel.io:443', ], },
              { 'urls': 'turn:turn-eu.connectel.io:443?transport=UDP', 'username': 'turnuser', 'credential': 'vqG77PH4Ye54mqkS41jerHn5GC8VrXK8JAP4', },
              { 'urls': 'turn:turn-eu.connectel.io:443?transport=TCP', 'username': 'turnuser', 'credential': 'vqG77PH4Ye54mqkS41jerHn5GC8VrXK8JAP4', },
            ],
          },
        },
      },
    };

    this.sipUser = new SipUser(url, this.agentOption);
  }

  async connect() {
    try {
      this.dispatch(actionAddSipUser({
        agentprofile_id: this.agentprofile_id,
        voice_asterisk: this.voice_asterisk,
      }));
      await this.sipUser.connect();
      await this.sipUser.register();
    } catch (e: any) {
      console.error(`Error while connecting to asterisk ${this.voice_asterisk.id}: ${e.toString()}`);
    }
    return this;
  }

  async disconnect() {
    this.dispatch(actionRemoveSipUser({
      voice_asterisk: this.voice_asterisk,
    }));
    await Sleep(.5);
    return this.sipUser.disconnect();
  }

  getSipUser() {
    return this.sipUser;
  }

  private setSession(updated_session: Session | Invitation) {
    const sessions = {};
    const updated_session_key = this.sipUser.getKey(updated_session);
    const emergency = this.sipUser.isEmergencyNumber(updated_session, this.voice_asterisk);

    Object.keys(this.sipUser.sessions).forEach((key) => {
      sessions[key] = {
        key,
        last_update: key === updated_session_key ? Date.now() : 0,
        emergency,
      };
    });

    this.dispatch(actionUpdateSipUser({
      voice_asterisk: this.voice_asterisk,
      sessions,
    }));
  }

  setMicrophone(deviceId: Nullable<string>) {
    this.sipUser.setMicrophone(deviceId);
  }

  setMicrophoneMute(mute: boolean) {
    this.sipUser.setMicrophoneMute(mute);
  }

  hangupByKey(key: string) {
    const session = this.sipUser.sessions[key];

    if (session)
      this.sipUser.hangup(session);
  }
}
