import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchDispositions, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useDispositions = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const dispositions = useSelector((store: IReducerStore) => store.dispositions);

  useEffect(() => {
    if (!initiated && autoload && !dispositions.loaded && !dispositions.loading) {
      initiated = true;
      dispatch(actionFetchDispositions());
    }
  }, [autoload, dispositions.loaded, dispositions.loading, dispatch, ]);

  return dispositions;
};
