import React, { useMemo, useState, } from 'react';
import { Badge, Popover, } from 'antd';
import './tab.header.scss';
import {
  CallAddIcon,
  ConversationListIcon,
  ExternalLinkIcon,
  MinimizeIcon,
  XIcon,
} from '@Assets/icons';
import { hasVoiceLicense, IPreviewTab, ISharedFullConversation, TabSizeEnum, } from 'atlas-shared';
import { IAuth, } from '@Store';
import { useTranslation, } from 'react-i18next';
import { VoiceSuggestions, Icon, ChannelIcon, Counter, } from '@Components';
import { preventDefault, } from '@Utils';

interface IProps {
  tab: IPreviewTab;
  auth: IAuth;
  conversation?: ISharedFullConversation;
  expanded?: boolean;
  infoVisible?: boolean;
  onClose: () => Promise<IPreviewTab>;
  onMaximize?: () => Promise<IPreviewTab>;
  onMinimize?: () => Promise<IPreviewTab>;
  onMini?: () => Promise<IPreviewTab>;
  onMore?: () => void;
  onClick?: () => Promise<IPreviewTab>;
}
export const TabHeader = React.memo(({ conversation, onClose, onMaximize, onMini, onMinimize, onMore, onClick, expanded, auth, infoVisible, tab, }: IProps) => {
  const { t, } = useTranslation();
  const [callStartTime, ] = useState<number>(0);
  const voiceLicense = useMemo(() => hasVoiceLicense(auth.user), [auth.user, ]);
  const unread = useMemo(() => conversation ? conversation.unread - conversation.read : 0, [conversation?.read, conversation?.unread, ]);

  return (
    <div className={'tab-header ' + (expanded && 'expanded')}>
      <div className={'title-container'}>
        {
          conversation?.main_channel && <Badge size='small' style={{ marginTop: 6, marginRight: 3, fontSize: 10, }} count={unread}><ChannelIcon channel={{ c: conversation.main_channel, d: conversation.direction, }} size={10} padding={4}/></Badge>
        }
        <span className={'text'} onClick={onClick}>
          <span>#{conversation?.id}</span>
          <span style={{ opacity: .75, paddingLeft: 5, flexShrink: 1, }}>{conversation?.title}</span>
        </span>
      </div>
      {callStartTime ? <Counter since={callStartTime} /> : undefined}
      <div className={'tab-header-icons'}>
        {auth.user.main && voiceLicense && <Popover
          trigger='click'
          placement='topRight'
          content={<><VoiceSuggestions conversation={conversation} /></>}
        >
          <Icon
            icon={CallAddIcon}
            className='add-call'
            iconProps={{ size: 12, }}
            tooltip={{ title: t('ADD_CALL_TO_CONVERSATION'), }}
            onClick={preventDefault()}
          />
        </Popover>}
        {
          onMore && tab.size === TabSizeEnum.Mini && <Icon icon={ConversationListIcon} iconProps={{ size: 14, }} onClick={preventDefault(onMore)} className={infoVisible ? 'active' : ''}/>
        }
        {
          onMaximize && <Icon icon={ExternalLinkIcon} iconProps={{ size: 14, }} onClick={preventDefault(onMaximize)}/>
        }
        {
          onMinimize && <Icon icon={MinimizeIcon} iconProps={{ size: 14, }} onClick={preventDefault(onMinimize)}/>
        }
        {
          onMini && <Icon icon={MinimizeIcon} iconProps={{ size: 14, }} onClick={preventDefault(onMini)}/>
        }
        <Icon icon={XIcon} iconProps={{ size: 14, }} onClick={preventDefault(onClose)}/>
      </div>
    </div>
  );
});
