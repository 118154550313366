import { useNavigate, } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';
import { ConversationListRow, ScrollView, SearchNoResult, } from '@Components';
import React from 'react';
import { IPreviewContact, IPreviewConversation, ISharedConversation, SearchSectionEnum, } from 'atlas-shared';
import { useAuth, } from '@Hooks';
import { ConversationListLeftSkeleton, } from '@Components/conversation/conversation.list/conversation.list.left.skeleton';

interface IProps {
  shortened: boolean;
  conversations: Array<ISharedConversation>;
  search?: string; contact_id?:
  IPreviewContact['id'];
  current: number;
  onScrollEnd: () => void;
  isLoading: boolean;
}

export const SearchLayoutLeft = React.memo((props: IProps) => {
  const auth = useAuth();
  const { shortened, conversations, search, contact_id, current, isLoading, } = props;
  const navigate = useNavigate();
  const { t, } = useTranslation();

  return (
    <ScrollView
      className={'search-left'}
      onScrollEnd={props.onScrollEnd}
    >
      {!conversations.length && <SearchNoResult
        t={t}
        keyword={search}
        contact_id={contact_id}
        section={SearchSectionEnum.Conversations}
      />}
      {
        conversations && conversations.map((conversation: IPreviewConversation) => (
          <>
            <ConversationListRow
              auth={auth}
              t={t}
              onClick={() => navigate(`/dashboard/search/${conversation.organization_id}/${conversation.id}${window.location.search}`)}
              shortened={shortened}
              key={conversation.id}
              conversation={conversation}
              selected={current === conversation.id}
            />
          </>
        ))
      }
      {isLoading && <ConversationListLeftSkeleton long={false} />}
    </ScrollView>
  );
});
