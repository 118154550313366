import {
  EnumValues,
  ISharedCreateSuperadminProfile,
  SuperadminProfilePermissionIdEnum,
} from 'atlas-shared/dist';
import { IForm, } from 'atlas-form';
import { IAuth, } from '@Store';
import {
  SuperadminProfileAdditionalParams as SuperadminProfileAdditionalParamsBase,
  ISuperadminProfileFormOptionsProps,
} from './abstract/superadmin_profile.form.abstract';
import { FormComponentCrudSuperadmin, } from '@Utils/forms/components/crud-sa';

export * from './abstract/superadmin_profile.form.abstract';

export const SuperadminProfileAddInitialValues: ISharedCreateSuperadminProfile = {
  permissions: EnumValues(SuperadminProfilePermissionIdEnum).map(table => ({
    id: table,
    crud: 15,
  })),
} as any;

export const SuperadminProfileComponents: IForm['components'] = {
  permissions_crud: FormComponentCrudSuperadmin,
};

export const SuperadminProfileAdditionalParams: (auth: IAuth, props: ISuperadminProfileFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ISuperadminProfileFormOptionsProps) => ({
  ...SuperadminProfileAdditionalParamsBase(auth, props),
  permissions: {
    shouldUpdate: (prev, next) => prev.functionParams[0]?.organization_id !== next.functionParams[0].organization_id,
  },
  permissions_id: { hideLabel: true, row_style: { width: 150, }, },
  permissions_crud: {
    hideLabel: true,
    shouldUpdate: (prev, next) => prev.functionParams[1]?.id !== next.functionParams[1].id,
  },
});
