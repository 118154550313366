import * as React from 'react';
import './channels.scss';
import {
  MailIcon,
  MailInboundAutoReplyIcon,
  MailInboundIcon,
  MailOutboundAutoReplayIcon,
  MailOutboundIcon,
} from '../Mail';
import { ChatIcon, } from '../Chat';
import {
  PhoneOutgoingIcon,
  PhoneIncomingIcon,
  PhoneIcon,
  PhoneInternalIcon,
  PhoneTransferIcon,
  PhoneCallLiveIcon,
} from '../Phone';
import { EditIcon, } from '../Edit';
import { ArchiveIcon, } from '../Archive';
import { AlignLeftIcon, } from '../Align';
import { TFunction, } from 'i18next';
import {
  ArrowDownLeftIcon,
  ArrowInternalIcon,
  ArrowUpRightIcon,
  ChanSpyIcon,
  DollarIcon,
  UnknownIcon,
  VoicemailIcon,
  WarningIcon,
} from '@Assets';

export interface IChannelIconCircleProps {
  t: TFunction;
  size?: number;
  padding?: number;
  className?: string | null | undefined;
}

export const MailIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <MailIcon
  className={`${className ? `${className} ` : ''}circle-icon mail-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--COLOR-D9E2FE)', padding: padding || 7, }, }}
  style={{ color: 'var(--COLOR-3953FE)', }}
  title={t('CHANNEL_MAIL')}
/>;

export const MailInboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <MailInboundIcon
  className={`${className ? `${className} ` : ''}circle-icon mail-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--COLOR-D9E2FE)', padding: padding || 7, }, }}
  style={{ color: 'var(--COLOR-3953FE)', }}
  title={t('CHANNEL_MAIL_INBOUND')}
/>;

export const MailInboundAutoReplyIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <MailInboundAutoReplyIcon
  className={`${className ? `${className} ` : ''}circle-icon mail-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--COLOR-D9E2FE)', padding: padding || 7, }, }}
  style={{ color: 'var(--COLOR-3953FE)', }}
  title={t('CHANNEL_MAIL_INBOUND')}
/>;

export const MailOutboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <MailOutboundIcon
  className={`${className ? `${className} ` : ''}circle-icon mail-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--COLOR-D9E2FE)', padding: padding || 7, }, }}
  style={{ color: 'var(--COLOR-3953FE)', }}
  title={t('CHANNEL_MAIL_OUTBOUND')}
/>;

export const MailOutboundAutoReplyIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <MailOutboundAutoReplayIcon
  className={`${className ? `${className} ` : ''}circle-icon mail-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--COLOR-D9E2FE)', padding: padding || 7, }, }}
  style={{ color: 'var(--COLOR-3953FE)', }}
  title={t('CHANNEL_MAIL_OUTBOUND')}
/>;

export const ChatIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <ChatIcon
  className={`${className ? `${className} ` : ''}circle-icon chat-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--green-main)', }}
  title={t('CHANNEL_CHAT')}
/>;

export const ChatInboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <ChatIcon
  className={`${className ? `${className} ` : ''}circle-icon chat-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--green-main)', }}
  title={t('CHANNEL_CHAT_INBOUND')}
/>;

export const ChatOutboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <ChatIcon
  className={`${className ? `${className} ` : ''}circle-icon chat-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--green-main)', }}
  title={t('CHANNEL_CHAT_OUTBOUND')}
/>;

export const TextMessageIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneIncomingIcon
  className={`${className ? `${className} ` : ''}circle-icon text-message-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  title={t('CHANNEL_SMS')}
/>;

export const TextMessageInboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneIncomingIcon
  className={`${className ? `${className} ` : ''}circle-icon text-message-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  title={t('CHANNEL_SMS_INBOUND')}
/>;

export const TextMessageOutboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneIncomingIcon
  className={`${className ? `${className} ` : ''}circle-icon text-message-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  title={t('CHANNEL_SMS_OUTBOUND')}
/>;

export const PhoneIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  style={{ color: '#000533', }}
  title={t('CHANNEL_VOICE')}
/>;

export const PhoneInboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneIncomingIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--orange-dark)', }}
  title={t('CHANNEL_VOICE_INBOUND')}
/>;

export const VoiceInternalIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneCallLiveIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--blue-main)', padding: padding || 7, }, }}
  style={{ color: '#FFFFFF', }}
  title={t('INTERNAL_CALL')}
/>;

export const PhoneInternalIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneInternalIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--orange-dark)', }}
  title={t('CHANNEL_VOICE_INTERNAL')}
/>;

export const PhoneInternalLiveIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneInternalIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--green-main)', }}
  title={t('CHANNEL_VOICE_INTERNAL_LIVE')}
/>;

export const PhoneTransferIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneTransferIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--orange-dark)', }}
  title={t('CHANNEL_VOICE_TRANSFER')}
/>;

export const PhoneTransferLiveIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneTransferIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--green-main)', }}
  title={t('CHANNEL_VOICE_TRANSFER_LIVE')}
/>;

export const PhoneInboundLiveIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneIncomingIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--green-main)', }}
  title={t('CHANNEL_VOICE_INBOUND_LIVE')}
/>;

export const PhoneOutboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneOutgoingIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  title={t('CHANNEL_VOICE_OUTBOUND')}
/>;

export const PhoneOutboundLiveIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <PhoneOutgoingIcon
  className={`${className ? `${className} ` : ''}circle-icon phone-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--green-main)', }}
  title={t('CHANNEL_VOICE_OUTBOUND_LIVE')}
/>;

export const EditIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <EditIcon
  className={`${className ? `${className} ` : ''}circle-icon edit-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  style={{ color: '#000533', }}
  title={t('CHANNEL_EDIT')}
/>;

export const DispositionNoteIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <ArchiveIcon
  className={`${className ? `${className} ` : ''}circle-icon disposition-note-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'rgb(223, 224, 245)', padding: padding || 7, }, }}
  style={{ color: '#000533', }}
  title={t('CHANNEL_DISPOSITION_NOTE')}
/>;

export const FormIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <AlignLeftIcon
  className={`${className ? `${className} ` : ''}circle-icon form-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'rgb(220,220,220)', padding: padding || 7, }, }}
  style={{ color: '#000533', }}
  title={t('CHANNEL_FORM')}
/>;

export const VoicemailIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <VoicemailIcon
  className={`${className ? `${className} ` : ''}circle-icon voicemail-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  title={t('CHANNEL_VOICEMAIL')}
/>;

export const ChanSpyIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <ChanSpyIcon
  className={`${className ? `${className} ` : ''}circle-icon chanspy-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--red-light)', padding: padding || 7, }, }}
  title={t('CHANNEL_CHANSPY')}
/>;

export const OrderIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <DollarIcon
  className={`${className ? `${className} ` : ''}circle-icon order-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--blue-light)', padding: padding || 7, }, }}
  title={t('EVENT_ORDER')}
/>;

export const UnknownIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <UnknownIcon
  className={`${className ? `${className} ` : ''}circle-icon order-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--blue-light)', padding: padding || 7, }, }}
/>;

export const InboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <ArrowDownLeftIcon
  stroke={2}
  strokeColor={'var(--green-main)'}
  className={`${className ? `${className} ` : ''}circle-icon inbound-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  title={t('INBOUND')}
/>;

export const OutboundIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <ArrowUpRightIcon
  stroke={2}
  strokeColor={'var(--red-main)'}
  className={`${className ? `${className} ` : ''}circle-icon outbound-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--red-light)', padding: padding || 7, }, }}
  title={t('INBOUND')}
/>;

export const InternalIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <ArrowInternalIcon
  stroke={2}
  strokeColor={'#000533'}
  className={`${className ? `${className} ` : ''}circle-icon internal-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--orange-light)', padding: padding || 7, }, }}
  title={t('INBOUND')}
/>;

export const WarningIconCircled = ({ size, padding, className, t, }: IChannelIconCircleProps) => <WarningIcon
  className={`${className ? `${className} ` : ''}circle-icon chat-circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--green-light)', padding: padding || 7, }, }}
  style={{ color: 'var(--green-main)', }}
  title={t('CHANNEL_CHAT')}
/>;
