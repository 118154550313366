import { IPreviewJourney, } from 'atlas-shared';

export enum JourneyActions {
  SET_STATE = 'JOURNEY_SET_STATE',
  ADD_STATE = 'JOURNEY_ADD_STATE',
  REMOVE_STATE = 'JOURNEY_REMOVE_STATE',
  GET_STATE = 'JOURNEY_GET_STATE',
  UPDATE_STATE = 'JOURNEY_UPDATE_STATE',
  LOADING = 'JOURNEY_LOADING',
}

export interface IJourneyLoading {
  type: JourneyActions.LOADING;
}

export interface IJourneySetAction {
  type: JourneyActions.SET_STATE;
  payload: Array<IPreviewJourney>;
}

export interface IJourneyUpdateAction {
  type: JourneyActions.UPDATE_STATE;
  payload: IPreviewJourney;
}

export interface IJourneyAddAction {
  type: JourneyActions.ADD_STATE;
  payload: IPreviewJourney;
}

export interface IJourneyRemoveAction {
  type: JourneyActions.REMOVE_STATE;
  payload: IPreviewJourney['id'];
}

export interface IJourneyStore {
  loaded: boolean;
  loading: boolean;
  journeies: Array<IPreviewJourney>;
  dict: Record<IPreviewJourney['id'], IPreviewJourney>;
}

export type TJourneyActionTypes = IJourneySetAction | IJourneyUpdateAction | IJourneyAddAction | IJourneyRemoveAction | IJourneyLoading;
