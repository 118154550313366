import { journeyAction, settingsFormSchema, } from './base';

import { JourneyActionSendMail as JourneyActionSendMailBase, } from 'atlas-shared';
import { FormElementTypes, } from 'atlas-form';

export const JourneyActionSendMail = journeyAction({
  ...JourneyActionSendMailBase,
  settings_form: settingsFormSchema({
    elements: [
      { ref: 'mail_account_id', },
      { ref: 'subject', },
      { ref: 'to', mode: 'tags', },
      { ref: 'body', },
    ],
  }),
  additionalParams: {
    properties_body: ({ ui_type: FormElementTypes.HTML, hashOptions: ['conversation', 'contact', ], }),
  },
  icon: 'Send',
});
