import { IAuth, } from '@Store';
import { IForm, } from '@Modules/atlas-form-core';
import { IGdprFormOptionsProps, } from '@Utils/forms/abstract/gdpr.form.abstract';

export * from './abstract/gdpr.form.abstract';
import { GdprAdditionalParams as GdprAdditionalParamsBase, } from './abstract/gdpr.form.abstract';

export const GdprAdditionalParams: (auth: IAuth, props: IGdprFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IGdprFormOptionsProps) => ({
  ...GdprAdditionalParamsBase(auth, props),
  contact_anonymization: { help: 'CONTACT_ANONYMIZATION_DESCRIPTION', postFixText: 'DAYS', postFixTextNull: '', },
  contact_deletion: { help: 'CONTACT_DELETION_RECORDINGS_DESCRIPTION', postFixText: 'DAYS', },
  contact_voice_recordings: { help: 'CONTACT_VOICE_RECORDINGS_DESCRIPTION', postFixText: 'DAYS', postFixTextNull: '', },
  contact_email_attachments: { help: 'CONTACT_EMAIL_ATTACHMENTS_DESCRIPTION', postFixText: 'DAYS', postFixTextNull: '', },
  contact_form_submission_attachments: { help: 'CONTACT_FORM_SUBMISSION_ATTACHMENTS_DESCRIPTION', postFixText: 'DAYS', postFixTextNull: '', },
  agent_anonymization: { help: 'AGENT_ANONYMIZATION_DESCRIPTION', },
  ip_whitelist_ip_addresses: { mode: 'tags', help: 'IP_WHITELIST_DESCRIPTION', },
  password_lifetime: { postFixText: 'DAYS', label: 'PASSWORD_LIFETIME', help: 'PASSWORD_LIFETIME_DESCRIPTION', },
  password_history: { disabled: true, label: 'PASSWORD_HISTORY', help: 'PASSWORD_HISTORY_DESCRIPTION', },
  password_strong: { disabled: true, label: 'PASSWORD_STRONG', help: 'PASSWORD_STRONG_DESCRIPTION', },
  connectel_info_share: { disabled: true, help: 'CONNECTEL_INFO_SHARE_DESCRIPTION', },
});
