import { RestRequest, } from '@Utils';
import { IPreviewPhonenumberPrefix, ISharedPhonenumberPrefix, ISharedSearchPhonenumberPrefix, } from 'atlas-shared';

export const fetchPhonenumberPrefix = async (id: IPreviewPhonenumberPrefix['id']): Promise<ISharedPhonenumberPrefix> => {
  return RestRequest.get<ISharedPhonenumberPrefix>(`phonenumber_prefix/${id}`, { socket_id: global.socketId, });
};

export const searchPhonenumberPrefix = async (params: ISharedSearchPhonenumberPrefix): Promise<{items: Array<IPreviewPhonenumberPrefix>; count: number}> => {
  return RestRequest.post<ISharedSearchPhonenumberPrefix, {items: Array<IPreviewPhonenumberPrefix>; count: number}>('phonenumber_prefix/search', params, { socket_id: global.socketId, });
};
