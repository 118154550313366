import { IPreviewVoiceRecordingTranscriptSetting, } from 'atlas-shared';

export enum VoiceRecordingTranscriptSettingActions {
  SET_STATE = 'VOICE_RECORDING_TRANSCRIPT_SETTING_SET_STATE',
  ADD_STATE = 'VOICE_RECORDING_TRANSCRIPT_SETTING_ADD_STATE',
  REMOVE_STATE = 'VOICE_RECORDING_TRANSCRIPT_SETTING_REMOVE_STATE',
  GET_STATE = 'VOICE_RECORDING_TRANSCRIPT_SETTING_GET_STATE',
  UPDATE_STATE = 'VOICE_RECORDING_TRANSCRIPT_SETTING_UPDATE_STATE',
  LOADING = 'VOICE_RECORDING_TRANSCRIPT_SETTING_LOADING',
}

export interface IVoiceRecordingTranscriptSettingLoading {
  type: VoiceRecordingTranscriptSettingActions.LOADING;
}

export interface IVoiceRecordingTranscriptSettingSetAction {
  type: VoiceRecordingTranscriptSettingActions.SET_STATE;
  payload: Array<IPreviewVoiceRecordingTranscriptSetting>;
}

export interface IVoiceRecordingTranscriptSettingUpdateAction {
  type: VoiceRecordingTranscriptSettingActions.UPDATE_STATE;
  payload: IPreviewVoiceRecordingTranscriptSetting;
}

export interface IVoiceRecordingTranscriptSettingAddAction {
  type: VoiceRecordingTranscriptSettingActions.ADD_STATE;
  payload: IPreviewVoiceRecordingTranscriptSetting;
}

export interface IVoiceRecordingTranscriptSettingRemoveAction {
  type: VoiceRecordingTranscriptSettingActions.REMOVE_STATE;
  payload: IPreviewVoiceRecordingTranscriptSetting['id'];
}

export interface IVoiceRecordingTranscriptSettingStore {
  loaded: boolean;
  loading: boolean;
  voice_recording_transcript_settings: Array<IPreviewVoiceRecordingTranscriptSetting>;
  dict: Record<IPreviewVoiceRecordingTranscriptSetting['id'], IPreviewVoiceRecordingTranscriptSetting>;
}

export type TVoiceRecordingTranscriptSettingActionTypes = IVoiceRecordingTranscriptSettingSetAction | IVoiceRecordingTranscriptSettingUpdateAction | IVoiceRecordingTranscriptSettingAddAction | IVoiceRecordingTranscriptSettingRemoveAction | IVoiceRecordingTranscriptSettingLoading;
