import React from 'react';
import './header.admin.menu.scss';
import { MenuIcon, } from '@Assets';

interface IProps {
  onToggleMenu: () => void;
}

export const HeaderAdminMenu = React.memo(({ onToggleMenu, }: IProps) => {
  return <div onClick={onToggleMenu} id={'menu-icon'}>
    <MenuIcon size={24} />
  </div>;
});
