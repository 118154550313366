import {
  CustomFormItemProps,
  FormElementTypes,
  IFormAdditionalParams,
  IFormElement,
  IFormElementArray,
  IFormElementDate,
  IFormElementDatetime,
  IFormElementFile,
  IFormElementHtml,
  IFormElementObject,
  IFormElements,
  IFormElementSelect,
  IFormElementText,
  IFormElementTime,
  IFormElementTree,
  IFormElementTypeahead,
  JSONForm,
  TypeFormAdditionalParamsFunction,
  TypeFormOption,
  TypeFormOptionFunction,
} from '../atlas-form-core';
import { Undefinable, Nullable, } from 'atlas-shared';
import * as React from 'react';
import { Button, Checkbox, Input, InputNumber, PopoverProps, Radio, Switch, Upload, Typography, Tooltip, Popover, } from 'antd';
import './styles.scss';
import FormDatePicker from './date-pickers/form.date.picker';
import FormTimePicker from './date-pickers/form.time.picker';
import FormDateTimePicker from './date-pickers/form.date.time.picker';
import FormHtmlEditor from './form.html.editor';
import FormSection from './form.section';
import { ReactElement, } from 'react';
import { IFormItemsInputProps, } from './utils';
import FormModal from './form.modal';
import FormPopover from './form.popover';
import FormSelect from './form.select';
import FormInput from './form.input';
import FormText from './form.text';
import { clone, IOption, } from '@Utils';
import { store, } from '@Store';
import { Provider, } from 'react-redux';
import { FormList, } from './form.list';
import FormTreeSelect from './form.tree.select';
import { Icon, IconText, } from '@Components';
import { DownloadIcon, EyeIcon, EyeOffIcon, PlusIcon, TrashIcon, UploadIcon, } from '@Assets';
import { ShowUploadListInterface, } from 'antd/lib/upload/interface';
import { FormElementPhone, } from './form.element.phone';
import debounce from 'lodash/debounce';
import { SketchPicker, } from 'react-color';
import { AudioPlayer, } from '@Utils/audio-player';
import { buildFormFieldTitleKey, } from './errors';

export * from './form';
export { default as FormSelect, } from './form.select';
export { default as FormInput, } from './form.input';
export { default as FormText, } from './form.text';
export { default as FormTreeSelect, } from './form.tree.select';
export { default as FormHtmlEditor, } from './form.html.editor';
export { FormElementPhone, };

export const getFieldName = (path: string, name?: Array<string | number>): Array<string | number> => {

  if (!path)
    return [];

  const splitted = path.split('.').map(c => isNaN(c as any) ? c : +c);

  if (name)
    return splitted.filter((s, i) => name?.[i] !== s);

  return splitted;
};

export const createFormField = (
  t,
  props: IFormItemsInputProps,
  _item: IFormElements,
  name: Array<string | number>,
  value: any,
  AudioRecorder: IFormItemsInputProps['AudioRecorder'],
  addGlobalPopupModal: IFormItemsInputProps['addGlobalPopupModal']
): any => {

  let elm: ReactElement;
  let options: Nullable<Array<IOption>> = null;
  let preview: Undefinable<React.ReactElement>;

  let custom_options: Undefinable<TypeFormOption> = undefined;

  const path = ((props.form.path || '') as string).split('.');
  const string_path: string = path.filter(p => isNaN(p as any)).join('_');
  const baseValues = props.json_form.getValues();
  const getFunctionParams = (): [any, any, any, any, any] => [
    baseValues,
    path.length > 1 ? props.json_form.findValueFromPath(path.slice(0, path.length - 1).join('.')) : baseValues,
    path.length ? props.json_form.findValueFromPath(path.join('.')) : baseValues,
    path.length > 2 ? props.json_form.findValueFromPath(path.slice(0, path.length - 2).join('.')) : undefined,
    props.entity,
  ];

  const functionParams: [any, any, any, any, any, JSONForm] = [...clone(getFunctionParams()), props.json_form, ];

  let additionalParams: IFormAdditionalParams | TypeFormAdditionalParamsFunction | null | undefined = isNaN(+path?.[path.length - 1]) ? props.additionalParams?.[string_path] : null;

  if (typeof additionalParams === 'function')
    additionalParams = additionalParams(...functionParams);

  let inheritedAdditionalParams = props.inheritedAdditionalParams;

  if (typeof inheritedAdditionalParams === 'function')
    inheritedAdditionalParams = inheritedAdditionalParams(...functionParams);

  const item = { ..._item, ...additionalParams, ...inheritedAdditionalParams, };

  if (!props.form.path)
    item.hideLabel = true;

  let label = (item.label || item.label === '') ? item.label : ((name?.[name.length - 1] || buildFormFieldTitleKey(item.path?.split('.'))) + '').toUpperCase();

  if (item.labelRenderer)
    label = item.labelRenderer(label);

  if (item.hideLabel && !item.placeholder && typeof label === 'string')
    item.placeholder = label;

  if (item.placeholder)
    item.placeholder = t(item.placeholder);

  //if (!(item.nullable && value === null)) {
  // needs to calculate options as for nullables un-nulled
  if ('options' in item) {
    if (typeof item.options === 'string')
      custom_options = props.options[item.options];
    else
      custom_options = item.options;
  }

  if (!custom_options)
    custom_options = props.options[string_path];

  if (!custom_options && 'valid' in item)
    custom_options = item.valid?.filter(o => o !== null && o !== undefined).map((o: string) => ({ key: o, title: t((o + '').toUpperCase()), }));

  if (custom_options)
    options = typeof custom_options === 'function' ? (custom_options as TypeFormOptionFunction)(...functionParams) : custom_options as Array<IOption>;

  if (props.previews[string_path])
    preview = props.previews[string_path](...functionParams);

  let component: IFormElement['component'] = item.component || string_path;

  if (component && props.components && typeof component === 'string')
    component = props.components[component];

  const default_value = value;
  const onChange = (value) => {
    props.json_form.setValueByPath(props.form.path, value);
  };

  const onError = (errors: Array<string>) => {
    if (props.form.path)
      props.json_form.setErrorByPath(props.form.path, path, errors);
  };

  const debouncedChange = debounce((value) => {
    onChange(value);
  }, 250);

  if (typeof component === 'function') {
    const Component: React.FC<CustomFormItemProps> = component;

    elm = <Component entityId={props.entity?.id} t={t} entity={props.entity} value={default_value} onChange={onChange} data={functionParams} options={options || []} disabled={item.disabled} />;
  }
  else if (props.form.ui_type === FormElementTypes.SECTION) {
    elm = <FormSection key={(name || ['form', ]).join('_')} {...props} name={name} form={props.form as IFormElementObject} json_form={props.json_form} />;

    const modalKey: string = props.form.path as string;
    const modalProps = (props.form as IFormElementObject).modal;

    if (modalProps) {
      elm = <FormModal
        onClick={() => props.setModal(modalKey, true)}
        closeModal={() => { props.setModal(modalKey, false); }}
        modalProps={modalProps}
        visible={Boolean(props.modals[modalKey])}
        openButtonText={'Open'}
      >
        {elm}
      </FormModal>;
    }

    if (!elm) {
      const popoverProps: Undefinable<PopoverProps> = (props.form as IFormElementObject).popover;

      if (popoverProps) {
        elm = <FormPopover popoverProps={popoverProps} buttonText={'Popover'}>
          {elm}
        </FormPopover>;
      }
    }
  }
  else {

    switch (item.ui_type) {
    case FormElementTypes.TIME:
      elm = (
        <FormTimePicker
          className={'forms-input'}
          onChange={time => onChange(time)}
          value={default_value}
          style={item.element_style}
          disabled={item.disabled}
          format={(item as IFormElementTime).ui_settings?.format}
        />
      );
      break;
    case FormElementTypes.DATE:
      elm = (
        <FormDatePicker
          className={'forms-input'}
          onChange={dt => onChange(dt)}
          value={default_value}
          style={item.element_style}
          disabled={item.disabled}
          format={(item as IFormElementDate).ui_settings?.format}
        />
      );
      break;
    case FormElementTypes.DATETIME:
      elm = (
        <FormDateTimePicker
          className={'forms-input'}
          onChange={dt => onChange(dt)}
          value={default_value}
          style={item.element_style}
          disabled={item.disabled}
          format={(item as IFormElementDatetime).ui_settings?.format}
        />
      );
      break;
    case FormElementTypes.NUMBER:
      elm = (
        <InputNumber
          placeholder={item.placeholder}
          className={'forms-input'}
          onBlur={e => onChange(+e.target.value)}
          onKeyUp={e => debouncedChange((e.target as any).value)}
          defaultValue={default_value}
          style={{ ...(item.element_style || {}), width: item.precision ? Math.max((item.precision * 8) + 70, 90) : undefined, }}
          precision={item.precision}
          step={item.precision ? +`.${'0'.repeat(item.precision - 1)}1` : 1}
          disabled={item.disabled}
        />
      );
      break;
    case FormElementTypes.PREVIEW:
      elm = <div className='preview-value'><Tooltip title={t('FORM_PREVIEW_NOT_EDITABLE')}><Typography.Text>{props.form.preview_value || default_value}</Typography.Text></Tooltip></div>;
      break;
    case FormElementTypes.TEXT:
      elm = (
        <FormText
          placeholder={item.placeholder}
          className={'forms-input'}
          rows={(item as IFormElementText).rows || 3}
          onChange={e => onChange(e.target.value)}
          default_value={default_value}
          style={item.element_style}
          disabled={item.disabled}
        />
      );
      break;
    case FormElementTypes.FILE:
      let uploadProps: IFormElementFile['uploadProps'] = (item as IFormElementFile).uploadProps || {};

      uploadProps.showUploadList = {
        removeIcon: <Icon icon={TrashIcon} />,
        downloadIcon: <Icon icon={DownloadIcon} />,
        showDownloadIcon: true,
        ...((uploadProps.showUploadList as ShowUploadListInterface) || {}),
      };

      if (uploadProps.onValueChange)
        uploadProps.onChange = uploadProps.onValueChange(onChange);

      const uploadElm = <>
        <Upload
          name={item.ref}
          {...uploadProps}
          className={[uploadProps.className, `upload-${uploadProps.multiple ? 'multiple' : 'single'}`, ].filter(Boolean).join(' ')}
          disabled={item.disabled}
        >
          <Button>
            <IconText icon={UploadIcon} text={t('UPLOAD')} />
          </Button>
        </Upload>
        <div className='audio-player'>{(uploadProps.defaultFileList || []).map(file => file.name?.endsWith('.wav') && uploadProps?.onPreview && <AudioPlayer t={t} size='s' stream={() => uploadProps?.onPreview?.(file)} />)}</div>
      </>;

      elm = <div className='forms-input-file'>

        {uploadProps.accept?.includes('.wav') ? <AudioRecorder action={uploadProps.action as string} headers={uploadProps.headers || {}} uploadElm={uploadElm} /> : uploadElm}
      </div>;
      break;
    case FormElementTypes.HTML:
      elm = <Provider store={store}>
        <FormHtmlEditor
          organization_id={baseValues?.organization_id || props.entity?.organization_id }
          value={default_value}
          className={'forms-input'}
          placeholder={item.placeholder}
          onBlur={value => onChange(value)}
          onChange={value => debouncedChange(value)}
          atOptions={(item as IFormElementHtml).atOptions}
          hashOptions={(item as IFormElementHtml).hashOptions}
          canned_answers={(item as IFormElementHtml).canned_answers}
          style={item.element_style}
          file_upload={(item as IFormElementHtml).file_upload}
          disabled={item.disabled}
        />
      </Provider>;
      break;
    case FormElementTypes.INPUT:
      elm = (
        item.data_type === 'phone' ?
          <FormElementPhone
            placeholder={item.placeholder || t('LOCAL_PHONE_NUMBER')}
            className={'forms-input'}
            defaultValue={default_value}
            onBlur={phonenumber => onChange(phonenumber)}
            style={item.element_style}
            disabled={item.disabled}
          /> :
          <FormInput
            placeholder={item.placeholder}
            className={'forms-input'}
            default_value={default_value}
            onChange={onChange}
            style={item.element_style}
            disabled={item.disabled}
            options={options || []}
            onError={onError}
          />
      );
      break;
    case FormElementTypes.PASSWORD:
      elm = (
        <Input.Password
          placeholder={item.placeholder}
          className={'forms-input'}
          defaultValue={default_value}
          onBlur={e => onChange(e.target.value)}
          onKeyUp={e => debouncedChange((e.target as any).value)}
          style={item.element_style}
          iconRender={visible => (visible ? <Icon icon={EyeIcon} /> : <Icon icon={EyeOffIcon} /> )}
          autoComplete={'new-password'}
          disabled={item.disabled}
        />
      );
      break;
    case FormElementTypes.TREE:
      elm = <FormTreeSelect
        className={'forms-input'}
        value={value}
        options={options || []}
        onChange={selected => onChange(selected)}
        style={item.element_style}
        treeDefaultExpandAll={true}
        multiple={(item as IFormElementTree).multiple}
        disabled={item.disabled}
      />;
      // elm = <TreeSelect
      //   value={value}
      //   placeholder={item.placeholder}
      //   treeDefaultExpandAll
      //   onChange={onChange}
      //   multiple={!!(item as IFormElementTree).multiple}
      //   dropdownMatchSelectWidth={false}
      //   filterTreeNode={(w, option) => {
      //     console.log('option', option);
      //     return ((option?.title || '') as string).toLowerCase().includes(w.toLowerCase());
      //   }}
      //   tagRender={(props) => {
      //     return <div className='ant-select-selection-item-wrapper'>
      //       <div
      //         title={typeof props.label === 'string' ? props.label + '' : undefined}
      //         className='ant-select-selection-item'
      //       >
      //         <span className='ant-select-selection-item-content'>{props.label}</span>
      //         <Icon icon={XIcon} className='ant-select-selection-item-remove' onClick={e => props.onClose()} />
      //       </div>
      //     </div>;
      //   }}
      // >
      //   {options && optionsToTreeNode(options as Array<IOption>)}
      // </TreeSelect>;
      break;
    case FormElementTypes.SELECT:
      elm = (
        <FormSelect
          className={'forms-input'}
          placeholder={item.placeholder}
          value={value}
          options={options || []}
          onChange={selected => onChange(selected)}
          style={item.element_style}
          onSearch={(item as IFormElementSelect).onSearch}
          disabled={item.disabled}
          keep_default_sorting={!!item.keep_default_sorting}
        />
      );
      break;
    case FormElementTypes.TYPEAHEAD:
      elm = (
        <FormSelect
          className={'forms-input'}
          mode={(item as IFormElementTypeahead).mode}
          tagsOptionValidation={(item as IFormElementTypeahead).tagsOptionValidation}
          placeholder={item.placeholder}
          maxTagCount={(item as IFormElementTypeahead).maxTagCount}
          onChange={selected => {
            onChange(item.onAdd ? item.onAdd(selected, item as IFormElementTypeahead, value, baseValues) : selected);
          }}
          value={default_value}
          style={item.element_style}
          tag_style={(item as IFormElementTypeahead).tag_style}
          options={options || []}
          sortable={item.sortable || true}
          form_props={{ rest: props, item, name, }}
          tagRender={(item as IFormElementTypeahead).tagRender}
          disabled={item.disabled}
          // optionRender={option => {
          //   console.log('oooooooooo', options);
          //   return <>aaa</>;
          // }}
          // tagRender={option => {
          //   console.log('tttttttt', option);
          //   return option.label;
          // }}
        />
      );
      break;
    case FormElementTypes.SWITCH:
      elm = <Switch
        className={'forms-switch'}
        defaultChecked={default_value}
        onChange={checked => onChange(checked)}
        style={item.element_style}
        disabled={item.disabled}
      />;
      break;
    case FormElementTypes.CHECKBOX:
      elm = <Checkbox
        className={'form-cb'}
        defaultChecked={value}
        onChange={e => onChange(e.target.checked)}
        style={item.element_style}
        disabled={item.disabled}
      />;
      break;
    case FormElementTypes.RADIO:
      elm = (
        <Radio.Group
          value={value}
          onChange={e => onChange(e.target.value)}
          defaultValue={default_value}
          style={item.element_style}
          disabled={item.disabled}
        >
          {(options || []).map(({ key, title, }: IOption) => (
            <Radio key={key} value={key}>
              <span className='text' style={{ color:'var(--COLOR-00053380)', }}>
                {title}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      );
      break;
    case FormElementTypes.CHECKBOXES:
      elm = <Checkbox.Group
        options={options?.map(o => ({ label: o.title, value: o.key, }) as any) || []}
        onChange={checked => onChange(checked)}
        defaultValue={default_value}
        style={item.element_style}
        disabled={item.disabled}
      />;
      break;
    case FormElementTypes.COLOR:
      elm = <Popover trigger='click' overlayClassName='color-picker-popover' content={<SketchPicker
        color={default_value}
        onChangeComplete={({ rgb: { r, g, b, a, }, }) => onChange(`rgba(${r}, ${g}, ${b}, ${a})`)}
        disabled={item.disabled}
      />}>
        <div className='color-picker-btn' style={{ backgroundColor: default_value || undefined, }}></div>
      </Popover>;
      break;
    case FormElementTypes.LIST:
      elm = <FormList
        {...props}
        item={item as IFormElementArray}
        name={name}
        value={value}
        list_delimiter={additionalParams?.list_delimiter}
        disabled={item.disabled}
      />;
      break;
    default:
      elm = <>DEFAULT - should not reach here!</>;
    }
  }

  const onModalAdd = (additionalParams as IFormAdditionalParams)?.onModalAdd;

  if (addGlobalPopupModal && onModalAdd)
    elm = <div className='form-item-elm-wrapper'>
      {elm}
      <Icon
        icon={PlusIcon}
        className='form-item-select-modal-add'
        iconProps={{ size:18, }}
        title={onModalAdd.item_text && `${t('ADD')} ${t(onModalAdd.item_text)}`}
        onClick={() => {
          addGlobalPopupModal({
            ...onModalAdd,
            onFormChange: (payload) => {
              onChange(payload);
            },
            onFormChangePayload: onModalAdd.onFormChangePayload || ((payload) => payload.id),
            formInitialValues: { organization_id: baseValues?.organization_id || props.entity?.organization_id, },
          });
        }}
      />
    </div>;

  return {
    element: preview ? <div className='form-item-elm-wrapper'>{elm}{<div className='form-preview'>{preview}</div>}</div> : elm,
    params: item,
    options,
    additionalParams,
    string_path,
    items: item.items,
    shouldUpdate: item.shouldUpdate,
    functionParams,
    label,
    labelRenderer: props.labelProps,
    hasAdd: addGlobalPopupModal && onModalAdd,
  };

};
