import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.css';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider, } from 'react-redux';
import { store, } from './redux';
import { LoadingContainer, } from './containers/loading-container/loading.container';
import { ReactFlowProvider, } from 'react-flow-renderer';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <LoadingContainer>
      <ReactFlowProvider>
        <App />
      </ReactFlowProvider>
    </LoadingContainer>
  </Provider>
);
// const rootElement = document.getElementById('root');
// if (!rootElement) throw new Error('Failed to find the root element');
// const root = ReactDOM.createRoot(rootElement);
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
