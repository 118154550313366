import { journeyAction, } from './base';

import { JourneyActionAddInternalNote as JourneyActionAddInternalNoteBase, } from 'atlas-shared';
import { FormElementTypes, } from 'atlas-form';

export const JourneyActionAddInternalNote = journeyAction({
  ...JourneyActionAddInternalNoteBase,
  additionalParams: {
    properties_body: ({ ui_type: FormElementTypes.HTML, hashOptions: ['conversation', 'contact', ], }),
  },
  icon: 'Note',
});
