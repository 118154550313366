import React from 'react';
import './app.spinner.scss';

export const AppSpinner = () => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', padding: '10px', }}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default React.memo(AppSpinner);
