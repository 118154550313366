import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { IPreviewAdminprofile, TAdminprofileSortableListCol, } from 'atlas-shared/dist';
import React from 'react';
import { ColumnsType, } from 'antd/lib/table/interface';

export * from './abstract/adminprofile.list.column.abstract';

import { AdminprofileListColumns as AdminprofileListColumnsBase, } from './abstract/adminprofile.list.column.abstract';
import { Icon, } from '@Components';
import { UsersIcon, } from '@Assets';
import { IWithListProps, } from '@Hocs/with.list';

export const AdminprofileListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewAdminprofile) => React.ReactElement> = [], filters: IWithListProps<IPreviewAdminprofile, TAdminprofileSortableListCol>['filters'], sorter: IWithListProps<IPreviewAdminprofile, TAdminprofileSortableListCol>['sorter']): ColumnsType<IPreviewAdminprofile> => {
  return AdminprofileListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, [
    ...buttons,
    (row: IPreviewAdminprofile) => <Icon
      icon={UsersIcon}
      tooltip={{ title: t('USERS'), }}
      onClick={() => navigate(`/admin/adminprofile/${row.id}/users`)}
    />,
  ], filters, sorter);
};
