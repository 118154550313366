import React from 'react';
import { Modal, ModalProps, } from 'antd';
import './styles.scss';
import { Button, } from '../../components/misc';

interface IProps {
  onClick: ()=> void;
  openButtonText: string;
  modalProps: ModalProps;
  visible: boolean;
  closeModal: ()=> void;
  children?: any;
}

const FormModal = (props: IProps) => {
  const { children, closeModal, modalProps, onClick, visible, openButtonText, } = props;

  return (
    <>
      <Button onClick={onClick}>{openButtonText}</Button>
      <Modal
        {...modalProps}
        className={'form-modal'}
        visible={visible}
        onCancel={closeModal}
        onOk={closeModal}>
        {children}
      </Modal>
    </>
  );
};

export default React.memo(FormModal);
