import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateVoiceRoute, ISharedUpdateVoiceRoute, ISharedVoiceRoute, IPreviewOrganization, IPreviewVoiceTrunk, IPreviewJourney, EnumOptions, VoiceRoutePhonenumberProviderEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IVoiceRouteFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  voice_trunks: Array<IPreviewVoiceTrunk>;
  journeies: Array<IPreviewJourney>;
}

export const VoiceRouteFormOptions = (voice_route: ISharedCreateVoiceRoute | ISharedVoiceRoute, props: IVoiceRouteFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.VoiceRoute, {})), 'organization_id', false), 'organization'),
  inbound_voice_trunk_id: (it: ISharedVoiceRoute) => filterRelated<IPreviewVoiceTrunk>(it.organization_id || voice_route.organization_id, 'voice_trunk', 'voice_route', props.voice_trunks, true),
  outbound_voice_trunk_id: (it: ISharedVoiceRoute) => filterRelated<IPreviewVoiceTrunk>(it.organization_id || voice_route.organization_id, 'voice_trunk', 'voice_route', props.voice_trunks, true),
  inbound_journey_id: (it: ISharedVoiceRoute) => filterRelated<IPreviewJourney>(it.organization_id || voice_route.organization_id, 'journey', 'voice_route', props.journeies, true),
  outbound_journey_id: (it: ISharedVoiceRoute) => filterRelated<IPreviewJourney>(it.organization_id || voice_route.organization_id, 'journey', 'voice_route', props.journeies, true),
  provider: EnumOptions('VOICE_ROUTE_PHONENUMBER_PROVIDER', VoiceRoutePhonenumberProviderEnum, t),
});

export const VoiceRouteComponents: IForm['components'] = {};
export const VoiceRouteAdditionalParams: (auth: IAuth, props: IVoiceRouteFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IVoiceRouteFormOptionsProps) => ({
});

export const VoiceRouteEditForm = (voice_route: ISharedVoiceRoute, props: IVoiceRouteFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `voice_route_edit_${voice_route.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === voice_route.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'provider', },
    { ref: 'inbound_voice_trunk_id', },
    { ref: 'outbound_voice_trunk_id', },
    { ref: 'inbound_journey_id', },
    { ref: 'outbound_journey_id', },
    { ref: 'description', },
  ],
});

export const VoiceRouteAddForm = (voice_route: ISharedCreateVoiceRoute, props: IVoiceRouteFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'voice_route_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'provider', },
    { ref: 'inbound_voice_trunk_id', },
    { ref: 'outbound_voice_trunk_id', },
    { ref: 'number', },
    { ref: 'inbound_journey_id', },
    { ref: 'outbound_journey_id', },
    { ref: 'description', },
  ],
});

export const VoiceRouteAddInitialValues: ISharedCreateVoiceRoute = {} as ISharedCreateVoiceRoute;

export const VoiceRouteEditInitialValues = (voice_route: ISharedVoiceRoute): ISharedUpdateVoiceRoute => ({
  provider: voice_route.provider,
  inbound_voice_trunk_id: voice_route.inbound_voice_trunk_id,
  outbound_voice_trunk_id: voice_route.outbound_voice_trunk_id,
  inbound_journey_id: voice_route.inbound_journey_id,
  outbound_journey_id: voice_route.outbound_journey_id,
  description: voice_route.description,
});

export const onVoiceRouteFormSaved = (t: TFunction, action: 'edit' | 'add', voice_route: ISharedVoiceRoute, navigate: NavigateFunction): void => {
  navigate(onFormSaved('voice_routes'));
};
