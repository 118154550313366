import React from 'react';
import { deleteMailAccount, } from '@Api';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { CrudEnum, IPreviewMailAccount, PermissionEnum, EnumTranslateTitle, EnumListSearchProps, TMailAccountSortableListCol, MailAccountImapStateEnum, MailAccountSmtpStateEnum, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnStringSorter, timeSince, } from '@Utils';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { ColumnsType, } from 'antd/lib/table/interface';
import { listAuditButton, listCloneButton, listDeleteButton, listEditButton, GetColumnSearchProps, GetColumnSearchLikeProps, GetColumnSearchEqualProps, } from '../components';
import { IWithListProps, } from '@Hocs/with.list';

export const MailAccountListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewMailAccount) => React.ReactElement> = [], filters: IWithListProps<IPreviewMailAccount, TMailAccountSortableListCol>['filters'], sorter: IWithListProps<IPreviewMailAccount, TMailAccountSortableListCol>['sorter']): ColumnsType<IPreviewMailAccount> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewMailAccount, b: IPreviewMailAccount) => a.id - b.id,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewMailAccount['organization_id'], mail_account: IPreviewMailAccount) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id, }, }),
      sorter: (a: IPreviewMailAccount, b: IPreviewMailAccount) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewMailAccount, b: IPreviewMailAccount) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      filteredValue: filters.title || null,
      ...GetColumnSearchLikeProps(t, 'title', t('TITLE')),
      className: 'table-cell-title',
    },
    {
      title: t('ADDRESS'),
      dataIndex: 'address',
      key: 'address',
      sorter: (a: IPreviewMailAccount, b: IPreviewMailAccount) => tableColumnStringSorter(a.address, b.address),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'address')?.order || null,
      filteredValue: filters.address || null,
      ...GetColumnSearchLikeProps(t, 'address', t('ADDRESS')),
      className: 'table-cell-address',
    },
    {
      title: t('IMAP_STATE'),
      dataIndex: 'imap_state',
      key: 'imap_state',
      render: (imap_state: IPreviewMailAccount['imap_state'], mail_account: IPreviewMailAccount) => EnumTranslateTitle('MAIL_ACCOUNT_IMAP_STATE', imap_state, t),
      sorter: (a: IPreviewMailAccount, b: IPreviewMailAccount) => EnumTranslateTitle('MAIL_ACCOUNT_IMAP_STATE', a.imap_state, t).localeCompare(EnumTranslateTitle('MAIL_ACCOUNT_IMAP_STATE', b.imap_state, t)),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'imap_state')?.order || null,
      filteredValue: filters.imap_state || null,
      ...GetColumnSearchProps(t, 'imap_state', t('IMAP_STATE'), EnumListSearchProps('MAIL_ACCOUNT_IMAP_STATE', MailAccountImapStateEnum, t)),
      className: 'table-cell-imap_state',
    },
    {
      title: t('SMTP_STATE'),
      dataIndex: 'smtp_state',
      key: 'smtp_state',
      render: (smtp_state: IPreviewMailAccount['smtp_state'], mail_account: IPreviewMailAccount) => EnumTranslateTitle('MAIL_ACCOUNT_SMTP_STATE', smtp_state, t),
      sorter: (a: IPreviewMailAccount, b: IPreviewMailAccount) => EnumTranslateTitle('MAIL_ACCOUNT_SMTP_STATE', a.smtp_state, t).localeCompare(EnumTranslateTitle('MAIL_ACCOUNT_SMTP_STATE', b.smtp_state, t)),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'smtp_state')?.order || null,
      filteredValue: filters.smtp_state || null,
      ...GetColumnSearchProps(t, 'smtp_state', t('SMTP_STATE'), EnumListSearchProps('MAIL_ACCOUNT_SMTP_STATE', MailAccountSmtpStateEnum, t)),
      className: 'table-cell-smtp_state',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewMailAccount['created_at'], mail_account: IPreviewMailAccount) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewMailAccount, b: IPreviewMailAccount) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    showDeleted && { 
      title: t('DELETED'), 
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      className: 'table-cell-deleted_at',
      render: (deleted_at: IPreviewMailAccount['deleted_at']) => deleted_at ? timeSince(t, new Date(deleted_at), true) : '',
      sorter: (a: IPreviewMailAccount, b: IPreviewMailAccount) => tableColumnDatetimeSorter(a.deleted_at, b.deleted_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'deleted_at')?.order || null,
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewMailAccount) => (
        <div className={'table-operation-wrp'}>
          {!row.deleted_at && buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Insert, PermissionEnum.MailAccount, row) && listCloneButton(t, navigate, 'mail_account', row)}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'mail_account', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.MailAccount, row) && listEditButton(t, navigate, 'mail_account', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Delete, PermissionEnum.MailAccount, row) && listDeleteButton(t, row, deleteMailAccount)}
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<IPreviewMailAccount>;

};
