import React, { RefObject, useCallback, } from 'react';
import debounce from 'lodash/debounce';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
  scrollRef?: RefObject<HTMLDivElement>;
  onScrollEnd?: () => void;
}

export const ScrollView = React.memo((props: IProps) => {
  const className = `scroll-view${props.className ? ` ${props.className}` : ''}`;
  const onScrollEnd = useCallback(debounce(() => {
    props.onScrollEnd?.();
  }, 50), [props.onScrollEnd, ]);

  return <div
    {...props}
    ref={props.scrollRef}
    style={{ overflowY: 'auto', overflowX: 'hidden', height: '100%', ...(props.style || {}), }}
    className={className}
    onScroll={(e) => {
      (e.currentTarget.offsetHeight + e.currentTarget.scrollTop + 100 >= e.currentTarget.scrollHeight) && onScrollEnd();
      props.onScroll?.(e);
    }}
  >
    {props.children}
  </div>;
});
