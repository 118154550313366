import { RestRequest, } from '@Utils';
import { IPreviewPause, ISharedPause, ISharedCreatePause, ISharedPatchPause, } from 'atlas-shared';

export const fetchPauses = async (): Promise<Array<IPreviewPause>> => {
  return RestRequest.get<Array<IPreviewPause>>('pause', { socket_id: global.socketId, });
};

export const fetchPause = async (id: IPreviewPause['id']): Promise<ISharedPause> => {
  return RestRequest.get<ISharedPause>(`pause/${id}`, { socket_id: global.socketId, });
};

export const createPause = (payload: ISharedCreatePause) => {
  return RestRequest.post<any, ISharedPause>('pause', payload, { socket_id: global.socketId, });
};

export const patchPause = (id: IPreviewPause['id'], payload: ISharedPatchPause) => {
  return RestRequest.patch<any, ISharedPause>(`pause/${id}`, payload, { socket_id: global.socketId, });
};

export const deletePause = (id: IPreviewPause['id']) => {
  return RestRequest.delete(`pause/${id}`, { socket_id: global.socketId, });
};

export const getPauseUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('pause/update/schema');
};

export const getPauseCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('pause/create/schema');
};

export const getPausePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('pause/patch/schema');
};
