import { IAuth, } from '@Store';
import { IForm, } from '@Modules/atlas-form-core';
import { IStatsReportFormOptionsProps, } from '@Utils/forms/abstract/stats_report.form.abstract';

export * from './abstract/stats_report.form.abstract';
import { StatsReportAdditionalParams as StatsReportAdditionalParamsBase, } from './abstract/stats_report.form.abstract';

export const StatsReportAdditionalParams: (auth: IAuth, props: IStatsReportFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IStatsReportFormOptionsProps) => {
  const base = StatsReportAdditionalParamsBase(auth, props);

  return {
    ...base,
    shared: { ...base?.shared, labelWidth: '80px', },
    shared_shared: { ...base?.shared_shared, labelWidth: '40px', },
    shared_with: { ...base?.shared_with, labelWidth: '40px', },
    shared_with_users: { ...base?.shared_with_users, labelWidth: '80px', },
    shared_with_teams: { ...base?.shared_with_teams, labelWidth: '80px', },
    shared_with_agentprofiles: { ...base?.shared_with_agentprofiles, labelWidth: '80px', },
    template_id: { ...base?.template_id, hidden: true, },
  };
};
