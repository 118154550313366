import { ConversationFavouriteActions, } from '@Store';
import { IPreviewConversationFavourite, } from 'atlas-shared';
import { fetchConversationFavourites, } from '@Api';

export const actionFetchConversationFavourites = () => {
  return async (dispatch) => {
    dispatch(actionLoadingConversationFavourites());
    const conversation_favourites = await fetchConversationFavourites();

    dispatch(actionSetConversationFavourites(conversation_favourites));
    return conversation_favourites;
  };
};

export const actionLoadingConversationFavourites = () => ({
  type: ConversationFavouriteActions.LOADING,
  payload: [],
});

export const actionSetConversationFavourites = (payload: Array<IPreviewConversationFavourite>) => ({
  type: ConversationFavouriteActions.SET_STATE,
  payload: payload,
});

export const actionAddConversationFavourite = (payload: IPreviewConversationFavourite) => ({
  type: ConversationFavouriteActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateConversationFavourite = (payload: IPreviewConversationFavourite) => ({
  type: ConversationFavouriteActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveConversationFavourite = (id: IPreviewConversationFavourite['id']) => ({
  type: ConversationFavouriteActions.REMOVE_STATE,
  payload: id,
});
