import { TeamActions, } from '@Store';
import { IPreviewTeam, } from 'atlas-shared';
import { fetchTeams, } from '@Api';

export const actionFetchTeams = () => {
  return async (dispatch) => {
    dispatch(actionLoadingTeams());
    const teams = await fetchTeams();

    dispatch(actionSetTeams(teams));
    return teams;
  };
};

export const actionLoadingTeams = () => ({
  type: TeamActions.LOADING,
  payload: [],
});

export const actionSetTeams = (payload: Array<IPreviewTeam>) => ({
  type: TeamActions.SET_STATE,
  payload: payload,
});

export const actionAddTeam = (payload: IPreviewTeam) => ({
  type: TeamActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateTeam = (payload: IPreviewTeam) => ({
  type: TeamActions.UPDATE_STATE,
  payload: payload,
});
