import * as React from 'react';
import { FormProps, ModalProps, TreeSelect, } from 'antd';
import { IOption, } from '@Utils';
import {
  FormElementTypes,
  IForm, IFormAdditionalParams,
  IFormElement,
  IFormElementArray, IFormElementList,
  IFormElements,
  IFormOptions, IFormPreviews, TypeFormAdditionalParamsFunction,
} from '../atlas-form-core/Interfaces';
import { JSONForm, } from '../atlas-form-core';
import { FormInstance, } from 'antd/lib/form/hooks/useForm';
import { createFormField, getFieldName, } from './index';
import { TFunction, } from 'i18next';
import { ReactElement, } from 'react';
import { AudioRecorder, } from './audio-recorder';
import { IGlobalPopupModal, } from '@Store';

const { TreeNode, } = TreeSelect;

export const FormItemClassNames = (path: Array<string | number>, prefix: string = 'ant-form-element-') => {
  const classNames: Array<string> = [];

  classNames.push(`${prefix}${path.join('_')}`);
  classNames.push(`${prefix}${path.filter(p => isNaN(+p)).join('_')}`);
  return classNames.join(' ');
};

export const optionsToTreeNode = (options: Array<IOption>) => {
  return <>
    {options.map(option => <TreeNode
      value={option.key as any}
      disabled={option.deleted}
      key={option.key}
      title={option.title}
      className={option.deleted ? 'ant-select-tree-treenode-deleted' : ''}
    >
      {option.children && optionsToTreeNode(option.children)}
    </TreeNode>)}
  </>;
};

export const getFormValueByElementType = (type: IFormElement['type']) => {
  switch (type) {
  case 'boolean':
    return true;
  case 'number':
    return 0;
  case 'string':
    return '';
  case 'array':
    return [];
  case 'object':
    return {};
  default:
    return '';
  }
};

export type ISetModal = (key: string, visible: ModalProps['visible']) => void;
export type IModals = Record<string, ModalProps['visible']>;
export type TOnError = (errors: Array<string>) => void;
export interface IFormItemsInputProps {
  form: IFormElements;
  json_form: JSONForm;
  options: IFormOptions;
  previews: IFormPreviews;
  components: IForm['components'];
  additionalParams: IForm['additionalParams'];
  inheritedAdditionalParams?: IFormAdditionalParams | TypeFormAdditionalParamsFunction;
  antForm: FormInstance;
  isSubmitted?: boolean;
  name: Array<number | string>;
  path: Array<number | string>;
  setModal: ISetModal;
  modals: IModals;
  onValuesChange?: FormProps['onValuesChange'];
  hideLabel?: boolean;
  labelProps?: any;
  entity: any;
  t: TFunction;
  AudioRecorder: IFormItemsProps['AudioRecorder'];
  addGlobalPopupModal: IFormItemsProps['addGlobalPopupModal'];
  advanced?: boolean;
  displayAdvanced: boolean;
  labelRenderer?: IFormItemsProps['labelRenderer'];
}

export interface IFormItemsProps {
  label: string | ReactElement;
  labelRenderer?: ((label: string | ReactElement) => string | ReactElement);
  t: TFunction;
  element: ReactElement;
  params: IFormElements;
  display_errors: boolean;
  errors: Array<any>;
  options: any;
  //options: Nullable<Array<IFormElementOptionObject>>;
  row_style: React.CSSProperties;
  name: Array<string | number>;
  help: string;
  value: any;
  form_path: IFormElements['path'];
  path: Array<number | string>;
  isTypeahead: boolean;
  nullable: boolean;
  hidden: boolean;
  labelWidth?: number;
  required: boolean;
  setValueByPath: (str_path: undefined | string, value: any) => any;
  ui_type?: FormElementTypes;
  hideHelp?: IFormElements['hideHelp'];
  preFixText?: IFormElements['preFixText'];
  preFixTextNull?: IFormElements['preFixTextNull'];
  postFixText?: IFormElements['postFixText'];
  postFixTextNull?: IFormElements['postFixTextNull'];
  default?: IFormElements['default'];
  default_un_null?: IFormElements['default_un_null'];
  type: IFormElements['type'];
  modals: IModals;
  shouldUpdate: IFormElements['shouldUpdate'];
  functionParams: Array<any>;
  values: Record<string, any>;
  AudioRecorder: typeof AudioRecorder;
  addGlobalPopupModal?: (payload: IGlobalPopupModal) => void;
  advanced: boolean;
  displayAdvanced: boolean;
  list_delimiter?: IFormElementList['list_delimiter'];
  disabled?: IFormElements['disabled'];
  hasAdd: boolean;
}

export const FormItemProps = (props: IFormItemsInputProps): IFormItemsProps => {
  const { t, path, AudioRecorder, } = props;
  const name = getFieldName(props?.form?.path || '', props.path);
  let value = props.form.preview_value || (props?.form?.path) ? props.json_form.findValueFromPath(props.form.path) : props.json_form.getValues();

  if (typeof value === 'object' && value !== null)
    value = JSON.parse(JSON.stringify(value));

  let { element, options, params, additionalParams, string_path, shouldUpdate, functionParams, label, hasAdd, } = createFormField(t, props, props.form, name, value, AudioRecorder, props.addGlobalPopupModal);
  const form = { ...props.form, ...additionalParams, };
  const isTypeahead: boolean = form?.ui_type === FormElementTypes.TYPEAHEAD;
  const display_errors = props.form.path ? (props.isSubmitted || (props.form.ui_type !== FormElementTypes.SECTION && props.antForm.isFieldTouched(getFieldName(props.form.path as string))) || value !== undefined || [FormElementTypes.TIME, ].includes(form.ui_type as FormElementTypes)) : false;
  let errors = [
    ...(props.form.errors || []),
    //...customErrors
  ];

  if (!form.help)
    form.help = t(`${(string_path || '').toUpperCase()}_HELP`, '') || null;
  else
    form.help = t(form.help);

  if (isTypeahead) {
    props.form.items?.forEach(item => {
      if (item.errors)
        errors = [...errors, ...item.errors, ];

      if ((item as IFormElementArray).element?.errors)
        errors = [...errors, ...((item as IFormElementArray).element?.errors || []), ];
    });
  }

  // hide if only valid option is null
  if (props.form.valid?.length === 1 && props.form.valid?.[0] === null) {
    if (value !== null)
      props.json_form.setValueByPath(props.form.path, null);

    element = <></>;
  }

  // if (value === undefined)
  //   value = props.form.default;

  return {
    label,
    t,
    name,
    value,
    element,
    help: form.help,
    display_errors,
    row_style: form.row_style,
    options,
    errors,
    params,
    form_path: form.path,
    path,
    isTypeahead,
    nullable: form.nullable,
    hidden: props.form.hidden || params.hidden,
    labelWidth: form.labelWidth,
    hideHelp: !!form.hideHelp,
    required: !!props.form.required && props.form.default === undefined,
    setValueByPath: (str_path, value) => props.json_form.setValueByPath(str_path, value),
    ui_type: props.form.ui_type,
    preFixText: form.preFixText,
    preFixTextNull: form.preFixTextNull,
    postFixText: form.postFixText,
    postFixTextNull: form.postFixTextNull,
    default: form.default,
    default_un_null: form.default_un_null,
    type: form.type,
    modals: props.modals,
    shouldUpdate,
    functionParams,
    AudioRecorder,
    values: props.json_form.getValues(),
    displayAdvanced: props.displayAdvanced,
    advanced: form.advanced,
    list_delimiter: form.list_delimiter,
    addGlobalPopupModal: form.addGlobalPopupModal,
    disabled: form.disabled,
    hasAdd,
  };
};

export const stringifyValue = (value: any) => {
  try {
    return typeof value === 'object' && value !== null ? JSON.stringify(value) : value + '';
  } catch (e) {
    return '';
  }
};
