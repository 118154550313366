import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  ClientAddForm,
  ClientAddInitialValues,
  ClientComponents,
  ClientAdditionalParams,
  ClientFormOptions,
  onClientFormSaved,
  IClientFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateClient, PermissionEnum, } from 'atlas-shared';
import { createClient, fetchClient, getClientCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateClient> {}

const ClientFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateClient>>();

  const param_props: IClientFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
  };
  const formLayout = useMemo(() => ClientAddForm(values, param_props, t), [values, organizations, t, abilities, ]);
  const options: IFormOptions = useMemo(() => ClientFormOptions(values || ClientAddInitialValues, param_props, t), [values, organizations, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateClient, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateClient) => save(() => createClient(values).then(
    res => onSaved ? onSaved(res, navigate) : onClientFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchClient(+id)
        .then(({
          organization_id,
          title,
        }) => {
          setCloned({
            organization_id,
            title,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.Client, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={ClientComponents}
        additionalParams={ClientAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...ClientAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('CLIENT')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const ClientFormAddView = React.memo(withAdd(ClientFormAdd, getClientCreateSchema, ClientAddInitialValues));
