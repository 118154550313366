import { TariffActions, } from '@Store';
import { IPreviewTariff, } from 'atlas-shared';
import { fetchTariffs, } from '@Api';

export const actionFetchTariffs = () => {
  return async (dispatch) => {
    dispatch(actionLoadingTariffs());
    const tariffs = await fetchTariffs();

    dispatch(actionSetTariffs(tariffs));
    return tariffs;
  };
};

export const actionLoadingTariffs = () => ({
  type: TariffActions.LOADING,
  payload: [],
});

export const actionSetTariffs = (payload: Array<IPreviewTariff>) => ({
  type: TariffActions.SET_STATE,
  payload: payload,
});

export const actionAddTariff = (payload: IPreviewTariff) => ({
  type: TariffActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateTariff = (payload: IPreviewTariff) => ({
  type: TariffActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveTariff = (id: IPreviewTariff['id']) => ({
  type: TariffActions.REMOVE_STATE,
  payload: id,
});
