import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateOrganization, ISharedUpdateOrganization, ISharedOrganization, IPreviewOrganization, IPreviewVoiceAsterisk, IPreviewTariff, IPreviewPhonenumberMonthlyCost, IPreviewPhonenumberSetupCost, EnumOptions, InvoicePaymentOptionEnum, InvoicePeriodEnum, InvoiceDuePeriodEnum, InvoiceCurrencyEnum, InvoicePaymentInfoTemplateEnum, OrganizationDashboardReportingTypeEnum, InvoicePostAmountTypeEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface IOrganizationFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  voice_asterisks: Array<IPreviewVoiceAsterisk>;
  tariffs: Array<IPreviewTariff>;
  phonenumber_monthly_costs: Array<IPreviewPhonenumberMonthlyCost>;
  phonenumber_setup_costs: Array<IPreviewPhonenumberSetupCost>;
}

export const OrganizationFormOptions = (organization: ISharedCreateOrganization | ISharedOrganization, props: IOrganizationFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Organization, {})), 'organization_id', false), 'organization'),
  voice_asterisk_id: entitiesToOptions<IPreviewVoiceAsterisk>(props.voice_asterisks, 'voice_asterisk'),
  invoice_tariff_id: entitiesToOptions<IPreviewTariff>(props.tariffs, 'tariff'),
  invoice_phonenumber_monthly_cost_id: entitiesToOptions<IPreviewPhonenumberMonthlyCost>(props.phonenumber_monthly_costs, 'phonenumber_monthly_cost'),
  invoice_phonenumber_setup_cost_id: entitiesToOptions<IPreviewPhonenumberSetupCost>(props.phonenumber_setup_costs, 'phonenumber_setup_cost'),
  invoice_data_payment_option: EnumOptions('INVOICE_PAYMENT_OPTION', InvoicePaymentOptionEnum, t),
  invoice_data_period: EnumOptions('INVOICE_PERIOD', InvoicePeriodEnum, t),
  invoice_data_due_period_type: EnumOptions('INVOICE_DUE_PERIOD', InvoiceDuePeriodEnum, t),
  invoice_currency: EnumOptions('INVOICE_CURRENCY', InvoiceCurrencyEnum, t),
  invoice_payment_info_template: EnumOptions('INVOICE_PAYMENT_INFO_TEMPLATE', InvoicePaymentInfoTemplateEnum, t),
  invoice_dashboard_license_type: EnumOptions('ORGANIZATION_DASHBOARD_REPORTING_TYPE', OrganizationDashboardReportingTypeEnum, t),
  invoice_reporting_license_type: EnumOptions('ORGANIZATION_DASHBOARD_REPORTING_TYPE', OrganizationDashboardReportingTypeEnum, t),
  invoice_addons_invoice_post_addon_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_license_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_usage_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_all_license_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_voice_tariff_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_voice_usage_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_voice_numbers_monthly_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_voice_numbers_setup_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_voice_license_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_voice_recording_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_voice_recording_transcript_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_voice_recording_storage_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_email_usage_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_email_license_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_invoice_post_form_usage_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_ai_request_kb_assistant_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
  invoice_addons_text_to_voice_usage_amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
});

export const OrganizationComponents: IForm['components'] = {};
export const OrganizationAdditionalParams: (auth: IAuth, props: IOrganizationFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IOrganizationFormOptionsProps) => ({
  voice_asterisk_id: { hidden: !auth.is_superadmin, }, 
  invoice: { hidden: !auth.is_superadmin, }, 
  negative_balance_lock: { hidden: !auth.is_superadmin, }, 
  active: { hidden: !auth.is_superadmin, },
});

export const OrganizationEditForm = (organization: ISharedOrganization, props: IOrganizationFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `organization_edit_${organization.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'two_factor_auth', },
    { ref: 'voice_asterisk_id', },
    { ref: 'invoice', },
    { ref: 'max_inactivity_ready', },
    { ref: 'timezone', },
    { ref: 'negative_balance_lock', },
    { ref: 'active', },
  ],
});

export const OrganizationAddForm = (organization: ISharedCreateOrganization, props: IOrganizationFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'organization_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'two_factor_auth', },
    { ref: 'invoice', },
    { ref: 'max_inactivity_ready', },
    { ref: 'timezone', },
    { ref: 'negative_balance_lock', },
    { ref: 'active', },
  ],
});

export const OrganizationAddInitialValues: ISharedCreateOrganization = {} as ISharedCreateOrganization;

export const OrganizationEditInitialValues = (organization: ISharedOrganization): ISharedUpdateOrganization => ({
  title: organization.title,
  two_factor_auth: organization.two_factor_auth,
  voice_asterisk_id: organization.voice_asterisk_id,
  invoice: organization.invoice,
  max_inactivity_ready: organization.max_inactivity_ready,
  timezone: organization.timezone,
  negative_balance_lock: organization.negative_balance_lock,
  active: organization.active,
});

export const onOrganizationFormSaved = (t: TFunction, action: 'edit' | 'add', organization: ISharedOrganization, navigate: NavigateFunction): void => {
  navigate(onFormSaved('organizations'));
};
