import { TimeIntervalComponents, } from '../time_interval.form';
import { AtlasForm, FormElementTypes, FormLayoutTypes, IFormComponent, } from 'atlas-form';
import React, { useEffect, useMemo, useState, } from 'react';
import Joi from 'joi';
import moment from 'moment';
import { useTranslation, } from 'react-i18next';
import Title from 'antd/es/typography/Title';
import { Nullable, } from 'atlas-shared';
import { clone, } from '@Utils';

type FromTo = {
  from?: Nullable<number>;
  to?: Nullable<number>;
};

type FormType = {
  type: 'always' | 'custom';
  ranges: {
    time: {
      from?: Nullable<string>;
      to?: Nullable<string>;
    };
    week_days: FromTo;
    days: FromTo;
    months: FromTo;
  };
};

const JoiRange = () => {
  return Joi.object().keys({
    from: Joi.number().required().allow(null).default(null),
    to: Joi.number().min(Joi.ref('from')).allow(null).default(null).when(Joi.ref('from'), { is: Joi.valid(null), then: Joi.forbidden(), }),
  });
};

const always = '*,*,*,*';
const defaultRanges = {
  months: { from: null, to: null, },
  week_days: { from: null, to: null, },
  days: { from: null, to: null, },
  time: { from: null, to: null, },
};
const validators = Joi.object({
  type: Joi.string().valid('custom', 'always').default('custom'),
  ranges: Joi.object({
    time: Joi.object().keys({
      from: Joi.string().required().allow(null).default(null),
      to: Joi.string().when(Joi.ref('from'), { is: Joi.valid(null), then: Joi.forbidden(), otherwise: Joi.required(), }),
    }),
    week_days: JoiRange(),
    days: JoiRange(),
    months: JoiRange(),
  }).default(defaultRanges),
});

interface IProps {
  value: any;
  onChange: (interval?: string)=> void;
  // onChange: (data: {type: 'always'} | {type: 'custom', time_range:FromTo, week_days_range: FromTo, month_days_range: FromTo, month_range: FromTo})=> void
}

// interface IIntervalFormValues {
//   type: 'always' | 'custom';
//   ranges?: {
//     time: {from: Nullable<string>; to?: string;};
//     days: {from: Nullable<string>; to?: string;};
//     week_days: {from: Nullable<string>; to?: string;};
//     months: {from: Nullable<string>; to?: string;};
//   }
// }

export const FormComponentInterval: IFormComponent = (props: IProps) => {
  const { onChange, value: _value, } = props;
  const { t, } = useTranslation();

  const WEEK_DAYS = moment.weekdaysShort();
  const WEEK_DAYS_OPTIONS = WEEK_DAYS.map((day, i) => ({ key: i, title: day, }));
  const MONTH_DAYS = Array.from(Array(31).keys());
  const MONTH_DAYS_OPTIONS = MONTH_DAYS.map((day, i) => {
    const date = (day + 1) + '';
    const last = date.split('').pop();

    return { key: i + 1, title: t(`${date}${last === '1' ? 'st' : last === '2' && date !== '12' ? 'nd' : last === '3' && date !== '13' ? 'rd' : 'th'}`), };
  });
  // const MONTH_DAYS_OPTIONS2 = useMemo(() => MONTH_DAYS.map((day, i) => ({ key: i, title: String((day + 1)) })), [MONTH_DAYS]);
  const MONTHS = moment.monthsShort();
  const MONTHS_OPTIONS = MONTHS.map((month, i) => ({ key: i, title: month, }));
  const FORM_OPTIONS = useMemo(() => ({
    week_days: WEEK_DAYS_OPTIONS,
    months: MONTHS_OPTIONS,
  }), [MONTHS_OPTIONS, WEEK_DAYS_OPTIONS, ]);

  const [description, setDescription, ] = useState<string>('');
  const [initialValues, setInitialValues, ] = useState<{}>();

  useEffect(() => {
    const values = {
      type: (!_value || _value === always) ? 'always' : 'custom',
      ranges: clone(defaultRanges),
    } as FormType;

    const array_value = (_value || '*,*,*,*').split(',');

    if (values.type === 'custom') {
      ['time', 'week_days', 'days', 'months', ].map((s, i) => {
        values.ranges[s] = {} as any;
        if (array_value[i] === '*')
          values.ranges[s] = { from: null, to: null, };
        else {
          const options = FORM_OPTIONS[s];
          const [from = null, to = null, ] = array_value[i].split('-');

          values.ranges[s] = {
            from: from !== null ? options ? options.find(o => o.title.toLowerCase() === from)?.key : from : null,
            to: to !== null ? (options ? options.find(o => o.title.toLowerCase() === to)?.key : to) : null,
          };
        }
      });
    }

    setInitialValues(values);
  }, []);

  if (!initialValues)
    return <>Loading</>;

  return <div>
    <AtlasForm
      formLayout={{
        id: 'time_interval_form',
        ui_layout: FormLayoutTypes.HORIZONTAL,
      }}
      form={validators}
      additionalParams={{
        type: { hidden: true, },
        ranges: { ui_layout: FormLayoutTypes.HORIZONTAL, hideLabel: true, },
        ranges_days: { ui_layout: FormLayoutTypes.INLINE, },
        ranges_week_days: { ui_layout: FormLayoutTypes.INLINE, },
        ranges_week_days_from: { ui_type: FormElementTypes.SELECT, keep_default_sorting: true, },
        ranges_week_days_to: { ui_type: FormElementTypes.SELECT, keep_default_sorting: true, },
        ranges_days_from: { ui_type: FormElementTypes.SELECT, keep_default_sorting: true, },
        ranges_days_to: { ui_type: FormElementTypes.SELECT, keep_default_sorting: true, },
        ranges_months_from: { ui_type: FormElementTypes.SELECT, keep_default_sorting: true, },
        ranges_months_to: { ui_type: FormElementTypes.SELECT, keep_default_sorting: true, },
        ranges_months: { ui_layout: FormLayoutTypes.INLINE, },
        ranges_time: { ui_layout: FormLayoutTypes.INLINE, label: t('TIME_RANGE'), },
        ranges_time_from: { ui_type: FormElementTypes.TIME, },
        ranges_time_to: { ui_type: FormElementTypes.TIME, },
      }}
      options={{
        ranges_week_days_from: WEEK_DAYS_OPTIONS,
        ranges_week_days_to: (interval) => WEEK_DAYS_OPTIONS.filter(it => interval.ranges?.week_days?.from !== null && it.key > interval.ranges.week_days.from),
        ranges_days_from: MONTH_DAYS_OPTIONS,
        ranges_days_to: (interval) => MONTH_DAYS_OPTIONS.filter(it => {
          return interval.ranges?.days?.from && it.key > interval.ranges.days.from;
        }),
        ranges_months_from: MONTHS_OPTIONS,
        ranges_months_to: (interval) => MONTHS_OPTIONS.filter(it => interval.ranges?.months?.from !== null && it.key > interval.ranges.months.from),
      }}
      components={TimeIntervalComponents}
      onValuesChange={(changedValues, values: FormType) => {

        const result = validators.validate(values);

        if (result.error) {
          setDescription(t('INVALID_INTERVAL'));
          return onChange(undefined);
        }

        const { ranges = defaultRanges, } = values;

        const interval = ['time', 'week_days', 'days', 'months', ].map(s => {
          const options = {
            week_days: WEEK_DAYS_OPTIONS,
            months: MONTHS_OPTIONS,
          }[s];

          let from = ranges[s].from;
          let to = from !== null ? ranges[s].to : null;

          if (options) {
            from = options.find(o => (o.key + '') === (ranges[s].from + ''))?.title;
            if (to)
              to = options.find(o => (o.key + '') === (ranges[s].to + ''))?.title;
          }

          return `${from || '*'}${to ? `-${to}` : ''}`.toLowerCase();
        }).join(',');

        const description_array = [
          ranges.months.from === null ? undefined : t(ranges.months.to ? 'FROM_THROUGH_TO' : 'ON_FROM', {
            replace: {
              from: MONTHS_OPTIONS.find(o => o.key === (ranges.months.from || 0))?.title,
              to: MONTHS_OPTIONS.find(o => o.key === (ranges.months.to || 0))?.title,
            },
          }),

          ranges.week_days.from === null && ranges.days.from === null
            ? t('EVERY_DAY')
            : [
              ranges.week_days.from !== null ? t(ranges.week_days.to ? 'FROM_THROUGH_TO' : 'EVERY_SPECIFIC_DAY', {
                replace: {
                  from: WEEK_DAYS_OPTIONS.find(o => o.key === (ranges.week_days.from || 0))?.title,
                  to: WEEK_DAYS_OPTIONS.find(o => o.key === (ranges.week_days.to || 0))?.title,
                },
              }) : undefined,
              ranges.days.from !== null ? t(ranges.days.to ? 'FROM_THROUGH_TO_OF_MONTH' : 'ON_FROM_OF_MONTH', {
                replace: {
                  from: MONTH_DAYS_OPTIONS.find(o => o.key === (ranges.days.from || 0))?.title,
                  to: MONTH_DAYS_OPTIONS.find(o => o.key === (ranges.days.to || 0))?.title,
                },
              }) : undefined,
            ],
          ranges.time.from === null ? t('ALL_DAY') : t('BETWEEN_FROM_AND_TO', {
            replace: {
              from: ranges.time?.from,
              to: ranges.time?.to,
            },
          }),
        ];

        setDescription(description_array.flat().filter(Boolean).join(', '));
        onChange(interval);

      }}
      initialValues={initialValues}
      submitBtn={false}
    />
    {description && <Title level={5} style={{ fontWeight: 'lighter', opacity: .7, }}>{description}</Title>}
  </div>;
};
