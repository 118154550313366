import {
  IJourneyActionPropsResponse as IJourneyActionPropsResponseBase, Nullable,
} from 'atlas-shared';
import { IForm, IFormOptions, } from 'atlas-form';

// T extends IJourneyPayloadNodesKeyPortsKeyProperty['properties']
export const settingsFormSchema = (properties?: Partial<IForm>) => {
  
  const elements = [
    { ref: 'title', },
    { ref: 'description', },
  ];

  //if (properties)
  elements.push(
    {
      ref: 'properties',
      ...(properties || {}),
    }
  );

  return { elements, };
};

export interface IJourneyActionProps extends IJourneyActionPropsResponseBase {
  settings_form?: Nullable<Partial<IForm>>;
  additionalParams?: IForm['additionalParams'];
  options?: IFormOptions;
}

export interface IJourneyActionPropsResponse extends IJourneyActionPropsResponseBase {
  settings_form: Nullable<Partial<IForm>>;
  additionalParams: IForm['additionalParams'];
  components: IForm['components'];
  options: IFormOptions;
}

export const journeyAction = (props: IJourneyActionProps): IJourneyActionPropsResponse => {
  return {
    settings_form: settingsFormSchema(),
    additionalParams: {},
    options: {},
    components: {},
    ...props,
  };
};
