import { journeyAction, settingsFormSchema, } from './base';

import { JourneyActionVoiceGetDigits as JourneyActionVoiceGetDigitsBase, } from 'atlas-shared';

export const JourneyActionVoiceGetDigits = journeyAction({
  ...JourneyActionVoiceGetDigitsBase,
  settings_form: settingsFormSchema({
    elements: [
      { ref: 'asterisk_sound_id', },
      { ref: 'journey_variable_id', },
      { ref: 'max_digits', },
      { ref: 'retries', },
      { ref: 'timeout', },
    ],
  }),
  additionalParams: {
    properties_timeout: { postFixText: 'MILLISECONDS', },
    properties_retries: { postFixText: 'TIMES_TO_RETRY', },
  },
  icon: 'Numbers',
});
