import {
  ISharedCreateOperationalHours,
  ISharedOperationalHours, TimezoneMapOptions,
} from 'atlas-shared';
import { TFunction, } from 'i18next';
import { IFormOptions, } from 'atlas-form';
import { IOperationalHoursFormOptionsProps, OperationalHoursFormOptions as OperationalHoursFormOptionsBase, } from './abstract/operational_hours.form.abstract';

export * from './abstract/operational_hours.form.abstract';

export const OperationalHoursFormOptions = (operational_hours: ISharedCreateOperationalHours | ISharedOperationalHours, props: IOperationalHoursFormOptionsProps, t: TFunction): IFormOptions => ({
  ...OperationalHoursFormOptionsBase(operational_hours, props, t),
  timezone: TimezoneMapOptions,
});
