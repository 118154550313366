import { findMetricByAlias, ISharedNotification, } from 'atlas-shared/dist';
import { TFunction, } from 'i18next';

export const generateText = (t: TFunction, notification: ISharedNotification) => {
  return (notification.payload?.c || []).map(({ a, c, v, }) => {
    const metric = findMetricByAlias(a);

    return <div>
      <strong>{metric?.title}</strong>&nbsp;
      {t('MATCHED_CONDITION')}&nbsp;
      &quot;<strong style={{ fontStyle: 'italic', }}>{t(c.toString().toUpperCase())}</strong>&quot;&nbsp;
      {t('BY')} {t(Array.isArray(v) ? 'VALUES' : 'VALUE').toLowerCase()}&nbsp;
      <strong>{v}</strong>.
    </div>;
  });
};
