import { IPreviewStatsReportWidget, } from 'atlas-shared';

export enum StatsReportWidgetActions {
  SET_STATE = 'STATS_REPORT_WIDGET_SET_STATE',
  ADD_STATE = 'STATS_REPORT_WIDGET_ADD_STATE',
  REMOVE_STATE = 'STATS_REPORT_WIDGET_REMOVE_STATE',
  GET_STATE = 'STATS_REPORT_WIDGET_GET_STATE',
  UPDATE_STATE = 'STATS_REPORT_WIDGET_UPDATE_STATE',
  LOADING = 'STATS_REPORT_WIDGET_LOADING',
}

export interface IStatsReportWidgetLoading {
  type: StatsReportWidgetActions.LOADING;
}

export interface IStatsReportWidgetSetAction {
  type: StatsReportWidgetActions.SET_STATE;
  payload: Array<IPreviewStatsReportWidget>;
}

export interface IStatsReportWidgetUpdateAction {
  type: StatsReportWidgetActions.UPDATE_STATE;
  payload: IPreviewStatsReportWidget;
}

export interface IStatsReportWidgetAddAction {
  type: StatsReportWidgetActions.ADD_STATE;
  payload: IPreviewStatsReportWidget;
}

export interface IStatsReportWidgetRemoveAction {
  type: StatsReportWidgetActions.REMOVE_STATE;
  payload: IPreviewStatsReportWidget['id'];
}

export interface IStatsReportWidgetStore {
  loaded: boolean;
  loading: boolean;
  stats_report_widgets: Array<IPreviewStatsReportWidget>;
  dict: Record<IPreviewStatsReportWidget['id'], IPreviewStatsReportWidget>;
}

export type TStatsReportWidgetActionTypes = IStatsReportWidgetSetAction | IStatsReportWidgetUpdateAction | IStatsReportWidgetAddAction | IStatsReportWidgetRemoveAction | IStatsReportWidgetLoading;
