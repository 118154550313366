import React from 'react';
import { TQueueCallSuggestion, TVoiceSuggestionOnSelect, } from './voice-suggestion.utils';
import { InboxIcon, } from '@Assets/icons';
import { IconText, } from '@Components';
import { IAuth, IVoiceAsteriskStore, } from '@Store';

interface IProps {
  auth: IAuth;
  voice_asterisks: IVoiceAsteriskStore;
  onSelect: TVoiceSuggestionOnSelect;
  queue: TQueueCallSuggestion;
  callBtn: React.ReactNode;
}

export const VoiceSuggestionQueue = React.memo(({ auth, voice_asterisks, queue, onSelect, callBtn, }: IProps) => {

  return <>
    <div className={`li-content${queue.selectable ? ' clickable' : ''}`} onClick={() => {
      if (queue.selectable) {

        onSelect([{
          title: queue.title,
          voice_asterisk_id: -1,
          numbers: [`q-${queue.id}`, ],
        }, ], true);
      }
    }}>
      <IconText icon={InboxIcon} text={queue.title} />
      {callBtn}
    </div>
  </>;
});
