import { IPreviewSlaPolicy, } from 'atlas-shared';
import { ISlaPolicyStore, SlaPolicyActions, TSlaPolicyActionTypes, } from '@Store';

export const SlaPolicyReducer = (state: ISlaPolicyStore = {
  loaded: false,
  loading: false,
  sla_policies: [],
  dict: {},
}, action: TSlaPolicyActionTypes): ISlaPolicyStore => {
  switch (action.type) {
  case SlaPolicyActions.LOADING:
    return { ...state, loading: true, };
  case SlaPolicyActions.SET_STATE:
    const dict: ISlaPolicyStore['dict'] = {};

    action.payload.forEach((sla_policy: IPreviewSlaPolicy) => dict[sla_policy.id] = sla_policy);

    return { ...state, loaded: true, loading: false, sla_policies: action.payload, dict, };
  case SlaPolicyActions.ADD_STATE:
    return { ...state, sla_policies: [...state.sla_policies, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case SlaPolicyActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, sla_policies: state.sla_policies.filter((item: IPreviewSlaPolicy) => item.id !== action.payload), dict, };
  }
  case SlaPolicyActions.UPDATE_STATE:
    const sla_policies = [...state.sla_policies, ];
    const index = state.sla_policies.findIndex((sla_policy: IPreviewSlaPolicy) => sla_policy.id === action.payload.id);

    if (!~index)
      sla_policies.push(action.payload);
    else
      sla_policies[index] = action.payload;

    return { ...state, sla_policies, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
