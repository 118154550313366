import { QueueActions, } from '@Store';
import { IPreviewQueue, } from 'atlas-shared';
import { fetchQueues, } from '@Api';

export const actionFetchQueues = () => {
  return async (dispatch) => {
    dispatch(actionLoadingQueues());
    const queues = await fetchQueues();

    dispatch(actionSetQueues(queues));
    return queues;
  };
};

export const actionLoadingQueues = () => ({
  type: QueueActions.LOADING,
  payload: [],
});

export const actionSetQueues = (payload: Array<IPreviewQueue>) => ({
  type: QueueActions.SET_STATE,
  payload: payload,
});

export const actionAddQueue = (payload: IPreviewQueue) => ({
  type: QueueActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateQueue = (payload: IPreviewQueue) => ({
  type: QueueActions.UPDATE_STATE,
  payload: payload,
});
