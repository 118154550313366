import { IPreviewCannedAnswer, } from 'atlas-shared';

export enum CannedAnswerActions {
  SET_STATE = 'CANNED_ANSWER_SET_STATE',
  ADD_STATE = 'CANNED_ANSWER_ADD_STATE',
  REMOVE_STATE = 'CANNED_ANSWER_REMOVE_STATE',
  GET_STATE = 'CANNED_ANSWER_GET_STATE',
  UPDATE_STATE = 'CANNED_ANSWER_UPDATE_STATE',
  LOADING = 'CANNED_ANSWER_LOADING',
}

export interface ICannedAnswerLoading {
  type: CannedAnswerActions.LOADING;
}

export interface ICannedAnswerSetAction {
  type: CannedAnswerActions.SET_STATE;
  payload: Array<IPreviewCannedAnswer>;
}

export interface ICannedAnswerUpdateAction {
  type: CannedAnswerActions.UPDATE_STATE;
  payload: IPreviewCannedAnswer;
}

export interface ICannedAnswerAddAction {
  type: CannedAnswerActions.ADD_STATE;
  payload: IPreviewCannedAnswer;
}

export interface ICannedAnswerRemoveAction {
  type: CannedAnswerActions.REMOVE_STATE;
  payload: IPreviewCannedAnswer['id'];
}

export interface ICannedAnswerStore {
  loaded: boolean;
  loading: boolean;
  canned_answers: Array<IPreviewCannedAnswer>;
  dict: Record<IPreviewCannedAnswer['id'], IPreviewCannedAnswer>;
}

export type TCannedAnswerActionTypes = ICannedAnswerSetAction | ICannedAnswerUpdateAction | ICannedAnswerAddAction | ICannedAnswerRemoveAction | ICannedAnswerLoading;
