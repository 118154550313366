import { JourneyVariableActions, } from '@Store';
import { IPreviewJourneyVariable, } from 'atlas-shared';
import { fetchJourneyVariables, } from '@Api';

export const actionFetchJourneyVariables = () => {
  return async (dispatch) => {
    dispatch(actionLoadingJourneyVariables());
    const journey_variables = await fetchJourneyVariables();

    dispatch(actionSetJourneyVariables(journey_variables));
    return journey_variables;
  };
};

export const actionLoadingJourneyVariables = () => ({
  type: JourneyVariableActions.LOADING,
  payload: [],
});

export const actionSetJourneyVariables = (payload: Array<IPreviewJourneyVariable>) => ({
  type: JourneyVariableActions.SET_STATE,
  payload: payload,
});

export const actionAddJourneyVariable = (payload: IPreviewJourneyVariable) => ({
  type: JourneyVariableActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateJourneyVariable = (payload: IPreviewJourneyVariable) => ({
  type: JourneyVariableActions.UPDATE_STATE,
  payload: payload,
});
