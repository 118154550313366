import { IPreviewPhonenumberSetupCost, } from 'atlas-shared';
import { IPhonenumberSetupCostStore, PhonenumberSetupCostActions, TPhonenumberSetupCostActionTypes, } from '@Store';

export const PhonenumberSetupCostReducer = (state: IPhonenumberSetupCostStore = {
  loaded: false,
  loading: false,
  phonenumber_setup_costs: [],
  dict: {},
}, action: TPhonenumberSetupCostActionTypes): IPhonenumberSetupCostStore => {
  switch (action.type) {
  case PhonenumberSetupCostActions.LOADING:
    return { ...state, loading: true, };
  case PhonenumberSetupCostActions.SET_STATE:
    const dict: IPhonenumberSetupCostStore['dict'] = {};

    action.payload.forEach((phonenumber_setup_cost: IPreviewPhonenumberSetupCost) => dict[phonenumber_setup_cost.id] = phonenumber_setup_cost);

    return { ...state, loaded: true, loading: false, phonenumber_setup_costs: action.payload, dict, };
  case PhonenumberSetupCostActions.ADD_STATE:
    return { ...state, phonenumber_setup_costs: [...state.phonenumber_setup_costs, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case PhonenumberSetupCostActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, phonenumber_setup_costs: state.phonenumber_setup_costs.filter((item: IPreviewPhonenumberSetupCost) => item.id !== action.payload), dict, };
  }
  case PhonenumberSetupCostActions.UPDATE_STATE:
    const phonenumber_setup_costs = [...state.phonenumber_setup_costs, ];
    const index = state.phonenumber_setup_costs.findIndex((phonenumber_setup_cost: IPreviewPhonenumberSetupCost) => phonenumber_setup_cost.id === action.payload.id);

    if (!~index)
      phonenumber_setup_costs.push(action.payload);
    else
      phonenumber_setup_costs[index] = action.payload;

    return { ...state, phonenumber_setup_costs, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
