import { StatsReportWidgetActions, } from '@Store';
import { IPreviewStatsReportWidget, } from 'atlas-shared';
import { fetchStatsReportWidgets, } from '@Api';

export const actionFetchStatsReportWidgets = () => {
  return async (dispatch) => {
    dispatch(actionLoadingStatsReportWidgets());
    const stats_report_widgets = await fetchStatsReportWidgets();

    dispatch(actionSetStatsReportWidgets(stats_report_widgets));
    return stats_report_widgets;
  };
};

export const actionLoadingStatsReportWidgets = () => ({
  type: StatsReportWidgetActions.LOADING,
  payload: [],
});

export const actionSetStatsReportWidgets = (payload: Array<IPreviewStatsReportWidget>) => ({
  type: StatsReportWidgetActions.SET_STATE,
  payload: payload,
});

export const actionAddStatsReportWidget = (payload: IPreviewStatsReportWidget) => ({
  type: StatsReportWidgetActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateStatsReportWidget = (payload: IPreviewStatsReportWidget) => ({
  type: StatsReportWidgetActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveStatsReportWidget = (id: IPreviewStatsReportWidget['id']) => ({
  type: StatsReportWidgetActions.REMOVE_STATE,
  payload: id,
});
