import { IPreviewOperationalHours, } from 'atlas-shared';

export enum OperationalHoursActions {
  SET_STATE = 'OPERATIONAL_HOURS_SET_STATE',
  ADD_STATE = 'OPERATIONAL_HOURS_ADD_STATE',
  REMOVE_STATE = 'OPERATIONAL_HOURS_REMOVE_STATE',
  GET_STATE = 'OPERATIONAL_HOURS_GET_STATE',
  UPDATE_STATE = 'OPERATIONAL_HOURS_UPDATE_STATE',
  LOADING = 'OPERATIONAL_HOURS_LOADING',
}

export interface IOperationalHoursLoading {
  type: OperationalHoursActions.LOADING;
}

export interface IOperationalHoursSetAction {
  type: OperationalHoursActions.SET_STATE;
  payload: Array<IPreviewOperationalHours>;
}

export interface IOperationalHoursUpdateAction {
  type: OperationalHoursActions.UPDATE_STATE;
  payload: IPreviewOperationalHours;
}

export interface IOperationalHoursAddAction {
  type: OperationalHoursActions.ADD_STATE;
  payload: IPreviewOperationalHours;
}

export interface IOperationalHoursRemoveAction {
  type: OperationalHoursActions.REMOVE_STATE;
  payload: IPreviewOperationalHours['id'];
}

export interface IOperationalHoursStore {
  loaded: boolean;
  loading: boolean;
  operational_hourses: Array<IPreviewOperationalHours>;
  dict: Record<IPreviewOperationalHours['id'], IPreviewOperationalHours>;
}

export type TOperationalHoursActionTypes = IOperationalHoursSetAction | IOperationalHoursUpdateAction | IOperationalHoursAddAction | IOperationalHoursRemoveAction | IOperationalHoursLoading;
