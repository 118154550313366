import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchPhonenumberPurchases, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const usePhonenumberPurchases = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const phonenumber_purchases = useSelector((store: IReducerStore) => store.phonenumber_purchases);

  useEffect(() => {
    if (!initiated && autoload && !phonenumber_purchases.loaded && !phonenumber_purchases.loading) {
      initiated = true;
      dispatch(actionFetchPhonenumberPurchases());
    }
  }, [autoload, phonenumber_purchases.loaded, phonenumber_purchases.loading, dispatch, ]);

  return phonenumber_purchases;
};
