import { IAuth, } from '@Store';
import { FormElementTypes, FormLayoutTypes, IForm, } from 'atlas-form';
import { IDestinationRestrictorFormOptionsProps, } from '@Utils/forms/abstract/destination_restrictor.form.abstract';
import { DestinationRestrictorAdditionalParams as DestinationRestrictorAdditionalParamsBase, } from './abstract/destination_restrictor.form.abstract';
import { CountryFlagTitle, } from '@Utils';

export * from './abstract/destination_restrictor.form.abstract';

export const DestinationRestrictorAdditionalParams: (auth: IAuth, props: IDestinationRestrictorFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IDestinationRestrictorFormOptionsProps) => ({
  ...DestinationRestrictorAdditionalParamsBase(auth, props),
  destinations: {
    ui_type: FormElementTypes.SECTION,
    child_params: {
      ui_type: FormElementTypes.SECTION,
      ui_layout: FormLayoutTypes.INLINE,
      labelRenderer: label => <CountryFlagTitle t={props.t} country={label as string} width={21} />,
      child_params: {
        //hideLabel: true
      },
    },
  },
});
