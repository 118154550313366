import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchPrepaidCredits, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const usePrepaidCredits = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const prepaid_credits = useSelector((store: IReducerStore) => store.prepaid_credits);

  useEffect(() => {
    if (!initiated && autoload && !prepaid_credits.loaded && !prepaid_credits.loading) {
      initiated = true;
      dispatch(actionFetchPrepaidCredits());
    }
  }, [autoload, prepaid_credits.loaded, prepaid_credits.loading, dispatch, ]);

  return prepaid_credits;
};
