import React, { useEffect, useState, } from 'react';
import { timeSince, } from '@Utils';
import { TFunction, } from 'i18next';
import { IPreviewDraft, } from 'atlas-shared/dist';

interface IProps {
  t: TFunction;
  since: IPreviewDraft['updated_at'];
}

export const ConversationMessageReplyTimer = React.memo(({ since, t, }: IProps) => {
  const [updatedSince, setUpdatedSince, ] = useState<string>('');

  useEffect(() => {

    const interval = setInterval(() => {
      setUpdatedSince(timeSince(t, new Date(since), true, true));
    }, 1000);

    return () => clearInterval(interval);

  }, [since, t, ]);
  
  return <div>{updatedSince}</div>;
});
