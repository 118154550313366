import { IPreviewOrganization, ISharedOrganization, ISharedUser, } from 'atlas-shared';
import { IOrganizationStore, store, } from '@Store';
import { entityToOption, IOptionEntity, } from './options';
import { isSuperAdmin, } from '@Utils/user';

export const areRelated = (src_organization: IPreviewOrganization, dst_type: string, src_type: string, item: any) => {

  if (!src_organization)
    return false;

  const organizations: IOrganizationStore = store.getState().organizations;
  const dst_organization = dst_type === 'team' ? organizations.organizations.find(o => o.id === item.organization_id) : null;

  if (dst_type === 'organization')
    return src_organization.id === item.id || src_organization.children.includes(item.id);

  else if (dst_type === 'journey' && ['mail_account', 'voice_route', 'form_website', ].includes(src_type))
    return src_organization.id === item.organization_id;

  else if (dst_type === 'team') {
    if (!dst_organization)
      return false;

    return [dst_organization.id, ...dst_organization.children, ].includes(src_organization.id);

  }

  else if (dst_type === 'user')
    return !isSuperAdmin(item as ISharedUser) && (item as ISharedUser).__organizations.some(o => [...(item as ISharedUser).__admin_organization_ids, ...(item as ISharedUser).__agent_organization_ids, ].includes(src_organization.id));
  else if (dst_type === 'user_reverse') {
    const src_organization_ids = [src_organization.id, ...src_organization.children, ];

    return !isSuperAdmin(item as ISharedUser) && [...(item as ISharedUser).__admin_organization_ids, ...(item as ISharedUser).__agent_organization_ids, ].some(oid => src_organization_ids.includes(oid));
  }
  else
    return [src_organization.id, ...src_organization.children, ].includes(item.organization_id);

};

export const filterRelated = <T extends any>(src_organization_id: ISharedOrganization['id'], dst_type: string, src_type: string, items: Array<T>, toOption: boolean = false, customFilter?: (item: T) => boolean, titleOrganization: boolean = false): Array<any> => {
  const response: Array<any> = [];

  if (!src_organization_id)
    return response;
    //return items.filter(item => !item.deleted_at);

  const organizations: IOrganizationStore = store.getState().organizations;
  const organization = src_organization_id ? organizations.dict[src_organization_id] : null;

  if (!organization)
    return response;

  items.forEach(item => {
    if (areRelated(organization, dst_type, src_type, item) && (!customFilter || customFilter(item)))
      response.push(toOption ? entityToOption(item as IOptionEntity, dst_type, titleOrganization ? organizations.dict[(item as any).organization_id] : undefined) : item);
  });

  return response;
};
