import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateSkill, ISharedUpdateSkill, ISharedSkill, IPreviewOrganization, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface ISkillFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const SkillFormOptions = (skill: ISharedCreateSkill | ISharedSkill, props: ISkillFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Skill, {})), 'organization_id', false), 'organization'),
  
});

export const SkillComponents: IForm['components'] = {};
export const SkillAdditionalParams: (auth: IAuth, props: ISkillFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ISkillFormOptionsProps) => ({
});

export const SkillEditForm = (skill: ISharedSkill, props: ISkillFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `skill_edit_${skill.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === skill.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'description', },
  ],
});

export const SkillAddForm = (skill: ISharedCreateSkill, props: ISkillFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'skill_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'description', },
  ],
});

export const SkillAddInitialValues: ISharedCreateSkill = {} as ISharedCreateSkill;

export const SkillEditInitialValues = (skill: ISharedSkill): ISharedUpdateSkill => ({
  title: skill.title,
  description: skill.description,
});

export const onSkillFormSaved = (t: TFunction, action: 'edit' | 'add', skill: ISharedSkill, navigate: NavigateFunction): void => {
  navigate(onFormSaved('skills'));
};
