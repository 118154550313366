import { RestRequest, } from '@Utils';
import { IPreviewMailAccount, ISharedMailAccount, ISharedCreateMailAccount, ISharedPatchMailAccount, } from 'atlas-shared';

export const fetchMailAccounts = async (): Promise<Array<IPreviewMailAccount>> => {
  return RestRequest.get<Array<IPreviewMailAccount>>('mail_account', { socket_id: global.socketId, });
};

export const fetchMailAccount = async (id: IPreviewMailAccount['id']): Promise<ISharedMailAccount> => {
  return RestRequest.get<ISharedMailAccount>(`mail_account/${id}`, { socket_id: global.socketId, });
};

export const createMailAccount = (payload: ISharedCreateMailAccount) => {
  return RestRequest.post<any, ISharedMailAccount>('mail_account', payload, { socket_id: global.socketId, });
};

export const patchMailAccount = (id: IPreviewMailAccount['id'], payload: ISharedPatchMailAccount) => {
  return RestRequest.patch<any, ISharedMailAccount>(`mail_account/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteMailAccount = (id: IPreviewMailAccount['id']) => {
  return RestRequest.delete(`mail_account/${id}`, { socket_id: global.socketId, });
};

export const getMailAccountUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('mail_account/update/schema');
};

export const getMailAccountCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('mail_account/create/schema');
};

export const getMailAccountPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('mail_account/patch/schema');
};
