import { IPreviewField, Nullable, TRequired, } from 'atlas-shared';
import { Table, } from 'antd';
import { useEffect, useState, } from 'react';
import { useFields, } from '@Hooks';
import { ColumnType, } from 'antd/es/table';
import { FormSelect, CustomFormItemProps, IFormComponent, } from 'atlas-form';
import { XIcon, } from '@Assets/icons';
import { Icon, } from '@Components';
import './list-fields.scss';
import { getListPreview, } from '@Api';
import { useTranslation, } from 'react-i18next';

export const FormComponentListFields: IFormComponent = ({ onChange, value, data, options, }: TRequired<CustomFormItemProps>) => {
  
  const [columns, setColumns, ] = useState<Array<ColumnType<any>>>([]);
  const [rows, setRows, ] = useState<Array<any>>([]);
  const [selectedFields, setSelectedFields, ] = useState<Array<Nullable<IPreviewField>>>(value);
  const fields = useFields();
  const { t, } = useTranslation();
  
  useEffect(() => {
    getListPreview(data[4].id).then(sheets => setRows(sheets.map((sheet, sheet_index) => sheet.map((row, row_index) => ({
      ...row,
      sheet: t('SHEET_N', { replace: { n: sheet_index + 1, }, }),
      row: t('ROW_N', { replace: { n: row_index + 1, }, }),
    }))).flat()));
  }, [data, t, ]);

  useEffect(() => {
    if (fields.loaded)
      setSelectedFields(
        [
          ...(value.length
            ? value.map(field_id => fields.dict[field_id])
            : fields.fields.filter(field => field.organization_id === data[4].organization_id && ['__contact_first_name', '__contact_last_name', '__contact_emails', '__contact_phones', ].includes(field.alias))),
          null,
        ]
      );
  }, [fields, data, value, ]);

  useEffect(() => {
    console.log('selectedFields', selectedFields);
    setColumns([
      {
        dataIndex: 'sheet',
        title: t('SHEET'),
      },
      {
        dataIndex: 'row',
        title: t('ROW'),
      },
      ...selectedFields.map((field, index) => ({
        dataIndex: String.fromCharCode(65 + index),
        title: <div>
          <FormSelect 
            options={options} 
            value={field?.id || null} 
            onChange={selected => {
              const _selectedFields = selectedFields.map((field, i) => i === index ? fields.dict[selected] : field);

              if (index === selectedFields.length - 1)
                _selectedFields.push(null);

              setSelectedFields(_selectedFields);
            }}
          />
          { field && <Icon
            icon={XIcon}
            className={'remove-btn'}
            onClick={() => {
              const _selectedFields = [...selectedFields, ];

              _selectedFields.splice(index, 1);
              setSelectedFields(_selectedFields);
            }}
          /> }
        </div>,
      })),
    ]);
    onChange(selectedFields.filter(Boolean).map(field => field?.id));
  }, [selectedFields, fields.dict, onChange, options, t, ]);

  console.log('columns', rows);

  return <Table
    columns={columns} 
    dataSource={rows}
    className={'list-fields'}
    scroll={{ x: 660, }}
  />;
};
