import { RestRequest, } from '@Utils';
import { IPreviewGdpr, ISharedGdpr, ISharedPatchGdpr, } from 'atlas-shared';

export const fetchGdprs = async (): Promise<Array<IPreviewGdpr>> => {
  return RestRequest.get<Array<IPreviewGdpr>>('gdpr', { socket_id: global.socketId, });
};

export const fetchGdpr = async (id: IPreviewGdpr['id']): Promise<ISharedGdpr> => {
  return RestRequest.get<ISharedGdpr>(`gdpr/${id}`, { socket_id: global.socketId, });
};

export const patchGdpr = (id: IPreviewGdpr['id'], payload: ISharedPatchGdpr) => {
  return RestRequest.patch<any, ISharedGdpr>(`gdpr/${id}`, payload, { socket_id: global.socketId, });
};

export const getGdprUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('gdpr/update/schema');
};

export const getGdprPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('gdpr/patch/schema');
};
