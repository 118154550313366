import { IAuth, } from '@Store';
import { IForm, } from '@Modules/atlas-form-core';
import { IWallboardFormOptionsProps, WallboardAdditionalParams as WallboardAdditionalParamsBase, } from './abstract/wallboard.form.abstract';

export * from './abstract/wallboard.form.abstract';

export const WallboardAdditionalParams: (auth: IAuth, props: IWallboardFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IWallboardFormOptionsProps) => ({
  ...WallboardAdditionalParamsBase(auth, props),
  share_data: {
    help: 'SHARE_DATA_DESCRIPTION',
  },
});
