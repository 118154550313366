import { IAuth, } from '@Store';
import { IForm, IFormOptions, } from '@Modules/atlas-form-core';
import {
  ISoundFormOptionsProps,
  SoundAdditionalParams as SoundAdditionalParamsBase,
  SoundComponents as SoundComponentsBase,
  SoundFormOptions as SoundFormOptionsBase,
} from './abstract/sound.form.abstract';
import { FormComponentElevenlabs, } from './components/elevenlabs';
import {
  ElevenlabsVoiceEnum,
  EnumOptionsKey,
  ISharedCreateSound,
  ISharedSound,
  SoundTypeEnum,
} from 'atlas-shared';
import { TFunction, } from 'i18next';

export * from './abstract/sound.form.abstract';

export const SoundAdditionalParams: (auth: IAuth, props: ISoundFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ISoundFormOptionsProps) => {
  const base = SoundAdditionalParamsBase(auth, props);

  return {
    ...base,
    filename: (it, b, c, d, sound: ISharedSound) => ({
      ...(base?.filename as any)?.(it, b, c, d, sound),
      hidden: it.type !== SoundTypeEnum.FileUpload,
    }),
  };
};

export const SoundComponents: IForm['components'] = {
  ...SoundComponentsBase,
  text: FormComponentElevenlabs,
};

export const SoundFormOptions = (sound: ISharedCreateSound | ISharedSound, props: ISoundFormOptionsProps, t: TFunction): IFormOptions => ({
  ...SoundFormOptionsBase(sound, props, t),
  elevenlabs_settings_voice: EnumOptionsKey('ELEVENLABS_VOICE', ElevenlabsVoiceEnum, t),
});
