import { IPreviewJourneyDraft, } from 'atlas-shared';
import { IJourneyDraftStore, JourneyDraftActions, TJourneyDraftActionTypes, } from '@Store';

export const JourneyDraftReducer = (state: IJourneyDraftStore = {
  loaded: false,
  loading: false,
  journey_drafts: [],
  dict: {},
}, action: TJourneyDraftActionTypes): IJourneyDraftStore => {
  switch (action.type) {
  case JourneyDraftActions.LOADING:
    return { ...state, loading: true, };
  case JourneyDraftActions.SET_STATE:
    const dict: IJourneyDraftStore['dict'] = {};

    action.payload.forEach((journey_draft: IPreviewJourneyDraft) => dict[journey_draft.id] = journey_draft);

    return { ...state, loaded: true, loading: false, journey_drafts: action.payload, dict, };
  case JourneyDraftActions.ADD_STATE:
    return { ...state, journey_drafts: [...state.journey_drafts, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case JourneyDraftActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, journey_drafts: state.journey_drafts.filter((item: IPreviewJourneyDraft) => item.id !== action.payload), dict, };
  }
  case JourneyDraftActions.UPDATE_STATE:
    const journey_drafts = [...state.journey_drafts, ];
    const index = state.journey_drafts.findIndex((journey_draft: IPreviewJourneyDraft) => journey_draft.id === action.payload.id);

    if (!~index)
      journey_drafts.push(action.payload);
    else
      journey_drafts[index] = action.payload;

    return { ...state, journey_drafts, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
