import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchVoiceAsterisks, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useVoiceAsterisks = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const voice_asterisks = useSelector((store: IReducerStore) => store.voice_asterisks);

  useEffect(() => {
    if (!initiated && autoload && !voice_asterisks.loaded && !voice_asterisks.loading) {
      initiated = true;
      dispatch(actionFetchVoiceAsterisks());
    }
  }, [autoload, voice_asterisks.loaded, voice_asterisks.loading, dispatch, ]);

  return voice_asterisks;
};
