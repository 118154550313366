import { RestRequest, } from '@Utils';
import { IPreviewWallboard, ISharedWallboard, ISharedCreateWallboard, ISharedPatchWallboard, } from 'atlas-shared';

export const fetchWallboards = async (): Promise<Array<IPreviewWallboard>> => {
  return RestRequest.get<Array<IPreviewWallboard>>('wallboard', { socket_id: global.socketId, });
};

export const fetchWallboard = async (id: IPreviewWallboard['id']): Promise<ISharedWallboard> => {
  return RestRequest.get<ISharedWallboard>(`wallboard/${id}`, { socket_id: global.socketId, });
};

export const createWallboard = (payload: ISharedCreateWallboard) => {
  return RestRequest.post<any, ISharedWallboard>('wallboard', payload, { socket_id: global.socketId, });
};

export const patchWallboard = (id: IPreviewWallboard['id'], payload: ISharedPatchWallboard) => {
  return RestRequest.patch<any, ISharedWallboard>(`wallboard/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteWallboard = (id: IPreviewWallboard['id']) => {
  return RestRequest.delete(`wallboard/${id}`, { socket_id: global.socketId, });
};

export const getWallboardUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('wallboard/update/schema');
};

export const getWallboardCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('wallboard/create/schema');
};

export const getWallboardPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('wallboard/patch/schema');
};
