import { IPreviewMailAccount, } from 'atlas-shared';

export enum MailAccountActions {
  SET_STATE = 'MAIL_ACCOUNT_SET_STATE',
  ADD_STATE = 'MAIL_ACCOUNT_ADD_STATE',
  REMOVE_STATE = 'MAIL_ACCOUNT_REMOVE_STATE',
  GET_STATE = 'MAIL_ACCOUNT_GET_STATE',
  UPDATE_STATE = 'MAIL_ACCOUNT_UPDATE_STATE',
  LOADING = 'MAIL_ACCOUNT_LOADING',
}

export interface IMailAccountLoading {
  type: MailAccountActions.LOADING;
}

export interface IMailAccountSetAction {
  type: MailAccountActions.SET_STATE;
  payload: Array<IPreviewMailAccount>;
}

export interface IMailAccountUpdateAction {
  type: MailAccountActions.UPDATE_STATE;
  payload: IPreviewMailAccount;
}

export interface IMailAccountAddAction {
  type: MailAccountActions.ADD_STATE;
  payload: IPreviewMailAccount;
}

export interface IMailAccountRemoveAction {
  type: MailAccountActions.REMOVE_STATE;
  payload: IPreviewMailAccount['id'];
}

export interface IMailAccountStore {
  loaded: boolean;
  loading: boolean;
  mail_accounts: Array<IPreviewMailAccount>;
  dict: Record<IPreviewMailAccount['id'], IPreviewMailAccount>;
}

export type TMailAccountActionTypes = IMailAccountSetAction | IMailAccountUpdateAction | IMailAccountAddAction | IMailAccountRemoveAction | IMailAccountLoading;
