import { RestRequest, } from '@Utils';
import { IPreviewStatsReportPreviewWidget, ISharedStatsReportPreviewWidget, ISharedCreateStatsReportPreviewWidget, ISharedPatchStatsReportPreviewWidget, } from 'atlas-shared';

export const fetchStatsReportPreviewWidgets = async (): Promise<Array<IPreviewStatsReportPreviewWidget>> => {
  return RestRequest.get<Array<IPreviewStatsReportPreviewWidget>>('stats_report_preview_widget', { socket_id: global.socketId, });
};

export const fetchStatsReportPreviewWidget = async (id: IPreviewStatsReportPreviewWidget['id']): Promise<ISharedStatsReportPreviewWidget> => {
  return RestRequest.get<ISharedStatsReportPreviewWidget>(`stats_report_preview_widget/${id}`, { socket_id: global.socketId, });
};

export const createStatsReportPreviewWidget = (payload: ISharedCreateStatsReportPreviewWidget) => {
  return RestRequest.post<any, ISharedStatsReportPreviewWidget>('stats_report_preview_widget', payload, { socket_id: global.socketId, });
};

export const patchStatsReportPreviewWidget = (id: IPreviewStatsReportPreviewWidget['id'], payload: ISharedPatchStatsReportPreviewWidget) => {
  return RestRequest.patch<any, ISharedStatsReportPreviewWidget>(`stats_report_preview_widget/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteStatsReportPreviewWidget = (id: IPreviewStatsReportPreviewWidget['id']) => {
  return RestRequest.delete(`stats_report_preview_widget/${id}`, { socket_id: global.socketId, });
};

export const getStatsReportPreviewWidgetUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_report_preview_widget/update/schema');
};

export const getStatsReportPreviewWidgetCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_report_preview_widget/create/schema');
};

export const getStatsReportPreviewWidgetPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_report_preview_widget/patch/schema');
};
