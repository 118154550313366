import { IPreviewList, } from 'atlas-shared';

export enum ListActions {
  SET_STATE = 'LIST_SET_STATE',
  ADD_STATE = 'LIST_ADD_STATE',
  REMOVE_STATE = 'LIST_REMOVE_STATE',
  GET_STATE = 'LIST_GET_STATE',
  UPDATE_STATE = 'LIST_UPDATE_STATE',
  LOADING = 'LIST_LOADING',
}

export interface IListLoading {
  type: ListActions.LOADING;
}

export interface IListSetAction {
  type: ListActions.SET_STATE;
  payload: Array<IPreviewList>;
}

export interface IListUpdateAction {
  type: ListActions.UPDATE_STATE;
  payload: IPreviewList;
}

export interface IListAddAction {
  type: ListActions.ADD_STATE;
  payload: IPreviewList;
}

export interface IListRemoveAction {
  type: ListActions.REMOVE_STATE;
  payload: IPreviewList['id'];
}

export interface IListStore {
  loaded: boolean;
  loading: boolean;
  lists: Array<IPreviewList>;
  dict: Record<IPreviewList['id'], IPreviewList>;
}

export type TListActionTypes = IListSetAction | IListUpdateAction | IListAddAction | IListRemoveAction | IListLoading;
