import moment from 'moment-timezone';
import {
  bitwiseIncludes,
  DirectionEnum,
  EnumOptions,
  filterMap,
  findMetricByStatsWidgetMetric,
  getMaxUserStatus,
  getMaxUserStatusGroup,
  IGroupMetric,
  IMetric,
  IPreviewOrganization,
  IPreviewStatsCustomMetric,
  IPreviewStatsWidget,
  IPreviewStatsWidgetSettingsGroupByProperty,
  IPreviewStatsWidgetSettingsMetricProperty,
  IPreviewStatsWidgetSettingsMetricsThresholdProperty,
  IPreviewStatsWidgetSettingsNestedMetricsMetricProperty,
  IPreviewStatsWidgetSettingsSubGroupByProperty,
  IPreviewUserStatus,
  ISharedCreateStatsWidget,
  ISharedStatsWidget,
  ISharedStatsWidgetSettingsMetricProperty,
  IStatsWidgetDataTableMinifiedRow,
  MergedMetrics,
  MetricQueryTypeEnum,
  Metrics,
  MetricUnitTypeEnum,
  Nullable,
  StatsWidgetMetricFormatEnum,
  StatsWidgetThresholdComparisonEnum,
  StatsWidgetTypeEnum,
  TimezoneMomentMap,
  Undefinable,
  UserStatusStatusEnum,
  ChannelEnum,
  FontStyleEnum,
  StatsWidgetPeriodEnum,
  ISharedStatsWidgetSettingsGroupByProperty,
  IPreviewStatsAlarmSettingsMetricProperty,
  findMetricByAlias,
  ISharedStatsAlarm,
  ISharedStatsAlarmSettingsMetricProperty,
} from 'atlas-shared/dist';
import { getFullName, } from '@Utils/user';
import { secondsToTime, } from '@Utils/time';
import {
  IAgentprofileStore,
  IClientStore,
  IDispositionStore,
  IFormWebsiteStore,
  IMailAccountStore,
  IOrganizationStore, IPauseStore,
  IQueueStore,
  IUserStatusStore,
  IUserStore,
  IVoiceRouteStore,
} from '@Store';

import { TFunction, } from 'i18next';
import React from 'react';
import { Avatar, ChannelsIcon, Icon, } from '@Components';
import {
  LiveIcon,
  PercentIcon,
  TrendingUpIcon,
  WidgetBarIcon,
  WidgetGridIcon,
  WidgetLineIcon, WidgetPieIcon, WidgetPolarIcon, WidgetRadarIcon,
  WidgetTableIcon, WidgetTextIcon, WidgetTimelineIcon,
} from '@Assets';
import { IOption, } from '@Utils/options';
import { clone, } from '@Utils/misc';
import { TranslateAndDisplayPhoneNumber, } from '@Utils/phone';
import { WIDGET_COLOR_PALETTE, } from '@Components/stats/widget-types/widget.color.palette';
import { UserStatusColors, } from '@Utils/user-status-color';

// TODO: remove optional from stats_custom_metrics
export const getStatsWidgetMetricTitle = (t, metric: IPreviewStatsWidgetSettingsMetricProperty | IPreviewStatsWidgetSettingsGroupByProperty | IPreviewStatsWidgetSettingsSubGroupByProperty | IPreviewStatsAlarmSettingsMetricProperty['metric'], stats_custom_metrics: Array<IPreviewStatsCustomMetric>, trend: boolean = false, forceTitle: Nullable<string> = null): {elm: React.ReactElement; txt: string} => {
  const item = metric as IPreviewStatsWidgetSettingsMetricProperty;
  const m = findMetricByStatsWidgetMetric(item, stats_custom_metrics || []);
  const title = forceTitle || metric.title || (m as IPreviewStatsCustomMetric)?.title || t(`METRIC_${metric.alias}`);
  const original_title = (m as IPreviewStatsCustomMetric)?.title;
  const threshold = metric.alias
    ? ((m as IMetric)?.within_seconds || (m as IMetric)?.after_seconds)
      ? (metric.alias.includes('thread_')
        ? item.thread_seconds_threshold
        : metric.alias.includes('message_')
          ? item.message_seconds_threshold
          : item.seconds_threshold)
      : false
    : 0;

  const txt_postfix: Array<string | false> = [
    (m as IMetric)?.live && t('LIVE'),
    item.user_self && t('USER_SELF'),
    item.of_total && t('OF_TOTAL'),
    item.direction && `${t('DIRECTION')} : ${t(`DIRECTION_${item.direction}`)}`,
    item.channels?.length ? `${t('MAIN_CHANNELS')}: ${item.channels.map(c => t(`CHANNEL_${c}`)).join(', ')}` : false,
    item.thread_direction && `${t('THREAD_DIRECTION')} : ${t(`DIRECTION_${item.thread_direction}`)}`,
    item.thread_channels?.length ? `${t('THREAD_CHANNELS')}: ${item.thread_channels.map(c => t(`CHANNEL_${c}`)).join(', ')}` : false,
    item.message_direction && `${t('MESSAGE_DIRECTION')} : ${t(`DIRECTION_${item.message_direction}`)}`,
    item.message_channels?.length ? `${t('MESSAGE_CHANNELS')}: ${item.message_channels.map(c => t(`CHANNEL_${c}`)).join(', ')}` : false,
    threshold ? threshold < 60 ? `${threshold} ${t('SECONDS')}` : threshold < 3600 ? `${threshold / 60} ${t('MINUTES')}` : `${threshold / 3600} ${t('HOURS')}` : false,
    trend && t('TREND'),
  ].filter(Boolean);
  const txt = title + (original_title && original_title !== title ? ` (${original_title})` : '') + (txt_postfix.length ? ` (${txt_postfix.join(', ')})` : '');
  const iconSize = 12;
  const iconPadding = 3;

  return {
    elm: <div style={{ display: 'flex', alignItems: 'center', }}>
      {!!txt_postfix.length && <div className='prefixes' style={{ display: 'flex', paddingRight: iconPadding, }}>
        {(m as IMetric)?.live && <LiveIcon style={{ animation: 'pulse 5s infinite', }} />}
        {item.user_self && <Avatar size={14} tooltipTitle={t('USER_SELF')} />}
        {item.of_total && <Icon icon={PercentIcon} iconProps={{ size: iconSize, }} tooltip={{ title: t('OF_TOTAL'), }} />}
        {!!item.channels?.length && <span style={{ paddingRight: 5, }}><ChannelsIcon channels={(item.channels || []).map(c => ({ c, d: item.direction, }))} size={iconSize} max={5} width={item.channels.length * 17} padding={iconPadding} /></span>}
        {!item.channels?.length && item.direction && <span style={{ paddingRight: 5, }}><ChannelsIcon channels={[{ d: item.direction, }, ]} size={iconSize} padding={iconPadding} /></span>}
        {!!item.thread_channels?.length && <span style={{ paddingRight: 5, }}><ChannelsIcon channels={(item.thread_channels || []).map(c => ({ c, d: item.thread_direction, }))} size={iconSize} max={5} width={item.thread_channels.length * 17} padding={iconPadding} /></span>}
        {!item.thread_channels?.length && item.thread_direction && <span style={{ paddingRight: 5, }}><ChannelsIcon channels={[{ d: item.thread_direction, }, ]} size={iconSize} padding={iconPadding} /></span>}
        {!!item.message_channels?.length && <span style={{ paddingRight: 5, }}><ChannelsIcon channels={(item.message_channels || []).map(c => ({ c, d: item.message_direction, }))} size={iconSize} max={5} width={item.message_channels.length * 17} padding={iconPadding} /></span>}
        {!item.message_channels?.length && item.message_direction && <span style={{ paddingRight: 5, }}><ChannelsIcon channels={[{ d: item.message_direction, }, ]} size={iconSize} padding={iconPadding} /></span>}
        {trend && <Icon icon={TrendingUpIcon} iconProps={{ size: iconSize, }} tooltip={{ title: t('TREND'), }} />}
      </div>}
      <div className='text' title={(m as IMetric)?.description || txt}>{title}</div>
    </div>,
    txt,
  };
};

export interface IMetricDataDicts {
  organizations: IOrganizationStore;
  users: IUserStore;
  pauses: IPauseStore;
  queues: IQueueStore;
  clients: IClientStore;
  dispositions: IDispositionStore;
  mail_accounts: IMailAccountStore;
  voice_routes: IVoiceRouteStore;
  form_websites: IFormWebsiteStore;
}
export const getStatsWidgetMetricData = (t, metric: IPreviewStatsWidgetSettingsMetricProperty | IPreviewStatsWidgetSettingsGroupByProperty, data: any, dicts: IMetricDataDicts, organization: IPreviewOrganization): string => {

  switch (metric.alias) {
  case 'user_status':
    return data;
  case 'status':
    return data === '-' ? '' : t(`STATUS_${data}`);
  case 'conversation_user_id':
  case 'thread_user_id':
  case 'message_user_id':
    return dicts.users.dict[data] ? getFullName(dicts.users.dict[data]) : (+data ? t('USER_ID_X', { replace: { x: data, }, }) : t('NO_SPECIFIC_USER'));
  case 'conversation_main_channel':
  case 'thread_channel':
  case 'message_channel':
    return (data + '') !== '0' ? t(`CHANNEL_${data}`) : t('NO_SPECIFIC_CHANNEL');
  case 'direction':
  case 'thread_direction':
  case 'message_direction':
    return (data + '') !== '0' ? t(`DIRECTION_${data}`) : t('NO_SPECIFIC_DIRECTION');
  case 'conversation_queue_id':
  case 'thread_queue_id':
  case 'message_queue_id':
    return dicts.queues.dict[data]?.title || (+data ? t('QUEUE_ID_X', { replace: { x: data, }, }) : t('NO_SPECIFIC_QUEUE'));
  case 'client_id':
    return dicts.clients.dict[data]?.title || (+data ? t('ORGANIZATION_ID_X', { replace: { x: data, }, }) : t('NO_SPECIFIC_ORGANIZATION'));
  case 'organization_id':
    return dicts.organizations.dict[data]?.title || (+data ? t('CLIENT_ID_X', { replace: { x: data, }, }) : t('NO_SPECIFIC_CLIENT'));
  case 'pause_id':
    return (data + '') === '-1' ? t('ACW') : dicts.pauses.dict[data]?.title || (+data ? t('PAUSE_ID_X', { replace: { x: data, }, }) : t('NO_SPECIFIC_PAUSE'));
  case 'disposition_id':
  case 'sub_disposition_id':
  case 'thrd_disposition_id':
    return dicts.dispositions.dict[data]?.title || (+data ? t('DISPOSITION_ID_X', { replace: { x: data, }, }) : t('NO_SPECIFIC_DISPOSITION'));
  case 'account_id':
    let dict: IMailAccountStore['dict'] | IVoiceRouteStore['dict'] | IFormWebsiteStore['dict'] = dicts.mail_accounts.dict;
    const channel = (data || '').substr(0, 1);
    const id = (data || '').substr(1, 99);

    switch (channel) {
    case ChannelEnum.Voice:
      dict = dicts.voice_routes.dict;
      break;
    case ChannelEnum.Mail:
      dict = dicts.mail_accounts.dict;
      break;
    case ChannelEnum.Form:
      dict = dicts.form_websites.dict;
      break;
    }

    // @ts-ignore
    return dict?.[id]?.title || (dict?.[id]?.number && <TranslateAndDisplayPhoneNumber t={t} phone_number={dict[id].number.split('___').at(-1)} />) || (+data ? t('ACCOUNT_ID_X', { replace: { x: data, }, }) : t('NO_SPECIFIC_ACCOUNT'));
  }

  return getStatsWidgetMetricDataFormatted(t, metric, data, organization);
};

export const getStatsWidgetMetricDataFormatted = (t, metric: IPreviewStatsWidgetSettingsMetricProperty | IPreviewStatsWidgetSettingsGroupByProperty, data: any, organization: IPreviewOrganization): string => {

  if (!metric || data === '-')
    return data;

  if (data === null)
    return '';

  const tz = TimezoneMomentMap[organization?.timezone];
  const format = (metric as IPreviewStatsWidgetSettingsMetricProperty).of_total ? StatsWidgetMetricFormatEnum.VALUE_PERCENT : (metric as IPreviewStatsWidgetSettingsMetricProperty).format;

  switch (format) {
  case StatsWidgetMetricFormatEnum.STATIC:
    return data;
  case StatsWidgetMetricFormatEnum.SECONDS_TO_HH_MM:
    return secondsToTime(data, false, true);
  case StatsWidgetMetricFormatEnum.SECONDS_TO_HH_MM_SS:
    return secondsToTime(data, false, false);
  case StatsWidgetMetricFormatEnum.SECONDS_TO_MM_SS:
    return secondsToTime(data, true, false);
  case StatsWidgetMetricFormatEnum.HOUR_OF_DAY_24H:
    return moment(data).format('HH:mm');
  case StatsWidgetMetricFormatEnum.HOUR_OF_DAY_12H:
    return moment(data).format('hh:mm A');
  case StatsWidgetMetricFormatEnum.WEEKDAY_SHORT:
    return t(`WEEKDAY_SHORT_${data}`);
  case StatsWidgetMetricFormatEnum.WEEKDAY_LONG:
    return t(`WEEKDAY_LONG_${data}`);
  case StatsWidgetMetricFormatEnum.WEEK_NUMBER:
    return `${t('W_WEEK_SHORT')}${moment(data).isoWeek()}`;
  case StatsWidgetMetricFormatEnum.YEAR_WEEK_NUMBER: {
    const d = moment(data).tz(tz);

    return `${d.year()} ${t('W_WEEK_SHORT')}${d.isoWeek()}`;

  }
  case StatsWidgetMetricFormatEnum.MONTH_SHORT:
    return t(`MONTH_SHORT_${moment(data).month() + 1}`);
  case StatsWidgetMetricFormatEnum.YEAR_MONTH_SHORT: {
    const d = moment(data).tz(tz);

    return `${d.year()} ${t(`MONTH_SHORT_${d.month() + 1}`)}`;
  }
  case StatsWidgetMetricFormatEnum.MONTH_LONG:
    return t(`MONTH_LONG_${moment(data).month() + 1}`);
  case StatsWidgetMetricFormatEnum.YEAR_MONTH_LONG: {
    const d = moment(data).tz(tz);

    return `${d.year()} ${t(`MONTH_LONG_${d.month() + 1}`)}`;
  }
  case StatsWidgetMetricFormatEnum.HH_mm:
    return moment(data).tz(tz).format('HH:mm');
    // return (data + '').substr(11, 5);
  case StatsWidgetMetricFormatEnum.HH_mm_ss:
    return moment(data).tz(tz).format('HH:mm:ss');
  case StatsWidgetMetricFormatEnum.mm_ss:
    return moment(data).tz(tz).format('mm:ss');
    // return (data + '').substr(11, 8);
  case StatsWidgetMetricFormatEnum.D_SLASH_M:
    return moment(data).tz(tz).format('D/M');
  case StatsWidgetMetricFormatEnum.YYYYMMDD:
    return moment(data).tz(tz).format('YYYYMMDD');
  case StatsWidgetMetricFormatEnum.YYYY_MM_DD:
    return moment(data).tz(tz).format('YYYY-MM-DD');
  case StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm:
    return moment(data).tz(tz).format('YYYY-MM-DD HH:mm');
  case StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm_ss:
    return moment(data).tz(tz).format('YYYY-MM-DD HH:mm:ss');
  case StatsWidgetMetricFormatEnum.D_SLASH_M_HH_mm:
    return moment(data).tz(tz).format('D/M HH:mm');
  case StatsWidgetMetricFormatEnum.VALUE_PERCENT:
    return `${(+data || 0).toFixed((metric as IPreviewStatsWidgetSettingsMetricProperty).precision || 0)}%`;
  case StatsWidgetMetricFormatEnum.VALUE_DECIMAL:
    return `${(+data || 0).toFixed((metric as IPreviewStatsWidgetSettingsMetricProperty).precision || 0)}`;
  case StatsWidgetMetricFormatEnum.VALUE_PCS:
    return `${data || 0}pcs`;
  }

  return data;
};

export const metricToOption = (metric: IMetric | IGroupMetric, t: TFunction): IOption => {
  const isPopular = metric.popular;
  const isLive = metric.live;
  const descriptionKey = `METRIC_${metric.alias}_DESCRIPTION`;
  const description = t(descriptionKey);

  return {
    key: metric.alias,
    title: t(`METRIC_${metric.alias}`),
    description: description !== descriptionKey ? description : metric.description,
    group: isPopular ? 'popular' : isLive ? 'live' : 'others',
    group_order: isPopular ? 3 : isLive ? 1 : 0,
  };
};

export const customMetricToOption = (metric: IPreviewStatsCustomMetric, t: TFunction, stringify_key: boolean = false): IOption => {
  return {
    key: stringify_key ? metric.id + '' : metric.id,
    title: metric.title,
    group: 'custom',
    group_order: 2,
  };
};

export const metricOptions = (t: TFunction, custom_metrics: Array<IPreviewStatsCustomMetric>, item: ISharedCreateStatsWidget | ISharedStatsAlarm, unit_type?: MetricUnitTypeEnum) => {
  const stats_widget = item as ISharedCreateStatsWidget;
  const reduced_groups = [...(stats_widget.settings?.group_by || []), ...(stats_widget.settings?.sub_group_by || []), ...(stats_widget.settings?.split_by || []), ];
  const message_agent = reduced_groups.some(g => g.alias === 'message_user_id');
  const agent = reduced_groups.some(g => g.alias === 'conversation_user_id');
  const is_live = stats_widget.settings?.period?.type === StatsWidgetPeriodEnum.LIVE_TODAY;
  const type = stats_widget.type || StatsWidgetTypeEnum.Table;

  return [
    ...Metrics.filter(m =>
      (agent || (!agent && !m.agent))
      && (message_agent || (!message_agent && !m.message_agent))
      && (is_live || (!is_live && !m.live))
      && (!unit_type || m.unit_type === unit_type)
      && (
        (type === StatsWidgetTypeEnum.Timeline && m.unit_type === MetricUnitTypeEnum.TimelineEvent)
        || (type !== StatsWidgetTypeEnum.Timeline && m.unit_type !== MetricUnitTypeEnum.TimelineEvent)
      )
    ).map(m => metricToOption(m, t)),
    ...(type === StatsWidgetTypeEnum.Timeline ? [] : custom_metrics.filter(m => !m.deleted_at && (!unit_type || m.unit_type === unit_type)).map(m => customMetricToOption(m, t)) ),
  ];
};

export const metric_count_options = (t: TFunction, custom_metrics: Array<IPreviewStatsCustomMetric>) => {
  return [
    ...filterMap(Metrics, (m) => [MetricUnitTypeEnum.Count, MetricUnitTypeEnum.Second, MetricUnitTypeEnum.Decimal, MetricUnitTypeEnum.Percent, ].includes(m.unit_type) && metricToOption(m, t)),
    ...custom_metrics.map(m => customMetricToOption(m, t, true)),
  ];
};

export const stats_message_channels_options = (t: TFunction) => EnumOptions('CHANNEL', ChannelEnum, t);
export const stats_channels_options = (t: TFunction) => stats_message_channels_options(t).filter(({ key, }) => key !== ChannelEnum.InternalNote);
export const stats_message_direction_options = (t: TFunction) => EnumOptions('DIRECTION', DirectionEnum, t);
export const stats_direction_options = (t: TFunction) => stats_message_direction_options(t).filter(({ key, }) => key !== DirectionEnum.Internal);
export const stats_metric_query_type = (t: TFunction) => EnumOptions('METRIC_QUERY_TYPE', MetricQueryTypeEnum, t);
export const stats_custom_metric_query_type = (t: TFunction) => stats_metric_query_type(t).filter(({ key, }) => ![MetricQueryTypeEnum.SumMax, MetricQueryTypeEnum.Static, ].includes(key as any));

export const getDefaultFormatByUnitType = (
  unit_type: IMetric['unit_type'],
  alias: IMetric['alias'],
  stats_widget: ISharedStatsWidget
): ISharedStatsWidgetSettingsMetricProperty['format'] => {
  switch (unit_type) {
  case MetricUnitTypeEnum.DateTime:
    if ([StatsWidgetPeriodEnum.TODAY, StatsWidgetPeriodEnum.LIVE_TODAY, StatsWidgetPeriodEnum.LAST_24_HOURS, StatsWidgetPeriodEnum.YESTERDAY, ].includes(stats_widget.settings.period.type))
      return StatsWidgetMetricFormatEnum.HH_mm;

    return StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm;
  case MetricUnitTypeEnum.Date:
    return StatsWidgetMetricFormatEnum.D_SLASH_M;
  case MetricUnitTypeEnum.Second:
    return StatsWidgetMetricFormatEnum.SECONDS_TO_MM_SS;
  case MetricUnitTypeEnum.Percent:
    return StatsWidgetMetricFormatEnum.VALUE_PERCENT;
  case MetricUnitTypeEnum.Decimal:
    return StatsWidgetMetricFormatEnum.VALUE_DECIMAL;
  case MetricUnitTypeEnum.HOUR_OF_DAY:
    return StatsWidgetMetricFormatEnum.HOUR_OF_DAY_24H;
  case MetricUnitTypeEnum.WEEKDAY:
    return StatsWidgetMetricFormatEnum.WEEKDAY_SHORT;
  case MetricUnitTypeEnum.Month:
    return StatsWidgetMetricFormatEnum.MONTH_SHORT;
  case MetricUnitTypeEnum.Week:
    return StatsWidgetMetricFormatEnum.WEEK_NUMBER;
  case MetricUnitTypeEnum.Countdown:
    return StatsWidgetMetricFormatEnum.COUNTDOWN;
  }

  return StatsWidgetMetricFormatEnum.STATIC;
};
export const getMetricDefaultFormat = (alias: IMetric['alias'], stats_widget: ISharedStatsWidget): ISharedStatsWidgetSettingsMetricProperty['format'] => {
  const metric = MergedMetrics.find(m => m.alias === alias);

  if (metric)
    return getDefaultFormatByUnitType(metric.unit_type, metric.alias, stats_widget);

  return StatsWidgetMetricFormatEnum.STATIC;
};

export const getCustomMetricDefaultFormat = (id: IPreviewStatsCustomMetric['id'], stats_widget: ISharedStatsWidget, stats_custom_metrics: Array<IPreviewStatsCustomMetric>): ISharedStatsWidgetSettingsMetricProperty['format'] => {
  const custom_metric = stats_custom_metrics.find(m => m.id === id);

  if (custom_metric)
    return getDefaultFormatByUnitType(custom_metric.unit_type, custom_metric.id + '', stats_widget);

  return StatsWidgetMetricFormatEnum.STATIC;
};

export const MetricSelectableFormat = {
  [MetricUnitTypeEnum.Count]: [StatsWidgetMetricFormatEnum.VALUE_PCS, ],
  [MetricUnitTypeEnum.Percent]: [StatsWidgetMetricFormatEnum.VALUE_PERCENT, ],
  [MetricUnitTypeEnum.Decimal]: [StatsWidgetMetricFormatEnum.VALUE_DECIMAL, ],
  [MetricUnitTypeEnum.Week]: [StatsWidgetMetricFormatEnum.WEEK_NUMBER, StatsWidgetMetricFormatEnum.YEAR_WEEK_NUMBER, ],
  [MetricUnitTypeEnum.Month]: [
    StatsWidgetMetricFormatEnum.MONTH_LONG,
    StatsWidgetMetricFormatEnum.MONTH_SHORT,
    StatsWidgetMetricFormatEnum.YEAR_MONTH_LONG,
    StatsWidgetMetricFormatEnum.YEAR_MONTH_SHORT,
  ],
  [MetricUnitTypeEnum.Second]: [
    StatsWidgetMetricFormatEnum.SECONDS_TO_HH_MM,
    StatsWidgetMetricFormatEnum.SECONDS_TO_HH_MM_SS,
    StatsWidgetMetricFormatEnum.SECONDS_TO_MM_SS,
  ],
  [MetricUnitTypeEnum.Date]: [
    StatsWidgetMetricFormatEnum.D_SLASH_M,
    StatsWidgetMetricFormatEnum.YYYYMMDD,
    StatsWidgetMetricFormatEnum.YYYY_MM_DD,
    StatsWidgetMetricFormatEnum.D_SLASH_M_HH_mm,
  ],
  [MetricUnitTypeEnum.DateTime]: [
    StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm,
    StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm_ss,
    StatsWidgetMetricFormatEnum.HH_mm,
    StatsWidgetMetricFormatEnum.mm_ss,
    StatsWidgetMetricFormatEnum.HH_mm_ss,
  ],
  [MetricUnitTypeEnum.HOUR_OF_DAY]: [
    StatsWidgetMetricFormatEnum.HOUR_OF_DAY_24H,
    StatsWidgetMetricFormatEnum.HOUR_OF_DAY_12H,
  ],
  [MetricUnitTypeEnum.WEEKDAY]: [
    StatsWidgetMetricFormatEnum.WEEKDAY_SHORT,
    StatsWidgetMetricFormatEnum.WEEKDAY_LONG,
  ],
  [MetricUnitTypeEnum.Countdown]: [
    StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm_ss,
    StatsWidgetMetricFormatEnum.COUNTDOWN,
  ],
};

export const getMetricSelectableFormats = (alias: IMetric['alias']): Array<StatsWidgetMetricFormatEnum> => {
  const metric = MergedMetrics.find(m => m.alias === alias);

  return [StatsWidgetMetricFormatEnum.STATIC, ...((metric?.unit_type && MetricSelectableFormat[metric.unit_type]) || []), ];
};

export const getCustomMetricSelectableFormats = (id: IPreviewStatsCustomMetric['id'], stats_custom_metrics: Array<IPreviewStatsCustomMetric>): Array<StatsWidgetMetricFormatEnum> => {
  const custom_metric = stats_custom_metrics.find(m => m.id === id);

  return [StatsWidgetMetricFormatEnum.STATIC, ...((custom_metric?.unit_type && MetricSelectableFormat[custom_metric.unit_type]) || []), ];
};

export const getStatsWidgetSplitHeaderStyles = (stats_widget: IPreviewStatsWidget, value: Array<any>): React.CSSProperties => {
  let backgroundColor: Undefinable<string> = undefined;
  const status = stats_widget.settings.split_by?.[0].alias === 'user_status';

  if (status) {
    if ((value[0] + '').startsWith('Ready'))
      backgroundColor = UserStatusColors[UserStatusStatusEnum.Idle];
    else if ((value[0] + '').startsWith('Pause'))
      backgroundColor = UserStatusColors[UserStatusStatusEnum.Paused];
    else if ((value[0] + '').startsWith('Busy'))
      backgroundColor = UserStatusColors[UserStatusStatusEnum.Busy];
  }

  return {
    backgroundColor,
    color: backgroundColor ? '#FFFFFF' : undefined,
  };
};

export const getStatsWidgetTableMetricStyles = (metric: IPreviewStatsWidgetSettingsMetricProperty | IPreviewStatsWidgetSettingsGroupByProperty | IPreviewStatsWidgetSettingsSubGroupByProperty, value: number, trend_value: Undefinable<number>, trend_comparison: Undefinable<number>, widget?: IPreviewStatsWidget): React.CSSProperties => {
  const styles: React.CSSProperties = {};

  if (metric.format === StatsWidgetMetricFormatEnum.COUNTDOWN)
    value = (value && moment(value).diff(moment(), 'seconds') || 0) * -1;

  if (metric.bg_color)
    styles.backgroundColor = metric.bg_color;

  if (metric.color)
    styles.color = metric.color;

  if (metric.font_style) {
    if ([FontStyleEnum.Bold, FontStyleEnum.BoldItalic, ].includes(metric.font_style))
      styles.fontWeight = 'bold';

    if ([FontStyleEnum.Italic, FontStyleEnum.BoldItalic, ].includes(metric.font_style))
      styles.fontStyle = 'italic';
  }

  if (metric.border?.color) {
    styles.borderBottom = `${metric.border.width}px solid ${metric.border.color}`;
    if (widget?.type === StatsWidgetTypeEnum.Grid)
      styles.borderRight = `${metric.border.width}px solid ${metric.border.color}`;

    if (widget?.type === StatsWidgetTypeEnum.Text) {
      styles.borderBottomLeftRadius = 0;
      styles.borderBottomRightRadius = 0;
    }
  }

  if (metric.alignment)
    styles.textAlign = metric.alignment;

  metric.thresholds.forEach(threshold => {
    if (thresholdComparison(value, threshold, trend_value || 0, trend_comparison)) {
      if (threshold.bg_color)
        styles.backgroundColor = threshold.bg_color;

      if (threshold.border_color)
        styles.borderColor = threshold.border_color;

      if (threshold.color)
        styles.color = threshold.color;

      if (threshold.blink)
        styles.animation = 'pulse 2s infinite';
    }
  });

  return styles;
};

export const getStatsWidgetTableMetricBoxStyles = (metric: IPreviewStatsWidgetSettingsMetricProperty | IPreviewStatsWidgetSettingsGroupByProperty | IPreviewStatsWidgetSettingsSubGroupByProperty, value: number, trend_value: Undefinable<number>, trend_comparison: Undefinable<number>): React.CSSProperties => {
  const styles: React.CSSProperties = {};

  // if (metric.alias === 'status' && !isNaN(value))
  //   styles.backgroundColor = UserStatusColors[value];

  if (metric.box_color)
    styles.backgroundColor = metric.box_color;

  if (metric.color)
    styles.color = metric.color;

  if (metric.font_style) {
    if ([FontStyleEnum.Italic, FontStyleEnum.BoldItalic, ].includes(metric.font_style))
      styles.fontStyle = 'italic';
    else if ([FontStyleEnum.Bold, FontStyleEnum.BoldItalic, ].includes(metric.font_style))
      styles.fontWeight = 'bold';
  }

  metric.thresholds.forEach(threshold => {
    if (thresholdComparison(value, threshold, trend_value || 0, trend_comparison)) {
      if (threshold.box_color)
        styles.backgroundColor = threshold.box_color;

      if (threshold.color)
        styles.color = threshold.color;
    }
  });

  return styles;
};

export const getStatsWidgetChartMetricStyles = (t: TFunction, value: number, trend_value: number, trend_comparison: Undefinable<number>, metric: IPreviewStatsWidgetSettingsMetricProperty | IPreviewStatsWidgetSettingsNestedMetricsMetricProperty | ISharedStatsWidgetSettingsGroupByProperty, index: number, stats_widget: IPreviewStatsWidget, stats_custom_metrics: Array<IPreviewStatsCustomMetric>, trend: boolean = false) => {
  if (!metric)
    return {};

  const forcedBorder = [StatsWidgetTypeEnum.Radar, StatsWidgetTypeEnum.Line, ].includes(stats_widget.type);
  const backgroundColor = (metric.bg_color || (forcedBorder ? 'transparent' : WIDGET_COLOR_PALETTE[index]));
  const styles: Partial<{
    label: string;
    color: string;
    backgroundColor: string;
    boxColor?: string;
    borderColor: string;
    borderWidth: Undefinable<number>;
    fill: boolean;
  }> = {
    label: getStatsWidgetMetricTitle(t, metric, stats_custom_metrics).txt + (trend ? ` (${t('Trend')})` : ''),
    backgroundColor,
    color: metric.color || (forcedBorder ? 'var(--COLOR-000533)' : '#FFFFFF'),
    boxColor: metric.box_color || undefined,
    borderColor: metric.border?.color || metric.bg_color || (forcedBorder ? WIDGET_COLOR_PALETTE[index] : 'transparent'),
    borderWidth: metric.border?.width || (forcedBorder ? 2 : 0),
    fill: true,
  };

  if (!trend)
    metric.thresholds.forEach(threshold => {
      if (thresholdComparison(value, threshold, trend_value, trend_comparison, trend)) {
        if (threshold.bg_color)
          styles.backgroundColor = threshold.bg_color;

        if (threshold.border_color)
          styles.borderColor = threshold.border_color;

        if (threshold.color)
          styles.color = threshold.color;

        if (threshold.box_color)
          styles.boxColor = threshold.box_color;
      }
    });

  return styles;
};

const thresholdComparison = (value: number, threshold: IPreviewStatsWidgetSettingsMetricsThresholdProperty, trend_value: number, trend_comparison: Undefinable<number>, is_trend: boolean = false) => {
  const trendValue = () => trend_comparison !== undefined ? trend_comparison : value;

  switch (threshold.comparison) {
  case StatsWidgetThresholdComparisonEnum.Gt:
    return value > threshold.value;
  case StatsWidgetThresholdComparisonEnum.Gte:
    return value >= threshold.value;
  case StatsWidgetThresholdComparisonEnum.Lt:
    return value < threshold.value;
  case StatsWidgetThresholdComparisonEnum.Lte:
    return value <= threshold.value;
  case StatsWidgetThresholdComparisonEnum.EqNum:
    return value == threshold.value;
  case StatsWidgetThresholdComparisonEnum.nEqNum:
    return value != threshold.value;
  case StatsWidgetThresholdComparisonEnum.AnyOfNum:
    return (threshold.value_arr || []).includes(+value);
  case StatsWidgetThresholdComparisonEnum.NoneOfNum:
    return !(threshold.value_arr || []).includes(+value);
  case StatsWidgetThresholdComparisonEnum.GtTrend:
    return !is_trend && trendValue() > trend_value;
  case StatsWidgetThresholdComparisonEnum.GteTrend:
    return !is_trend && trendValue() >= trend_value;
  case StatsWidgetThresholdComparisonEnum.LtTrend:
    return !is_trend && trendValue() < trend_value;
  case StatsWidgetThresholdComparisonEnum.LteTrend:
    return !is_trend && trendValue() <= trend_value;
  case StatsWidgetThresholdComparisonEnum.EqNumTrend:
    return !is_trend && trendValue() == trend_value;
  }
};

export type TClientDataMetrics = Array<[IMetric, number]>;
export const mergeClientData = (t: TFunction, stats_widget: IPreviewStatsWidget, data: IPreviewStatsWidget['data'], user_statuses: IUserStatusStore, agentprofiles: IAgentprofileStore, metrics: TClientDataMetrics) => {

  if (data) {
    let cloned = clone(data);
    let group_metrics: Array<IPreviewStatsWidgetSettingsGroupByProperty> = [];

    if (['split', 'summary', ].includes(data.g))
      group_metrics = stats_widget.settings.split_by;
    else if (data.g === 'group')
      group_metrics = stats_widget.settings.group_by;
    else if (data.g === 'sub_group')
      group_metrics = stats_widget.settings.sub_group_by;

    let index_fix = group_metrics.length;

    /* user status group */
    const group_user_status_index = group_metrics.findIndex(m => m.alias === 'user_status');

    if (group_user_status_index > -1) {
      const sub_group_user_id_index = stats_widget.settings[['split', 'summary', ].includes(data.g) ? 'group_by' : 'sub_group_by'].findIndex(m => m.alias === 'conversation_user_id');

      if (sub_group_user_id_index > -1) {
        const sub_rows = (cloned.r || []).flatMap(r => r.c?.r || []);
        const grouped_rows = {
          [UserStatusStatusEnum.Busy]: [],
          [UserStatusStatusEnum.Paused]: [],
          [UserStatusStatusEnum.Idle]: [],
          //[UserStatusStatusEnum.Online]: [],
        };

        sub_rows.forEach(r => {
          const user_id = r.d[sub_group_user_id_index];

          if (!+user_id)
            return;

          const status = user_statuses.dict[user_id]?.status || 0;
          const group_status = getMaxUserStatusGroup(status);

          if (grouped_rows[group_status])
            grouped_rows[group_status].push(r);

        });

        cloned = {
          g: cloned.g,
          r: [
            // UserStatusStatusEnum.Online,
            UserStatusStatusEnum.Idle,
            UserStatusStatusEnum.Paused,
            UserStatusStatusEnum.Busy,
          ].flatMap(group_status => {
            return (cloned.r || []).flatMap(r => {
              const d = [...r.d, ].fill('-');

              d[group_user_status_index] = `${t(`STATUS_${getMaxUserStatus(group_status)}`)} (${grouped_rows[group_status].length})`;
              return {
                ...r,
                d,
                c: {
                  g: r.c!.g,
                  r: grouped_rows[group_status],
                },
              };
            });
          }),
        };
      }

    }
    /* user status group end */

    (cloned.r || []).forEach(row => {
      metrics.forEach(([metric, index, ]) => {
        row.d[index_fix + index] = getClientMetricData(t, row, metric, user_statuses, agentprofiles, group_metrics);
      });
      if (row.c)
        row.c = mergeClientData(t, stats_widget, row.c, user_statuses, agentprofiles, metrics);
    });

    return cloned;
  }

  return data;
};

const findUserStatus = (user_statuses: IUserStatusStore, group_metrics: Array<IPreviewStatsWidgetSettingsGroupByProperty>, row: IStatsWidgetDataTableMinifiedRow): Nullable<IPreviewUserStatus> => {
  const user_id_index = group_metrics.findIndex(m => m.alias === 'conversation_user_id');

  if (user_id_index === -1)
    return null;

  const user_id = row.d[user_id_index];

  if (!+user_id)
    return null;

  return user_statuses.dict[user_id];
};

const getClientMetricData = (t: TFunction, row: IStatsWidgetDataTableMinifiedRow, metric: IMetric, user_statuses: IUserStatusStore, agentprofiles: IAgentprofileStore, group_metrics: Array<IPreviewStatsWidgetSettingsGroupByProperty>) => {
  if (['status_pause', 'status_idle', 'status_busy', ].includes(metric.alias)) {
    const group_queue_index = group_metrics.findIndex(gm => gm.alias === 'conversation_queue_id');
    const in_queue = (user_status: IPreviewUserStatus) => group_queue_index === -1 || user_status.__current_queues.some(queue_id => row.d[group_queue_index]);

    if (metric.alias === 'status_pause')
      return user_statuses.user_statuses.filter(user_status => !user_status.busy_since && !!user_status.pause_since && in_queue(user_status)).length;

    if (metric.alias === 'status_idle')
      return user_statuses.user_statuses.filter(user_status => !user_status.busy_since && !user_status.pause_since && !!user_status.idle_since && in_queue(user_status)).length;

    if (metric.alias === 'status_busy')
      return user_statuses.user_statuses.filter(user_status => !!user_status.busy_since && in_queue(user_status)).length;
  }

  if (metric.alias === 'status') {
    const user_status = findUserStatus(user_statuses, group_metrics, row);

    if (user_status)
      return getMaxUserStatus(user_status?.status || 0);
  }

  if (metric.alias === 'status_info') {
    const user_status = findUserStatus(user_statuses, group_metrics, row);

    return {
      status_info: user_status?.status_info || {},
      status: user_status?.status || 0,
      pause_id: user_status?.pause_id,
    };
    // return [
    //   { c: ChannelEnum.Voice, d: DirectionEnum.Internal, p: '+982110000000', k: 's' },
    //   { c: ChannelEnum.Voice, d: DirectionEnum.Internal, u: 10002, k: 's' },
    //   { c: ChannelEnum.Voice, d: DirectionEnum.Internal, t: 'sdfdfrghdf', k: 's' }
    // ];
  }

  if (metric.alias === 'status_time') {
    const user_status = findUserStatus(user_statuses, group_metrics, row);

    return user_status?.busy_since || user_status?.pause_since || user_status?.idle_since || '-';
  }

  if (metric.alias === 'status_agentprofile') {
    const user_status = findUserStatus(user_statuses, group_metrics, row);

    if (user_status?.agentprofile_id)
      return agentprofiles.dict[user_status?.agentprofile_id]?.title || '-';
  }

  return '-';
};

export interface IRenderStatusValue {
  status_info: IPreviewUserStatus['status_info'];
  status: IPreviewUserStatus['status'];
  pause_id: IPreviewUserStatus['pause_id'];
}

export const RenderStatusInfo = ({ value, users, pauses, queues, t, }: {
  value: IRenderStatusValue;
  users: IUserStore;
  pauses: IPauseStore;
  queues: IQueueStore;
  t: TFunction;
}) => {

  if (!value)
    return <></>;

  const [item_1, ...rest] = Object.values(value.status_info || {});
  const busyChannels: Array<{status: UserStatusStatusEnum; channel: ChannelEnum }> = [
    {
      status: UserStatusStatusEnum.BusyMail,
      channel: ChannelEnum.Mail,
    },
    {
      status: UserStatusStatusEnum.BusyForm,
      channel: ChannelEnum.Form,
    },
  ].filter(({ status, }) => bitwiseIncludes(value.status, status));

  if (!value.pause_id && (!item_1 || (value.status_info as unknown) === '-') && !busyChannels.length)
    return <></>;

  return <>
    <div className='status-info'>
      {value.pause_id && <div className='info'>{value.pause_id === -1 ? t('ACW') : pauses.dict[value.pause_id]?.title || `Pause ${value.pause_id}`}</div>}
      {busyChannels.map(({ status, channel, }) => <ChannelsIcon channels={[{ c: channel as any, }, ]} size={12} padding={4} />)}
      {item_1 && [JSON.parse(item_1 as unknown as string), ].map(({ c, d, u, t, p, q, }) => <div className='info'>
        <ChannelsIcon channels={[{ c, d, }, ]} size={10} />
        {t && <span>{t}</span>}
        {p && <TranslateAndDisplayPhoneNumber t={t} phone_number={p} />}
        {u && <span>{getFullName(users.dict[u])}</span>}
        {q && queues.dict[q] && <span className='ellipsis queue' title={queues.dict[q]?.title}>{queues.dict[q]?.title + queues.dict[q]?.title + queues.dict[q]?.title + queues.dict[q]?.title}</span>}
      </div>)}
      {rest?.length > 0 && <div className='badge'>+{rest.length}</div>}
    </div>
  </>;
};

export const WidgetTypeIcons: Record<StatsWidgetTypeEnum, any> = {
  [StatsWidgetTypeEnum.Table]: WidgetTableIcon,
  [StatsWidgetTypeEnum.Grid]: WidgetGridIcon,
  [StatsWidgetTypeEnum.Bar]: WidgetBarIcon,
  [StatsWidgetTypeEnum.Line]: WidgetLineIcon,
  [StatsWidgetTypeEnum.Text]: WidgetTextIcon,
  [StatsWidgetTypeEnum.Pie]: WidgetPieIcon,
  [StatsWidgetTypeEnum.Radar]: WidgetRadarIcon,
  [StatsWidgetTypeEnum.Polar]: WidgetPolarIcon,
  [StatsWidgetTypeEnum.Timeline]: WidgetTimelineIcon,
};

export const seconds_threshold_options = (t: TFunction, options: Array<number>) => {
  const minute = 60;
  const hour = minute * 60;

  return options.map(v => {
    let title = `${v} ${t(`SECOND${v > 1 ? 'S' : ''}`)}`;

    if (v >= hour)
      title = `${v / hour} ${t(`HOUR${v > 1 ? 'S' : ''}`)}`;
    else if (v >= minute)
      title = `${v / minute} ${t(`MINUTE${v > 1 ? 'S' : ''}`)}`;

    return {
      key: v,
      title,
    };
  });
};

export const seconds_threshold_settings = (settings_key: string, it: ISharedStatsWidget | ISharedStatsAlarm, settings: ISharedStatsWidgetSettingsMetricProperty | ISharedStatsAlarmSettingsMetricProperty['metric']) => {
  const alias = settings.alias;

  if (!alias)
    return { hidden: true, };

  const metric = alias ? findMetricByAlias(alias) : undefined;
  const after_seconds = !!metric?.after_seconds;
  const within_seconds = !!metric?.within_seconds;
  let hidden = !after_seconds && !within_seconds;

  if (!hidden && metric) {
    const message_alias = metric.alias.startsWith('message_');
    const message_settings = settings_key === 'message_seconds_threshold';
    const thread_alias = metric.alias.startsWith('thread_');
    const thread_settings = settings_key === 'thread_seconds_threshold';
    const conversation_settings = settings_key === 'seconds_threshold';

    if (message_alias && !message_settings)
      hidden = true;
    else if (thread_alias && !thread_settings)
      hidden = true;
    else if (!message_alias && !thread_alias && !conversation_settings)
      hidden = true;
  }

  return { label: 'TIME_THRESHOLD', hidden, preFixText: after_seconds ? 'AFTER' : 'WITHIN', };
};

export const metric_options = (t: TFunction, stats_custom_metrics: Array<IPreviewStatsCustomMetric>, stats_widget: ISharedCreateStatsWidget | ISharedStatsAlarm) => metricOptions(t, stats_custom_metrics, stats_widget);

export const PAUSE_FILTER_METRICS = [
  'login_time',
  'pause_time_login_time',
  'pause_time',
  'pause_work_time',
  'pause_none_work_time',
  'idle_time',
  'total_idle_time',
  'total_work_time',
  'total_effective_work_time',
  'total_efficient_work_time',
  'invoiceable_time',
  'none_invoiceable_time',
];
