import React, { useEffect, useState, } from 'react';
import '../admin.list.scss';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { ColumnsType, SorterResult, } from 'antd/lib/table/interface';
import { IPreviewPrepaidCredit, PermissionEnum, CrudEnum, TPrepaidCreditSortableListCol, } from 'atlas-shared';
import { Table, } from 'antd';
import { AdminListPageHeader, ListPageEmpty, } from '@Components';
import { IWithListProps, IWithListSorterProps, withList, } from '@Hocs/with.list';
import { useAbilities, useAuth, usePrepaidCredits, useOrganizations, } from '@Hooks';
import { PrepaidCreditListColumns, } from '@Utils';
import { IAuth, } from '@Store';

const PrepaidCreditList = ({ filters, filtersCount, hasFilters, sorter, pagination, setQueryParams, resetQueryParams, }: IWithListProps<IPreviewPrepaidCredit, TPrepaidCreditSortableListCol>) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth: IAuth = useAuth();
  const prepaid_credits = usePrepaidCredits();
  const abilities = useAbilities();
  const [loading, setLoading, ] = useState<boolean>(true);
  const [rows, setRows, ] = useState<Array<any>>([]);
  const organizations = useOrganizations();
  
  useEffect(() => {
    if (!prepaid_credits.loaded)
      return;
      
    setLoading(false);
    setRows((prepaid_credits?.prepaid_credits || []).map(row => ({ ...row, key: row.id, })));
    
  }, [prepaid_credits, ]);

  const columns: ColumnsType<IPreviewPrepaidCredit> = React.useMemo(() => {
    return PrepaidCreditListColumns(t, navigate, false, organizations, abilities, auth, [], filters, sorter);
  }, [t, navigate, organizations, abilities, auth, filters, sorter, ]);

  return <div className='admin-list-view'>
    <AdminListPageHeader 
      t={t}
      title={t('PREPAID_CREDITS')}
      filtersCount={filtersCount}
      reset={() => resetQueryParams()}
      addBtn={abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.PrepaidCredit) ? { callback: () => navigate('/admin/prepaid_credit/add'), } : undefined} 
    />
    <div className='table-wrp'>
      <Table
        key={loading ? 1 : 0}
        columns={columns}
        dataSource={rows}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
        onChange={(pagination, filters, _sorter) => {
          const sorter: Array<SorterResult<any>> = Array.isArray(_sorter) ? _sorter : _sorter?.columnKey ? [_sorter, ] : [];

          setQueryParams({
            pagination: { current: pagination.current as number, pageSize: pagination.pageSize as number, },
            filters: Object.fromEntries(Object.entries(filters).filter(([key, value, ]) => value !== null)),
            sorter: sorter
              .filter(s => !!s.order)
              .map(s => ({ column: s.columnKey as TPrepaidCreditSortableListCol, order: s.order as IWithListSorterProps<IPreviewPrepaidCredit, TPrepaidCreditSortableListCol>['order'], })),
          });
        }}
        locale={{
          emptyText: <ListPageEmpty t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => resetQueryParams()} />,
        }}
      />
    </div>
  </div>;
};

export const PrepaidCreditListView = React.memo(withList<IPreviewPrepaidCredit, TPrepaidCreditSortableListCol>(PrepaidCreditList, {
  filters: {},
  sorter: [{ column: 'created_at', order: 'descend', }, ],
  pagination: { current: 1, pageSize: 10, },
}));
