import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateForm, ISharedUpdateForm, ISharedForm, IPreviewOrganization, IPreviewField, EnumOptions, FormTypeEnum, FormFieldConditionComparisonEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IFormFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  fields: Array<IPreviewField>;
}

export const FormFormOptions = (form: ISharedCreateForm | ISharedForm, props: IFormFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Form, {})), 'organization_id', false), 'organization'),
  __fields: (it: ISharedForm) => filterRelated<IPreviewField>(it.organization_id || form.organization_id, 'field', 'form', props.fields, true),
  __submission_display_fields: (it: ISharedForm) => filterRelated<IPreviewField>(it.organization_id || form.organization_id, 'field', 'form', props.fields, true),
  field_conditions_field_id: (it: ISharedForm) => filterRelated<IPreviewField>(it.organization_id || form.organization_id, 'field', 'form', props.fields, true),
  field_conditions_condition_field_id: (it: ISharedForm) => filterRelated<IPreviewField>(it.organization_id || form.organization_id, 'field', 'form', props.fields, true),
  type: EnumOptions('FORM_TYPE', FormTypeEnum, t),
  field_conditions_condition_comparison: EnumOptions('FORM_FIELD_CONDITION_COMPARISON', FormFieldConditionComparisonEnum, t),
});

export const FormComponents: IForm['components'] = {};
export const FormAdditionalParams: (auth: IAuth, props: IFormFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IFormFormOptionsProps) => ({
  __fields: {
    onModalAdd: {
      key: 'queue-__fields-add',
      path: '@Components/page-views/field',
      component: 'FieldFormAddView',
      item_text: 'FIELD',
    },
  }, 
  __submission_display_fields: {
    onModalAdd: {
      key: 'queue-__submission_display_fields-add',
      path: '@Components/page-views/field',
      component: 'FieldFormAddView',
      item_text: 'FIELD',
    },
  },
});

export const FormEditForm = (form: ISharedForm, props: IFormFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `form_edit_${form.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === form.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'type', },
    { ref: 'title', },
    { ref: 'display_title', },
    { ref: '__fields', },
    { ref: '__submission_display_fields', },
    { ref: 'field_conditions', },
    { ref: 'description', },
  ],
});

export const FormAddForm = (form: ISharedCreateForm, props: IFormFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'form_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'type', },
    { ref: 'title', },
    { ref: 'display_title', },
    { ref: '__fields', },
    { ref: '__submission_display_fields', },
    { ref: 'field_conditions', },
    { ref: 'description', },
  ],
});

export const FormAddInitialValues: ISharedCreateForm = {} as ISharedCreateForm;

export const FormEditInitialValues = (form: ISharedForm): ISharedUpdateForm => ({
  type: form.type,
  title: form.title,
  display_title: form.display_title,
  __fields: form.__fields,
  __submission_display_fields: form.__submission_display_fields,
  field_conditions: form.field_conditions,
  description: form.description,
});

export const onFormFormSaved = (t: TFunction, action: 'edit' | 'add', form: ISharedForm, navigate: NavigateFunction): void => {
  navigate(onFormSaved('forms'));
};
