import '../search.layout.scss';
import React, { useCallback, useEffect, useState, } from 'react';
import { useLocation, useParams, } from 'react-router-dom';
import { useAuth, } from '@Hooks';
import { ISharedConversation, RecentlyViewedSourceEnum, Nullable, } from 'atlas-shared';
import { createRecentlyViewedConversation, searchGlobalEntities, } from '@Api';
import { SearchLayoutRight, } from './search.layout.right';
import { SearchLayoutLeft, } from './search.layout.left';
import { isAdmin, } from '@Utils';

export const SearchLayout = React.memo(() => {
  const { id, organization_id, } = useParams<'id' | 'organization_id'>();
  const auth = useAuth();
  const location = useLocation();
  const [search, setSearch, ] = useState<Nullable<string>>(null);
  const [contactId, setContactId, ] = useState<Nullable<string>>(null);
  const [type, setType, ] = useState<Nullable<string>>(null);
  const [results, setResults, ] = useState([] as ISharedConversation[]);
  const [isLoading, setIsLoading, ] = useState<boolean>(false);

  const loadMore = useCallback((search, contactId, skip: number = 0) => {
    if (isLoading)
      return;

    setIsLoading(true);
    searchGlobalEntities(search || undefined, !isAdmin(auth.user) ? auth.user_status.agentprofile_id : null, contactId || undefined, 20, skip)
      .then(res => {
        setResults([
          ...results,
          ...res,
        ]);
      })
      .finally(() => {
        setIsLoading(false);
      })
    ;
  }, [results, search, ]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (
      search !== query.get('keyword')
      || type !== query.get('type')
      || contactId !== query.get('c')
    ) {
      setResults([]);
      setSearch(query.get('keyword'));
      setContactId(query.get('c'));
      setType(query.get('type'));
    }
  }, [location, ]);

  useEffect(() => {
    (search || contactId) && loadMore(search, contactId);
  }, [search, type, contactId, auth.user_status.agentprofile_id, ]);

  useEffect(() => {
    id && organization_id && createRecentlyViewedConversation({
      agentprofile_id: auth.user_status.agentprofile_id || null,
      user_id: auth.user.id,
      conversation_id: +id,
      organization_id: +organization_id,
      source: RecentlyViewedSourceEnum.Search,
    });
  }, [id, auth.user_status.agentprofile_id, auth.user.id, ]);

  return (
    <div className={'search-container'}>
      <SearchLayoutLeft
        conversations={results}
        shortened={Boolean(id)}
        search={search || undefined}
        contact_id={contactId ? +contactId : undefined}
        current={Number(id)}
        onScrollEnd={() => (results.length % 20) === 0 && loadMore(search, contactId, results.length)}
        isLoading={isLoading}
      />
      {id && organization_id && <SearchLayoutRight id={+id} organization_id={+organization_id} />}
    </div>
  );
});

export default SearchLayout;
