import { RestRequest, } from '@Utils';
import { IPreviewJourneyDraft, ISharedJourneyDraft, ISharedCreateJourneyDraft, ISharedPatchJourneyDraft, } from 'atlas-shared';

export const fetchJourneyDrafts = async (): Promise<Array<IPreviewJourneyDraft>> => {
  return RestRequest.get<Array<IPreviewJourneyDraft>>('journey_draft', { socket_id: global.socketId, });
};

export const fetchJourneyDraft = async (id: IPreviewJourneyDraft['id']): Promise<ISharedJourneyDraft> => {
  return RestRequest.get<ISharedJourneyDraft>(`journey_draft/${id}`, { socket_id: global.socketId, });
};

export const createJourneyDraft = (payload: ISharedCreateJourneyDraft) => {
  return RestRequest.post<any, ISharedJourneyDraft>('journey_draft', payload, { socket_id: global.socketId, });
};

export const patchJourneyDraft = (id: IPreviewJourneyDraft['id'], payload: ISharedPatchJourneyDraft) => {
  return RestRequest.patch<any, ISharedJourneyDraft>(`journey_draft/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteJourneyDraft = (id: IPreviewJourneyDraft['id']) => {
  return RestRequest.delete(`journey_draft/${id}`, { socket_id: global.socketId, });
};

export const getJourneyDraftUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('journey_draft/update/schema');
};

export const getJourneyDraftCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('journey_draft/create/schema');
};

export const getJourneyDraftPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('journey_draft/patch/schema');
};
