import React, { useEffect, useState, } from 'react';
import { ISharedConversationFilter, Undefinable, ConversationFilterSortOrderEnum, ISharedCreateConversationFilter, } from 'atlas-shared';
import { ObjectSchema, Schema, } from 'json-joi-converter';
import { Select, } from 'antd';
import { useTranslation, } from 'react-i18next';
import { createConversationFilter, patchConversationFilter, patchUserStatus, } from '@Api';
import { Icon, } from '@Components';
import { AscIcon, DescIcon, } from '@Assets/icons';
import { IAuth, } from '@Store';

const { Option, } = Select;

interface IProps {
  auth: IAuth;
  selected: Undefinable<ISharedConversationFilter>;
  schema: Undefinable<Schema>;
  default_filters: Array<ISharedCreateConversationFilter>;
}
export const ConversationFilterSort = React.memo((props: IProps) => {

  const { auth, selected, schema, default_filters, } = props;
  const { t, } = useTranslation();
  const [sortBy, setSortBy, ] = useState<Undefinable<ISharedConversationFilter['sort_by']>>();

  useEffect(() => {
    setSortBy(selected?.sort_by);
  }, [selected?.sort_by, ]);

  if (!selected || !schema || !sortBy)
    return <></>;

  const sort_by_schema: ObjectSchema = (schema as ObjectSchema).properties.sort_by as ObjectSchema;

  const patch = async (sort: Partial<ISharedConversationFilter['sort_by']>) => {

    if (selected.id < 0) {
      // @ts-ignore
      const { id, ...default_filter } = default_filters.find(f => f.id === selected.id);

      if (default_filter) {
        const conversation_filter = await createConversationFilter({ ...default_filter, title: `${default_filter.title} (${t('CUSTOM')})`, sort_by: { ...sortBy, ...sort, }, });

        if (conversation_filter)
          patchUserStatus(auth.user_status.id, { conversation_filter_id: conversation_filter.id, });

      }
    }
    else
      patchConversationFilter(selected.id, { sort_by: { ...sortBy, ...sort, }, });
  };

  return <div className='conversation-sort'>
    <Select
      value={sortBy.column}
      onChange={column => patch({ column, })}
      dropdownClassName='conversation-filter-sort-dropdown-list'
    >
      {sort_by_schema.properties.column.valid.map(opt => <Option value={opt} key={opt}>{t(opt.toUpperCase())}</Option>)}
    </Select>
    {sortBy.order === ConversationFilterSortOrderEnum.Desc && <Icon icon={DescIcon} onClick={e => patch({ order: ConversationFilterSortOrderEnum.Asc, })} />}
    {sortBy.order === ConversationFilterSortOrderEnum.Asc && <Icon icon={AscIcon} onClick={e => patch({ order: ConversationFilterSortOrderEnum.Desc, })} />}
  </div>;
});
