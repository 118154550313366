import { MailAccountActions, } from '@Store';
import { IPreviewMailAccount, } from 'atlas-shared';
import { fetchMailAccounts, } from '@Api';

export const actionFetchMailAccounts = () => {
  return async (dispatch) => {
    dispatch(actionLoadingMailAccounts());
    const mail_accounts = await fetchMailAccounts();

    dispatch(actionSetMailAccounts(mail_accounts));
    return mail_accounts;
  };
};

export const actionLoadingMailAccounts = () => ({
  type: MailAccountActions.LOADING,
  payload: [],
});

export const actionSetMailAccounts = (payload: Array<IPreviewMailAccount>) => ({
  type: MailAccountActions.SET_STATE,
  payload: payload,
});

export const actionAddMailAccount = (payload: IPreviewMailAccount) => ({
  type: MailAccountActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateMailAccount = (payload: IPreviewMailAccount) => ({
  type: MailAccountActions.UPDATE_STATE,
  payload: payload,
});
