import { RestRequest, } from '@Utils';
import { IPreviewNotificationUser, ISharedNotificationUser, ISharedPatchNotificationUser, } from 'atlas-shared';

export const fetchNotificationUsers = async (): Promise<Array<IPreviewNotificationUser>> => {
  return RestRequest.get<Array<IPreviewNotificationUser>>('notification_user', { socket_id: global.socketId, });
};

export const patchNotificationUser = (organization_id: IPreviewNotificationUser['organization_id'], id: IPreviewNotificationUser['id'], payload: ISharedPatchNotificationUser) => {
  return RestRequest.patch<any, ISharedNotificationUser>(`notification_user/p/${organization_id}/${id}`, payload, { socket_id: global.socketId, });
};

export const getNotificationUserPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('notification_user/patch/schema');
};
