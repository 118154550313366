import { useQueues, } from '@Hooks';
import React, { useEffect, useState, } from 'react';
import { InboxIcon, } from '@Assets/icons';
import { IPreviewConversation, IPreviewQueue, Undefinable, } from 'atlas-shared';
import { IconText, } from '@Components';

interface IProps {
  conversation: IPreviewConversation;
}

export const ConversationQueue = React.memo(({ conversation, }: IProps) => {
  const queues = useQueues();
  const [rowQueue, setRowQueue, ] = useState<Undefinable<IPreviewQueue>>();

  useEffect(() => {
    setRowQueue(queues.queues.find(queue => queue.id === conversation.queue_id));
  }, [conversation.queue_id, conversation.user_id, queues, rowQueue, ]);

  return (
    <div className={'queue'}>
      {rowQueue ? <IconText icon={InboxIcon} className={'icon-style'} iconProps={{ className:'icon-props', size: 10, }} textProps={{ className:'icon-text-props', }} text={(rowQueue?.title || '')}/> : ''}
    </div>
  );
});
