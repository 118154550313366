import { ISipAddAction, ISipRemoveAction, ISipUpdateAction, SipActions, } from '@Store';

export const actionAddSipUser = (payload: ISipAddAction['payload']) => ({
  type: SipActions.ADD_STATE,
  payload,
});

export const actionUpdateSipUser = (payload: ISipUpdateAction['payload']) => ({
  type: SipActions.UPDATE_STATE,
  payload,
});

export const actionRemoveSipUser = (payload: ISipRemoveAction['payload']) => ({
  type: SipActions.REMOVE_STATE,
  payload,
});
