import React from 'react';
import { ISvgIconProps, } from './interface';
import { SvgIcon, } from './SvgIcon';

export const GlobeIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="2" y1="12" x2="22" y2="12"></line>
    <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
  </SvgIcon>;
};

export const GlobeAddIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="2" y1="12" x2="22" y2="12"></line>
    <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
    <line x1="20" y1="16" x2="20" y2="24"></line>
    <line x1="16" y1="20" x2="24" y2="20"></line>
  </SvgIcon>;
};
