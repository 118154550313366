import { JourneyActions, } from '@Store';
import { IPreviewJourney, } from 'atlas-shared';
import { fetchJourneies, } from '@Api';

export const actionFetchJourneies = () => {
  return async (dispatch) => {
    dispatch(actionLoadingJourneies());
    const journeies = await fetchJourneies();

    dispatch(actionSetJourneies(journeies));
    return journeies;
  };
};

export const actionLoadingJourneies = () => ({
  type: JourneyActions.LOADING,
  payload: [],
});

export const actionSetJourneies = (payload: Array<IPreviewJourney>) => ({
  type: JourneyActions.SET_STATE,
  payload: payload,
});

export const actionAddJourney = (payload: IPreviewJourney) => ({
  type: JourneyActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateJourney = (payload: IPreviewJourney) => ({
  type: JourneyActions.UPDATE_STATE,
  payload: payload,
});
