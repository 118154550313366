import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import {
  CrudEnum,
  IPreviewInvoice,
  ISharedInvoice,
  PermissionEnum,
  SuperadminProfilePermissionIdEnum,
  TInvoiceSortableListCol,
} from 'atlas-shared';
import React from 'react';
import { ColumnsType, } from 'antd/lib/table/interface';
import { InvoiceListColumns as InvoiceListColumnsBase, } from './abstract/invoice.list.column.abstract';
import { Icon, } from '@Components';
import {
  DollarIcon,
  DollarStrokedIcon,
  FileTextIcon,
  InboxIcon,
  ListIcon, LockIcon,
  PdfIcon, PlusIcon,
  RefreshIcon,
  SendIcon,
} from '@Assets';
import {
  putInvoiceLock,
  putInvoiceNotPaid,
  putInvoicePaid, putInvoiceSend,
  reProcessInvoice,
  reProcessInvoiceTariffs,
} from '@Api';
import { AlertError, } from '@Utils/popup';
import { timeSince, } from '@Utils/time';
import moment from 'moment';
import { IWithListProps, } from '@Hocs/with.list';
import { invoicePdf, } from '@Utils/download';

export const InvoiceListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewInvoice) => React.ReactElement> = [], filters: Record<string, any>, sorter: IWithListProps<IPreviewInvoice, TInvoiceSortableListCol>['sorter']): ColumnsType<IPreviewInvoice> => {
  const cols = InvoiceListColumnsBase(
    t,
    navigate,
    showDeleted,
    organizations,
    abilities,
    auth,
    [
      (row: IPreviewInvoice) => row.locked_at && !row.sent_at && abilities.can(null, CrudEnum.Update, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={SendIcon} tooltip={{ title: t('SEND_INVOICE'), }} iconProps={{ size: 14, }} onClick={() => { putInvoiceSend(row.id).catch(err => AlertError(t, { content: err.toString(), }) ); }} /> : <></>,
      (row: IPreviewInvoice) => row.conversation_id && abilities.can(null, CrudEnum.Update, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={InboxIcon} tooltip={{ title: t('GO_TO_CONVERSATION'), }} iconProps={{ size: 14, }} onClick={() => { navigate(`/dashboard/conversation/${row.conversation_organization_id}/${row.conversation_id}`); }} /> : <></>,
      (row: IPreviewInvoice) => !row.locked_at && abilities.can(null, CrudEnum.Update, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={LockIcon} tooltip={{ title: t('LOCK_INVOICE'), }} iconProps={{ size: 14, }} onClick={() => putInvoiceLock(row.id).catch(err => AlertError(t, { content: err.toString(), }) )} /> : <></>,
      (row: IPreviewInvoice) => row.locked_at && abilities.can(null, CrudEnum.Read, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={PdfIcon} tooltip={{ title: t('INVOICE_PDF'), }} iconProps={{ size: 14, }} onClick={() => invoicePdf(row.id, `invoice-${row.id}.pdf`) } /> : <></>,
      (row: IPreviewInvoice) => abilities.can(null, CrudEnum.Read, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={FileTextIcon} tooltip={{ title: t('INVOICE_PRINT'), }} iconProps={{ size: 14, }} onClick={() => { navigate(`/admin/invoice/${row.id}`); }} /> : <></>,
      (row: IPreviewInvoice) => abilities.can(null, CrudEnum.Read, SuperadminProfilePermissionIdEnum.InvoicePost as unknown as PermissionEnum, row) ? <Icon icon={ListIcon} tooltip={{ title: t('INVOICE_POSTS'), }} iconProps={{ size: 14, }} onClick={() => { navigate(`/admin/invoice_posts/search?ff={"invoice_id":["${row.id}"]}`); }} /> : <></>,
      (row: IPreviewInvoice) => !row.locked_at && abilities.can(null, CrudEnum.Insert, SuperadminProfilePermissionIdEnum.InvoicePost as unknown as PermissionEnum, row) ? <Icon icon={PlusIcon} tooltip={{ title: t('ADD_CUSTOM_INVOICE_POST'), }} iconProps={{ size: 14, }} onClick={() => { navigate(`/admin/invoice_post/add?iv={"organization_id": ${row.organization_id}, "invoice_id": ${row.id}}`); }} /> : <></>,
      (row: IPreviewInvoice) => row.locked_at && !row.paid_at && abilities.can(null, CrudEnum.Update, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={DollarIcon} tooltip={{ title: t('MARK_AS_PAID'), }} iconProps={{ size: 14, }} onClick={() => { putInvoicePaid(row.id).catch(err => AlertError(t, { content: err.toString(), }) ); }} /> : <></>,
      (row: IPreviewInvoice) => row.locked_at && row.paid_at && abilities.can(null, CrudEnum.Update, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={DollarStrokedIcon} tooltip={{ title: t('MARK_AS_UNPAID'), }} iconProps={{ size: 14, }} onClick={() => { putInvoiceNotPaid(row.id).catch(err => AlertError(t, { content: err.toString(), }) ); }} /> : <></>,
      (row: IPreviewInvoice) => !row.locked_at && abilities.can(null, CrudEnum.Update, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={RefreshIcon} wrapperStyle={{ animation: row.processing ? '_spin 1.2s linear infinite' : undefined, }} tooltip={{ title: <div>
        <div>{t('RE_PROCESS')}</div>
        {row.processed_at && !row.processing ? <div>{t('PROCESSED_AT')}: {timeSince(t, new Date(row.processed_at), true)}</div> : <></>}
      </div>, }} iconProps={{ size: 14, }} onClick={() => {
        if (row.processing)
          return;

        reProcessInvoice(row.id).then(_ => {}, err => {
          AlertError(t, {
            content: err.toString(),
          });
        });

      }} /> : <></>,
      (row: IPreviewInvoice) => !row.locked_at && abilities.can(null, CrudEnum.Update, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={RefreshIcon} wrapperStyle={{ animation: row.processing ? '_spin 1.2s linear infinite' : undefined, }} tooltip={{ title: t('RE_PROCESS_TARIFFS_NO_RESET_TARIFF_ID'), }} iconProps={{ size: 14, }} onClick={() => {
        if (row.processing)
          return;

        reProcessInvoiceTariffs(row.id).then(_ => {}, err => {
          AlertError(t, {
            content: err.toString(),
          });
        });

      }} /> : <></>,
      (row: IPreviewInvoice) => !row.locked_at && abilities.can(null, CrudEnum.Update, SuperadminProfilePermissionIdEnum.Invoice as unknown as PermissionEnum, row) ? <Icon icon={RefreshIcon} wrapperStyle={{ animation: row.processing ? '_spin 1.2s linear infinite' : undefined, }} tooltip={{ title: t('RE_PROCESS_TARIFFS_WITH_RESET_TARIFF_ID'), }} iconProps={{ size: 14, }} onClick={() => {
        if (row.processing)
          return;

        reProcessInvoiceTariffs(row.id, true).then(_ => {}, err => {
          AlertError(t, {
            content: err.toString(),
          });
        });

      }} /> : <></>,
      ...buttons,
    ].filter(Boolean),
    filters,
    sorter
  );
  
  const dueDateIndex = cols.findIndex(col => col.key === 'due_date');
  
  if (dueDateIndex > -1) {
    cols[dueDateIndex].render = (due_date: ISharedInvoice['due_date']) => <div className='date-column'>{moment(due_date).format('YYYY-MM-DD')}</div>;
  }

  return cols;

};

export * from './abstract/invoice.list.column.abstract';
