import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchJourneyVariables, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useJourneyVariables = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const journey_variables = useSelector((store: IReducerStore) => store.journey_variables);

  useEffect(() => {
    if (!initiated && autoload && !journey_variables.loaded && !journey_variables.loading) {
      initiated = true;
      dispatch(actionFetchJourneyVariables());
    }
  }, [autoload, journey_variables.loaded, journey_variables.loading, dispatch, ]);

  return journey_variables;
};
