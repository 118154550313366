import { AuthActions, IAuth, } from '@Store';
import { IPreviewUser, } from 'atlas-shared';

export const actionSetAuth = (payload: IAuth) => ({
  type: AuthActions.SET_STATE,
  payload: payload,
});

export const authSetUserStatusState = (payload: IAuth['user_status']) => ({
  type: AuthActions.SET_USER_STATUS_STATE,
  payload: payload,
});

export const authSetUserState = (payload: IPreviewUser) => ({
  type: AuthActions.SET_USER_STATE,
  payload: payload,
});

export const actionRemoveAuth = () => ({
  type: AuthActions.REMOVE_STATE,
});
