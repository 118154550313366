import React, { useEffect, useState, } from 'react';
import '../admin.list.scss';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { ColumnsType, SorterResult, } from 'antd/lib/table/interface';
import { IPreviewVoiceRoute, PermissionEnum, CrudEnum, TVoiceRouteSortableListCol, } from 'atlas-shared';
import { Table, } from 'antd';
import { AdminListPageHeader, ListPageEmpty, } from '@Components';
import { IWithListProps, IWithListSorterProps, withList, } from '@Hocs/with.list';
import { useAbilities, useAuth, useVoiceRoutes, useOrganizations, useJourneies, useUsers, } from '@Hooks';
import { VoiceRouteListColumns, } from '@Utils';
import { IAuth, } from '@Store';

const VoiceRouteList = ({ filters, filtersCount, hasFilters, sorter, pagination, setQueryParams, resetQueryParams, }: IWithListProps<IPreviewVoiceRoute, TVoiceRouteSortableListCol>) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth: IAuth = useAuth();
  const voice_routes = useVoiceRoutes();
  const abilities = useAbilities();
  const [loading, setLoading, ] = useState<boolean>(true);
  const [rows, setRows, ] = useState<Array<any>>([]);
  const organizations = useOrganizations();
  const journeies = useJourneies();
  const users = useUsers();
  
  const [showDeleted, setShowDeleted, ] = useState(false);
  const [deletedCount, setDeletedCount, ] = useState<number>(0);
  
  useEffect(() => {
    if (!voice_routes.loaded)
      return;
      
    setLoading(false);
    setRows((!showDeleted ? voice_routes.voice_routes.filter(p => !p.deleted_at) : voice_routes.voice_routes || []).map(row => ({ ...row, key: row.id, })));
    setDeletedCount(voice_routes.voice_routes.filter(voice_route => !!voice_route.deleted_at).length);
  }, [voice_routes, showDeleted, ]);

  const columns: ColumnsType<IPreviewVoiceRoute> = React.useMemo(() => {
    return VoiceRouteListColumns(t, navigate, showDeleted, organizations, journeies, users, abilities, auth, [], filters, sorter);
  }, [t, navigate, showDeleted, organizations, journeies, users, abilities, auth, filters, sorter, ]);

  return <div className='admin-list-view'>
    <AdminListPageHeader 
      t={t}
      title={t('VOICE_ROUTES')}
      filtersCount={filtersCount}
      reset={() => resetQueryParams()}
      deleted={[showDeleted, setShowDeleted, ]} deletedCount={deletedCount}
      addBtn={abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.VoiceRoute) ? { callback: () => navigate('/admin/voice_route/add'), } : undefined} 
    />
    <div className='table-wrp'>
      <Table
        key={loading ? 1 : 0}
        columns={columns}
        dataSource={rows}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
        onChange={(pagination, filters, _sorter) => {
          const sorter: Array<SorterResult<any>> = Array.isArray(_sorter) ? _sorter : _sorter?.columnKey ? [_sorter, ] : [];

          setQueryParams({
            pagination: { current: pagination.current as number, pageSize: pagination.pageSize as number, },
            filters: Object.fromEntries(Object.entries(filters).filter(([key, value, ]) => value !== null)),
            sorter: sorter
              .filter(s => !!s.order)
              .map(s => ({ column: s.columnKey as TVoiceRouteSortableListCol, order: s.order as IWithListSorterProps<IPreviewVoiceRoute, TVoiceRouteSortableListCol>['order'], })),
          });
        }}
        locale={{
          emptyText: <ListPageEmpty t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => resetQueryParams()} />,
        }}
      />
    </div>
  </div>;
};

export const VoiceRouteListView = React.memo(withList<IPreviewVoiceRoute, TVoiceRouteSortableListCol>(VoiceRouteList, {
  filters: {},
  sorter: [{ column: 'created_at', order: 'descend', }, ],
  pagination: { current: 1, pageSize: 10, },
}));
