import { RestRequest, } from '@Utils/rest';

export const signout = async () => {
  const response = await RestRequest.get('auth/logout');

  if (localStorage.getItem('atlas-auth-personified'))
    localStorage.removeItem('atlas-auth-personified');
  else
    localStorage.removeItem('atlas-auth');

  window.location.pathname = '/';
  return response;
};
