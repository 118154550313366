import { AiAssistantActions, } from '@Store';
import { IPreviewAiAssistant, } from 'atlas-shared';
import { fetchAiAssistants, } from '@Api';

export const actionFetchAiAssistants = () => {
  return async (dispatch) => {
    dispatch(actionLoadingAiAssistants());
    const ai_assistants = await fetchAiAssistants();

    dispatch(actionSetAiAssistants(ai_assistants));
    return ai_assistants;
  };
};

export const actionLoadingAiAssistants = () => ({
  type: AiAssistantActions.LOADING,
  payload: [],
});

export const actionSetAiAssistants = (payload: Array<IPreviewAiAssistant>) => ({
  type: AiAssistantActions.SET_STATE,
  payload: payload,
});

export const actionAddAiAssistant = (payload: IPreviewAiAssistant) => ({
  type: AiAssistantActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateAiAssistant = (payload: IPreviewAiAssistant) => ({
  type: AiAssistantActions.UPDATE_STATE,
  payload: payload,
});
