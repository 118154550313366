import { IPreviewSession, } from 'atlas-shared';
import { ISessionStore, SessionActions, TSessionActionTypes, } from '@Store';

export const SessionReducer = (state: ISessionStore = {
  loaded: false,
  loading: false,
  sessions: [],
  dict: {},
}, action: TSessionActionTypes): ISessionStore => {
  switch (action.type) {
  case SessionActions.LOADING:
    return { ...state, loading: true, };
  case SessionActions.SET_STATE:
    const dict: ISessionStore['dict'] = {};

    action.payload.forEach((session: IPreviewSession) => dict[session.id] = session);

    return { ...state, loaded: true, loading: false, sessions: action.payload, dict, };
  case SessionActions.ADD_STATE:
    return { ...state, sessions: [...state.sessions, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case SessionActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, sessions: state.sessions.filter((item: IPreviewSession) => item.id !== action.payload), dict, };
  }
  case SessionActions.UPDATE_STATE:
    const sessions = [...state.sessions, ];
    const index = state.sessions.findIndex((session: IPreviewSession) => session.id === action.payload.id);

    if (!~index)
      sessions.push(action.payload);
    else
      sessions[index] = action.payload;

    return { ...state, sessions, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
