import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import DocViewer, { DocViewerRenderers, } from '@cyntler/react-doc-viewer';
import { useDocsPreview, } from '@Hooks';
import { actionDocsPreview, IDocumentPreview, useAppDispatch, } from '@Store';
import { Icon, } from '@Components';
import { ArrowLeftIcon, ArrowRightIcon, DownloadIcon, XIcon, } from '@Assets';
import { download, RestRequest, } from '@Utils';
import { getAttachmentToken, } from '@Api';
import { useTranslation, } from 'react-i18next';

const CustomNoRenderComponent = (({ document, fileName, }) => {
  const { t, } = useTranslation();

  return <div id='doc-preview-no-render'>
    <div>{t('NO_PREVIEW_AVAILABLE')}</div>
  </div>;

});

export const DocumentViewer = React.memo(() => {

  const dispatch = useAppDispatch();
  const docs = useDocsPreview();
  const close = useCallback(() => {
    dispatch(actionDocsPreview(null));
  }, []);
  const _docs = useMemo(() => docs && ({
    ...docs,
    docs: docs.docs.map(doc => ({ ...doc, uri: RestRequest.getFullSrc(doc.uri), })),
  }), [docs, ]);
  
  const [current, setCurrent, ] = useState<IDocumentPreview>();

  useEffect(() => {
    const current = _docs && _docs.docs[_docs.index];

    if (current) {
      const extension = current.filename.split('.').at(-1);

      if (extension && ['xlsx', 'xls', 'odt', 'docx', 'ppt', 'pptx', ].includes(extension)) {
        getAttachmentToken(current.uri).then(({ token, }) => {
          setCurrent({
            ...current,
            uri: `${current.uri}/${token}`,
            download_uri: current.uri,
          });
        });
      }
      else
        setCurrent(current);
    }
  }, [_docs?.index, ]);

  return _docs?.docs.length && current ? <div id='document-viewer'>
    <div id='document-viewer-bg' onClick={close}></div>
    <div id='document-viewer-body'>
      <div id='document-viewer-header'>
        <span className='filename'>{current.filename}</span>
        <Icon icon={DownloadIcon} onClick={() => download(current.download_uri || current.uri, current.filename)} />
        <div className='controls'>
          {_docs.index > 0 && <Icon icon={ArrowLeftIcon} onClick={() => dispatch(actionDocsPreview({ ..._docs, index: _docs.index - 1, }))}/>}
          <div className='of_page'>{_docs.index + 1}/{_docs.docs.length}</div>
          {_docs.index + 1 < _docs.docs.length && <Icon icon={ArrowRightIcon} onClick={() => dispatch(actionDocsPreview({ ..._docs, index: _docs.index + 1, }))}/>}
        </div>
      </div>
      <DocViewer
        documents={_docs.docs}
        initialActiveDocument={current}
        pluginRenderers={DocViewerRenderers}
        prefetchMethod="GET"
        requestHeaders={RestRequest.getHeaders()}
        config={{
          noRenderer: {
            overrideComponent: CustomNoRenderComponent,
          },
        }}
      />
    </div>
    <div id='document-viewer-right'>
      <div id='document-viewer-close' onClick={close}>
        <Icon icon={XIcon} iconProps={{ size: 36, }} />
      </div>
      {_docs.docs.length > 1 && <div id='document-viewer-file-list'>
        <ul>
          {_docs.docs.map((doc, i) => <li className={i === _docs.index ? 'active' : ''} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(actionDocsPreview({ ..._docs, index: i, }));
          }}>{doc.filename}</li>)}
        </ul>
      </div>}
    </div>

  </div> : <></>
  ;
});
