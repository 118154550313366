import { IPreviewVoiceRoute, } from 'atlas-shared';

export enum VoiceRouteActions {
  SET_STATE = 'VOICE_ROUTE_SET_STATE',
  ADD_STATE = 'VOICE_ROUTE_ADD_STATE',
  REMOVE_STATE = 'VOICE_ROUTE_REMOVE_STATE',
  GET_STATE = 'VOICE_ROUTE_GET_STATE',
  UPDATE_STATE = 'VOICE_ROUTE_UPDATE_STATE',
  LOADING = 'VOICE_ROUTE_LOADING',
}

export interface IVoiceRouteLoading {
  type: VoiceRouteActions.LOADING;
}

export interface IVoiceRouteSetAction {
  type: VoiceRouteActions.SET_STATE;
  payload: Array<IPreviewVoiceRoute>;
}

export interface IVoiceRouteUpdateAction {
  type: VoiceRouteActions.UPDATE_STATE;
  payload: IPreviewVoiceRoute;
}

export interface IVoiceRouteAddAction {
  type: VoiceRouteActions.ADD_STATE;
  payload: IPreviewVoiceRoute;
}

export interface IVoiceRouteRemoveAction {
  type: VoiceRouteActions.REMOVE_STATE;
  payload: IPreviewVoiceRoute['id'];
}

export interface IVoiceRouteStore {
  loaded: boolean;
  loading: boolean;
  voice_routes: Array<IPreviewVoiceRoute>;
  dict: Record<IPreviewVoiceRoute['id'], IPreviewVoiceRoute>;
}

export type TVoiceRouteActionTypes = IVoiceRouteSetAction | IVoiceRouteUpdateAction | IVoiceRouteAddAction | IVoiceRouteRemoveAction | IVoiceRouteLoading;
