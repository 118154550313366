import { IPreviewDisposition, } from 'atlas-shared';
import { IDispositionStore, DispositionActions, TDispositionActionTypes, } from '@Store';

export const DispositionReducer = (state: IDispositionStore = {
  loaded: false,
  loading: false,
  dispositions: [],
  dict: {},
}, action: TDispositionActionTypes): IDispositionStore => {
  switch (action.type) {
  case DispositionActions.LOADING:
    return { ...state, loading: true, };
  case DispositionActions.SET_STATE:
    const dict: IDispositionStore['dict'] = {};

    action.payload.forEach((disposition: IPreviewDisposition) => dict[disposition.id] = disposition);

    return { ...state, loaded: true, loading: false, dispositions: action.payload, dict, };
  case DispositionActions.ADD_STATE:
    return { ...state, dispositions: [...state.dispositions, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case DispositionActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, dispositions: state.dispositions.filter((item: IPreviewDisposition) => item.id !== action.payload), dict, };
  }
  case DispositionActions.UPDATE_STATE:
    const dispositions = [...state.dispositions, ];
    const index = state.dispositions.findIndex((disposition: IPreviewDisposition) => disposition.id === action.payload.id);

    if (!~index)
      dispositions.push(action.payload);
    else
      dispositions[index] = action.payload;

    return { ...state, dispositions, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
