import { RestRequest, } from '@Utils';
import { IPreviewSuperadminProfile, ISharedSuperadminProfile, ISharedCreateSuperadminProfile, ISharedPatchSuperadminProfile, } from 'atlas-shared';

export const fetchSuperadminProfiles = async (): Promise<Array<IPreviewSuperadminProfile>> => {
  return RestRequest.get<Array<IPreviewSuperadminProfile>>('superadmin_profile', { socket_id: global.socketId, });
};

export const fetchSuperadminProfile = async (id: IPreviewSuperadminProfile['id']): Promise<ISharedSuperadminProfile> => {
  return RestRequest.get<ISharedSuperadminProfile>(`superadmin_profile/${id}`, { socket_id: global.socketId, });
};

export const createSuperadminProfile = (payload: ISharedCreateSuperadminProfile) => {
  return RestRequest.post<any, ISharedSuperadminProfile>('superadmin_profile', payload, { socket_id: global.socketId, });
};

export const patchSuperadminProfile = (id: IPreviewSuperadminProfile['id'], payload: ISharedPatchSuperadminProfile) => {
  return RestRequest.patch<any, ISharedSuperadminProfile>(`superadmin_profile/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteSuperadminProfile = (id: IPreviewSuperadminProfile['id']) => {
  return RestRequest.delete(`superadmin_profile/${id}`, { socket_id: global.socketId, });
};

export const getSuperadminProfileUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('superadmin_profile/update/schema');
};

export const getSuperadminProfileCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('superadmin_profile/create/schema');
};

export const getSuperadminProfilePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('superadmin_profile/patch/schema');
};
