import { IPreviewListFile, } from 'atlas-shared';

export enum ListFileActions {
  SET_STATE = 'LIST_FILE_SET_STATE',
  ADD_STATE = 'LIST_FILE_ADD_STATE',
  REMOVE_STATE = 'LIST_FILE_REMOVE_STATE',
  GET_STATE = 'LIST_FILE_GET_STATE',
  UPDATE_STATE = 'LIST_FILE_UPDATE_STATE',
  LOADING = 'LIST_FILE_LOADING',
}

export interface IListFileLoading {
  type: ListFileActions.LOADING;
}

export interface IListFileSetAction {
  type: ListFileActions.SET_STATE;
  payload: Array<IPreviewListFile>;
}

export interface IListFileUpdateAction {
  type: ListFileActions.UPDATE_STATE;
  payload: IPreviewListFile;
}

export interface IListFileAddAction {
  type: ListFileActions.ADD_STATE;
  payload: IPreviewListFile;
}

export interface IListFileRemoveAction {
  type: ListFileActions.REMOVE_STATE;
  payload: IPreviewListFile['id'];
}

export interface IListFileStore {
  loaded: boolean;
  loading: boolean;
  list_files: Array<IPreviewListFile>;
  dict: Record<IPreviewListFile['id'], IPreviewListFile>;
}

export type TListFileActionTypes = IListFileSetAction | IListFileUpdateAction | IListFileAddAction | IListFileRemoveAction | IListFileLoading;
