import { RestRequest, } from '@Utils';
import { IPreviewVoiceAsteriskOperationalHours, ISharedVoiceAsteriskOperationalHours, ISharedSearchVoiceAsteriskOperationalHours, } from 'atlas-shared';

export const fetchVoiceAsteriskOperationalHours = async (id: IPreviewVoiceAsteriskOperationalHours['id']): Promise<ISharedVoiceAsteriskOperationalHours> => {
  return RestRequest.get<ISharedVoiceAsteriskOperationalHours>(`voice_asterisk_operational_hours/${id}`, { socket_id: global.socketId, });
};

export const searchVoiceAsteriskOperationalHours = async (params: ISharedSearchVoiceAsteriskOperationalHours): Promise<{items: Array<IPreviewVoiceAsteriskOperationalHours>; count: number}> => {
  return RestRequest.post<ISharedSearchVoiceAsteriskOperationalHours, {items: Array<IPreviewVoiceAsteriskOperationalHours>; count: number}>('voice_asterisk_operational_hours/search', params, { socket_id: global.socketId, });
};
