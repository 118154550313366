import { StatsAlarmActions, } from '@Store';
import { IPreviewStatsAlarm, } from 'atlas-shared';
import { fetchStatsAlarms, } from '@Api';

export const actionFetchStatsAlarms = () => {
  return async (dispatch) => {
    dispatch(actionLoadingStatsAlarms());
    const stats_alarms = await fetchStatsAlarms();

    dispatch(actionSetStatsAlarms(stats_alarms));
    return stats_alarms;
  };
};

export const actionLoadingStatsAlarms = () => ({
  type: StatsAlarmActions.LOADING,
  payload: [],
});

export const actionSetStatsAlarms = (payload: Array<IPreviewStatsAlarm>) => ({
  type: StatsAlarmActions.SET_STATE,
  payload: payload,
});

export const actionAddStatsAlarm = (payload: IPreviewStatsAlarm) => ({
  type: StatsAlarmActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateStatsAlarm = (payload: IPreviewStatsAlarm) => ({
  type: StatsAlarmActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveStatsAlarm = (id: IPreviewStatsAlarm['id']) => ({
  type: StatsAlarmActions.REMOVE_STATE,
  payload: id,
});
