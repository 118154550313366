import { PauseActions, } from '@Store';
import { IPreviewPause, } from 'atlas-shared';
import { fetchPauses, } from '@Api';

export const actionFetchPauses = () => {
  return async (dispatch) => {
    dispatch(actionLoadingPauses());
    const pauses = await fetchPauses();

    dispatch(actionSetPauses(pauses));
    return pauses;
  };
};

export const actionLoadingPauses = () => ({
  type: PauseActions.LOADING,
  payload: [],
});

export const actionSetPauses = (payload: Array<IPreviewPause>) => ({
  type: PauseActions.SET_STATE,
  payload: payload,
});

export const actionAddPause = (payload: IPreviewPause) => ({
  type: PauseActions.ADD_STATE,
  payload: payload,
});

export const actionUpdatePause = (payload: IPreviewPause) => ({
  type: PauseActions.UPDATE_STATE,
  payload: payload,
});
