import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchPhonenumberMonthlyCostParents, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const usePhonenumberMonthlyCostParents = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const phonenumber_monthly_cost_parents = useSelector((store: IReducerStore) => store.phonenumber_monthly_cost_parents);

  useEffect(() => {
    if (!initiated && autoload && !phonenumber_monthly_cost_parents.loaded && !phonenumber_monthly_cost_parents.loading) {
      initiated = true;
      dispatch(actionFetchPhonenumberMonthlyCostParents());
    }
  }, [autoload, phonenumber_monthly_cost_parents.loaded, phonenumber_monthly_cost_parents.loading, dispatch, ]);

  return phonenumber_monthly_cost_parents;
};
