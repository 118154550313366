import { TFunction, } from 'i18next';
import { useQueues, useUsers, } from '@Hooks';
import React, { useEffect, useState, } from 'react';
import { HeadphonesIcon, } from '@Assets/icons';
import { AlertError, getFullName, } from '@Utils';
import { Avatar, IconText, ConversationAssign, } from '@Components';
import Text from 'antd/lib/typography/Text';
import { IPreviewConversation, IPreviewQueue, IPreviewUser, } from 'atlas-shared';
import { patchConversation, } from '@Api/abstract/conversation.api.abstract';
import { IAuth, } from '@Store';
import { Tooltip, } from 'antd';

interface IProps {
  auth: IAuth;
  conversation: IPreviewConversation;
  shortened: boolean;
  t: TFunction;
  to_me: boolean;
  unassign?: boolean;
}

export const ConversationAgent = ({ auth, conversation, shortened, t, to_me, unassign, }: IProps) => {
  const users = useUsers();
  const queues = useQueues();
  const [rowAgent, setRowAgent, ] = useState<IPreviewUser>();
  const [queue, setQueue, ] = useState<IPreviewQueue>();

  useEffect(() => {
    setRowAgent(conversation.user_id ? users.dict[conversation.user_id] : undefined);
  }, [conversation.user_id, users, ]);

  useEffect(() => {
    if (conversation.queue_id)
      setQueue(queues.dict[conversation.queue_id]);
  }, [conversation.queue_id, queues, ]);

  return <div className='assign-wrapper' style={{ cursor: 'pointer', }}>
    {
      !conversation.user_id &&
      <>
        <IconText icon={HeadphonesIcon} className={'icon-style'} style={{ marginLeft: shortened ? 7 : 0, fontSize: 10, }} iconProps={{ className:'icon-props', size: 10, }} textProps={{ className:'icon-text-props', }} text={shortened ? '' : (rowAgent ? getFullName(rowAgent) : t('UNASSIGNED') )}/>
        <div className={'row-col'}>
          <ConversationAssign conversation={conversation}>
            <div className={'assign-btn'}>{t('ASSIGN')}</div>
          </ConversationAssign>
        </div>
        {to_me && queue?.__users.includes(auth.user.id) && <div className={'row-col'}>
          <Tooltip title={t('ASSIGN_CONVERSATION_TO_ME')}>
            <div className={'assign-btn'} onClick={() => patchConversation(conversation.organization_id, conversation.id, { user_id: auth.user.id, }).catch(e => AlertError(t, { content: t(e.toString()), }))}>{t('TO_ME')}</div>
          </Tooltip>
        </div>}
      </>
    }
    {
      conversation.user_id &&
      <>
        <ConversationAssign conversation={conversation}>
          <>
            <Avatar user={rowAgent} size={14} style={{ marginRight: 5, }} />
            <Text className={'icon-text-props ellipsis'} style={{ opacity: 0.5, fontSize: 10, whiteSpace: 'nowrap', }}>
              {rowAgent ? getFullName(rowAgent) : 'Unknown'}
            </Text>
          </>
        </ConversationAssign>
        {unassign && (conversation.user_id === auth.user.id || auth.user.__admin_organization_ids.includes(conversation.organization_id)) && <div className={'row-col'}>
          <Tooltip title={t('UNASSIGN')}>
            <div className={'unassign-btn'} onClick={() => patchConversation(conversation.organization_id, conversation.id, { user_id: null, }).catch(e => AlertError(t, { content: t(e.toString()), }))}>{t('UNASSIGN')}</div>
          </Tooltip>
        </div>}
      </>
    }
  </div>;
};
