import { ConversationFilterActions, } from '@Store';
import { IPreviewConversationFilter, } from 'atlas-shared';
import { fetchConversationFilters, } from '@Api';

export const actionFetchConversationFilters = () => {
  return async (dispatch) => {
    dispatch(actionLoadingConversationFilters());
    const conversation_filters = await fetchConversationFilters();

    dispatch(actionSetConversationFilters(conversation_filters));
    return conversation_filters;
  };
};

export const actionLoadingConversationFilters = () => ({
  type: ConversationFilterActions.LOADING,
  payload: [],
});

export const actionSetConversationFilters = (payload: Array<IPreviewConversationFilter>) => ({
  type: ConversationFilterActions.SET_STATE,
  payload: payload,
});

export const actionAddConversationFilter = (payload: IPreviewConversationFilter) => ({
  type: ConversationFilterActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateConversationFilter = (payload: IPreviewConversationFilter) => ({
  type: ConversationFilterActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveConversationFilter = (id: IPreviewConversationFilter['id']) => ({
  type: ConversationFilterActions.REMOVE_STATE,
  payload: id,
});
