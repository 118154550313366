import { IPreviewVoiceCall, } from 'atlas-shared';

export enum VoiceCallActions {
  SET_STATE = 'VOICE_CALL_SET_STATE',
  ADD_STATE = 'VOICE_CALL_ADD_STATE',
  REMOVE_STATE = 'VOICE_CALL_REMOVE_STATE',
  GET_STATE = 'VOICE_CALL_GET_STATE',
  UPDATE_STATE = 'VOICE_CALL_UPDATE_STATE',
  LOADING = 'VOICE_CALL_LOADING',
}

export interface IVoiceCallLoading {
  type: VoiceCallActions.LOADING;
}

export interface IVoiceCallSetAction {
  type: VoiceCallActions.SET_STATE;
  payload: Array<IPreviewVoiceCall>;
}

export interface IVoiceCallUpdateAction {
  type: VoiceCallActions.UPDATE_STATE;
  payload: IPreviewVoiceCall;
}

export interface IVoiceCallAddAction {
  type: VoiceCallActions.ADD_STATE;
  payload: IPreviewVoiceCall;
}

export interface IVoiceCallRemoveAction {
  type: VoiceCallActions.REMOVE_STATE;
  payload: IPreviewVoiceCall['id'];
}

export interface IVoiceCallStore {
  loaded: boolean;
  loading: boolean;
  voice_calls: Array<IPreviewVoiceCall>;
  dict: Record<IPreviewVoiceCall['id'], IPreviewVoiceCall>;
}

export type TVoiceCallActionTypes = IVoiceCallSetAction | IVoiceCallUpdateAction | IVoiceCallAddAction | IVoiceCallRemoveAction | IVoiceCallLoading;
