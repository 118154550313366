import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreatePhonenumberPurchase, ISharedUpdatePhonenumberPurchase, ISharedPhonenumberPurchase, IPreviewOrganization, EnumOptions, PhonenumberProviderEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface IPhonenumberPurchaseFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const PhonenumberPurchaseFormOptions = (phonenumber_purchase: ISharedCreatePhonenumberPurchase | ISharedPhonenumberPurchase, props: IPhonenumberPurchaseFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.PhonenumberPurchase, {})), 'organization_id', false), 'organization'),
  provider: EnumOptions('PHONENUMBER_PROVIDER', PhonenumberProviderEnum, t),
});

export const PhonenumberPurchaseComponents: IForm['components'] = {};
export const PhonenumberPurchaseAdditionalParams: (auth: IAuth, props: IPhonenumberPurchaseFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPhonenumberPurchaseFormOptionsProps) => ({
});

export const PhonenumberPurchaseEditForm = (phonenumber_purchase: ISharedPhonenumberPurchase, props: IPhonenumberPurchaseFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `phonenumber_purchase_edit_${phonenumber_purchase.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === phonenumber_purchase.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'enabled', },
  ],
});

export const PhonenumberPurchaseAddForm = (phonenumber_purchase: ISharedCreatePhonenumberPurchase, props: IPhonenumberPurchaseFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'phonenumber_purchase_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ui_type: FormElementTypes.PREVIEW, preview_value: phonenumber_purchase.provider, path: 'provider', shouldUpdate: () => false, },
    { ui_type: FormElementTypes.PREVIEW, preview_value: phonenumber_purchase.number, path: 'number', shouldUpdate: () => false, },
  ],
});

export const PhonenumberPurchaseAddInitialValues: ISharedCreatePhonenumberPurchase = {} as ISharedCreatePhonenumberPurchase;

export const PhonenumberPurchaseEditInitialValues = (phonenumber_purchase: ISharedPhonenumberPurchase): ISharedUpdatePhonenumberPurchase => ({
  enabled: phonenumber_purchase.enabled,
});

export const onPhonenumberPurchaseFormSaved = (t: TFunction, action: 'edit' | 'add', phonenumber_purchase: ISharedPhonenumberPurchase, navigate: NavigateFunction): void => {
  navigate(onFormSaved('phonenumber_purchases'));
};
