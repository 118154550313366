import React, { useEffect, useRef, useState, } from 'react';
import './tab.mini.scss';
import {
  ConversationWatcherSourceEnum,
  IPreviewTab
  , ISharedFullConversation, ChannelEnum, IPreviewVoiceCall, TabSizeEnum, } from 'atlas-shared';
import { TabInfo, } from '../tab.info';
import { TabHeader, } from '../tab.header';
import { TabMiniConversationList, } from './tab.mini.conversation.list';
import { IAuth, } from '@Store';
import { withFullConversation, IWithFullConversationProps, } from '@Hocs/with.full.conversation';
import { Skeleton, } from 'antd';
import { useFocusedConversation, useHighlightedMessage, } from '@Hooks';
import { AbsoluteActiveCalls, } from '@Components/tab/absolute.active-calls';
import { preventDefault, } from '@Utils';

interface IProps {
  tab: IPreviewTab;
  auth: IAuth;
  index: number;
  expandedTab?: IPreviewTab;
  onClose: () => Promise<IPreviewTab>;
  onMaximize: () => Promise<IPreviewTab>;
  onHeaderClick: () => Promise<IPreviewTab>;
  onFocus: (conversation: ISharedFullConversation) => void;
  onDragStart: (e: any, index: number) => void;
  onDragEnd: (e: any) => void;
  onDragOver: (index: number) => void;
}

const TabMiniComponent = React.memo((props: IProps & IWithFullConversationProps) => {
  const { conversation, onHeaderClick, onMaximize, onClose, contact_view, patch_conversation, auth, tab, index, onDragOver, onDragStart, onFocus, onDragEnd, expandedTab, customer_journey_view, } = props;
  const [infoVisible, setInfoVisible, ] = useState<boolean>(conversation?.messages.length === 1 && conversation.messages[0].channel === ChannelEnum.Voice && !(conversation.messages[0] as IPreviewVoiceCall).end_time);
  const [absoluteVoiceControllers, setAbsoluteVoiceControllers, ] = useState<boolean>(false);
  const focused_conversation = useFocusedConversation();
  const focused = focused_conversation === conversation?.id;
  const className = `tab-wrapper${focused ? ' focused' : ''}`;
  const minimized = expandedTab || tab.size !== TabSizeEnum.Mini;
  const scrollRef = useRef<HTMLDivElement>(null);
  const highlightedMessage = useHighlightedMessage(scrollRef);

  useEffect(() => {
    setAbsoluteVoiceControllers((infoVisible || tab.size === TabSizeEnum.Minimized) && !!conversation.active_calls.length);
  }, [infoVisible, tab.size, conversation.active_calls.length, ]);

  return <div
    onDragOver={e => onDragOver(index)}
    className={`${focused ? ' focused' : ''}`}
    onClick={preventDefault(() => conversation && onFocus(conversation))}
  >
    <div
      onDragStart={e => onDragStart(e, index)}
      onDragEnd={onDragEnd}
      draggable={true}
    >
      <div className={'tab-container ' + className}>
        <div className='tab-container-inner'>
          <div className={`tab-mini-view${absoluteVoiceControllers ? ' has-voice-controllers' : ''}`}>
            <TabHeader tab={tab} auth={auth} conversation={conversation} onClose={onClose} onClick={onHeaderClick} onMaximize={onMaximize} onMore={() => setInfoVisible(!infoVisible)} infoVisible={infoVisible} />
            {!minimized && <div className={'body'}>
              {
                infoVisible && <TabInfo
                  auth={auth}
                  contact_view={contact_view}
                  customer_journey_view={customer_journey_view}
                  patch_conversation={patch_conversation}
                  className={'info-view'}
                  onClose={() => setInfoVisible(false)}
                  conversation={conversation}
                />
              }
              {
                !infoVisible && <TabMiniConversationList
                  className={'list-view'}
                  conversationId={conversation.id}
                  source={ConversationWatcherSourceEnum.Tab}
                  conversation={conversation}
                  auth={auth}
                  highlightedMessage={highlightedMessage}
                  scrollRef={scrollRef}
                  tab={tab}
                />
              }
            </div>}
          </div>
          {absoluteVoiceControllers && conversation && <AbsoluteActiveCalls
            auth={auth}
            conversation={conversation}
            minimized={tab.size !== TabSizeEnum.Mini}
            highlightedMessage={highlightedMessage}
          />}
        </div>
      </div>
    </div>
  </div>;
});

export const TabMini = React.memo(withFullConversation<IProps>(TabMiniComponent, ConversationWatcherSourceEnum.Tab, true, <div style={{ width: '100%', }}>
  <Skeleton.Input active={true} size={'small'} style={{ margin: '10px 20px 10px 10px', height: 20, width: 220, display: 'flex', }} />
  <Skeleton.Button active={true} size={'small'} style={{ margin: '10px 0 10px 10px', height: 20, width: 20, display: 'flex', }} />
  <Skeleton.Button active={true} size={'small'} style={{ margin: '10px 0 10px 10px', height: 20, width: 20, display: 'flex', }} />
</div>));
