import React, { useMemo, } from 'react';
import './conversation.form.preview.scss';
import { IPreviewForm, ISharedFullConversation, } from 'atlas-shared';
import { useFields, } from '@Hooks';
import { useTranslation, } from 'react-i18next';
import { AppSpinner, } from '@Components';

interface IProps {
  conversation: ISharedFullConversation;
  form?: IPreviewForm;
}

export const ConversationFormTablePreview = React.memo(({ conversation, form, }: IProps) => {
  const fields = useFields();
  const { t, } = useTranslation();

  const _fields = useMemo(() => {
    const _fields = (fields.loaded && form?.__fields || []).map(field_id => {
      const field = fields.dict[field_id];
      const value = conversation.data[field.alias];

      let translated_value: string = value;

      if (value === true)
        translated_value = t('YES');

      else if (value === false)
        translated_value = t('NO');
      else if (Array.isArray(translated_value))
        translated_value = translated_value.join(', ');

      let rows = [
        <tr>
          <td>{field.display_title || field.title}</td>
          <td>{translated_value}</td>
        </tr>,
      ];

      field.settings?.validation?.valid?.forEach(option => {
        if (option.custom_answer)
          rows.push(<tr>
            <td>{option.title}</td>
            <td>{conversation.data[`${field.alias}___${option.alias}`]}</td>
          </tr>);
      });

      return rows;
    });

    return _fields;
  }, [form?.__fields, fields, conversation.data, ]);

  if (!form)
    return <></>;

  if (!fields.loaded)
    return <AppSpinner />;

  return <div className='conversation-form-table-preview'>
    <table>
      {_fields}
    </table>
  </div>;
});
