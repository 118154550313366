import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import React from 'react';
import { IWithListProps, } from '@Hocs/with.list';
import { ColumnsType, } from 'antd/lib/table/interface';
import { VoiceAsteriskToggleHistoryListColumns as VoiceAsteriskToggleHistoryListColumnsBase, } from './abstract/voice_asterisk_toggle_history.list.column.abstract';
import { IPreviewVoiceAsteriskToggleHistory, TVoiceAsteriskToggleHistorySortableListCol, } from 'atlas-shared/dist';
import { tableColumnBooleanRender, } from '@Utils/table';
import { Icon, } from '@Components';
import { InfoIcon, } from '@Assets';

export const VoiceAsteriskToggleHistoryListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceAsteriskToggleHistory) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceAsteriskToggleHistory, TVoiceAsteriskToggleHistorySortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceAsteriskToggleHistory, TVoiceAsteriskToggleHistorySortableListCol>['sorter']): ColumnsType<IPreviewVoiceAsteriskToggleHistory> => {
  const columns = [...VoiceAsteriskToggleHistoryListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, buttons, filters, sorter), ];
  
  const is_downgraded_index = columns.findIndex(c => c.key === 'is_downgraded');
  const can_toggle_index = columns.findIndex(c => c.key === 'can_toggle');

  columns[is_downgraded_index].title = t('STATUS');
  columns[is_downgraded_index].render = (is_downgraded: IPreviewVoiceAsteriskToggleHistory['is_downgraded']) => t(is_downgraded ? 'DOWNGRADED' : 'INITIAL');

  columns[can_toggle_index].title = t('DID_TOGGLE');
  columns[can_toggle_index].render = (can_toggle: IPreviewVoiceAsteriskToggleHistory['can_toggle'], voice_asterisk_toggle_history: IPreviewVoiceAsteriskToggleHistory) => <div className='with-info'>{tableColumnBooleanRender(t, can_toggle)}{voice_asterisk_toggle_history.details && <Icon icon={InfoIcon} title={t('REASON')} tooltip={{ title: [
    voice_asterisk_toggle_history.details?.booting && t('BOOTING_UP'),
    voice_asterisk_toggle_history.details?.downgrade_running === false && t('DOWNGRADE_ASTERISK_OFFLINE'),
    voice_asterisk_toggle_history.details?.toggle_failed === true && t('TOGGLE_FAILED'),
    voice_asterisk_toggle_history.details?.voice_calls?.length && t('X_ONGOING_CALLS', { calls: voice_asterisk_toggle_history.details.voice_calls.length, }),
    voice_asterisk_toggle_history.details?.user_statuses?.length && t('X_USER_STATUSES', { users: voice_asterisk_toggle_history.details.user_statuses.length, }),
  ].filter(Boolean).join(` ${t('AND').toLowerCase()} `) + ' ' + t('PREVENTED_ASTERISK_TOGGLE'), }} />}</div>;

  return columns;

};

export * from './abstract/voice_asterisk_toggle_history.list.column.abstract';
