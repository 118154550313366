import { IPreviewVoiceChanspy, } from 'atlas-shared';

export enum VoiceChanspyActions {
  SET_STATE = 'VOICE_CHANSPY_SET_STATE',
  ADD_STATE = 'VOICE_CHANSPY_ADD_STATE',
  REMOVE_STATE = 'VOICE_CHANSPY_REMOVE_STATE',
  GET_STATE = 'VOICE_CHANSPY_GET_STATE',
  UPDATE_STATE = 'VOICE_CHANSPY_UPDATE_STATE',
  LOADING = 'VOICE_CHANSPY_LOADING',
}

export interface IVoiceChanspyLoading {
  type: VoiceChanspyActions.LOADING;
}

export interface IVoiceChanspySetAction {
  type: VoiceChanspyActions.SET_STATE;
  payload: Array<IPreviewVoiceChanspy>;
}

export interface IVoiceChanspyUpdateAction {
  type: VoiceChanspyActions.UPDATE_STATE;
  payload: IPreviewVoiceChanspy;
}

export interface IVoiceChanspyAddAction {
  type: VoiceChanspyActions.ADD_STATE;
  payload: IPreviewVoiceChanspy;
}

export interface IVoiceChanspyRemoveAction {
  type: VoiceChanspyActions.REMOVE_STATE;
  payload: IPreviewVoiceChanspy['id'];
}

export interface IVoiceChanspyStore {
  loaded: boolean;
  loading: boolean;
  voice_chanspies: Array<IPreviewVoiceChanspy>;
  dict: Record<IPreviewVoiceChanspy['id'], IPreviewVoiceChanspy>;
}

export type TVoiceChanspyActionTypes = IVoiceChanspySetAction | IVoiceChanspyUpdateAction | IVoiceChanspyAddAction | IVoiceChanspyRemoveAction | IVoiceChanspyLoading;
