import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedUpdateInvoice, ISharedInvoice, IPreviewOrganization, EnumOptions, InvoiceCurrencyEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface IInvoiceFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const InvoiceFormOptions = (invoice: ISharedInvoice, props: IInvoiceFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Invoice, {})), 'organization_id', false), 'organization'),
  currency: EnumOptions('INVOICE_CURRENCY', InvoiceCurrencyEnum, t),
});

export const InvoiceComponents: IForm['components'] = {};
export const InvoiceAdditionalParams: (auth: IAuth, props: IInvoiceFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IInvoiceFormOptionsProps) => ({
});

export const InvoiceEditForm = (invoice: ISharedInvoice, props: IInvoiceFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `invoice_edit_${invoice.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === invoice.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'due_date', },
  ],
});

export const InvoiceEditInitialValues = (invoice: ISharedInvoice): ISharedUpdateInvoice => ({
  title: invoice.title,
  due_date: invoice.due_date,
});

export const onInvoiceFormSaved = (t: TFunction, action: 'edit' | 'add', invoice: ISharedInvoice, navigate: NavigateFunction): void => {
  navigate(onFormSaved('invoices'));
};
