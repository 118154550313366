import { useParams, } from 'react-router-dom';
import { PasswordResetForm, } from '@Components/user/password-reset/password-reset-form';

export const PasswordResetPage = () => {
  const { user_id, } = useParams<'user_id'>();

  if (!user_id)
    return <>no user_id!</>;
  
  return <div className='entity-form-edit'>
    <PasswordResetForm user_id={+user_id} />
  </div>;

};
