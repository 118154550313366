import { IPreviewSignature, } from 'atlas-shared';

export enum SignatureActions {
  SET_STATE = 'SIGNATURE_SET_STATE',
  ADD_STATE = 'SIGNATURE_ADD_STATE',
  REMOVE_STATE = 'SIGNATURE_REMOVE_STATE',
  GET_STATE = 'SIGNATURE_GET_STATE',
  UPDATE_STATE = 'SIGNATURE_UPDATE_STATE',
  LOADING = 'SIGNATURE_LOADING',
}

export interface ISignatureLoading {
  type: SignatureActions.LOADING;
}

export interface ISignatureSetAction {
  type: SignatureActions.SET_STATE;
  payload: Array<IPreviewSignature>;
}

export interface ISignatureUpdateAction {
  type: SignatureActions.UPDATE_STATE;
  payload: IPreviewSignature;
}

export interface ISignatureAddAction {
  type: SignatureActions.ADD_STATE;
  payload: IPreviewSignature;
}

export interface ISignatureRemoveAction {
  type: SignatureActions.REMOVE_STATE;
  payload: IPreviewSignature['id'];
}

export interface ISignatureStore {
  loaded: boolean;
  loading: boolean;
  signatures: Array<IPreviewSignature>;
  dict: Record<IPreviewSignature['id'], IPreviewSignature>;
}

export type TSignatureActionTypes = ISignatureSetAction | ISignatureUpdateAction | ISignatureAddAction | ISignatureRemoveAction | ISignatureLoading;
