import { NavigateFunction, } from 'react-router';
import {
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { ISharedCreatePhonenumberSetupCost, ISharedUpdatePhonenumberSetupCost, ISharedPhonenumberSetupCost, IPreviewPhonenumberSetupCostParent, IPreviewOrganization, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { entitiesToOptions, onFormSaved, } from '@Utils';

export interface IPhonenumberSetupCostFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  phonenumber_setup_cost_parents: Array<IPreviewPhonenumberSetupCostParent>;
  organizations: Array<IPreviewOrganization>;
}

export const PhonenumberSetupCostFormOptions = (phonenumber_setup_cost: ISharedCreatePhonenumberSetupCost | ISharedPhonenumberSetupCost, props: IPhonenumberSetupCostFormOptionsProps, t: TFunction): IFormOptions => ({
  phonenumber_setup_cost_parent_id: entitiesToOptions<IPreviewPhonenumberSetupCostParent>(props.phonenumber_setup_cost_parents, 'phonenumber_setup_cost_parent'),
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  
});

export const PhonenumberSetupCostComponents: IForm['components'] = {};
export const PhonenumberSetupCostAdditionalParams: (auth: IAuth, props: IPhonenumberSetupCostFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPhonenumberSetupCostFormOptionsProps) => ({
});

export const PhonenumberSetupCostEditForm = (phonenumber_setup_cost: ISharedPhonenumberSetupCost, props: IPhonenumberSetupCostFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `phonenumber_setup_cost_edit_${phonenumber_setup_cost.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'phonenumber_setup_cost_parent_id', },
    { ref: 'title', },
    { ref: 'phonenumber_setup_cost_list', },
  ],
});

export const PhonenumberSetupCostAddForm = (phonenumber_setup_cost: ISharedCreatePhonenumberSetupCost, props: IPhonenumberSetupCostFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'phonenumber_setup_cost_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'phonenumber_setup_cost_parent_id', },
    { ref: 'title', },
    { ref: 'phonenumber_setup_cost_list', },
  ],
});

export const PhonenumberSetupCostAddInitialValues: ISharedCreatePhonenumberSetupCost = {} as ISharedCreatePhonenumberSetupCost;

export const PhonenumberSetupCostEditInitialValues = (phonenumber_setup_cost: ISharedPhonenumberSetupCost): ISharedUpdatePhonenumberSetupCost => ({
  phonenumber_setup_cost_parent_id: phonenumber_setup_cost.phonenumber_setup_cost_parent_id,
  title: phonenumber_setup_cost.title,
  phonenumber_setup_cost_list: phonenumber_setup_cost.phonenumber_setup_cost_list,
});

export const onPhonenumberSetupCostFormSaved = (t: TFunction, action: 'edit' | 'add', phonenumber_setup_cost: ISharedPhonenumberSetupCost, navigate: NavigateFunction): void => {
  navigate(onFormSaved('phonenumber_setup_costs'));
};
