import React from 'react';
import { ISvgIconProps, } from './interface';
import { SvgIcon, } from './SvgIcon';

export const GoogleIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.18997 7.02577C5.03174 3.17777 8.87911 0.54541 13.3538 0.54541C16.2363 0.54541 18.8094 1.65632 20.8168 3.4545L17.5229 6.83959C16.3906 5.88759 14.9493 5.30577 13.3538 5.30577C10.384 5.30577 7.87702 7.27596 6.99842 10.0185" fill="#EA4335"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.18997 7.02588C2.44725 8.57788 2.03077 10.3273 2.03077 12.1819C2.03077 14.0364 2.44725 15.7859 3.18997 17.3379L6.99841 14.3452C6.77531 13.647 6.66207 12.9167 6.66297 12.1819C6.66207 11.447 6.77531 10.7168 6.99841 10.0186L3.18997 7.02588ZM6.99841 10.0186L3.18997 7.02588L6.99841 10.0186Z" fill="#FBBC05"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.734 20.92L17.1188 18.0436C18.301 17.2771 19.1233 16.0945 19.4273 14.5622H13.3538V10.0662H23.9048C24.0591 10.7538 24.1621 11.4942 24.1621 12.1818C24.1621 15.9745 22.8118 18.9345 20.734 20.92Z" fill="#4285F4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.734 20.9202C18.7549 22.8115 16.1156 23.8184 13.3538 23.8184C8.87804 23.8184 5.02961 21.1845 3.18855 17.3347L6.99522 14.3354C7.87099 17.0831 10.3805 19.058 13.3538 19.058C14.8089 19.058 16.0979 18.706 17.1188 18.0438L20.734 20.9202Z" fill="#34A853"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.01305 6.84413C4.85482 2.99613 8.70218 0.36377 13.1769 0.36377C16.0594 0.36377 18.6325 1.47468 20.6399 3.27286L17.3459 6.65795C16.2136 5.70595 14.7724 5.12413 13.1769 5.12413C10.2071 5.12413 7.70009 7.09432 6.82149 9.83686" fill="#EA4335"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.01304 6.84424C2.27032 8.39624 1.85384 10.1457 1.85384 12.0002C1.85384 13.8548 2.27032 15.6042 3.01304 17.1562L6.82149 14.1635C6.59838 13.4654 6.48514 12.7351 6.48604 12.0002C6.48514 11.2654 6.59838 10.5351 6.82149 9.83697L3.01304 6.84424ZM6.82149 9.83697L3.01304 6.84424L6.82149 9.83697Z" fill="#FBBC05"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.5571 20.7383L16.9418 17.862C18.124 17.0954 18.9464 15.9129 19.2503 14.3805H13.1769V9.88452H23.7279C23.8822 10.5722 23.9851 11.3125 23.9851 12.0002C23.9851 15.7929 22.6349 18.7529 20.5571 20.7383Z" fill="#4285F4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.5571 20.7385C18.578 22.6298 15.9387 23.6367 13.1769 23.6367C8.70111 23.6367 4.85268 21.0029 3.01162 17.1531L6.8183 14.1538C7.69407 16.9014 10.2035 18.8764 13.1769 18.8764C14.6319 18.8764 15.921 18.5244 16.9418 17.8622L20.5571 20.7385Z" fill="#34A853"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
      <clipPath id="clip1">
        <rect width="23" height="24" fill="white" transform="translate(1.5)"/>
      </clipPath>
    </defs>
  </SvgIcon>;
};
