import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchConversations, IConversationStore, useAppDispatch, } from '@Store';
import { useAuth, } from './use.auth';
import { ISharedConversationFilter, } from 'atlas-shared';

const useConversations = (autoload: boolean, conversation_filter_id: ISharedConversationFilter['id'] = -1) => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  let conversations = useSelector((s: any) => s.conversations) as IConversationStore;

  useEffect(() => {
    autoload && auth && auth.user_status.agentprofile_id && !conversations.loaded && !conversations.loading && dispatch(actionFetchConversations(conversation_filter_id, auth.user_status.agentprofile_id));
  }, [autoload, auth, conversations.loaded, conversations.loading, dispatch, conversation_filter_id, auth.user_status.agentprofile_id, ]);

  return conversations;
};

export { useConversations, };
