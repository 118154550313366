import { IPreviewVoiceAsterisk, Undefinable, IPreviewAgentprofile, } from 'atlas-shared';
import { RegistererState, } from 'sip.js';

export enum SipActions {
  ADD_STATE = 'SIP_ADD_STATE',
  UPDATE_STATE = 'SIP_UPDATE_STATE',
  REMOVE_STATE = 'SIP_REMOVE_STATE'
}

export interface ISipStore {
  sip_users: Array<ISipStoreSip>;
}

export interface ISipAddAction {
  type: SipActions.ADD_STATE;
  payload: {
    agentprofile_id?: IPreviewAgentprofile['id'];
    voice_asterisk: IPreviewVoiceAsterisk;
  };
}

export interface ISipUpdateAction {
  type: SipActions.UPDATE_STATE;
  payload: Partial<ISipStoreSip> & { voice_asterisk: IPreviewVoiceAsterisk };
}

export interface ISipRemoveAction {
  type: SipActions.REMOVE_STATE;
  payload: { voice_asterisk: IPreviewVoiceAsterisk };
}

export interface ISipStoreSession {
  key: string;
  last_update: number;
  emergency?: string;
}

export interface ISipStoreSip {
  agentprofile_id?: IPreviewAgentprofile['id'];
  state: Undefinable<RegistererState>;
  sessions: Record<string, ISipStoreSession>;
  voice_asterisk: IPreviewVoiceAsterisk;
}

export type TSipActionTypes = ISipAddAction | ISipUpdateAction | ISipRemoveAction;
