import React from 'react';
import './stats_hidden_by_choice.scss';
import { Icon, } from '@Components';
import { EyeIcon, } from '@Assets';
import { TFunction, } from 'i18next';
import { patchUser, } from '@Api';
import { IAuth, } from '@Store';

interface IProps {
  t: TFunction;
  auth: IAuth;
}

export const StatsHiddenByChoice = React.memo((props: IProps) => {
  const { t, auth, } = props;

  return <div className='stats-hidden-by-choice'>
    <Icon
      icon={EyeIcon}
      tooltip={{
        title: t('DISPLAY_STATS_PREVIEW'),
        placement: 'left',
      }}
      onClick={() => {
        return patchUser(auth.user.id, {
          settings: {
            ...auth.user.settings,
            hide_stats_preview: false,
          },
        });
      }}
    />
  </div>;
});
