import React, { useCallback, useEffect, useState, } from 'react';
import * as Joi from 'joi';
import { useSchema, } from '@Hooks';
import { NavigateFunction, } from 'react-router';
import { JSONForm, } from '@Modules/atlas-form-core';
import { clone, queryParam, } from '@Utils';

export interface IWithAddProps<T>{
  saving: boolean;
  schemaLoading: boolean;
  formSchema: Joi.Schema;
  save: (cb: ()=> Promise<void>)=> void;
  values: T;
  setValues: (values: T) => void;
  initialValues?: Partial<T>;
  title?: string;
  hasAdvanced?: boolean;
  onSaved?: (res: Record<string, any>, navigate: NavigateFunction) => void;
  onCancel?: () => void;
  onChange?: (values: Record<string, any>, changedValue: any, form?: JSONForm) => void;
}
export function withAdd <T>(Component: React.JSXElementConstructor<any>, schemaFetcher: () => Promise<any>, initialValues: T | undefined) {
  return function (props: any) {
    const [formSchema, schemaLoading, ] = useSchema(schemaFetcher);
    const [saving, setSaving, ] = useState<boolean>(false);
    const [values, setValues, ] = useState(initialValues || {});
    const [loaded, setLoaded, ] = useState(false);
    const _initialValues = clone({ ...(initialValues || {}), ...JSON.parse(queryParam('iv') || '{}'), });

    useEffect(() => {
      setLoaded(true);
    }, []);

    const save = useCallback(async (cb: ()=> Promise<void>) => {
      setSaving(true);
      try {
        await cb();
      } catch (e) {
        console.error(e);
      } finally {
        setSaving(false);
      }
    }, [setSaving, ]);

    return <div className='entity-form-add'>
      {loaded && <Component values={values} setValues={setValues} save={save} saving={saving} formSchema={formSchema} schemaLoading={schemaLoading} {...props} initialValues={{ ..._initialValues, ...props.initialValues, }} />}
    </div>;
  };
}
