import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateAiAssistant, ISharedUpdateAiAssistant, ISharedAiAssistant, IPreviewOrganization, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface IAiAssistantFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const AiAssistantFormOptions = (ai_assistant: ISharedCreateAiAssistant | ISharedAiAssistant, props: IAiAssistantFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.AiAssistant, {})), 'organization_id', false), 'organization'),
  
});

export const AiAssistantComponents: IForm['components'] = {};
export const AiAssistantAdditionalParams: (auth: IAuth, props: IAiAssistantFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IAiAssistantFormOptionsProps) => ({
});

export const AiAssistantEditForm = (ai_assistant: ISharedAiAssistant, props: IAiAssistantFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `ai_assistant_edit_${ai_assistant.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === ai_assistant.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'kb_unique_id', },
  ],
});

export const AiAssistantAddForm = (ai_assistant: ISharedCreateAiAssistant, props: IAiAssistantFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'ai_assistant_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'kb_unique_id', },
  ],
});

export const AiAssistantAddInitialValues: ISharedCreateAiAssistant = {} as ISharedCreateAiAssistant;

export const AiAssistantEditInitialValues = (ai_assistant: ISharedAiAssistant): ISharedUpdateAiAssistant => ({
  title: ai_assistant.title,
  kb_unique_id: ai_assistant.kb_unique_id,
});

export const onAiAssistantFormSaved = (t: TFunction, action: 'edit' | 'add', ai_assistant: ISharedAiAssistant, navigate: NavigateFunction): void => {
  navigate(onFormSaved('ai_assistants'));
};
