import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  VoiceShortcutAddForm,
  VoiceShortcutAddInitialValues,
  VoiceShortcutComponents,
  VoiceShortcutAdditionalParams,
  VoiceShortcutFormOptions,
  onVoiceShortcutFormSaved,
  IVoiceShortcutFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateVoiceShortcut, PermissionEnum, } from 'atlas-shared';
import { createVoiceShortcut, fetchVoiceShortcut, getVoiceShortcutCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateVoiceShortcut> {}

const VoiceShortcutFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateVoiceShortcut>>();

  const param_props: IVoiceShortcutFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
  };
  const formLayout = useMemo(() => VoiceShortcutAddForm(values, param_props, t), [values, organizations, t, abilities, ]);
  const options: IFormOptions = useMemo(() => VoiceShortcutFormOptions(values || VoiceShortcutAddInitialValues, param_props, t), [values, organizations, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateVoiceShortcut, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateVoiceShortcut) => save(() => createVoiceShortcut(values).then(
    res => onSaved ? onSaved(res, navigate) : onVoiceShortcutFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchVoiceShortcut(+id)
        .then(({
          organization_id,
          title,
          number,
        }) => {
          setCloned({
            organization_id,
            title,
            number,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.VoiceShortcut, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={VoiceShortcutComponents}
        additionalParams={VoiceShortcutAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...VoiceShortcutAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('VOICE_SHORTCUT')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const VoiceShortcutFormAddView = React.memo(withAdd(VoiceShortcutFormAdd, getVoiceShortcutCreateSchema, VoiceShortcutAddInitialValues));
