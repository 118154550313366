import React, { useState, useEffect, } from 'react';
import { ISharedPause, Nullable, IPreviewPause, UserStatusStatusEnum, getTopBitwiseMember, } from 'atlas-shared';
import './header.user.status.scss';
import { Switch, } from 'antd';
import { ITimerProps, Timer, Icon, } from '@Components';
import { patchUserStatus, } from '@Api';
import { useAuth, usePauses, } from '@Hooks';
import { HeaderUserStatusPausesPopover, } from './header.user.status.pauses.popover';
import { ChevronDownIcon, } from '@Assets/icons';
import { UserStatusColors, } from '@Utils';
import { useTranslation, } from 'react-i18next';

export const HeaderUserStatus = React.memo(() => {
  const [counterStartTime, setCounterStartTime, ] = useState<ITimerProps['base']>(null);
  const [selectedPause, setSelectedPause, ] = useState<Nullable<IPreviewPause>>(null);
  const [status, setStatus, ] = useState<UserStatusStatusEnum>(UserStatusStatusEnum.Online);
  const auth = useAuth();
  const pauses = usePauses();
  const { t, } = useTranslation();

  useEffect(() => {
    if (!pauses.loaded)
      return;

    setSelectedPause(auth.user_status.pause_id === -1 ? { id: -1, title: 'ACW', } as ISharedPause : auth.user_status.pause_id ? pauses.dict[auth.user_status.pause_id] : null);
    setCounterStartTime(auth.user_status.busy_since || auth.user_status.pause_since || auth.user_status.idle_since || null);
  }, [pauses, auth.user_status.pause_id, auth.user_status.idle_since, auth.user_status.pause_since, pauses.loaded, pauses.dict, ]);

  useEffect(() => {
    setStatus(getTopBitwiseMember<UserStatusStatusEnum>(UserStatusStatusEnum, auth.user_status.status));
  }, [auth.user_status.status, ]);

  return (
    <div className={'header-user-status'} >
      <HeaderUserStatusPausesPopover>
        <div className={'box'} >
          <div className={`pause-action-wrp${auth.user_status.pause_id ? ' paused' : ''}`} >
            <div className={'pause-time-switch-wrp'} >
              {auth.user_status.pause_id && <Switch
                size="small"
                checked={!!auth.user_status.pause_id}
                onChange={e => patchUserStatus(auth.user_status.id, { pause_id: null, })}
                onClick={(checked, evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                }}
              />}
              <div className={'status-time'}>
                <div className={'status-color'} style={{ backgroundColor: UserStatusColors[status], }} />
                <Timer base={counterStartTime} format="HH:mm:ss" default_value='00:00:00' />
              </div>
            </div>
            <div className={'status-title'} >
              {selectedPause?.title || t(`STATUS_${status}`)}
            </div>
          </div>

          <div className={'chevron'}>
            <Icon icon={ChevronDownIcon} />
          </div>
        </div>
      </HeaderUserStatusPausesPopover>
    </div>
  );
});
