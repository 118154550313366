import React from 'react';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { CrudEnum, IPreviewPhonenumberPrefix, PermissionEnum, EnumTranslateTitle, EnumListSearchProps, TPhonenumberPrefixSortableListCol, PhonenumberDidTypeEnum, } from 'atlas-shared';
import { tableColumnStringSorter, } from '@Utils';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { ColumnsType, } from 'antd/lib/table/interface';
import { listAuditButton, GetColumnSearchProps, GetColumnSearchEqualProps, } from '../components';
import { IWithListProps, } from '@Hocs/with.list';

export const PhonenumberPrefixListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewPhonenumberPrefix) => React.ReactElement> = [], filters: IWithListProps<IPreviewPhonenumberPrefix, TPhonenumberPrefixSortableListCol>['filters'], sorter: IWithListProps<IPreviewPhonenumberPrefix, TPhonenumberPrefixSortableListCol>['sorter']): ColumnsType<IPreviewPhonenumberPrefix> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewPhonenumberPrefix, b: IPreviewPhonenumberPrefix) => a.id - b.id,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    {
      title: t('TYPE'),
      dataIndex: 'type',
      key: 'type',
      render: (type: IPreviewPhonenumberPrefix['type'], phonenumber_prefix: IPreviewPhonenumberPrefix) => EnumTranslateTitle('PHONENUMBER_DID_TYPE', type, t),
      sorter: (a: IPreviewPhonenumberPrefix, b: IPreviewPhonenumberPrefix) => EnumTranslateTitle('PHONENUMBER_DID_TYPE', a.type, t).localeCompare(EnumTranslateTitle('PHONENUMBER_DID_TYPE', b.type, t)),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'type')?.order || null,
      filteredValue: filters.type || null,
      ...GetColumnSearchProps(t, 'type', t('TYPE'), EnumListSearchProps('PHONENUMBER_DID_TYPE', PhonenumberDidTypeEnum, t)),
      className: 'table-cell-type',
    },
    {
      title: t('ALPHA2'),
      dataIndex: 'alpha2',
      key: 'alpha2',
      sorter: (a: IPreviewPhonenumberPrefix, b: IPreviewPhonenumberPrefix) => tableColumnStringSorter(a.alpha2, b.alpha2),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'alpha2')?.order || null,
      filteredValue: filters.alpha2 || null,
      ...GetColumnSearchProps(t, 'alpha2', t('ALPHA2')),
      className: 'table-cell-alpha2',
    },
    {
      title: t('AREA_NAME'),
      dataIndex: 'area_name',
      key: 'area_name',
      sorter: (a: IPreviewPhonenumberPrefix, b: IPreviewPhonenumberPrefix) => tableColumnStringSorter(a.area_name, b.area_name),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'area_name')?.order || null,
      className: 'table-cell-area_name',
    },
    {
      title: t('AREA_CODE'),
      dataIndex: 'area_code',
      key: 'area_code',
      render: (area_code: IPreviewPhonenumberPrefix['area_code'], phonenumber_prefix: IPreviewPhonenumberPrefix) => <div className='number-column'>{area_code}</div>,
      sorter: (a: IPreviewPhonenumberPrefix, b: IPreviewPhonenumberPrefix) => (a.area_code || 0) - (b.area_code || 0),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'area_code')?.order || null,
      filteredValue: filters.area_code || null,
      ...GetColumnSearchEqualProps(t, 'area_code', t('AREA_CODE')),
      className: 'table-cell-area_code',
    },
    {
      title: t('STOCK'),
      dataIndex: 'stock',
      key: 'stock',
      render: (stock: IPreviewPhonenumberPrefix['stock'], phonenumber_prefix: IPreviewPhonenumberPrefix) => <div className='number-column'>{stock}</div>,
      sorter: (a: IPreviewPhonenumberPrefix, b: IPreviewPhonenumberPrefix) => a.stock - b.stock,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'stock')?.order || null,
      className: 'table-cell-stock',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewPhonenumberPrefix) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(null, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'phonenumber_prefix', row)}
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<IPreviewPhonenumberPrefix>;

};
