import React, { useCallback, useEffect, useState, } from 'react';
import { Undefinable, } from 'atlas-shared';
import * as Joi from 'joi';
import { NavigateFunction, } from 'react-router';
import { JSONForm, } from '@Modules/atlas-form-core';
import { fromJson, Schema, } from 'json-joi-converter';

export interface IWithEditProps<T>{
  id: number;
  saving: boolean;
  schemaLoading: boolean;
  formSchema: Joi.Schema;
  save: (cb: ()=> Promise<void>)=> void;
  value: T;
  setValue: (values: T) => void;
  title?: string;
  hasAdvanced?: boolean;
  onSaved?: (res: Record<string, any>, navigate: NavigateFunction) => void;
  onCancel?: () => void;
  onChange?: (values: Record<string, any>, changedValue: any, form?: JSONForm) => void;
}

export function withEdit <T>(
  Component: React.JSXElementConstructor<any>,
  schemaFetcher: (value: T) => Promise<any>
) {
  return function (props: any) {
    const [value, setValue, ] = useState<Undefinable<T>>();
    const [saving, setSaving, ] = useState<boolean>(false);
    const [formSchema, setFormSchema, ] = useState<Joi.Schema>();
    const [schema, setSchema, ] = useState<Schema>();
    const [schemaLoading, setSchemaLoading, ] = useState<boolean>(false);

    const save = useCallback(async (cb: ()=> Promise<void>) => {
      setSaving(true);
      try {
        await cb();
      } catch (e) {
        console.error(e);
      } finally {
        setSaving(false);
      }
    }, [setSaving, ]);

    const loadSchema = useCallback(async (cb: (value: any) => Promise<Schema>) => {
      setSchemaLoading(true);
      try {
        const response = await cb(value);

        setSchema(response);
        setFormSchema(fromJson(response));
      } catch (e) {
        console.error(e);
      } finally {
        setSchemaLoading(false);
      }
    }, [value, setSchemaLoading, setFormSchema, ]);

    useEffect(() => {
      if (value && !schema && !schemaLoading)
        loadSchema(schemaFetcher);
    }, [value, schema, schemaLoading, ]);

    return <div className='entity-form-edit'>
      <Component
        value={value}
        setValue={setValue}
        save={save}
        saving={saving}
        formSchema={formSchema}
        schemaLoading={schemaLoading}
        {...props}
      />
    </div>;
  };
}
