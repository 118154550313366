import { useAuth, useUsers, } from '@Hooks';
import { RoleEnum, hasRole, } from 'atlas-shared';
import { useEffect, useMemo, useState, } from 'react';

interface IEditorAtOption {
  id: number;
  value: string;
}
export type TEditorAtOptions = Array<IEditorAtOption>;

export const useEditorAtOptions = (): TEditorAtOptions => {
  const auth = useAuth();
  const users = useUsers();
  const [atOptions, setAtOptions, ] = useState<Array<{id: number; value: string}>>([]);
  const isSuperadmin = useMemo(() => hasRole(auth.user, RoleEnum.Superadmin), [auth, ]);

  useEffect(() => {
    setAtOptions((isSuperadmin ? users.users : users.users.filter(user => !hasRole(user, RoleEnum.Superadmin))).map(user => ({ id: user.id, value: `${user.first_name} ${user.last_name}`, })));
  }, [users, users.loaded, users.users.length, isSuperadmin, ]);

  return atOptions;
};
