import React from 'react';
import { deleteApiKey, } from '@Api';
import { IAbilities, IAuth, IOrganizationStore, IUserStore, } from '@Store';
import { CrudEnum, IPreviewApiKey, PermissionEnum, TApiKeySortableListCol, } from 'atlas-shared';
import { getFullName, tableColumnDatetimeSorter, tableColumnBooleanRender, timeSince, } from '@Utils';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { ColumnsType, } from 'antd/lib/table/interface';
import { listAuditButton, listDeleteButton, listEditButton, GetColumnSearchProps, GetColumnSearchEqualProps, } from '../components';
import { IWithListProps, } from '@Hocs/with.list';

export const ApiKeyListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, users: IUserStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewApiKey) => React.ReactElement> = [], filters: IWithListProps<IPreviewApiKey, TApiKeySortableListCol>['filters'], sorter: IWithListProps<IPreviewApiKey, TApiKeySortableListCol>['sorter']): ColumnsType<IPreviewApiKey> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewApiKey, b: IPreviewApiKey) => a.id - b.id,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewApiKey['organization_id'], api_key: IPreviewApiKey) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id, }, }),
      sorter: (a: IPreviewApiKey, b: IPreviewApiKey) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('ACTIVE'),
      dataIndex: 'active',
      key: 'active',
      render: (active: IPreviewApiKey['active'], api_key: IPreviewApiKey) => tableColumnBooleanRender(t, active),
      sorter: (a: IPreviewApiKey, b: IPreviewApiKey) => (a.active ? 1 : 0) > (b.active ? 1 : 0) ? 1 : -1,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'active')?.order || null,
      className: 'table-cell-active',
    },
    {
      title: t('USER'),
      dataIndex: 'user_id',
      key: 'user_id',
      render: (user_id: IPreviewApiKey['user_id'], api_key: IPreviewApiKey) => user_id === -1 ? t('SYS_USER') : users.dict?.[user_id] ? getFullName(users.dict[user_id]) : t('USER_NOT_FOUND', { replace: { user_id, }, }),
      sorter: (a: IPreviewApiKey, b: IPreviewApiKey) => (users.dict?.[a.user_id] ? a.user_id === -1 ? t('SYS_USER') : getFullName(users.dict?.[a.user_id]) : '').localeCompare(users.dict?.[b.user_id] ? getFullName(users.dict?.[b.user_id]) : ''),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'user_id')?.order || null,
      className: 'table-cell-user_id',
    },
    {
      title: t('USER'),
      dataIndex: 'created_by',
      key: 'created_by',
      render: (created_by: IPreviewApiKey['created_by'], api_key: IPreviewApiKey) => created_by === -1 ? t('SYS_USER') : users.dict?.[created_by] ? getFullName(users.dict[created_by]) : t('USER_NOT_FOUND', { replace: { user_id: created_by, }, }),
      sorter: (a: IPreviewApiKey, b: IPreviewApiKey) => (users.dict?.[a.created_by] ? a.created_by === -1 ? t('SYS_USER') : getFullName(users.dict?.[a.created_by]) : '').localeCompare(users.dict?.[b.created_by] ? getFullName(users.dict?.[b.created_by]) : ''),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'created_by')?.order || null,
      className: 'table-cell-created_by',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewApiKey['created_at'], api_key: IPreviewApiKey) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewApiKey, b: IPreviewApiKey) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewApiKey) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'api_key', row)}
          {abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.ApiKey, row) && listEditButton(t, navigate, 'api_key', row)}
          {abilities.can(row.organization_id, CrudEnum.Delete, PermissionEnum.ApiKey, row) && listDeleteButton(t, row, deleteApiKey)}
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<IPreviewApiKey>;

};
