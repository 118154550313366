import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateStatsAlarm, ISharedUpdateStatsAlarm, ISharedStatsAlarm, IPreviewOrganization, IPreviewStatsCustomMetric, IPreviewUser, IPreviewQueue, IPreviewPause, IPreviewDisposition, IPreviewTeam, IPreviewMailAccount, IPreviewVoiceRoute, EnumOptions, StatsAlarmPeriodEnum, DirectionEnum, StatsWidgetFilterMetricComparisonEnum, ConditionAndOr, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IStatsAlarmFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  stats_custom_metrics: Array<IPreviewStatsCustomMetric>;
  users: Array<IPreviewUser>;
  queues: Array<IPreviewQueue>;
  pauses: Array<IPreviewPause>;
  dispositions: Array<IPreviewDisposition>;
  teams: Array<IPreviewTeam>;
  mail_accounts: Array<IPreviewMailAccount>;
  voice_routes: Array<IPreviewVoiceRoute>;
}

export const StatsAlarmFormOptions = (stats_alarm: ISharedCreateStatsAlarm | ISharedStatsAlarm, props: IStatsAlarmFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.StatsAlarm, {})), 'organization_id', false), 'organization'),
  settings_metrics_metric_stats_custom_metric_id: (it: ISharedStatsAlarm) => filterRelated<IPreviewStatsCustomMetric>(it.organization_id || stats_alarm.organization_id, 'stats_custom_metric', 'stats_alarm', props.stats_custom_metrics, true),
  settings_metrics_metric_filter_organization_ids: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  settings_metrics_metric_filter_conversation_user_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewUser>(it.organization_id || stats_alarm.organization_id, 'user_reverse', 'stats_alarm', props.users, true),
  settings_metrics_metric_filter_thread_user_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewUser>(it.organization_id || stats_alarm.organization_id, 'user_reverse', 'stats_alarm', props.users, true),
  settings_metrics_metric_filter_message_user_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewUser>(it.organization_id || stats_alarm.organization_id, 'user_reverse', 'stats_alarm', props.users, true),
  settings_metrics_metric_filter_conversation_queue_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewQueue>(it.organization_id || stats_alarm.organization_id, 'queue', 'stats_alarm', props.queues, true),
  settings_metrics_metric_filter_thread_queue_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewQueue>(it.organization_id || stats_alarm.organization_id, 'queue', 'stats_alarm', props.queues, true),
  settings_metrics_metric_filter_message_queue_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewQueue>(it.organization_id || stats_alarm.organization_id, 'queue', 'stats_alarm', props.queues, true),
  settings_metrics_metric_filter_pause_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewPause>(it.organization_id || stats_alarm.organization_id, 'pause', 'stats_alarm', props.pauses, true, it => !it.global),
  settings_metrics_metric_filter_disposition_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewDisposition>(it.organization_id || stats_alarm.organization_id, 'disposition', 'stats_alarm', props.dispositions, true, it => !it.global),
  settings_metrics_metric_filter_sub_disposition_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewDisposition>(it.organization_id || stats_alarm.organization_id, 'disposition', 'stats_alarm', props.dispositions, true, it => !it.global),
  settings_metrics_metric_filter_thrd_disposition_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewDisposition>(it.organization_id || stats_alarm.organization_id, 'disposition', 'stats_alarm', props.dispositions, true, it => !it.global),
  settings_metrics_metric_filter_team_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewTeam>(it.organization_id || stats_alarm.organization_id, 'team_reverse', 'stats_alarm', props.teams, true),
  settings_metrics_metric_filter_thread_team_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewTeam>(it.organization_id || stats_alarm.organization_id, 'team_reverse', 'stats_alarm', props.teams, true),
  settings_metrics_metric_filter_message_team_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewTeam>(it.organization_id || stats_alarm.organization_id, 'team_reverse', 'stats_alarm', props.teams, true),
  settings_metrics_metric_filter_mail_account_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewMailAccount>(it.organization_id || stats_alarm.organization_id, 'mail_account', 'stats_alarm', props.mail_accounts, true),
  settings_metrics_metric_filter_voice_route_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewVoiceRoute>(it.organization_id || stats_alarm.organization_id, 'voice_route', 'stats_alarm', props.voice_routes, true),
  settings_filter_organization_ids: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  settings_filter_conversation_user_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewUser>(it.organization_id || stats_alarm.organization_id, 'user_reverse', 'stats_alarm', props.users, true),
  settings_filter_thread_user_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewUser>(it.organization_id || stats_alarm.organization_id, 'user_reverse', 'stats_alarm', props.users, true),
  settings_filter_message_user_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewUser>(it.organization_id || stats_alarm.organization_id, 'user_reverse', 'stats_alarm', props.users, true),
  settings_filter_conversation_queue_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewQueue>(it.organization_id || stats_alarm.organization_id, 'queue', 'stats_alarm', props.queues, true),
  settings_filter_thread_queue_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewQueue>(it.organization_id || stats_alarm.organization_id, 'queue', 'stats_alarm', props.queues, true),
  settings_filter_message_queue_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewQueue>(it.organization_id || stats_alarm.organization_id, 'queue', 'stats_alarm', props.queues, true),
  settings_filter_pause_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewPause>(it.organization_id || stats_alarm.organization_id, 'pause', 'stats_alarm', props.pauses, true, it => !it.global),
  settings_filter_disposition_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewDisposition>(it.organization_id || stats_alarm.organization_id, 'disposition', 'stats_alarm', props.dispositions, true, it => !it.global),
  settings_filter_sub_disposition_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewDisposition>(it.organization_id || stats_alarm.organization_id, 'disposition', 'stats_alarm', props.dispositions, true, it => !it.global),
  settings_filter_thrd_disposition_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewDisposition>(it.organization_id || stats_alarm.organization_id, 'disposition', 'stats_alarm', props.dispositions, true, it => !it.global),
  settings_filter_team_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewTeam>(it.organization_id || stats_alarm.organization_id, 'team_reverse', 'stats_alarm', props.teams, true),
  settings_filter_thread_team_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewTeam>(it.organization_id || stats_alarm.organization_id, 'team_reverse', 'stats_alarm', props.teams, true),
  settings_filter_message_team_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewTeam>(it.organization_id || stats_alarm.organization_id, 'team_reverse', 'stats_alarm', props.teams, true),
  settings_filter_mail_account_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewMailAccount>(it.organization_id || stats_alarm.organization_id, 'mail_account', 'stats_alarm', props.mail_accounts, true),
  settings_filter_voice_route_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewVoiceRoute>(it.organization_id || stats_alarm.organization_id, 'voice_route', 'stats_alarm', props.voice_routes, true),
  settings_notification_user_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewUser>(it.organization_id || stats_alarm.organization_id, 'user', 'stats_alarm', props.users, true),
  settings_notification_team_ids: (it: ISharedStatsAlarm) => filterRelated<IPreviewTeam>(it.organization_id || stats_alarm.organization_id, 'team', 'stats_alarm', props.teams, true),
  settings_period: EnumOptions('STATS_ALARM_PERIOD', StatsAlarmPeriodEnum, t),
  settings_metrics_metric_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  settings_metrics_metric_thread_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  settings_metrics_metric_message_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  settings_metrics_comparison: EnumOptions('STATS_WIDGET_FILTER_METRIC_COMPARISON', StatsWidgetFilterMetricComparisonEnum, t),
  settings_filter_row_type: EnumOptions('CONDITION_AND_OR', ConditionAndOr, t),
  settings_filter_row_rules_comparison: EnumOptions('STATS_WIDGET_FILTER_METRIC_COMPARISON', StatsWidgetFilterMetricComparisonEnum, t),
});

export const StatsAlarmComponents: IForm['components'] = {};
export const StatsAlarmAdditionalParams: (auth: IAuth, props: IStatsAlarmFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IStatsAlarmFormOptionsProps) => ({
  settings_filter_pause_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_pause_ids-add',
      path: '@Components/page-views/pause',
      component: 'PauseFormAddView',
      item_text: 'PAUSE',
    },
  }, 
  settings_filter_disposition_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_disposition_ids-add',
      path: '@Components/page-views/disposition',
      component: 'DispositionFormAddView',
      item_text: 'DISPOSITION',
    },
  }, 
  settings_filter_sub_disposition_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_sub_disposition_ids-add',
      path: '@Components/page-views/disposition',
      component: 'DispositionFormAddView',
      item_text: 'DISPOSITION',
    },
  }, 
  settings_filter_thrd_disposition_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_thrd_disposition_ids-add',
      path: '@Components/page-views/disposition',
      component: 'DispositionFormAddView',
      item_text: 'DISPOSITION',
    },
  }, 
  settings_filter_team_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_team_ids-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM',
    },
  }, 
  settings_filter_thread_team_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_thread_team_ids-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM',
    },
  }, 
  settings_filter_message_team_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_message_team_ids-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM',
    },
  }, 
  settings_notification_team_ids: {
    onModalAdd: {
      key: 'queue-settings_notification_team_ids-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM',
    },
  },
});

export const StatsAlarmEditForm = (stats_alarm: ISharedStatsAlarm, props: IStatsAlarmFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `stats_alarm_edit_${stats_alarm.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === stats_alarm.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'auto_text', },
    { ref: 'text', ui_type: FormElementTypes.TEXT, },
    { ref: 'min_interval', },
    { ref: 'settings', },
  ],
});

export const StatsAlarmAddForm = (stats_alarm: ISharedCreateStatsAlarm, props: IStatsAlarmFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'stats_alarm_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'auto_text', },
    { ref: 'text', ui_type: FormElementTypes.TEXT, },
    { ref: 'min_interval', },
    { ref: 'settings', },
  ],
});

export const StatsAlarmAddInitialValues: ISharedCreateStatsAlarm = {} as ISharedCreateStatsAlarm;

export const StatsAlarmEditInitialValues = (stats_alarm: ISharedStatsAlarm): ISharedUpdateStatsAlarm => ({
  title: stats_alarm.title,
  auto_text: stats_alarm.auto_text,
  text: stats_alarm.text,
  min_interval: stats_alarm.min_interval,
  settings: stats_alarm.settings,
});

export const onStatsAlarmFormSaved = (t: TFunction, action: 'edit' | 'add', stats_alarm: ISharedStatsAlarm, navigate: NavigateFunction): void => {
  navigate(onFormSaved('stats_alarms'));
};
