import React from 'react';
import { deleteOrganization, } from '@Api';
import { IAbilities, IAuth, } from '@Store';
import { CrudEnum, IPreviewOrganization, PermissionEnum, TOrganizationSortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnBooleanRender, tableColumnStringSorter, timeSince, } from '@Utils';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { ColumnsType, } from 'antd/lib/table/interface';
import { listAuditButton, listCloneButton, listDeleteButton, listEditButton, } from '../components';
import { IWithListProps, } from '@Hocs/with.list';

export const OrganizationListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewOrganization) => React.ReactElement> = [], filters: IWithListProps<IPreviewOrganization, TOrganizationSortableListCol>['filters'], sorter: IWithListProps<IPreviewOrganization, TOrganizationSortableListCol>['sorter']): ColumnsType<IPreviewOrganization> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewOrganization, b: IPreviewOrganization) => a.id - b.id,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      className: 'table-cell-id',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewOrganization, b: IPreviewOrganization) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      className: 'table-cell-title',
    },
    {
      title: t('NEGATIVE_BALANCE_LOCK'),
      dataIndex: 'negative_balance_lock',
      key: 'negative_balance_lock',
      render: (negative_balance_lock: IPreviewOrganization['negative_balance_lock'], organization: IPreviewOrganization) => tableColumnBooleanRender(t, negative_balance_lock),
      sorter: (a: IPreviewOrganization, b: IPreviewOrganization) => (a.negative_balance_lock ? 1 : 0) > (b.negative_balance_lock ? 1 : 0) ? 1 : -1,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'negative_balance_lock')?.order || null,
      className: 'table-cell-negative_balance_lock',
    },
    {
      title: t('ACTIVE'),
      dataIndex: 'active',
      key: 'active',
      render: (active: IPreviewOrganization['active'], organization: IPreviewOrganization) => tableColumnBooleanRender(t, active),
      sorter: (a: IPreviewOrganization, b: IPreviewOrganization) => (a.active ? 1 : 0) > (b.active ? 1 : 0) ? 1 : -1,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'active')?.order || null,
      className: 'table-cell-active',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewOrganization['created_at'], organization: IPreviewOrganization) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewOrganization, b: IPreviewOrganization) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    showDeleted && { 
      title: t('DELETED'), 
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      className: 'table-cell-deleted_at',
      render: (deleted_at: IPreviewOrganization['deleted_at']) => deleted_at ? timeSince(t, new Date(deleted_at), true) : '',
      sorter: (a: IPreviewOrganization, b: IPreviewOrganization) => tableColumnDatetimeSorter(a.deleted_at, b.deleted_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'deleted_at')?.order || null,
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewOrganization) => (
        <div className={'table-operation-wrp'}>
          {!row.deleted_at && buttons.map(button => button(row))}
          {abilities.can(row.id, CrudEnum.Insert, PermissionEnum.Organization, row) && listCloneButton(t, navigate, 'organization', row)}
          {abilities.can(row.id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'organization', row)}
          {!row.deleted_at && abilities.can(row.id, CrudEnum.Update, PermissionEnum.Organization, row) && listEditButton(t, navigate, 'organization', row)}
          {!row.deleted_at && abilities.can(row.id, CrudEnum.Delete, PermissionEnum.Organization, row) && listDeleteButton(t, row, deleteOrganization)}
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<IPreviewOrganization>;

};
