import { ISharedVoiceRecordingTranscript, } from 'atlas-shared';
import React, { useEffect, useMemo, useState, } from 'react';
import { getVoiceRecordingTranscript, } from '@Api';
import './conversation.message.voice_call.voice_recording_transcript.scss';
import { ConversationMessageVoiceCallVoiceRecordingTranscriptSentence, } from './conversation.message.voice_call.voice_recording_transcript.sentence';
import { useTranslation, } from 'react-i18next';
import moment from 'moment';

interface IProps {
  organization_id: ISharedVoiceRecordingTranscript['organization_id'];
  id: ISharedVoiceRecordingTranscript['id'];
  currentTime: number;
}

export const ConversationMessageVoiceCallVoiceRecordingTranscript = React.memo(({ organization_id, id, currentTime, }: IProps) => {

  const { t, } = useTranslation();
  const [transcript, setTranscript, ] = useState<ISharedVoiceRecordingTranscript>();
  const [error, setError, ] = useState<string>();

  useEffect(() => {
    if (id !== transcript?.id)
      getVoiceRecordingTranscript(organization_id, id)
        .then(transcript => {
          setTranscript(transcript);
          if (transcript.error)
            setError(transcript.error);
        })
        .catch(error => {
          setError(error.toString());
          setTranscript(undefined);
        })
      ;
  }, [id, ]);

  const created = useMemo(() => transcript && moment(transcript.created_at).format('YYYY-MM-DD HH:mm:ss'), [transcript, ]);
  const created_date = useMemo(() => created?.substr(0, 10), [created, ]);
  const done = useMemo(() => transcript && moment(transcript.done_at).format('YYYY-MM-DD HH:mm:ss'), [transcript, ]);
  const done_date = useMemo(() => done?.substr(0, 10), [done, ]);
  const today = useMemo(() => moment().format('YYYY-MM-DD'), []);

  return <div className='call-transcript'>
    <div className={`call-transcript-inner${error ? ' has-error' : ''}${currentTime ? ' playing' : ''}`}>
      {transcript && <div className='call-transcript-info'>
        <div className='data'>
          <div className='key'>{t('MODEL')}</div>
          <div className='value'>{t(`VOICE_RECORDING_TRANSCRIPT_MODEL_${transcript.model.toUpperCase()}`)}</div>
        </div>
        {created && <div className='data' title={created}>
          <div className='key'>{t('CREATED')}</div>
          <div className='value'>{created_date === today ? created.substr(11, 20) : created_date}</div>
        </div>}
        {done && <div className='data' title={done}>
          <div className='key'>{t('PROCESSED_AT')}</div>
          <div className='value'>{done_date === today ? done.substr(11, 20) : done_date}</div>
        </div>}
      </div>}
      {error}
      {transcript?.anonymized_at && <div>{t('TRANSCRIPT_ANONYMIZED')}</div>}
      {!transcript?.anonymized_at && transcript?.transcript?.sentences.map(sentence => <ConversationMessageVoiceCallVoiceRecordingTranscriptSentence
        t={t}
        sentence={sentence}
        currentTime={sentence.start <= currentTime && sentence.end >= currentTime ? currentTime : 0}
      />)}
    </div>
  </div>;
});
