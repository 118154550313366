import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateTeam, ISharedUpdateTeam, ISharedTeam, IPreviewOrganization, IPreviewUser, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface ITeamFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  users: Array<IPreviewUser>;
}

export const TeamFormOptions = (team: ISharedCreateTeam | ISharedTeam, props: ITeamFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Team, {})), 'organization_id', false), 'organization'),
  __users: (it: ISharedTeam) => filterRelated<IPreviewUser>(it.organization_id || team.organization_id, 'user', 'team', props.users, true),
  
});

export const TeamComponents: IForm['components'] = {};
export const TeamAdditionalParams: (auth: IAuth, props: ITeamFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ITeamFormOptionsProps) => ({
});

export const TeamEditForm = (team: ISharedTeam, props: ITeamFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `team_edit_${team.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === team.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: '__users', },
  ],
});

export const TeamAddForm = (team: ISharedCreateTeam, props: ITeamFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'team_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: '__users', },
  ],
});

export const TeamAddInitialValues: ISharedCreateTeam = {} as ISharedCreateTeam;

export const TeamEditInitialValues = (team: ISharedTeam): ISharedUpdateTeam => ({
  title: team.title,
  __users: team.__users,
});

export const onTeamFormSaved = (t: TFunction, action: 'edit' | 'add', team: ISharedTeam, navigate: NavigateFunction): void => {
  navigate(onFormSaved('teams'));
};
