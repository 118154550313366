import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchVoiceVoicemails, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useVoiceVoicemails = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const voice_voicemails = useSelector((store: IReducerStore) => store.voice_voicemails);

  useEffect(() => {
    if (!initiated && autoload && !voice_voicemails.loaded && !voice_voicemails.loading) {
      initiated = true;
      dispatch(actionFetchVoiceVoicemails());
    }
  }, [autoload, voice_voicemails.loaded, voice_voicemails.loading, dispatch, ]);

  return voice_voicemails;
};
