import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateStatsReport, ISharedUpdateStatsReport, ISharedStatsReport, IPreviewOrganization, IPreviewUser, IPreviewTeam, IPreviewAgentprofile, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IStatsReportFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  users: Array<IPreviewUser>;
  teams: Array<IPreviewTeam>;
  agentprofiles: Array<IPreviewAgentprofile>;
}

export const StatsReportFormOptions = (stats_report: ISharedCreateStatsReport | ISharedStatsReport, props: IStatsReportFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.StatsReport, {})), 'organization_id', false), 'organization'),
  shared_with_users: (it: ISharedStatsReport) => filterRelated<IPreviewUser>(it.organization_id || stats_report.organization_id, 'user_reverse', 'stats_report', props.users, true),
  shared_with_teams: (it: ISharedStatsReport) => filterRelated<IPreviewTeam>(it.organization_id || stats_report.organization_id, 'team_reverse', 'stats_report', props.teams, true),
  shared_with_agentprofiles: (it: ISharedStatsReport) => filterRelated<IPreviewAgentprofile>(it.organization_id || stats_report.organization_id, 'agentprofile', 'stats_report', props.agentprofiles, true),
  
});

export const StatsReportComponents: IForm['components'] = {};
export const StatsReportAdditionalParams: (auth: IAuth, props: IStatsReportFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IStatsReportFormOptionsProps) => ({
  shared_with_teams: {
    onModalAdd: {
      key: 'queue-shared_with_teams-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM',
    },
  },
});

export const StatsReportEditForm = (stats_report: ISharedStatsReport, props: IStatsReportFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `stats_report_edit_${stats_report.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === stats_report.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'is_dashboard', },
    { ref: 'shared', },
  ],
});

export const StatsReportAddForm = (stats_report: ISharedCreateStatsReport, props: IStatsReportFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'stats_report_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'is_dashboard', },
    { ref: 'shared', },
    { ref: 'template_id', },
  ],
});

export const StatsReportAddInitialValues: ISharedCreateStatsReport = {} as ISharedCreateStatsReport;

export const StatsReportEditInitialValues = (stats_report: ISharedStatsReport): ISharedUpdateStatsReport => ({
  title: stats_report.title,
  is_dashboard: stats_report.is_dashboard,
  shared: stats_report.shared,
});

export const onStatsReportFormSaved = (t: TFunction, action: 'edit' | 'add', stats_report: ISharedStatsReport, navigate: NavigateFunction): void => {
  navigate(onFormSaved('stats_reports'));
};
