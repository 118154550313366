import { IPreviewUserStatus, } from 'atlas-shared';

export enum UserStatusActions {
  SET_STATE = 'USER_STATUS_SET_STATE',
  ADD_STATE = 'USER_STATUS_ADD_STATE',
  REMOVE_STATE = 'USER_STATUS_REMOVE_STATE',
  GET_STATE = 'USER_STATUS_GET_STATE',
  UPDATE_STATE = 'USER_STATUS_UPDATE_STATE',
  LOADING = 'USER_STATUS_LOADING',
}

export interface IUserStatusLoading {
  type: UserStatusActions.LOADING;
}

export interface IUserStatusSetAction {
  type: UserStatusActions.SET_STATE;
  payload: Array<IPreviewUserStatus>;
}

export interface IUserStatusUpdateAction {
  type: UserStatusActions.UPDATE_STATE;
  payload: IPreviewUserStatus;
}

export interface IUserStatusAddAction {
  type: UserStatusActions.ADD_STATE;
  payload: IPreviewUserStatus;
}

export interface IUserStatusRemoveAction {
  type: UserStatusActions.REMOVE_STATE;
  payload: IPreviewUserStatus['id'];
}

export interface IUserStatusStore {
  loaded: boolean;
  loading: boolean;
  user_statuses: Array<IPreviewUserStatus>;
  dict: Record<IPreviewUserStatus['id'], IPreviewUserStatus>;
}

export type TUserStatusActionTypes = IUserStatusSetAction | IUserStatusUpdateAction | IUserStatusAddAction | IUserStatusRemoveAction | IUserStatusLoading;
