import './stats.scss';
import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import {
  useAuth,
  useHideStats, useIsPrint,
  useStatsReportPreviewWidgets,
  useStatsReports,
  useStatsWidgets,
} from '@Hooks';
import { IPreviewStatsReport, IPreviewStatsWidget, Nullable, IPreviewUserSettingsStatsReportProperty, IPreviewStatsReportPreviewWidget, Undefinable, } from 'atlas-shared';
import {
  patchStatsReportPreviewWidget, patchUser,
  setStatsReportInactive,
  statsWidgetLoadWidgets,
} from '@Api';
import { FormSelect, } from 'atlas-form';
import { StatsWidgetView, } from './widget-types/stats.widget.view';
import { Icon, } from '@Components';
import { useTranslation, } from 'react-i18next';
import { useStableNavigate, } from '../../context/StableNavigateContext';
import { useAppDispatch, } from '@Store';
import GridLayout from 'react-grid-layout';
import debounce from 'lodash/debounce';
import { ExternalLinkIcon, EyeOffIcon, PlusIcon, } from '@Assets';
import { StatsWidgetFormSider, } from './stats_widget.form';
import { StatsHiddenByChoice, } from './stats_hidden_by_choice';

interface IInputLayout {
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
}
interface IOutputLayout extends IInputLayout {
  moved: boolean;
}

export const Stats = React.memo(() => {
  const { t, } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useStableNavigate();
  const auth = useAuth();
  const stats_widgets = useStatsWidgets();
  const stats_reports = useStatsReports();
  const stats_report_preview_widgets = useStatsReportPreviewWidgets();
  const [activeReport, setActiveReport, ] = useState<Undefinable<IPreviewStatsReport>>();
  const [defaultPreviewWidgets, setDefaultPreviewWidgets, ] = useState<Array<IPreviewStatsReportPreviewWidget>>([]);
  const [customPreviewWidgets, setCustomPreviewWidgets, ] = useState<Array<IPreviewStatsReportPreviewWidget>>([]);
  const [addPreview, setAddPreview, ] = useState<Nullable<IPreviewStatsWidget['id']>>(null);
  const isHidden = useHideStats();
  const isPrint = useIsPrint();
  const switchingWidgets = useRef(false);
  const [isDefaultWidgets, setIsDefaultWidgets, ] = useState<number>(1);
  const [isHiddenByChoice, setIsHiddenByChoice, ] = useState<boolean>(!!auth?.user?.settings?.hide_stats_preview);

  const loadWidgetsData = useCallback((report_id) => {
    statsWidgetLoadWidgets(report_id, auth.user_status.agentprofile_id || null, dispatch, true);
  }, [auth.user_status.agentprofile_id, ]);

  const _setActiveReport = useCallback((id: IPreviewStatsReport['id']) => {
    setActiveReport(stats_reports.dict[id]);
    setIsDefaultWidgets(+!stats_report_preview_widgets.stats_report_preview_widgets.some(({ stats_report_id, user_id, is_default, }) => user_id === auth.user.id && !is_default && stats_report_id === id));
    switchingWidgets.current = true;
  }, [stats_reports, stats_report_preview_widgets, ]);

  useEffect(() => {
    if (!auth?.user.settings)
      return;

    const hide = !!auth?.user?.settings?.hide_stats_preview;

    if (hide !== isHiddenByChoice) {
      if (hide)
        setStatsReportInactive();
      else {
        if (activeReport?.id)
          setActiveReport(undefined);
      }

    }

    setIsHiddenByChoice(hide);
  }, [auth?.user?.settings?.hide_stats_preview, isHiddenByChoice, activeReport, ]);

  useEffect(() => {

    if (isHidden || isHiddenByChoice || !stats_report_preview_widgets.loaded)
      return;

    let settings_report: Nullable<IPreviewUserSettingsStatsReportProperty> = (auth.user.settings.stats_reports || []).find(stats_report => stats_report.agentprofile_id === (auth.user_status.agentprofile_id || null)) || null;

    if (!switchingWidgets.current && settings_report && settings_report.stats_report_id !== activeReport?.id && stats_reports.dict[settings_report.stats_report_id])
      _setActiveReport(settings_report.stats_report_id);

  }, [auth.user_status.agentprofile_id, auth.user.settings.stats_reports, stats_reports, activeReport, isHidden, isHiddenByChoice, stats_report_preview_widgets.loaded, ]);

  useEffect(() => {
    const allWidgets = activeReport
      ? stats_report_preview_widgets.stats_report_preview_widgets
        .filter(({ stats_report_id, }) => stats_report_id === activeReport.id)
      : [];

    setDefaultPreviewWidgets(allWidgets.filter(w => w.is_default));
    setCustomPreviewWidgets(allWidgets.filter(w => !w.is_default));
  }, [stats_report_preview_widgets, activeReport, ]);

  useEffect(() => {
    if (activeReport?.id) {
      loadWidgetsData(activeReport.id);
      switchingWidgets.current = false;
    }
  }, [activeReport?.id, ]);

  useEffect(() => {
    return () => {
      setStatsReportInactive();
    };
  }, []);

  const previewWidgets = isDefaultWidgets ? defaultPreviewWidgets : customPreviewWidgets;
  const isOwner = activeReport?.user_id === auth.user.id;

  const handleLayoutChange = useCallback(debounce((layout: Array<IOutputLayout>) => {
    layout.filter(l => {
      const widget = previewWidgets.find(widget => widget.id === +l.i);

      if (!widget)
        return false;

      const w: any = (widget.settings?.dashboard_position || {}) as IPreviewStatsReportPreviewWidget['settings']['dashboard_position'];

      return l.h !== w.h || l.y !== w.y;
    }).map(l => {
      const widget = previewWidgets.find(widget => widget.id === +l.i);

      if (widget)
        patchStatsReportPreviewWidget(+l.i, { settings: { ...widget.settings, dashboard_position: { h: l.h, y: l.y, }, }, });
    });
  }, 250), [previewWidgets, ]);

  const onLayoutChange = useCallback((layout: Array<IOutputLayout>) => {
    handleLayoutChange(layout);
  }, [previewWidgets, ]);

  const [selectableReports, selectableReportOptions, ] = useMemo(() => {
    const reports = stats_reports.stats_reports.filter(stats_report => stats_report.is_dashboard);

    return [
      reports,
      reports.map(report => ({ key: report.id, title: report.title, })),
    ];
  }, [stats_reports, ]);

  const [widgetForm, setWidgetForm, ] = useState<number>(0);
  const addWidget = useMemo(() => {
    return !!widgetForm && activeReport && <StatsWidgetFormSider
      t={t}
      auth={auth}
      report={activeReport}
      setWidgetForm={setWidgetForm}
      stats_widgets={stats_widgets}
      widgets={previewWidgets}
      widgetForm={widgetForm}
      isPreview
      isPreviewDefault={!!isDefaultWidgets}
    />;
  }, [activeReport, previewWidgets, widgetForm, ]);

  if (isPrint)
    return <></>;

  if (!isHidden && isHiddenByChoice)
    return <StatsHiddenByChoice
      t={t}
      auth={auth}
    />;

  return <div className={`stats-wrp${isHidden ? ' hidden' : ''}${isHiddenByChoice ? ' hidden-by-choice' : ''}`} >
    {!isHiddenByChoice && <div id='stats-hide-preview'>
      <Icon
        icon={EyeOffIcon}
        tooltip={{
          title: t('HIDE_STATS_PREVIEW'),
          placement: 'left',
        }}
        onClick={() => {
          return patchUser(auth.user.id, {
            settings: {
              ...auth.user.settings,
              hide_stats_preview: true,
            },
          });
        }}
      />
    </div>}
    <h2>{t('STATS')}</h2>
    <div className='report-selection'>
      <FormSelect options={selectableReportOptions} value={activeReport?.id} onChange={(id) => _setActiveReport(id)} placeholder={t('SELECT_DASHBOARD')} />
      <div className='report-widget-default-selection'>
        <FormSelect options={[
          { key: 1, title: t('DEFAULT'), },
          { key: 0, title: t('CUSTOM'), },
        ]} value={isDefaultWidgets} onChange={(isDefaultWidgets) => setIsDefaultWidgets(isDefaultWidgets)} />
      </div>
      {activeReport && (!isDefaultWidgets || isOwner) && previewWidgets.length < 4 && <Icon icon={PlusIcon} tooltip={{ title: t('ADD_WIDGET'), placement: 'left', }} onClick={() => setWidgetForm(-1)} />}
      {activeReport?.id && <Icon icon={ExternalLinkIcon} onClick={() => navigate(`/stats/${activeReport.id}/dashboard`)} iconProps={{ size: 14, }} tooltip={{ title: t('OPEN_DASHBOARD'), placement: 'left', }} />}
    </div>
    <div>
      {activeReport && <GridLayout
        className='layout'
        cols={12}
        rowHeight={30}
        width={390 * 12}
        draggableHandle=".draggable-handle"
        onResizeStop={onLayoutChange}
        onDragStop={onLayoutChange}
      >
        {previewWidgets
          .map((stats_report_preview_widget, index) => {
            const { y, h, } = stats_report_preview_widget.settings.dashboard_position || { y: 0, h: 6, };

            return <div className='widget' key={stats_report_preview_widget.id + ''} data-grid={{ x: 0, y, w: 1, h, isResizable: stats_report_preview_widget.user_id === auth.user.id, minWidth: 1, maxWidth: 1, }}>
              <StatsWidgetView
                key={stats_report_preview_widget.stats_widget_id}
                t={t}
                report={activeReport}
                is_preview={true}
                is_print={false}
                stats_report_widget={stats_report_preview_widget}
                auth={auth}
                widgets={previewWidgets}
                index={index}
                width={360}
                height={(h * 40) + 10}
              />
            </div>;
          })
        }
      </GridLayout>}
    </div>
    {!activeReport && <div className='report-not-selected'>{t('DASHBOARD_NOT_SELECTED')}</div>}
    {activeReport && !previewWidgets.length && <div className='no-preview-widgets'>{t('NO_WIDGETS')}</div>}
    {addWidget}
  </div>;
});
