import React from 'react';
import { TFunction, } from 'i18next';
import { ISharedMailMessage, ISharedFullConversation, } from 'atlas-shared';
import { Icon, } from '@Components/misc';
import { MailIconCircled, } from '@Assets/icons/channels';
import { THighlightedMessage, } from '@Hooks';
import { ConversationMessageReplies, } from '@Components/conversation';
import { highlightClassNames, } from '@Utils';

interface IProps {
  t: TFunction;
  message: ISharedMailMessage;
  conversation: ISharedFullConversation;
  highlightedMessage: THighlightedMessage;
}

export const ConversationMessageMailMini = React.memo(({ t, message, conversation, highlightedMessage, }: IProps) => {
  return <div className={`conversation-message conversation-message-mail${highlightClassNames(message, highlightedMessage)}`}>
    <div className='conversation-message-content'>
      <Icon icon={MailIconCircled} iconProps={{ size: 10, padding: 3, }} className='channel-icon' tooltip={{ title: t('MAIL_MESSAGE'), }} />
      <div className='subject'>{message.subject ? message.subject : <span style={{ opacity: .5, }}>{t('NO_SUBJECT')}</span>}</div>
    </div>
    <ConversationMessageReplies message={message} conversation={conversation} highlightedMessage={highlightedMessage} />
  </div>;
});
