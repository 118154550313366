import { RestRequest, } from '@Utils';
import { IPreviewRecentlyViewedConversation, ISharedRecentlyViewedConversation, ISharedCreateRecentlyViewedConversation, ISharedPatchRecentlyViewedConversation, } from 'atlas-shared';

export const fetchRecentlyViewedConversations = async (): Promise<Array<IPreviewRecentlyViewedConversation>> => {
  return RestRequest.get<Array<IPreviewRecentlyViewedConversation>>('recently_viewed_conversation', { socket_id: global.socketId, });
};

export const fetchRecentlyViewedConversation = async (id: IPreviewRecentlyViewedConversation['id']): Promise<ISharedRecentlyViewedConversation> => {
  return RestRequest.get<ISharedRecentlyViewedConversation>(`recently_viewed_conversation/${id}`, { socket_id: global.socketId, });
};

export const createRecentlyViewedConversation = (payload: ISharedCreateRecentlyViewedConversation) => {
  return RestRequest.post<any, ISharedRecentlyViewedConversation>('recently_viewed_conversation', payload, { socket_id: global.socketId, });
};

export const patchRecentlyViewedConversation = (id: IPreviewRecentlyViewedConversation['id'], payload: ISharedPatchRecentlyViewedConversation) => {
  return RestRequest.patch<any, ISharedRecentlyViewedConversation>(`recently_viewed_conversation/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteRecentlyViewedConversation = (id: IPreviewRecentlyViewedConversation['id']) => {
  return RestRequest.delete(`recently_viewed_conversation/${id}`, { socket_id: global.socketId, });
};

export const getRecentlyViewedConversationUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('recently_viewed_conversation/update/schema');
};

export const getRecentlyViewedConversationCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('recently_viewed_conversation/create/schema');
};

export const getRecentlyViewedConversationPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('recently_viewed_conversation/patch/schema');
};
