import { RestRequest, } from '@Utils';
import { IPreviewQueue, ISharedQueue, ISharedCreateQueue, ISharedPatchQueue, } from 'atlas-shared';

export const fetchQueues = async (): Promise<Array<IPreviewQueue>> => {
  return RestRequest.get<Array<IPreviewQueue>>('queue', { socket_id: global.socketId, });
};

export const fetchQueue = async (id: IPreviewQueue['id']): Promise<ISharedQueue> => {
  return RestRequest.get<ISharedQueue>(`queue/${id}`, { socket_id: global.socketId, });
};

export const createQueue = (payload: ISharedCreateQueue) => {
  return RestRequest.post<any, ISharedQueue>('queue', payload, { socket_id: global.socketId, });
};

export const patchQueue = (id: IPreviewQueue['id'], payload: ISharedPatchQueue) => {
  return RestRequest.patch<any, ISharedQueue>(`queue/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteQueue = (id: IPreviewQueue['id']) => {
  return RestRequest.delete(`queue/${id}`, { socket_id: global.socketId, });
};

export const getQueueUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('queue/update/schema');
};

export const getQueueCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('queue/create/schema');
};

export const getQueuePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('queue/patch/schema');
};
