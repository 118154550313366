import { IJourneyPayloadNodesKeyPortsKeyProperty, } from 'atlas-shared';
import { IJourneyActionProps, } from '@Utils';
import { Handle, Position, } from 'react-flow-renderer';
import React from 'react';
import './styles.scss';
import { TFunction, } from 'i18next';
import JourneyError from './journey.error';
import { Tooltip, } from 'antd';

interface IProps {
  t: TFunction;
  port: IJourneyPayloadNodesKeyPortsKeyProperty;
  index: number;
  actionProps: IJourneyActionProps;
  errors: Array<any>;
  title: string;
  left: number;
  width: number;
}

export const RenderOutputHandle = (props: IProps ) => {
  const { port, title, left, width, errors, t, } = props;

  return <Handle
    type={'source'}
    position={Position.Bottom}
    id={port.id}
    className={'port-output-view'}
    style={{ left, width, background: port.color || undefined, color: port.color ? '#FFFFFF' : 'var(--COLOR-000533)', }}
    isConnectable={true}>
    {title}
    <JourneyError errors={errors} />
    {port.description && <Tooltip
      title={t(port.description)}
      overlayClassName='info'
      overlayInnerStyle={{ textAlign: 'center', }}
    >
      <div className='info'>?</div>
    </Tooltip>}
  </Handle>;
};
