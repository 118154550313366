import React, { useCallback, useEffect, useState, } from 'react';
import './header.auth.user.scss';
import { useAuth, } from '@Hooks';
import { HeaderAuthUserPopover, } from './header.auth.user.popover';
import { Nullable, } from 'atlas-shared/dist';
import { useTranslation, } from 'react-i18next';
import { Icon, } from '@Components';
import { BellOffIcon, } from '@Assets';

export const HeaderAuthUser = React.memo(() => {
  const auth = useAuth();
  const { t, } = useTranslation();
  const [hasNotifications, setHasNotifications, ] = useState<boolean>(false);
  const [notificationError, setNotificationError, ] = useState<Nullable<string>>(t('NOTIFICATION_REQUEST_DENIED'));
  
  const setNotificationPermission = useCallback((err: Nullable<string> = null) => {
    setNotificationError(err);
    setHasNotifications(!err);
  }, []);
  
  const requestPermission = useCallback(() => {
    if (!('Notification' in window))
      setNotificationError(t('NOTIFICATION_REQUEST_NOT_SUPPORTED'));
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission()
        .then((result) => {
          if (['denied', 'default', ].includes(result))
            setNotificationPermission(t('NOTIFICATION_REQUEST_DENIED'));
          else
            setNotificationPermission();

        })
        .catch(err => {
          setNotificationPermission(t('NOTIFICATION_REQUEST_CATCH', { replace: err.toString(), }));
        })
      ;
    }
  }, []);

  useEffect(() => {
    requestPermission();
  }, []);

  if (!auth?.user)
    return <></>;

  return (
    <div className={'header-auth-user'} >
      {!hasNotifications && <Icon 
        icon={BellOffIcon} 
        className={'notifications-disabled'} 
        onClick={() => requestPermission()}
        tooltip={{
          title: notificationError,
          placement: 'bottomLeft',
          color: '#E45779',
        }}
      />}
      <HeaderAuthUserPopover />
    </div>
  );
});
