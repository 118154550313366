import { RestRequest, } from '@Utils';
import { IPreviewPhonenumberMonthlyCost, ISharedPhonenumberMonthlyCost, ISharedCreatePhonenumberMonthlyCost, ISharedPatchPhonenumberMonthlyCost, } from 'atlas-shared';

export const fetchPhonenumberMonthlyCosts = async (): Promise<Array<IPreviewPhonenumberMonthlyCost>> => {
  return RestRequest.get<Array<IPreviewPhonenumberMonthlyCost>>('phonenumber_monthly_cost', { socket_id: global.socketId, });
};

export const fetchPhonenumberMonthlyCost = async (id: IPreviewPhonenumberMonthlyCost['id']): Promise<ISharedPhonenumberMonthlyCost> => {
  return RestRequest.get<ISharedPhonenumberMonthlyCost>(`phonenumber_monthly_cost/${id}`, { socket_id: global.socketId, });
};

export const createPhonenumberMonthlyCost = (payload: ISharedCreatePhonenumberMonthlyCost) => {
  return RestRequest.post<any, ISharedPhonenumberMonthlyCost>('phonenumber_monthly_cost', payload, { socket_id: global.socketId, });
};

export const patchPhonenumberMonthlyCost = (id: IPreviewPhonenumberMonthlyCost['id'], payload: ISharedPatchPhonenumberMonthlyCost) => {
  return RestRequest.patch<any, ISharedPhonenumberMonthlyCost>(`phonenumber_monthly_cost/${id}`, payload, { socket_id: global.socketId, });
};

export const deletePhonenumberMonthlyCost = (id: IPreviewPhonenumberMonthlyCost['id']) => {
  return RestRequest.delete(`phonenumber_monthly_cost/${id}`, { socket_id: global.socketId, });
};

export const getPhonenumberMonthlyCostUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_monthly_cost/update/schema');
};

export const getPhonenumberMonthlyCostCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_monthly_cost/create/schema');
};

export const getPhonenumberMonthlyCostPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_monthly_cost/patch/schema');
};
