import React from 'react';
import './header.create.conversation.scss';
import { PlusIcon, } from '@Assets/icons';
import { Popover, } from 'antd';
import { useTranslation, } from 'react-i18next';
import { ChannelEnum, } from 'atlas-shared';
import { Icon, } from '@Components';
import { HeaderCreateConversationPopover, } from './header.create.conversation.popover';

interface IProps {
  channels: Array<ChannelEnum>;
}

export const HeaderCreateConversation = React.memo(({ channels, }: IProps) => {

  const { t, } = useTranslation();

  return (
    <div className={'create-conversation-wrapper'} >
      <Popover
        content={<HeaderCreateConversationPopover channels={channels} t={t} />}
        title={t('START_CONVERSATION')}
        overlayClassName={'create-conversation-popover'}
        trigger={'hover'}
        placement={'bottomRight'}
      >
        <div className={'create-conversation'}>
          <Icon icon={PlusIcon} iconProps={{ size: 18, }} />
        </div>
      </Popover>
    </div>
  );
});
