import { RestRequest, } from '@Utils/rest';
import {
  IPreviewAgentprofile,
  IPreviewContact,
  IPreviewOrganization,
  Nullable,
  SearchSectionEnum,
  ISharedAgentprofile,
  IElasticPreviewContact,
  IElasticPreviewConversation,
  ISharedConversation,
  IElasticSearchResult,
} from 'atlas-shared';

export const searchGlobal = async (keyword: string, type: Nullable<SearchSectionEnum>, agentprofile_id: Nullable<IPreviewAgentprofile['id']>): Promise<{
  conversations: IElasticSearchResult<IElasticPreviewConversation>;
  contacts: IElasticSearchResult<IElasticPreviewContact>;
}> => {
  return RestRequest.get<any>('/global/search', { q: keyword, s: (type as any), agentprofile_id: agentprofile_id || undefined, });
};

export const searchGlobalEntities = async (q?: string, agentprofile_id?: Nullable<ISharedAgentprofile['id']>, c?: string, l: number = 20, f: number = 0): Promise<Array<ISharedConversation>> => {
  return RestRequest.get<Array<ISharedConversation>, { agentprofile_id?: number; q?: string; c?: string; l?: string; f?: string }>('/global/search/conversation/entities', { agentprofile_id: agentprofile_id || undefined, q, c, l, f, });
};

export const searchContact = async (keyword: string, size: number = 5, exclude: Array<IPreviewContact['id']> = [], agentprofile_id: Nullable<IPreviewAgentprofile['id']>, organization_id?: IPreviewOrganization['id']): Promise<IElasticSearchResult<IElasticPreviewContact>> => {
  return RestRequest.get<any>('/contact/search', { q: keyword, size, exclude, organization_id, agentprofile_id: agentprofile_id || undefined, });
};

export const searchConversation = async (keyword: string, size: number = 5, exclude: Array<IPreviewContact['id']> = [], agentprofile_id: Nullable<IPreviewAgentprofile['id']>, organization_id?: IPreviewOrganization['id']): Promise<IElasticSearchResult<IElasticPreviewConversation>> => {
  return RestRequest.get<any>('/conversation/search', { q: keyword, size, exclude, organization_id, agentprofile_id: agentprofile_id || undefined, });
};

export const searchConversationEntities = async (keyword: string, size: number = 5, exclude: Array<IPreviewContact['id']> = [], agentprofile_id: Nullable<IPreviewAgentprofile['id']>, organization_id?: IPreviewOrganization['id']): Promise<Array<IElasticPreviewConversation>> => {
  return RestRequest.get<any>('/global/search/conversation/entities', { q: keyword, size, exclude, organization_id, agentprofile_id: agentprofile_id || undefined, });
};
