import { useSelector, } from 'react-redux';
import { useEffect, useState, } from 'react';
import { actionFetchVoiceCalls, IReducerStore, useAppDispatch, } from '@Store';
import { IPreviewUserStatus, } from 'atlas-shared';
import { useAuth, } from './use.auth';

export const useVoiceCalls = (autoload = true) => {
  const dispatch = useAppDispatch();
  const voice_calls = useSelector((store: IReducerStore) => store.voice_calls);
  const auth = useAuth();
  const [queues, setQueues, ] = useState<IPreviewUserStatus['__current_queues']>(auth.user_status.__current_queues);

  useEffect(() => {
    autoload && !voice_calls.loaded && !voice_calls.loading && dispatch(actionFetchVoiceCalls());
  }, [autoload, dispatch, voice_calls.loaded, voice_calls.loading, ]);

  useEffect(() => {
    if (autoload && voice_calls.loaded && !voice_calls.loading && queues.join() !== auth.user_status.__current_queues.join()) {
      dispatch(actionFetchVoiceCalls());
      setQueues(auth.user_status.__current_queues);
    }
  }, [autoload, auth.user_status.__current_queues, queues, voice_calls.loaded, voice_calls.loading, dispatch, ]);

  return voice_calls;
};
