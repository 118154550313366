import React, { useEffect, useState, } from 'react';
import '../admin.list.scss';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { ColumnsType, SorterResult, } from 'antd/lib/table/interface';
import { IPreviewApiKey, PermissionEnum, CrudEnum, TApiKeySortableListCol, } from 'atlas-shared';
import { Table, } from 'antd';
import { AdminListPageHeader, ListPageEmpty, } from '@Components';
import { IWithListProps, IWithListSorterProps, withList, } from '@Hocs/with.list';
import { useAbilities, useAuth, useApiKeys, useOrganizations, useUsers, } from '@Hooks';
import { ApiKeyListColumns, } from '@Utils';
import { IAuth, } from '@Store';

const ApiKeyList = ({ filters, filtersCount, hasFilters, sorter, pagination, setQueryParams, resetQueryParams, }: IWithListProps<IPreviewApiKey, TApiKeySortableListCol>) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth: IAuth = useAuth();
  const api_keys = useApiKeys();
  const abilities = useAbilities();
  const [loading, setLoading, ] = useState<boolean>(true);
  const [rows, setRows, ] = useState<Array<any>>([]);
  const organizations = useOrganizations();
  const users = useUsers();
  
  useEffect(() => {
    if (!api_keys.loaded)
      return;
      
    setLoading(false);
    setRows((api_keys?.api_keys || []).map(row => ({ ...row, key: row.id, })));
    
  }, [api_keys, ]);

  const columns: ColumnsType<IPreviewApiKey> = React.useMemo(() => {
    return ApiKeyListColumns(t, navigate, false, organizations, users, abilities, auth, [], filters, sorter);
  }, [t, navigate, organizations, users, abilities, auth, filters, sorter, ]);

  return <div className='admin-list-view'>
    <AdminListPageHeader 
      t={t}
      title={t('API_KEYS')}
      filtersCount={filtersCount}
      reset={() => resetQueryParams()}
      addBtn={abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.ApiKey) ? { callback: () => navigate('/admin/api_key/add'), } : undefined} 
    />
    <div className='table-wrp'>
      <Table
        key={loading ? 1 : 0}
        columns={columns}
        dataSource={rows}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
        onChange={(pagination, filters, _sorter) => {
          const sorter: Array<SorterResult<any>> = Array.isArray(_sorter) ? _sorter : _sorter?.columnKey ? [_sorter, ] : [];

          setQueryParams({
            pagination: { current: pagination.current as number, pageSize: pagination.pageSize as number, },
            filters: Object.fromEntries(Object.entries(filters).filter(([key, value, ]) => value !== null)),
            sorter: sorter
              .filter(s => !!s.order)
              .map(s => ({ column: s.columnKey as TApiKeySortableListCol, order: s.order as IWithListSorterProps<IPreviewApiKey, TApiKeySortableListCol>['order'], })),
          });
        }}
        locale={{
          emptyText: <ListPageEmpty t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => resetQueryParams()} />,
        }}
      />
    </div>
  </div>;
};

export const ApiKeyListView = React.memo(withList<IPreviewApiKey, TApiKeySortableListCol>(ApiKeyList, {
  filters: {},
  sorter: [{ column: 'created_at', order: 'descend', }, ],
  pagination: { current: 1, pageSize: 10, },
}));
