import { IPreviewStatsAlarm, } from 'atlas-shared';
import { IStatsAlarmStore, StatsAlarmActions, TStatsAlarmActionTypes, } from '@Store';

export const StatsAlarmReducer = (state: IStatsAlarmStore = {
  loaded: false,
  loading: false,
  stats_alarms: [],
  dict: {},
}, action: TStatsAlarmActionTypes): IStatsAlarmStore => {
  switch (action.type) {
  case StatsAlarmActions.LOADING:
    return { ...state, loading: true, };
  case StatsAlarmActions.SET_STATE:
    const dict: IStatsAlarmStore['dict'] = {};

    action.payload.forEach((stats_alarm: IPreviewStatsAlarm) => dict[stats_alarm.id] = stats_alarm);

    return { ...state, loaded: true, loading: false, stats_alarms: action.payload, dict, };
  case StatsAlarmActions.ADD_STATE:
    return { ...state, stats_alarms: [...state.stats_alarms, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case StatsAlarmActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, stats_alarms: state.stats_alarms.filter((item: IPreviewStatsAlarm) => item.id !== action.payload), dict, };
  }
  case StatsAlarmActions.UPDATE_STATE:
    const stats_alarms = [...state.stats_alarms, ];
    const index = state.stats_alarms.findIndex((stats_alarm: IPreviewStatsAlarm) => stats_alarm.id === action.payload.id);

    if (!~index)
      stats_alarms.push(action.payload);
    else
      stats_alarms[index] = action.payload;

    return { ...state, stats_alarms, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
