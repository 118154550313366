import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { Tabs, Popover, } from 'antd';
import {
  IPreviewQueue,
  IPreviewUser,
  Nullable, IPreviewConversation,
} from 'atlas-shared';
import {
  AlertError,
  entitiesToOptions,
  getFullName, preventDefault,
} from '@Utils';
import { IQueueStore, IUserStore, } from '@Store';
import './conversation.assign.scss';
import { patchConversation, } from '@Api';
import { useQueues, useUsers, } from '@Hooks';
import { FormSelect, } from 'atlas-form';

interface IProps extends PropsWithChildren<any> {
  conversation: IPreviewConversation;
}

export const ConversationAssign = ({ conversation, children, }: IProps) => {

  const { t, } = useTranslation();
  const { TabPane, } = Tabs;
  const queues: IQueueStore = useQueues();
  const users: IUserStore = useUsers();
  const [selectedQueue, setSelectedQueue, ] = useState<Nullable<IPreviewQueue>>();
  const [selectedAgent, setSelectedAgent, ] = useState<IPreviewUser | null>(null);
  const [activeKey, setActiveKey, ] = useState<string>(conversation.queue_id ? '2' : '1');
  const [queueOptions, setQueueOptions, ] = useState<Array<IPreviewQueue>>([]);
  const [agentOptions, setAgentOptions, ] = useState<Array<IPreviewUser>>([]);
  const [visible, setVisible, ] = useState<boolean>(false);

  useEffect(() => {
    setQueueOptions(queues.queues);
    setSelectedQueue(queues.queues.find(queue => queue.id === conversation.queue_id));
  }, [queues, conversation.queue_id, ]);

  useEffect(() => {
    if (!selectedAgent && conversation.user_id)
      setSelectedAgent(users.dict[conversation.user_id]);
  }, [users, conversation.user_id, selectedAgent, ]);

  useEffect(() => {
    setAgentOptions(selectedQueue ? users.users.filter(agent => selectedQueue?.__users?.includes(agent.id)) : []);
  }, [users, selectedQueue, ]);

  const selectQueue = useCallback((queue: IPreviewQueue): void => {
    setSelectedQueue(queue);
    if (selectedAgent && !queue?.__users.includes(selectedAgent.id))
      setSelectedAgent(null);

    setActiveKey('2');
  }, []);

  const selectAgent = useCallback((agent: IPreviewUser): void => {
    setSelectedAgent(agent);
  }, []);

  const confirmSelection = useCallback(() => {
    if (!selectedQueue)
      return;

    patchConversation(conversation.organization_id, conversation.id, {
      user_id: selectedAgent?.id || null,
      queue_id: selectedQueue.id,
    })
      .then(() => {
        setVisible(false);
      })
      .catch(error => {
        AlertError(t, {
          title: t('ERROR'),
          content: error.toString(),
        });
      });
  }, [selectedQueue, selectedAgent, ]);
  
  const confirmButtonTitle = useMemo((): string => {
    if (selectedAgent?.id && selectedQueue?.id)
      return `${t('ASSIGN')} ${t('TO')} ${getFullName(selectedAgent)} ${t('IN')} ${t('QUEUE')}  ${selectedQueue?.title}`;

    return `${t('ASSIGN')} ${t('TO')} ${t('QUEUE')} ${selectedQueue?.title}`;
  }, [selectedQueue, selectedAgent, ]);

  return (
    <Popover
      destroyTooltipOnHide={true}
      trigger={'click'}
      placement={'bottom'}
      open={visible}
      onOpenChange={visible => setVisible(visible)}
      content={<div className={'conversation-edit-popover'}>
        <Tabs
          onTabClick={activeKey => setActiveKey(activeKey)}
          activeKey={activeKey}
        >
          <TabPane
            tab={
              <div>
                <span>
                  {t('QUEUE')}
                </span>
                <span>
                  {(selectedQueue?.title) ? selectedQueue?.title : (selectedQueue?.title || t('CHOOSE_QUEUE'))}
                </span>
              </div>
            }
            key="1">
            <FormSelect
              options={entitiesToOptions(queueOptions, 'queue')}
              onChange={(id, option) => selectQueue(queues.dict[id])}
              fixedDropdown
              placeholder={t('SEARCH_QUEUE_AND_ASSIGN')}
              value={selectedQueue?.id}
              optionRender={(option, selected) => <><span className='option-title' style={{ fontSize: 12, }}><span>{option.title}</span></span><span className={'select-btn'}>{t(selected === option.key ? 'SELECTED' : 'CHOOSE')}</span></>}
            />
          </TabPane>
          <TabPane
            tab={
              <div>
                <span>
                  {t('AGENT')}
                </span>
                <span>
                  {selectedAgent ? getFullName(selectedAgent) : t('CHOOSE_AGENT')}
                </span>
              </div>
            }
            key="2"
          >
            <FormSelect
              options={entitiesToOptions(agentOptions, 'user')}
              onChange={(id, option) => selectAgent(users.dict[id])}
              fixedDropdown={true}
              placeholder={t('SEARCH_AGENT_AND_ASSIGN')}
              value={selectedAgent?.id}
              optionRender={(option, selected) => <>{option.display}<span className={'select-btn'}>{t(selected === option.key ? 'SELECTED' : 'CHOOSE')}</span></>}
            />
          </TabPane>
        </Tabs>
        {
          selectedQueue && <div className={'done-btn'} onClick={() => confirmSelection()}>{confirmButtonTitle}</div>
        }
      </div>}
    >
      <span style={{ display: 'flex', width: '100%', }} onClick={preventDefault(() => setVisible(true))}>{children}</span>
    </Popover>
  );
};
