import { TFunction, } from 'i18next';
import { ITagStore, } from '@Store';
import React, { useEffect, useState, } from 'react';
import { TagAutoIcon, TagIcon, } from '@Assets/icons';
import { IPreviewConversation, } from 'atlas-shared';
import { Icon, } from '@Components';
import { Badge, } from 'antd';

interface IProps {
  conversation: IPreviewConversation;
  t: TFunction;
  tags: ITagStore;
  auto?: boolean;
}

export const ConversationTags = React.memo(({ conversation, t, tags: _tags, auto = false, }: IProps) => {
  const [tags, setTags, ] = useState<Array<string>>([]);

  useEffect(() => {
    const conversation_tags = auto ? conversation.auto_tags : conversation.__tags;

    if (!conversation_tags?.length || !_tags.loaded)
      return;

    setTags(conversation_tags?.map(tag => _tags.tags.find(t => t.id === tag)?.title).filter(Boolean) as Array<string>);
  }, [_tags, conversation.auto_tags, conversation.__tags, ]);

  return tags.length ? <Badge
    count={2}
    size={'small'}
    className='conversation-tags-badge'
  >
    <Icon
      icon={auto ? TagAutoIcon : TagIcon}
      tooltip={{
        title: <span style={{ fontSize: 12, }}>{t(auto ? 'AUTO_TAGS' : 'TAGS')}: {tags.join(', ')}</span>,
        placement: 'bottom',
      }}
      className='conversation-tags'
    />
  </Badge> : <></>;
});
