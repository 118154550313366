import { RestRequest, } from '@Utils';
import { IPreviewPrepaidCredit, ISharedPrepaidCredit, ISharedCreatePrepaidCredit, ISharedPatchPrepaidCredit, } from 'atlas-shared';

export const fetchPrepaidCredits = async (): Promise<Array<IPreviewPrepaidCredit>> => {
  return RestRequest.get<Array<IPreviewPrepaidCredit>>('prepaid_credit', { socket_id: global.socketId, });
};

export const fetchPrepaidCredit = async (id: IPreviewPrepaidCredit['id']): Promise<ISharedPrepaidCredit> => {
  return RestRequest.get<ISharedPrepaidCredit>(`prepaid_credit/${id}`, { socket_id: global.socketId, });
};

export const createPrepaidCredit = (payload: ISharedCreatePrepaidCredit) => {
  return RestRequest.post<any, ISharedPrepaidCredit>('prepaid_credit', payload, { socket_id: global.socketId, });
};

export const patchPrepaidCredit = (id: IPreviewPrepaidCredit['id'], payload: ISharedPatchPrepaidCredit) => {
  return RestRequest.patch<any, ISharedPrepaidCredit>(`prepaid_credit/${id}`, payload, { socket_id: global.socketId, });
};

export const deletePrepaidCredit = (id: IPreviewPrepaidCredit['id']) => {
  return RestRequest.delete(`prepaid_credit/${id}`, { socket_id: global.socketId, });
};

export const getPrepaidCreditUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('prepaid_credit/update/schema');
};

export const getPrepaidCreditCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('prepaid_credit/create/schema');
};

export const getPrepaidCreditPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('prepaid_credit/patch/schema');
};
