import { journeyAction, } from './base';

import { JourneyActionTimeout as JourneyActionTimeoutBase, } from 'atlas-shared';

export const JourneyActionTimeout = journeyAction({
  ...JourneyActionTimeoutBase,
  additionalParams: {
    properties_timeout: { postFixText: 'MILLISECONDS', },
  },
  icon: 'Timer',
});
