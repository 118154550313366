import React, { useEffect, useState, } from 'react';
import { Popover, } from 'antd';
import { IPreviewQueue, ISharedFullConversation, } from 'atlas-shared';
import './conversation.tag.popover.scss';
import { IWithFullConversationProps, } from '@Hocs/with.full.conversation';
import { FormSelect, } from 'atlas-form';
import { useTranslation, } from 'react-i18next';
import { useTags, } from '@Hooks';
import { filterRelated, IOption, } from '@Utils';

interface IProps {
  conversation: ISharedFullConversation;
  children?: any;
  patch_conversation: IWithFullConversationProps['patch_conversation'];
  queue: IPreviewQueue;
}

export const ConversationTagPopover = React.memo(({ conversation, queue, children, patch_conversation, }: IProps) => {
  const { t, } = useTranslation();
  const tags = useTags();
  const [tagOptions, setTagOptions, ] = useState<Array<IOption>>([]);

  useEffect(() => {
    if (queue && tags.loaded)
      setTagOptions(filterRelated(conversation.organization_id, 'tag', 'conversation', tags.tags, true, tag => tag.global || queue.__tags.includes(tag.id)));
  }, [queue, tags.loaded, conversation.organization_id, tags.tags, ]);

  if (!queue)
    return <></>;

  return (
    <Popover overlayClassName={'tag-popover-container'} placement='bottomLeft' content={
      <>
        <div className={'popover-header'}>
          <span className='popover-title' style={{ opacity: .5, textTransform: 'uppercase', }}>{t('TAGS')}</span>
          <span className='popover-title'>{t('EDIT_TAGS')}</span>
        </div>
        <div className={'popover-content'}>
          <FormSelect
            placeholder={t('SEARCH_TAGS')}
            options={tagOptions}
            onChange={(__tags) => patch_conversation({ __tags, })}
            mode={'multiple'}
            value={conversation.__tags}
            fixedDropdown={true}
          />
        </div>
      </>
    } trigger='click'>
      {children}
    </Popover>
  );
});
