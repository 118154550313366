import { ReactElement, useState, } from 'react';

interface IProps {
  dropElement: ReactElement;
  value: Array<any>;
  onChange: (value: Array<any>) => void;
}

interface IResponse {
  onDragStart: (e: any, i: number) => void;
  onDragEnd: (e: any) => void;
  onDragOver: (i: number) => void;
  canDrop: (i: number, before: boolean) => IProps['dropElement'] | false;
  draggedItemDest: number;
  draggedItemIndex: number;
}

export const useSort = (props: IProps): IResponse => {
  
  const { value, onChange, dropElement, } = props;
  const [draggedItemIndex, setDraggedItemIndex, ] = useState<number>(-1);
  const [draggedItemDest, setDraggedItemDest, ] = useState<number>(-1);

  const onDragStart = (e: any, i: number) => {
    setDraggedItemIndex(i);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text', e.target.id);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragEnd = (e: any) => {

    if (draggedItemIndex < 0 || draggedItemDest === draggedItemIndex)
      return;

    let new_order = [...(value || []), ];
    let [splicedItem, ] = new_order.splice(draggedItemIndex, 1);

    new_order.splice(draggedItemDest, 0, splicedItem);

    onChange(new_order);

    setDraggedItemIndex(-1);
    setDraggedItemDest(-1);
  };

  const onDragOver = (i: number) => {
    setDraggedItemDest(i);
  };
  
  const canDrop = (index, before: boolean) => {
    return draggedItemDest === index && ((before && draggedItemIndex > index) || (!before && draggedItemIndex < index)) && dropElement;
  };
  
  return {
    onDragStart,
    onDragEnd,
    onDragOver,
    canDrop,
    draggedItemDest,
    draggedItemIndex,
  };
};
