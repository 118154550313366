import React from 'react';
import { ISharedFormSubmission, } from 'atlas-shared';
import { TFunction, } from 'i18next';

interface IProps {
  t: TFunction;
  message: ISharedFormSubmission;
}

export const ConversationMessageFormSubmissionMini = React.memo(({ t, message, }: IProps) => {
  return <div className={'conversation-message conversation-message-form-submission'}>{t('FORM_SUBMISSION')}</div>;
}, () => true);
