import { RestRequest, } from '@Utils';
import { ISharedWallboard, IWallboardRegister, } from 'atlas-shared';

export * from './abstract/wallboard.api.abstract';

export const registerWallboardKey = async (wallboard_key: ISharedWallboard['wallboard_key']): Promise<IWallboardRegister> => {
  return RestRequest.get<IWallboardRegister>(`wallboard/${wallboard_key}/register`, { socket_id: global.socketId, });
};

export const unregisterWallboardKey = async (wallboard_key: ISharedWallboard['wallboard_key']): Promise<any> => {
  return RestRequest.delete(`wallboard/${wallboard_key}`, { socket_id: global.socketId, });
};
