import { IPreviewPhonenumberMonthlyCost, } from 'atlas-shared';

export enum PhonenumberMonthlyCostActions {
  SET_STATE = 'PHONENUMBER_MONTHLY_COST_SET_STATE',
  ADD_STATE = 'PHONENUMBER_MONTHLY_COST_ADD_STATE',
  REMOVE_STATE = 'PHONENUMBER_MONTHLY_COST_REMOVE_STATE',
  GET_STATE = 'PHONENUMBER_MONTHLY_COST_GET_STATE',
  UPDATE_STATE = 'PHONENUMBER_MONTHLY_COST_UPDATE_STATE',
  LOADING = 'PHONENUMBER_MONTHLY_COST_LOADING',
}

export interface IPhonenumberMonthlyCostLoading {
  type: PhonenumberMonthlyCostActions.LOADING;
}

export interface IPhonenumberMonthlyCostSetAction {
  type: PhonenumberMonthlyCostActions.SET_STATE;
  payload: Array<IPreviewPhonenumberMonthlyCost>;
}

export interface IPhonenumberMonthlyCostUpdateAction {
  type: PhonenumberMonthlyCostActions.UPDATE_STATE;
  payload: IPreviewPhonenumberMonthlyCost;
}

export interface IPhonenumberMonthlyCostAddAction {
  type: PhonenumberMonthlyCostActions.ADD_STATE;
  payload: IPreviewPhonenumberMonthlyCost;
}

export interface IPhonenumberMonthlyCostRemoveAction {
  type: PhonenumberMonthlyCostActions.REMOVE_STATE;
  payload: IPreviewPhonenumberMonthlyCost['id'];
}

export interface IPhonenumberMonthlyCostStore {
  loaded: boolean;
  loading: boolean;
  phonenumber_monthly_costs: Array<IPreviewPhonenumberMonthlyCost>;
  dict: Record<IPreviewPhonenumberMonthlyCost['id'], IPreviewPhonenumberMonthlyCost>;
}

export type TPhonenumberMonthlyCostActionTypes = IPhonenumberMonthlyCostSetAction | IPhonenumberMonthlyCostUpdateAction | IPhonenumberMonthlyCostAddAction | IPhonenumberMonthlyCostRemoveAction | IPhonenumberMonthlyCostLoading;
