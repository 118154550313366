import { RestRequest, } from '@Utils';
import { IPreviewPointsSystem, ISharedPointsSystem, ISharedPatchPointsSystem, } from 'atlas-shared';

export const fetchPointsSystems = async (): Promise<Array<IPreviewPointsSystem>> => {
  return RestRequest.get<Array<IPreviewPointsSystem>>('points_system', { socket_id: global.socketId, });
};

export const fetchPointsSystem = async (id: IPreviewPointsSystem['id']): Promise<ISharedPointsSystem> => {
  return RestRequest.get<ISharedPointsSystem>(`points_system/${id}`, { socket_id: global.socketId, });
};

export const patchPointsSystem = (id: IPreviewPointsSystem['id'], payload: ISharedPatchPointsSystem) => {
  return RestRequest.patch<any, ISharedPointsSystem>(`points_system/${id}`, payload, { socket_id: global.socketId, });
};

export const getPointsSystemUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('points_system/update/schema');
};

export const getPointsSystemPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('points_system/patch/schema');
};
