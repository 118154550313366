import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  OrganizationAddForm,
  OrganizationAddInitialValues,
  OrganizationComponents,
  OrganizationAdditionalParams,
  OrganizationFormOptions,
  onOrganizationFormSaved,
  IOrganizationFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateOrganization, PermissionEnum, } from 'atlas-shared';
import { createOrganization, fetchOrganization, getOrganizationCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useVoiceAsterisks, useTariffs, usePhonenumberMonthlyCosts, usePhonenumberSetupCosts, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateOrganization> {}

const OrganizationFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const voice_asterisks = useVoiceAsterisks();
  const tariffs = useTariffs();
  const phonenumber_monthly_costs = usePhonenumberMonthlyCosts();
  const phonenumber_setup_costs = usePhonenumberSetupCosts();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateOrganization>>();

  const param_props: IOrganizationFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    voice_asterisks: voice_asterisks.voice_asterisks,
    tariffs: tariffs.tariffs,
    phonenumber_monthly_costs: phonenumber_monthly_costs.phonenumber_monthly_costs,
    phonenumber_setup_costs: phonenumber_setup_costs.phonenumber_setup_costs,
  };
  const formLayout = useMemo(() => OrganizationAddForm(values, param_props, t), [values, organizations, voice_asterisks, tariffs, phonenumber_monthly_costs, phonenumber_setup_costs, t, abilities, ]);
  const options: IFormOptions = useMemo(() => OrganizationFormOptions(values || OrganizationAddInitialValues, param_props, t), [values, organizations, voice_asterisks, tariffs, phonenumber_monthly_costs, phonenumber_setup_costs, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateOrganization, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateOrganization) => save(() => createOrganization(values).then(
    res => onSaved ? onSaved(res, navigate) : onOrganizationFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchOrganization(+id)
        .then(({
          title,
          organization_id,
          two_factor_auth,
          invoice,
          max_inactivity_ready,
          timezone,
          negative_balance_lock,
          active,
        }) => {
          setCloned({
            title,
            organization_id,
            two_factor_auth,
            invoice,
            max_inactivity_ready,
            timezone,
            negative_balance_lock,
            active,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!voice_asterisks.loaded || !tariffs.loaded || !phonenumber_monthly_costs.loaded || !phonenumber_setup_costs.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.Organization, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={OrganizationComponents}
        additionalParams={OrganizationAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...OrganizationAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('ORGANIZATION')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const OrganizationFormAddView = React.memo(withAdd(OrganizationFormAdd, getOrganizationCreateSchema, OrganizationAddInitialValues));
