import React, { useCallback, useEffect, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions, } from 'atlas-form';
import {
  UserEditForm,
  UserEditInitialValues,
  UserComponents,
  UserAdditionalParams,
  UserFormOptions,
  onUserFormSaved,
  IUserFormOptionsProps,
  AlertError,
} from '@Utils';
import { ISharedUser, ISharedPatchUser, PermissionEnum, CrudEnum, } from 'atlas-shared';
import { fetchUser, patchUser, getUserUpdateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withEdit, IWithEditProps, } from '@Hocs/with.edit';
import { useAbilities, useAuth, useQueues, useAgentprofiles, useStatsReports, usePauses, useOrganizations, useAdminprofiles, useSuperadminProfiles, useSkills, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithEditProps<ISharedUser> {}

const UserFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const queues = useQueues();
  const agentprofiles = useAgentprofiles();
  const stats_reports = useStatsReports();
  const pauses = usePauses();
  const organizations = useOrganizations();
  const adminprofiles = useAdminprofiles();
  const superadmin_profiles = useSuperadminProfiles();
  const skills = useSkills();

  useEffect(() => {
    fetchUser(id).then(user => setValue(user));
  }, [id, setValue, ]);

  const param_props: IUserFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    queues: queues.queues,
    agentprofiles: agentprofiles.agentprofiles,
    stats_reports: stats_reports.stats_reports,
    pauses: pauses.pauses,
    organizations: organizations.organizations,
    adminprofiles: adminprofiles.adminprofiles,
    superadmin_profiles: superadmin_profiles.superadmin_profiles,
    skills: skills.skills,
  };
  const formLayout: IForm | undefined = useMemo(() => value && UserEditForm(value, param_props, t), [value, queues, agentprofiles, stats_reports, pauses, organizations, adminprofiles, superadmin_profiles, skills, t, abilities, ]);
  const options: IFormOptions | undefined = useMemo(() => value && UserFormOptions(value, param_props, t), [value, queues, agentprofiles, stats_reports, pauses, organizations, adminprofiles, superadmin_profiles, skills, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchUser, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchUser) => value && save(() => patchUser(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onUserFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [value, save, navigate, t, ]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!queues.loaded || !agentprofiles.loaded || !stats_reports.loaded || !pauses.loaded || !organizations.loaded || !adminprofiles.loaded || !superadmin_profiles.loaded || !skills.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(null, CrudEnum.Update, PermissionEnum.User, value)}
    >
      <AtlasForm<ISharedPatchUser>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={UserComponents}
        additionalParams={UserAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={UserEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('USER')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const UserFormEditView = React.memo(withEdit(UserFormEdit, getUserUpdateSchema));
