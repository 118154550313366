import { IPreviewTag, } from 'atlas-shared';

export enum TagActions {
  SET_STATE = 'TAG_SET_STATE',
  ADD_STATE = 'TAG_ADD_STATE',
  REMOVE_STATE = 'TAG_REMOVE_STATE',
  GET_STATE = 'TAG_GET_STATE',
  UPDATE_STATE = 'TAG_UPDATE_STATE',
  LOADING = 'TAG_LOADING',
}

export interface ITagLoading {
  type: TagActions.LOADING;
}

export interface ITagSetAction {
  type: TagActions.SET_STATE;
  payload: Array<IPreviewTag>;
}

export interface ITagUpdateAction {
  type: TagActions.UPDATE_STATE;
  payload: IPreviewTag;
}

export interface ITagAddAction {
  type: TagActions.ADD_STATE;
  payload: IPreviewTag;
}

export interface ITagRemoveAction {
  type: TagActions.REMOVE_STATE;
  payload: IPreviewTag['id'];
}

export interface ITagStore {
  loaded: boolean;
  loading: boolean;
  tags: Array<IPreviewTag>;
  dict: Record<IPreviewTag['id'], IPreviewTag>;
}

export type TTagActionTypes = ITagSetAction | ITagUpdateAction | ITagAddAction | ITagRemoveAction | ITagLoading;
