import React from 'react';
import { ISvgIconProps, } from './interface';
import { SvgIcon, } from './SvgIcon';

export const JourneyIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <g fill="currentColor" transform="scale(0.01953125 0.01953125)"><path d="M921.6 758.989v-118.989c0-98.97-80.23-179.2-179.2-179.2h-102.4c-42.394 0-76.8-34.406-76.8-76.8v-118.989c42.24-19.456 71.68-61.85 71.68-111.411 0-67.891-54.989-122.88-122.88-122.88s-122.88 54.989-122.88 122.88c0 49.51 29.491 91.955 71.68 111.411v118.989c0 42.394-34.406 76.8-76.8 76.8h-102.4c-98.97 0-179.2 80.23-179.2 179.2v118.989c-42.24 19.456-71.68 61.901-71.68 111.411 0 67.891 54.989 122.88 122.88 122.88s122.88-55.040 122.88-122.88c0-49.51-29.491-91.955-71.68-111.411v-118.989c0-42.394 34.406-76.8 76.8-76.8h102.4c27.597 0 53.453-6.758 76.8-17.92v213.709c-42.24 19.456-71.68 61.901-71.68 111.411 0 67.891 54.989 122.88 122.88 122.88s122.88-55.040 122.88-122.88c0-49.51-29.491-91.955-71.68-111.411v-213.709c23.347 11.162 49.203 17.92 76.8 17.92h102.4c42.394 0 76.8 34.406 76.8 76.8v118.989c-42.24 19.456-71.68 61.85-71.68 111.411 0 67.891 54.989 122.88 122.88 122.88s122.88-55.040 122.88-122.88c0-49.51-29.44-91.955-71.68-111.411zM512 82.688c39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912-39.168 0-70.861-31.795-70.861-70.912 0-39.219 31.693-70.912 70.861-70.912zM153.6 941.312c-39.219 0-70.912-31.795-70.912-70.912 0-39.219 31.693-70.912 70.912-70.912 39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912zM512 941.312c-39.219 0-70.912-31.795-70.912-70.912 0-39.219 31.693-70.912 70.912-70.912 39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912zM870.4 941.312c-39.219 0-70.912-31.795-70.912-70.912 0-39.219 31.693-70.912 70.912-70.912 39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912z" /></g>
  </SvgIcon>;
};

export const JourneyAddIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <g fill="currentColor" transform="scale(0.01953125 0.01953125)"><path d="M921.6 758.989v-118.989c0-98.97-80.23-179.2-179.2-179.2h-102.4c-42.394 0-76.8-34.406-76.8-76.8v-118.989c42.24-19.456 71.68-61.85 71.68-111.411 0-67.891-54.989-122.88-122.88-122.88s-122.88 54.989-122.88 122.88c0 49.51 29.491 91.955 71.68 111.411v118.989c0 42.394-34.406 76.8-76.8 76.8h-102.4c-98.97 0-179.2 80.23-179.2 179.2v118.989c-42.24 19.456-71.68 61.901-71.68 111.411 0 67.891 54.989 122.88 122.88 122.88s122.88-55.040 122.88-122.88c0-49.51-29.491-91.955-71.68-111.411v-118.989c0-42.394 34.406-76.8 76.8-76.8h102.4c27.597 0 53.453-6.758 76.8-17.92v213.709c-42.24 19.456-71.68 61.901-71.68 111.411 0 67.891 54.989 122.88 122.88 122.88s122.88-55.040 122.88-122.88c0-49.51-29.491-91.955-71.68-111.411v-213.709c23.347 11.162 49.203 17.92 76.8 17.92h102.4c42.394 0 76.8 34.406 76.8 76.8v118.989c-42.24 19.456-71.68 61.85-71.68 111.411 0 67.891 54.989 122.88 122.88 122.88s122.88-55.040 122.88-122.88c0-49.51-29.44-91.955-71.68-111.411zM512 82.688c39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912-39.168 0-70.861-31.795-70.861-70.912 0-39.219 31.693-70.912 70.861-70.912zM153.6 941.312c-39.219 0-70.912-31.795-70.912-70.912 0-39.219 31.693-70.912 70.912-70.912 39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912zM512 941.312c-39.219 0-70.912-31.795-70.912-70.912 0-39.219 31.693-70.912 70.912-70.912 39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912zM870.4 941.312c-39.219 0-70.912-31.795-70.912-70.912 0-39.219 31.693-70.912 70.912-70.912 39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912z" /></g>
    <line x1="20" y1="0" x2="20" y2="8"></line>
    <line x1="16" y1="4" x2="24" y2="4"></line>
  </SvgIcon>;
};

export const JourneyListIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <g fill="currentColor" transform="scale(0.01953125 0.01953125)"><path d="M921.6 758.989v-118.989c0-98.97-80.23-179.2-179.2-179.2h-102.4c-42.394 0-76.8-34.406-76.8-76.8v-118.989c42.24-19.456 71.68-61.85 71.68-111.411 0-67.891-54.989-122.88-122.88-122.88s-122.88 54.989-122.88 122.88c0 49.51 29.491 91.955 71.68 111.411v118.989c0 42.394-34.406 76.8-76.8 76.8h-102.4c-98.97 0-179.2 80.23-179.2 179.2v118.989c-42.24 19.456-71.68 61.901-71.68 111.411 0 67.891 54.989 122.88 122.88 122.88s122.88-55.040 122.88-122.88c0-49.51-29.491-91.955-71.68-111.411v-118.989c0-42.394 34.406-76.8 76.8-76.8h102.4c27.597 0 53.453-6.758 76.8-17.92v213.709c-42.24 19.456-71.68 61.901-71.68 111.411 0 67.891 54.989 122.88 122.88 122.88s122.88-55.040 122.88-122.88c0-49.51-29.491-91.955-71.68-111.411v-213.709c23.347 11.162 49.203 17.92 76.8 17.92h102.4c42.394 0 76.8 34.406 76.8 76.8v118.989c-42.24 19.456-71.68 61.85-71.68 111.411 0 67.891 54.989 122.88 122.88 122.88s122.88-55.040 122.88-122.88c0-49.51-29.44-91.955-71.68-111.411zM512 82.688c39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912-39.168 0-70.861-31.795-70.861-70.912 0-39.219 31.693-70.912 70.861-70.912zM153.6 941.312c-39.219 0-70.912-31.795-70.912-70.912 0-39.219 31.693-70.912 70.912-70.912 39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912zM512 941.312c-39.219 0-70.912-31.795-70.912-70.912 0-39.219 31.693-70.912 70.912-70.912 39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912zM870.4 941.312c-39.219 0-70.912-31.795-70.912-70.912 0-39.219 31.693-70.912 70.912-70.912 39.117 0 70.861 31.693 70.861 70.912 0 39.117-31.744 70.912-70.861 70.912z" /></g>
    <line x1="16" y1="0" x2="24" y2="1"></line>
    <line x1="16" y1="4" x2="24" y2="4"></line>
    <line x1="16" y1="8" x2="24" y2="7"></line>
  </SvgIcon>;
};
