import { IForm, IFormOptions, } from 'atlas-form';
import {
  FieldAdditionalParams as FieldAdditionalParamsBase,
  FieldEditInitialValues as FieldEditInitialValuesBase,
  FieldFormOptions as FieldFormOptionsBase,
  IFieldFormOptionsProps,
} from './abstract/field.form.abstract';
import {
  EnumOptions,
  FieldTypeEnum,
  FormElementTypeExposedEnum,
  ISharedCreateField,
  ISharedField,
  ISharedUpdateField,
  LANGUAGE_CODES,
} from 'atlas-shared';
import { IAuth, } from '@Store';
import { TFunction, } from 'i18next';

export * from './abstract/field.form.abstract';

export const FieldAddInitialValues: ISharedCreateField = {
  settings: {
    ui_type: FormElementTypeExposedEnum.INPUT,
  },
} as ISharedCreateField;

export const FieldAdditionalParams: (auth: IAuth, props: IFieldFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IFieldFormOptionsProps) => ({
  ...FieldAdditionalParamsBase(auth, props),
  type: { help: 'FIELD_TYPE_DESCRIPTION', },
  settings: { labelWidth: '220px', },
  settings_ui_type: (a, b, c, d, e) => ({ label: 'UI_DATA_TYPE', hidden: e?.removable === false, }),
  settings_validation: (a, b, c, d, e) => ({ labelWidth: '99px', hidden: e?.removable === false, shouldUpdate: (prev, next) => prev.functionParams[1].ui_type !== next.functionParams[1].ui_type, }),
  settings_validation_min: (a, b, c, d) => ({
    label: 'MINIMUM',
    labelWidth: '99px',
    help: d.ui_type === FormElementTypeExposedEnum.NUMBER ? 'VALIDATION_MINIMUM_NUMBER_HELP' : d.ui_type === FormElementTypeExposedEnum.LIST ? 'VALIDATION_MINIMUM_LIST_HELP' : 'VALIDATION_MINIMUM_TEXT_HELP',
  }),
  settings_validation_max: (a, b, c, d) => ({
    label: 'MAXIMUM',
    labelWidth: '99px',
    help: d.ui_type === FormElementTypeExposedEnum.NUMBER ? 'VALIDATION_MAXIMUM_NUMBER_HELP' : d.ui_type === FormElementTypeExposedEnum.LIST ? 'VALIDATION_MAXIMUM_LIST_HELP' : 'VALIDATION_MAXIMUM_TEXT_HELP',
  }),
  settings_validation_valid: (a, b, c, d, e) => ({ label: 'OPTIONS', labelWidth: '99px', hidden: e?.removable === false, }),
  settings_validation_child_type: { labelWidth: '99px', },
  settings_validation_max_filesize: { labelWidth: '99px', postFixText: 'kb', },
  settings_validation_mimetypes: { labelWidth: '99px', },
  settings_validation_default_value: (a) => {
    return { ui_type: a.settings.ui_type, };
  },
  settings_validation_valid_alias: { labelWidth: '40px', },
  settings_validation_valid_custom_answer: { labelWidth: '48px', help: 'FIELD_CUSTOM_ANSWER_HELP', hideHelp: true, },
  settings_validation_valid_title: { labelWidth: '40px', },
  settings_validation_valid_translations_language: { hideLabel: true, },
  settings_validation_valid_translations_translation: { hideLabel: true, },
  alias: (a, b, c, d, e) => ({ label: 'ALIAS__KEY', help: 'FIELD_ALIAS_HELP', hidden: e?.removable === false, }),
});

export const FieldFormOptions = (field: ISharedCreateField | ISharedField, props: IFieldFormOptionsProps, t: TFunction): IFormOptions => {
  const languages = LANGUAGE_CODES.map(lc => ({
    key: lc,
    title: lc,
  }));

  return {
    ...FieldFormOptionsBase(field, props, t),
    settings_ui_type: (a, b) => {
      const options = EnumOptions('FORM_ELEMENT_TYPE_EXPOSED', FormElementTypeExposedEnum, t);

      if (a.type === FieldTypeEnum.FormSubmission)
        return options.filter(({ key, }) => key === FormElementTypeExposedEnum.FILE);
      else
        return options.filter(({ key, }) => key !== FormElementTypeExposedEnum.FILE);
    },
    translations_language: languages,
    settings_validation_default_value: (a) => {
      return (a.settings.validation?.valid || []).map(({ alias: key, title, }) => ({ key, title, }));
    },
    settings_validation_valid_translations_language: languages,
    settings_validation_mimetypes: [
      { key: 'image/jpeg', title: 'Image (.jpg)', },
      { key: 'image/png', title: 'Image (.png)', },
      { key: 'application/pdf', title: 'Pdf', },
      { key: 'video/mp4', title: 'Video (.mp4)', },
      { key: 'video/quicktime', title: 'Video (.mov)', },
    ],
  };
};

export const FieldEditInitialValues = (field: ISharedField): ISharedUpdateField => {
  const base = FieldEditInitialValuesBase(field);

  if (!field.removable && base.settings?.validation?.when)
    base.settings.validation.when = {};

  return base;
};
