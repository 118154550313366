import { IPreviewNotificationUser, } from 'atlas-shared';
import { INotificationUserStore, NotificationUserActions, TNotificationUserActionTypes, } from '@Store';

export const NotificationUserReducer = (state: INotificationUserStore = {
  loaded: false,
  loading: false,
  notification_users: [],
  dict: {},
}, action: TNotificationUserActionTypes): INotificationUserStore => {
  switch (action.type) {
  case NotificationUserActions.LOADING:
    return { ...state, loading: true, };
  case NotificationUserActions.SET_STATE:
    const dict: INotificationUserStore['dict'] = {};

    action.payload.forEach((notification_user: IPreviewNotificationUser) => dict[notification_user.id] = notification_user);

    return { ...state, loaded: true, loading: false, notification_users: action.payload, dict, };
  case NotificationUserActions.ADD_STATE:
    return { ...state, notification_users: [...state.notification_users, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case NotificationUserActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, notification_users: state.notification_users.filter((item: IPreviewNotificationUser) => item.id !== action.payload), dict, };
  }
  case NotificationUserActions.UPDATE_STATE:
    const notification_users = [...state.notification_users, ];
    const index = state.notification_users.findIndex((notification_user: IPreviewNotificationUser) => notification_user.id === action.payload.id);

    if (!~index)
      notification_users.push(action.payload);
    else
      notification_users[index] = action.payload;

    return { ...state, notification_users, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
