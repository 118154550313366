import React, { useCallback, useEffect, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions, } from 'atlas-form';
import {
  VoiceAsteriskEditForm,
  VoiceAsteriskEditInitialValues,
  VoiceAsteriskComponents,
  VoiceAsteriskAdditionalParams,
  VoiceAsteriskFormOptions,
  onVoiceAsteriskFormSaved,
  IVoiceAsteriskFormOptionsProps,
  AlertError,
} from '@Utils';
import { ISharedVoiceAsterisk, ISharedPatchVoiceAsterisk, PermissionEnum, CrudEnum, } from 'atlas-shared';
import { fetchVoiceAsterisk, patchVoiceAsterisk, getVoiceAsteriskUpdateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withEdit, IWithEditProps, } from '@Hocs/with.edit';
import { useAbilities, useAuth, useVoiceAsteriskDowngrades, useOrganizations, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithEditProps<ISharedVoiceAsterisk> {}

const VoiceAsteriskFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const voice_asterisk_downgrades = useVoiceAsteriskDowngrades();
  const organizations = useOrganizations();

  useEffect(() => {
    fetchVoiceAsterisk(id).then(voice_asterisk => setValue(voice_asterisk));
  }, [id, setValue, ]);

  const param_props: IVoiceAsteriskFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    voice_asterisk_downgrades: voice_asterisk_downgrades.voice_asterisk_downgrades,
    organizations: organizations.organizations,
  };
  const formLayout: IForm | undefined = useMemo(() => value && VoiceAsteriskEditForm(value, param_props, t), [value, voice_asterisk_downgrades, organizations, t, abilities, ]);
  const options: IFormOptions | undefined = useMemo(() => value && VoiceAsteriskFormOptions(value, param_props, t), [value, voice_asterisk_downgrades, organizations, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchVoiceAsterisk, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchVoiceAsterisk) => value && save(() => patchVoiceAsterisk(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onVoiceAsteriskFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [value, save, navigate, t, ]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!voice_asterisk_downgrades.loaded || !organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(null, CrudEnum.Update, PermissionEnum.VoiceAsterisk, value)}
    >
      <AtlasForm<ISharedPatchVoiceAsterisk>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={VoiceAsteriskComponents}
        additionalParams={VoiceAsteriskAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={VoiceAsteriskEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('VOICE_ASTERISK')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const VoiceAsteriskFormEditView = React.memo(withEdit(VoiceAsteriskFormEdit, getVoiceAsteriskUpdateSchema));
