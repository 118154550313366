import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchFormWebsites, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useFormWebsites = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const form_websites = useSelector((store: IReducerStore) => store.form_websites);

  useEffect(() => {
    if (!initiated && autoload && !form_websites.loaded && !form_websites.loading) {
      initiated = true;
      dispatch(actionFetchFormWebsites());
    }
  }, [autoload, form_websites.loaded, form_websites.loading, dispatch, ]);

  return form_websites;
};
