import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchStatsAlarms, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useStatsAlarms = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const stats_alarms = useSelector((store: IReducerStore) => store.stats_alarms);

  useEffect(() => {
    if (!initiated && autoload && !stats_alarms.loaded && !stats_alarms.loading) {
      initiated = true;
      dispatch(actionFetchStatsAlarms());
    }
  }, [autoload, stats_alarms.loaded, stats_alarms.loading, dispatch, ]);

  return stats_alarms;
};
