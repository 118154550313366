import { RestRequest, } from '@Utils';
import { IAgentprofileAgent, IPreviewAgentprofile, ISharedOrganization, } from 'atlas-shared/dist';

export * from './abstract/agentprofile.api.abstract';

export const fetchAgentprofileAgents = async (id: IPreviewAgentprofile['id']): Promise<Array<IAgentprofileAgent>> => {
  return RestRequest.get<Array<IAgentprofileAgent>>(`agentprofile/${id}/agents`, { socket_id: global.socketId, });
};

export const getCustomAgentprofileUpdateSchema = (organization_id: ISharedOrganization['id']): Promise<any> => {
  return RestRequest.get<any>(`agentprofile/update/schema/${organization_id}`);
};
