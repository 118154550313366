import { Icon, } from '@Components';
import { CloneIcon, } from '@Assets/icons';
import { Tooltip, } from 'antd';
import React from 'react';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';

export const listCloneButton = <T extends {id: number}>(t: TFunction, navigate: NavigateFunction, entity: string | Array<string>, row: T): React.ReactElement => {
  return <Tooltip overlayClassName={'table-tooltip-custom'} arrowPointAtCenter title={t('CLONE')} placement="bottom">
    <div className={'table-icon-wrp'}>
      <Icon icon={CloneIcon} iconProps={{ size: 14, }} onClick={_ => navigate(`/admin/${entity}/add?clone=${row.id}`) } />
    </div>
  </Tooltip>;
};
