import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateFormWebsite, ISharedUpdateFormWebsite, ISharedFormWebsite, IPreviewOrganization, IPreviewJourney, IPreviewForm, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IFormWebsiteFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  journeies: Array<IPreviewJourney>;
  forms: Array<IPreviewForm>;
}

export const FormWebsiteFormOptions = (form_website: ISharedCreateFormWebsite | ISharedFormWebsite, props: IFormWebsiteFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.FormWebsite, {})), 'organization_id', false), 'organization'),
  journey_id: (it: ISharedFormWebsite) => filterRelated<IPreviewJourney>(it.organization_id || form_website.organization_id, 'journey', 'form_website', props.journeies, true),
  form_id: (it: ISharedFormWebsite) => filterRelated<IPreviewForm>(it.organization_id || form_website.organization_id, 'form', 'form_website', props.forms, true),
  
});

export const FormWebsiteComponents: IForm['components'] = {};
export const FormWebsiteAdditionalParams: (auth: IAuth, props: IFormWebsiteFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IFormWebsiteFormOptionsProps) => ({
  form_id: {
    onModalAdd: {
      key: 'queue-form_id-add',
      path: '@Components/page-views/form',
      component: 'FormFormAddView',
      item_text: 'FORM',
    },
  },
});

export const FormWebsiteEditForm = (form_website: ISharedFormWebsite, props: IFormWebsiteFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `form_website_edit_${form_website.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === form_website.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'journey_id', },
    { ref: 'form_id', },
    { ref: 'description', },
  ],
});

export const FormWebsiteAddForm = (form_website: ISharedCreateFormWebsite, props: IFormWebsiteFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'form_website_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'journey_id', },
    { ref: 'form_id', },
    { ref: 'description', },
  ],
});

export const FormWebsiteAddInitialValues: ISharedCreateFormWebsite = {} as ISharedCreateFormWebsite;

export const FormWebsiteEditInitialValues = (form_website: ISharedFormWebsite): ISharedUpdateFormWebsite => ({
  title: form_website.title,
  journey_id: form_website.journey_id,
  form_id: form_website.form_id,
  description: form_website.description,
});

export const onFormWebsiteFormSaved = (t: TFunction, action: 'edit' | 'add', form_website: ISharedFormWebsite, navigate: NavigateFunction): void => {
  navigate(onFormSaved('form_websites'));
};
