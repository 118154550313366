import { Modal, } from 'antd';
import React from 'react';
import { TFunction, } from 'i18next';
import { Button, } from '@Components';

export type TypeModal = {destroy: () => void};

interface IConfirmProps {
  title?: string;
  content?: string | React.ReactElement;
  icon?: React.ReactElement;
  okText?: string;
  cancelText?: string;
  onOk?: (modal: TypeModal) => void;
  onCancel?: () => void;
  width?: number;
}

const width = '768px';
const onOk = (modal: TypeModal) => modal.destroy();

const CustomModal = (type: 'confirm' | 'error' | 'info' | 'form' | 'iframe', t: TFunction, props: IConfirmProps) => {
  // return <Modal
  //   title="Title"
  //   visible={true}
  // >
  //   <p>dsfdsg</p>
  // </Modal>
  const modal = Modal.confirm({
    icon: null,
    closable: true,
    centered: true,
    className: `ant-custom-modal-${type}`,
    width,
    ...props,
    content: <>
      {props.content || ''}
      {props.content && <p>&nbsp;</p>}
      <div className='ant-modal-btns'>
        {type === 'confirm' && <Button onClick={() => {
          modal.destroy();
          props.onCancel?.();
        }}>{props.cancelText || t('NO')}</Button>}
        {type !== 'form' && <Button onClick={() => props.onOk ? props.onOk(modal) : onOk(modal)} type='primary'>{props.okText || t(type === 'confirm' ? 'YES' : 'OK')}</Button>}
      </div>
    </>,
  });

  return modal;
};

export const Confirm = (t: TFunction, props: IConfirmProps) => {
  return CustomModal('confirm', t, props);
};

export const AlertError = (t: TFunction, props: IConfirmProps) => {
  return CustomModal('error', t, { ...props, content: typeof props.content === 'string' ? t(props.content) : props.content, });
};

export const InfoModal = (t: TFunction, props: IConfirmProps) => {
  return CustomModal('info', t, props);
};

export const FormModal = (t: TFunction, props: IConfirmProps) => {
  return CustomModal('form', t, props);
};

export const PopupIframe = (t: TFunction, props: Omit<IConfirmProps, 'content'> & {path: string}) => {
  return CustomModal('iframe', t, { ...props, content: <iframe src={props.path} style={{ width: '100%', height: '500px', }}></iframe>, });
};
