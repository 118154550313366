import React from 'react';
import { deleteField, } from '@Api';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { CrudEnum, IPreviewField, PermissionEnum, EnumTranslateTitle, EnumListSearchProps, TFieldSortableListCol, FieldTypeEnum, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnStringSorter, timeSince, } from '@Utils';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { ColumnsType, } from 'antd/lib/table/interface';
import { listAuditButton, listCloneButton, listDeleteButton, listEditButton, GetColumnSearchProps, GetColumnSearchLikeProps, GetColumnSearchEqualProps, } from '../components';
import { IWithListProps, } from '@Hocs/with.list';

export const FieldListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewField) => React.ReactElement> = [], filters: IWithListProps<IPreviewField, TFieldSortableListCol>['filters'], sorter: IWithListProps<IPreviewField, TFieldSortableListCol>['sorter']): ColumnsType<IPreviewField> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewField, b: IPreviewField) => a.id - b.id,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewField['organization_id'], field: IPreviewField) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id, }, }),
      sorter: (a: IPreviewField, b: IPreviewField) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewField, b: IPreviewField) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      filteredValue: filters.title || null,
      ...GetColumnSearchLikeProps(t, 'title', t('TITLE')),
      className: 'table-cell-title',
    },
    {
      title: t('DISPLAY_TITLE'),
      dataIndex: 'display_title',
      key: 'display_title',
      sorter: (a: IPreviewField, b: IPreviewField) => tableColumnStringSorter(a.display_title, b.display_title),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'display_title')?.order || null,
      filteredValue: filters.display_title || null,
      ...GetColumnSearchLikeProps(t, 'display_title', t('DISPLAY_TITLE')),
      className: 'table-cell-display_title',
    },
    {
      title: t('TYPE'),
      dataIndex: 'type',
      key: 'type',
      render: (type: IPreviewField['type'], field: IPreviewField) => EnumTranslateTitle('FIELD_TYPE', type, t),
      sorter: (a: IPreviewField, b: IPreviewField) => EnumTranslateTitle('FIELD_TYPE', a.type, t).localeCompare(EnumTranslateTitle('FIELD_TYPE', b.type, t)),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'type')?.order || null,
      filteredValue: filters.type || null,
      ...GetColumnSearchProps(t, 'type', t('TYPE'), EnumListSearchProps('FIELD_TYPE', FieldTypeEnum, t)),
      className: 'table-cell-type',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewField['created_at'], field: IPreviewField) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewField, b: IPreviewField) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    showDeleted && { 
      title: t('DELETED'), 
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      className: 'table-cell-deleted_at',
      render: (deleted_at: IPreviewField['deleted_at']) => deleted_at ? timeSince(t, new Date(deleted_at), true) : '',
      sorter: (a: IPreviewField, b: IPreviewField) => tableColumnDatetimeSorter(a.deleted_at, b.deleted_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'deleted_at')?.order || null,
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewField) => (
        <div className={'table-operation-wrp'}>
          {!row.deleted_at && buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Insert, PermissionEnum.Field, row) && listCloneButton(t, navigate, 'field', row)}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'field', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.Field, row) && listEditButton(t, navigate, 'field', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Delete, PermissionEnum.Field, row) && listDeleteButton(t, row, deleteField)}
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<IPreviewField>;

};
