import { IPreviewSignature, } from 'atlas-shared';
import { ISignatureStore, SignatureActions, TSignatureActionTypes, } from '@Store';

export const SignatureReducer = (state: ISignatureStore = {
  loaded: false,
  loading: false,
  signatures: [],
  dict: {},
}, action: TSignatureActionTypes): ISignatureStore => {
  switch (action.type) {
  case SignatureActions.LOADING:
    return { ...state, loading: true, };
  case SignatureActions.SET_STATE:
    const dict: ISignatureStore['dict'] = {};

    action.payload.forEach((signature: IPreviewSignature) => dict[signature.id] = signature);

    return { ...state, loaded: true, loading: false, signatures: action.payload, dict, };
  case SignatureActions.ADD_STATE:
    return { ...state, signatures: [...state.signatures, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case SignatureActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, signatures: state.signatures.filter((item: IPreviewSignature) => item.id !== action.payload), dict, };
  }
  case SignatureActions.UPDATE_STATE:
    const signatures = [...state.signatures, ];
    const index = state.signatures.findIndex((signature: IPreviewSignature) => signature.id === action.payload.id);

    if (!~index)
      signatures.push(action.payload);
    else
      signatures[index] = action.payload;

    return { ...state, signatures, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
