import { IPreviewGdpr, } from 'atlas-shared';
import { IGdprStore, GdprActions, TGdprActionTypes, } from '@Store';

export const GdprReducer = (state: IGdprStore = {
  loaded: false,
  loading: false,
  gdprs: [],
  dict: {},
}, action: TGdprActionTypes): IGdprStore => {
  switch (action.type) {
  case GdprActions.LOADING:
    return { ...state, loading: true, };
  case GdprActions.SET_STATE:
    const dict: IGdprStore['dict'] = {};

    action.payload.forEach((gdpr: IPreviewGdpr) => dict[gdpr.id] = gdpr);

    return { ...state, loaded: true, loading: false, gdprs: action.payload, dict, };
  case GdprActions.ADD_STATE:
    return { ...state, gdprs: [...state.gdprs, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case GdprActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, gdprs: state.gdprs.filter((item: IPreviewGdpr) => item.id !== action.payload), dict, };
  }
  case GdprActions.UPDATE_STATE:
    const gdprs = [...state.gdprs, ];
    const index = state.gdprs.findIndex((gdpr: IPreviewGdpr) => gdpr.id === action.payload.id);

    if (!~index)
      gdprs.push(action.payload);
    else
      gdprs[index] = action.payload;

    return { ...state, gdprs, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
