import { ITabMaximizedProps, TabMaximize, } from '@Components/tab/tab.maximize/tab.maximize';
import { ISharedConversation, } from 'atlas-shared';

interface IProps {
  organization_id: ISharedConversation['organization_id'];
  conversation_id: ISharedConversation['id'];
  mock?: true;
  absolute: boolean;
  visible: boolean;
}

export const TabMaximizeWrapper = ({ absolute = true, visible, ...props }: ITabMaximizedProps & IProps) => {
  return <div className={`tab-maximize-wrapper${absolute ? ' absolute' : ''}${visible ? ' visible' : ''}`}>
    <TabMaximize {...props} />
  </div>;
};
