import { IPreviewDestinationRestrictor, } from 'atlas-shared';

export enum DestinationRestrictorActions {
  SET_STATE = 'DESTINATION_RESTRICTOR_SET_STATE',
  ADD_STATE = 'DESTINATION_RESTRICTOR_ADD_STATE',
  REMOVE_STATE = 'DESTINATION_RESTRICTOR_REMOVE_STATE',
  GET_STATE = 'DESTINATION_RESTRICTOR_GET_STATE',
  UPDATE_STATE = 'DESTINATION_RESTRICTOR_UPDATE_STATE',
  LOADING = 'DESTINATION_RESTRICTOR_LOADING',
}

export interface IDestinationRestrictorLoading {
  type: DestinationRestrictorActions.LOADING;
}

export interface IDestinationRestrictorSetAction {
  type: DestinationRestrictorActions.SET_STATE;
  payload: Array<IPreviewDestinationRestrictor>;
}

export interface IDestinationRestrictorUpdateAction {
  type: DestinationRestrictorActions.UPDATE_STATE;
  payload: IPreviewDestinationRestrictor;
}

export interface IDestinationRestrictorAddAction {
  type: DestinationRestrictorActions.ADD_STATE;
  payload: IPreviewDestinationRestrictor;
}

export interface IDestinationRestrictorRemoveAction {
  type: DestinationRestrictorActions.REMOVE_STATE;
  payload: IPreviewDestinationRestrictor['id'];
}

export interface IDestinationRestrictorStore {
  loaded: boolean;
  loading: boolean;
  destination_restrictors: Array<IPreviewDestinationRestrictor>;
  dict: Record<IPreviewDestinationRestrictor['id'], IPreviewDestinationRestrictor>;
}

export type TDestinationRestrictorActionTypes = IDestinationRestrictorSetAction | IDestinationRestrictorUpdateAction | IDestinationRestrictorAddAction | IDestinationRestrictorRemoveAction | IDestinationRestrictorLoading;
