import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchCannedAnswers, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useCannedAnswers = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const canned_answers = useSelector((store: IReducerStore) => store.canned_answers);

  useEffect(() => {
    if (!initiated && autoload && !canned_answers.loaded && !canned_answers.loading) {
      initiated = true;
      dispatch(actionFetchCannedAnswers());
    }
  }, [autoload, canned_answers.loaded, canned_answers.loading, dispatch, ]);

  return canned_answers;
};
