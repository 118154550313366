import { IPreviewVoiceAsterisk, } from 'atlas-shared';

export enum VoiceAsteriskActions {
  SET_STATE = 'VOICE_ASTERISK_SET_STATE',
  ADD_STATE = 'VOICE_ASTERISK_ADD_STATE',
  REMOVE_STATE = 'VOICE_ASTERISK_REMOVE_STATE',
  GET_STATE = 'VOICE_ASTERISK_GET_STATE',
  UPDATE_STATE = 'VOICE_ASTERISK_UPDATE_STATE',
  LOADING = 'VOICE_ASTERISK_LOADING',
}

export interface IVoiceAsteriskLoading {
  type: VoiceAsteriskActions.LOADING;
}

export interface IVoiceAsteriskSetAction {
  type: VoiceAsteriskActions.SET_STATE;
  payload: Array<IPreviewVoiceAsterisk>;
}

export interface IVoiceAsteriskUpdateAction {
  type: VoiceAsteriskActions.UPDATE_STATE;
  payload: IPreviewVoiceAsterisk;
}

export interface IVoiceAsteriskAddAction {
  type: VoiceAsteriskActions.ADD_STATE;
  payload: IPreviewVoiceAsterisk;
}

export interface IVoiceAsteriskRemoveAction {
  type: VoiceAsteriskActions.REMOVE_STATE;
  payload: IPreviewVoiceAsterisk['id'];
}

export interface IVoiceAsteriskStore {
  loaded: boolean;
  loading: boolean;
  voice_asterisks: Array<IPreviewVoiceAsterisk>;
  dict: Record<IPreviewVoiceAsterisk['id'], IPreviewVoiceAsterisk>;
}

export type TVoiceAsteriskActionTypes = IVoiceAsteriskSetAction | IVoiceAsteriskUpdateAction | IVoiceAsteriskAddAction | IVoiceAsteriskRemoveAction | IVoiceAsteriskLoading;
