import { ClientActions, } from '@Store';
import { IPreviewClient, } from 'atlas-shared';
import { fetchClients, } from '@Api';

export const actionFetchClients = () => {
  return async (dispatch) => {
    dispatch(actionLoadingClients());
    const clients = await fetchClients();

    dispatch(actionSetClients(clients));
    return clients;
  };
};

export const actionLoadingClients = () => ({
  type: ClientActions.LOADING,
  payload: [],
});

export const actionSetClients = (payload: Array<IPreviewClient>) => ({
  type: ClientActions.SET_STATE,
  payload: payload,
});

export const actionAddClient = (payload: IPreviewClient) => ({
  type: ClientActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateClient = (payload: IPreviewClient) => ({
  type: ClientActions.UPDATE_STATE,
  payload: payload,
});
