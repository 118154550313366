import { IAuthResponse, } from 'atlas-shared';

export interface IAuth {
  access_token: string;
  refresh_token: string;
  user: IAuthResponse['user'];
  user_status: IAuthResponse['user_status'];
  is_superadmin: boolean;
  is_admin: boolean;
  is_agent: boolean;
  is_wallboard?: boolean;
}

export enum AuthActions {
  SET_STATE = 'AUTH_SET_STATE',
  REMOVE_STATE = 'AUTH_REMOVE_STATE',
  SET_USER_STATE = 'SET_USER_STATE',
  SET_USER_STATUS_STATE = 'SET_USER_STATUS_STATE'
}

export interface IAuthSetAction {
  type: AuthActions.SET_STATE;
  payload: IAuth;
}

export interface IAuthSetUserStatusAction {
  type: AuthActions.SET_USER_STATUS_STATE;
  payload: IAuth['user_status'];
}

export interface IAuthSetUserAction {
  type: AuthActions.SET_USER_STATE;
  payload: IAuth['user'];
}

export interface IAuthRemoveAction {
  type: AuthActions.REMOVE_STATE;
}

export type TAuthActionTypes = IAuthSetAction | IAuthRemoveAction | IAuthSetUserAction | IAuthSetUserStatusAction;
