import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateField, ISharedUpdateField, ISharedField, IPreviewOrganization, EnumOptions, FieldTypeEnum, FormElementTypeExposedEnum, FormElementChildTypeExposedEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface IFieldFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const FieldFormOptions = (field: ISharedCreateField | ISharedField, props: IFieldFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Field, {})), 'organization_id', false), 'organization'),
  type: EnumOptions('FIELD_TYPE', FieldTypeEnum, t),
  settings_ui_type: EnumOptions('FORM_ELEMENT_TYPE_EXPOSED', FormElementTypeExposedEnum, t),
  settings_validation_child_type: EnumOptions('FORM_ELEMENT_CHILD_TYPE_EXPOSED', FormElementChildTypeExposedEnum, t),
});

export const FieldComponents: IForm['components'] = {};
export const FieldAdditionalParams: (auth: IAuth, props: IFieldFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IFieldFormOptionsProps) => ({
});

export const FieldEditForm = (field: ISharedField, props: IFieldFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `field_edit_${field.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === field.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'display_title', },
    { ref: 'translations', },
    { ref: 'alias', },
    { ref: 'type', },
    { ref: 'settings', },
    { ref: 'description', },
  ],
});

export const FieldAddForm = (field: ISharedCreateField, props: IFieldFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'field_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'display_title', },
    { ref: 'translations', },
    { ref: 'alias', },
    { ref: 'type', },
    { ref: 'settings', },
    { ref: 'description', },
  ],
});

export const FieldAddInitialValues: ISharedCreateField = {} as ISharedCreateField;

export const FieldEditInitialValues = (field: ISharedField): ISharedUpdateField => ({
  title: field.title,
  display_title: field.display_title,
  translations: field.translations,
  alias: field.alias,
  type: field.type,
  settings: field.settings,
  description: field.description,
});

export const onFieldFormSaved = (t: TFunction, action: 'edit' | 'add', field: ISharedField, navigate: NavigateFunction): void => {
  navigate(onFormSaved('fields'));
};
