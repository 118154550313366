import { TFunction, } from 'i18next';
import { timeSince, } from '@Utils';
import React, { useCallback, useEffect, useState, } from 'react';
import { IPreviewConversation, } from 'atlas-shared';

interface IProps {
  t: TFunction;
  conversation: IPreviewConversation;
}

export const ConversationCreatedAt = React.memo(({ conversation, t, }: IProps) => {
  const renderTime = useCallback(() => timeSince(t, new Date(conversation.created_at)), [t, conversation.created_at, ]);
  const [time, setTime, ] = useState<string>(renderTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(renderTime());
      return () => clearInterval(timer);
    }, 60_000);
  }, [conversation.created_at, renderTime, ]);

  return (
    <small className='time'>
      {time}
    </small>
  );
});
