import { RestRequest, } from '@Utils';
import { IPreviewStatsWidget, ISharedStatsWidget, ISharedCreateStatsWidget, ISharedPatchStatsWidget, } from 'atlas-shared';

export const fetchStatsWidgets = async (): Promise<Array<IPreviewStatsWidget>> => {
  return RestRequest.get<Array<IPreviewStatsWidget>>('stats_widget', { socket_id: global.socketId, });
};

export const fetchStatsWidget = async (id: IPreviewStatsWidget['id']): Promise<ISharedStatsWidget> => {
  return RestRequest.get<ISharedStatsWidget>(`stats_widget/${id}`, { socket_id: global.socketId, });
};

export const createStatsWidget = (payload: ISharedCreateStatsWidget) => {
  return RestRequest.post<any, ISharedStatsWidget>('stats_widget', payload, { socket_id: global.socketId, });
};

export const patchStatsWidget = (id: IPreviewStatsWidget['id'], payload: ISharedPatchStatsWidget) => {
  return RestRequest.patch<any, ISharedStatsWidget>(`stats_widget/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteStatsWidget = (id: IPreviewStatsWidget['id']) => {
  return RestRequest.delete(`stats_widget/${id}`, { socket_id: global.socketId, });
};

export const getStatsWidgetUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_widget/update/schema');
};

export const getStatsWidgetCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_widget/create/schema');
};

export const getStatsWidgetPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_widget/patch/schema');
};
