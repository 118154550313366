import { RestRequest, } from '@Utils';
import { IPreviewJourney, ISharedJourney, ISharedCreateJourney, ISharedPatchJourney, } from 'atlas-shared';

export const fetchJourneies = async (): Promise<Array<IPreviewJourney>> => {
  return RestRequest.get<Array<IPreviewJourney>>('journey', { socket_id: global.socketId, });
};

export const fetchJourney = async (id: IPreviewJourney['id']): Promise<ISharedJourney> => {
  return RestRequest.get<ISharedJourney>(`journey/${id}`, { socket_id: global.socketId, });
};

export const createJourney = (payload: ISharedCreateJourney) => {
  return RestRequest.post<any, ISharedJourney>('journey', payload, { socket_id: global.socketId, });
};

export const patchJourney = (id: IPreviewJourney['id'], payload: ISharedPatchJourney) => {
  return RestRequest.patch<any, ISharedJourney>(`journey/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteJourney = (id: IPreviewJourney['id']) => {
  return RestRequest.delete(`journey/${id}`, { socket_id: global.socketId, });
};

export const getJourneyUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('journey/update/schema');
};

export const getJourneyCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('journey/create/schema');
};

export const getJourneyPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('journey/patch/schema');
};
