import { StatsWidgetActions, } from '@Store';
import { IPreviewStatsWidget, } from 'atlas-shared';
import { fetchStatsWidgets, } from '@Api';

export const actionFetchStatsWidgets = () => {
  return async (dispatch) => {
    dispatch(actionLoadingStatsWidgets());
    const stats_widgets = await fetchStatsWidgets();

    dispatch(actionSetStatsWidgets(stats_widgets));
    return stats_widgets;
  };
};

export const actionLoadingStatsWidgets = () => ({
  type: StatsWidgetActions.LOADING,
  payload: [],
});

export const actionSetStatsWidgets = (payload: Array<IPreviewStatsWidget>) => ({
  type: StatsWidgetActions.SET_STATE,
  payload: payload,
});

export const actionAddStatsWidget = (payload: IPreviewStatsWidget) => ({
  type: StatsWidgetActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateStatsWidget = (payload: IPreviewStatsWidget) => ({
  type: StatsWidgetActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveStatsWidget = (id: IPreviewStatsWidget['id']) => ({
  type: StatsWidgetActions.REMOVE_STATE,
  payload: id,
});
