import { RestRequest, } from '@Utils';
import { IPreviewStatsReportWidget, ISharedStatsReportWidget, ISharedCreateStatsReportWidget, ISharedPatchStatsReportWidget, } from 'atlas-shared';

export const fetchStatsReportWidgets = async (): Promise<Array<IPreviewStatsReportWidget>> => {
  return RestRequest.get<Array<IPreviewStatsReportWidget>>('stats_report_widget', { socket_id: global.socketId, });
};

export const fetchStatsReportWidget = async (id: IPreviewStatsReportWidget['id']): Promise<ISharedStatsReportWidget> => {
  return RestRequest.get<ISharedStatsReportWidget>(`stats_report_widget/${id}`, { socket_id: global.socketId, });
};

export const createStatsReportWidget = (payload: ISharedCreateStatsReportWidget) => {
  return RestRequest.post<any, ISharedStatsReportWidget>('stats_report_widget', payload, { socket_id: global.socketId, });
};

export const patchStatsReportWidget = (id: IPreviewStatsReportWidget['id'], payload: ISharedPatchStatsReportWidget) => {
  return RestRequest.patch<any, ISharedStatsReportWidget>(`stats_report_widget/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteStatsReportWidget = (id: IPreviewStatsReportWidget['id']) => {
  return RestRequest.delete(`stats_report_widget/${id}`, { socket_id: global.socketId, });
};

export const getStatsReportWidgetUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_report_widget/update/schema');
};

export const getStatsReportWidgetCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_report_widget/create/schema');
};

export const getStatsReportWidgetPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_report_widget/patch/schema');
};
