import { UserStatusStatusEnum, } from 'atlas-shared';

export enum FlagEnumColors {
  CustomerResponded = 'var(--green-grey)',
  New = 'var(--blue-grey)',
  NoUpdatedIn7Days = 'var(--orange-dark)',
}

export const UserStatusColors: Record<UserStatusStatusEnum, string> = {
  [UserStatusStatusEnum.Offline]: '#000000',
  [UserStatusStatusEnum.Online]: '#92e091',
  [UserStatusStatusEnum.Idle]: '#3cc639',
  [UserStatusStatusEnum.IdleMail]: '#3cc639',
  [UserStatusStatusEnum.IdleVoice]: '#3cc639',
  [UserStatusStatusEnum.IdleForm]: '#3cc639',
  [UserStatusStatusEnum.PartialBusy]: 'rgba(205, 3, 3, .75)',
  [UserStatusStatusEnum.HandlingConversation]: 'rgb(0,202,253)',
  [UserStatusStatusEnum.Pinging]: 'rgb(253,210,0)',
  [UserStatusStatusEnum.Paused]: '#D95959',
  [UserStatusStatusEnum.Busy]: '#cd0303',
  [UserStatusStatusEnum.BusyMail]: '#cd0303',
  [UserStatusStatusEnum.BusyForm]: '#cd0303',
  [UserStatusStatusEnum.BusyVoice]: '#cd0303',
};
