import { IPreviewSuperadminProfile, } from 'atlas-shared';

export enum SuperadminProfileActions {
  SET_STATE = 'SUPERADMIN_PROFILE_SET_STATE',
  ADD_STATE = 'SUPERADMIN_PROFILE_ADD_STATE',
  REMOVE_STATE = 'SUPERADMIN_PROFILE_REMOVE_STATE',
  GET_STATE = 'SUPERADMIN_PROFILE_GET_STATE',
  UPDATE_STATE = 'SUPERADMIN_PROFILE_UPDATE_STATE',
  LOADING = 'SUPERADMIN_PROFILE_LOADING',
}

export interface ISuperadminProfileLoading {
  type: SuperadminProfileActions.LOADING;
}

export interface ISuperadminProfileSetAction {
  type: SuperadminProfileActions.SET_STATE;
  payload: Array<IPreviewSuperadminProfile>;
}

export interface ISuperadminProfileUpdateAction {
  type: SuperadminProfileActions.UPDATE_STATE;
  payload: IPreviewSuperadminProfile;
}

export interface ISuperadminProfileAddAction {
  type: SuperadminProfileActions.ADD_STATE;
  payload: IPreviewSuperadminProfile;
}

export interface ISuperadminProfileRemoveAction {
  type: SuperadminProfileActions.REMOVE_STATE;
  payload: IPreviewSuperadminProfile['id'];
}

export interface ISuperadminProfileStore {
  loaded: boolean;
  loading: boolean;
  superadmin_profiles: Array<IPreviewSuperadminProfile>;
  dict: Record<IPreviewSuperadminProfile['id'], IPreviewSuperadminProfile>;
}

export type TSuperadminProfileActionTypes = ISuperadminProfileSetAction | ISuperadminProfileUpdateAction | ISuperadminProfileAddAction | ISuperadminProfileRemoveAction | ISuperadminProfileLoading;
