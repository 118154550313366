import axios from 'axios';
import { HttpRequestHeader, } from 'antd/es/upload/interface';
import { ISharedInvoice, ISharedStatsReportWidget, Nullable, } from 'atlas-shared/dist';
import { IGlobalStore, } from '@Store';

interface IBaseApiConstructorProps {
  suffix: string;
  needsToken?: boolean;
  raiseInfo?: boolean;
  raiseError?: boolean;
}

export type IRequestPathQuery = Record<string, null | undefined | string | number | Array<string | number>>;

class BaseAPI {

  private auth: string | null = null;

  baseURL: string;

  httpService: ReturnType<typeof axios.create>;

  constructor(props?: IBaseApiConstructorProps) {
    this.baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
    this.httpService = axios.create({
      baseURL: this.baseURL,
      ...props,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
    });
    this.requestInterceptors();
    this.responseInterceptors();
  }

  setAuth(auth: string) {
    this.auth = auth;
    return this;
  }

  responseInterceptors = () => {
    this.httpService.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.data) {
          return error.response.data;
        }

        return error.message;
      });
  };

  requestInterceptors = () => {
    this.httpService.interceptors.request.use(
      config => config
    );
  };

  getHeaders(): HttpRequestHeader {
    const headers: HttpRequestHeader = {};

    if (this.auth)
      headers.Authorization = this.auth;

    return headers;
  }

  private prepareQuery(path: string, query?: IRequestPathQuery) {
    return path + (query ? `?${
      Object.entries(query)
        .map(([key, value, ]) => {
          if (Array.isArray(value))
            return value.map(v => `${key}[]=${v}`).join('&');
          
          if (value === undefined || value === null)
            return undefined;

          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .filter(Boolean)
        .join('&')
    }` : '');
  }

  async get<T = any, Q = IRequestPathQuery>(path: string, query?: IRequestPathQuery): Promise<T> {
    const response = await this.httpService.get(this.prepareQuery(path, query), {
      headers: this.getHeaders(),
    });

    return this.respond(response);
  }

  getFile(path: string, query?: IRequestPathQuery): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = this.getHeaders();

      fetch((path.startsWith('http') ? '' : this.baseURL + '/') + this.prepareQuery(path, query), {
        headers,
      }).then((response: any) => {
        if (response.status < 300)
          return resolve(response.blob());

        return reject(response?.message);
      })
        .catch(reject);

    });
  }

  getPutFile(path: string, body: Record<string, any>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.put(path, body, {
        headers: this.getHeaders(),
        responseType: 'blob',
      }).then((response: any) => {
        if (response.status < 300)
          return resolve(response.blob());

        return reject(response?.message);
      })
        .catch(reject);

    });
  }

  getScreenshot(url: string, filename: string, is_dashboard: boolean, theme: IGlobalStore['theme'], stats_report_widget_id: Nullable<ISharedStatsReportWidget['id']>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(this.baseURL + '/stats_report/screenshot', {
        url: `${url}?print=1${stats_report_widget_id ? `&widget_id=${stats_report_widget_id}` : ''}&theme=${theme}&access_token=${this.auth?.replace('Bearer ', '')}`,
        filename,
        is_dashboard,
      }, {
        headers: this.getHeaders(),
        responseType: 'blob',
      }).then((response: any) => {
        if (response.status < 300)
          return resolve(response.data);

        return reject(response?.message);
      })
        .catch(reject);

    });
  }

  getCsv(url: string, filename: string, is_dashboard: boolean, theme: IGlobalStore['theme'], stats_report_widget_id: Nullable<ISharedStatsReportWidget['id']>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(this.baseURL + '/stats_widget/csv', {
        url: `${url}?print=1${stats_report_widget_id ? `&widget_id=${stats_report_widget_id}` : ''}&theme=${theme}&access_token=${this.auth?.replace('Bearer ', '')}`,
        filename,
        is_dashboard,
        delimiter: ',',
      }, {
        headers: this.getHeaders(),
        responseType: 'blob',
      }).then((response: any) => {
        if (response.status < 300)
          return resolve(response.data);

        return reject(response?.message);
      })
        .catch(reject);

    });
  }

  getInvoicePdf(id: ISharedInvoice['id'], filename: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`${this.baseURL}/invoice/${id}/pdf`, {
        url: `${window.location.origin}/admin/invoice/${id}?print=1&access_token=${this.auth?.replace('Bearer ', '')}`,
        filename,
      }, {
        headers: this.getHeaders(),
        responseType: 'blob',
      }).then((response: any) => {
        if (response.status < 300)
          return resolve(response.data);

        return reject(response?.message);
      })
        .catch(reject);

    });
  }

  async post<T, R>(path: string, payload: T, query?: IRequestPathQuery): Promise<R> {
    const response = await this.httpService.post(this.prepareQuery(path, query), payload, {
      headers: this.getHeaders(),
    });

    return this.respond(response);
  }

  async put<T, R>(path: string, payload: T, query?: IRequestPathQuery): Promise<R> {
    const response = await this.httpService.put(this.prepareQuery(path, query), payload, {
      headers: this.getHeaders(),
    });

    return this.respond(response);
  }

  async patch<T, R>(path: string, payload: Partial<T>, query?: IRequestPathQuery): Promise<R> {
    const response = await this.httpService.patch(this.prepareQuery(path, query), payload, {
      headers: this.getHeaders(),
    });

    return this.respond(response);
  }

  async delete<T extends any>(path: string, query?: IRequestPathQuery): Promise<T> {
    const headers = this.getHeaders();
    const response = await this.httpService.delete(this.prepareQuery(path, query), {
      headers,
    });

    return this.respond(response);
  }

  private respond(response) {
    if (response.status < 300)
      return Promise.resolve(response?.data?.data);

    return Promise.reject(response?.message);
  }

  getFullSrc(path: string, query?: IRequestPathQuery) {
    if (path.startsWith('http'))
      return path;

    return `${this.baseURL}${path.startsWith('/') ? '' : '/'}${this.prepareQuery(path, query)}`;
  }

  upload(url: string, file, propName?: string) {
    const fileData = new FormData();

    fileData.append(propName || 'file', file);

    return axios.post(`${url.startsWith('http') ? '' : `${this.baseURL}/`}${url}`, fileData, { headers: this.getHeaders(), });
  }

}

export default BaseAPI;
