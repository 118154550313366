import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { IPreviewSound, TSoundSortableListCol, } from 'atlas-shared';
import React from 'react';
import { ColumnsType, } from 'antd/lib/table/interface';
import { SoundListColumns as SoundListColumnsBase, } from './abstract/sound.list.column.abstract';
import { streamSoundFilename, } from '@Api';
import { AudioPlayer, } from '@Utils/audio-player';
import { IWithListProps, } from '@Hocs/with.list';

export const SoundListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewSound) => React.ReactElement> = [], filters: IWithListProps<IPreviewSound, TSoundSortableListCol>['filters'], sorter: IWithListProps<IPreviewSound, TSoundSortableListCol>['sorter']): ColumnsType<IPreviewSound> => {
  const cols = SoundListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, buttons, filters, sorter);

  cols.splice(cols.length - 2, 0, {
    title: '',
    dataIndex: 'player',
    key: 'player',
    render: (_, sound: IPreviewSound) => sound.original_filename ? <AudioPlayer key={sound.id} t={t} stream={() => streamSoundFilename(sound.id)} size='s' /> : <></>,
    sortDirections: ['descend', 'ascend', ],
  });
  
  return cols;

};
