import { PhonenumberSetupCostActions, } from '@Store';
import { IPreviewPhonenumberSetupCost, } from 'atlas-shared';
import { fetchPhonenumberSetupCosts, } from '@Api';

export const actionFetchPhonenumberSetupCosts = () => {
  return async (dispatch) => {
    dispatch(actionLoadingPhonenumberSetupCosts());
    const phonenumber_setup_costs = await fetchPhonenumberSetupCosts();

    dispatch(actionSetPhonenumberSetupCosts(phonenumber_setup_costs));
    return phonenumber_setup_costs;
  };
};

export const actionLoadingPhonenumberSetupCosts = () => ({
  type: PhonenumberSetupCostActions.LOADING,
  payload: [],
});

export const actionSetPhonenumberSetupCosts = (payload: Array<IPreviewPhonenumberSetupCost>) => ({
  type: PhonenumberSetupCostActions.SET_STATE,
  payload: payload,
});

export const actionAddPhonenumberSetupCost = (payload: IPreviewPhonenumberSetupCost) => ({
  type: PhonenumberSetupCostActions.ADD_STATE,
  payload: payload,
});

export const actionUpdatePhonenumberSetupCost = (payload: IPreviewPhonenumberSetupCost) => ({
  type: PhonenumberSetupCostActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemovePhonenumberSetupCost = (id: IPreviewPhonenumberSetupCost['id']) => ({
  type: PhonenumberSetupCostActions.REMOVE_STATE,
  payload: id,
});
