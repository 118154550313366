import { IPreviewOrganization, } from 'atlas-shared';

export enum OrganizationActions {
  SET_STATE = 'ORGANIZATION_SET_STATE',
  ADD_STATE = 'ORGANIZATION_ADD_STATE',
  REMOVE_STATE = 'ORGANIZATION_REMOVE_STATE',
  GET_STATE = 'ORGANIZATION_GET_STATE',
  UPDATE_STATE = 'ORGANIZATION_UPDATE_STATE',
  LOADING = 'ORGANIZATION_LOADING',
}

export interface IOrganizationLoading {
  type: OrganizationActions.LOADING;
}

export interface IOrganizationSetAction {
  type: OrganizationActions.SET_STATE;
  payload: Array<IPreviewOrganization>;
}

export interface IOrganizationUpdateAction {
  type: OrganizationActions.UPDATE_STATE;
  payload: IPreviewOrganization;
}

export interface IOrganizationAddAction {
  type: OrganizationActions.ADD_STATE;
  payload: IPreviewOrganization;
}

export interface IOrganizationRemoveAction {
  type: OrganizationActions.REMOVE_STATE;
  payload: IPreviewOrganization['id'];
}

export interface IOrganizationStore {
  loaded: boolean;
  loading: boolean;
  organizations: Array<IPreviewOrganization>;
  dict: Record<IPreviewOrganization['id'], IPreviewOrganization>;
}

export type TOrganizationActionTypes = IOrganizationSetAction | IOrganizationUpdateAction | IOrganizationAddAction | IOrganizationRemoveAction | IOrganizationLoading;
