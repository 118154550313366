import { RestRequest, } from '@Utils';
import { IPreviewStatsAlarm, ISharedStatsAlarm, ISharedCreateStatsAlarm, ISharedPatchStatsAlarm, } from 'atlas-shared';

export const fetchStatsAlarms = async (): Promise<Array<IPreviewStatsAlarm>> => {
  return RestRequest.get<Array<IPreviewStatsAlarm>>('stats_alarm', { socket_id: global.socketId, });
};

export const fetchStatsAlarm = async (id: IPreviewStatsAlarm['id']): Promise<ISharedStatsAlarm> => {
  return RestRequest.get<ISharedStatsAlarm>(`stats_alarm/${id}`, { socket_id: global.socketId, });
};

export const createStatsAlarm = (payload: ISharedCreateStatsAlarm) => {
  return RestRequest.post<any, ISharedStatsAlarm>('stats_alarm', payload, { socket_id: global.socketId, });
};

export const patchStatsAlarm = (id: IPreviewStatsAlarm['id'], payload: ISharedPatchStatsAlarm) => {
  return RestRequest.patch<any, ISharedStatsAlarm>(`stats_alarm/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteStatsAlarm = (id: IPreviewStatsAlarm['id']) => {
  return RestRequest.delete(`stats_alarm/${id}`, { socket_id: global.socketId, });
};

export const getStatsAlarmUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_alarm/update/schema');
};

export const getStatsAlarmCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_alarm/create/schema');
};

export const getStatsAlarmPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_alarm/patch/schema');
};
