import React from 'react';
import { ISvgIconProps, } from './interface';
import { SvgIcon, } from './SvgIcon';

export const VolumeIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
  </SvgIcon>;
};

export const VolumeXIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
    <line x1="23" y1="9" x2="17" y2="15"></line>
    <line x1="17" y1="9" x2="23" y2="15"></line>
  </SvgIcon>;
};

export const Volume1Icon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
    <path d="M15.54 8.46a5 5 0 0 1 0 7.07"></path>
  </SvgIcon>;
};

export const Volume2Icon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
    <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
  </SvgIcon>;
};
