import { IPreviewClient, } from 'atlas-shared';

export enum ClientActions {
  SET_STATE = 'CLIENT_SET_STATE',
  ADD_STATE = 'CLIENT_ADD_STATE',
  REMOVE_STATE = 'CLIENT_REMOVE_STATE',
  GET_STATE = 'CLIENT_GET_STATE',
  UPDATE_STATE = 'CLIENT_UPDATE_STATE',
  LOADING = 'CLIENT_LOADING',
}

export interface IClientLoading {
  type: ClientActions.LOADING;
}

export interface IClientSetAction {
  type: ClientActions.SET_STATE;
  payload: Array<IPreviewClient>;
}

export interface IClientUpdateAction {
  type: ClientActions.UPDATE_STATE;
  payload: IPreviewClient;
}

export interface IClientAddAction {
  type: ClientActions.ADD_STATE;
  payload: IPreviewClient;
}

export interface IClientRemoveAction {
  type: ClientActions.REMOVE_STATE;
  payload: IPreviewClient['id'];
}

export interface IClientStore {
  loaded: boolean;
  loading: boolean;
  clients: Array<IPreviewClient>;
  dict: Record<IPreviewClient['id'], IPreviewClient>;
}

export type TClientActionTypes = IClientSetAction | IClientUpdateAction | IClientAddAction | IClientRemoveAction | IClientLoading;
