import { GdprActions, } from '@Store';
import { IPreviewGdpr, } from 'atlas-shared';
import { fetchGdprs, } from '@Api';

export const actionFetchGdprs = () => {
  return async (dispatch) => {
    dispatch(actionLoadingGdprs());
    const gdprs = await fetchGdprs();

    dispatch(actionSetGdprs(gdprs));
    return gdprs;
  };
};

export const actionLoadingGdprs = () => ({
  type: GdprActions.LOADING,
  payload: [],
});

export const actionSetGdprs = (payload: Array<IPreviewGdpr>) => ({
  type: GdprActions.SET_STATE,
  payload: payload,
});

export const actionAddGdpr = (payload: IPreviewGdpr) => ({
  type: GdprActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateGdpr = (payload: IPreviewGdpr) => ({
  type: GdprActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveGdpr = (id: IPreviewGdpr['id']) => ({
  type: GdprActions.REMOVE_STATE,
  payload: id,
});
