import { journeyAction, } from './base';

import { JourneyActionFetchContactData as JourneyActionFetchContactDataBase, } from 'atlas-shared';
import { FormElementTypes, FormLayoutTypes, } from 'atlas-form';

export const JourneyActionFetchContactData = journeyAction({
  ...JourneyActionFetchContactDataBase,
  additionalParams: {
    properties_status_ports: { ui_type: FormElementTypes.LIST, addText: 'ADD_PORT', },
    properties_body: { ui_layout: FormLayoutTypes.INLINE, noPadding: true, addText: 'ADD_PROPERTY', },
    properties_body_key: { hideLabel: true, },
    properties_body_type: { hideLabel: true, },
    properties_body_value: { hideLabel: true, },
    properties_body_variable_alias: { hideLabel: true, },
    properties_headers: { ui_layout: FormLayoutTypes.INLINE, noPadding: true, addText: 'ADD_PROPERTY', },
    properties_headers_key: { hideLabel: true, },
    properties_headers_type: { hideLabel: true, },
    properties_headers_value: { hideLabel: true, },
    properties_headers_variable_alias: { hideLabel: true, },
    properties_query_params: { ui_layout: FormLayoutTypes.INLINE, noPadding: true, addText: 'ADD_PROPERTY', },
    properties_query_params_key: { hideLabel: true, },
    properties_query_params_type: { hideLabel: true, },
    properties_query_params_value: { hideLabel: true, },
    properties_query_params_variable_alias: { hideLabel: true, },
    properties_merge: { label: 'MERGE_CONTACT_IF_EXISTS', },
    properties_merge_first_name: { postFixText: 'OVERRIDES_CURRENT', },
    properties_merge_last_name: { postFixText: 'OVERRIDES_CURRENT', },
    properties_merge_phones: { postFixText: 'MERGES_AND_REMOVES_DUPLICATED', },
    properties_merge_emails: { postFixText: 'MERGES_AND_REMOVES_DUPLICATED', },
    properties_merge_data: { label: 'CUSTOM_DATA', nullable: true, },
  },
  icon: 'User',
});
