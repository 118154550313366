import { NavigateFunction, } from 'react-router';
import {
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { ISharedCreatePhonenumberMonthlyCostParent, ISharedUpdatePhonenumberMonthlyCostParent, ISharedPhonenumberMonthlyCostParent, IPreviewOrganization, EnumOptions, InvoiceCurrencyEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { entitiesToOptions, onFormSaved, } from '@Utils';

export interface IPhonenumberMonthlyCostParentFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const PhonenumberMonthlyCostParentFormOptions = (phonenumber_monthly_cost_parent: ISharedCreatePhonenumberMonthlyCostParent | ISharedPhonenumberMonthlyCostParent, props: IPhonenumberMonthlyCostParentFormOptionsProps, t: TFunction): IFormOptions => ({
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  currency: EnumOptions('INVOICE_CURRENCY', InvoiceCurrencyEnum, t),
});

export const PhonenumberMonthlyCostParentComponents: IForm['components'] = {};
export const PhonenumberMonthlyCostParentAdditionalParams: (auth: IAuth, props: IPhonenumberMonthlyCostParentFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPhonenumberMonthlyCostParentFormOptionsProps) => ({
});

export const PhonenumberMonthlyCostParentEditForm = (phonenumber_monthly_cost_parent: ISharedPhonenumberMonthlyCostParent, props: IPhonenumberMonthlyCostParentFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `phonenumber_monthly_cost_parent_edit_${phonenumber_monthly_cost_parent.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'currency', },
    { ref: 'phonenumber_monthly_cost_list', },
  ],
});

export const PhonenumberMonthlyCostParentAddForm = (phonenumber_monthly_cost_parent: ISharedCreatePhonenumberMonthlyCostParent, props: IPhonenumberMonthlyCostParentFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'phonenumber_monthly_cost_parent_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'currency', },
    { ref: 'phonenumber_monthly_cost_list', },
  ],
});

export const PhonenumberMonthlyCostParentAddInitialValues: ISharedCreatePhonenumberMonthlyCostParent = {} as ISharedCreatePhonenumberMonthlyCostParent;

export const PhonenumberMonthlyCostParentEditInitialValues = (phonenumber_monthly_cost_parent: ISharedPhonenumberMonthlyCostParent): ISharedUpdatePhonenumberMonthlyCostParent => ({
  title: phonenumber_monthly_cost_parent.title,
  currency: phonenumber_monthly_cost_parent.currency,
  phonenumber_monthly_cost_list: phonenumber_monthly_cost_parent.phonenumber_monthly_cost_list,
});

export const onPhonenumberMonthlyCostParentFormSaved = (t: TFunction, action: 'edit' | 'add', phonenumber_monthly_cost_parent: ISharedPhonenumberMonthlyCostParent, navigate: NavigateFunction): void => {
  navigate(onFormSaved('phonenumber_monthly_cost_parents'));
};
