import { formatDate, } from '@Utils';
import React from 'react';
import { ISharedCustomerJourney, ISharedConversation, IPreviewContactCustomEvent, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { useStableNavigate, } from '../../context/StableNavigateContext';
import { useParams, } from 'react-router-dom';

interface IProps {
  t: TFunction;
  customer_journey: ISharedCustomerJourney;
  miniMode?: boolean;
  setSecondaryConversationId?: (conversation_id: ISharedConversation['id']) => void;
  setEventId?: (eventId: IPreviewContactCustomEvent['id'])=> void;
}

export const CustomerJourneyListMiniItem = React.memo(({ t, customer_journey, miniMode, setSecondaryConversationId, setEventId, }: IProps) => {
  const navigate = useStableNavigate();
  const { id, organization_id, } = useParams<'id' | 'organization_id'>();

  return (
    <div className={'journey-row-mini clickable'} onClick={() => (setSecondaryConversationId || setEventId) && navigate(`/dashboard/conversation/${organization_id}/${id}/${customer_journey.type}/${customer_journey.id}`)}>
      <span className={'text id'} title={`#${customer_journey.display_id || customer_journey.id}`}>
        #<span>{customer_journey.display_id || customer_journey.id}</span>
      </span>
      {
        !miniMode &&
        <span className={'text dot'}>
          {formatDate(customer_journey.action_at, 'MMM DD')}
        </span>
      }
      {customer_journey.status && <span className={'text dot'}>
        {t(`STATUS_${customer_journey.status}`)}
      </span>}
      {customer_journey.text_status && <span className={'text dot'}>
        {customer_journey.text_status}
      </span>}
    </div>
  );
});
