import { IPreviewTag, } from 'atlas-shared';
import { ITagStore, TagActions, TTagActionTypes, } from '@Store';

export const TagReducer = (state: ITagStore = {
  loaded: false,
  loading: false,
  tags: [],
  dict: {},
}, action: TTagActionTypes): ITagStore => {
  switch (action.type) {
  case TagActions.LOADING:
    return { ...state, loading: true, };
  case TagActions.SET_STATE:
    const dict: ITagStore['dict'] = {};

    action.payload.forEach((tag: IPreviewTag) => dict[tag.id] = tag);

    return { ...state, loaded: true, loading: false, tags: action.payload, dict, };
  case TagActions.ADD_STATE:
    return { ...state, tags: [...state.tags, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case TagActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, tags: state.tags.filter((item: IPreviewTag) => item.id !== action.payload), dict, };
  }
  case TagActions.UPDATE_STATE:
    const tags = [...state.tags, ];
    const index = state.tags.findIndex((tag: IPreviewTag) => tag.id === action.payload.id);

    if (!~index)
      tags.push(action.payload);
    else
      tags[index] = action.payload;

    return { ...state, tags, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
