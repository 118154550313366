import { RestRequest, } from '@Utils';
import { IPreviewStatsReport, ISharedStatsReport, ISharedCreateStatsReport, ISharedPatchStatsReport, } from 'atlas-shared';

export const fetchStatsReports = async (): Promise<Array<IPreviewStatsReport>> => {
  return RestRequest.get<Array<IPreviewStatsReport>>('stats_report', { socket_id: global.socketId, });
};

export const fetchStatsReport = async (id: IPreviewStatsReport['id']): Promise<ISharedStatsReport> => {
  return RestRequest.get<ISharedStatsReport>(`stats_report/${id}`, { socket_id: global.socketId, });
};

export const createStatsReport = (payload: ISharedCreateStatsReport) => {
  return RestRequest.post<any, ISharedStatsReport>('stats_report', payload, { socket_id: global.socketId, });
};

export const patchStatsReport = (id: IPreviewStatsReport['id'], payload: ISharedPatchStatsReport) => {
  return RestRequest.patch<any, ISharedStatsReport>(`stats_report/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteStatsReport = (id: IPreviewStatsReport['id']) => {
  return RestRequest.delete(`stats_report/${id}`, { socket_id: global.socketId, });
};

export const getStatsReportUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_report/update/schema');
};

export const getStatsReportCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_report/create/schema');
};

export const getStatsReportPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_report/patch/schema');
};
