import { FormTypeEnum, IPreviewField, ISharedCreateForm, ISharedForm, FormFieldConditionComparisonEnum, FormElementTypeExposedEnum, Undefinable, ISharedFormFieldConditionProperty, } from 'atlas-shared/dist';
import { TFunction, } from 'i18next';
import { FormLayoutTypes, IForm, IFormOptions, } from 'atlas-form';
import {
  FormAdditionalParams as FormAdditionalParamsBase,
  FormFormOptions as FormFormOptionsBase,
  IFormFormOptionsProps,
} from './abstract/form.form.abstract';
import { filterRelated, IOption, } from '@Utils';
import { IAuth, } from '@Store';

export * from './abstract/form.form.abstract';

export const FormFormOptions = (form: ISharedCreateForm | ISharedForm, props: IFormFormOptionsProps, t: TFunction): IFormOptions => {
  const base = FormFormOptionsBase(form, props, t);

  return {
    ...base,
    __fields: (it: ISharedForm) => filterRelated<IPreviewField>(it.organization_id || form.organization_id, 'field', 'form', props.fields, true, field => {
      const formType = (it.type || form.type);
      const websiteForm = formType === FormTypeEnum.Website;

      return websiteForm || (formType === (field.type as any) && (field.alias !== '__conversation_body' || (websiteForm && field.alias === '__conversation_body')));
    }, true),
    field_conditions_field_id: (it: ISharedForm) => {
      return (base.field_conditions_field_id as any)?.(it).filter(f => it.__fields.includes(f.key as any));
    },
    field_conditions_condition_field_id: (it: ISharedForm) => {
      return (base.field_conditions_field_id as any)?.(it).filter(f => {
        if (it.__fields.includes(f.key as any)) {
          const field: Undefinable<IPreviewField> = props.fields.find(field => field.id === f.key);

          if (field?.settings.ui_type)
            return [
              FormElementTypeExposedEnum.INPUT,
              FormElementTypeExposedEnum.NUMBER,
              FormElementTypeExposedEnum.SELECT,
              FormElementTypeExposedEnum.SWITCH,
              FormElementTypeExposedEnum.RADIO,
              FormElementTypeExposedEnum.CHECKBOXES,
              FormElementTypeExposedEnum.CHECKBOX,
              FormElementTypeExposedEnum.EMAIL,
            ].includes(field.settings.ui_type);
        }

        return false;
      });
    },
    field_conditions_condition_comparison: (it: ISharedForm, b: ISharedFormFieldConditionProperty['condition']) => {
      const field: Undefinable<IPreviewField> = b && props.fields.find(field => field.id === b.field_id);
      const all_options = {};

      (base.field_conditions_condition_comparison as Array<IOption>).forEach(option => {
        all_options[option.key as any] = { ...option, title: t(option.title?.replace('FORM_FIELD_CONDITION_COMPARISON_', '')), };
      });

      const SELECT_OPTIONS = [
        all_options[FormFieldConditionComparisonEnum.EqStr],
        all_options[FormFieldConditionComparisonEnum.nEqStr],
        all_options[FormFieldConditionComparisonEnum.AnyOfStr],
        all_options[FormFieldConditionComparisonEnum.NoneOfStr],
      ];
      const STRING_OPTIONS = [
        all_options[FormFieldConditionComparisonEnum.EqStr],
        all_options[FormFieldConditionComparisonEnum.IsEmpty],
        all_options[FormFieldConditionComparisonEnum.IsNotEmpty],
        ...SELECT_OPTIONS,
      ];
      const BOOLEAN_OPTIONS = [
        all_options[FormFieldConditionComparisonEnum.IsFalse],
        all_options[FormFieldConditionComparisonEnum.IsTrue],
      ];
      const NUMBER_OPTIONS = [
        all_options[FormFieldConditionComparisonEnum.EqNum],
        all_options[FormFieldConditionComparisonEnum.nEqNum],
        all_options[FormFieldConditionComparisonEnum.AnyOfNum],
        all_options[FormFieldConditionComparisonEnum.NoneOfNum],
        all_options[FormFieldConditionComparisonEnum.Gt],
        all_options[FormFieldConditionComparisonEnum.Gte],
        all_options[FormFieldConditionComparisonEnum.Lt],
        all_options[FormFieldConditionComparisonEnum.Lte],
      ];
      const NULL_OPTIONS = [
        all_options[FormFieldConditionComparisonEnum.IsNull],
        all_options[FormFieldConditionComparisonEnum.IsNotNull],
      ];
      const DEFINED_OPTIONS = [
        all_options[FormFieldConditionComparisonEnum.IsDefined],
        all_options[FormFieldConditionComparisonEnum.IsUndefined],
      ];

      const option_type_map = {
        [FormElementTypeExposedEnum.INPUT]: STRING_OPTIONS,
        [FormElementTypeExposedEnum.NUMBER]: NUMBER_OPTIONS,
        [FormElementTypeExposedEnum.SELECT]: SELECT_OPTIONS,
        [FormElementTypeExposedEnum.SWITCH]: BOOLEAN_OPTIONS,
        [FormElementTypeExposedEnum.RADIO]: SELECT_OPTIONS,
        [FormElementTypeExposedEnum.CHECKBOXES]: SELECT_OPTIONS,
        [FormElementTypeExposedEnum.CHECKBOX]: BOOLEAN_OPTIONS,
        [FormElementTypeExposedEnum.EMAIL]: STRING_OPTIONS,
      };

      let type_options = [
        ...DEFINED_OPTIONS,
        ...(field?.settings?.ui_type ? option_type_map[field.settings.ui_type] : []),
      ];

      if (field?.settings.nullable)
        type_options = [
          ...type_options,
          ...NULL_OPTIONS,
        ];

      return type_options;
    },
    field_conditions_condition_value_str: (it: ISharedForm, b: ISharedFormFieldConditionProperty['condition']) => {
      const field: Undefinable<IPreviewField> = b && props.fields.find(field => field.id === b.field_id);

      if (field?.settings?.validation?.valid?.length)
        return field.settings.validation.valid.map(v => ({ key: v.alias, title: v.title, }));

      return null;
    },
    field_conditions_condition_values: (it: ISharedForm, b: ISharedFormFieldConditionProperty['condition']) => {
      const field: Undefinable<IPreviewField> = b && props.fields.find(field => field.id === b.field_id);

      if (field?.settings?.validation?.valid?.length)
        return field.settings.validation.valid.map(v => ({ key: v.alias, title: v.title, }));

      return [];
    },
  };
};

export const FormAdditionalParams: (auth: IAuth, props: IFormFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IFormFormOptionsProps) => {
  const value_fields = (a, b: ISharedFormFieldConditionProperty['condition']) => {
    return { hideLabel: true, label: 'VALUE', hidden: !b?.field_id || !b?.comparison, };
  };

  return {
    ...FormAdditionalParamsBase(auth, props),
    field_conditions_field_id: { hideLabel: true, help: 'FIELD_TO_AFFECT', },
    field_conditions_condition: { label: 'IF', ui_layout: FormLayoutTypes.INLINE, },
    field_conditions_condition_field_id: { hideLabel: true, },
    field_conditions_condition_comparison: { hideLabel: true, },
    field_conditions_condition_value_str: value_fields,
    field_conditions_condition_value_num: value_fields,
    field_conditions_condition_values: (a, b, c) => {
      const field: Undefinable<IPreviewField> = b && props.fields.find(field => field.id === b.field_id);

      return { hideLabel: true, label: 'VALUE', hidden: !b?.field_id || !b?.comparison, mode: field?.settings?.validation?.valid?.length ? 'multiple' : 'tags', };
    },
  };
};
