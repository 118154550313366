import { SharedAbilities, SharedAbility, IPreviewUser, } from 'atlas-shared';

export type IAbilities = SharedAbilities<SharedAbility<IPreviewUser>, IPreviewUser>;

export enum AbilitiesActions {
  SET_STATE = 'ABILITIES_SET_STATE',
  REMOVE_STATE = 'ABILITIES_REMOVE_STATE',
}

export interface IAbilitiesSetAction {
  type: AbilitiesActions.SET_STATE;
  payload: IAbilities;
}

export interface IAbilitiesRemoveAction {
  type: AbilitiesActions.REMOVE_STATE;
}

export type TAbilitiesActionTypes = IAbilitiesSetAction | IAbilitiesRemoveAction;
