import { getStatsWidgetChartMetricStyles, getStatsWidgetMetricDataFormatted, } from '@Utils';
import React from 'react';
import { TFunction, } from 'i18next';
import {
  IPreviewOrganization,
  IPreviewStatsWidget, IPreviewStatsWidgetSettingsNestedMetricsMetricProperty,
} from 'atlas-shared/dist';
import { IStatsCustomMetricStore, } from '@Store';

interface IProps {
  t: TFunction;
  dataKey: string;
  metric: IPreviewStatsWidgetSettingsNestedMetricsMetricProperty;
  organization: IPreviewOrganization;
  stats_widget: IPreviewStatsWidget;
  stats_custom_metrics: IStatsCustomMetricStore;
  nested_metric_index: number;
  trend?: boolean;
}

interface IForcedProps extends IProps {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  style: React.CSSProperties;
  opacity: number;
  strokeWidth: number;
  stroke: string;
}

export const WidgetChartBar = (props: IProps) => {
  const { t, dataKey, metric, organization, stats_widget, stats_custom_metrics, fill: _fill, x, y, nested_metric_index, width: _width, height, style, opacity, strokeWidth: _strokeWidth, stroke: _stroke, trend = false, } = props as IForcedProps;
  
  if (!height)
    return <></>;

  const styles = props[dataKey] ? getStatsWidgetChartMetricStyles(t, props[dataKey], props[`${dataKey}_trend`] || 0, props[`${dataKey}_trend_comparison`], metric, nested_metric_index, stats_widget, stats_custom_metrics.stats_custom_metrics) : {};
  
  const width = Math.max(trend ? (_width / 5) * 4 : _width, 3);
  //const width = _width;

  const strokeWidth = Math.min(styles.borderWidth || _strokeWidth || 0, height / 2, width / 2);
  const color = styles.color || _fill;
  const fill = styles.backgroundColor || _fill;
  const stroke = styles.borderColor || _stroke || fill;
  const value = props[dataKey] && height > 10 ? getStatsWidgetMetricDataFormatted(props.t, metric, props[dataKey], organization) : '';
  const charLength = ((value + '').length * 6) + 6;
  const boxWidth = Math.min(width, Math.max(width / 2, charLength));
  const boxHeight = styles.boxColor ? Math.min(height, 13) : 0;

  return <>
    <path d={getPath(x, y, width, height, strokeWidth)} x={x} y={y} fill={fill} style={style} strokeWidth={strokeWidth} opacity={opacity} stroke={stroke} />
    {stats_widget.settings.ui.display_values && value && boxWidth && boxWidth >= charLength && <>
      <rect width={boxWidth} height={boxHeight} x={x - (trend ? 5 : 0) + ((width - boxWidth) / 2)} y={y - (boxHeight / 2) + (height / 2)} rx={3} fill={styles.boxColor}/>
      <text x={x + (width / 2)} y={y + (height / 2)} width={width} fill={color} height={height} fontSize={10} style={style} dy={3} textAnchor="middle">
        {value}
      </text>
    </>}
  </>;
};

const getPath = (x: number, y: number, _width: number, _height: number, strokeWidth: number = 0) => {
  const width = _width - strokeWidth;
  const height = _height - strokeWidth;

  return `M ${x + (strokeWidth / 2)},${y + (strokeWidth / 2)} h ${width} v ${height} h ${width * -1} Z`;
};
