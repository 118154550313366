import { Icon, } from '@Components';
import { AuditIcon, } from '@Assets/icons';
import { Tooltip, } from 'antd';
import React from 'react';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { auditSearchLink, } from '@Utils';

export const listAuditButton = <T extends {id: number}>(t: TFunction, navigate: NavigateFunction, entity: string | Array<string>, row: T): React.ReactElement => {
  return <Tooltip overlayClassName={'table-tooltip-custom'} arrowPointAtCenter title={t('AUDIT')}
    placement="bottom">
    <div className={'table-icon-wrp'}>
      <Icon icon={AuditIcon} iconProps={{ size: 14, }} onClick={_ => navigate(auditSearchLink(entity, row.id)) }/>
    </div>
  </Tooltip>;
};
