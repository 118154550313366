import { RestRequest, } from '@Utils/rest';
import { IAuth2faResponse, IAuthResponse, ISharedUser, cipher, DECIPHER_POSTFIX, } from 'atlas-shared';

export const apiSignIn = (username: string, password: string): Promise<IAuthResponse | IAuth2faResponse> => {
  return RestRequest.post<{username: string; password: string}, IAuthResponse | IAuth2faResponse>('auth/login', { username, password: cipher('atlas')(password), });
};

export const apiTwoFactorAuthenticate = (two_factor_session_token: string, two_factor_auth_token: string): Promise<IAuthResponse> => {
  return RestRequest.post<{two_factor_auth_token: string; two_factor_session_token: string}, IAuthResponse>('auth/two-factor', { two_factor_auth_token, two_factor_session_token, });
};

export const apiRefreshToken = (refresh_token: IAuthResponse['tokens']['refresh_token'], old_access_token: IAuthResponse['tokens']['access_token']): Promise<IAuthResponse> => {
  return RestRequest.post<{refresh_token: IAuthResponse['tokens']['refresh_token']; old_access_token: IAuthResponse['tokens']['access_token']}, IAuthResponse>('auth/refresh', { refresh_token, old_access_token, });
};

export const apiRequestResetPassword = (username: ISharedUser['username']): Promise<any> => {
  return RestRequest.post<{username: ISharedUser['username']}, any>('auth/request/password/reset', { username, });
};

export const apiResetPassword = (username: ISharedUser['username'], token: string, password: string): Promise<any> => {
  return RestRequest.post<{username: ISharedUser['username']; token: string; password: string}, any>('auth/reset/password', {
    username,
    token,
    password: `${cipher('atlas')(password)}${DECIPHER_POSTFIX}`,
  });
};

export const apiMe = (): Promise<IAuthResponse> => {
  return RestRequest.get<IAuthResponse>('auth/me');
};
