import { RestRequest, } from '@Utils';
import { IPreviewInvoice, ISharedInvoice, ISharedPatchInvoice, } from 'atlas-shared';

export const fetchInvoices = async (): Promise<Array<IPreviewInvoice>> => {
  return RestRequest.get<Array<IPreviewInvoice>>('invoice', { socket_id: global.socketId, });
};

export const fetchInvoice = async (id: IPreviewInvoice['id']): Promise<ISharedInvoice> => {
  return RestRequest.get<ISharedInvoice>(`invoice/${id}`, { socket_id: global.socketId, });
};

export const patchInvoice = (id: IPreviewInvoice['id'], payload: ISharedPatchInvoice) => {
  return RestRequest.patch<any, ISharedInvoice>(`invoice/${id}`, payload, { socket_id: global.socketId, });
};

export const getInvoiceUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('invoice/update/schema');
};

export const getInvoicePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('invoice/patch/schema');
};
