import { IPreviewPhonenumberPurchase, } from 'atlas-shared';
import { IPhonenumberPurchaseStore, PhonenumberPurchaseActions, TPhonenumberPurchaseActionTypes, } from '@Store';

export const PhonenumberPurchaseReducer = (state: IPhonenumberPurchaseStore = {
  loaded: false,
  loading: false,
  phonenumber_purchases: [],
  dict: {},
}, action: TPhonenumberPurchaseActionTypes): IPhonenumberPurchaseStore => {
  switch (action.type) {
  case PhonenumberPurchaseActions.LOADING:
    return { ...state, loading: true, };
  case PhonenumberPurchaseActions.SET_STATE:
    const dict: IPhonenumberPurchaseStore['dict'] = {};

    action.payload.forEach((phonenumber_purchase: IPreviewPhonenumberPurchase) => dict[phonenumber_purchase.id] = phonenumber_purchase);

    return { ...state, loaded: true, loading: false, phonenumber_purchases: action.payload, dict, };
  case PhonenumberPurchaseActions.ADD_STATE:
    return { ...state, phonenumber_purchases: [...state.phonenumber_purchases, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case PhonenumberPurchaseActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, phonenumber_purchases: state.phonenumber_purchases.filter((item: IPreviewPhonenumberPurchase) => item.id !== action.payload), dict, };
  }
  case PhonenumberPurchaseActions.UPDATE_STATE:
    const phonenumber_purchases = [...state.phonenumber_purchases, ];
    const index = state.phonenumber_purchases.findIndex((phonenumber_purchase: IPreviewPhonenumberPurchase) => phonenumber_purchase.id === action.payload.id);

    if (!~index)
      phonenumber_purchases.push(action.payload);
    else
      phonenumber_purchases[index] = action.payload;

    return { ...state, phonenumber_purchases, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
