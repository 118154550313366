import React, { useState, } from 'react';
import { IChangelogHistory, } from './changelog.history';
import { ChangelogHistoryVersionChange, } from './changelog.history.version.change';
import { TFunction, } from 'i18next';

interface IProps {
  t: TFunction;
  history: IChangelogHistory;
  expanded?: boolean;
}

export const ChangelogHistoryVersion = React.memo(({ t, history, expanded = false, }: IProps) => {

  const [isExpanded, setExpanded, ] = useState<boolean>(expanded);

  return <li className={`version${isExpanded ? ' expanded' : ''}`}>
    <div className='title' onClick={() => setExpanded(!isExpanded)}>
      <span className='date'>{history.date}</span>
      <span className='version'>v{history.version}</span>
    </div>
    <ul className='changes'>
      {history.history.map(change => <ChangelogHistoryVersionChange t={t} change={change} />)}
    </ul>
  </li>;
});
