import { clone, IMetricDataDicts, } from '@Utils';
import React from 'react';
import { TFunction, } from 'i18next';
import { IPreviewStatsWidgetSettingsMetricProperty, IPreviewStatsWidget, IMetric, IPreviewStatsCustomMetric, StatsWidgetTableTrendIndicatorEnum, StatsWidgetMetricFormatEnum, IPreviewOrganization, TimezoneMomentMap, } from 'atlas-shared/dist';
import { StatsWidgetMetricDataValue, } from './stats_widget.metric.data.value';
import { Icon, } from '@Components';
import { MinusIcon, TrendingDownIcon, TrendingUpIcon, } from '@Assets';
import moment from 'moment-timezone';

interface IProps {
  t: TFunction;
  organization: IPreviewOrganization;
  stats_widget: IPreviewStatsWidget;
  metric: IMetric | IPreviewStatsCustomMetric;
  metric_settings: IPreviewStatsWidgetSettingsMetricProperty;
  trend_value?: number;
  trend_comparison?: number;
  trend_period?: [string, string];
  value: number;
  metric_data_dicts: IMetricDataDicts;
}
export const StatsWidgetMetricTrend = ({ t, stats_widget, metric, metric_settings: _metric_settings, trend_value, trend_comparison, trend_period, value, metric_data_dicts, organization, }: IProps) => {

  if (trend_value == undefined || trend_period === undefined || !stats_widget.settings.trend)
    return {
      trend_classes: [],
      trend_data: '',
      trend_elm: <></>,
    };

  const metric_settings = clone(_metric_settings);

  const trend_settings = stats_widget.settings.trend;
  const indicator = trend_settings!.text_indicator || StatsWidgetTableTrendIndicatorEnum.Data;
  const hasArrow = [
    StatsWidgetTableTrendIndicatorEnum.Arrow,
    StatsWidgetTableTrendIndicatorEnum.ArrowData,
    StatsWidgetTableTrendIndicatorEnum.ArrowDiffData,
    StatsWidgetTableTrendIndicatorEnum.ArrowDiffPercentage,
  ].includes(indicator);
  const hasIcon = [
    StatsWidgetTableTrendIndicatorEnum.Icon,
    StatsWidgetTableTrendIndicatorEnum.IconData,
    StatsWidgetTableTrendIndicatorEnum.IconDiffData,
    StatsWidgetTableTrendIndicatorEnum.IconDiffPercentage,
  ].includes(indicator);
  let display_value: number | string = trend_value;
  const compare_value = (trend_comparison !== undefined ? trend_comparison : value);
  const trend_diff = compare_value - trend_value;

  switch (indicator) {
  case StatsWidgetTableTrendIndicatorEnum.ArrowDiffData:
  case StatsWidgetTableTrendIndicatorEnum.DiffData:
  case StatsWidgetTableTrendIndicatorEnum.IconDiffData:
    display_value = trend_diff;

    if (display_value > 0)
      display_value = `+${display_value}`;

    break;
  case StatsWidgetTableTrendIndicatorEnum.DiffPercentage:
  case StatsWidgetTableTrendIndicatorEnum.ArrowDiffPercentage:
  case StatsWidgetTableTrendIndicatorEnum.IconDiffPercentage:
    let perc_value = +compare_value;
    let perc_trend = +trend_value;

    if (!perc_value) {
      perc_value = 1;
      perc_trend++;
    }
    else if (!perc_trend) {
      perc_trend = 1;
      perc_value++;
    }

    display_value = (perc_value / perc_trend) * 100;
    if (!+compare_value && display_value < 100)
      display_value = 0;

    display_value = display_value === 100 ? 0 : display_value >= 100 ? `+${display_value - 100}` : `-${100 - display_value}`;

    metric_settings.format = StatsWidgetMetricFormatEnum.VALUE_PERCENT;
    metric_settings.precision = 0;

    break;
  case StatsWidgetTableTrendIndicatorEnum.Icon:
  case StatsWidgetTableTrendIndicatorEnum.Arrow:
    display_value = '';
    break;
  }
  const trend_classes = [`trend-${(metric as IMetric)?.negative ? 'negative' : 'positive'}`, `trend-${trend_diff ? trend_diff > 0 ? 'up' : 'down' : 'unchanged'}`, `trend-indicator-${stats_widget.settings.trend!.text_indicator?.toLowerCase() || 'data'}`, ];
  const { rendered = <></>, text_value = undefined, } = (display_value !== undefined && display_value !== null) ? StatsWidgetMetricDataValue({ t, metric_data_dicts, metric_settings, value: display_value, is_trend: true, organization, }) : {};
  const tz = TimezoneMomentMap[organization.timezone];
  const trend_period_arr = trend_period?.map(tp => tp && moment(tp.replace(' ', 'T') + 'Z').tz(tz).format('YYYY-MM-DD HH:mm'));

  return {
    trend_classes,
    trend_data: `Data: ${trend_value}${trend_period_arr ? `, Period: ${trend_period_arr.join(' - ')}` : ''}`,
    trend_tooltip: <div>
      <span style={{ opacity: .5, }}>{t('TREND_VALUE')}:</span> {trend_value}
      {trend_comparison && <>
        <br />
        <span style={{ opacity: .5, }}>{t('TREND_COMPARISON_VALUE')}:</span> {trend_comparison}
      </>}
      {trend_period ? <>
        <br />
        <span style={{ opacity: .5, }}>{t('TREND_PERIOD')}:</span> {trend_period_arr.map((tp, i) => <div key={i}>{tp}</div>)}
      </> : ''}</div>,
    trend_elm: <div className={'trend'}>
      {hasArrow && <span className='arrow'>{trend_diff ? trend_diff > 0 ? '▲' : '▼' : '-'}</span>}
      {hasIcon && <Icon icon={trend_diff ? trend_diff > 0 ? TrendingUpIcon : TrendingDownIcon : MinusIcon} />}
      {indicator !== StatsWidgetTableTrendIndicatorEnum.Background && rendered}
    </div>,
  };
};
