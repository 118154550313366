import { RestRequest, } from '@Utils';
import { IPreviewClient, ISharedClient, ISharedCreateClient, ISharedPatchClient, } from 'atlas-shared';

export const fetchClients = async (): Promise<Array<IPreviewClient>> => {
  return RestRequest.get<Array<IPreviewClient>>('client', { socket_id: global.socketId, });
};

export const fetchClient = async (id: IPreviewClient['id']): Promise<ISharedClient> => {
  return RestRequest.get<ISharedClient>(`client/${id}`, { socket_id: global.socketId, });
};

export const createClient = (payload: ISharedCreateClient) => {
  return RestRequest.post<any, ISharedClient>('client', payload, { socket_id: global.socketId, });
};

export const patchClient = (id: IPreviewClient['id'], payload: ISharedPatchClient) => {
  return RestRequest.patch<any, ISharedClient>(`client/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteClient = (id: IPreviewClient['id']) => {
  return RestRequest.delete(`client/${id}`, { socket_id: global.socketId, });
};

export const getClientUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('client/update/schema');
};

export const getClientCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('client/create/schema');
};

export const getClientPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('client/patch/schema');
};
