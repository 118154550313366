import { IPreviewTab, } from 'atlas-shared';
import { ITabStore, TabActions, TTabActionTypes, } from '@Store';

export const TabReducer = (state: ITabStore = {
  loaded: false,
  loading: false,
  tabs: [],
  dict: {},
}, action: TTabActionTypes): ITabStore => {
  switch (action.type) {
  case TabActions.LOADING:
    return { ...state, loading: true, };
  case TabActions.SET_STATE:
    const dict: ITabStore['dict'] = {};

    action.payload.forEach((tab: IPreviewTab) => dict[tab.id] = tab);

    return { ...state, loaded: true, loading: false, tabs: action.payload, dict, };
  case TabActions.ADD_STATE:
    return { ...state, tabs: [...state.tabs, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case TabActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, tabs: state.tabs.filter((item: IPreviewTab) => item.id !== action.payload), dict, };
  }
  case TabActions.UPDATE_STATE:
    const tabs = [...state.tabs, ];
    const index = state.tabs.findIndex((tab: IPreviewTab) => tab.id === action.payload.id);

    if (!~index)
      tabs.push(action.payload);
    else
      tabs[index] = action.payload;

    return { ...state, tabs, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
