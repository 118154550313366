import React from 'react';
import { Tooltip, } from 'antd';
import { IJourneyNodeError, } from 'atlas-shared';

const JourneyError = (props: { errors: Array<IJourneyNodeError> }) => {
  const { errors, } = props;

  if (!errors.length)
    return <></>;

  return <Tooltip
    title={errors.map(e => e.reason).join('\n')}
    overlayClassName='error'
    overlayInnerStyle={{ textAlign: 'center', }}
  >
    <div className='error'></div>
  </Tooltip>;
};

export default React.memo(JourneyError);
