import { IPreviewDisposition, } from 'atlas-shared';

export enum DispositionActions {
  SET_STATE = 'DISPOSITION_SET_STATE',
  ADD_STATE = 'DISPOSITION_ADD_STATE',
  REMOVE_STATE = 'DISPOSITION_REMOVE_STATE',
  GET_STATE = 'DISPOSITION_GET_STATE',
  UPDATE_STATE = 'DISPOSITION_UPDATE_STATE',
  LOADING = 'DISPOSITION_LOADING',
}

export interface IDispositionLoading {
  type: DispositionActions.LOADING;
}

export interface IDispositionSetAction {
  type: DispositionActions.SET_STATE;
  payload: Array<IPreviewDisposition>;
}

export interface IDispositionUpdateAction {
  type: DispositionActions.UPDATE_STATE;
  payload: IPreviewDisposition;
}

export interface IDispositionAddAction {
  type: DispositionActions.ADD_STATE;
  payload: IPreviewDisposition;
}

export interface IDispositionRemoveAction {
  type: DispositionActions.REMOVE_STATE;
  payload: IPreviewDisposition['id'];
}

export interface IDispositionStore {
  loaded: boolean;
  loading: boolean;
  dispositions: Array<IPreviewDisposition>;
  dict: Record<IPreviewDisposition['id'], IPreviewDisposition>;
}

export type TDispositionActionTypes = IDispositionSetAction | IDispositionUpdateAction | IDispositionAddAction | IDispositionRemoveAction | IDispositionLoading;
