import React, { PropsWithChildren, useEffect, useState, } from 'react';
import { Popover, Radio, } from 'antd';
import './header.user.status.pauses.popover.scss';
import { useAuth, usePauses, } from '@Hooks';
import { ISharedPause, IPreviewPause, } from 'atlas-shared';
import { patchUserStatus, } from '@Api';
import { useTranslation, } from 'react-i18next';
import { NoResult, } from '@Components';

export const HeaderUserStatusPausesPopover = React.memo(({ children, }: PropsWithChildren<{}>) => {
  const pauses = usePauses();
  const auth = useAuth();
  const [selectablePauses, setSelectablePauses, ] = useState<Array<IPreviewPause>>([]);
  const { t, } = useTranslation();

  useEffect(() => {
    setSelectablePauses(pauses.pauses.filter(pause => (pause.global || auth.user_status.__current_pauses.includes(pause.id)) && !pause.deleted_at));
  }, [pauses.pauses, auth.user_status.__current_pauses, ]);

  const togglePause = (pause_id: ISharedPause['id']) => patchUserStatus(auth.user_status.id, { pause_id, });

  return (
    <Popover className={'pauses-menu-popup'} placement='bottomRight' content={
      <div className={'pauses-menu-popup-content'}>
        {
          !selectablePauses.length && <NoResult description={t('NO_PAUSES_FOUND')} style={{ padding: 10, }} />
        }
        {
          selectablePauses.map((pause) => <div key={pause.id} className={'menu-item'} onClick={() => togglePause(pause.id)}>
            <Radio checked={auth.user_status.pause_id === pause.id}/>
            <span className={'title ' + (auth.user_status.pause_id === pause.id && 'selected')}>
              {pause.title}
            </span>
            <span className={'span'}/>
          </div>
          )
        }
      </div>
    } trigger='click'>
      {children}
    </Popover>
  );
});
