import React, { useEffect, useState, } from 'react';
import './conversation.disposition.form.scss';
import { useTranslation, } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { useDispositions, useQueues, } from '@Hooks';
import { ISharedConversation, ConversationStatusEnum, ISharedPatchConversation, ISharedFullConversation, IPreviewDisposition, IPreviewQueue, Undefinable, } from 'atlas-shared';
import { entitiesToOptions, filterRelated, IOption, nestedArray, } from '@Utils';
import { IWithFullConversationProps, } from '@Hocs/with.full.conversation';
import { FormTreeSelect, } from 'atlas-form';
import { Button, } from '@Components/misc';

interface IProps {
  conversation: ISharedFullConversation;
  onClose: ()=> void;
  patch_conversation: IWithFullConversationProps['patch_conversation'];
}

export const ConversationDispositionForm = React.memo(({ conversation, onClose, patch_conversation, }: IProps) => {
  const { t, } = useTranslation();
  const [text, setText, ] = useState<ISharedConversation['disposition_note']>(conversation.disposition_note);
  const queues = useQueues();
  const [rowQueue, setRowQueue, ] = useState<Undefinable<IPreviewQueue>>();
  const [disposition, setDisposition, ] = useState<string>([conversation.disposition_id, conversation.sub_disposition_id, conversation.thrd_disposition_id, ].filter(Boolean).join('-'));
  const dispositions = useDispositions();
  const [selectableDispositions, setSelectableDispositions, ] = useState<Array<IOption>>([]);

  useEffect(() => {
    setRowQueue(queues.queues.find(queue => queue.id === conversation.queue_id));
  }, [conversation.queue_id, conversation.user_id, queues, rowQueue, ]);

  const dispose = (resolved: boolean = false) => {
    return new Promise((resolve) => {
      if (disposition) {
        const patch: ISharedPatchConversation = {
          disposition_note: text || null,
        };

        const [a = null, b = null, c = null, ] = disposition.split('-').map(c => +c);

        patch.disposition_id = a;
        patch.sub_disposition_id = b;
        patch.thrd_disposition_id = c;

        if (resolved)
          patch.status = ConversationStatusEnum.Resolved;

        patch_conversation(patch)
          .then(onClose)
          .catch(e => {})
          .finally(() => resolve(true));
      }
      else
        resolve(true);
    });
  };

  useEffect(() => {
    if (!rowQueue)
      return;

    setSelectableDispositions(entitiesToOptions<IPreviewDisposition>(nestedArray<any>(filterRelated<IPreviewDisposition>(conversation.organization_id, 'disposition', 'conversation', dispositions.dispositions.filter(disposition => disposition.parents || disposition.global || rowQueue.__dispositions.includes(disposition.id))), 'parents', true, true), undefined, undefined, true, {}));
  }, [dispositions.dispositions, rowQueue, conversation.organization_id, ]);

  if (!rowQueue)
    return <></>;

  if (!selectableDispositions)
    return <>{t('NO_SELECTABLE_DISPOSITIONS')}</>;

  return (
    <div className={'conversation-disposition-select'}>
      <FormTreeSelect
        onChange={(key: string) => setDisposition(key)}
        className={'selector'}
        value={disposition || undefined}
        placeholder={t('CHOOSE_REASON_FOR_DISPOSAL')}
        options={selectableDispositions}
      />
      <TextArea rows={4} defaultValue={text || ''} onChange={e => setText(e.target.value)} className={'input'} placeholder={`${t('WRITE_A_COMMENT')}...`}/>
      <div className='buttons'>
        <Button onClick={() => dispose()}>
          {t('DISPOSE')}
        </Button>
        <Button type='primary' onClick={() => dispose(true)}>
          {t('DISPOSE_AND_RESOLVE')}
        </Button>
      </div>
    </div>
  );
});
