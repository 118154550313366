import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchOrganizations, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useOrganizations = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const organizations = useSelector((store: IReducerStore) => store.organizations);

  useEffect(() => {
    if (!initiated && autoload && !organizations.loaded && !organizations.loading) {
      initiated = true;
      dispatch(actionFetchOrganizations());
    }
  }, [autoload, organizations.loaded, organizations.loading, dispatch, ]);

  return organizations;
};
