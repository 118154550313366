import { IJourneyPayloadNodesKeyPortsKeyProperty, } from 'atlas-shared';
import { IJourneyActionProps, } from '@Utils';
import { Handle, Position, } from 'react-flow-renderer';
import React from 'react';
import './styles.scss';
import { TFunction, } from 'i18next';
import JourneyError from './journey.error';

interface IProps {
  t: TFunction;
  port: IJourneyPayloadNodesKeyPortsKeyProperty;
  index: number;
  actionProps: IJourneyActionProps;
  errors: Array<any>;
  required: boolean;
}

export const RenderInputHandle = (props: IProps) => {
  const { t, port, errors, required, } = props;

  return (
    <Handle
      type={'target'}
      position={Position.Top}
      title={t(`${required ? '' : 'NOT_'}REQUIRED`)}
      id={port.id}
      className={`port-input-view${required ? ' required' : ''}`}
      isValidConnection={() => true}
      isConnectable={true}>
      <div className='dot'>
        <JourneyError errors={errors} />
      </div>
    </Handle>
  );
};
