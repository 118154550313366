import { IPreviewSkill, } from 'atlas-shared';

export enum SkillActions {
  SET_STATE = 'SKILL_SET_STATE',
  ADD_STATE = 'SKILL_ADD_STATE',
  REMOVE_STATE = 'SKILL_REMOVE_STATE',
  GET_STATE = 'SKILL_GET_STATE',
  UPDATE_STATE = 'SKILL_UPDATE_STATE',
  LOADING = 'SKILL_LOADING',
}

export interface ISkillLoading {
  type: SkillActions.LOADING;
}

export interface ISkillSetAction {
  type: SkillActions.SET_STATE;
  payload: Array<IPreviewSkill>;
}

export interface ISkillUpdateAction {
  type: SkillActions.UPDATE_STATE;
  payload: IPreviewSkill;
}

export interface ISkillAddAction {
  type: SkillActions.ADD_STATE;
  payload: IPreviewSkill;
}

export interface ISkillRemoveAction {
  type: SkillActions.REMOVE_STATE;
  payload: IPreviewSkill['id'];
}

export interface ISkillStore {
  loaded: boolean;
  loading: boolean;
  skills: Array<IPreviewSkill>;
  dict: Record<IPreviewSkill['id'], IPreviewSkill>;
}

export type TSkillActionTypes = ISkillSetAction | ISkillUpdateAction | ISkillAddAction | ISkillRemoveAction | ISkillLoading;
