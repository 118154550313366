import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchStatsReportPreviewWidgets, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useStatsReportPreviewWidgets = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const stats_report_preview_widgets = useSelector((store: IReducerStore) => store.stats_report_preview_widgets);

  useEffect(() => {
    if (!initiated && autoload && !stats_report_preview_widgets.loaded && !stats_report_preview_widgets.loading) {
      initiated = true;
      dispatch(actionFetchStatsReportPreviewWidgets());
    }
  }, [autoload, stats_report_preview_widgets.loaded, stats_report_preview_widgets.loading, dispatch, ]);

  return stats_report_preview_widgets;
};
