import React, { PropsWithChildren, } from 'react';
import { ScrollView, } from '@Components';
import './sider.scss';

interface IProps {
  onClose?: () => void;
  styles?: React.CSSProperties;
  contentStyles?: React.CSSProperties;
  classNames?: Array<string>;
}

export const Sider = React.memo(({ children, onClose, styles = {}, contentStyles = {}, classNames = [], }: PropsWithChildren<IProps>) => {
  return <div className={['sider', ...classNames, ].join(' ')} style={styles}>
    <div className='background' onClick={() => onClose?.()}></div>
    <div className='content' style={contentStyles}>
      <ScrollView>
        {children}
      </ScrollView>
    </div>
  </div>;
});
