import { ISvgIconProps, } from './interface';
import { SvgIcon, } from './SvgIcon';

export const EditIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
  </SvgIcon>;
};

export const EditWithLineIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M12 20h9"></path>
    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
  </SvgIcon>;
};

export const EditInBoxIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
  </SvgIcon>;
};
