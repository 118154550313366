import { Empty, } from 'antd';
import React from 'react';
import { TFunction, } from 'i18next';

interface IProps {
  t: TFunction;
  reset: () => void;
  hasFilters: boolean;
  hasRows: boolean;
  title?: string;
}

export const ListPageEmpty = ({ t, reset, hasRows, hasFilters, title, }: IProps) => {
  return <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={
      <span>
        {t(!hasRows ? title || 'NO_DATA_CREATED_YET' : 'NO_DATA_MATCHING_YOUR_FILTERS')}
        <br />
        {hasFilters && <span><a onClick={() => reset()}>{t('RESET_FILTERS')}</a></span>}
      </span>
    }
  >
  </Empty>;
};
