import React, { Dispatch, SetStateAction, useMemo, } from 'react';
import {
  IPreviewForm,
  IPreviewList,
  ISharedContact,
  ISharedFullConversation,
} from 'atlas-shared';
import { ScrollView, } from '@Components';
import { useTranslation, } from 'react-i18next';
import './conversation.contact.form.scss';
import { fromJson, } from 'json-joi-converter';
import { AtlasForm, FormElementTypes, FormLayoutTypes, } from 'atlas-form';
import { patchContactByList, } from '@Api';
import { actionUpdateConversationContact, useAppDispatch, } from '@Store';
import { AlertError, clone, } from '@Utils';

interface IProps {
  conversation: ISharedFullConversation;
  editContactVisible: boolean;
  setEditContactVisible: Dispatch<SetStateAction<boolean>>;
  form: IPreviewForm;
  list: IPreviewList;
  initialValues: Record<string, any>;
}

export const ConversationContactForm = React.memo((props: IProps) => {

  const { form, list, conversation, initialValues: _initialValues, } = props;
  const { t, } = useTranslation();

  const initialValues = useMemo(() => _initialValues, [_initialValues, ]);
  const contact: ISharedContact = useMemo(() => conversation.contact, [conversation.contact, ]);
  const dispatch = useAppDispatch();
  const formSchema = useMemo(() => {
    const properties = clone(form.__validation_on_save);

    if (!properties.__contact_emails && properties.__contact_phones)
      properties.__contact_phones.min = 1;

    return fromJson({
      type: 'object',
      properties,
    });
  }, []);

  if (!form || !initialValues)
    return <></>;

  return <ScrollView>
    <div className={'conversation-contact-form'}>
      <h2>
        {form.display_title || t('CONTACT')}
      </h2>
      <AtlasForm
        formLayout={{
          id: 'contact',
          ui_layout: FormLayoutTypes.VERTICAL,
          noPadding: true,
          elements: form.__layout as any,
        }}
        form={formSchema}
        initialValues={initialValues}
        additionalParams={{
          __contact_emails: {
            ui_type: FormElementTypes.LIST,
          },
          __contact_phones: {
            ui_type: FormElementTypes.LIST,
          },
        }}
        onFinish={values => {
          patchContactByList(contact.organization_id, contact.id, list.id, values).then(res => {
            props.setEditContactVisible(false);
            dispatch(actionUpdateConversationContact(res));
          }).catch(err => {
            AlertError(t, {
              title: t('ERROR'),
              content: t(err.replace('Error: ', '')),
            });
          });
        }}
        onCancel={() => {
          props.setEditContactVisible(false);
        }}
      />
    </div>
  </ScrollView>;

});
