import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateInvoicePost, ISharedUpdateInvoicePost, ISharedInvoicePost, IPreviewOrganization, IPreviewInvoice, EnumOptions, InvoicePostAmountTypeEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IInvoicePostFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  invoices: Array<IPreviewInvoice>;
}

export const InvoicePostFormOptions = (invoice_post: ISharedCreateInvoicePost | ISharedInvoicePost, props: IInvoicePostFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.InvoicePost, {})), 'organization_id', false), 'organization'),
  invoice_id: (it: ISharedInvoicePost) => filterRelated<IPreviewInvoice>(it.organization_id || invoice_post.organization_id, 'invoice', 'invoice_post', props.invoices, true),
  amount_type: EnumOptions('INVOICE_POST_AMOUNT_TYPE', InvoicePostAmountTypeEnum, t),
});

export const InvoicePostComponents: IForm['components'] = {};
export const InvoicePostAdditionalParams: (auth: IAuth, props: IInvoicePostFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IInvoicePostFormOptionsProps) => ({
});

export const InvoicePostEditForm = (invoice_post: ISharedInvoicePost, props: IInvoicePostFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `invoice_post_edit_${invoice_post.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === invoice_post.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.invoices.find(it => it.id === invoice_post.invoice_id)?.title, path: 'invoice_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ui_type: FormElementTypes.PREVIEW, preview_value: invoice_post.type, path: 'type', shouldUpdate: () => false, },
    { ref: 'amount_type', },
    { ref: 'amount_currency', },
    { ref: 'amount_percent', },
    { ref: 'exclude', },
  ],
});

export const InvoicePostAddForm = (invoice_post: ISharedCreateInvoicePost, props: IInvoicePostFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'invoice_post_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'invoice_id', },
    { ref: 'title', },
    { ref: 'amount_type', },
    { ref: 'amount_currency', },
    { ref: 'amount_percent', },
    { ref: 'exclude', },
  ],
});

export const InvoicePostAddInitialValues: ISharedCreateInvoicePost = {} as ISharedCreateInvoicePost;

export const InvoicePostEditInitialValues = (invoice_post: ISharedInvoicePost): ISharedUpdateInvoicePost => ({
  title: invoice_post.title,
  amount_type: invoice_post.amount_type,
  amount_currency: invoice_post.amount_currency,
  amount_percent: invoice_post.amount_percent,
  exclude: invoice_post.exclude,
});

export const onInvoicePostFormSaved = (t: TFunction, action: 'edit' | 'add', invoice_post: ISharedInvoicePost, navigate: NavigateFunction): void => {
  navigate(onFormSaved('invoice_posts'));
};
