import { IAuth, } from '@Store';
import { FormElementTypes, FormLayoutTypes, IForm, } from 'atlas-form';
import { ITariffFormOptionsProps, } from '@Utils/forms/abstract/tariff.form.abstract';
import { TariffAdditionalParams as TariffAdditionalParamsBase, } from './abstract/tariff.form.abstract';
import { CountryFlagTitle, } from '@Utils/phone';

export * from './abstract/tariff.form.abstract';

export const TariffAdditionalParams: (auth: IAuth, props: ITariffFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ITariffFormOptionsProps) => ({
  ...TariffAdditionalParamsBase(auth, props),
  voice_tariff_list: {
    ui_type: FormElementTypes.SECTION,
    child_params: {
      ui_type: FormElementTypes.SECTION,
      ui_layout: FormLayoutTypes.INLINE,
      labelRenderer: label => <CountryFlagTitle t={props.t} country={label as string} width={21} />,
      child_params: {
        // hideLabel: true,
        // width: '145px'
      },
    },
  },
  voice_recording: {
    postFixText: '/min',
    postFixTextNull: '',
  },
  voice_recording_transcript: {
    postFixText: '/min',
    postFixTextNull: '',
  },
  voice_recording_storage: {
    postFixText: '/MB',
    postFixTextNull: '',
  },
  attachments_storage: {
    postFixText: '/MB',
    postFixTextNull: '',
  },
  ai_request_kb_assistance: {
    postFixText: '/request',
    postFixTextNull: '',
  },
});
