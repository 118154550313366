import { TFunction, } from 'i18next';
import React from 'react';
import { IPreviewConversation, } from 'atlas-shared';
import { ConversationTags, } from './';
import { ITagStore, } from '@Store';

interface IProps {
  conversation: IPreviewConversation;
  t: TFunction;
  tags: ITagStore;
}

export const ConversationTagsSqueeze = React.memo(({ conversation, t, tags, }: IProps) => {

  return <div className='conversation-tags-squeezed'>
    <ConversationTags conversation={conversation} t={t} tags={tags} />
    <ConversationTags auto={true} conversation={conversation} t={t} tags={tags} />
  </div>;
});
