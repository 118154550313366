import React from 'react';
import './contact_custom_event.view.scss';
import { ISharedContactCustomEvent, } from 'atlas-shared/dist';
import { ChannelsIcon, Icon, ScrollView, } from '@Components';
import { timeSince, } from '@Utils';
import { XIcon, } from '@Assets';
import { useTranslation, } from 'react-i18next';

interface IProps {
  event: ISharedContactCustomEvent;
  onClose: () => void;
}

export const ContactCustomEventView = React.memo(({ event, onClose, }: IProps) => {

  const { t, } = useTranslation();

  return (
    <div className={'contact_custom_event-wrapper'}>
      <div className={'contact_custom_event-header'}>
        <ChannelsIcon channels={[{ c: event.type || 'Unknown', }, ]} size={14} padding={5} max={3} />
        <span className={'title'}>
          {`#${event.display_id}`}
        </span>
        <small className={'date'}>
          {timeSince(t, new Date(event.event_at), true)}
        </small>
        <small className={'status'}>
          {event.status}
        </small>
        <Icon className={'close'} icon={XIcon} iconProps={{ size: 15, }} onClick={onClose}/>
      </div>
      <ScrollView>
        <div dangerouslySetInnerHTML={{ __html: event.body, }} className={'contact_custom_event-body'}></div>
      </ScrollView>
    </div>
  );
});
