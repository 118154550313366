import { IPreviewCannedAnswer, } from 'atlas-shared';
import { ICannedAnswerStore, CannedAnswerActions, TCannedAnswerActionTypes, } from '@Store';

export const CannedAnswerReducer = (state: ICannedAnswerStore = {
  loaded: false,
  loading: false,
  canned_answers: [],
  dict: {},
}, action: TCannedAnswerActionTypes): ICannedAnswerStore => {
  switch (action.type) {
  case CannedAnswerActions.LOADING:
    return { ...state, loading: true, };
  case CannedAnswerActions.SET_STATE:
    const dict: ICannedAnswerStore['dict'] = {};

    action.payload.forEach((canned_answer: IPreviewCannedAnswer) => dict[canned_answer.id] = canned_answer);

    return { ...state, loaded: true, loading: false, canned_answers: action.payload, dict, };
  case CannedAnswerActions.ADD_STATE:
    return { ...state, canned_answers: [...state.canned_answers, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case CannedAnswerActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, canned_answers: state.canned_answers.filter((item: IPreviewCannedAnswer) => item.id !== action.payload), dict, };
  }
  case CannedAnswerActions.UPDATE_STATE:
    const canned_answers = [...state.canned_answers, ];
    const index = state.canned_answers.findIndex((canned_answer: IPreviewCannedAnswer) => canned_answer.id === action.payload.id);

    if (!~index)
      canned_answers.push(action.payload);
    else
      canned_answers[index] = action.payload;

    return { ...state, canned_answers, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
