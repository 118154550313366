import React from 'react';
import './tab.mini.conversation.message.scss';
import {
  Avatar,
  ConversationMessageMailMini,
  ConversationMessageInternalNoteMini,
  ConversationMessageFormSubmissionMini,
  ConversationMessageVoiceCallMini,
} from '@Components';
import {
  ChannelEnum,
  DirectionEnum,
  ISharedFullConversation, 
  ISharedInternalNote,
  ISharedMailMessage,
  TypeConversationMessage,
  ISharedFormSubmission,
  TConversationVoiceCall,
  IPreviewTab,
} from 'atlas-shared';
import { IAuth, IUserStore, } from '@Store';
import { TFunction, } from 'i18next';
import { THighlightedMessage, } from '@Hooks';

interface IProps {
  t: TFunction;
  auth: IAuth;
  message: TypeConversationMessage;
  conversation: ISharedFullConversation;
  users: IUserStore;
  highlightedMessage: THighlightedMessage;
  tab?: IPreviewTab;
}

export const TabMiniConversationMessage = React.memo(({ message, users, conversation, auth, t, highlightedMessage, tab, }: IProps) => {
  const user_id = (message as ISharedMailMessage)?.user_id;

  return <div className={[
    (message as ISharedMailMessage).direction === DirectionEnum.InBound ? 'row-left' : 'row-right',
    'conversation-message-row',
  ].filter(Boolean).join(' ')}
  id={`conversation-message-${highlightedMessage[4]}-${message.channel}-${message.id}`}>
    {
      (message as ISharedMailMessage).direction !== DirectionEnum.Outbound && !user_id ? <Avatar size={20} style={{ background: 'var(--grey-middle)', color: 'var(--blue-grey)', }} /> : (user_id ? <Avatar size={20} user={users.dict[user_id]} /> : <></>)
    }
    {message.channel === ChannelEnum.Mail && <ConversationMessageMailMini t={t} message={message as ISharedMailMessage} conversation={conversation} highlightedMessage={highlightedMessage} />}
    {message.channel === ChannelEnum.Form && <ConversationMessageFormSubmissionMini t={t} message={message as ISharedFormSubmission} />}
    {message.channel === ChannelEnum.Voice && <ConversationMessageVoiceCallMini conversation={conversation} message={message as TConversationVoiceCall} auth={auth} highlightedMessage={highlightedMessage} /> }
    {message.channel === ChannelEnum.InternalNote && <ConversationMessageInternalNoteMini message={message as ISharedInternalNote} t={t} conversation={conversation} highlightedMessage={highlightedMessage} tab={tab} />}
  </div>;
});
