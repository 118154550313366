import * as React from 'react';
import { Form, Switch, Tooltip, Typography, } from 'antd';
import {
  FormElementTypes, IFormElement,
} from '../atlas-form-core';
import { buildFormErrorKey, } from './errors';
import './styles.scss';
import { FormItemClassNames, getFormValueByElementType, IFormItemsProps, } from './utils';
import { Icon, } from '@Components';
import { HelpCircleIcon, } from '@Assets/icons';
import { isValueEmpty, } from '@Utils';

const { Text, } = Typography;

const _FormItem: React.FC<IFormItemsProps> = (props: IFormItemsProps) => {

  const {
    t,
    label,
    params,
    errors,
    display_errors,
    options,
    path,
    name,
    form_path,
    value,
    isTypeahead,
    nullable,
    hidden,
    help,
    hideHelp,
    row_style,
    labelWidth,
    required,
    ui_type,
    setValueByPath,
    preFixText,
    preFixTextNull,
    postFixText,
    postFixTextNull,
    element,
    default: _default,
    default_un_null,
    type,
    displayAdvanced,
    advanced,
    disabled = false,
    hasAdd,
  } = props;

  const NullableFormItem: React.FC<{onChange: Function; value: any; default?: any; default_un_null?: any; type: IFormElement['type']; disabled: IFormElement['disabled']}> = ({ value, onChange, default: _default, default_un_null, type, disabled = false, }) => {

    const getDefault = () => {

      if (default_un_null !== undefined)
        return default_un_null;

      if (_default !== undefined && _default !== null)
        return _default;

      if (type === 'array')
        return [];

      if (options?.length)
        return options[0].key;

      return getFormValueByElementType(type);
    };

    return <Tooltip placement="right" title={t(value === null ? 'FORM_NULLABLE_ENABLE' : 'FORM_NULLABLE_DISABLE')}>
      <Switch
        className={'forms-switch'}
        defaultChecked={value !== null}
        disabled={disabled}
        onChange={checked => {
          onChange(checked ? getDefault() : null);
        }}
      />
    </Tooltip>;
  };

  const isLabelString = typeof label === 'string';
  const _label = isLabelString ? t(label) : label;

  if (hidden)
    return <></>;

  return (
    <Form.Item
      key={form_path}
      label={params.hideLabel !== true && (label || label === '') ? <>
        <Text title={isLabelString ? _label as string : ''} className='text' style={{ ...(labelWidth && { width: labelWidth, }), }}>
          <span style={{ display: 'flex', }}>
            {_label}
            <span style={{ paddingLeft: 5, display: 'flex', }}>{help && <Icon icon={HelpCircleIcon} wrapperStyle={{ cursor: 'help', }} tooltip={{ title: help, }} />}</span>
          </span>
        </Text>
        <div className={`after${required ? ' required' : ''}`}>*</div>
      </> : ''}
      hidden={(!displayAdvanced && advanced && isValueEmpty(value)) || hidden}
      className={`form-item ${FormItemClassNames([...path, ...name, ])}${nullable ? ' form-nullable' : ''}${hasAdd ? ' form-has-add' : ''}`}
      /*className={(props.form.ui_type === FormElementTypes.CHECKBOX || props.form.ui_type === FormElementTypes.SWITCH ? 'form-item-horizontal' : 'form-item-vertical') + ' ' + (props.form.nullable ? 'form-nullable' : '')}*/
      /*required={props.form.required}*/
      validateStatus={display_errors && errors?.length ? 'error' : 'success'}
      help={display_errors && errors.length ? errors.map((e) => {
        return buildFormErrorKey(e, t, options) + (value && isTypeahead && value[e.path[e.path.length - 1]] ? `: (${value[e.path[e.path.length - 1]]})` : '');
      }).join(', ') : (!hideHelp && help ? help : undefined) }
      style={row_style}
    >
      {
        nullable && <Form.Item
          key={`${form_path}_nullable`}
          className={'form-item'}
          noStyle
        >

          <NullableFormItem value={value} onChange={value => setValueByPath(form_path, value)} default={_default} default_un_null={default_un_null} type={type} disabled={disabled} />
        </Form.Item>
      }
      <Form.Item
        key={`${form_path}_elm`}
        valuePropName={ui_type && [FormElementTypes.CHECKBOX, ].includes(ui_type) ? 'checked' : undefined}
        noStyle
      >
        {nullable && value === null && preFixTextNull && <span className="ant-form-text">{t(preFixTextNull)}</span>}
        {
          (!nullable || value !== null) && <>
            {preFixText && <span className="ant-form-text prefix">{t(preFixText)}</span>}
            {element}
            {postFixText && <span className="ant-form-text postfix">{t(postFixText)}</span>}
          </>
        }
        {nullable && value === null && postFixTextNull && <span className="ant-form-text">{t(postFixTextNull)}</span>}
      </Form.Item>
    </Form.Item>
  );
};

export const FormItem = React.memo(_FormItem);
