import { RestRequest, } from '@Utils';
import { IPreviewVoiceChanspy, ISharedVoiceChanspy, } from 'atlas-shared';

export const fetchVoiceChanspies = async (): Promise<Array<IPreviewVoiceChanspy>> => {
  return RestRequest.get<Array<IPreviewVoiceChanspy>>('voice_chanspy', { socket_id: global.socketId, });
};

export const fetchVoiceChanspy = async (id: IPreviewVoiceChanspy['id']): Promise<ISharedVoiceChanspy> => {
  return RestRequest.get<ISharedVoiceChanspy>(`voice_chanspy/${id}`, { socket_id: global.socketId, });
};
