import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchSignatures, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useSignatures = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const signatures = useSelector((store: IReducerStore) => store.signatures);

  useEffect(() => {
    if (!initiated && autoload && !signatures.loaded && !signatures.loading) {
      initiated = true;
      dispatch(actionFetchSignatures());
    }
  }, [autoload, signatures.loaded, signatures.loading, dispatch, ]);

  return signatures;
};
