import { IPreviewVoiceCall, } from 'atlas-shared';
import { IVoiceCallStore, VoiceCallActions, TVoiceCallActionTypes, } from '../types/abstract/voice_call.types.abstract';
import { VoiceCallReducer as VoiceCallReducerBase, } from './abstract/voice_call.reducer.abstract';

export const VoiceCallReducer = (state: IVoiceCallStore = {
  loaded: false,
  loading: false,
  voice_calls: [],
  dict: {},
}, action: TVoiceCallActionTypes) => {
  switch (action.type) {
  case VoiceCallActions.ADD_STATE:
  case VoiceCallActions.UPDATE_STATE:
    const voice_calls = [...state.voice_calls, ];
    const index = state.voice_calls.findIndex((voice_call: IPreviewVoiceCall) => voice_call.id === action.payload.id);

    if (!~index)
      voice_calls.push(action.payload);
    else
      voice_calls[index] = action.payload;

    return { ...state, voice_calls: voice_calls.sort((a, b) => a.created_at > b.created_at ? -1 : 1), dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return VoiceCallReducerBase(state, action);
  }
};
