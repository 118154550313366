import { IPreviewPhonenumberSetupCost, } from 'atlas-shared';

export enum PhonenumberSetupCostActions {
  SET_STATE = 'PHONENUMBER_SETUP_COST_SET_STATE',
  ADD_STATE = 'PHONENUMBER_SETUP_COST_ADD_STATE',
  REMOVE_STATE = 'PHONENUMBER_SETUP_COST_REMOVE_STATE',
  GET_STATE = 'PHONENUMBER_SETUP_COST_GET_STATE',
  UPDATE_STATE = 'PHONENUMBER_SETUP_COST_UPDATE_STATE',
  LOADING = 'PHONENUMBER_SETUP_COST_LOADING',
}

export interface IPhonenumberSetupCostLoading {
  type: PhonenumberSetupCostActions.LOADING;
}

export interface IPhonenumberSetupCostSetAction {
  type: PhonenumberSetupCostActions.SET_STATE;
  payload: Array<IPreviewPhonenumberSetupCost>;
}

export interface IPhonenumberSetupCostUpdateAction {
  type: PhonenumberSetupCostActions.UPDATE_STATE;
  payload: IPreviewPhonenumberSetupCost;
}

export interface IPhonenumberSetupCostAddAction {
  type: PhonenumberSetupCostActions.ADD_STATE;
  payload: IPreviewPhonenumberSetupCost;
}

export interface IPhonenumberSetupCostRemoveAction {
  type: PhonenumberSetupCostActions.REMOVE_STATE;
  payload: IPreviewPhonenumberSetupCost['id'];
}

export interface IPhonenumberSetupCostStore {
  loaded: boolean;
  loading: boolean;
  phonenumber_setup_costs: Array<IPreviewPhonenumberSetupCost>;
  dict: Record<IPreviewPhonenumberSetupCost['id'], IPreviewPhonenumberSetupCost>;
}

export type TPhonenumberSetupCostActionTypes = IPhonenumberSetupCostSetAction | IPhonenumberSetupCostUpdateAction | IPhonenumberSetupCostAddAction | IPhonenumberSetupCostRemoveAction | IPhonenumberSetupCostLoading;
