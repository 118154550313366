import { IPreviewAdminprofile, } from 'atlas-shared';

export enum AdminprofileActions {
  SET_STATE = 'ADMINPROFILE_SET_STATE',
  ADD_STATE = 'ADMINPROFILE_ADD_STATE',
  REMOVE_STATE = 'ADMINPROFILE_REMOVE_STATE',
  GET_STATE = 'ADMINPROFILE_GET_STATE',
  UPDATE_STATE = 'ADMINPROFILE_UPDATE_STATE',
  LOADING = 'ADMINPROFILE_LOADING',
}

export interface IAdminprofileLoading {
  type: AdminprofileActions.LOADING;
}

export interface IAdminprofileSetAction {
  type: AdminprofileActions.SET_STATE;
  payload: Array<IPreviewAdminprofile>;
}

export interface IAdminprofileUpdateAction {
  type: AdminprofileActions.UPDATE_STATE;
  payload: IPreviewAdminprofile;
}

export interface IAdminprofileAddAction {
  type: AdminprofileActions.ADD_STATE;
  payload: IPreviewAdminprofile;
}

export interface IAdminprofileRemoveAction {
  type: AdminprofileActions.REMOVE_STATE;
  payload: IPreviewAdminprofile['id'];
}

export interface IAdminprofileStore {
  loaded: boolean;
  loading: boolean;
  adminprofiles: Array<IPreviewAdminprofile>;
  dict: Record<IPreviewAdminprofile['id'], IPreviewAdminprofile>;
}

export type TAdminprofileActionTypes = IAdminprofileSetAction | IAdminprofileUpdateAction | IAdminprofileAddAction | IAdminprofileRemoveAction | IAdminprofileLoading;
