import { useSelector, } from 'react-redux';
import { useAuth, } from './use.auth';
import { INotificationUserStore, actionFetchNotificationUsers, useAppDispatch, } from '@Store';
import { useEffect, useState, } from 'react';
import { IPreviewUserStatus, } from 'atlas-shared';

export const useNotificationUsers = (autoload = true) => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const notification_users = useSelector((s: any) => s.notification_users) as INotificationUserStore;
  const [agentprofileId, setAgentprofileId, ] = useState<IPreviewUserStatus['agentprofile_id']>(auth.user_status.agentprofile_id);

  useEffect(() => {
    autoload && !notification_users.loaded && !notification_users.loading && dispatch(actionFetchNotificationUsers(auth.user_status.agentprofile_id));
  }, [autoload, auth.user_status.agentprofile_id, notification_users.loaded, notification_users.loading, agentprofileId, dispatch, ]);
  
  useEffect(() => {
    if (autoload && notification_users.loaded && !notification_users.loading && auth.user_status.agentprofile_id !== agentprofileId) {
      dispatch(actionFetchNotificationUsers(auth.user_status.agentprofile_id));
      setAgentprofileId(auth.user_status.agentprofile_id);
    }
  }, [autoload, auth.user_status.agentprofile_id, notification_users.loaded, notification_users.loading, agentprofileId, dispatch, ]);

  return notification_users;
};
