import { Nullable, } from 'atlas-shared';

export const hexToRgb = (hex: string): Nullable<{r: number; g: number; b: number}> => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};
