import React, { useEffect, useState, } from 'react';
import './notifications.scss';
import { useAuth, useNotificationUsers, } from '@Hooks';
import { NotificationSubjectEnum, IPreviewNotificationUser, ISharedNotificationUser, } from 'atlas-shared';
import { Notification, } from './notification';

export const Notifications = React.memo(() => {
  const notification_users = useNotificationUsers(false);
  const [notificationUsers, setNotificationUsers, ] = useState<Array<ISharedNotificationUser>>([]);
  const auth = useAuth();

  useEffect(() => {
    setNotificationUsers(notification_users.notification_users.filter(nu => (
      nu.notification?.subject
      && (
        [NotificationSubjectEnum.NewAssignment, NotificationSubjectEnum.NewInternal, NotificationSubjectEnum.SysError, NotificationSubjectEnum.PasswordLifetimeExpiryClose, ].includes(nu.notification.subject)
        || (nu.notification.subject === NotificationSubjectEnum.StatsAlarm && nu.notification.payload?.h)
      )
      && !nu.deleted_at
      && !nu.action_at
    )) as Array<ISharedNotificationUser>);
  }, [notification_users.notification_users, ]);

  return (
    <div className={'notifications'} >
      <div className={'body'} >
        {
          notificationUsers.map((notification_user: IPreviewNotificationUser) => <Notification header={false} auth={auth} key={notification_user.id} notification_user={notification_user as ISharedNotificationUser} />)
        }
      </div>
    </div>
  );
});
