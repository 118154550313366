import React, { useEffect, useState, } from 'react';
import { voiceCallsTimeline, } from '@Api';
import {
  ISharedOrganization,
  ISharedVoiceCall,
  TConversationVoiceCall,
  IConversationTimeline,
} from 'atlas-shared';
import { useTranslation, } from 'react-i18next';
import { Timeline, ITimelineEvent, translateConversationTimelineEvent, } from '@Components/timeline';
import { useDispositions, useJourneyTriggers, useQueues, useUsers, useVoiceRoutes, } from '@Hooks';

interface IProps {
  organization_id: ISharedOrganization['id'];
  voice_call_id: ISharedVoiceCall['id'];
  voice_call: TConversationVoiceCall;
}

export const VoiceCallTimeline = React.memo(({ organization_id, voice_call_id, voice_call, }: IProps) => {

  const { t, } = useTranslation();
  const users = useUsers();
  const queues = useQueues();
  const voice_routes = useVoiceRoutes();
  const dispositions = useDispositions();
  const journey_triggers = useJourneyTriggers();

  const [events, setEvents, ] = useState<Array<{translated: ITimelineEvent} & IConversationTimeline>>([]);
  const [error, setError, ] = useState<string>();

  useEffect(() => {
    voiceCallsTimeline(organization_id, voice_call.conversation_id, voice_call_id)
      .then(data => {
        setEvents(data.events.map(event => ({
          ...event,
          dt: new Date(event.dt),
          translated: translateConversationTimelineEvent(
            t,
            event,
            {
              users,
              queues,
              voice_routes,
              dispositions,
              journey_triggers,
            },
            undefined,
            voice_call
          ),
        })));
      })
      .catch(err => {
        setError(err || 'UNAUTHORIZED');
      });

  }, []);

  if (error)
    return <div className='voice-call-timeline error'><span style={{ opacity: .5, }}>{t('VOICE_TIMELINE_ERROR')}:</span> {error}</div>;

  return <Timeline events={events} />;
});
