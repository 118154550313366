import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchTariffs, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useTariffs = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const tariffs = useSelector((store: IReducerStore) => store.tariffs);

  useEffect(() => {
    if (!initiated && autoload && !tariffs.loaded && !tariffs.loading) {
      initiated = true;
      dispatch(actionFetchTariffs());
    }
  }, [autoload, tariffs.loaded, tariffs.loading, dispatch, ]);

  return tariffs;
};
