import { TabActions, } from '@Store';
import { IPreviewTab, } from 'atlas-shared';
import { fetchTabs, } from '@Api';

export const actionFetchTabs = () => {
  return async (dispatch) => {
    dispatch(actionLoadingTabs());
    const tabs = await fetchTabs();

    dispatch(actionSetTabs(tabs));
    return tabs;
  };
};

export const actionLoadingTabs = () => ({
  type: TabActions.LOADING,
  payload: [],
});

export const actionSetTabs = (payload: Array<IPreviewTab>) => ({
  type: TabActions.SET_STATE,
  payload: payload,
});

export const actionAddTab = (payload: IPreviewTab) => ({
  type: TabActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateTab = (payload: IPreviewTab) => ({
  type: TabActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveTab = (id: IPreviewTab['id']) => ({
  type: TabActions.REMOVE_STATE,
  payload: id,
});
