import React from 'react';
import { getStatsWidgetMetricDataFormatted, getStatsWidgetMetricTitle, } from '@Utils';
import { TooltipProps, } from 'recharts';
import { TFunction, } from 'i18next';
import { IPreviewOrganization, IPreviewStatsWidgetSettingsMetricProperty, } from 'atlas-shared/dist';
import { IStatsCustomMetricStore, } from '@Store';
import { WIDGET_COLOR_PALETTE, } from '@Components/stats/widget-types/widget.color.palette';

interface IProps extends TooltipProps<number, string> {
  t: TFunction;
  metrics: Array<IPreviewStatsWidgetSettingsMetricProperty>;
  organization: IPreviewOrganization;
  stats_custom_metrics: IStatsCustomMetricStore;
  group_based?: boolean;
}

export const WidgetChartTooltip = React.memo((props: IProps) => {
  return <div className={'recharts-tooltip'}>
    <strong className='label'>{props.label}</strong>
    <ul>
      {(props.payload || []).map((entry, i) => {
        
        if (props.group_based) {
          const is_trend = (entry.dataKey + '').endsWith('_trend');
          const [metric, ] = props.metrics;
          const { elm, } = getStatsWidgetMetricTitle(props.t, metric, props.stats_custom_metrics.stats_custom_metrics, is_trend, entry.name);

          return <li className={`recharts-tooltip-item tooltip-item-${i}`} key={`item-${i}`}>
            <div className='recharts-legend-item-color' style={{
              backgroundColor: (entry as any).payload.fill,
              borderColor: (entry as any).payload.stroke,
              borderWidth: (entry as any).payload.strokeWidth || 0,
              opacity: (entry as any).payload.opacity || 1,
            }}></div>
            <div className='recharts-tooltip-item-title'>{elm}</div>
            <div className='recharts-tooltip-item-value'>{getStatsWidgetMetricDataFormatted(props.t, metric, entry.value, props.organization)}</div>
          </li>;
        }

        const is_trend = (entry.dataKey + '').endsWith('_trend');
        const metric_value = (entry.name + '').replace('_trend', '');
        const metric_index = props.metrics.findIndex(m => m.value === metric_value);

        if (!~metric_index)
          return <></>;

        const metric = props.metrics[metric_index];
        const { elm, } = getStatsWidgetMetricTitle(props.t, metric, props.stats_custom_metrics.stats_custom_metrics, is_trend);
        const backgroundColor = (entry as any).fill || (entry as any).payload.fill;
        const borderColor = (entry as any).stroke || (entry as any).payload.stroke;

        return <li className={`recharts-tooltip-item tooltip-item-${i}`} key={`item-${i}`}>
          <div className='recharts-legend-item-color' style={{
            backgroundColor: backgroundColor.startsWith('url(') ? metric.bg_color || 'transparent' : backgroundColor,
            borderColor: borderColor.startsWith('url(') ? metric.border?.color || WIDGET_COLOR_PALETTE[metric_index] : borderColor,
            borderWidth: (entry as any).strokeWidth || (entry as any).payload.strokeWidth || 0,
            opacity: (entry as any).opacity || (entry as any).payload.opacity || 1,
          }}></div>
          <div className='recharts-tooltip-item-title'>{elm}</div>
          <div className='recharts-tooltip-item-value'>{getStatsWidgetMetricDataFormatted(props.t, metric, entry.value, props.organization)}</div>
        </li>;
      })}
    </ul>
  </div>;
}, (prevProps, nextProps) => JSON.stringify(prevProps.payload) === JSON.stringify(nextProps.payload)); 
