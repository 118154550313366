import { IPreviewSound, } from 'atlas-shared';
import { ISoundStore, SoundActions, TSoundActionTypes, } from '@Store';

export const SoundReducer = (state: ISoundStore = {
  loaded: false,
  loading: false,
  sounds: [],
  dict: {},
}, action: TSoundActionTypes): ISoundStore => {
  switch (action.type) {
  case SoundActions.LOADING:
    return { ...state, loading: true, };
  case SoundActions.SET_STATE:
    const dict: ISoundStore['dict'] = {};

    action.payload.forEach((sound: IPreviewSound) => dict[sound.id] = sound);

    return { ...state, loaded: true, loading: false, sounds: action.payload, dict, };
  case SoundActions.ADD_STATE:
    return { ...state, sounds: [...state.sounds, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case SoundActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, sounds: state.sounds.filter((item: IPreviewSound) => item.id !== action.payload), dict, };
  }
  case SoundActions.UPDATE_STATE:
    const sounds = [...state.sounds, ];
    const index = state.sounds.findIndex((sound: IPreviewSound) => sound.id === action.payload.id);

    if (!~index)
      sounds.push(action.payload);
    else
      sounds[index] = action.payload;

    return { ...state, sounds, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
