import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateMailAccount, ISharedUpdateMailAccount, ISharedMailAccount, IPreviewOrganization, IPreviewJourney, EnumOptions, MailAccountAuthStrategy, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IMailAccountFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  journeies: Array<IPreviewJourney>;
}

export const MailAccountFormOptions = (mail_account: ISharedCreateMailAccount | ISharedMailAccount, props: IMailAccountFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.MailAccount, {})), 'organization_id', false), 'organization'),
  inbound_journey_id: (it: ISharedMailAccount) => filterRelated<IPreviewJourney>(it.organization_id || mail_account.organization_id, 'journey', 'mail_account', props.journeies, true),
  outbound_journey_id: (it: ISharedMailAccount) => filterRelated<IPreviewJourney>(it.organization_id || mail_account.organization_id, 'journey', 'mail_account', props.journeies, true),
  imap_strategy: EnumOptions('MAIL_ACCOUNT_AUTH_STRATEGY', MailAccountAuthStrategy, t),
  smtp_strategy: EnumOptions('MAIL_ACCOUNT_AUTH_STRATEGY', MailAccountAuthStrategy, t),
});

export const MailAccountComponents: IForm['components'] = {};
export const MailAccountAdditionalParams: (auth: IAuth, props: IMailAccountFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IMailAccountFormOptionsProps) => ({
});

export const MailAccountEditForm = (mail_account: ISharedMailAccount, props: IMailAccountFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `mail_account_edit_${mail_account.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === mail_account.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'address', },
    { ref: 'inbound_journey_id', },
    { ref: 'outbound_journey_id', },
    { ref: 'active', },
    { ref: 'imap', },
    { ref: 'smtp', },
  ],
});

export const MailAccountAddForm = (mail_account: ISharedCreateMailAccount, props: IMailAccountFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'mail_account_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'address', },
    { ref: 'inbound_journey_id', },
    { ref: 'outbound_journey_id', },
    { ref: 'active', },
    { ref: 'imap', },
    { ref: 'smtp', },
  ],
});

export const MailAccountAddInitialValues: ISharedCreateMailAccount = {} as ISharedCreateMailAccount;

export const MailAccountEditInitialValues = (mail_account: ISharedMailAccount): ISharedUpdateMailAccount => ({
  title: mail_account.title,
  address: mail_account.address,
  inbound_journey_id: mail_account.inbound_journey_id,
  outbound_journey_id: mail_account.outbound_journey_id,
  active: mail_account.active,
  imap: mail_account.imap,
  smtp: mail_account.smtp,
});

export const onMailAccountFormSaved = (t: TFunction, action: 'edit' | 'add', mail_account: ISharedMailAccount, navigate: NavigateFunction): void => {
  navigate(onFormSaved('mail_accounts'));
};
