import React from 'react';
import './channel.icon.scss';

import { ChannelIcon, IConversationChannelInterface, } from './channel.icon';
import { useTranslation, } from 'react-i18next';

interface IProps {
  channels: Array<IConversationChannelInterface>;
  size: number;
  padding?: number;
  max?: number;
  width?: number;
}

export const ChannelsIcon = React.memo(({ size, channels, padding, max, width, }: IProps) => {
  
  const { t, } = useTranslation();

  if (!max)
    max = 2;

  const ln = channels.length;

  if (!padding)
    padding = (size / 2);

  const fullSize = (padding * 2) + size;
  const halfSize = fullSize / 2;

  max = Math.min(max, ln);

  if (!width)
    width = (max * halfSize) + halfSize;

  const left = (width - fullSize) / (max - 1);

  return (
    <div style={{ position: 'relative', height: fullSize, width, }} title={channels.map(channel => t(channel.c ? `CHANNEL_${channel.c}` : `DIRECTION_${channel.d}`)).join(', ')}>
      {
        (max > 0 && max <= ln ? (channels.slice(0, ln > max ? max - 1 : max)) : channels).map((channel_icon, index) => <div style={{ left: index ? index * left : 0, zIndex: index, }} key={index} className={index > 0 ? 'channel-icon-secondary' : `channel-icon-secondary ${ln > 1 ? 'channel-icon-bordered' : ''}`}>
          <ChannelIcon channel={channel_icon} size={size} padding={padding} />
        </div>)
      }
      {
        max > 0 && ln > max && <div key={'additional'} className={'channel-icon-secondary-additional'} style={{ left: (max - 1) * left, zIndex: max, }}>
          <figure style={{ width: size, height: size, padding: padding, }}>+{channels.length + 1 - max}</figure>
        </div>
      }
    </div>
  );
});
