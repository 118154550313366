import { IPreviewVoiceChanspy, } from 'atlas-shared';
import { IVoiceChanspyStore, VoiceChanspyActions, TVoiceChanspyActionTypes, } from '@Store';

export const VoiceChanspyReducer = (state: IVoiceChanspyStore = {
  loaded: false,
  loading: false,
  voice_chanspies: [],
  dict: {},
}, action: TVoiceChanspyActionTypes): IVoiceChanspyStore => {
  switch (action.type) {
  case VoiceChanspyActions.LOADING:
    return { ...state, loading: true, };
  case VoiceChanspyActions.SET_STATE:
    const dict: IVoiceChanspyStore['dict'] = {};

    action.payload.forEach((voice_chanspy: IPreviewVoiceChanspy) => dict[voice_chanspy.id] = voice_chanspy);

    return { ...state, loaded: true, loading: false, voice_chanspies: action.payload, dict, };
  case VoiceChanspyActions.ADD_STATE:
    return { ...state, voice_chanspies: [...state.voice_chanspies, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case VoiceChanspyActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_chanspies: state.voice_chanspies.filter((item: IPreviewVoiceChanspy) => item.id !== action.payload), dict, };
  }
  case VoiceChanspyActions.UPDATE_STATE:
    const voice_chanspies = [...state.voice_chanspies, ];
    const index = state.voice_chanspies.findIndex((voice_chanspy: IPreviewVoiceChanspy) => voice_chanspy.id === action.payload.id);

    if (!~index)
      voice_chanspies.push(action.payload);
    else
      voice_chanspies[index] = action.payload;

    return { ...state, voice_chanspies, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
