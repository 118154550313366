import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchWallboards, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useWallboards = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const wallboards = useSelector((store: IReducerStore) => store.wallboards);

  useEffect(() => {
    if (!initiated && autoload && !wallboards.loaded && !wallboards.loading) {
      initiated = true;
      dispatch(actionFetchWallboards());
    }
  }, [autoload, wallboards.loaded, wallboards.loading, dispatch, ]);

  return wallboards;
};
