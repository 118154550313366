import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  QueueAddForm,
  QueueAddInitialValues,
  QueueComponents,
  QueueAdditionalParams,
  QueueFormOptions,
  onQueueFormSaved,
  IQueueFormOptionsProps,
  AlertError,
  AudioPlayer,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { ISharedQueue, CrudEnum, ISharedCreateQueue, PermissionEnum, } from 'atlas-shared';
import { createQueue, fetchQueue, getQueueCreateSchema, streamSoundFilename, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useLists, useClients, useForms, useSlaPolicies, useAiAssistants, useSounds, useDispositions, useCannedAnswers, useTags, useSignatures, useMailAccounts, useVoiceRoutes, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateQueue> {}

const QueueFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const lists = useLists();
  const clients = useClients();
  const forms = useForms();
  const sla_policies = useSlaPolicies();
  const ai_assistants = useAiAssistants();
  const sounds = useSounds();
  const dispositions = useDispositions();
  const canned_answers = useCannedAnswers();
  const tags = useTags();
  const signatures = useSignatures();
  const mail_accounts = useMailAccounts();
  const voice_routes = useVoiceRoutes();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateQueue>>();

  const param_props: IQueueFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    lists: lists.lists,
    clients: clients.clients,
    forms: forms.forms,
    sla_policies: sla_policies.sla_policies,
    ai_assistants: ai_assistants.ai_assistants,
    sounds: sounds.sounds,
    dispositions: dispositions.dispositions,
    canned_answers: canned_answers.canned_answers,
    tags: tags.tags,
    signatures: signatures.signatures,
    mail_accounts: mail_accounts.mail_accounts,
    voice_routes: voice_routes.voice_routes,
  };
  const formLayout = useMemo(() => QueueAddForm(values, param_props, t), [values, organizations, lists, clients, forms, sla_policies, ai_assistants, sounds, dispositions, canned_answers, tags, signatures, mail_accounts, voice_routes, t, abilities, ]);
  const options: IFormOptions = useMemo(() => QueueFormOptions(values || QueueAddInitialValues, param_props, t), [values, organizations, lists, clients, forms, sla_policies, ai_assistants, sounds, dispositions, canned_answers, tags, signatures, mail_accounts, voice_routes, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateQueue, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateQueue) => save(() => createQueue(values).then(
    res => onSaved ? onSaved(res, navigate) : onQueueFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchQueue(+id)
        .then(({
          organization_id,
          title,
          create_list_id,
          read_lists,
          clients,
          conversation_form_id,
          inbound_sla_policy_id,
          outbound_sla_policy_id,
          settings,
          mail_settings,
          form_settings,
          voice_settings,
          __dispositions,
          __canned_answers,
          __tags,
          auto_tags,
          __agent_signatures,
          __outbound_mail_accounts,
          __outbound_voice_routes,
        }) => {
          setCloned({
            organization_id,
            title,
            create_list_id,
            read_lists,
            clients,
            conversation_form_id,
            inbound_sla_policy_id,
            outbound_sla_policy_id,
            settings,
            mail_settings,
            form_settings,
            voice_settings,
            __dispositions,
            __canned_answers,
            __tags,
            auto_tags,
            __agent_signatures,
            __outbound_mail_accounts,
            __outbound_voice_routes,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !lists.loaded || !clients.loaded || !forms.loaded || !sla_policies.loaded || !ai_assistants.loaded || !sounds.loaded || !dispositions.loaded || !canned_answers.loaded || !tags.loaded || !signatures.loaded || !mail_accounts.loaded || !voice_routes.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.Queue, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={QueueComponents}
        additionalParams={QueueAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...QueueAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('QUEUE')}`}
        hasAdvanced={hasAdvanced}
        previews={{
          voice_settings_announce: (it: ISharedQueue) => it.voice_settings?.announce ? <AudioPlayer key={it.voice_settings?.announce} t={t} stream={() => streamSoundFilename(it.voice_settings?.announce as any)} size='s' /> : <></>,
          voice_settings_periodic_announce: (it: ISharedQueue) => <>{it.voice_settings?.periodic_announce?.map(item => <AudioPlayer key={item} t={t} stream={() => streamSoundFilename(item)} size='s' />)}</>,
        }}
      />
    </RuleProtected>
  );
};

export const QueueFormAddView = React.memo(withAdd(QueueFormAdd, getQueueCreateSchema, QueueAddInitialValues));
