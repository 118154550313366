import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchLists, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useLists = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const lists = useSelector((store: IReducerStore) => store.lists);

  useEffect(() => {
    if (!initiated && autoload && !lists.loaded && !lists.loading) {
      initiated = true;
      dispatch(actionFetchLists());
    }
  }, [autoload, lists.loaded, lists.loading, dispatch, ]);

  return lists;
};
