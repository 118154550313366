import { WallboardActions, } from '@Store';
import { IPreviewWallboard, } from 'atlas-shared';
import { fetchWallboards, } from '@Api';

export const actionFetchWallboards = () => {
  return async (dispatch) => {
    dispatch(actionLoadingWallboards());
    const wallboards = await fetchWallboards();

    dispatch(actionSetWallboards(wallboards));
    return wallboards;
  };
};

export const actionLoadingWallboards = () => ({
  type: WallboardActions.LOADING,
  payload: [],
});

export const actionSetWallboards = (payload: Array<IPreviewWallboard>) => ({
  type: WallboardActions.SET_STATE,
  payload: payload,
});

export const actionAddWallboard = (payload: IPreviewWallboard) => ({
  type: WallboardActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateWallboard = (payload: IPreviewWallboard) => ({
  type: WallboardActions.UPDATE_STATE,
  payload: payload,
});
