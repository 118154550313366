import { IPreviewInvoice, } from 'atlas-shared';

export enum InvoiceActions {
  SET_STATE = 'INVOICE_SET_STATE',
  ADD_STATE = 'INVOICE_ADD_STATE',
  REMOVE_STATE = 'INVOICE_REMOVE_STATE',
  GET_STATE = 'INVOICE_GET_STATE',
  UPDATE_STATE = 'INVOICE_UPDATE_STATE',
  LOADING = 'INVOICE_LOADING',
}

export interface IInvoiceLoading {
  type: InvoiceActions.LOADING;
}

export interface IInvoiceSetAction {
  type: InvoiceActions.SET_STATE;
  payload: Array<IPreviewInvoice>;
}

export interface IInvoiceUpdateAction {
  type: InvoiceActions.UPDATE_STATE;
  payload: IPreviewInvoice;
}

export interface IInvoiceAddAction {
  type: InvoiceActions.ADD_STATE;
  payload: IPreviewInvoice;
}

export interface IInvoiceRemoveAction {
  type: InvoiceActions.REMOVE_STATE;
  payload: IPreviewInvoice['id'];
}

export interface IInvoiceStore {
  loaded: boolean;
  loading: boolean;
  invoices: Array<IPreviewInvoice>;
  dict: Record<IPreviewInvoice['id'], IPreviewInvoice>;
}

export type TInvoiceActionTypes = IInvoiceSetAction | IInvoiceUpdateAction | IInvoiceAddAction | IInvoiceRemoveAction | IInvoiceLoading;
