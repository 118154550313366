import './elevenlabs.scss';
import {
  CustomFormItemProps,
  IFormComponent,
  FormText,
} from 'atlas-form';
import React, { useCallback, useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { streamSoundTextDemo, } from '@Api';
import { ISharedPatchSound, ISharedSound, ElevenlabsVoiceEnum, Undefinable, } from 'atlas-shared';
import { AudioPlayer, } from '@Utils/audio-player';

interface IProps {
  value: any;
  data: CustomFormItemProps['data'];
  entityId: ISharedSound['id'];
  entity: ISharedPatchSound;
  onChange: (text?: string)=> void;
}

export const FormComponentElevenlabs: IFormComponent = (props: IProps) => {

  const {
    onChange,
    value: _value,
    data,
    entity,
    entityId,
  } = props;
  const { t, } = useTranslation();
  const [playerKey, setPlayerKey, ] = useState<string>(Math.random() + '');
  const [voice, setVoice, ] = useState<Undefinable<ElevenlabsVoiceEnum>>(data[0].elevenlabs_settings?.voice);
  const _onChange = useCallback((v) => {
    onChange(v);
    setPlayerKey(Math.random() + '');
  }, []);

  useEffect(() => {
    if (data[0].elevenlabs_settings?.voice !== voice) {
      setVoice(data[0].elevenlabs_settings?.voice);
      setPlayerKey(Math.random() + '');
    }
  }, [data[0].elevenlabs_settings?.voice, ]);

  return <div className='elevenlabs-editor'>
    <FormText
      default_value={_value}
      onChange={v => _onChange(v)}
      rows={3}
    />
    <AudioPlayer
      t={t}
      key={playerKey}
      canPlay={true}
      size={'s'}
      stream={() => streamSoundTextDemo(entityId, _value, voice)}
    />

  </div>;
};
