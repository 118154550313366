import { Modal, } from 'antd';
import './password-reset-modal.scss';
import React from 'react';
import { useResetPassword, } from '@Hooks';
import { actionSetResetPassword, useAppDispatch, } from '@Store';
import { PasswordResetForm, } from './password-reset-form';

export const PasswordResetModal = () => {

  const dispatch = useAppDispatch();
  const user_id = useResetPassword();

  return user_id ? <Modal
    visible={!!user_id}
    width={520}
    onCancel={() => dispatch(actionSetResetPassword(null))}
    className={'reset-password-modal'}
  >
    <PasswordResetForm
      user_id={user_id}
      onCancel={() => dispatch(actionSetResetPassword(null))}
      onOk={() => dispatch(actionSetResetPassword(null))}
    />
  </Modal> : <></>;
};
