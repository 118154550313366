import React, { useCallback, useEffect, useState, } from 'react';
import './header.search.scss';
import { useTranslation, } from 'react-i18next';
import { useFocus, useAuth, useSearch, useRecentlySearcheds, useRecentlyViewedConversations, } from '@Hooks';
import debounce from 'lodash/debounce';
import { searchGlobal, createRecentlySearched, } from '@Api';
import { actionSetGlobalSearch, useAppDispatch, } from '@Store';
import { Icon, SearchNoResult, } from '@Components';
import { SearchIcon, } from '@Assets/icons';
import { isAdmin, SearchSections, } from '@Utils';
import { SearchSectionEnum, IElasticPreviewContact, IElasticPreviewConversation, Nullable, IElasticSearchResult, } from 'atlas-shared';
import { HeaderSearchResults, } from './header.search.results';
import { HeaderSearchRecentlySearch, } from './header.search.recently.searched';
import { HeaderSearchRecentlyViewed, } from './header.search.recently.viewed';
import { useStableNavigate, } from '../../../context/StableNavigateContext';

export const HeaderSearch = React.memo(_ => {
  const { t, } = useTranslation();
  const dispatch = useAppDispatch();
  const globalSearch = useSearch();
  const [dropdown, setDropdown, ] = useState<boolean>(false);
  const [selectedSearchType, setSelectedSearchType, ] = useState<Nullable<SearchSectionEnum>>(SearchSections[2]);
  const [results, setResults, ] = useState<{
    conversations: IElasticSearchResult<IElasticPreviewConversation>;
    contacts: IElasticSearchResult<IElasticPreviewContact>;
  }>({
    contacts: {
      max_score: 0,
      items: [],
    },
    conversations: {
      max_score: 0,
      items: [],
    },
  });
  const [inputRef, setInputFocus, ] = useFocus();
  const auth = useAuth();
  const navigate = useStableNavigate();
  const [searching, setSearching, ] = useState<boolean>(false);
  const searches = useRecentlySearcheds();
  const views = useRecentlyViewedConversations(auth.user_status.agentprofile_id || undefined);

  /* eslint-disable react-hooks/exhaustive-deps */
  const closeDropDown = useCallback((): void => {
    setTimeout(() => document.activeElement !== inputRef.current && setDropdown(false), 500);
  }, [inputRef, setDropdown, ]);

  const performSearch = useCallback(debounce((text: string) => {
    setSearching(true);
    searchGlobal(text, selectedSearchType, !isAdmin(auth.user) ? auth.user_status.agentprofile_id : null)
      .then((res) => {
        setSearching(false);
        setResults(res);
      });
  }, 650), [selectedSearchType, auth, ]);

  useEffect(() => {

    if (globalSearch)
      performSearch(globalSearch);
    else
      setResults({
        contacts: {
          max_score: 0,
          items: [],
        },
        conversations: {
          max_score: 0,
          items: [],
        },
      });

  }, [globalSearch, selectedSearchType, ]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      closeDropDown();
      goToSearchPage();
    }
  };

  const goToSearchPage = useCallback(() => {
    navigate(`/dashboard/search?keyword=${globalSearch}`);
    createRecentlySearched({
      agentprofile_id: auth.user_status.agentprofile_id || null,
      search: globalSearch,
      user_id: auth.user.id,
      section: selectedSearchType === SearchSections[2] ? null : selectedSearchType as SearchSectionEnum,
    });
    closeDropDown();
  }, [globalSearch, auth.user.id, selectedSearchType, ]);

  return (
    <div className={`search-wrapper${searching ? ' searching' : ''}`} >
      <div className={'search-content'} >
        <div className='search-input'>
          <input
            onKeyDown={handleKeyDown}
            value={globalSearch}
            onFocus={() => setDropdown(true)}
            onBlur={closeDropDown}
            ref={inputRef}
            onChange={e => dispatch(actionSetGlobalSearch(e.target.value))}
            placeholder={t('GLOBAL_SEARCH_PLACEHOLDER')}
            type="text"
            style={{ paddingLeft:'15px', }}
          />
          <div onClick={() => globalSearch && goToSearchPage()} className={'search-icon-wrapper'} >
            <div className='search-icon'>
              <Icon icon={SearchIcon} iconProps={{ size: 16, style:{ marginRight: 10, }, }} />
            </div>
            <div className='searching-wrapper'>
              <div className='loader'></div>
            </div>
          </div>
        </div>
        <div className={`search-results-popover${dropdown ? ' active' : ''}`} onClick={setInputFocus}>
          <div className={'return-to-send-wrp'} >
            <span>{t('RETURN')}</span> {t('TO')} {t('SEND')}
          </div>
          <div className={'search-results-sections'} >
            <div className={'search-header'}>
              <ul className={'search-types'} >
                <li onClick={() => setSelectedSearchType(SearchSections[2])} className={selectedSearchType === SearchSections[2] ? 'active' : ''} >
                  {t('ALL')}
                </li>
                <li onClick={() => setSelectedSearchType(SearchSections[0])} className={selectedSearchType === SearchSections[0] ? 'active' : ''} >
                  {t('CONVERSATIONS')}
                </li>
                <li onClick={() => setSelectedSearchType(SearchSections[1])} className={selectedSearchType === SearchSections[1] ? 'active' : ''} >
                  {t('CONTACTS')}
                </li>
              </ul>
            </div>
            <div className={'search-results'} >
              {
                <>
                  {globalSearch && <HeaderSearchResults
                    contacts={results.contacts}
                    conversations={results.conversations}
                    keyword={globalSearch}
                    navigate={navigate}
                    section={selectedSearchType}
                  />}
                  {
                    !globalSearch &&
                      <>
                        {!searches.recently_searcheds.length && !views.recently_viewed_conversations.length && <div className='start-typing'>
                          <SearchNoResult
                            section={selectedSearchType}
                            t={t}
                            text={t('START_TYPING_TO_SEARCH')}
                            style={{ marginBottom: 20, }}
                          />
                        </div>}
                        <HeaderSearchRecentlySearch
                          searches={searches}
                          setSelectedSearchType={setSelectedSearchType}
                          setInputFocus={setInputFocus}
                        />
                        <HeaderSearchRecentlyViewed
                          views={views}
                          setInputFocus={setInputFocus}
                        />
                      </>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
