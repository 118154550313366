import {
  hasRole,
  isAdmin,
  isSuperAdmin,
  isAgent,
  getFullName,
  getInitials,
} from 'atlas-shared';

export {
  hasRole,
  isAdmin,
  isSuperAdmin,
  isAgent,
  getFullName,
  getInitials,
};
