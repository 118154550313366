import { Empty, } from 'antd';
import { useTranslation, } from 'react-i18next';
import React from 'react';

interface IProps {
  description?: string;
  style?: React.CSSProperties;
}
export const NoResult = React.memo((props: IProps) => {

  const { t, } = useTranslation();
  const { description, style, } = props;

  return <Empty description={description || t('NO_ITEMS_WERE_FOUND')} style={style} />;
});
