import { IPreviewQueue, } from 'atlas-shared';

export enum QueueActions {
  SET_STATE = 'QUEUE_SET_STATE',
  ADD_STATE = 'QUEUE_ADD_STATE',
  REMOVE_STATE = 'QUEUE_REMOVE_STATE',
  GET_STATE = 'QUEUE_GET_STATE',
  UPDATE_STATE = 'QUEUE_UPDATE_STATE',
  LOADING = 'QUEUE_LOADING',
}

export interface IQueueLoading {
  type: QueueActions.LOADING;
}

export interface IQueueSetAction {
  type: QueueActions.SET_STATE;
  payload: Array<IPreviewQueue>;
}

export interface IQueueUpdateAction {
  type: QueueActions.UPDATE_STATE;
  payload: IPreviewQueue;
}

export interface IQueueAddAction {
  type: QueueActions.ADD_STATE;
  payload: IPreviewQueue;
}

export interface IQueueRemoveAction {
  type: QueueActions.REMOVE_STATE;
  payload: IPreviewQueue['id'];
}

export interface IQueueStore {
  loaded: boolean;
  loading: boolean;
  queues: Array<IPreviewQueue>;
  dict: Record<IPreviewQueue['id'], IPreviewQueue>;
}

export type TQueueActionTypes = IQueueSetAction | IQueueUpdateAction | IQueueAddAction | IQueueRemoveAction | IQueueLoading;
