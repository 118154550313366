import { IPreviewVoiceInternalCall, } from 'atlas-shared';

export enum VoiceInternalCallActions {
  SET_STATE = 'VOICE_INTERNAL_CALL_SET_STATE',
  ADD_STATE = 'VOICE_INTERNAL_CALL_ADD_STATE',
  REMOVE_STATE = 'VOICE_INTERNAL_CALL_REMOVE_STATE',
  GET_STATE = 'VOICE_INTERNAL_CALL_GET_STATE',
  UPDATE_STATE = 'VOICE_INTERNAL_CALL_UPDATE_STATE',
  LOADING = 'VOICE_INTERNAL_CALL_LOADING',
}

export interface IVoiceInternalCallLoading {
  type: VoiceInternalCallActions.LOADING;
}

export interface IVoiceInternalCallSetAction {
  type: VoiceInternalCallActions.SET_STATE;
  payload: Array<IPreviewVoiceInternalCall>;
}

export interface IVoiceInternalCallUpdateAction {
  type: VoiceInternalCallActions.UPDATE_STATE;
  payload: IPreviewVoiceInternalCall;
}

export interface IVoiceInternalCallAddAction {
  type: VoiceInternalCallActions.ADD_STATE;
  payload: IPreviewVoiceInternalCall;
}

export interface IVoiceInternalCallRemoveAction {
  type: VoiceInternalCallActions.REMOVE_STATE;
  payload: IPreviewVoiceInternalCall['id'];
}

export interface IVoiceInternalCallStore {
  loaded: boolean;
  loading: boolean;
  voice_internal_calls: Array<IPreviewVoiceInternalCall>;
  dict: Record<IPreviewVoiceInternalCall['id'], IPreviewVoiceInternalCall>;
}

export type TVoiceInternalCallActionTypes = IVoiceInternalCallSetAction | IVoiceInternalCallUpdateAction | IVoiceInternalCallAddAction | IVoiceInternalCallRemoveAction | IVoiceInternalCallLoading;
