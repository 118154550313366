import React, { useEffect, useState, } from 'react';
import '../admin.list.scss';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { ColumnsType, SorterResult, } from 'antd/lib/table/interface';
import { IPreviewTariffParent, PermissionEnum, CrudEnum, TTariffParentSortableListCol, } from 'atlas-shared';
import { Table, } from 'antd';
import { AdminListPageHeader, ListPageEmpty, } from '@Components';
import { IWithListProps, IWithListSorterProps, withList, } from '@Hocs/with.list';
import { useAbilities, useAuth, useTariffParents, useOrganizations, } from '@Hooks';
import { TariffParentListColumns, } from '@Utils';
import { IAuth, } from '@Store';

const TariffParentList = ({ filters, filtersCount, hasFilters, sorter, pagination, setQueryParams, resetQueryParams, }: IWithListProps<IPreviewTariffParent, TTariffParentSortableListCol>) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth: IAuth = useAuth();
  const tariff_parents = useTariffParents();
  const abilities = useAbilities();
  const [loading, setLoading, ] = useState<boolean>(true);
  const [rows, setRows, ] = useState<Array<any>>([]);
  const organizations = useOrganizations();
  
  useEffect(() => {
    if (!tariff_parents.loaded)
      return;
      
    setLoading(false);
    setRows((tariff_parents?.tariff_parents || []).map(row => ({ ...row, key: row.id, })));
    
  }, [tariff_parents, ]);

  const columns: ColumnsType<IPreviewTariffParent> = React.useMemo(() => {
    return TariffParentListColumns(t, navigate, false, organizations, abilities, auth, [], filters, sorter);
  }, [t, navigate, organizations, abilities, auth, filters, sorter, ]);

  return <div className='admin-list-view'>
    <AdminListPageHeader 
      t={t}
      title={t('TARIFF_PARENTS')}
      filtersCount={filtersCount}
      reset={() => resetQueryParams()}
      addBtn={abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.TariffParent) ? { callback: () => navigate('/admin/tariff_parent/add'), } : undefined} 
    />
    <div className='table-wrp'>
      <Table
        key={loading ? 1 : 0}
        columns={columns}
        dataSource={rows}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
        onChange={(pagination, filters, _sorter) => {
          const sorter: Array<SorterResult<any>> = Array.isArray(_sorter) ? _sorter : _sorter?.columnKey ? [_sorter, ] : [];

          setQueryParams({
            pagination: { current: pagination.current as number, pageSize: pagination.pageSize as number, },
            filters: Object.fromEntries(Object.entries(filters).filter(([key, value, ]) => value !== null)),
            sorter: sorter
              .filter(s => !!s.order)
              .map(s => ({ column: s.columnKey as TTariffParentSortableListCol, order: s.order as IWithListSorterProps<IPreviewTariffParent, TTariffParentSortableListCol>['order'], })),
          });
        }}
        locale={{
          emptyText: <ListPageEmpty t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => resetQueryParams()} />,
        }}
      />
    </div>
  </div>;
};

export const TariffParentListView = React.memo(withList<IPreviewTariffParent, TTariffParentSortableListCol>(TariffParentList, {
  filters: {},
  sorter: [{ column: 'created_at', order: 'descend', }, ],
  pagination: { current: 1, pageSize: 10, },
}));
