import { TFunction, } from 'i18next';

import { AuditListColumns as AuditListColumnsBase, } from './abstract/audit.list.column.abstract';

export * from './abstract/audit.list.column.abstract';

import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, IUserStore, } from '@Store';
import React from 'react';
import { IWithListProps, } from '@Hocs/with.list';
import { ColumnsType, } from 'antd/lib/table/interface';
import { IPreviewAudit, TAuditSortableListCol, } from 'atlas-shared';
import { auditPageLink, translateAudit, } from '@Utils/audit';
import { Icon, } from '@Components';
import { TableIcon, } from '@Assets';

export const AuditListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, users: IUserStore, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewAudit) => React.ReactElement> = [], filters: IWithListProps<IPreviewAudit, TAuditSortableListCol>['filters'], sorter: IWithListProps<IPreviewAudit, TAuditSortableListCol>['sorter']): ColumnsType<IPreviewAudit> => {
  const BaseCols = AuditListColumnsBase(t, navigate, showDeleted, users, organizations, abilities, auth, [
    (item: IPreviewAudit) => <Icon icon={TableIcon} tooltip={{ title: t('DETAILS'), }} onClick={() => navigate(auditPageLink(item.id, item.pk))} />,
    ...buttons,
  ], filters, sorter);

  return [
    ...BaseCols.slice(0, 2),
    {
      title: t('CHANGED_PROPS'),
      dataIndex: 'changed_props',
      key: 'changed_props',
      render: (changed_props: IPreviewAudit['changed_props'], item: IPreviewAudit) => <div className='wrapper'>{translateAudit(t, navigate, item)}</div>,
      className: 'table-cell_changed_props',
    },
    ...BaseCols.slice(2, 8),
  ];
};
