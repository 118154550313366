import { RestRequest, } from '@Utils';
import { IPreviewVoiceRecordingTranscriptSetting, ISharedVoiceRecordingTranscriptSetting, ISharedPatchVoiceRecordingTranscriptSetting, } from 'atlas-shared';

export const fetchVoiceRecordingTranscriptSettings = async (): Promise<Array<IPreviewVoiceRecordingTranscriptSetting>> => {
  return RestRequest.get<Array<IPreviewVoiceRecordingTranscriptSetting>>('voice_recording_transcript_setting', { socket_id: global.socketId, });
};

export const fetchVoiceRecordingTranscriptSetting = async (id: IPreviewVoiceRecordingTranscriptSetting['id']): Promise<ISharedVoiceRecordingTranscriptSetting> => {
  return RestRequest.get<ISharedVoiceRecordingTranscriptSetting>(`voice_recording_transcript_setting/${id}`, { socket_id: global.socketId, });
};

export const patchVoiceRecordingTranscriptSetting = (id: IPreviewVoiceRecordingTranscriptSetting['id'], payload: ISharedPatchVoiceRecordingTranscriptSetting) => {
  return RestRequest.patch<any, ISharedVoiceRecordingTranscriptSetting>(`voice_recording_transcript_setting/${id}`, payload, { socket_id: global.socketId, });
};

export const getVoiceRecordingTranscriptSettingUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_recording_transcript_setting/update/schema');
};

export const getVoiceRecordingTranscriptSettingPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_recording_transcript_setting/patch/schema');
};
