import React, { useMemo, useState, } from 'react';
import { ArchiveIcon, } from '@Assets/icons';
import './tab.info.scss';
import { ISharedFullConversation, } from 'atlas-shared';
import { useTranslation, } from 'react-i18next';
import { IWithFullConversationProps, } from '@Hocs/with.full.conversation';
import { IAuth, } from '@Store';
import { ConversationTitleBox, ConversationDispositionForm, IconText, CustomerJourneyListMini, } from '@Components';

interface IProps {
  auth: IAuth;
  conversation: ISharedFullConversation;
  onClose: ()=> void;
  className?: string;
  contact_view: IWithFullConversationProps['contact_view'];
  customer_journey_view: IWithFullConversationProps['customer_journey_view'];
  patch_conversation: IWithFullConversationProps['patch_conversation'];
}

export const TabInfo = React.memo(({ onClose, conversation, className, contact_view, patch_conversation, auth, customer_journey_view, }: IProps) => {
  const { t, } = useTranslation();
  const [disposeVisible, setDisposeVisible, ] = useState<boolean>(false);
  const classNames = useMemo(() => ['tab-info-view', className, ].filter(Boolean).join(' '), [className, ]);

  return (
    <div className={classNames}>
      <div className={'col-1'}>
        <div className={'left'}>
          <ConversationTitleBox auth={auth} patch_conversation={patch_conversation} conversation={conversation} minimized={true} short={true} />
        </div>
        <div className={'right'}>
          {/*<IconText icon={ChatIcon} style={{ padding: 8, border:'1px solid rgba(128, 130, 153, 0.2)', borderRadius: 2 }} iconProps={{ size: 20, style:{ color: '#808299' } }} text={''}/>*/}
          {!disposeVisible && <IconText icon={ArchiveIcon} className={'dispose'} iconProps={{ size: 20, }} text={t('DISPOSE')} onClick={() => setDisposeVisible(true)}/>}
        </div>
      </div>
      {
        disposeVisible ?
          <ConversationDispositionForm
            conversation={conversation}
            patch_conversation={patch_conversation}
            onClose={() => setDisposeVisible(false)}
          /> :
          <div className={'col-2'}>
            {contact_view}
            {conversation.journey.length > 0 && <CustomerJourneyListMini
              t={t}
              conversation={conversation}
              journeys={conversation.journey}
            />}
          </div>
      }
    </div>
  );
});
