import { RestRequest, } from '@Utils';
import { IPreviewDestinationRestrictor, ISharedDestinationRestrictor, ISharedPatchDestinationRestrictor, } from 'atlas-shared';

export const fetchDestinationRestrictors = async (): Promise<Array<IPreviewDestinationRestrictor>> => {
  return RestRequest.get<Array<IPreviewDestinationRestrictor>>('destination_restrictor', { socket_id: global.socketId, });
};

export const fetchDestinationRestrictor = async (id: IPreviewDestinationRestrictor['id']): Promise<ISharedDestinationRestrictor> => {
  return RestRequest.get<ISharedDestinationRestrictor>(`destination_restrictor/${id}`, { socket_id: global.socketId, });
};

export const patchDestinationRestrictor = (id: IPreviewDestinationRestrictor['id'], payload: ISharedPatchDestinationRestrictor) => {
  return RestRequest.patch<any, ISharedDestinationRestrictor>(`destination_restrictor/${id}`, payload, { socket_id: global.socketId, });
};

export const getDestinationRestrictorUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('destination_restrictor/update/schema');
};

export const getDestinationRestrictorPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('destination_restrictor/patch/schema');
};
