import { ReleaseActions, } from '@Store';
import { IPreviewRelease, } from 'atlas-shared';
import { fetchReleases, } from '@Api';

export const actionFetchReleases = () => {
  return async (dispatch) => {
    dispatch(actionLoadingReleases());
    const releases = await fetchReleases();

    dispatch(actionSetReleases(releases));
    return releases;
  };
};

export const actionLoadingReleases = () => ({
  type: ReleaseActions.LOADING,
  payload: [],
});

export const actionSetReleases = (payload: Array<IPreviewRelease>) => ({
  type: ReleaseActions.SET_STATE,
  payload: payload,
});

export const actionAddRelease = (payload: IPreviewRelease) => ({
  type: ReleaseActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateRelease = (payload: IPreviewRelease) => ({
  type: ReleaseActions.UPDATE_STATE,
  payload: payload,
});

export const actionRemoveRelease = (id: IPreviewRelease['id']) => ({
  type: ReleaseActions.REMOVE_STATE,
  payload: id,
});
