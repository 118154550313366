import { UserActions, } from '@Store';
import { IPreviewUser, } from 'atlas-shared';
import { fetchUsers, } from '@Api';

export const actionFetchUsers = () => {
  return async (dispatch) => {
    dispatch(actionLoadingUsers());
    const users = await fetchUsers();

    dispatch(actionSetUsers(users));
    return users;
  };
};

export const actionLoadingUsers = () => ({
  type: UserActions.LOADING,
  payload: [],
});

export const actionSetUsers = (payload: Array<IPreviewUser>) => ({
  type: UserActions.SET_STATE,
  payload: payload,
});

export const actionAddUser = (payload: IPreviewUser) => ({
  type: UserActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateUser = (payload: IPreviewUser) => ({
  type: UserActions.UPDATE_STATE,
  payload: payload,
});
