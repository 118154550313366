import { IPreviewRelease, } from 'atlas-shared';

export enum ReleaseActions {
  SET_STATE = 'RELEASE_SET_STATE',
  ADD_STATE = 'RELEASE_ADD_STATE',
  REMOVE_STATE = 'RELEASE_REMOVE_STATE',
  GET_STATE = 'RELEASE_GET_STATE',
  UPDATE_STATE = 'RELEASE_UPDATE_STATE',
  LOADING = 'RELEASE_LOADING',
}

export interface IReleaseLoading {
  type: ReleaseActions.LOADING;
}

export interface IReleaseSetAction {
  type: ReleaseActions.SET_STATE;
  payload: Array<IPreviewRelease>;
}

export interface IReleaseUpdateAction {
  type: ReleaseActions.UPDATE_STATE;
  payload: IPreviewRelease;
}

export interface IReleaseAddAction {
  type: ReleaseActions.ADD_STATE;
  payload: IPreviewRelease;
}

export interface IReleaseRemoveAction {
  type: ReleaseActions.REMOVE_STATE;
  payload: IPreviewRelease['id'];
}

export interface IReleaseStore {
  loaded: boolean;
  loading: boolean;
  releases: Array<IPreviewRelease>;
  dict: Record<IPreviewRelease['id'], IPreviewRelease>;
}

export type TReleaseActionTypes = IReleaseSetAction | IReleaseUpdateAction | IReleaseAddAction | IReleaseRemoveAction | IReleaseLoading;
