import { RestRequest, } from '@Utils';
import { IPreviewCannedAnswer, ISharedCannedAnswer, ISharedCreateCannedAnswer, ISharedPatchCannedAnswer, } from 'atlas-shared';

export const fetchCannedAnswers = async (): Promise<Array<IPreviewCannedAnswer>> => {
  return RestRequest.get<Array<IPreviewCannedAnswer>>('canned_answer', { socket_id: global.socketId, });
};

export const fetchCannedAnswer = async (id: IPreviewCannedAnswer['id']): Promise<ISharedCannedAnswer> => {
  return RestRequest.get<ISharedCannedAnswer>(`canned_answer/${id}`, { socket_id: global.socketId, });
};

export const createCannedAnswer = (payload: ISharedCreateCannedAnswer) => {
  return RestRequest.post<any, ISharedCannedAnswer>('canned_answer', payload, { socket_id: global.socketId, });
};

export const patchCannedAnswer = (id: IPreviewCannedAnswer['id'], payload: ISharedPatchCannedAnswer) => {
  return RestRequest.patch<any, ISharedCannedAnswer>(`canned_answer/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteCannedAnswer = (id: IPreviewCannedAnswer['id']) => {
  return RestRequest.delete(`canned_answer/${id}`, { socket_id: global.socketId, });
};

export const getCannedAnswerUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('canned_answer/update/schema');
};

export const getCannedAnswerCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('canned_answer/create/schema');
};

export const getCannedAnswerPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('canned_answer/patch/schema');
};
