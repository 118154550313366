import { IPreviewSound, } from 'atlas-shared';

export enum SoundActions {
  SET_STATE = 'SOUND_SET_STATE',
  ADD_STATE = 'SOUND_ADD_STATE',
  REMOVE_STATE = 'SOUND_REMOVE_STATE',
  GET_STATE = 'SOUND_GET_STATE',
  UPDATE_STATE = 'SOUND_UPDATE_STATE',
  LOADING = 'SOUND_LOADING',
}

export interface ISoundLoading {
  type: SoundActions.LOADING;
}

export interface ISoundSetAction {
  type: SoundActions.SET_STATE;
  payload: Array<IPreviewSound>;
}

export interface ISoundUpdateAction {
  type: SoundActions.UPDATE_STATE;
  payload: IPreviewSound;
}

export interface ISoundAddAction {
  type: SoundActions.ADD_STATE;
  payload: IPreviewSound;
}

export interface ISoundRemoveAction {
  type: SoundActions.REMOVE_STATE;
  payload: IPreviewSound['id'];
}

export interface ISoundStore {
  loaded: boolean;
  loading: boolean;
  sounds: Array<IPreviewSound>;
  dict: Record<IPreviewSound['id'], IPreviewSound>;
}

export type TSoundActionTypes = ISoundSetAction | ISoundUpdateAction | ISoundAddAction | ISoundRemoveAction | ISoundLoading;
