import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  PhonenumberPurchaseAddForm,
  PhonenumberPurchaseAddInitialValues,
  PhonenumberPurchaseComponents,
  PhonenumberPurchaseAdditionalParams,
  PhonenumberPurchaseFormOptions,
  onPhonenumberPurchaseFormSaved,
  IPhonenumberPurchaseFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, Nullable, } from 'atlas-form';
import { CrudEnum, ISharedCreatePhonenumberPurchase, PermissionEnum, ISharedPhonenumberPrefix, ISharedPhonenumberSetupCost, ISharedPhonenumberMonthlyCost, ISharedOrganization, } from 'atlas-shared';
import {
  createPhonenumberPurchase,
  fetchOrganization, fetchPhonenumberMonthlyCost, fetchPhonenumberPrefix,
  fetchPhonenumberSetupCost,
  getPhonenumberPurchaseCreateSchema,
} from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, } from '@Hooks';
import { AppSpinner, displayWithCurrency, } from '@Components';
import { useAppDispatch, } from '@Store';
import './phonenumber_purchase.form.scss';

interface IProps extends IWithAddProps<ISharedCreatePhonenumberPurchase> {}

const PhonenumberPurchaseFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const [phonenumber_prefix, set_phonenumber_prefix, ] = useState<Nullable<ISharedPhonenumberPrefix>>(null);
  const [invoice_organization_id, set_invoice_organization_id, ] = useState<Nullable<ISharedOrganization['invoice_organization_id']>>(null);

  const [setupCost, setSetupCost, ] = useState<{phonenumber_setup_cost: Nullable<ISharedPhonenumberSetupCost>; cost: number}>({
    phonenumber_setup_cost: null,
    cost: 0,
  });
  const [monthlyCost, setMonthlyCost, ] = useState<{phonenumber_monthly_cost: Nullable<ISharedPhonenumberMonthlyCost>; cost: number}>({
    phonenumber_monthly_cost: null,
    cost: 0,
  });

  useEffect(() => {
    fetchPhonenumberPrefix(JSON.parse(queryParam('iv') || '{}').phonenumber_prefix_id)
      .then(phonenumber_prefix => set_phonenumber_prefix(phonenumber_prefix));
  }, []);

  useEffect(() => {

    if (invoice_organization_id && phonenumber_prefix) {
      fetchOrganization(invoice_organization_id)
        .then(organization => {

          if (organization.invoice.phonenumber_setup_cost_id !== setupCost.phonenumber_setup_cost?.id)
            fetchPhonenumberSetupCost(organization.invoice.phonenumber_setup_cost_id).then(phonenumber_setup_cost => {
              setSetupCost({
                phonenumber_setup_cost,
                cost: phonenumber_setup_cost.__reduced_phonenumber_setup_costs.phonenumber_setup_cost_list[phonenumber_prefix.alpha2][phonenumber_prefix.type.toLowerCase()],
              });
            });

          if (organization.invoice.phonenumber_monthly_cost_id !== monthlyCost.phonenumber_monthly_cost?.id)
            fetchPhonenumberMonthlyCost(organization.invoice.phonenumber_monthly_cost_id).then(phonenumber_monthly_cost => {
              setMonthlyCost({
                phonenumber_monthly_cost,
                cost: phonenumber_monthly_cost.__reduced_phonenumber_monthly_costs.phonenumber_monthly_cost_list[phonenumber_prefix.alpha2][phonenumber_prefix.type.toLowerCase()],
              });
            });

        });
    }

  }, [invoice_organization_id, phonenumber_prefix, ]);

  useEffect(() => {

    if (phonenumber_prefix && values.organization_id && values.organization_id !== invoice_organization_id) {
      const organization_id = organizations.dict[values.organization_id].invoice_organization_id;

      set_invoice_organization_id(organization_id || null);
    }

  }, [organizations, invoice_organization_id, values.organization_id, phonenumber_prefix, ]);

  const param_props: IPhonenumberPurchaseFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
  };
  const formLayout = useMemo(() => PhonenumberPurchaseAddForm(values, param_props, t), [values, organizations, t, abilities, ]);
  const options: IFormOptions = useMemo(() => PhonenumberPurchaseFormOptions(values || PhonenumberPurchaseAddInitialValues, param_props, t), [values, organizations, t, abilities, ]);

  const onValuesChange = useCallback((changedValue: any, values: ISharedCreatePhonenumberPurchase, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreatePhonenumberPurchase) => save(() => createPhonenumberPurchase(values).then(
    res => onSaved ? onSaved(res, navigate) : onPhonenumberPurchaseFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);

  if (schemaLoading)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;

  if (!organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  const { regulation, } = phonenumber_prefix || {};
  const regulations: Array<string> = [];

  if (regulation?.proofRequired)
    regulations.push(t(`REGULATION_${regulation.addressType}_ADDRESS_PROOF_REQUIRED`));

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.PhonenumberPurchase, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={PhonenumberPurchaseComponents}
        additionalParams={PhonenumberPurchaseAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...PhonenumberPurchaseAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('PHONENUMBER_PURCHASE')}`}
        hasAdvanced={hasAdvanced}
        saveBtnTitle={'PURCHASE'}
      />
      {values.organization_id && <table id='phonenumber_purchase-cost-preview'>
        <tr>
          <th>{t('SETUP_COST')}</th>
          <td>{(!!setupCost && setupCost.phonenumber_setup_cost?.currency) ? displayWithCurrency(setupCost.phonenumber_setup_cost?.currency, setupCost.cost) : '-'}</td>
        </tr>
        <tr>
          <th>{t('MONTHLY_COST')}</th>
          <td>{(!!monthlyCost && monthlyCost.phonenumber_monthly_cost?.currency) ? displayWithCurrency(monthlyCost.phonenumber_monthly_cost?.currency, monthlyCost.cost) : '-'}</td>
        </tr>
        {!!regulations.length && <tr>
          <th>{t('REGULATIONS')}</th>
          <td>
            <ul>
              {regulations.map(r => <li>{r}</li>)}
            </ul>
          </td>
        </tr>}
      </table>}
    </RuleProtected>
  );
};

export const PhonenumberPurchaseFormAddView = React.memo(withAdd(PhonenumberPurchaseFormAdd, getPhonenumberPurchaseCreateSchema, PhonenumberPurchaseAddInitialValues));
