import { IAuth, } from '@Store';
import { IForm, } from '@Modules/atlas-form-core';
import {
  VoiceRecordingTranscriptSettingAdditionalParams as VoiceRecordingTranscriptSettingAdditionalParamsBase,
  IVoiceRecordingTranscriptSettingFormOptionsProps,
} from '@Utils/forms/abstract/voice_recording_transcript_setting.form.abstract';

export * from './abstract/voice_recording_transcript_setting.form.abstract';

export const VoiceRecordingTranscriptSettingAdditionalParams: (auth: IAuth, props: IVoiceRecordingTranscriptSettingFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IVoiceRecordingTranscriptSettingFormOptionsProps) => ({
  ...VoiceRecordingTranscriptSettingAdditionalParamsBase(auth, props),
  model_en: { label: 'LANGUAGE_EN', },
  model_se: { label: 'LANGUAGE_SE', },
  model_fi: { label: 'LANGUAGE_FI', },
  model_dk: { label: 'LANGUAGE_DK', },
  model_de: { label: 'LANGUAGE_DE', },
  model_no: { label: 'LANGUAGE_NO', },
  model_pl: { label: 'LANGUAGE_PL', },
});
