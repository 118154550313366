import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchDestinationRestrictors, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useDestinationRestrictors = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const destination_restrictors = useSelector((store: IReducerStore) => store.destination_restrictors);

  useEffect(() => {
    if (!initiated && autoload && !destination_restrictors.loaded && !destination_restrictors.loading) {
      initiated = true;
      dispatch(actionFetchDestinationRestrictors());
    }
  }, [autoload, destination_restrictors.loaded, destination_restrictors.loading, dispatch, ]);

  return destination_restrictors;
};
