import { journeyAction, } from './base';

import { JourneyActionRule as JourneyActionRuleBase, JourneyConditionComparisonEnum, } from 'atlas-shared';
import { FormLayoutTypes, } from 'atlas-form';

export const JourneyActionRule = journeyAction({
  ...JourneyActionRuleBase,
  additionalParams: {
    properties_conditions: { noPadding: true, labelWidth: '80px', addText: 'ADD_CONDITION', },
    properties_conditions_rules: { ui_layout: FormLayoutTypes.INLINE, noPadding: true, addText: 'ADD_RULE', },
    properties_conditions_rules_key: { hideLabel: true, },
    properties_conditions_rules_comparison: { hideLabel: true, },
    properties_conditions_rules_value: { hideLabel: true, },
    properties_conditions_rules_value_arr: (base, parent) => ({
      hideLabel: true,
      mode: [
        JourneyConditionComparisonEnum.AnyOfStr,
        JourneyConditionComparisonEnum.NoneOfStr,
      ].includes(parent.comparison) ? 'tags' : 'multiple',
    }),
    properties_conditions_rules_data_key: { hideLabel: true, },
    properties_conditions_rules_variable_alias: { hideLabel: true, },
  },
  icon: 'HelpCircle',
});
