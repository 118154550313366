import { useSelector, } from 'react-redux';
import { useCallback, useEffect, useState, } from 'react';
import {
  actionFetchConversationsPaginating, actionSetConversations, IReducerStore, useAppDispatch,
} from '@Store';
import { ISharedConversationFilter, Undefinable, default_conversation_filters, } from 'atlas-shared';
import { useAuth, } from './use.auth';
import { useConversationFilters, } from './abstract/use.conversation_filters.abstract';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { AlertError, } from '@Utils';
import { useTranslation, } from 'react-i18next';
import { useResetConversationList, } from '@Hooks/use.reset.conversation.list';

const useConversationsPagination = (conversation_filter_id: ISharedConversationFilter['id'] = 0) => {
  const dispatch = useAppDispatch();
  const { t, } = useTranslation();
  const auth = useAuth();
  const [perPage, ] = useState(20);
  let conversations = useSelector((store: IReducerStore) => store.conversations);
  const [canLoadMore, setCanLoadMore, ] = useState(!auth.user_status.conversation_filter_id);
  const [isLoading, setIsLoading, ] = useState(false);
  const conversationFilters = useConversationFilters();
  const [conversationFilter, setConversationFilter, ] = useState<Undefinable<ISharedConversationFilter>>(undefined);
  const [lastResetConversationList, setLastResetConversationList, ] = useState(null);
  const reset_conversation_list = useResetConversationList();

  const loadMore = useCallback(() => {
    if (!isLoading && auth && canLoadMore) {
      setIsLoading(true);
      (dispatch(actionFetchConversationsPaginating(conversation_filter_id || auth.user_status.conversation_filter_id || -1, auth.user_status.agentprofile_id || 0, { limit: perPage, offset: conversations.conversations.length, })) as any)
        .then(res => {
          if (res.length < perPage)
            setCanLoadMore(false);
        }, error => {
          setCanLoadMore(false);
          AlertError(t, { content: t(error.toString()), });
        }).finally(() => {
          setIsLoading(false);
        });
    }
  }, [auth, canLoadMore, conversations.conversations.length, auth.user_status.agentprofile_id, conversation_filter_id, isLoading, ]);

  // useEffect(() => {
  //   if (conversationFilters.loaded && conversationFilter === undefined)
  //     setConversationFilter(auth.user_status.conversation_filter_id ? conversationFilters.dict[auth.user_status.conversation_filter_id] : {} as ISharedConversationFilter);
  //
  // }, [conversationFilters.loaded]);

  useEffect(() => {

    if (!conversationFilters.loaded)
      return;

    const default_filters = default_conversation_filters(auth.user.id, 0);
    const conversation_filter_id = auth.user_status.conversation_filter_id || -1;
    let current_conversation_filter;

    if (conversation_filter_id > 0)
      current_conversation_filter = conversationFilters.dict[conversation_filter_id];
    else if (conversation_filter_id < 0)
      current_conversation_filter = default_filters.find(f => (f as any).id === conversation_filter_id);

    if (
      (!current_conversation_filter && !conversationFilter)
      || (
        current_conversation_filter
      && current_conversation_filter.id === conversationFilter?.id
      && current_conversation_filter.agentprofile_id === conversationFilter?.agentprofile_id
      && isEqual(current_conversation_filter.filters, conversationFilter?.filters)
      && isEqual(current_conversation_filter.sort_by, conversationFilter?.sort_by)
      )
    )
      return;

    debounce(() => {
      dispatch(actionSetConversations([]));
      setCanLoadMore(true);
    }, 150)();

    setConversationFilter(current_conversation_filter);

  }, [auth.user_status.conversation_filter_id, auth.user_status.conversation_filter_id && conversationFilters.dict[auth.user_status.conversation_filter_id], auth.user_status.agentprofile_id, conversationFilter, ]);

  useEffect(() => {
    debounce(() => {
      dispatch(actionSetConversations([]));
      setCanLoadMore(true);
    }, 150)();
  }, [reset_conversation_list, ]);

  useEffect(() => {
    if (canLoadMore && !isLoading && !conversations.conversations.length)
      loadMore();
  }, [loadMore, conversations.conversations.length, canLoadMore, isLoading, ]);

  return {
    conversations,
    loadMore,
    isLoading,
  };
};

export { useConversationsPagination, };
