import { IconSet, SettingsIcon, } from '@Assets/icons';
import { IJourneyActionProps, } from '../actions';
import { SvgIcon, } from '@Assets/icons/SvgIcon';
import { IconText, } from '@Components';
import React, { MouseEvent, } from 'react';
import { IJourneyPayloadNodesKeyProperty, JourneyActionEnum, } from 'atlas-shared';

export const getActionIcon = (action: IJourneyActionProps): typeof SvgIcon => {
  return IconSet[action.icon] || SettingsIcon;
};

export const getActionIconText = (action: IJourneyActionProps, t, node?: IJourneyPayloadNodesKeyProperty): React.ReactElement => {
  const defaultTitle = () => {
    if (action.action === JourneyActionEnum.When && node?.properties?.type)
      return `${t('WHEN')}: ${t(node?.properties?.type?.toUpperCase())}`;

    return t(action.action);
  };

  return <IconText icon={getActionIcon(action)} text={node?.title || defaultTitle()} textProps={{ style: { maxWidth: 205, }, }} />;
};

export const getActionSubtitle = (action: IJourneyActionProps, t, node?: IJourneyPayloadNodesKeyProperty): string => {
  if (!node)
    return '';

  return t(`NODE_SUBTITLE_${node.action}`, ' ') + (action.action === JourneyActionEnum.When && node?.properties?.type ? ` ${t(node?.properties?.type?.toUpperCase())}` : '');
};

export const getActionPanelIcon = (action: IJourneyActionProps, t, onClick?: (e?: MouseEvent<HTMLElement>) => any) => {
  return <IconText icon={getActionIcon(action)} text={t(action.action)} iconProps={{ size: 12, }} onClick={onClick} tooltip={{ title: t(`NODE_SUBTITLE_${action.action}`), placement: 'right', }} />;
};
