import { journeyAction, } from './base';

import { JourneyActionCloseTabs as JourneyActionCloseTabsBase, } from 'atlas-shared';

export const JourneyActionCloseTabs = journeyAction({
  ...JourneyActionCloseTabsBase,
  additionalParams: {
    properties_conversation_owner_only: { labelWidth: '220px', },
  },
  icon: 'Close',
});
