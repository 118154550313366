import { IPreviewFormWebsite, } from 'atlas-shared';

export enum FormWebsiteActions {
  SET_STATE = 'FORM_WEBSITE_SET_STATE',
  ADD_STATE = 'FORM_WEBSITE_ADD_STATE',
  REMOVE_STATE = 'FORM_WEBSITE_REMOVE_STATE',
  GET_STATE = 'FORM_WEBSITE_GET_STATE',
  UPDATE_STATE = 'FORM_WEBSITE_UPDATE_STATE',
  LOADING = 'FORM_WEBSITE_LOADING',
}

export interface IFormWebsiteLoading {
  type: FormWebsiteActions.LOADING;
}

export interface IFormWebsiteSetAction {
  type: FormWebsiteActions.SET_STATE;
  payload: Array<IPreviewFormWebsite>;
}

export interface IFormWebsiteUpdateAction {
  type: FormWebsiteActions.UPDATE_STATE;
  payload: IPreviewFormWebsite;
}

export interface IFormWebsiteAddAction {
  type: FormWebsiteActions.ADD_STATE;
  payload: IPreviewFormWebsite;
}

export interface IFormWebsiteRemoveAction {
  type: FormWebsiteActions.REMOVE_STATE;
  payload: IPreviewFormWebsite['id'];
}

export interface IFormWebsiteStore {
  loaded: boolean;
  loading: boolean;
  form_websites: Array<IPreviewFormWebsite>;
  dict: Record<IPreviewFormWebsite['id'], IPreviewFormWebsite>;
}

export type TFormWebsiteActionTypes = IFormWebsiteSetAction | IFormWebsiteUpdateAction | IFormWebsiteAddAction | IFormWebsiteRemoveAction | IFormWebsiteLoading;
