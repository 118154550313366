import { IPreviewStatsWidget, } from 'atlas-shared';
import { IStatsWidgetStore, StatsWidgetActions, TStatsWidgetActionTypes, } from '@Store';

export const StatsWidgetReducer = (state: IStatsWidgetStore = {
  loaded: false,
  loading: false,
  stats_widgets: [],
  dict: {},
}, action: TStatsWidgetActionTypes): IStatsWidgetStore => {
  switch (action.type) {
  case StatsWidgetActions.LOADING:
    return { ...state, loading: true, };
  case StatsWidgetActions.SET_STATE:
    const dict: IStatsWidgetStore['dict'] = {};

    action.payload.forEach((stats_widget: IPreviewStatsWidget) => dict[stats_widget.id] = stats_widget);

    return { ...state, loaded: true, loading: false, stats_widgets: action.payload, dict, };
  case StatsWidgetActions.ADD_STATE:
    return { ...state, stats_widgets: [...state.stats_widgets, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case StatsWidgetActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, stats_widgets: state.stats_widgets.filter((item: IPreviewStatsWidget) => item.id !== action.payload), dict, };
  }
  case StatsWidgetActions.UPDATE_STATE:
    const stats_widgets = [...state.stats_widgets, ];
    const index = state.stats_widgets.findIndex((stats_widget: IPreviewStatsWidget) => stats_widget.id === action.payload.id);

    if (!~index)
      stats_widgets.push(action.payload);
    else
      stats_widgets[index] = action.payload;

    return { ...state, stats_widgets, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
