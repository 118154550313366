import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  TariffParentAddForm,
  TariffParentAddInitialValues,
  TariffParentComponents,
  TariffParentAdditionalParams,
  TariffParentFormOptions,
  onTariffParentFormSaved,
  ITariffParentFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateTariffParent, PermissionEnum, } from 'atlas-shared';
import { createTariffParent, fetchTariffParent, getTariffParentCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateTariffParent> {}

const TariffParentFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateTariffParent>>();

  const param_props: ITariffParentFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
  };
  const formLayout = useMemo(() => TariffParentAddForm(values, param_props, t), [values, organizations, t, abilities, ]);
  const options: IFormOptions = useMemo(() => TariffParentFormOptions(values || TariffParentAddInitialValues, param_props, t), [values, organizations, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateTariffParent, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateTariffParent) => save(() => createTariffParent(values).then(
    res => onSaved ? onSaved(res, navigate) : onTariffParentFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchTariffParent(+id)
        .then(({
          title,
          currency,
          voice_tariff_list,
          voice_recording,
          voice_recording_storage,
          voice_recording_transcript,
          voice_tariff_roundup_strategy,
          voice_outbound_usage,
          voice_usage_roundup_strategy,
          voice_inbound_usage,
          voice_internal_usage,
          attachments_storage,
          email_inbound_usage,
          email_outbound_usage,
          form_inbound_usage,
          ai_request_kb_assistance,
          text_to_voice_usage,
        }) => {
          setCloned({
            title,
            currency,
            voice_tariff_list,
            voice_recording,
            voice_recording_storage,
            voice_recording_transcript,
            voice_tariff_roundup_strategy,
            voice_outbound_usage,
            voice_usage_roundup_strategy,
            voice_inbound_usage,
            voice_internal_usage,
            attachments_storage,
            email_inbound_usage,
            email_outbound_usage,
            form_inbound_usage,
            ai_request_kb_assistance,
            text_to_voice_usage,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(null, CrudEnum.Insert, PermissionEnum.TariffParent, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={TariffParentComponents}
        additionalParams={TariffParentAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...TariffParentAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('TARIFF_PARENT')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const TariffParentFormAddView = React.memo(withAdd(TariffParentFormAdd, getTariffParentCreateSchema, TariffParentAddInitialValues));
