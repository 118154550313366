import './echo.test.scss';
import { getSipUsersWrapper, IVoiceSessionObject, } from 'SIP';
import { useCallback, useEffect, useState, } from 'react';
import { useVoiceAsterisks, } from '@Hooks';
import { Undefinable, } from 'atlas-shared/dist';
import { Switch, } from 'antd';
import * as React from 'react';
import { useTranslation, } from 'react-i18next';

interface IProps {
  onDone: (passed: boolean) => void;
}

export const EchoTest = ({ onDone, }: IProps) => {
  const { t, } = useTranslation();
  const sip_users_wrapper = getSipUsersWrapper();
  const voice_asterisks = useVoiceAsterisks();
  const [sipUser, setSipUser, ] = useState<Undefinable<any>>();
  const [sessions, setSessions, ] = useState<Array<IVoiceSessionObject>>([]);
  const [speakerTestPassed, setSpeakerTestPassed, ] = useState<boolean>(false);
  const [microphoneTestPassed, setMicrophoneTestPassed, ] = useState<boolean>(false);
  
  const hangup = useCallback(() => {
    sessions.forEach(session => {
      sipUser.hangup(session);
    });
  }, [sessions, ]);
  
  useEffect(() => {
    if (!voice_asterisks.voice_asterisks.length)
      return;

    setSipUser(sip_users_wrapper?.getSipUser(voice_asterisks.voice_asterisks[0].id)?.getSipUser());
  }, []);
  
  useEffect(() => {
    if (!sipUser)
      return;

    sipUser.call(`sip:echotest@${sipUser.options.userAgentOptions?.uri?.host}:5060`);
    
    setTimeout(() => {
      setSessions(sipUser.getEchoTestSessions());
    }, 1500);

  }, [sipUser, ]);
  
  useEffect(() => {
    return () => hangup();
  }, [sessions, ]);
  
  useEffect(() => {
    if (speakerTestPassed && microphoneTestPassed)
      hangup();
  }, [speakerTestPassed, microphoneTestPassed, ]);
  
  useEffect(() => {
    if (speakerTestPassed && microphoneTestPassed)
      onDone(speakerTestPassed && microphoneTestPassed);
  }, [speakerTestPassed, microphoneTestPassed, ]);

  const passed = speakerTestPassed && microphoneTestPassed;
  
  return <div className='echo-test-wrapper'>
    {!passed && <h4>{t('PLEASE_CONFIRM_FOLLOWING')}:</h4>}
    {!passed && <div className='question'>
      <Switch
        defaultChecked={speakerTestPassed}
        onChange={checked => setSpeakerTestPassed(checked)}
      /> <span className='question'>{t('ECHO_TEST_SOUND_QUESTION')}</span>
    </div>}
    {!passed && <div className='question'>
      <Switch
        defaultChecked={microphoneTestPassed}
        onChange={checked => setMicrophoneTestPassed(checked)}
      /> <span className='question'>{t('ECHO_TEST_MICROPHONE_QUESTION')}</span>
    </div>}
  </div>;

};
