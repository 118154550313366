import { Select, } from 'antd';
import { actionSetGlobalVoiceQueueRouteSelected, useAppDispatch, } from '@Store';
import { Icon, IconText, } from '@Components';
import { ArrowRightIcon, InboxIcon, } from '@Assets';
import React from 'react';
import { useVoiceQueueRouteOptions, useVoiceQueueRouteSelected, } from '@Hooks';
import { TFunction, } from 'i18next';
import { DisplayPhoneNumber, translatePhoneNumber, } from '@Utils/phone';

interface IProps {
  t: TFunction;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const QueueRouteSelection = ({ t, onBlur, onFocus, }: IProps) => {
  const dispatch = useAppDispatch();
  const voice_queue_route_options = useVoiceQueueRouteOptions();
  const voice_queue_route_selected = useVoiceQueueRouteSelected();

  return <div className='queue-route-selection'>
    <Select
      dropdownClassName={'queue-route-selection-options'}
      placeholder={t('SELECT_VOICE_QUEUE_ROUTE')}
      onFocus={() => onFocus?.()}
      onBlur={() => onBlur?.()}
      value={voice_queue_route_selected ? `${voice_queue_route_selected.queue_id}-${voice_queue_route_selected.route_id}` : undefined}
      onChange={(value) => {
        if (!value)
          return;

        const [queue_id, route_id, ] = (value as string).split('-').map(d => +d);

        dispatch(actionSetGlobalVoiceQueueRouteSelected({
          queue_id,
          route_id,
        }));
      }}
    >
      {voice_queue_route_options.map(queue_route => <Select.Option key={`${queue_route.queue.id}-${queue_route.route.id}`} value={`${queue_route.queue.id}-${queue_route.route.id}`}>
        <IconText icon={InboxIcon} text={queue_route.queue.title} iconProps={{ size: 12, }} />
        <Icon icon={ArrowRightIcon} className={'arrow'} iconProps={{ size: 12, }} wrapperStyle={{ margin: '0 5px', }} />
        <DisplayPhoneNumber t={t} phonenumber={translatePhoneNumber(queue_route.route.number)} />
      </Select.Option>)}
    </Select>
  </div>;
};
