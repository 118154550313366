import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  PhonenumberSetupCostAddForm,
  PhonenumberSetupCostAddInitialValues,
  PhonenumberSetupCostComponents,
  PhonenumberSetupCostAdditionalParams,
  PhonenumberSetupCostFormOptions,
  onPhonenumberSetupCostFormSaved,
  IPhonenumberSetupCostFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreatePhonenumberSetupCost, PermissionEnum, } from 'atlas-shared';
import { createPhonenumberSetupCost, fetchPhonenumberSetupCost, getPhonenumberSetupCostCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, usePhonenumberSetupCostParents, useOrganizations, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreatePhonenumberSetupCost> {}

const PhonenumberSetupCostFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const phonenumber_setup_cost_parents = usePhonenumberSetupCostParents();
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreatePhonenumberSetupCost>>();

  const param_props: IPhonenumberSetupCostFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    phonenumber_setup_cost_parents: phonenumber_setup_cost_parents.phonenumber_setup_cost_parents,
    organizations: organizations.organizations,
  };
  const formLayout = useMemo(() => PhonenumberSetupCostAddForm(values, param_props, t), [values, phonenumber_setup_cost_parents, organizations, t, abilities, ]);
  const options: IFormOptions = useMemo(() => PhonenumberSetupCostFormOptions(values || PhonenumberSetupCostAddInitialValues, param_props, t), [values, phonenumber_setup_cost_parents, organizations, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreatePhonenumberSetupCost, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreatePhonenumberSetupCost) => save(() => createPhonenumberSetupCost(values).then(
    res => onSaved ? onSaved(res, navigate) : onPhonenumberSetupCostFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchPhonenumberSetupCost(+id)
        .then(({
          phonenumber_setup_cost_parent_id,
          title,
          phonenumber_setup_cost_list,
        }) => {
          setCloned({
            phonenumber_setup_cost_parent_id,
            title,
            phonenumber_setup_cost_list,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!phonenumber_setup_cost_parents.loaded || !organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(null, CrudEnum.Insert, PermissionEnum.PhonenumberSetupCost, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={PhonenumberSetupCostComponents}
        additionalParams={PhonenumberSetupCostAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...PhonenumberSetupCostAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('PHONENUMBER_SETUP_COST')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const PhonenumberSetupCostFormAddView = React.memo(withAdd(PhonenumberSetupCostFormAdd, getPhonenumberSetupCostCreateSchema, PhonenumberSetupCostAddInitialValues));
