import { RestRequest, } from '@Utils';
import { IPreviewRelease, ISharedRelease, } from 'atlas-shared';

export const fetchReleases = async (): Promise<Array<IPreviewRelease>> => {
  return RestRequest.get<Array<IPreviewRelease>>('release', { socket_id: global.socketId, });
};

export const fetchRelease = async (id: IPreviewRelease['id']): Promise<ISharedRelease> => {
  return RestRequest.get<ISharedRelease>(`release/${id}`, { socket_id: global.socketId, });
};
