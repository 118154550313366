import { getFullName, timeSince, } from '@Utils';
import React, { useEffect, useState, } from 'react';
import { useUsers, } from '@Hooks';
import { IPreviewDraft, IPreviewUser, } from 'atlas-shared/dist';
import { TFunction, } from 'i18next';
import { Avatar, } from '@Components';

interface IProps {
  t: TFunction;
  draft: IPreviewDraft;
}

export const ConversationMessageDraftTyping = React.memo(({ draft, t, }: IProps) => {
  const [user, setUser, ] = useState<IPreviewUser>();
  const users = useUsers();

  useEffect(() => {
    setUser(users.dict[draft.user_id]);
  }, []);
  return <>
    <span className={'head'}>
      <div className={'info'}>
        <div className={'time'} >
          <span style={{ opacity: .5, paddingRight: 5, }}>{t('LAST_UPDATED')}</span> {timeSince(t, new Date(draft.updated_at), true)}
        </div>
      </div>
      {user && <div className={'user'} >
        <Avatar user={user} size={24} />
        <div className={'user-info'} >
          <span>
            {getFullName(user)}
          </span>
        </div>
      </div>}
    </span>
    <span className={'body'} dangerouslySetInnerHTML={{ __html: draft.body, }}/>
  </>; 
});
