import { RestRequest, } from '@Utils';
import { IPreviewList, ISharedList, ISharedCreateList, ISharedPatchList, } from 'atlas-shared';

export const fetchLists = async (): Promise<Array<IPreviewList>> => {
  return RestRequest.get<Array<IPreviewList>>('list', { socket_id: global.socketId, });
};

export const fetchList = async (id: IPreviewList['id']): Promise<ISharedList> => {
  return RestRequest.get<ISharedList>(`list/${id}`, { socket_id: global.socketId, });
};

export const createList = (payload: ISharedCreateList) => {
  return RestRequest.post<any, ISharedList>('list', payload, { socket_id: global.socketId, });
};

export const patchList = (id: IPreviewList['id'], payload: ISharedPatchList) => {
  return RestRequest.patch<any, ISharedList>(`list/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteList = (id: IPreviewList['id']) => {
  return RestRequest.delete(`list/${id}`, { socket_id: global.socketId, });
};

export const getListUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('list/update/schema');
};

export const getListCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('list/create/schema');
};

export const getListPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('list/patch/schema');
};
