import React, { useEffect, useMemo, useState, } from 'react';
import { Input, Mentions, } from 'antd';
import debounce from 'lodash/debounce';
import { IFormElement, } from '../atlas-form-core';
import { IMention, IOption, } from '@Utils';
import { TOnError, } from './utils';

interface IProps {
  className?: string;
  default_value?: string;
  placeholder?: string;
  onChange: (value: any) => void;
  style?: IFormElement['element_style'];
  disabled?: boolean;
  options?: Array<IOption>;
  onError: TOnError;
}

function FormInput(props: IProps) {

  const {
    placeholder,
    default_value,
    onChange,
    style,
    disabled,
    className,
    options,
    onError,
  } = props;

  const prefix = '*=';
  const mentions: Array<IMention> = useMemo(() => {
    return options?.map(option => ({
      value: option.key as string,
      label: option.title,
    })) || [];
  }, [options, ]);

  const debouncedChange = debounce((value) => {
    onChange(value);
  }, 250);

  const [value, setValue, ] = useState(default_value);

  useEffect(() => {
    debouncedChange(value);
  }, [value, ]);

  useEffect(() => {
    if (value?.includes(` ${prefix}`)) {
      setValue(value.replace(/\s\*=[a-z_.]+(\s=\*|\s|=\*)/i, v => `*=${v.replace(/\s|=|\*/g, '')}=*`));
    }
  }, [value, ]);

  useEffect(() => {
    const matched = value?.match(/\*=[a-z_.]+=\*/ig) || [];
    const errors = matched.filter(match => {
      const key = match.replace(prefix, '').replace(prefix.split('').reverse().join(''), '');

      return !options?.some(option => option.key === key);
    });

    onError(errors.map(key => `Invalid key ${key}`));
  }, [value, ]);

  useEffect(() => {
    if (value?.includes('\n'))
      setValue(value.split('\n').join(''));
  }, [value, ]);

  if (!options?.length)
    return <Input
      placeholder={placeholder}
      className={className}
      defaultValue={default_value}
      onBlur={e => onChange(e.target.value)}
      onKeyUp={e => debouncedChange((e.target as any).value)}
      style={style}
      disabled={disabled}
    />;

  return <Mentions
    value={value}
    placeholder={placeholder}
    className={className}
    defaultValue={default_value}
    onBlur={e => setValue((e.target as HTMLTextAreaElement).value)}
    onChange={value => setValue(value)}
    style={style}
    disabled={disabled}
    options={mentions}
    prefix={[prefix, ]}
  />;
}

export default React.memo(FormInput);
