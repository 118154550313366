import { IPreviewVoiceAsterisk, ISharedUser, ISharedAgentprofile, Nullable, } from 'atlas-shared';
import SipUserWrapper from './sip-user-wrapper';

export interface IVoiceAsteriskItem {
  voice_asterisk: IPreviewVoiceAsterisk;
  agentprofile_id?: ISharedAgentprofile['id'];
}

export default class SipUsersWrapper {
  private sipUsers: Record<IPreviewVoiceAsterisk['id'], SipUserWrapper> = {};

  public muted: boolean = false;

  constructor(
    private user: ISharedUser,
    private dispatch
  ) {
  }

  async addSipUser(voice_asterisk: IPreviewVoiceAsterisk, agentprofile_id: IVoiceAsteriskItem['agentprofile_id'], microphone: Nullable<string>) {

    if (this.sipUsers[voice_asterisk.id] && voice_asterisk.__is_downgraded !== this.sipUsers[voice_asterisk.id].voice_asterisk.__is_downgraded) {
      await this.sipUsers[voice_asterisk.id].disconnect();
      delete this.sipUsers[voice_asterisk.id];
    }

    if (!this.sipUsers[voice_asterisk.id]) {
      this.sipUsers[voice_asterisk.id] = new SipUserWrapper(this.user, agentprofile_id, this.dispatch, voice_asterisk, microphone);
      this.sipUsers[voice_asterisk.id].connect();
    }

    return this;
  }

  getSipUsers() {
    return this.sipUsers;
  }

  getSipUser(voice_asterisk_id: IPreviewVoiceAsterisk['id']) {
    return this.sipUsers[voice_asterisk_id];
  }
  
  mute(mute: boolean) {
    if (this.muted !== mute) {
      this.muted = mute;
      Object.values(this.sipUsers).forEach(wrapper => {
        wrapper.getSipUser().muteAll(mute);
      });
    }
  }

  async addSipUsers(voice_asterisk_items: Array<IVoiceAsteriskItem>, microphone: Nullable<string>) {
    // disconnect existing sip users not selected anymore
    await Promise.all(Object.entries(this.sipUsers).map(async ([key, sipUser, ]) => {
      const voice_asterisk_id = sipUser.voice_asterisk.id;
      const found = voice_asterisk_items.some(voice_asterisk_item => voice_asterisk_item.agentprofile_id === sipUser.agentprofile_id && voice_asterisk_item.voice_asterisk.id === voice_asterisk_id);

      if (!found) {
        await sipUser.disconnect();
        delete this.sipUsers[voice_asterisk_id];
      }
    }));
    return voice_asterisk_items.map(({ voice_asterisk, agentprofile_id, }) => this.addSipUser(voice_asterisk, agentprofile_id, microphone));
  }
  
  setMicrophone(deviceId: Nullable<string>) {
    Object.values(this.sipUsers).forEach(sipUserWrapper => {
      sipUserWrapper.setMicrophone(deviceId);
    });
  }

  setMicrophoneMute(mute: boolean) {
    Object.values(this.sipUsers).forEach(sipUserWrapper => {
      sipUserWrapper.setMicrophoneMute(mute);
    });
  }

  disconnectAll() {
    return Promise.all(Object.entries(this.sipUsers).map(async ([key, sipUser, ]) => {
      const voice_asterisk_id = sipUser.voice_asterisk.id;

      await sipUser.disconnect();
      delete this.sipUsers[voice_asterisk_id];
    }));
  }
}
