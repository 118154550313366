import { IAuthResponse, ISharedUser, Nullable, } from 'atlas-shared';
import moment from 'moment';
import { apiRefreshToken, } from '@Api';
import { RestRequest, } from './rest';
import { actionSetAuth, } from '@Store';
import { isAdmin, isAgent, isSuperAdmin, } from './index';
import { Dispatch, } from 'redux';
import { NavigateFunction, } from 'react-router';

export interface IJwtPayload {
  sub: ISharedUser['id'];
  iat: number;
  exp: number;
}

export const parseJwt = (token: string): Nullable<IJwtPayload> => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
};

export const hasJwtExpired = (token: string): boolean => {
  const parsed: Nullable<IJwtPayload> = parseJwt(token);

  return (parsed?.exp || 0) < moment().unix();
};

export const jwtTimeUntilExpire = (token: string): number => {
  const parsed: Nullable<IJwtPayload> = parseJwt(token);

  if (!parsed?.exp)
    return -1;

  return parsed.exp - moment().unix();
};

let tokenTimeout;

export const jwtRefreshToken = (user: IAuthResponse['user'], user_status: IAuthResponse['user_status'], tokens: IAuthResponse['tokens'], dispatch: Dispatch<any>, navigate: NavigateFunction, personified: boolean = false) => {
  const { access_token, refresh_token, } = tokens;
  const ttl = jwtTimeUntilExpire(access_token);

  clearTimeout(tokenTimeout);

  if (ttl > 0)
    tokenTimeout = setTimeout(() => {
      apiRefreshToken(refresh_token, access_token)
        .then(res => {
          const { user, user_status, tokens, } = res;

          jwtRefreshToken(user, user_status, tokens, dispatch, navigate);
        })
      ;
    }, Math.max((ttl - 3) * 1000, 0));

  finalizeAuth(user, user_status, tokens, dispatch, navigate, personified);

};

const finalizeAuth = (user: IAuthResponse['user'], user_status: IAuthResponse['user_status'], tokens: IAuthResponse['tokens'], dispatch: Dispatch<any>, navigate: NavigateFunction, personified: boolean = false) => {
  const { access_token, refresh_token, } = tokens;

  RestRequest.setAuth(`Bearer ${access_token}`);
  localStorage.setItem(!personified ? 'atlas-auth' : 'atlas-auth-personified', JSON.stringify(tokens));
  dispatch(actionSetAuth({
    access_token,
    refresh_token,
    user,
    user_status,
    is_admin: isAdmin(user),
    is_superadmin: isSuperAdmin(user),
    is_agent: isAgent(user),
  }));

  if (!window.location.pathname.replaceAll('/', '') || window.location.pathname === '/login')
    navigate('/dashboard');
};
