import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import './conversation.title.box.scss';
import Text from 'antd/es/typography/Text';
import { TabSizeEnum, ISharedFullConversation, IPreviewQueue, Undefinable, Nullable, } from 'atlas-shared';
import {
  ActivityIcon,
  ExternalLinkIcon, MergeIcon,
  ShrinkIcon, StarIcon,
} from '@Assets/icons';
import { useTranslation, } from 'react-i18next';
import { actionRequestTab, IAuth, useAppDispatch, } from '@Store';
import { useQueues, useSkills, useTabs, useTags, } from '@Hooks';
import { IWithFullConversationProps, } from '@Hocs/with.full.conversation';
import { ConversationTitleBoxIcons, Icon, ConversationReplyIn, ConversationAgent, ConversationTagsSqueeze, } from '@Components';

interface IProps {
  auth: IAuth;
  conversation: ISharedFullConversation;
  minimized: boolean;
  className?: string;
  patch_conversation: IWithFullConversationProps['patch_conversation'];
  short?: boolean;
  conversationTimeline?: {
    get: boolean;
    set: (value: boolean) => void;
  };
}

export const ConversationTitleBox = React.memo(({ conversation, minimized, className, auth, patch_conversation, short = false, conversationTimeline, }: IProps) => {
  const { t, } = useTranslation();
  const dispatch = useAppDispatch();
  const tabs = useTabs();
  const queues = useQueues();
  const skills = useSkills();
  const tags = useTags();
  const [rowQueue, setRowQueue, ] = useState<Undefinable<IPreviewQueue>>();

  const skill = useMemo(() => {
    return conversation.skill_id && skills.dict[conversation.skill_id] || null;
  }, [skills, conversation.skill_id, ]);

  const points = useMemo(() => (conversation.points_closed || 0) + (conversation.points_created || 0) + (conversation.points_resolved || 0), [conversation.points_closed, conversation.points_created, conversation.points_resolved, ]);
  const points_tooltip = useMemo(() => points && ([
    ['POINTS_CREATED', conversation.points_created, ],
    ['POINTS_RESOLVED', conversation.points_resolved, ],
    ['POINTS_CLOSED', conversation.points_closed, ],
  ] as Array<[string, Nullable<number>]>)
    .filter(([section, points, ]) => !!points)
    .map(([section, points, ]) => `${t(section)}: ${points}p`)
    .join('\n'), [points, conversation.points_closed, conversation.points_created, conversation.points_resolved, ]);

  useEffect(() => {
    setRowQueue(queues.queues.find(queue => queue.id === conversation.queue_id));
  }, [conversation.queue_id, conversation.user_id, queues, rowQueue, ]);

  const requestTab = useCallback((size: TabSizeEnum) => {
    return new Promise((resolve) => {
      dispatch(actionRequestTab(conversation, conversation.organization_id, size, tabs, resolve));
    });
  }, [tabs, conversation.id, conversation.organization_id, dispatch, ]);

  return (
    <div className={'conversation-title-view ' + className}>
      {
        !minimized && auth.user.main &&
        <div className={'expand-icons'}>
          <Icon icon={ShrinkIcon} iconProps={{ size: 14, }} tooltip={{ title: t('MINIMIZE'), }} onClick={() => requestTab(TabSizeEnum.Mini)}/>
          <Icon icon={ExternalLinkIcon} iconProps={{ size: 14, }} tooltip={{ title: t('MAXIMIZE'), }} onClick={() => requestTab(TabSizeEnum.Maximized)}/>
        </div>
      }
      <div className={'row'}>
        <small className={'title'}>
          {t('CONVERSATION')}
        </small>
      </div>
      <div className={'row'}>
        <Text className={'id'}>
          {`#${conversation.id}`}
          {!!points && <Icon
            icon={StarIcon}
            tooltip={{ title: points_tooltip, }}
            iconProps={{ style: { stroke: 'gold', fill: 'gold', }, }}
          />}
          {skill && <Icon
            icon={MergeIcon}
            tooltip={{ title: `${t('SKILL')}: ${skill.title}`, }}
          />}
          {conversationTimeline && <div className='conversation-toggle-timeline-button'>
            <Icon
              icon={ActivityIcon}
              className={`toggle-timeline${conversationTimeline.get ? ' active' : ''}`}
              onClick={_ => conversationTimeline.set(!conversationTimeline.get)}
              tooltip={{
                title: t('JOURNEY_TIMELINE'),
              }}
            />
          </div>}
        </Text>
        <ConversationReplyIn conversation={conversation} icon={true} short={short} />
        <ConversationTagsSqueeze conversation={conversation} t={t} tags={tags} />
      </div>
      <div className={'row'}>
        <ConversationAgent
          t={t}
          auth={auth}
          conversation={conversation}
          shortened={false}
          to_me={!minimized}
          unassign={!!rowQueue?.settings?.allow_agent_unassign}
        />
      </div>
      {
        !minimized && <ConversationTitleBoxIcons auth={auth} patch_conversation={patch_conversation} className={'icons-container'} conversation={conversation} short={true} />
      }
    </div>
  );
});
