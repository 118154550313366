import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  InvoicePostAddForm,
  InvoicePostAddInitialValues,
  InvoicePostComponents,
  InvoicePostAdditionalParams,
  InvoicePostFormOptions,
  onInvoicePostFormSaved,
  IInvoicePostFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateInvoicePost, PermissionEnum, } from 'atlas-shared';
import { createInvoicePost, fetchInvoicePost, getInvoicePostCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useInvoices, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateInvoicePost> {}

const InvoicePostFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const invoices = useInvoices();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateInvoicePost>>();

  const param_props: IInvoicePostFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    invoices: invoices.invoices,
  };
  const formLayout = useMemo(() => InvoicePostAddForm(values, param_props, t), [values, organizations, invoices, t, abilities, ]);
  const options: IFormOptions = useMemo(() => InvoicePostFormOptions(values || InvoicePostAddInitialValues, param_props, t), [values, organizations, invoices, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateInvoicePost, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateInvoicePost) => save(() => createInvoicePost(values).then(
    res => onSaved ? onSaved(res, navigate) : onInvoicePostFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchInvoicePost(+id)
        .then(({
          organization_id,
          invoice_id,
          title,
          amount_type,
          amount_currency,
          amount_percent,
          exclude,
        }) => {
          setCloned({
            organization_id,
            invoice_id,
            title,
            amount_type,
            amount_currency,
            amount_percent,
            exclude,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !invoices.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.InvoicePost, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={InvoicePostComponents}
        additionalParams={InvoicePostAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...InvoicePostAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('INVOICE_POST')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const InvoicePostFormAddView = React.memo(withAdd(InvoicePostFormAdd, getInvoicePostCreateSchema, InvoicePostAddInitialValues));
