import { IPreviewUser, } from 'atlas-shared/dist';
import { fromJson, } from 'json-joi-converter';
import React, { useEffect, useState, } from 'react';
import { Form, } from 'antd';
import { AtlasForm, FormLayoutTypes, } from 'atlas-form';
import { patchUser, userPasswordSchema, } from '@Api';
import { AlertError, InfoModal, } from '@Utils';
import { useTranslation, } from 'react-i18next';
import { LoadingContainer, } from '@Containers/loading-container';

interface IProps {
  user_id: IPreviewUser['id'];
  onOk?: () => void;
  onCancel?: () => void;
}

export const PasswordResetForm = ({ user_id, onOk, onCancel, }: IProps) => {

  const [antForm, ] = Form.useForm();
  const { t, } = useTranslation();
  const [passwordSchema, setPasswordSchema, ] = useState();

  useEffect(() => {
    userPasswordSchema()
      .then(schema => setPasswordSchema(schema));
  }, []);

  if (!passwordSchema)
    return <LoadingContainer />;

  return <>
    <AtlasForm
      formLayout={{
        id: 'reset-password',
        ui_layout: FormLayoutTypes.HORIZONTAL,
      }}
      form={fromJson(passwordSchema)}
      title={`${t('RESET_PASSWORD')}`}
      initialValues={{ password: '', }}
      additionalParams={{
        password: { labelWidth: '70px', },
      }}
      antForm={antForm}
      onFinish={(values) => {
        patchUser(user_id, {
          password: values.password,
        })
          .then(() => {
            InfoModal(t, { content: t('PASSWORD_RESET_SUCCESSFUL'), });
            onOk?.();
          })
          .catch(error => {
            if (!error.toString().includes(' '))
              AlertError(t, { content: t(error.toString()), });
            else
              AlertError(t, { content: t('SOMETHING_WENT_WRING'), });
          });
      }}
      onCancel={onCancel}
    />
  </>;
};
