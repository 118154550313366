import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateCannedAnswer, ISharedUpdateCannedAnswer, ISharedCannedAnswer, IPreviewOrganization, EnumOptions, CannedAnswerChannelEnum, CannedAnswerTypeEnum, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface ICannedAnswerFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const CannedAnswerFormOptions = (canned_answer: ISharedCreateCannedAnswer | ISharedCannedAnswer, props: ICannedAnswerFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.CannedAnswer, {})), 'organization_id', false), 'organization'),
  channel: EnumOptions('CANNED_ANSWER_CHANNEL', CannedAnswerChannelEnum, t),
  type: EnumOptions('CANNED_ANSWER_TYPE', CannedAnswerTypeEnum, t),
});

export const CannedAnswerComponents: IForm['components'] = {};
export const CannedAnswerAdditionalParams: (auth: IAuth, props: ICannedAnswerFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ICannedAnswerFormOptionsProps) => ({
});

export const CannedAnswerEditForm = (canned_answer: ISharedCannedAnswer, props: ICannedAnswerFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `canned_answer_edit_${canned_answer.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === canned_answer.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'channel', },
    { ref: 'type', },
    { ref: 'body', },
    { ref: 'global', },
    { ref: 'description', },
  ],
});

export const CannedAnswerAddForm = (canned_answer: ISharedCreateCannedAnswer, props: ICannedAnswerFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'canned_answer_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'channel', },
    { ref: 'type', },
    { ref: 'body', },
    { ref: 'global', },
    { ref: 'description', },
  ],
});

export const CannedAnswerAddInitialValues: ISharedCreateCannedAnswer = {} as ISharedCreateCannedAnswer;

export const CannedAnswerEditInitialValues = (canned_answer: ISharedCannedAnswer): ISharedUpdateCannedAnswer => ({
  title: canned_answer.title,
  channel: canned_answer.channel,
  type: canned_answer.type,
  body: canned_answer.body,
  global: canned_answer.global,
  description: canned_answer.description,
});

export const onCannedAnswerFormSaved = (t: TFunction, action: 'edit' | 'add', canned_answer: ISharedCannedAnswer, navigate: NavigateFunction): void => {
  navigate(onFormSaved('canned_answers'));
};
