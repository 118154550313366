import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateJourney, ISharedUpdateJourney, ISharedJourney, IPreviewOrganization, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface IJourneyFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const JourneyFormOptions = (journey: ISharedCreateJourney | ISharedJourney, props: IJourneyFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Journey, {})), 'organization_id', false), 'organization'),
  
});

export const JourneyComponents: IForm['components'] = {};
export const JourneyAdditionalParams: (auth: IAuth, props: IJourneyFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IJourneyFormOptionsProps) => ({
});

export const JourneyEditForm = (journey: ISharedJourney, props: IJourneyFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `journey_edit_${journey.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === journey.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'payload', },
  ],
});

export const JourneyAddForm = (journey: ISharedCreateJourney, props: IJourneyFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'journey_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
  ],
});

export const JourneyAddInitialValues: ISharedCreateJourney = {} as ISharedCreateJourney;

export const JourneyEditInitialValues = (journey: ISharedJourney): ISharedUpdateJourney => ({
  title: journey.title,
  payload: journey.payload,
});

export const onJourneyFormSaved = (t: TFunction, action: 'edit' | 'add', journey: ISharedJourney, navigate: NavigateFunction): void => {
  navigate(onFormSaved('journeies'));
};
