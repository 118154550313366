import React, { useCallback, useMemo, } from 'react';
import { IPreviewFormSubmission, ISharedFormSubmissionAttachmentProperty, } from 'atlas-shared';
import { AttachmentIcon, DownloadIcon, ImageIcon, } from '@Assets/icons';
import { Tag, } from 'antd';
import { TFunction, } from 'i18next';
import { Icon, IconText, } from '@Components';
import { actionDocsPreview, } from '@Store';
import { fetchDownloadFormSubmissionAttachment, } from '@Api';
import { AlertError, preventDefault, } from '@Utils';

interface IProps {
  dispatch: any;
  organization_id: IPreviewFormSubmission['organization_id'];
  form_submission_id: IPreviewFormSubmission['id'];
  attachment: ISharedFormSubmissionAttachmentProperty;
  attachments: Array<ISharedFormSubmissionAttachmentProperty>;
  anonymized: boolean;
  index: number;
  t: TFunction;
  stringRendered?: boolean;
}

export const ConversationMessageFormSubmissionAttachment = React.memo(({ dispatch, organization_id, form_submission_id, attachment, attachments, index, t, stringRendered = false, anonymized: _anonymized, }: IProps) => {

  const anonymized = _anonymized || !!attachment.anonymized_at;

  const error = useMemo(() => t('FILE_NOT_FOUND', {
    replace: {
      filename: attachment.filename,
    },
  }), [attachment, ]);

  const onClick = useCallback(() => {
    if (anonymized)
      return;

    return dispatch(actionDocsPreview({
      index,
      docs: attachments.map((attachment, index) => ({ uri: `form_submission/p/${organization_id}/${form_submission_id}/attachment/${index}`, filename: attachment.filename, })),
    }));
  }, [attachments, index, ]);

  const onDownload = () => {
    return fetchDownloadFormSubmissionAttachment(organization_id, form_submission_id, attachment.filename, index, () => {
      AlertError(t, {
        content: error,
        title: t('NOT_FOUND'),
      });
    });
  };

  const icon = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', ].includes(attachment.contentType) ? ImageIcon : AttachmentIcon;

  const url = `javascript:fetchDownloadFormSubmissionAttachment(${organization_id}, ${form_submission_id}, '${attachment.filename}', '${index}', e => alert('${error}'))`;

  if (stringRendered)
    return (
      <a href={url}>
        <Tag className={'clickable'}>
          <IconText icon={icon} text={attachment.filename} />
        </Tag>
      </a>
    );

  return <Tag className={`clickable attachment${anonymized ? ' anonymized' : ''}`} onClick={onClick}>
    <IconText icon={icon} text={anonymized ? t('ANONYMIZED') : attachment.filename} />
    {!anonymized && <Icon icon={DownloadIcon} className='download' onClick={preventDefault(onDownload)}/>}
  </Tag>;
});
