import { OperationalHoursActions, } from '@Store';
import { IPreviewOperationalHours, } from 'atlas-shared';
import { fetchOperationalHourses, } from '@Api';

export const actionFetchOperationalHourses = () => {
  return async (dispatch) => {
    dispatch(actionLoadingOperationalHourses());
    const operational_hourses = await fetchOperationalHourses();

    dispatch(actionSetOperationalHourses(operational_hourses));
    return operational_hourses;
  };
};

export const actionLoadingOperationalHourses = () => ({
  type: OperationalHoursActions.LOADING,
  payload: [],
});

export const actionSetOperationalHourses = (payload: Array<IPreviewOperationalHours>) => ({
  type: OperationalHoursActions.SET_STATE,
  payload: payload,
});

export const actionAddOperationalHours = (payload: IPreviewOperationalHours) => ({
  type: OperationalHoursActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateOperationalHours = (payload: IPreviewOperationalHours) => ({
  type: OperationalHoursActions.UPDATE_STATE,
  payload: payload,
});
