import { IPreviewUser, } from 'atlas-shared';
import { IUserStore, UserActions, TUserActionTypes, } from '@Store';

export const UserReducer = (state: IUserStore = {
  loaded: false,
  loading: false,
  users: [],
  dict: {},
}, action: TUserActionTypes): IUserStore => {
  switch (action.type) {
  case UserActions.LOADING:
    return { ...state, loading: true, };
  case UserActions.SET_STATE:
    const dict: IUserStore['dict'] = {};

    action.payload.forEach((user: IPreviewUser) => dict[user.id] = user);

    return { ...state, loaded: true, loading: false, users: action.payload, dict, };
  case UserActions.ADD_STATE:
    return { ...state, users: [...state.users, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case UserActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, users: state.users.filter((item: IPreviewUser) => item.id !== action.payload), dict, };
  }
  case UserActions.UPDATE_STATE:
    const users = [...state.users, ];
    const index = state.users.findIndex((user: IPreviewUser) => user.id === action.payload.id);

    if (!~index)
      users.push(action.payload);
    else
      users[index] = action.payload;

    return { ...state, users, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
