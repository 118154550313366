import React from 'react';
import { ISvgIconProps, } from './interface';
import { SvgIcon, } from './SvgIcon';

export const RecordIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10"></circle>
    <circle cx="12" cy="12" r="4" stroke='transparent' fill="red"></circle>
  </SvgIcon>;
};

export const RecordingIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10"></circle>
    <circle cx="12" cy="12" r="4" stroke='transparent' fill="red"></circle>
  </SvgIcon>;
};

export const RecordStopIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10"></circle>
    <circle cx="12" cy="12" r="4" stroke='transparent' fill="red"></circle>
    <line x1="1" y1="1" x2="22" y2="22" fill="red" />
  </SvgIcon>;
};
