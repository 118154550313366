import { IAuth, } from '@Store';
import { FormElementTypes, FormLayoutTypes, IForm, } from 'atlas-form';
import {
  IPhonenumberSetupCostParentFormOptionsProps,
  PhonenumberSetupCostParentAdditionalParams as PhonenumberSetupCostParentAdditionalParamsBase,
} from './abstract/phonenumber_setup_cost_parent.form.abstract';
import { CountryFlagTitle, } from '@Utils/phone';

export * from './abstract/phonenumber_setup_cost_parent.form.abstract';

export const PhonenumberSetupCostParentAdditionalParams: (auth: IAuth, props: IPhonenumberSetupCostParentFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPhonenumberSetupCostParentFormOptionsProps) => ({
  ...PhonenumberSetupCostParentAdditionalParamsBase(auth, props),
  phonenumber_setup_cost_list: {
    ui_type: FormElementTypes.SECTION,
    child_params: {
      ui_type: FormElementTypes.SECTION,
      ui_layout: FormLayoutTypes.INLINE,
      labelRenderer: label => <CountryFlagTitle t={props.t} country={label as string} width={21} />,
    },
  },
});
