import { SelectProps, TreeSelect, } from 'antd';
import { IFormElement, } from '../atlas-form-core';
import { Icon, } from '@Components';
import { XIcon, } from '@Assets/icons';
import { optionsToTreeNode, } from './utils';
import { clone, IOption, } from '@Utils';
import * as React from 'react';
import { useSort, } from '@Hooks/use.sort';
import { useTranslation, } from 'react-i18next';
import { useEffect, useMemo, useState, } from 'react';

interface IProps {
  value?: any;
  options?: Array<IOption>;
  className?: string;
  dropdownClassName?: string;
  onChange: (value: any, option?: any) => void;
  style?: IFormElement['element_style'];
  placeholder?: string;
  multiple?: boolean;
  maxTagCount?: SelectProps<any>['maxTagCount'];
  fixedDropdown?: true;
  sortable?: boolean;
  allowClear?: boolean;
  treeDefaultExpandAll?: boolean;
  showSearch?: boolean;
  disabled?: boolean;
  keep_default_sorting?: boolean;
}

const sort = (a, b) => a.title < b.title ? -1 : 1;

function FormTreeSelect(props: IProps) {

  const { t, } = useTranslation();
  const [options, setOptions, ] = useState<Array<IOption>>([]);
  const {
    value,
    placeholder,
    onChange,
    multiple,
    treeDefaultExpandAll,
    allowClear,
    maxTagCount,
    style,
    disabled,
    sortable = true,
    showSearch = true,
    keep_default_sorting = false,
  } = props;
  const { onDragStart, onDragEnd, onDragOver, canDrop, } = useSort({
    dropElement: <span className="drop-element">{t('DROP_HERE')}</span>,
    value,
    onChange,
  });

  useEffect(() => {
    if (!props.options)
      return;

    if (keep_default_sorting) {
      setOptions(props.options);
      return;
    }

    setOptions(
      clone(props.options)
        .sort(sort)
        .map(g => {
          (g.children || [])
            .sort(sort)
            .map(g2 => {
              g2.children?.sort(sort);

              return g2;
            })
          ;

          return g;
        })
    );
  }, [props.options, ]);

  const status = useMemo(() => {
    if (multiple || typeof value !== 'string')
      return undefined;

    const tree = value.split('-');
    let base: any = options;
    let key_base: Array<string> = [];

    for (const id of tree) {
      key_base.push(id);
      const found = base?.find(option => !option.deleted && option.key === key_base.join('-'));

      if (!found)
        return 'error';

      base = found.children;
    }

  }, [value, options, ]);

  return <TreeSelect
    showSearch={showSearch}
    value={value}
    status={status}
    placeholder={placeholder}
    treeDefaultExpandAll={treeDefaultExpandAll}
    treeDefaultExpandedKeys={treeDefaultExpandAll ? undefined : [value, ]}
    allowClear={allowClear}
    onChange={onChange}
    multiple={multiple}
    maxTagCount={maxTagCount}
    style={style}
    dropdownMatchSelectWidth={false}
    dropdownClassName={props.dropdownClassName}
    className={props.className}
    disabled={disabled}
    filterTreeNode={(w, option) => ((option?.title || '') as string).toLowerCase().includes(w.toLowerCase())}
    tagRender={(props) => {
      const index = value.findIndex(v => v === props.value);

      return <div className='ant-select-selection-item-wrapper'>
        {canDrop(index, true)}
        <div
          title={typeof props.label === 'string' ? props.label + '' : undefined}
          onDragOver={() => onDragOver(index)}
          onMouseDown={e => e.stopPropagation()}
          draggable={sortable}
          onDragStart={e => onDragStart(e, index)}
          onDragEnd={onDragEnd}
          className={`ant-select-selection-item${sortable ? ' draggable' : ''}`}
        >
          <span className='ant-select-selection-item-content'>{props.label}</span>
          <Icon icon={XIcon} className='ant-select-selection-item-remove' onClick={e => props.onClose()} />
        </div>
        {canDrop(index, false)}
      </div>;
    }}
  >
    {options && optionsToTreeNode(options as Array<IOption>)}
  </TreeSelect>;
}

export default React.memo(FormTreeSelect);
