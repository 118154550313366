import { RestRequest, } from '@Utils';
import { IPreviewPhonenumberPurchase, ISharedPhonenumberPurchase, ISharedCreatePhonenumberPurchase, ISharedPatchPhonenumberPurchase, } from 'atlas-shared';

export const fetchPhonenumberPurchases = async (): Promise<Array<IPreviewPhonenumberPurchase>> => {
  return RestRequest.get<Array<IPreviewPhonenumberPurchase>>('phonenumber_purchase', { socket_id: global.socketId, });
};

export const fetchPhonenumberPurchase = async (id: IPreviewPhonenumberPurchase['id']): Promise<ISharedPhonenumberPurchase> => {
  return RestRequest.get<ISharedPhonenumberPurchase>(`phonenumber_purchase/${id}`, { socket_id: global.socketId, });
};

export const createPhonenumberPurchase = (payload: ISharedCreatePhonenumberPurchase) => {
  return RestRequest.post<any, ISharedPhonenumberPurchase>('phonenumber_purchase', payload, { socket_id: global.socketId, });
};

export const patchPhonenumberPurchase = (id: IPreviewPhonenumberPurchase['id'], payload: ISharedPatchPhonenumberPurchase) => {
  return RestRequest.patch<any, ISharedPhonenumberPurchase>(`phonenumber_purchase/${id}`, payload, { socket_id: global.socketId, });
};

export const getPhonenumberPurchaseUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_purchase/update/schema');
};

export const getPhonenumberPurchaseCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_purchase/create/schema');
};

export const getPhonenumberPurchasePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_purchase/patch/schema');
};
