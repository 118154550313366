import { useLocation, useParams, } from 'react-router-dom';
import React, { useCallback, useEffect, } from 'react';
import {
  actionSetFocusedConversation,
  actionSetHandlingConversation,
  IAuth,
  useAppDispatch,
} from '@Store';
import { useFocusedConversation, useHandlingConversation, useIsPrint, useTheme, } from '@Hooks';
import { patchUserStatus, } from '@Api';

export const HandlingFocusedConversationHandler = React.memo(({ children, auth, }: React.PropsWithChildren<{auth: IAuth}>) => {
  const isPrint = useIsPrint();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const focused_conversation = useFocusedConversation();
  const handling_conversation_id = useHandlingConversation();
  const setHandlingConversation = useCallback(() => {
    dispatch(actionSetHandlingConversation(location.pathname.startsWith('/dashboard') && params?.id ? +params.id : null));
  }, [location, params, ]);

  useEffect(() => {
    setHandlingConversation();
  }, [location, ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (handling_conversation_id !== auth.user_status.handling_conversation_id)
        patchUserStatus(auth.user.id, { handling_conversation_id, });
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [handling_conversation_id, ]);
  
  return <div className={`master-wrp ${theme} ${isPrint ? 'is-print' : ''}`} onClick={() => {
    setHandlingConversation();
    dispatch(actionSetFocusedConversation(null));
  }}>
    {children}
  </div>;

});
