import React, { useState, useEffect, PropsWithChildren, } from 'react';
import { useLocation, } from 'react-router-dom';

interface ILeftMenuProps {
  visible: boolean;
  setVisible: (flagActivator: boolean) => void;
}

export const AdminMenuAnimator = React.memo(({ visible, setVisible, children, }: PropsWithChildren<ILeftMenuProps>) => {
  const location = useLocation();

  const [isAdminPath, setIsAdminPath, ] = useState<boolean>(false);

  useEffect(() => {
    setIsAdminPath(location.pathname.split('/')?.[1] === 'admin');
  }, [location, ]);

  useEffect( () => {
    if (!isAdminPath)
      setVisible(false);
  }, [isAdminPath, setVisible, ]);
  return (
    <div id='admin-menu' className={[isAdminPath && 'fixed', (visible || isAdminPath) && 'active', ].join(' ')}>
      <div onClick={() => setVisible(false)} className='right-cover-layout' />
      {children}
    </div>
  );
});
