import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState, } from 'react';
import '../conversation.list/conversation.list.row/conversation.list.row.scss';
import './conversation.contact.suggestion.scss';
import { Timeline, Typography, Tag, Radio, Skeleton, } from 'antd';
import './conversation.messages.scss';
import {
  ISharedConversationWatcher,
  ISharedDraft,
  ISharedFullConversation,
  TypeConversationMessage,
  Undefinable,
  ISharedContact,
  IPreviewForm,
  ChannelEnum,
  ISharedVoiceCall,
  ISharedConversation,
  IPreviewTab,
  ConversationWatcherSourceEnum,
  ISharedMailMessage,
  IConversationTimeline,
} from 'atlas-shared';
import { AlertError, timeSince, TranslateAndDisplayPhoneNumber, } from '@Utils';
import { WarningIcon, XIcon, } from '@Assets/icons';
import { useTranslation, } from 'react-i18next';
import { useAuth, useHighlightedMessage, useIsMounted, } from '@Hooks';
import {
  actionSetConversationContact,
  useAppDispatch,
} from '@Store';
import {
  deleteConversationWatcher,
  fetchConversationContactSuggestions,
  fetchFullConversation,
  patchConversation,
} from '@Api';
import {
  ConversationMessage,
  ConversationDispositionNote,
} from './conversation.message';
import {
  Button,
  Icon,
  IconText,
  ScrollView,
  ChannelIcon,
  AppSpinner,
  ConversationFormPreview,
  ConversationMessageDraftTimeline,
  ChannelsIcon,
} from '@Components';
import { ConversationTimeline, } from '../components/conversation.timeline/conversation.timeline';
import debounce from 'lodash/debounce';

interface IProps {
  organization_id: ISharedConversation['organization_id'];
  conversation_id: ISharedConversation['id'];
  conversation?: ISharedFullConversation;
  source: ISharedConversationWatcher['source'];
  className?: string;
  header?: { onClose: ()=> void };
  form?: IPreviewForm;
  editConversationVisible?: boolean;
  setEditConversationVisible?: Dispatch<SetStateAction<boolean>>;
  tab?: IPreviewTab;
  isSecondary?: boolean;
  conversationTimeline?: Array<IConversationTimeline>;
  onConversationTimelineClose?: () => void;
  contactTimeline?: Array<IConversationTimeline>;
  onContactTimelineClose?: () => void;
}

export const ConversationMessages = React.memo((props: IProps) => {
  const {
    organization_id,
    conversation_id,
    className,
    header,
    form,
    conversationTimeline,
    onConversationTimelineClose,
    contactTimeline,
    onContactTimelineClose,
  } = props;
  const [conversation, setConversation, ] = useState<Undefinable<ISharedFullConversation>>(props.conversation);
  const { t, } = useTranslation();
  const [draftsByMessage, setDraftsByMessage, ] = useState<Record<string, Array<ISharedDraft>>>({});
  const [contactSuggestions, setContactSuggestions, ] = useState<Undefinable<Array<ISharedContact>>>();
  const [loadingContactSuggestions, setLoadingConversationSuggestions, ] = useState<boolean>(false);
  const [selectContact, setSelectContact, ] = useState<boolean>(false);
  const [selectedContact, setSelectedContact, ] = useState<Undefinable<ISharedContact>>();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const highlightedMessage = useHighlightedMessage(scrollRef);
  const isMounted = useIsMounted();
  const [scrolled, setScrolled, ] = useState<boolean>(false);

  const scrolling = useCallback(debounce(() => {
    setScrolled(true);
  }, 100), []);

  useEffect(() => {
    if (scrolled)
      setScrolled(false);
  }, [scrolled, ]);

  const { Text, } = Typography;

  useEffect(() => {
    return () => {
      if (isMounted.current)
        return;

      if (conversation && props.isSecondary)
        conversation.conversation_watchers?.forEach(conversation_watcher => {
          if (
            conversation_watcher.user_id === auth.user.id &&
            (
              (props.source === ConversationWatcherSourceEnum.Tab && conversation_watcher.source === ConversationWatcherSourceEnum.TabCustomerJourney)
              || (props.source === ConversationWatcherSourceEnum.Dashboard && conversation_watcher.source === ConversationWatcherSourceEnum.DashboardCustomerJourney)
            )
          )
            deleteConversationWatcher(conversation_watcher.id);
        });
    };
  }, [isMounted, conversation?.conversation_watchers, ]);

  useEffect(() => {
    if (!props.conversation && !conversation)
      fetchFullConversation(organization_id, conversation_id).then(conversation => {
        setConversation(conversation);
      });

    if (props.conversation)
      setConversation(props.conversation);

  }, [props.conversation, conversation, conversation_id, ]);

  useEffect(() => {
    if (conversation_id && conversation && conversation_id !== conversation.id)
      setConversation(undefined);
  }, [conversation, conversation_id, ]);

  useEffect(() => {
    const _draftsByMessage: Record<string, Array<ISharedDraft>> = {};

    [...(conversation?.drafts || []), ].reverse().forEach(draft => {
      const key = draft.reply_to || 'STANDALONE';

      if (!_draftsByMessage[key])
        _draftsByMessage[key] = [];

      _draftsByMessage[key].push(draft);
    });
    setDraftsByMessage(_draftsByMessage);
  }, [conversation?.drafts, ]);

  useEffect(() => {
    if (conversation && !conversation?.contact && !loadingContactSuggestions && selectContact && !contactSuggestions) {
      setLoadingConversationSuggestions(true);
      fetchConversationContactSuggestions(conversation.organization_id, conversation.id).then(contacts => {
        setContactSuggestions(contacts);
        setLoadingConversationSuggestions(false);
      }).catch(err => AlertError(t, { content: t(err), }));
    }
  }, [conversation?.id, conversation?.contact, selectContact, loadingContactSuggestions, contactSuggestions, t, ]);

  if (!conversation)
    return <div style={{ display: 'flex', width: '100%', flexDirection: 'column', }}>
      <Skeleton.Input active={true} size={'large'} />
      <div style={{ padding: 40, }}>
        {new Array(3).fill(null).map(_ => <div style={{ marginBottom: 20, opacity: .5, }}><Skeleton avatar active paragraph={{ rows: 3, }} /></div>)}
      </div>
    </div>;

  return (
    <div className={`conversation-messages-view${className ? ` ${className}` : ''}`}>
      {
        header &&
        <div className={'conversation-messages-header'}>
          <ChannelsIcon channels={conversation.channels} size={14} padding={5} max={3} />
          <span className={'title'}>
            {`#${conversation.id}`}
          </span>
          <small className={'date'}>
            {timeSince(t, new Date(conversation.created_at), true)}
          </small>
          <small className={'status'}>
            {t(`STATUS_${conversation.status}`)}
          </small>
          <Icon className={'close'} icon={XIcon} iconProps={{ size: 15, }} onClick={header.onClose}/>
        </div>
      }

      <ConversationFormPreview
        conversation={conversation}
        form={form}
        editConversationVisible={props.editConversationVisible}
        setEditConversationVisible={props.setEditConversationVisible}
      />

      {conversationTimeline && <ConversationTimeline
        t={t}
        conversation={conversation}
        timeline={conversationTimeline}
        onClose={onConversationTimelineClose}
      />}

      {contactTimeline && <ConversationTimeline
        t={t}
        conversation={conversation}
        timeline={contactTimeline}
        onClose={onContactTimelineClose}
      />}

      {!conversation.contact && <div className='conversation-contact-suggestion-wrapper'>
        {!conversation.queue_id && <div className='conversation-select-contact'>
          <IconText icon={WarningIcon} text={t('SELECT_QUEUE_TO_SELECT_CONTACT')} />
        </div>}
        {conversation.queue_id && !selectContact && <div className='conversation-select-contact'>
          <IconText icon={WarningIcon} text={t('CONTACT_NOT_AUTOMATICALLY_ASSIGNED')} />
          <Button onClick={() => setSelectContact(true)}>{t('SELECT_CONTACT')}</Button>
        </div>}
        {conversation.queue_id && contactSuggestions && selectContact && loadingContactSuggestions && <div className='conversation-select-suggestion-loading'><AppSpinner /></div>}
        {conversation.queue_id && contactSuggestions && selectContact && !loadingContactSuggestions && <div className='conversation-select-suggestion'>
          <IconText icon={WarningIcon} text={t('WHICH_ONE_DO_YOU_WANT_TO_USE')} />
          <div className='conversation-contact-suggestions'>
            {contactSuggestions.map(contact => <div key={contact.id} className={`conversation-contact-suggestion${selectedContact?.id === contact.id ? ' selected' : ''}`} onClick={() => setSelectedContact(contact) }>
              <Text strong><Radio checked={selectedContact?.id === contact.id} /> #{contact.id} - {contact.first_name} {contact.last_name}</Text>
              <Text><label>{t('LAST_UPDATED')}</label>: {timeSince(t, new Date(contact.updated_at), true)}</Text>
              {!!contact.emails?.length && <Text><label>{t('EMAILS')}</label>: {contact.emails.map(email => <Tag key={email}>{email}</Tag>)}</Text>}
              {!!contact.phones?.length && <Text><label>{t('PHONES')}</label>: {contact.phones.map((phone, index) => <Tag key={phone}><TranslateAndDisplayPhoneNumber t={t} phone_number={phone} key={index} /></Tag>)}</Text>}
            </div>)}
          </div>
          <Button type='primary' disabled={!selectedContact} onClick={() => {
            if (!selectedContact)
              return;

            return new Promise((resolve) => {
              patchConversation(conversation.organization_id, conversation.id, { contact_id: selectedContact.id, })
                .then(() => {
                  dispatch(actionSetConversationContact({
                    conversation_id: conversation.id,
                    contact: selectedContact,
                  }));
                })
                .finally(() => resolve(true))
              ;
            });
          }}>{t('SELECT_CONTACT')}</Button>
        </div>}
      </div>}

      <ScrollView scrollRef={scrollRef} onScroll={() => scrolling()}>
        <Timeline className={'conversation-messages-timeline'} style={{ display: 'flex', flexDirection: 'column', paddingBottom: 80, }}>
          {
            conversation.disposition_id && <Timeline.Item dot={<ChannelIcon channel={{ c: 'DispositionNote', }} size={14} padding={7}/>}>
              <ConversationDispositionNote conversation={conversation} />
            </Timeline.Item>
          }
          {
            (draftsByMessage['STANDALONE'] || []).map(draft => <ConversationMessageDraftTimeline
              key={`draft_${draft.id}`}
              conversation={conversation}
              conversationForm={form}
              draft={draft}
              editable={draft.user_id === auth.user.id}
              highlightedMessage={highlightedMessage}
              auth={auth}
            />)
          }
          {
            conversation.messages.map((message: TypeConversationMessage, index) => {
              const key: string = `${message.channel}-${message.id}`;

              return <React.Fragment key={key}>
                {
                  (draftsByMessage[key] || []).map(draft => <ConversationMessageDraftTimeline
                    key={`draft_${draft.id}`}
                    conversation={conversation}
                    conversationForm={form}
                    draft={draft}
                    editable={draft.user_id === auth.user.id}
                    highlightedMessage={highlightedMessage}
                    auth={auth}
                  />)
                }
                <Timeline.Item dot={<ChannelIcon channel={{ c: message.channel, d: (message as any)?.direction, live: message.channel === ChannelEnum.Voice && !(message as ISharedVoiceCall).end_time, auto_reply: (message as ISharedMailMessage).auto_reply || false, }} size={14} padding={7} />}>
                  <ConversationMessage
                    dispatch={dispatch}
                    t={t}
                    auth={auth}
                    index={index}
                    message={message}
                    conversation={conversation}
                    highlightedMessage={highlightedMessage}
                    tab={props.tab}
                    scrolled={scrolled}
                  />
                </Timeline.Item>
              </React.Fragment>;
            })
          }
        </Timeline>
      </ScrollView>
    </div>
  );
});
