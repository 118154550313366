import { IPreviewPrepaidCredit, } from 'atlas-shared';
import { IPrepaidCreditStore, PrepaidCreditActions, TPrepaidCreditActionTypes, } from '@Store';

export const PrepaidCreditReducer = (state: IPrepaidCreditStore = {
  loaded: false,
  loading: false,
  prepaid_credits: [],
  dict: {},
}, action: TPrepaidCreditActionTypes): IPrepaidCreditStore => {
  switch (action.type) {
  case PrepaidCreditActions.LOADING:
    return { ...state, loading: true, };
  case PrepaidCreditActions.SET_STATE:
    const dict: IPrepaidCreditStore['dict'] = {};

    action.payload.forEach((prepaid_credit: IPreviewPrepaidCredit) => dict[prepaid_credit.id] = prepaid_credit);

    return { ...state, loaded: true, loading: false, prepaid_credits: action.payload, dict, };
  case PrepaidCreditActions.ADD_STATE:
    return { ...state, prepaid_credits: [...state.prepaid_credits, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case PrepaidCreditActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, prepaid_credits: state.prepaid_credits.filter((item: IPreviewPrepaidCredit) => item.id !== action.payload), dict, };
  }
  case PrepaidCreditActions.UPDATE_STATE:
    const prepaid_credits = [...state.prepaid_credits, ];
    const index = state.prepaid_credits.findIndex((prepaid_credit: IPreviewPrepaidCredit) => prepaid_credit.id === action.payload.id);

    if (!~index)
      prepaid_credits.push(action.payload);
    else
      prepaid_credits[index] = action.payload;

    return { ...state, prepaid_credits, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
