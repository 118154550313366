import { fetchNotificationUsers, } from '@Api';
import {
  actionLoadingNotificationUsers,
  actionSetNotificationUsers,
} from './abstract/notification_user.actions.abstract';
import { ISharedAgentprofile, Nullable, } from 'atlas-shared';

export * from './abstract/notification_user.actions.abstract';

export const actionFetchNotificationUsers = (agentprofile_id?: Nullable<ISharedAgentprofile['id']>) => {
  return async (dispatch) => {
    dispatch(actionLoadingNotificationUsers());
    const notification_users = await fetchNotificationUsers(agentprofile_id);

    dispatch(actionSetNotificationUsers(notification_users));
    return notification_users;
  };
};
