import { IPreviewWallboard, } from 'atlas-shared';
import { IWallboardStore, WallboardActions, TWallboardActionTypes, } from '@Store';

export const WallboardReducer = (state: IWallboardStore = {
  loaded: false,
  loading: false,
  wallboards: [],
  dict: {},
}, action: TWallboardActionTypes): IWallboardStore => {
  switch (action.type) {
  case WallboardActions.LOADING:
    return { ...state, loading: true, };
  case WallboardActions.SET_STATE:
    const dict: IWallboardStore['dict'] = {};

    action.payload.forEach((wallboard: IPreviewWallboard) => dict[wallboard.id] = wallboard);

    return { ...state, loaded: true, loading: false, wallboards: action.payload, dict, };
  case WallboardActions.ADD_STATE:
    return { ...state, wallboards: [...state.wallboards, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case WallboardActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, wallboards: state.wallboards.filter((item: IPreviewWallboard) => item.id !== action.payload), dict, };
  }
  case WallboardActions.UPDATE_STATE:
    const wallboards = [...state.wallboards, ];
    const index = state.wallboards.findIndex((wallboard: IPreviewWallboard) => wallboard.id === action.payload.id);

    if (!~index)
      wallboards.push(action.payload);
    else
      wallboards[index] = action.payload;

    return { ...state, wallboards, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
