import { IPreviewField, } from 'atlas-shared';

export enum FieldActions {
  SET_STATE = 'FIELD_SET_STATE',
  ADD_STATE = 'FIELD_ADD_STATE',
  REMOVE_STATE = 'FIELD_REMOVE_STATE',
  GET_STATE = 'FIELD_GET_STATE',
  UPDATE_STATE = 'FIELD_UPDATE_STATE',
  LOADING = 'FIELD_LOADING',
}

export interface IFieldLoading {
  type: FieldActions.LOADING;
}

export interface IFieldSetAction {
  type: FieldActions.SET_STATE;
  payload: Array<IPreviewField>;
}

export interface IFieldUpdateAction {
  type: FieldActions.UPDATE_STATE;
  payload: IPreviewField;
}

export interface IFieldAddAction {
  type: FieldActions.ADD_STATE;
  payload: IPreviewField;
}

export interface IFieldRemoveAction {
  type: FieldActions.REMOVE_STATE;
  payload: IPreviewField['id'];
}

export interface IFieldStore {
  loaded: boolean;
  loading: boolean;
  fields: Array<IPreviewField>;
  dict: Record<IPreviewField['id'], IPreviewField>;
}

export type TFieldActionTypes = IFieldSetAction | IFieldUpdateAction | IFieldAddAction | IFieldRemoveAction | IFieldLoading;
