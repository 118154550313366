import React, { useCallback, useEffect, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions, } from 'atlas-form';
import {
  AdminprofileEditForm,
  AdminprofileEditInitialValues,
  AdminprofileComponents,
  AdminprofileAdditionalParams,
  AdminprofileFormOptions,
  onAdminprofileFormSaved,
  IAdminprofileFormOptionsProps,
  AlertError,
} from '@Utils';
import { ISharedAdminprofile, ISharedPatchAdminprofile, PermissionEnum, CrudEnum, } from 'atlas-shared';
import { fetchAdminprofile, patchAdminprofile, getAdminprofileUpdateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withEdit, IWithEditProps, } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useAdminprofiles, useAgentprofiles, useCannedAnswers, useClients, useDestinationRestrictors, useDispositions, useFields, useForms, useInvoices, useJourneies, useLists, useMailAccounts, useOperationalHourses, usePauses, useQueues, useSlaPolicies, useSignatures, useSkills, useSounds, useTags, useTeams, useTimeIntervals, useUsers, useVoiceRoutes, useVoiceShortcuts, useVoiceVoicemails, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithEditProps<ISharedAdminprofile> {}

const AdminprofileFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const adminprofiles = useAdminprofiles();
  const agentprofiles = useAgentprofiles();
  const canned_answers = useCannedAnswers();
  const clients = useClients();
  const destination_restrictors = useDestinationRestrictors();
  const dispositions = useDispositions();
  const fields = useFields();
  const forms = useForms();
  const invoices = useInvoices();
  const journeies = useJourneies();
  const lists = useLists();
  const mail_accounts = useMailAccounts();
  const operational_hourses = useOperationalHourses();
  const pauses = usePauses();
  const queues = useQueues();
  const sla_policies = useSlaPolicies();
  const signatures = useSignatures();
  const skills = useSkills();
  const sounds = useSounds();
  const tags = useTags();
  const teams = useTeams();
  const time_intervals = useTimeIntervals();
  const users = useUsers();
  const voice_routes = useVoiceRoutes();
  const voice_shortcuts = useVoiceShortcuts();
  const voice_voicemails = useVoiceVoicemails();

  useEffect(() => {
    fetchAdminprofile(id).then(adminprofile => setValue(adminprofile));
  }, [id, setValue, ]);

  const param_props: IAdminprofileFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    adminprofiles: adminprofiles.adminprofiles,
    agentprofiles: agentprofiles.agentprofiles,
    canned_answers: canned_answers.canned_answers,
    clients: clients.clients,
    destination_restrictors: destination_restrictors.destination_restrictors,
    dispositions: dispositions.dispositions,
    fields: fields.fields,
    forms: forms.forms,
    invoices: invoices.invoices,
    journeies: journeies.journeies,
    lists: lists.lists,
    mail_accounts: mail_accounts.mail_accounts,
    operational_hourses: operational_hourses.operational_hourses,
    pauses: pauses.pauses,
    queues: queues.queues,
    sla_policies: sla_policies.sla_policies,
    signatures: signatures.signatures,
    skills: skills.skills,
    sounds: sounds.sounds,
    tags: tags.tags,
    teams: teams.teams,
    time_intervals: time_intervals.time_intervals,
    users: users.users,
    voice_routes: voice_routes.voice_routes,
    voice_shortcuts: voice_shortcuts.voice_shortcuts,
    voice_voicemails: voice_voicemails.voice_voicemails,
  };
  const formLayout: IForm | undefined = useMemo(() => value && AdminprofileEditForm(value, param_props, t), [value, organizations, adminprofiles, agentprofiles, canned_answers, clients, destination_restrictors, dispositions, fields, forms, invoices, journeies, lists, mail_accounts, operational_hourses, pauses, queues, sla_policies, signatures, skills, sounds, tags, teams, time_intervals, users, voice_routes, voice_shortcuts, voice_voicemails, t, abilities, ]);
  const options: IFormOptions | undefined = useMemo(() => value && AdminprofileFormOptions(value, param_props, t), [value, organizations, adminprofiles, agentprofiles, canned_answers, clients, destination_restrictors, dispositions, fields, forms, invoices, journeies, lists, mail_accounts, operational_hourses, pauses, queues, sla_policies, signatures, skills, sounds, tags, teams, time_intervals, users, voice_routes, voice_shortcuts, voice_voicemails, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchAdminprofile, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchAdminprofile) => value && save(() => patchAdminprofile(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onAdminprofileFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [value, save, navigate, t, ]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !adminprofiles.loaded || !agentprofiles.loaded || !canned_answers.loaded || !clients.loaded || !destination_restrictors.loaded || !dispositions.loaded || !fields.loaded || !forms.loaded || !invoices.loaded || !journeies.loaded || !lists.loaded || !mail_accounts.loaded || !operational_hourses.loaded || !pauses.loaded || !queues.loaded || !sla_policies.loaded || !signatures.loaded || !skills.loaded || !sounds.loaded || !tags.loaded || !teams.loaded || !time_intervals.loaded || !users.loaded || !voice_routes.loaded || !voice_shortcuts.loaded || !voice_voicemails.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.Adminprofile, value)}
    >
      <AtlasForm<ISharedPatchAdminprofile>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={AdminprofileComponents}
        additionalParams={AdminprofileAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={AdminprofileEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('ADMINPROFILE')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const AdminprofileFormEditView = React.memo(withEdit(AdminprofileFormEdit, getAdminprofileUpdateSchema));
