import { IPreviewAudit, } from 'atlas-shared/dist';
import { humanReadableDateTime, } from '@Utils';
import { IAuditMap, } from './interface';

export const AUDIT_MAP: IAuditMap = {
  organization: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
    'invoice.data.payment_option': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_PAYMENT_OPTION_${v.toUpperCase()}`),
    }),
    'invoice.data.invoice_start': (item: IPreviewAudit) => ({
      title: 'INVOICE_START',
      render: (v, t, before) => humanReadableDateTime(v, true),
    }),
    'invoice.data.period': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_PERIOD_${v.toUpperCase()}`),
    }),
    'invoice.data.due_period.type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_DUE_PERIOD_${v.toUpperCase()}`),
    }),
    'invoice.currency': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
    'invoice.payment_info_template': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_PAYMENT_INFO_TEMPLATE_${v.toUpperCase()}`),
    }),
    'invoice.tariff_id': (item: IPreviewAudit) => ({
      link: `/admin/tariff/${item.changed_props.tariff_id.a}/edit`,
      title: 'TARIFF',
    }),
    'invoice.phonenumber_monthly_cost_id': (item: IPreviewAudit) => ({
      link: `/admin/phonenumber_monthly_cost/${item.changed_props.phonenumber_monthly_cost_id.a}/edit`,
      title: 'PHONENUMBER_MONTHLY_COST',
    }),
    'invoice.phonenumber_setup_cost_id': (item: IPreviewAudit) => ({
      link: `/admin/phonenumber_setup_cost/${item.changed_props.phonenumber_setup_cost_id.a}/edit`,
      title: 'PHONENUMBER_SETUP_COST',
    }),
    'invoice.dashboard_license_type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ORGANIZATION_DASHBOARD_REPORTING_TYPE_${v.toUpperCase()}`),
    }),
    'invoice.reporting_license_type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ORGANIZATION_DASHBOARD_REPORTING_TYPE_${v.toUpperCase()}`),
    }),
    parent_voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.parent_voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
    invoice_organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.invoice_organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    reduced_dashboard_license_type: (item: IPreviewAudit) => ({
      title: 'DASHBOARD_LICENSE_TYPE',
      render: (v, t, before) => v && t(`ORGANIZATION_DASHBOARD_REPORTING_TYPE_${v.toUpperCase()}`),
    }),
    reduced_reporting_license_type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ORGANIZATION_DASHBOARD_REPORTING_TYPE_${v.toUpperCase()}`),
    }),
  },
  list: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    form_id: (item: IPreviewAudit) => ({
      link: `/admin/form/${item.changed_props.form_id.a}/edit`,
      title: 'FORM',
    }),
  },
  list_file: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  form: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`FORM_TYPE_${v.toUpperCase()}`),
    }),
  },
  field: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`FIELD_TYPE_${v.toUpperCase()}`),
    }),
    'settings.ui_type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`FORM_ELEMENT_TYPE_EXPOSED_${v.toUpperCase()}`),
    }),
    'settings.validation.child_type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`FORM_ELEMENT_CHILD_TYPE_EXPOSED_${v.toUpperCase()}`),
    }),
  },
  contact: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  form_field: {
    form_id: (item: IPreviewAudit) => ({
      link: `/admin/form/${item.changed_props.form_id.a}/edit`,
      title: 'FORM',
    }),
    field_id: (item: IPreviewAudit) => ({
      link: `/admin/field/${item.changed_props.field_id.a}/edit`,
      title: 'FIELD',
    }),
  },
  list_contact: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    list_id: (item: IPreviewAudit) => ({
      link: `/admin/list/${item.changed_props.list_id.a}/edit`,
      title: 'LIST',
    }),
    contact_id: (item: IPreviewAudit) => ({
      title: 'CONTACT',
    }),
  },
  team: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  user_organization: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    role: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ROLE_${v.toUpperCase()}`),
    }),
    adminprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/adminprofile/${item.changed_props.adminprofile_id.a}/edit`,
      title: 'ADMINPROFILE',
    }),
    superadmin_profile_id: (item: IPreviewAudit) => ({
      link: `/admin/adminprofile/${item.changed_props.superadmin_profile_id.a}/edit`,
      title: 'ADMINPROFILE',
    }),
  },
  adminprofile: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  mail_account: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    inbound_journey_id: (item: IPreviewAudit) => ({
      link: `/admin/journey/${item.changed_props.inbound_journey_id.a}/edit`,
      title: 'JOURNEY',
    }),
    outbound_journey_id: (item: IPreviewAudit) => ({
      link: `/admin/journey/${item.changed_props.outbound_journey_id.a}/edit`,
      title: 'JOURNEY',
    }),
    imap_state: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`MAIL_ACCOUNT_IMAP_STATE_${v.toUpperCase()}`),
    }),
    smtp_state: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`MAIL_ACCOUNT_SMTP_STATE_${v.toUpperCase()}`),
    }),
    'imap.strategy': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`MAIL_ACCOUNT_AUTH_STRATEGY_${v.toUpperCase()}`),
    }),
    'smtp.strategy': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`MAIL_ACCOUNT_AUTH_STRATEGY_${v.toUpperCase()}`),
    }),
  },
  internal_note: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.queue_id.a}/edit`,
      title: 'USER',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
  },
  mail_thread: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    mail_account_id: (item: IPreviewAudit) => ({
      link: `/admin/mail_account/${item.changed_props.mail_account_id.a}/edit`,
      title: 'MAIL_ACCOUNT',
    }),
  },
  mail_message: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    mail_thread_id: (item: IPreviewAudit) => ({
      link: `/admin/mail_thread/${item.changed_props.mail_thread_id.a}/edit`,
      title: 'MAIL_THREAD',
    }),
    mail_account_id: (item: IPreviewAudit) => ({
      link: `/admin/mail_account/${item.changed_props.mail_account_id.a}/edit`,
      title: 'MAIL_ACCOUNT',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
  },
  queue: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    create_list_id: (item: IPreviewAudit) => ({
      link: `/admin/list/${item.changed_props.create_list_id.a}/edit`,
      title: 'LIST',
    }),
    conversation_form_id: (item: IPreviewAudit) => ({
      link: `/admin/form/${item.changed_props.conversation_form_id.a}/edit`,
      title: 'FORM',
    }),
    inbound_sla_policy_id: (item: IPreviewAudit) => ({
      link: `/admin/sla_policy/${item.changed_props.inbound_sla_policy_id.a}/edit`,
      title: 'SLA_POLICY',
    }),
    outbound_sla_policy_id: (item: IPreviewAudit) => ({
      link: `/admin/sla_policy/${item.changed_props.outbound_sla_policy_id.a}/edit`,
      title: 'SLA_POLICY',
    }),
    'settings.conversation_assignment': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CONVERSATION_ASSIGNMENT_${v.toUpperCase()}`),
    }),
    'mail_settings.strategy': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`QUEUE_STRATEGY_${v.toUpperCase()}`),
    }),
    'voice_settings.strategy': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_QUEUE_STRATEGY_${v.toUpperCase()}`),
    }),
    'voice_settings.recording': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_RECORDING_FORMAT_${v.toUpperCase()}`),
    }),
    'voice_settings.musicclass': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ASTERISK_MUSICCLASS_${v.toUpperCase()}`),
    }),
    'voice_settings.autopause': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ASTERISK_AUTOPAUSE_${v.toUpperCase()}`),
    }),
    'voice_settings.announce': (item: IPreviewAudit) => ({
      link: `/admin/sound/${item.changed_props.announce.a}/edit`,
      title: 'SOUND',
    }),
    'voice_settings.announce_holdtime': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ASTERISK_ANNOUNCE_HOLDTIME_${v.toUpperCase()}`),
    }),
    'voice_settings.announce_position': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ASTERISK_ANNOUNCE_POSITION_${v.toUpperCase()}`),
    }),
  },
  disposition: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    disposition_id: (item: IPreviewAudit) => ({
      link: `/admin/disposition/${item.changed_props.disposition_id.a}/edit`,
      title: 'DISPOSITION',
    }),
  },
  queue_outbound_mail_account: {
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    mail_account_id: (item: IPreviewAudit) => ({
      link: `/admin/mail_account/${item.changed_props.mail_account_id.a}/edit`,
      title: 'MAIL_ACCOUNT',
    }),
  },
  queue_outbound_voice_route: {
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    voice_route_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_route/${item.changed_props.voice_route_id.a}/edit`,
      title: 'VOICE_ROUTE',
    }),
  },
  queue_disposition: {
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    disposition_id: (item: IPreviewAudit) => ({
      link: `/admin/disposition/${item.changed_props.disposition_id.a}/edit`,
      title: 'DISPOSITION',
    }),
  },
  queue_canned_answer: {
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    canned_answer_id: (item: IPreviewAudit) => ({
      link: `/admin/canned_answer/${item.changed_props.canned_answer_id.a}/edit`,
      title: 'CANNED_ANSWER',
    }),
  },
  queue_signature: {
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    signature_id: (item: IPreviewAudit) => ({
      link: `/admin/signature/${item.changed_props.signature_id.a}/edit`,
      title: 'SIGNATURE',
    }),
  },
  queue_tag: {
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    tag_id: (item: IPreviewAudit) => ({
      link: `/admin/tag/${item.changed_props.tag_id.a}/edit`,
      title: 'TAG',
    }),
  },
  pause: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  canned_answer: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CANNED_ANSWER_CHANNEL_${v.toUpperCase()}`),
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CANNED_ANSWER_TYPE_${v.toUpperCase()}`),
    }),
  },
  signature: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CANNED_ANSWER_CHANNEL_${v.toUpperCase()}`),
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SIGNATURE_TYPE_${v.toUpperCase()}`),
    }),
  },
  refresh_token: {
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
  },
  user_status: {
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    online: (item: IPreviewAudit) => ({
      title: item.changed_props.online.b === null ? 'SIGN_IN' : 'SIGN_OUT',
      no_tooltip: true,
    }),
    handling_conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    'status_info.--key--.c': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CHANNEL_${v.toUpperCase()}`),
    }),
    'status_info.--key--.d': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    'status_info.--key--.u': (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.u.a}/edit`,
      title: 'USER',
    }),
    acw_conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
  },
  user: {
    login_strategy: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`LOGIN_STRATEGY_${v.toUpperCase()}`),
    }),
    login_pause: (item: IPreviewAudit) => ({
      link: `/admin/pause/${item.changed_props.login_pause.a}/edit`,
      title: 'PAUSE',
    }),
  },
  agentprofile: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    'settings.play_recording': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`PERMISSION_SELF_${v.toUpperCase()}`),
    }),
    'settings.download_recording': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`PERMISSION_SELF_${v.toUpperCase()}`),
    }),
  },
  audit: {
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    action: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`AUDIT_ACTION_${v.toUpperCase()}`),
    }),
    table: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`TABLE_${v.toUpperCase()}`),
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    payload_user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.payload_user_id.a}/edit`,
      title: 'USER',
    }),
    severity: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`AUDIT_SEVERITY_${v.toUpperCase()}`),
    }),
  },
  agentprofile_pause: {
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
    pause_id: (item: IPreviewAudit) => ({
      link: `/admin/pause/${item.changed_props.pause_id.a}/edit`,
      title: 'PAUSE',
    }),
  },
  agentprofile_queue: {
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
  },
  agentprofile_team: {
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
    team_id: (item: IPreviewAudit) => ({
      link: `/admin/team/${item.changed_props.team_id.a}/edit`,
      title: 'TEAM',
    }),
  },
  team_user: {
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
  },
  conversation: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    main_channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CHANNEL_${v.toUpperCase()}`),
    }),
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    mail_account_id: (item: IPreviewAudit) => ({
      link: `/admin/mail_account/${item.changed_props.mail_account_id.a}/edit`,
      title: 'MAIL_ACCOUNT',
    }),
    contact_id: (item: IPreviewAudit) => ({
      title: 'CONTACT',
    }),
    contact_email: (item: IPreviewAudit) => ({
      title: 'MAIL_MESSAGE',
    }),
    body: (item: IPreviewAudit) => ({
      title: 'DRAFT',
    }),
    signature_id: (item: IPreviewAudit) => ({
      link: `/admin/signature/${item.changed_props.signature_id.a}/edit`,
      title: 'SIGNATURE',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    journey_id: (item: IPreviewAudit) => ({
      link: `/admin/journey/${item.changed_props.journey_id.a}/edit`,
      title: 'JOURNEY',
    }),
    disposition_id: (item: IPreviewAudit) => ({
      link: `/admin/disposition/${item.changed_props.disposition_id.a}/edit`,
      title: 'DISPOSITION',
    }),
    sub_disposition_id: (item: IPreviewAudit) => ({
      link: `/admin/disposition/${item.changed_props.sub_disposition_id.a}/edit`,
      title: 'DISPOSITION',
    }),
    thrd_disposition_id: (item: IPreviewAudit) => ({
      link: `/admin/disposition/${item.changed_props.thrd_disposition_id.a}/edit`,
      title: 'DISPOSITION',
    }),
    status: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CONVERSATION_STATUS_${v.toUpperCase()}`),
    }),
    flag: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CONVERSATION_FLAG_${v.toUpperCase()}`),
    }),
    sla_policy_id: (item: IPreviewAudit) => ({
      link: `/admin/sla_policy/${item.changed_props.sla_policy_id.a}/edit`,
      title: 'SLA_POLICY',
    }),
    notification_id: (item: IPreviewAudit) => ({
      title: 'NOTIFICATION',
    }),
    priority: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CONVERSATION_PRIORITY_${v.toUpperCase()}`),
    }),
    direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    last_message_by: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CONVERSATION_LAST_MESSAGE_BY_${v.toUpperCase()}`),
    }),
  },
  conversation_favourite: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
  },
  tag: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  conversation_tag: {
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    tag_id: (item: IPreviewAudit) => ({
      link: `/admin/tag/${item.changed_props.tag_id.a}/edit`,
      title: 'TAG',
    }),
  },
  conversation_watcher: {
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    source: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CONVERSATION_WATCHER_SOURCE_${v.toUpperCase()}`),
    }),
  },
  journey: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    'payload.nodes.--key--.action': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`JOURNEY_ACTION_${v.toUpperCase()}`),
    }),
    'payload.nodes.--key--.type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`JOURNEY_NODE_DIRECTION_${v.toUpperCase()}`),
    }),
    'payload.nodes.--key--.ports.--key--.type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`JOURNEY_PORT_DIRECTION_${v.toUpperCase()}`),
    }),
  },
  journey_draft: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    journey_id: (item: IPreviewAudit) => ({
      link: `/admin/journey/${item.changed_props.journey_id.a}/edit`,
      title: 'JOURNEY',
    }),
    'payload.nodes.--key--.action': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`JOURNEY_ACTION_${v.toUpperCase()}`),
    }),
    'payload.nodes.--key--.type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`JOURNEY_NODE_DIRECTION_${v.toUpperCase()}`),
    }),
    'payload.nodes.--key--.ports.--key--.type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`JOURNEY_PORT_DIRECTION_${v.toUpperCase()}`),
    }),
  },
  journey_trigger: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    journey_id: (item: IPreviewAudit) => ({
      link: `/admin/journey/${item.changed_props.journey_id.a}/edit`,
      title: 'JOURNEY',
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`JOURNEY_ACTION_TRIGGER_TYPE_${v.toUpperCase()}`),
    }),
    'since.source': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`JOURNEY_WHEN_SINCE_SOURCE_${v.toUpperCase()}`),
    }),
    'since.since.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
  },
  journey_job: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    journey_id: (item: IPreviewAudit) => ({
      link: `/admin/journey/${item.changed_props.journey_id.a}/edit`,
      title: 'JOURNEY',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    journey_trigger_id: (item: IPreviewAudit) => ({
      title: 'JOURNEY_TRIGGER',
    }),
    channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CHANNEL_${v.toUpperCase()}`),
    }),
    action: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`JOURNEY_ACTION_${v.toUpperCase()}`),
    }),
  },
  conversation_filter: {
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
    'sort_by.column': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CONVERSATION_FILTER_SORT_COLUMN_${v.toUpperCase()}`),
    }),
    'sort_by.order': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CONVERSATION_FILTER_SORT_ORDER_${v.toUpperCase()}`),
    }),
  },
  notification: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CHANNEL_${v.toUpperCase()}`),
    }),
    direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    asterisk_unique_id: (item: IPreviewAudit) => ({
      title: 'VOICE_CALL',
    }),
    subject: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`NOTIFICATION_SUBJECT_${v.toUpperCase()}`),
    }),
  },
  notification_user: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    notification_id: (item: IPreviewAudit) => ({
      title: 'NOTIFICATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    action: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`NOTIFICATION_ACTION_${v.toUpperCase()}`),
    }),
  },
  tab: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    main_channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CHANNEL_${v.toUpperCase()}`),
    }),
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
    size: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`TAB_SIZE_${v.toUpperCase()}`),
    }),
  },
  time_interval: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  operational_hours: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  operational_hours_time_interval: {
    operational_hours_id: (item: IPreviewAudit) => ({
      link: `/admin/operational_hours/${item.changed_props.operational_hours_id.a}/edit`,
      title: 'OPERATIONAL_HOURS',
    }),
    time_interval_id: (item: IPreviewAudit) => ({
      link: `/admin/time_interval/${item.changed_props.time_interval_id.a}/edit`,
      title: 'TIME_INTERVAL',
    }),
  },
  sla_policy: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    'policy.U.first_response.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.U.every_response.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.U.resolution_time.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.U.operational_hours_id': (item: IPreviewAudit) => ({
      link: `/admin/operational_hours/${item.changed_props.operational_hours_id.a}/edit`,
      title: 'OPERATIONAL_HOURS',
    }),
    'policy.H.first_response.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.H.every_response.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.H.resolution_time.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.H.operational_hours_id': (item: IPreviewAudit) => ({
      link: `/admin/operational_hours/${item.changed_props.operational_hours_id.a}/edit`,
      title: 'OPERATIONAL_HOURS',
    }),
    'policy.M.first_response.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.M.every_response.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.M.resolution_time.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.M.operational_hours_id': (item: IPreviewAudit) => ({
      link: `/admin/operational_hours/${item.changed_props.operational_hours_id.a}/edit`,
      title: 'OPERATIONAL_HOURS',
    }),
    'policy.L.first_response.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.L.every_response.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.L.resolution_time.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SLA_TIME_UNIT_${v.toUpperCase()}`),
    }),
    'policy.L.operational_hours_id': (item: IPreviewAudit) => ({
      link: `/admin/operational_hours/${item.changed_props.operational_hours_id.a}/edit`,
      title: 'OPERATIONAL_HOURS',
    }),
  },
  recently_searched: {
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
    section: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SEARCH_SECTION_${v.toUpperCase()}`),
    }),
  },
  recently_viewed_conversation: {
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    source: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`RECENTLY_VIEWED_SOURCE_${v.toUpperCase()}`),
    }),
  },
  session: {
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`SESSION_TYPE_${v.toUpperCase()}`),
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
  },
  draft: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    signature_id: (item: IPreviewAudit) => ({
      link: `/admin/signature/${item.changed_props.signature_id.a}/edit`,
      title: 'SIGNATURE',
    }),
    channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DRAFT_CHANNEL_${v.toUpperCase()}`),
    }),
  },
  form_website: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    journey_id: (item: IPreviewAudit) => ({
      link: `/admin/journey/${item.changed_props.journey_id.a}/edit`,
      title: 'JOURNEY',
    }),
    form_id: (item: IPreviewAudit) => ({
      link: `/admin/form/${item.changed_props.form_id.a}/edit`,
      title: 'FORM',
    }),
  },
  form_submission: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    form_website_id: (item: IPreviewAudit) => ({
      link: `/admin/form_website/${item.changed_props.form_website_id.a}/edit`,
      title: 'FORM_WEBSITE',
    }),
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
  },
  voice_route: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
    inbound_voice_trunk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_trunk/${item.changed_props.inbound_voice_trunk_id.a}/edit`,
      title: 'VOICE_TRUNK',
    }),
    outbound_voice_trunk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_trunk/${item.changed_props.outbound_voice_trunk_id.a}/edit`,
      title: 'VOICE_TRUNK',
    }),
    inbound_journey_id: (item: IPreviewAudit) => ({
      link: `/admin/journey/${item.changed_props.inbound_journey_id.a}/edit`,
      title: 'JOURNEY',
    }),
    outbound_journey_id: (item: IPreviewAudit) => ({
      link: `/admin/journey/${item.changed_props.outbound_journey_id.a}/edit`,
      title: 'JOURNEY',
    }),
  },
  voice_chanspy: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
    voice_asterisk_downgrade_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk_downgrade/${item.changed_props.voice_asterisk_downgrade_id.a}/edit`,
      title: 'VOICE_ASTERISK_DOWNGRADE',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    spyee_user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    spyee_agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.spyee_agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
  },
  voice_chanspy_call: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
    voice_asterisk_downgrade_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk_downgrade/${item.changed_props.voice_asterisk_downgrade_id.a}/edit`,
      title: 'VOICE_ASTERISK_DOWNGRADE',
    }),
    voice_chanspy_id: (item: IPreviewAudit) => ({
      title: 'VOICE_CHANSPY',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    voice_call_id: (item: IPreviewAudit) => ({
      title: 'VOICE_CALL',
    }),
  },
  voice_voicemail: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
  },
  voice_voicemail_message: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    voice_voicemail_id: (item: IPreviewAudit) => ({
      title: 'VOICE_VOICEMAIL',
    }),
    voice_asterisk_downgrade_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk_downgrade/${item.changed_props.voice_asterisk_downgrade_id.a}/edit`,
      title: 'VOICE_ASTERISK_DOWNGRADE',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
    voice_call_id: (item: IPreviewAudit) => ({
      title: 'VOICE_CALL',
    }),
  },
  voice_internal_call: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
    voice_asterisk_downgrade_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk_downgrade/${item.changed_props.voice_asterisk_downgrade_id.a}/edit`,
      title: 'VOICE_ASTERISK_DOWNGRADE',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    callee_user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.callee_user_id.a}/edit`,
      title: 'USER',
    }),
  },
  voice_call_bridge: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    voice_call_id: (item: IPreviewAudit) => ({
      title: 'VOICE_CALL',
    }),
    direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_CALL_TYPE_${v.toUpperCase()}`),
    }),
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    callee_user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.callee_user_id.a}/edit`,
      title: 'USER',
    }),
    exit_reason: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_CALL_BRIDGE_EXIT_REASON_${v.toUpperCase()}`),
    }),
    transferred_to: (item: IPreviewAudit) => ({
      title: 'VOICE_CALL_BRIDGE',
    }),
    transferred_from: (item: IPreviewAudit) => ({
      title: 'VOICE_CALL_BRIDGE',
    }),
    transfer_type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_CALL_TRANSFER_TYPE_${v.toUpperCase()}`),
    }),
    tariff_id: (item: IPreviewAudit) => ({
      link: `/admin/tariff/${item.changed_props.tariff_id.a}/edit`,
      title: 'TARIFF',
    }),
    twin_tariff_id: (item: IPreviewAudit) => ({
      link: `/admin/tariff/${item.changed_props.twin_tariff_id.a}/edit`,
      title: 'TARIFF',
    }),
  },
  voice_call: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_CALL_TYPE_${v.toUpperCase()}`),
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    queue_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.queue_id.a}/edit`,
      title: 'QUEUE',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/queue/${item.changed_props.user_id.a}/edit`,
      title: 'QUEUE',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
    voice_asterisk_downgrade_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk_downgrade/${item.changed_props.voice_asterisk_downgrade_id.a}/edit`,
      title: 'VOICE_ASTERISK_DOWNGRADE',
    }),
    voice_route_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_route/${item.changed_props.voice_route_id.a}/edit`,
      title: 'VOICE_ROUTE',
    }),
    tariff_id: (item: IPreviewAudit) => ({
      link: `/admin/tariff/${item.changed_props.tariff_id.a}/edit`,
      title: 'TARIFF',
    }),
  },
  voice_asterisk: {
    status: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ASTERISK_STATUS_${v.toUpperCase()}`),
    }),
    voice_asterisk_downgrade_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk_downgrade/${item.changed_props.voice_asterisk_downgrade_id.a}/edit`,
      title: 'VOICE_ASTERISK_DOWNGRADE',
    }),
  },
  voice_asterisk_downgrade: {
    status: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`ASTERISK_STATUS_${v.toUpperCase()}`),
    }),
  },
  voice_asterisk_operational_hours: {
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
  },
  voice_asterisk_toggle_history: {
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
  },
  voice_trunk: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`TRUNK_TYPE_${v.toUpperCase()}`),
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
  },
  voice_shortcut: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  prepaid_credit: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    currency: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
  },
  invoice: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    currency: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    conversation_organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.conversation_organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  invoice_post: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    invoice_id: (item: IPreviewAudit) => ({
      link: `/admin/invoice/${item.changed_props.invoice_id.a}/edit`,
      title: 'INVOICE',
    }),
    nav_article_id: (item: IPreviewAudit) => ({
      link: `/admin/nav_article/${item.changed_props.nav_article_id.a}/edit`,
      title: 'NAV_ARTICLE',
    }),
    currency: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_POST_TYPE_${v.toUpperCase()}`),
    }),
    amount_type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_POST_AMOUNT_TYPE_${v.toUpperCase()}`),
    }),
  },
  license_history: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    license: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`LICENSE_TYPE_${v.toUpperCase()}`),
    }),
  },
  tariff_parent: {
    currency: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
    voice_tariff_roundup_strategy: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_TARIFF_ROUNDUP_STRATEGY_${v.toUpperCase()}`),
    }),
    voice_usage_roundup_strategy: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_TARIFF_ROUNDUP_STRATEGY_${v.toUpperCase()}`),
    }),
  },
  tariff: {
    tariff_parent_id: (item: IPreviewAudit) => ({
      link: `/admin/tariff_parent/${item.changed_props.tariff_parent_id.a}/edit`,
      title: 'TARIFF_PARENT',
    }),
    currency: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
    voice_tariff_roundup_strategy: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_TARIFF_ROUNDUP_STRATEGY_${v.toUpperCase()}`),
    }),
    voice_usage_roundup_strategy: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_TARIFF_ROUNDUP_STRATEGY_${v.toUpperCase()}`),
    }),
    '__reduced_tariffs.voice_tariff_roundup_strategy': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_TARIFF_ROUNDUP_STRATEGY_${v.toUpperCase()}`),
    }),
    '__reduced_tariffs.voice_usage_roundup_strategy': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOICE_TARIFF_ROUNDUP_STRATEGY_${v.toUpperCase()}`),
    }),
  },
  phonenumber_monthly_cost_parent: {
    currency: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
  },
  phonenumber_monthly_cost: {
    phonenumber_monthly_cost_parent_id: (item: IPreviewAudit) => ({
      link: `/admin/phonenumber_monthly_cost_parent/${item.changed_props.phonenumber_monthly_cost_parent_id.a}/edit`,
      title: 'PHONENUMBER_MONTHLY_COST_PARENT',
    }),
    currency: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
  },
  phonenumber_setup_cost_parent: {
    currency: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
  },
  phonenumber_setup_cost: {
    phonenumber_setup_cost_parent_id: (item: IPreviewAudit) => ({
      link: `/admin/phonenumber_setup_cost_parent/${item.changed_props.phonenumber_setup_cost_parent_id.a}/edit`,
      title: 'PHONENUMBER_SETUP_COST_PARENT',
    }),
    currency: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`INVOICE_CURRENCY_${v.toUpperCase()}`),
    }),
  },
  phonenumber_prefix: {
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`PHONENUMBER_DID_TYPE_${v.toUpperCase()}`),
    }),
    'regulation.addressType': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`VOXBONE_REGULATION_ADDRESS_TYPE_${v.toUpperCase()}`),
    }),
  },
  phonenumber_stock: {
    provider: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`PHONENUMBER_PROVIDER_${v.toUpperCase()}`),
    }),
    phonenumber_prefix_id: (item: IPreviewAudit) => ({
      link: `/admin/phonenumber_prefix/${item.changed_props.phonenumber_prefix_id.a}/edit`,
      title: 'PHONENUMBER_PREFIX',
    }),
  },
  phonenumber_purchase: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    provider: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`PHONENUMBER_PROVIDER_${v.toUpperCase()}`),
    }),
    status: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`PHONENUMBER_PURCHASE_STATUS_${v.toUpperCase()}`),
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    voice_route_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_route/${item.changed_props.voice_route_id.a}/edit`,
      title: 'VOICE_ROUTE',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
      link: `/dashboard/conversation/${item.changed_props.conversation_organization_id.a}/${item.changed_props.conversation_id.a}`,
    }),
    conversation_organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.conversation_organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  destination_restrictor: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  agentprofile_voice_shortcut: {
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
    voice_shortcut_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_shortcut/${item.changed_props.voice_shortcut_id.a}/edit`,
      title: 'VOICE_SHORTCUT',
    }),
  },
  journey_variable: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
  sound: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
  },
  voice_recording: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    voice_asterisk_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk/${item.changed_props.voice_asterisk_id.a}/edit`,
      title: 'VOICE_ASTERISK',
    }),
    voice_asterisk_downgrade_id: (item: IPreviewAudit) => ({
      link: `/admin/voice_asterisk_downgrade/${item.changed_props.voice_asterisk_downgrade_id.a}/edit`,
      title: 'VOICE_ASTERISK_DOWNGRADE',
    }),
    conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    voice_call_id: (item: IPreviewAudit) => ({
      title: 'VOICE_CALL',
    }),
    voice_call_bridge_id: (item: IPreviewAudit) => ({
      title: 'VOICE_CALL_BRIDGE',
    }),
  },
  stats_custom_metric: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    query_type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`METRIC_QUERY_TYPE_${v.toUpperCase()}`),
    }),
    unit_type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`METRIC_UNIT_TYPE_${v.toUpperCase()}`),
    }),
  },
  stats_report: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
  },
  stats_widget: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    type: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`STATS_WIDGET_TYPE_${v.toUpperCase()}`),
    }),
    'settings.period.type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`STATS_WIDGET_PERIOD_${v.toUpperCase()}`),
    }),
    'settings.trend.adjust.unit': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`STATS_WIDGET_TRENDS_ADJUST_UNIT_${v.toUpperCase()}`),
    }),
    'settings.trend.text_indicator': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`STATS_WIDGET_TABLE_TREND_INDICATOR_${v.toUpperCase()}`),
    }),
    'settings.filter.row.type': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CONDITION_AND_OR_${v.toUpperCase()}`),
    }),
    'settings.ui.legend.placement': (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`STATS_WIDGET_LEGEND_PLACEMENT_${v.toUpperCase()}`),
    }),
  },
  stats_report_widget: {
    stats_report_id: (item: IPreviewAudit) => ({
      link: `/admin/stats_report/${item.changed_props.stats_report_id.a}/edit`,
      title: 'STATS_REPORT',
    }),
    stats_widget_id: (item: IPreviewAudit) => ({
      link: `/admin/stats_widget/${item.changed_props.stats_widget_id.a}/edit`,
      title: 'STATS_WIDGET',
    }),
  },
  stats_report_preview_widget: {
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    stats_report_id: (item: IPreviewAudit) => ({
      link: `/admin/stats_report/${item.changed_props.stats_report_id.a}/edit`,
      title: 'STATS_REPORT',
    }),
    stats_widget_id: (item: IPreviewAudit) => ({
      link: `/admin/stats_widget/${item.changed_props.stats_widget_id.a}/edit`,
      title: 'STATS_WIDGET',
    }),
  },
  user_activity: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    user_id: (item: IPreviewAudit) => ({
      link: `/admin/user/${item.changed_props.user_id.a}/edit`,
      title: 'USER',
    }),
    agentprofile_id: (item: IPreviewAudit) => ({
      link: `/admin/agentprofile/${item.changed_props.agentprofile_id.a}/edit`,
      title: 'AGENTPROFILE',
    }),
    handling_conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    acw_conversation_id: (item: IPreviewAudit) => ({
      title: 'CONVERSATION',
    }),
    pause_id: (item: IPreviewAudit) => ({
      link: `/admin/pause/${item.changed_props.pause_id.a}/edit`,
      title: 'PAUSE',
    }),
  },
  stats_channel: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    main_channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CHANNEL_${v.toUpperCase()}`),
    }),
    direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    thread_direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    message_channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CHANNEL_WITH_NONE_${v.toUpperCase()}`),
    }),
    message_direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
  },
  stats_channel_live: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
    main_channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CHANNEL_${v.toUpperCase()}`),
    }),
    direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    thread_direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
    message_channel: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`CHANNEL_WITH_NONE_${v.toUpperCase()}`),
    }),
    message_direction: (item: IPreviewAudit) => ({
      render: (v, t, before) => v && t(`DIRECTION_${v.toUpperCase()}`),
    }),
  },
  stats_user: {
    organization_id: (item: IPreviewAudit) => ({
      link: `/admin/organization/${item.changed_props.organization_id.a}/edit`,
      title: 'ORGANIZATION',
    }),
  },
};
