import React, { useState, } from 'react';
import './customer.journey.list.scss';
import { Timeline, } from 'antd';
import { ISharedFullConversation, IPreviewContactCustomEvent, ISharedCustomerJourney, } from 'atlas-shared';
import { ScrollView, } from '@Components';
import { CustomerJourneyListItem, } from './customer.journey.list.item';
import { fetchConversationCustomerJourney, } from '@Api';
import { TFunction, } from 'i18next';
import { AlertError, preventDefault, } from '@Utils';

export interface IProps {
  t: TFunction;
  conversation: ISharedFullConversation;
  className?: string;
  setSecondaryConversationId: (conversationId: number)=> void;
  setEventId: (eventId: IPreviewContactCustomEvent['id'])=> void;
}

export const CustomerJourneyList = React.memo(({ t, conversation, className, setSecondaryConversationId, setEventId, }: IProps) => {

  const [moreJourney, setMoreJourney, ] = useState<Array<ISharedCustomerJourney>>();
  const [loadingMore, setLoadingMore, ] = useState<boolean>(false);
  const journeys = conversation.journey || [];

  return (
    <ScrollView className={`customer-journey-wrapper${className ? ` ${className}` : ''}`} >
      <div className={'head'} >
        <h2>
          {t('CUSTOMER_JOURNEY')}
        </h2>
        <div className={'info'} >
          <span>
            {t('PREVIOUS_ACTIVITY')}
          </span>
        </div>
      </div>
      <div className='customer-journies'>
        <Timeline>
          {
            [
              ...journeys,
              ...(moreJourney || []),
            ].map((customer_journey, i) => (
              <CustomerJourneyListItem
                key={i}
                journey={customer_journey}
                setSecondaryConversationId={setSecondaryConversationId}
                setEventId={setEventId}
              />
            ))
          }
        </Timeline>
        {journeys.length === 3 && (!Array.isArray(moreJourney) || (moreJourney.length % 5) === 0) && <div
          className='load-more'
          onClick={preventDefault(() => {
            if (loadingMore)
              return;

            setLoadingMore(true);
            return fetchConversationCustomerJourney(conversation.organization_id, conversation.id, 5, (moreJourney?.length || 0) + 3)
              .then(journeys => setMoreJourney([
                ...(moreJourney || []),
                ...journeys,
              ]))
              .catch(e => {
                AlertError(e, { content: e.toString(), });
              })
              .finally(() => {
                setLoadingMore(false);
              });
          })}
        >{t('LOAD_MORE')}{loadingMore && '...'}</div>}
      </div>
    </ScrollView>
  );
});
