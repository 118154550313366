import React from 'react';
import './tab.maximize.scss';
import { ConversationWatcherSourceEnum, IPreviewTab, ISharedFullConversation, } from 'atlas-shared';
import { TabHeader, } from '../tab.header';
import { withFullConversation, IWithFullConversationProps, } from '@Hocs/with.full.conversation';
import { preventDefault, } from '@Utils';
import { IAuth, } from '@Store';
import { ConversationMessages, ConversationTitleBoxIcons, } from '@Components';
import { ContactCustomEventView, } from '@Components/contact_custom_event/contact_custom_event.view';

export interface ITabMaximizedProps {
  auth: IAuth;
  onMini: () => Promise<IPreviewTab>;
  onClose: () => Promise<IPreviewTab>;
  onMinimize: () => Promise<IPreviewTab>;
  onFocus: (conversation: ISharedFullConversation) => void;
  tab: IPreviewTab;
}

const TabMaximizeComponent = (props: ITabMaximizedProps & IWithFullConversationProps) => {
  const {
    tab,
    onMinimize,
    onClose,
    onMini,
    onFocus,
    contact_view,
    conversation_title_view,
    customer_journey_view,
    secondaryConversation,
    secondaryConversationId,
    event,
    eventId,
    setEventId,
    setSecondaryConversationId,
    editContactVisible,
    conversationForm,
    editConversationVisible,
    setEditConversationVisible,
    contact_form_view,
    conversation_form_view,
    patch_conversation,
    conversation,
    auth,
    conversationTimeline,
    contactTimeline,
  } = props;

  if (!conversation) {
    return <></>;
  }

  return (
    <div
      className={'tab-maximize-view'}
      onClick={preventDefault(() => {
        onFocus(conversation);
      })}
    >
      <TabHeader auth={auth} onClick={() => onMinimize()} tab={tab} expanded={true} conversation={conversation} onClose={() => onClose()} onMini={() => onMini()}/>
      <div className={'body'}>
        <div className={'left'}>
          <div className={'sub-title'}>
            {conversation_title_view}
            <ConversationTitleBoxIcons auth={auth} conversation={conversation} patch_conversation={patch_conversation}/>
          </div>
          <div className={'body-view'}>
            <ConversationMessages
              className={'list'}
              form={conversationForm}
              organization_id={conversation.organization_id}
              conversation_id={conversation.id}
              source={ConversationWatcherSourceEnum.Tab}
              editConversationVisible={editConversationVisible}
              setEditConversationVisible={setEditConversationVisible}
              conversation={conversation}
              tab={tab}
              conversationTimeline={conversationTimeline}
              contactTimeline={contactTimeline}
            />
          </div>
        </div>
        <div className={`right ${secondaryConversationId || eventId ? 'large' : ''}`}>
          {
            editContactVisible ? contact_form_view :
              editConversationVisible ? conversation_form_view : (
                secondaryConversationId && secondaryConversation ?
                  <ConversationMessages
                    source={ConversationWatcherSourceEnum.Tab}
                    organization_id={conversation.organization_id}
                    conversation_id={secondaryConversationId}
                    conversation={secondaryConversation}
                    className={'secondary-list'}
                    header={{ onClose: () => setSecondaryConversationId(undefined), }}
                    isSecondary
                  />
                  :
                  eventId && event ? <div className={'secondary-view'}>
                    <ContactCustomEventView
                      event={event}
                      onClose={() => setEventId(undefined)}
                    />
                  </div>
                    :
                    <>
                      <div className={'contact-container'}>
                        {contact_view}
                      </div>
                      {customer_journey_view && <hr/>}
                      {customer_journey_view}
                    </>
              )
          }
          {
            eventId && event && <div className={'secondary-view'}>
              <ContactCustomEventView
                event={event}
                onClose={() => setEventId(undefined)}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export const TabMaximize = React.memo(withFullConversation<ITabMaximizedProps>(TabMaximizeComponent, ConversationWatcherSourceEnum.Tab, false, <div></div>, true));
