import React from 'react';
import { ISvgIconProps, } from './interface';
import { SvgIcon, } from './SvgIcon';

export const VoiceMergeIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M11.3536 12.3536C11.5488 12.1583 11.5488 11.8417 11.3536 11.6464L8.17157 8.46447C7.97631 8.2692 7.65973 8.2692 7.46447 8.46447C7.2692 8.65973 7.2692 8.97631 7.46447 9.17157L10.2929 12L7.46447 14.8284C7.2692 15.0237 7.2692 15.3403 7.46447 15.5355C7.65973 15.7308 7.97631 15.7308 8.17157 15.5355L11.3536 12.3536ZM2 12.5H11V11.5H2V12.5Z" fill="var(--COLOR-000533)" stroke="none" />
    <path d="M22.3536 12.3536C22.5488 12.1583 22.5488 11.8417 22.3536 11.6464L19.1716 8.46447C18.9763 8.2692 18.6597 8.2692 18.4645 8.46447C18.2692 8.65973 18.2692 8.97631 18.4645 9.17157L21.2929 12L18.4645 14.8284C18.2692 15.0237 18.2692 15.3403 18.4645 15.5355C18.6597 15.7308 18.9763 15.7308 19.1716 15.5355L22.3536 12.3536ZM13 12.5H22V11.5H13V12.5Z" fill="var(--COLOR-000533)" stroke="none" />
  </SvgIcon>;
};
