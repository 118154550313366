import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import React from 'react';
import { IWithListProps, } from '@Hocs/with.list';
import { ColumnsType, } from 'antd/lib/table/interface';
import {
  AdminprofilePermissionIdEnum,
  CrudEnum,
  IPreviewPhonenumberPrefix,
  PermissionEnum,
  TPhonenumberPrefixSortableListCol
  , SuperadminProfilePermissionIdEnum, } from 'atlas-shared';

export * from './abstract/phonenumber_prefix.list.column.abstract';
import { PhonenumberPrefixListColumns as PhonenumberPrefixListColumnsBase, } from './abstract/phonenumber_prefix.list.column.abstract';
import { Icon, } from '@Components';
import { ListIcon, PlusIcon, } from '@Assets';

export const PhonenumberPrefixListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewPhonenumberPrefix) => React.ReactElement> = [], filters: IWithListProps<IPreviewPhonenumberPrefix, TPhonenumberPrefixSortableListCol>['filters'], sorter: IWithListProps<IPreviewPhonenumberPrefix, TPhonenumberPrefixSortableListCol>['sorter']): ColumnsType<IPreviewPhonenumberPrefix> => {
  return [
    ...PhonenumberPrefixListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, [
      (row: IPreviewPhonenumberPrefix) => abilities.can(null, CrudEnum.Insert, AdminprofilePermissionIdEnum.PhonenumberPurchase as unknown as PermissionEnum, row) ? <Icon icon={ListIcon} tooltip={{ title: t('LIST_PHONENUMBERS'), }} iconProps={{ size: 14, }} onClick={() => { navigate(`/admin/phonenumber_stocks/search?ff={"phonenumber_prefix_id":["${row.id}"]}`); }} /> : <></>,
      (row: IPreviewPhonenumberPrefix) => abilities.can(null, CrudEnum.Insert, SuperadminProfilePermissionIdEnum.PhonenumberStock as unknown as PermissionEnum, row) ? <Icon icon={PlusIcon} tooltip={{ title: t('BULK_ADD_PHONENUMBERS'), }} iconProps={{ size: 14, }} onClick={() => { navigate(`/admin/phonenumber_stock/bulk/add?phonenumber_prefix_id=${row.id}`); }} /> : <></>,
      ...buttons,
    ], filters, sorter),
  ];
};
