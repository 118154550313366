import { IAuth, } from '@Store';
import { IForm, } from '@Modules/atlas-form-core';
import { ITagFormOptionsProps, TagAdditionalParams as TagAdditionalParamsBase, } from './abstract/tag.form.abstract';

export * from './abstract/tag.form.abstract';

export const TagAdditionalParams: (auth: IAuth, props: ITagFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ITagFormOptionsProps) => ({
  ...TagAdditionalParamsBase(auth, props),
  keywords: { mode: 'tags', help: 'TAG_KEYWORDS_HELP', },
});
