import React from 'react';

interface IProps {
  percentage: number;
  colour: string;
  size?: number;
  strokeWidth?: number;
}

export const CircleProgress = React.memo((props: IProps) => {

  const { percentage, colour, size = 28, strokeWidth = 2, } = props;
  const r = Math.floor((size - strokeWidth) / 2);
  const c = Math.floor(size / 2);
  const circle = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circle) / 100;

  return <svg width={size} height={size} className='circle-progress'>
    <circle
      r={r}
      cx={c}
      cy={c}
      fill="transparent"
      stroke={strokePct !== circle ? colour : ''}
      strokeWidth={strokeWidth}
      strokeDasharray={circle}
      strokeDashoffset={percentage ? strokePct : 0}
    ></circle>
  </svg>;
});
