import { IconText, } from '@Components';
import { CornerUpLeft, CornerUpLeftDouble, CornerUpRight, EditInBoxIcon, PhoneOutgoingIcon, } from '@Assets';
import './conversation.message.reply.scss';
import React, { useEffect, useMemo, useState, } from 'react';
import { ChannelEnum, ISharedFullConversation, ISharedMailMessage, TypeConversationMessage, DraftChannelEnum, TConversationVoiceCall, canEmail, IPreviewQueue, Nullable, hasVoiceLicense, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { conversationMessageReply, } from '@Utils';
import { actionAiResponding, actionSetGlobalCall, IAuth, useAppDispatch, } from '@Store';
import { useQueues, } from '@Hooks';

interface IProps {
  t: TFunction;
  auth: IAuth;
  message: TypeConversationMessage;
  conversation: ISharedFullConversation;
  mini?: boolean;
}

export const ConversationMessageReply = React.memo(({ message, conversation, t, auth, mini, }: IProps) => {

  const dispatch = useAppDispatch();
  const queues = useQueues();
  const [queue, setQueue, ] = useState<Nullable<IPreviewQueue>>(null);
  const [canReplyEmail, setCanReplyEmail, ] = useState<boolean>(false);
  const [canCallBack, setCanCallBack, ] = useState<boolean>(false);

  useEffect(() => {
    setQueue(queues.loaded && conversation.queue_id ? queues.dict[conversation.queue_id] || null : null);
  }, [conversation.queue_id, queues, ]);

  useEffect(() => {
    setCanReplyEmail(canEmail(auth.user) && [ChannelEnum.Mail, ChannelEnum.Form, ].includes(message.channel) && conversation.contact?.emails?.length > 0 && (queue?.__outbound_mail_accounts || []).length > 0);
  }, [message, auth.user, queue, ]);

  useEffect(() => {
    setCanCallBack(hasVoiceLicense(auth.user) && [ChannelEnum.Voice, ChannelEnum.Form, ].includes(message.channel) && conversation.contact?.phones?.length > 0 && auth.user.main && (queue?.__outbound_voice_routes || []).length > 0);
  }, [message, auth.user, queue, ]);

  const actions = useMemo(() => {
    return [
      canReplyEmail && queue?.ai_assistant_id && <li key='reply' onClick={_ => {
        dispatch(actionAiResponding(true));
        conversationMessageReply(t, auth.user, DraftChannelEnum.Mail, conversation, queue, message, false, false, true)
          .finally(() => {
            dispatch(actionAiResponding(false));
          });
      }}>
        <IconText icon={CornerUpLeft} iconProps={{ size: 14, }} text={t('REPLY_WITH_AI')}/>
      </li>,
      canReplyEmail && <li key='reply' onClick={_ => conversationMessageReply(t, auth.user, DraftChannelEnum.Mail, conversation, queue, message)}>
        <IconText icon={CornerUpLeft} iconProps={{ size: 14, }} text={t('REPLY')}/>
      </li>,
      canReplyEmail && !!(message?.channel === ChannelEnum.Mail ? (message as ISharedMailMessage).cc : []).length && <li key='reply-all' onClick={_ => conversationMessageReply(t, auth.user, DraftChannelEnum.Mail, conversation, queue, message, true)}>
        <IconText icon={CornerUpLeftDouble} iconProps={{ size: 14, }} text={t('ALL')}/>
      </li>,
      canReplyEmail && message.channel === ChannelEnum.Mail && <li key='forward' onClick={_ => conversationMessageReply(t, auth.user, DraftChannelEnum.Mail, conversation, queue, message, false, true)}>
        <IconText icon={CornerUpRight} iconProps={{ size: 14, }} text={t('FORWARD')}/>
      </li>,
      canCallBack && ((message as TConversationVoiceCall).contact_phone || '').startsWith('+') && <li key='call-back' onClick={_ => dispatch(actionSetGlobalCall({ numbers: [(message as TConversationVoiceCall).contact_phone, ], requires_route: true, confirm: true, voice_asterisk_id: -1, conversation_id: message.conversation_id, }))}>
        <IconText icon={PhoneOutgoingIcon} iconProps={{ size: 14, }} text={t('CALL_BACK')}/>
      </li>,
      <li onClick={_ => conversationMessageReply(t, auth.user, DraftChannelEnum.InternalNote, conversation, queue, message)} key='note'>
        <IconText icon={EditInBoxIcon} iconProps={{ size: 14, }} text={t('NOTE')}/>
      </li>,
    ].filter(Boolean);
  }, [queue, canReplyEmail, canCallBack, ]);

  return actions.length ? <div className={`conversation-message-reply${mini ? ' mini' : ''}`} >
    <ul className={'actions'} >{actions}</ul>
  </div> : <></>;
});
