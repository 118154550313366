import React from 'react';
import { deleteTimeInterval, } from '@Api';
import { IAbilities, IAuth, IOrganizationStore, } from '@Store';
import { CrudEnum, IPreviewTimeInterval, PermissionEnum, TTimeIntervalSortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnBooleanRender, tableColumnStringSorter, timeSince, } from '@Utils';
import { TFunction, } from 'i18next';
import { NavigateFunction, } from 'react-router';
import { ColumnsType, } from 'antd/lib/table/interface';
import { listAuditButton, listCloneButton, listDeleteButton, listEditButton, GetColumnSearchProps, GetColumnSearchLikeProps, GetColumnSearchEqualProps, } from '../components';
import { IWithListProps, } from '@Hocs/with.list';

export const TimeIntervalListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewTimeInterval) => React.ReactElement> = [], filters: IWithListProps<IPreviewTimeInterval, TTimeIntervalSortableListCol>['filters'], sorter: IWithListProps<IPreviewTimeInterval, TTimeIntervalSortableListCol>['sorter']): ColumnsType<IPreviewTimeInterval> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewTimeInterval, b: IPreviewTimeInterval) => a.id - b.id,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewTimeInterval['organization_id'], time_interval: IPreviewTimeInterval) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id, }, }),
      sorter: (a: IPreviewTimeInterval, b: IPreviewTimeInterval) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewTimeInterval, b: IPreviewTimeInterval) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      filteredValue: filters.title || null,
      ...GetColumnSearchLikeProps(t, 'title', t('TITLE')),
      className: 'table-cell-title',
    },
    {
      title: t('INTERVAL'),
      dataIndex: 'interval',
      key: 'interval',
      sorter: (a: IPreviewTimeInterval, b: IPreviewTimeInterval) => tableColumnStringSorter(a.interval, b.interval),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'interval')?.order || null,
      filteredValue: filters.interval || null,
      ...GetColumnSearchLikeProps(t, 'interval', t('INTERVAL')),
      className: 'table-cell-interval',
    },
    {
      title: t('OPEN'),
      dataIndex: 'open',
      key: 'open',
      render: (open: IPreviewTimeInterval['open'], time_interval: IPreviewTimeInterval) => tableColumnBooleanRender(t, open),
      sorter: (a: IPreviewTimeInterval, b: IPreviewTimeInterval) => (a.open ? 1 : 0) > (b.open ? 1 : 0) ? 1 : -1,
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'open')?.order || null,
      filteredValue: filters.open || null,
      ...GetColumnSearchProps(t, 'open', t('OPEN'), [{ id: true, title: t('YES'), }, { id: false, title: t('NO'), }, ]),
      className: 'table-cell-open',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewTimeInterval['created_at'], time_interval: IPreviewTimeInterval) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewTimeInterval, b: IPreviewTimeInterval) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    showDeleted && { 
      title: t('DELETED'), 
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      className: 'table-cell-deleted_at',
      render: (deleted_at: IPreviewTimeInterval['deleted_at']) => deleted_at ? timeSince(t, new Date(deleted_at), true) : '',
      sorter: (a: IPreviewTimeInterval, b: IPreviewTimeInterval) => tableColumnDatetimeSorter(a.deleted_at, b.deleted_at),
      sortDirections: ['descend', 'ascend', ],
      sortOrder: sorter.find(s => s.column === 'deleted_at')?.order || null,
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewTimeInterval) => (
        <div className={'table-operation-wrp'}>
          {!row.deleted_at && buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Insert, PermissionEnum.TimeInterval, row) && listCloneButton(t, navigate, 'time_interval', row)}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'time_interval', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.TimeInterval, row) && listEditButton(t, navigate, 'time_interval', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Delete, PermissionEnum.TimeInterval, row) && listDeleteButton(t, row, deleteTimeInterval)}
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<IPreviewTimeInterval>;

};
