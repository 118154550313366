import {
  getStatsWidgetMetricData,
  getStatsWidgetTableMetricBoxStyles,
  IMetricDataDicts, IRenderStatusValue, RenderStatusInfo, UserStatusColors,
} from '@Utils';
import React from 'react';
import { TFunction, } from 'i18next';
import {
  getUnixTime, IPreviewOrganization,
  IPreviewStatsWidgetSettingsMetricProperty,
  StatsWidgetMetricFormatEnum,
} from 'atlas-shared/dist';
import { Counter, } from '@Components';

interface IProps {
  t: TFunction;
  organization: IPreviewOrganization;
  metric_settings: IPreviewStatsWidgetSettingsMetricProperty;
  value: number | string;
  metric_data_dicts: IMetricDataDicts;
  is_trend?: boolean;
  trend_value?: number;
  trend_comparison?: number;
}

export const StatsWidgetMetricDataValue = ({ t, metric_settings, value, metric_data_dicts, is_trend = false, trend_value = 0, trend_comparison, organization, }: IProps) => {
  const text_value = getStatsWidgetMetricData(t, metric_settings, value, metric_data_dicts, organization);

  return {
    text_value,
    rendered: metric_settings.format === StatsWidgetMetricFormatEnum.COUNTDOWN
      ? (text_value + '').length > 6
        ? <div className='value'><Counter since={getUnixTime(new Date(text_value))} /></div> : '-'
      : metric_settings.alias === 'status_info'
        ? <div className='value'><RenderStatusInfo
          t={t}
          value={value as unknown as IRenderStatusValue}
          users={metric_data_dicts.users}
          pauses={metric_data_dicts.pauses}
          queues={metric_data_dicts.queues}
        /></div>
        : metric_settings.alias === 'status'
          ? <div className='status-color-wrapper'><div className='status-color' style={{ backgroundColor: UserStatusColors[value], }}></div><span className='status'>{text_value}</span></div>
          : <div className='value' style={!is_trend ? getStatsWidgetTableMetricBoxStyles(metric_settings, value as number, trend_value, trend_comparison) : {}} title={is_trend ? undefined : text_value}>{(text_value + '').replace(/pcs|%/, '')}{metric_settings.format === StatsWidgetMetricFormatEnum.VALUE_PCS && <span className='unit'>pcs</span>}{(metric_settings.of_total || metric_settings.custom_percent || metric_settings.format === StatsWidgetMetricFormatEnum.VALUE_PERCENT) && <span className='unit'>%</span>}</div>,
  };
};
