import { IPreviewAiAssistant, } from 'atlas-shared';
import { IAiAssistantStore, AiAssistantActions, TAiAssistantActionTypes, } from '@Store';

export const AiAssistantReducer = (state: IAiAssistantStore = {
  loaded: false,
  loading: false,
  ai_assistants: [],
  dict: {},
}, action: TAiAssistantActionTypes): IAiAssistantStore => {
  switch (action.type) {
  case AiAssistantActions.LOADING:
    return { ...state, loading: true, };
  case AiAssistantActions.SET_STATE:
    const dict: IAiAssistantStore['dict'] = {};

    action.payload.forEach((ai_assistant: IPreviewAiAssistant) => dict[ai_assistant.id] = ai_assistant);

    return { ...state, loaded: true, loading: false, ai_assistants: action.payload, dict, };
  case AiAssistantActions.ADD_STATE:
    return { ...state, ai_assistants: [...state.ai_assistants, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case AiAssistantActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, ai_assistants: state.ai_assistants.filter((item: IPreviewAiAssistant) => item.id !== action.payload), dict, };
  }
  case AiAssistantActions.UPDATE_STATE:
    const ai_assistants = [...state.ai_assistants, ];
    const index = state.ai_assistants.findIndex((ai_assistant: IPreviewAiAssistant) => ai_assistant.id === action.payload.id);

    if (!~index)
      ai_assistants.push(action.payload);
    else
      ai_assistants[index] = action.payload;

    return { ...state, ai_assistants, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
