import React from 'react';
import { ISvgIconProps, } from './interface';
import { SvgIcon, } from './SvgIcon';

export const VoicemailIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="5.5" cy="11.5" r="4.5"></circle>
    <circle cx="18.5" cy="11.5" r="4.5"></circle>
    <line x1="5.5" y1="16" x2="18.5" y2="16"></line>
  </SvgIcon>;
};
