import React, { useCallback, useEffect, } from 'react';
import { Stats, } from './stats';
import { actionSetHideStats, useAppDispatch, } from '@Store';
import { useLocation, } from 'react-router-dom';
import { useHideStats, } from '@Hooks';

export const StatsWrapper = React.memo(() => {
  
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isHidden = useHideStats();

  const checkStatsStatus = useCallback((location) => {

    dispatch(actionSetHideStats([
      new RegExp(/\/stats/),
      new RegExp(/\/admin\/journey_draft\/\d{1,}\/edit/),
      new RegExp(/\/admin\/journey\/\d{1,}\/edit/),
    ].some(re => !!location.pathname.match(re)?.length)));

  }, [dispatch, ]);

  useEffect(() => {
    checkStatsStatus(location);
  }, [location, checkStatsStatus, ]);
  
  return isHidden ? <></> : <Stats />;
});
