import { SoundActions, } from '@Store';
import { IPreviewSound, } from 'atlas-shared';
import { fetchSounds, } from '@Api';

export const actionFetchSounds = () => {
  return async (dispatch) => {
    dispatch(actionLoadingSounds());
    const sounds = await fetchSounds();

    dispatch(actionSetSounds(sounds));
    return sounds;
  };
};

export const actionLoadingSounds = () => ({
  type: SoundActions.LOADING,
  payload: [],
});

export const actionSetSounds = (payload: Array<IPreviewSound>) => ({
  type: SoundActions.SET_STATE,
  payload: payload,
});

export const actionAddSound = (payload: IPreviewSound) => ({
  type: SoundActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateSound = (payload: IPreviewSound) => ({
  type: SoundActions.UPDATE_STATE,
  payload: payload,
});
