import { RestRequest, } from '@Utils';
import { IPreviewOperationalHours, ISharedOperationalHours, ISharedCreateOperationalHours, ISharedPatchOperationalHours, } from 'atlas-shared';

export const fetchOperationalHourses = async (): Promise<Array<IPreviewOperationalHours>> => {
  return RestRequest.get<Array<IPreviewOperationalHours>>('operational_hours', { socket_id: global.socketId, });
};

export const fetchOperationalHours = async (id: IPreviewOperationalHours['id']): Promise<ISharedOperationalHours> => {
  return RestRequest.get<ISharedOperationalHours>(`operational_hours/${id}`, { socket_id: global.socketId, });
};

export const createOperationalHours = (payload: ISharedCreateOperationalHours) => {
  return RestRequest.post<any, ISharedOperationalHours>('operational_hours', payload, { socket_id: global.socketId, });
};

export const patchOperationalHours = (id: IPreviewOperationalHours['id'], payload: ISharedPatchOperationalHours) => {
  return RestRequest.patch<any, ISharedOperationalHours>(`operational_hours/${id}`, payload, { socket_id: global.socketId, });
};

export const deleteOperationalHours = (id: IPreviewOperationalHours['id']) => {
  return RestRequest.delete(`operational_hours/${id}`, { socket_id: global.socketId, });
};

export const getOperationalHoursUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('operational_hours/update/schema');
};

export const getOperationalHoursCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('operational_hours/create/schema');
};

export const getOperationalHoursPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('operational_hours/patch/schema');
};
