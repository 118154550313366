export const isValueEmpty = (value: any) => {
  if (!value)
    return true;

  if (Array.isArray(value))
    return !value.length;
  
  if (typeof value === 'object')
    return true;

  return false;
};
