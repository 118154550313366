import { IPreviewPhonenumberSetupCostParent, } from 'atlas-shared';
import { IPhonenumberSetupCostParentStore, PhonenumberSetupCostParentActions, TPhonenumberSetupCostParentActionTypes, } from '@Store';

export const PhonenumberSetupCostParentReducer = (state: IPhonenumberSetupCostParentStore = {
  loaded: false,
  loading: false,
  phonenumber_setup_cost_parents: [],
  dict: {},
}, action: TPhonenumberSetupCostParentActionTypes): IPhonenumberSetupCostParentStore => {
  switch (action.type) {
  case PhonenumberSetupCostParentActions.LOADING:
    return { ...state, loading: true, };
  case PhonenumberSetupCostParentActions.SET_STATE:
    const dict: IPhonenumberSetupCostParentStore['dict'] = {};

    action.payload.forEach((phonenumber_setup_cost_parent: IPreviewPhonenumberSetupCostParent) => dict[phonenumber_setup_cost_parent.id] = phonenumber_setup_cost_parent);

    return { ...state, loaded: true, loading: false, phonenumber_setup_cost_parents: action.payload, dict, };
  case PhonenumberSetupCostParentActions.ADD_STATE:
    return { ...state, phonenumber_setup_cost_parents: [...state.phonenumber_setup_cost_parents, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case PhonenumberSetupCostParentActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, phonenumber_setup_cost_parents: state.phonenumber_setup_cost_parents.filter((item: IPreviewPhonenumberSetupCostParent) => item.id !== action.payload), dict, };
  }
  case PhonenumberSetupCostParentActions.UPDATE_STATE:
    const phonenumber_setup_cost_parents = [...state.phonenumber_setup_cost_parents, ];
    const index = state.phonenumber_setup_cost_parents.findIndex((phonenumber_setup_cost_parent: IPreviewPhonenumberSetupCostParent) => phonenumber_setup_cost_parent.id === action.payload.id);

    if (!~index)
      phonenumber_setup_cost_parents.push(action.payload);
    else
      phonenumber_setup_cost_parents[index] = action.payload;

    return { ...state, phonenumber_setup_cost_parents, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
