import React, { PropsWithChildren, } from 'react';
import './styles.scss';
import '../../../styles/index.scss';

interface IProps {
  visible: boolean;
}

export const AppModal = ({ visible, children, }: PropsWithChildren<IProps>) => {
  return (
    <div className={visible ? 'modal-backdrop' : 'no-display'}>
      {
        children
      }
    </div>
  );
};
