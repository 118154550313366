import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchReleases, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useReleases = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const releases = useSelector((store: IReducerStore) => store.releases);

  useEffect(() => {
    if (!initiated && autoload && !releases.loaded && !releases.loading) {
      initiated = true;
      dispatch(actionFetchReleases());
    }
  }, [autoload, releases.loaded, releases.loading, dispatch, ]);

  return releases;
};
