import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchAiAssistants, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useAiAssistants = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const ai_assistants = useSelector((store: IReducerStore) => store.ai_assistants);

  useEffect(() => {
    if (!initiated && autoload && !ai_assistants.loaded && !ai_assistants.loading) {
      initiated = true;
      dispatch(actionFetchAiAssistants());
    }
  }, [autoload, ai_assistants.loaded, ai_assistants.loading, dispatch, ]);

  return ai_assistants;
};
