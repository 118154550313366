import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchOperationalHourses, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useOperationalHourses = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const operational_hourses = useSelector((store: IReducerStore) => store.operational_hourses);

  useEffect(() => {
    if (!initiated && autoload && !operational_hourses.loaded && !operational_hourses.loading) {
      initiated = true;
      dispatch(actionFetchOperationalHourses());
    }
  }, [autoload, operational_hourses.loaded, operational_hourses.loading, dispatch, ]);

  return operational_hourses;
};
