import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchJourneyTriggers, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useJourneyTriggers = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const journey_triggers = useSelector((store: IReducerStore) => store.journey_triggers);

  useEffect(() => {
    if (!initiated && autoload && !journey_triggers.loaded && !journey_triggers.loading) {
      initiated = true;
      dispatch(actionFetchJourneyTriggers());
    }
  }, [autoload, journey_triggers.loaded, journey_triggers.loading, dispatch, ]);

  return journey_triggers;
};
