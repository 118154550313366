import { Timeline, } from 'antd';
import { EditWithLineIcon, } from '@Assets';
import { ConversationMessageDraft, } from '@Components';
import React from 'react';
import { IPreviewForm, ISharedDraft, ISharedFullConversation, Undefinable, } from 'atlas-shared/dist';
import { THighlightedMessage, } from '@Hooks';
import { IAuth, } from '@Store';

interface IProps {
  conversation: ISharedFullConversation;
  draft: ISharedDraft;
  editable: boolean;
  conversationForm: Undefinable<IPreviewForm>;
  highlightedMessage: THighlightedMessage;
  auth: IAuth;
}

export const ConversationMessageDraftTimeline = React.memo(({
  conversation,
  draft,
  editable,
  highlightedMessage,
  auth,
  conversationForm,
}: IProps) => {
  return !conversation ? <></> : <Timeline.Item dot={<div className={'reply-edit-icon'} ><EditWithLineIcon size={13} /></div>} className={`reply-timeline-custom ${!editable ? 'reply-not-editable' : ''}`} >
    <ConversationMessageDraft
      conversation={conversation}
      conversationForm={conversationForm}
      draft={draft}
      editable={editable}
      highlightedMessage={highlightedMessage}
      auth={auth}
    />
  </Timeline.Item>;
});
