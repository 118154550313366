import React, { useCallback, useEffect, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions, } from 'atlas-form';
import {
  DestinationRestrictorEditForm,
  DestinationRestrictorEditInitialValues,
  DestinationRestrictorComponents,
  DestinationRestrictorAdditionalParams,
  DestinationRestrictorFormOptions,
  onDestinationRestrictorFormSaved,
  IDestinationRestrictorFormOptionsProps,
  AlertError,
} from '@Utils';
import { ISharedDestinationRestrictor, ISharedPatchDestinationRestrictor, PermissionEnum, CrudEnum, } from 'atlas-shared';
import { fetchDestinationRestrictor, patchDestinationRestrictor, getDestinationRestrictorUpdateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withEdit, IWithEditProps, } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithEditProps<ISharedDestinationRestrictor> {}

const DestinationRestrictorFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();

  useEffect(() => {
    fetchDestinationRestrictor(id).then(destination_restrictor => setValue(destination_restrictor));
  }, [id, setValue, ]);

  const param_props: IDestinationRestrictorFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
  };
  const formLayout: IForm | undefined = useMemo(() => value && DestinationRestrictorEditForm(value, param_props, t), [value, organizations, t, abilities, ]);
  const options: IFormOptions | undefined = useMemo(() => value && DestinationRestrictorFormOptions(value, param_props, t), [value, organizations, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchDestinationRestrictor, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchDestinationRestrictor) => value && save(() => patchDestinationRestrictor(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onDestinationRestrictorFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [value, save, navigate, t, ]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.DestinationRestrictor, value)}
    >
      <AtlasForm<ISharedPatchDestinationRestrictor>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={DestinationRestrictorComponents}
        additionalParams={DestinationRestrictorAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        confirmRequired={t('DESTINATION_RESTRICTOR_CONFIRM')}
        onValuesChange={onValuesChange}
        initialValues={DestinationRestrictorEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('DESTINATION_RESTRICTOR')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const DestinationRestrictorFormEditView = React.memo(withEdit(DestinationRestrictorFormEdit, getDestinationRestrictorUpdateSchema));
