import { Switch, } from 'antd';
import './header.notifications.settings.scss';
import { patchUser, } from '@Api';
import React from 'react';
import { EnumToArray, NotificationSubjectEnum, } from 'atlas-shared/dist';
import { IAuth, } from '@Store';
import { TFunction, } from 'i18next';

interface IProps {
  auth: IAuth;
  t: TFunction;
}

export const HeaderNotificationsSettings = React.memo(({ auth, t, }: IProps) => {
  return <ul id='notifications-settings'>
    {EnumToArray(NotificationSubjectEnum).map(([key, value, ]) => {
      if ([
        NotificationSubjectEnum.NewAssignment,
        NotificationSubjectEnum.NewInternal,
        NotificationSubjectEnum.SysInfo,
        NotificationSubjectEnum.SysError,
      ].includes(key))
        return;

      return <li>
        <Switch
          size='small'
          defaultChecked={(auth.user.notification_settings || {})[key] !== false}
          onChange={checked => patchUser(auth.user.id, {
            notification_settings: {
              ...(auth.user.notification_settings || {}),
              [key]: checked,
            },
          })}/>
        <span className='ellipsis'>{t(value)}</span>
      </li>;
    })}
  </ul>;
});
