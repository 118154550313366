import { RestRequest, } from '@Utils/rest';
import { IPreviewMailAccount, } from 'atlas-shared';

export * from './abstract/mail_account.api.abstract';

export const reconnectMailAccountImap = (id: IPreviewMailAccount['id']) => {
  return RestRequest.put<any, boolean>(`mail_account/${id}/reconnect/imap`, {}, { socket_id: global.socketId, });
};

export const reconnectMailAccountSmtp = (id: IPreviewMailAccount['id']) => {
  return RestRequest.put<any, boolean>(`mail_account/${id}/reconnect/smtp`, {}, { socket_id: global.socketId, });
};
