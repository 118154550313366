import { AuthActions, TAuthActionTypes, IAuth, } from '@Store';
import { Nullable, } from 'atlas-shared';

export const AuthReducer = (state: Nullable<IAuth> = null, action: TAuthActionTypes): Nullable<IAuth> => {
  switch (action.type) {
  case AuthActions.SET_STATE:
    return { ...action.payload, };
  case AuthActions.SET_USER_STATUS_STATE:
    return { ...(state as IAuth), user_status: action.payload, };
  case AuthActions.SET_USER_STATE:
    return { ...(state as IAuth), user: { ...(state?.user || {}), ...action.payload, }, };
  case AuthActions.REMOVE_STATE:
    return null;
  default:
    return state;
  }
};
