import { IPreviewStatsReport, } from 'atlas-shared';

export enum StatsReportActions {
  SET_STATE = 'STATS_REPORT_SET_STATE',
  ADD_STATE = 'STATS_REPORT_ADD_STATE',
  REMOVE_STATE = 'STATS_REPORT_REMOVE_STATE',
  GET_STATE = 'STATS_REPORT_GET_STATE',
  UPDATE_STATE = 'STATS_REPORT_UPDATE_STATE',
  LOADING = 'STATS_REPORT_LOADING',
}

export interface IStatsReportLoading {
  type: StatsReportActions.LOADING;
}

export interface IStatsReportSetAction {
  type: StatsReportActions.SET_STATE;
  payload: Array<IPreviewStatsReport>;
}

export interface IStatsReportUpdateAction {
  type: StatsReportActions.UPDATE_STATE;
  payload: IPreviewStatsReport;
}

export interface IStatsReportAddAction {
  type: StatsReportActions.ADD_STATE;
  payload: IPreviewStatsReport;
}

export interface IStatsReportRemoveAction {
  type: StatsReportActions.REMOVE_STATE;
  payload: IPreviewStatsReport['id'];
}

export interface IStatsReportStore {
  loaded: boolean;
  loading: boolean;
  stats_reports: Array<IPreviewStatsReport>;
  dict: Record<IPreviewStatsReport['id'], IPreviewStatsReport>;
}

export type TStatsReportActionTypes = IStatsReportSetAction | IStatsReportUpdateAction | IStatsReportAddAction | IStatsReportRemoveAction | IStatsReportLoading;
