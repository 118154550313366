import { IPreviewJourneyVariable, } from 'atlas-shared';

export enum JourneyVariableActions {
  SET_STATE = 'JOURNEY_VARIABLE_SET_STATE',
  ADD_STATE = 'JOURNEY_VARIABLE_ADD_STATE',
  REMOVE_STATE = 'JOURNEY_VARIABLE_REMOVE_STATE',
  GET_STATE = 'JOURNEY_VARIABLE_GET_STATE',
  UPDATE_STATE = 'JOURNEY_VARIABLE_UPDATE_STATE',
  LOADING = 'JOURNEY_VARIABLE_LOADING',
}

export interface IJourneyVariableLoading {
  type: JourneyVariableActions.LOADING;
}

export interface IJourneyVariableSetAction {
  type: JourneyVariableActions.SET_STATE;
  payload: Array<IPreviewJourneyVariable>;
}

export interface IJourneyVariableUpdateAction {
  type: JourneyVariableActions.UPDATE_STATE;
  payload: IPreviewJourneyVariable;
}

export interface IJourneyVariableAddAction {
  type: JourneyVariableActions.ADD_STATE;
  payload: IPreviewJourneyVariable;
}

export interface IJourneyVariableRemoveAction {
  type: JourneyVariableActions.REMOVE_STATE;
  payload: IPreviewJourneyVariable['id'];
}

export interface IJourneyVariableStore {
  loaded: boolean;
  loading: boolean;
  journey_variables: Array<IPreviewJourneyVariable>;
  dict: Record<IPreviewJourneyVariable['id'], IPreviewJourneyVariable>;
}

export type TJourneyVariableActionTypes = IJourneyVariableSetAction | IJourneyVariableUpdateAction | IJourneyVariableAddAction | IJourneyVariableRemoveAction | IJourneyVariableLoading;
