import { useSelector, } from 'react-redux';
import { useEffect, } from 'react';
import { actionFetchSlaPolicies, useAppDispatch, IReducerStore, } from '@Store';

let initiated = false;

export const useSlaPolicies = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const sla_policies = useSelector((store: IReducerStore) => store.sla_policies);

  useEffect(() => {
    if (!initiated && autoload && !sla_policies.loaded && !sla_policies.loading) {
      initiated = true;
      dispatch(actionFetchSlaPolicies());
    }
  }, [autoload, sla_policies.loaded, sla_policies.loading, dispatch, ]);

  return sla_policies;
};
