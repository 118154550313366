import React from 'react';
import { useParams, } from 'react-router';
import { InvoicePdf, } from '@Components/page-views/invoice/invoice.pdf';

export default () => {
  const { id, } = useParams<'id'>();

  if (!id)
    return <>Missing invoice ID!</>;

  return <InvoicePdf id={+id} />;
};
