import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  VoiceRouteAddForm,
  VoiceRouteAddInitialValues,
  VoiceRouteComponents,
  VoiceRouteAdditionalParams,
  VoiceRouteFormOptions,
  onVoiceRouteFormSaved,
  IVoiceRouteFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateVoiceRoute, PermissionEnum, } from 'atlas-shared';
import { createVoiceRoute, fetchVoiceRoute, getVoiceRouteCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useVoiceTrunks, useJourneies, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateVoiceRoute> {}

const VoiceRouteFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const voice_trunks = useVoiceTrunks();
  const journeies = useJourneies();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateVoiceRoute>>();

  const param_props: IVoiceRouteFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    voice_trunks: voice_trunks.voice_trunks,
    journeies: journeies.journeies,
  };
  const formLayout = useMemo(() => VoiceRouteAddForm(values, param_props, t), [values, organizations, voice_trunks, journeies, t, abilities, ]);
  const options: IFormOptions = useMemo(() => VoiceRouteFormOptions(values || VoiceRouteAddInitialValues, param_props, t), [values, organizations, voice_trunks, journeies, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateVoiceRoute, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateVoiceRoute) => save(() => createVoiceRoute(values).then(
    res => onSaved ? onSaved(res, navigate) : onVoiceRouteFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchVoiceRoute(+id)
        .then(({
          organization_id,
          provider,
          inbound_voice_trunk_id,
          outbound_voice_trunk_id,
          number,
          inbound_journey_id,
          outbound_journey_id,
          description,
        }) => {
          setCloned({
            organization_id,
            provider,
            inbound_voice_trunk_id,
            outbound_voice_trunk_id,
            number,
            inbound_journey_id,
            outbound_journey_id,
            description,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !voice_trunks.loaded || !journeies.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.VoiceRoute, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={VoiceRouteComponents}
        additionalParams={VoiceRouteAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...VoiceRouteAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('VOICE_ROUTE')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const VoiceRouteFormAddView = React.memo(withAdd(VoiceRouteFormAdd, getVoiceRouteCreateSchema, VoiceRouteAddInitialValues));
