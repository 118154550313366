import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  SlaPolicyAddForm,
  SlaPolicyAddInitialValues,
  SlaPolicyComponents,
  SlaPolicyAdditionalParams,
  SlaPolicyFormOptions,
  onSlaPolicyFormSaved,
  ISlaPolicyFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateSlaPolicy, PermissionEnum, } from 'atlas-shared';
import { createSlaPolicy, fetchSlaPolicy, getSlaPolicyCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useOperationalHourses, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateSlaPolicy> {}

const SlaPolicyFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const operational_hourses = useOperationalHourses();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateSlaPolicy>>();

  const param_props: ISlaPolicyFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    operational_hourses: operational_hourses.operational_hourses,
  };
  const formLayout = useMemo(() => SlaPolicyAddForm(values, param_props, t), [values, organizations, operational_hourses, t, abilities, ]);
  const options: IFormOptions = useMemo(() => SlaPolicyFormOptions(values || SlaPolicyAddInitialValues, param_props, t), [values, organizations, operational_hourses, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateSlaPolicy, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateSlaPolicy) => save(() => createSlaPolicy(values).then(
    res => onSaved ? onSaved(res, navigate) : onSlaPolicyFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchSlaPolicy(+id)
        .then(({
          organization_id,
          title,
          policy,
        }) => {
          setCloned({
            organization_id,
            title,
            policy,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !operational_hourses.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.SlaPolicy, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={SlaPolicyComponents}
        additionalParams={SlaPolicyAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...SlaPolicyAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('SLA_POLICY')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const SlaPolicyFormAddView = React.memo(withAdd(SlaPolicyFormAdd, getSlaPolicyCreateSchema, SlaPolicyAddInitialValues));
