import React, { useCallback, useEffect, useState, } from 'react';
import debounce from 'lodash/debounce';
import { BrowserNotification, RemoveBrowserNotification, } from '@Utils';
import { useAgentprofiles, useAuth, useIsInactive, } from '@Hooks';
import { UserStatusStatusEnum, } from 'atlas-shared';
import { actionSetInactive, useAppDispatch, } from '@Store';
import { useTranslation, } from 'react-i18next';

export const InactivityCheck = React.memo(() => {
  const [lastActivity, setLastActivity, ] = useState<number>(Date.now());
  const [inactivityReady, setInactivityReady, ] = useState(60);
  const [inactivityPause, setInactivityPause, ] = useState(75);
  const dispatch = useAppDispatch();
  const { t, } = useTranslation();

  const isInactive = useIsInactive();
  const auth = useAuth();
  const agentprofiles = useAgentprofiles();

  const debounceLastActivity = useCallback(debounce(() => {
    setLastActivity(Date.now());
  }, 5000, { maxWait: 15000, }), []);
  
  useEffect(() => {
    global.addEventListener('click', () => {
      debounceLastActivity();
    });
    global.addEventListener('mousemove', () => {
      debounceLastActivity();
    });
  }, []);
  
  useEffect(() => {

    if (isInactive)
      return;

    const timeoutReadyReminderId = 'inactivity-ready-reminder';
    let inactivityLimit = 0;

    if (auth.user_status.status < UserStatusStatusEnum.Paused) {
      inactivityLimit = inactivityReady;
    }
    else if (auth.user_status.status < UserStatusStatusEnum.Busy && auth.user_status.pause_id) {
      inactivityLimit = inactivityPause;
    }

    let timeoutReminder,
      timeoutReached;
    
    if (inactivityLimit) {
      timeoutReminder = setTimeout(() => {

        BrowserNotification(
          timeoutReadyReminderId,
          t('WARNING'),
          t('WARNING_INACTIVITY'),
          [
            {
              subject: 'INACTIVITY_REMINDER_BREAK',
              action: 'INACTIVITY_REMINDER_BREAK',
              title: t('IAM_BACK'),
              onClick: () => {
                setLastActivity(Date.now());
                RemoveBrowserNotification(timeoutReadyReminderId);
              },
            },
          ],
          '/icons/warning.png'
        );
      }, inactivityLimit * .85 * 60000);

      timeoutReached = setTimeout(() => {
        dispatch(actionSetInactive(true));
        RemoveBrowserNotification(timeoutReadyReminderId);
      }, inactivityLimit * 60000);
    }

    return () => {
      if (timeoutReached)
        clearTimeout(timeoutReached);

      if (timeoutReminder) {
        RemoveBrowserNotification(timeoutReadyReminderId);
        clearTimeout(timeoutReminder);
      }
    };
  }, [lastActivity, inactivityPause, inactivityReady, auth.user_status.status, isInactive, ]);
  
  useEffect(() => {
    const agentprofile = auth.user_status.agentprofile_id ? agentprofiles?.dict[auth.user_status.agentprofile_id] : null;
    
    setInactivityReady(agentprofile?.settings.inactivity_ready || 10);
    setInactivityPause(agentprofile?.settings.inactivity_pause || 75);
  }, [auth, agentprofiles, ]);

  return <></>;
});
