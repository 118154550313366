import { FormActions, } from '@Store';
import { IPreviewForm, } from 'atlas-shared';
import { fetchForms, } from '@Api';

export const actionFetchForms = () => {
  return async (dispatch) => {
    dispatch(actionLoadingForms());
    const forms = await fetchForms();

    dispatch(actionSetForms(forms));
    return forms;
  };
};

export const actionLoadingForms = () => ({
  type: FormActions.LOADING,
  payload: [],
});

export const actionSetForms = (payload: Array<IPreviewForm>) => ({
  type: FormActions.SET_STATE,
  payload: payload,
});

export const actionAddForm = (payload: IPreviewForm) => ({
  type: FormActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateForm = (payload: IPreviewForm) => ({
  type: FormActions.UPDATE_STATE,
  payload: payload,
});
