import React, { useState, } from 'react';
import { Popover, } from 'antd';
import { IPreviewConversation, ISharedFullConversation, } from 'atlas-shared';
import { IWithFullConversationProps, } from '@Hocs/with.full.conversation';
import { useTranslation, } from 'react-i18next';
import { entitiesToOptions, getFullName, IOption, } from '@Utils';
import { mergeConversations, searchConversationEntities, } from '@Api';
import { FormSelect, } from 'atlas-form';
import { useAuth, useQueues, useUsers, } from '@Hooks';
import { InboxIcon, UserIcon, } from '@Assets/icons';
import { IconText, } from '@Components';

interface IProps {
  conversation: ISharedFullConversation;
  children?: any;
  patch_conversation: IWithFullConversationProps['patch_conversation'];
}

export const ConversationMergePopover = ({ conversation, children, patch_conversation, }: IProps) => {
  const { t, } = useTranslation();
  const auth = useAuth();
  const users = useUsers();
  const queues = useQueues();
  const [primaryId, setPrimaryId, ] = useState<IPreviewConversation['id']>();

  return (
    <Popover
      className={'merge-popover-container'}
      placement='bottomLeft'
      overlayStyle={{ width: 320, }}
      content={
        <div className={'merge-popover-content'}>
          <div className={'popover-header'}>
            <span className='popover-title' style={{ opacity: .5, textTransform: 'uppercase', }}>{t('MERGE_CONVERSATION')}</span>
            <span className='popover-title' style={{ opacity: .5, }}>{t('CONVERSATION_MERGE_EXPLANATION')}</span>
            <span className='popover-title'>{t('CONVERSATION')}</span>
          </div>
          <div className={'popover-content'}>
            <FormSelect
              onChange={id => setPrimaryId(id)}
              onSearch={async (w: string) => entitiesToOptions(await searchConversationEntities(w, 10, [conversation.id, ], auth.user_status.agentprofile_id, conversation.organization_id), 'conversation') as Array<IOption>}
              placeholder={t('SEARCH_CONVERSATION')}
              fixedDropdown={true}
              dropdownClassName='conversation-select'
              className='conversation-selector'
              optionRender={(option: IOption) => {
                const conversation: IPreviewConversation = option.entity;
                const queue = queues.queues.find(queue => queue.id === conversation.queue_id);
                const agent = conversation.user_id && users.dict[conversation.user_id] ? getFullName(users.dict[conversation.user_id]) : t('NO_AGENT');

                return <>
                  <div className='row-1'>
                    <span className='id'>{conversation.id}</span>
                    <span className='title'>{conversation.title}</span>
                    <span className='status'>{t(`STATUS_${conversation.status}`)}</span>
                  </div>
                  <div className='row-2'>
                    <span className='queue'><IconText icon={InboxIcon} text={queue ? queue.title : t('NO_QUEUE')} /></span>
                    <span className='agent'><IconText icon={UserIcon} text={agent} /></span>
                    <span className='primary'>{t('PRIMARY')}</span>
                  </div>
                </>;
              }}
            />
          </div>
          {primaryId && <div className={'done-btn'} onClick={e => mergeConversations(conversation.organization_id, conversation.id, primaryId)}>{t('MERGE')}</div>}
        </div>
      } trigger='click'>
      {children}
    </Popover>
  );
};
