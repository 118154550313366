import { OrganizationActions, } from '@Store';
import { IPreviewOrganization, } from 'atlas-shared';
import { fetchOrganizations, } from '@Api';

export const actionFetchOrganizations = () => {
  return async (dispatch) => {
    dispatch(actionLoadingOrganizations());
    const organizations = await fetchOrganizations();

    dispatch(actionSetOrganizations(organizations));
    return organizations;
  };
};

export const actionLoadingOrganizations = () => ({
  type: OrganizationActions.LOADING,
  payload: [],
});

export const actionSetOrganizations = (payload: Array<IPreviewOrganization>) => ({
  type: OrganizationActions.SET_STATE,
  payload: payload,
});

export const actionAddOrganization = (payload: IPreviewOrganization) => ({
  type: OrganizationActions.ADD_STATE,
  payload: payload,
});

export const actionUpdateOrganization = (payload: IPreviewOrganization) => ({
  type: OrganizationActions.UPDATE_STATE,
  payload: payload,
});
