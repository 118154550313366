import { IPreviewList, } from 'atlas-shared';
import { IListStore, ListActions, TListActionTypes, } from '@Store';

export const ListReducer = (state: IListStore = {
  loaded: false,
  loading: false,
  lists: [],
  dict: {},
}, action: TListActionTypes): IListStore => {
  switch (action.type) {
  case ListActions.LOADING:
    return { ...state, loading: true, };
  case ListActions.SET_STATE:
    const dict: IListStore['dict'] = {};

    action.payload.forEach((list: IPreviewList) => dict[list.id] = list);

    return { ...state, loaded: true, loading: false, lists: action.payload, dict, };
  case ListActions.ADD_STATE:
    return { ...state, lists: [...state.lists, action.payload, ], dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  case ListActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, lists: state.lists.filter((item: IPreviewList) => item.id !== action.payload), dict, };
  }
  case ListActions.UPDATE_STATE:
    const lists = [...state.lists, ];
    const index = state.lists.findIndex((list: IPreviewList) => list.id === action.payload.id);

    if (!~index)
      lists.push(action.payload);
    else
      lists[index] = action.payload;

    return { ...state, lists, dict: { ...state.dict, [action.payload.id]: action.payload, }, };
  default:
    return state;
  }
};
